/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { PythPriceState, StorkPriceState } from "./oracle.js";
export const protobufPackage = "injective.oracle.v1beta1";
function createBaseSetChainlinkPriceEvent() {
    return { feedId: "", answer: "", timestamp: "0" };
}
export const SetChainlinkPriceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.answer !== "") {
            writer.uint32(18).string(message.answer);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).uint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetChainlinkPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.answer = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            answer: isSet(object.answer) ? String(object.answer) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.answer !== undefined && (obj.answer = message.answer);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return SetChainlinkPriceEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSetChainlinkPriceEvent();
        message.feedId = object.feedId ?? "";
        message.answer = object.answer ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSetBandPriceEvent() {
    return { relayer: "", symbol: "", price: "", resolveTime: "0", requestId: "0" };
}
export const SetBandPriceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.relayer !== "") {
            writer.uint32(10).string(message.relayer);
        }
        if (message.symbol !== "") {
            writer.uint32(18).string(message.symbol);
        }
        if (message.price !== "") {
            writer.uint32(26).string(message.price);
        }
        if (message.resolveTime !== "0") {
            writer.uint32(32).uint64(message.resolveTime);
        }
        if (message.requestId !== "0") {
            writer.uint32(40).uint64(message.requestId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetBandPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.relayer = reader.string();
                    break;
                case 2:
                    message.symbol = reader.string();
                    break;
                case 3:
                    message.price = reader.string();
                    break;
                case 4:
                    message.resolveTime = longToString(reader.uint64());
                    break;
                case 5:
                    message.requestId = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            relayer: isSet(object.relayer) ? String(object.relayer) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            price: isSet(object.price) ? String(object.price) : "",
            resolveTime: isSet(object.resolveTime) ? String(object.resolveTime) : "0",
            requestId: isSet(object.requestId) ? String(object.requestId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.relayer !== undefined && (obj.relayer = message.relayer);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.price !== undefined && (obj.price = message.price);
        message.resolveTime !== undefined && (obj.resolveTime = message.resolveTime);
        message.requestId !== undefined && (obj.requestId = message.requestId);
        return obj;
    },
    create(base) {
        return SetBandPriceEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSetBandPriceEvent();
        message.relayer = object.relayer ?? "";
        message.symbol = object.symbol ?? "";
        message.price = object.price ?? "";
        message.resolveTime = object.resolveTime ?? "0";
        message.requestId = object.requestId ?? "0";
        return message;
    },
};
function createBaseSetBandIBCPriceEvent() {
    return { relayer: "", symbols: [], prices: [], resolveTime: "0", requestId: "0", clientId: "0" };
}
export const SetBandIBCPriceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.relayer !== "") {
            writer.uint32(10).string(message.relayer);
        }
        for (const v of message.symbols) {
            writer.uint32(18).string(v);
        }
        for (const v of message.prices) {
            writer.uint32(26).string(v);
        }
        if (message.resolveTime !== "0") {
            writer.uint32(32).uint64(message.resolveTime);
        }
        if (message.requestId !== "0") {
            writer.uint32(40).uint64(message.requestId);
        }
        if (message.clientId !== "0") {
            writer.uint32(48).int64(message.clientId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetBandIBCPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.relayer = reader.string();
                    break;
                case 2:
                    message.symbols.push(reader.string());
                    break;
                case 3:
                    message.prices.push(reader.string());
                    break;
                case 4:
                    message.resolveTime = longToString(reader.uint64());
                    break;
                case 5:
                    message.requestId = longToString(reader.uint64());
                    break;
                case 6:
                    message.clientId = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            relayer: isSet(object.relayer) ? String(object.relayer) : "",
            symbols: Array.isArray(object?.symbols) ? object.symbols.map((e) => String(e)) : [],
            prices: Array.isArray(object?.prices) ? object.prices.map((e) => String(e)) : [],
            resolveTime: isSet(object.resolveTime) ? String(object.resolveTime) : "0",
            requestId: isSet(object.requestId) ? String(object.requestId) : "0",
            clientId: isSet(object.clientId) ? String(object.clientId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.relayer !== undefined && (obj.relayer = message.relayer);
        if (message.symbols) {
            obj.symbols = message.symbols.map((e) => e);
        }
        else {
            obj.symbols = [];
        }
        if (message.prices) {
            obj.prices = message.prices.map((e) => e);
        }
        else {
            obj.prices = [];
        }
        message.resolveTime !== undefined && (obj.resolveTime = message.resolveTime);
        message.requestId !== undefined && (obj.requestId = message.requestId);
        message.clientId !== undefined && (obj.clientId = message.clientId);
        return obj;
    },
    create(base) {
        return SetBandIBCPriceEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSetBandIBCPriceEvent();
        message.relayer = object.relayer ?? "";
        message.symbols = object.symbols?.map((e) => e) || [];
        message.prices = object.prices?.map((e) => e) || [];
        message.resolveTime = object.resolveTime ?? "0";
        message.requestId = object.requestId ?? "0";
        message.clientId = object.clientId ?? "0";
        return message;
    },
};
function createBaseEventBandIBCAckSuccess() {
    return { ackResult: "", clientId: "0" };
}
export const EventBandIBCAckSuccess = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ackResult !== "") {
            writer.uint32(10).string(message.ackResult);
        }
        if (message.clientId !== "0") {
            writer.uint32(16).int64(message.clientId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBandIBCAckSuccess();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ackResult = reader.string();
                    break;
                case 2:
                    message.clientId = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ackResult: isSet(object.ackResult) ? String(object.ackResult) : "",
            clientId: isSet(object.clientId) ? String(object.clientId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ackResult !== undefined && (obj.ackResult = message.ackResult);
        message.clientId !== undefined && (obj.clientId = message.clientId);
        return obj;
    },
    create(base) {
        return EventBandIBCAckSuccess.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBandIBCAckSuccess();
        message.ackResult = object.ackResult ?? "";
        message.clientId = object.clientId ?? "0";
        return message;
    },
};
function createBaseEventBandIBCAckError() {
    return { ackError: "", clientId: "0" };
}
export const EventBandIBCAckError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ackError !== "") {
            writer.uint32(10).string(message.ackError);
        }
        if (message.clientId !== "0") {
            writer.uint32(16).int64(message.clientId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBandIBCAckError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ackError = reader.string();
                    break;
                case 2:
                    message.clientId = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ackError: isSet(object.ackError) ? String(object.ackError) : "",
            clientId: isSet(object.clientId) ? String(object.clientId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ackError !== undefined && (obj.ackError = message.ackError);
        message.clientId !== undefined && (obj.clientId = message.clientId);
        return obj;
    },
    create(base) {
        return EventBandIBCAckError.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBandIBCAckError();
        message.ackError = object.ackError ?? "";
        message.clientId = object.clientId ?? "0";
        return message;
    },
};
function createBaseEventBandIBCResponseTimeout() {
    return { clientId: "0" };
}
export const EventBandIBCResponseTimeout = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientId !== "0") {
            writer.uint32(8).int64(message.clientId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBandIBCResponseTimeout();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientId = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { clientId: isSet(object.clientId) ? String(object.clientId) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.clientId !== undefined && (obj.clientId = message.clientId);
        return obj;
    },
    create(base) {
        return EventBandIBCResponseTimeout.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBandIBCResponseTimeout();
        message.clientId = object.clientId ?? "0";
        return message;
    },
};
function createBaseSetPriceFeedPriceEvent() {
    return { relayer: "", base: "", quote: "", price: "" };
}
export const SetPriceFeedPriceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.relayer !== "") {
            writer.uint32(10).string(message.relayer);
        }
        if (message.base !== "") {
            writer.uint32(18).string(message.base);
        }
        if (message.quote !== "") {
            writer.uint32(26).string(message.quote);
        }
        if (message.price !== "") {
            writer.uint32(34).string(message.price);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetPriceFeedPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.relayer = reader.string();
                    break;
                case 2:
                    message.base = reader.string();
                    break;
                case 3:
                    message.quote = reader.string();
                    break;
                case 4:
                    message.price = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            relayer: isSet(object.relayer) ? String(object.relayer) : "",
            base: isSet(object.base) ? String(object.base) : "",
            quote: isSet(object.quote) ? String(object.quote) : "",
            price: isSet(object.price) ? String(object.price) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.relayer !== undefined && (obj.relayer = message.relayer);
        message.base !== undefined && (obj.base = message.base);
        message.quote !== undefined && (obj.quote = message.quote);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    create(base) {
        return SetPriceFeedPriceEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSetPriceFeedPriceEvent();
        message.relayer = object.relayer ?? "";
        message.base = object.base ?? "";
        message.quote = object.quote ?? "";
        message.price = object.price ?? "";
        return message;
    },
};
function createBaseSetProviderPriceEvent() {
    return { provider: "", relayer: "", symbol: "", price: "" };
}
export const SetProviderPriceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.provider !== "") {
            writer.uint32(10).string(message.provider);
        }
        if (message.relayer !== "") {
            writer.uint32(18).string(message.relayer);
        }
        if (message.symbol !== "") {
            writer.uint32(26).string(message.symbol);
        }
        if (message.price !== "") {
            writer.uint32(34).string(message.price);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetProviderPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.provider = reader.string();
                    break;
                case 2:
                    message.relayer = reader.string();
                    break;
                case 3:
                    message.symbol = reader.string();
                    break;
                case 4:
                    message.price = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            provider: isSet(object.provider) ? String(object.provider) : "",
            relayer: isSet(object.relayer) ? String(object.relayer) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            price: isSet(object.price) ? String(object.price) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.provider !== undefined && (obj.provider = message.provider);
        message.relayer !== undefined && (obj.relayer = message.relayer);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    create(base) {
        return SetProviderPriceEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSetProviderPriceEvent();
        message.provider = object.provider ?? "";
        message.relayer = object.relayer ?? "";
        message.symbol = object.symbol ?? "";
        message.price = object.price ?? "";
        return message;
    },
};
function createBaseSetCoinbasePriceEvent() {
    return { symbol: "", price: "", timestamp: "0" };
}
export const SetCoinbasePriceEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.symbol !== "") {
            writer.uint32(10).string(message.symbol);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).uint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetCoinbasePriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.symbol = reader.string();
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            price: isSet(object.price) ? String(object.price) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.price !== undefined && (obj.price = message.price);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return SetCoinbasePriceEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSetCoinbasePriceEvent();
        message.symbol = object.symbol ?? "";
        message.price = object.price ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseEventSetStorkPrices() {
    return { prices: [] };
}
export const EventSetStorkPrices = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.prices) {
            StorkPriceState.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSetStorkPrices();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.prices.push(StorkPriceState.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { prices: Array.isArray(object?.prices) ? object.prices.map((e) => StorkPriceState.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.prices) {
            obj.prices = message.prices.map((e) => e ? StorkPriceState.toJSON(e) : undefined);
        }
        else {
            obj.prices = [];
        }
        return obj;
    },
    create(base) {
        return EventSetStorkPrices.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSetStorkPrices();
        message.prices = object.prices?.map((e) => StorkPriceState.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventSetPythPrices() {
    return { prices: [] };
}
export const EventSetPythPrices = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.prices) {
            PythPriceState.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSetPythPrices();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.prices.push(PythPriceState.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { prices: Array.isArray(object?.prices) ? object.prices.map((e) => PythPriceState.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.prices) {
            obj.prices = message.prices.map((e) => e ? PythPriceState.toJSON(e) : undefined);
        }
        else {
            obj.prices = [];
        }
        return obj;
    },
    create(base) {
        return EventSetPythPrices.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSetPythPrices();
        message.prices = object.prices?.map((e) => PythPriceState.fromPartial(e)) || [];
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
