/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "injective_spot_exchange_rpc";
function createBaseMarketsRequest() {
    return { marketStatus: "", baseDenom: "", quoteDenom: "", marketStatuses: [] };
}
export const MarketsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketStatus !== "") {
            writer.uint32(10).string(message.marketStatus);
        }
        if (message.baseDenom !== "") {
            writer.uint32(18).string(message.baseDenom);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(26).string(message.quoteDenom);
        }
        for (const v of message.marketStatuses) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketStatus = reader.string();
                    break;
                case 2:
                    message.baseDenom = reader.string();
                    break;
                case 3:
                    message.quoteDenom = reader.string();
                    break;
                case 4:
                    message.marketStatuses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketStatus: isSet(object.marketStatus) ? String(object.marketStatus) : "",
            baseDenom: isSet(object.baseDenom) ? String(object.baseDenom) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            marketStatuses: Array.isArray(object?.marketStatuses) ? object.marketStatuses.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketStatus !== undefined && (obj.marketStatus = message.marketStatus);
        message.baseDenom !== undefined && (obj.baseDenom = message.baseDenom);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        if (message.marketStatuses) {
            obj.marketStatuses = message.marketStatuses.map((e) => e);
        }
        else {
            obj.marketStatuses = [];
        }
        return obj;
    },
    create(base) {
        return MarketsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketsRequest();
        message.marketStatus = object.marketStatus ?? "";
        message.baseDenom = object.baseDenom ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.marketStatuses = object.marketStatuses?.map((e) => e) || [];
        return message;
    },
};
function createBaseMarketsResponse() {
    return { markets: [] };
}
export const MarketsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.markets) {
            SpotMarketInfo.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.markets.push(SpotMarketInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            markets: Array.isArray(object?.markets) ? object.markets.map((e) => SpotMarketInfo.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.markets) {
            obj.markets = message.markets.map((e) => e ? SpotMarketInfo.toJSON(e) : undefined);
        }
        else {
            obj.markets = [];
        }
        return obj;
    },
    create(base) {
        return MarketsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketsResponse();
        message.markets = object.markets?.map((e) => SpotMarketInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSpotMarketInfo() {
    return {
        marketId: "",
        marketStatus: "",
        ticker: "",
        baseDenom: "",
        baseTokenMeta: undefined,
        quoteDenom: "",
        quoteTokenMeta: undefined,
        makerFeeRate: "",
        takerFeeRate: "",
        serviceProviderFee: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
    };
}
export const SpotMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.marketStatus !== "") {
            writer.uint32(18).string(message.marketStatus);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.baseDenom !== "") {
            writer.uint32(34).string(message.baseDenom);
        }
        if (message.baseTokenMeta !== undefined) {
            TokenMeta.encode(message.baseTokenMeta, writer.uint32(42).fork()).ldelim();
        }
        if (message.quoteDenom !== "") {
            writer.uint32(50).string(message.quoteDenom);
        }
        if (message.quoteTokenMeta !== undefined) {
            TokenMeta.encode(message.quoteTokenMeta, writer.uint32(58).fork()).ldelim();
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(66).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(74).string(message.takerFeeRate);
        }
        if (message.serviceProviderFee !== "") {
            writer.uint32(82).string(message.serviceProviderFee);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(90).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(98).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(106).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.marketStatus = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.baseDenom = reader.string();
                    break;
                case 5:
                    message.baseTokenMeta = TokenMeta.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.quoteDenom = reader.string();
                    break;
                case 7:
                    message.quoteTokenMeta = TokenMeta.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.makerFeeRate = reader.string();
                    break;
                case 9:
                    message.takerFeeRate = reader.string();
                    break;
                case 10:
                    message.serviceProviderFee = reader.string();
                    break;
                case 11:
                    message.minPriceTickSize = reader.string();
                    break;
                case 12:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 13:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            marketStatus: isSet(object.marketStatus) ? String(object.marketStatus) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            baseDenom: isSet(object.baseDenom) ? String(object.baseDenom) : "",
            baseTokenMeta: isSet(object.baseTokenMeta) ? TokenMeta.fromJSON(object.baseTokenMeta) : undefined,
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            quoteTokenMeta: isSet(object.quoteTokenMeta) ? TokenMeta.fromJSON(object.quoteTokenMeta) : undefined,
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            serviceProviderFee: isSet(object.serviceProviderFee) ? String(object.serviceProviderFee) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.marketStatus !== undefined && (obj.marketStatus = message.marketStatus);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.baseDenom !== undefined && (obj.baseDenom = message.baseDenom);
        message.baseTokenMeta !== undefined &&
            (obj.baseTokenMeta = message.baseTokenMeta ? TokenMeta.toJSON(message.baseTokenMeta) : undefined);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.quoteTokenMeta !== undefined &&
            (obj.quoteTokenMeta = message.quoteTokenMeta ? TokenMeta.toJSON(message.quoteTokenMeta) : undefined);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.serviceProviderFee !== undefined && (obj.serviceProviderFee = message.serviceProviderFee);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return SpotMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotMarketInfo();
        message.marketId = object.marketId ?? "";
        message.marketStatus = object.marketStatus ?? "";
        message.ticker = object.ticker ?? "";
        message.baseDenom = object.baseDenom ?? "";
        message.baseTokenMeta = (object.baseTokenMeta !== undefined && object.baseTokenMeta !== null)
            ? TokenMeta.fromPartial(object.baseTokenMeta)
            : undefined;
        message.quoteDenom = object.quoteDenom ?? "";
        message.quoteTokenMeta = (object.quoteTokenMeta !== undefined && object.quoteTokenMeta !== null)
            ? TokenMeta.fromPartial(object.quoteTokenMeta)
            : undefined;
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.serviceProviderFee = object.serviceProviderFee ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBaseTokenMeta() {
    return { name: "", address: "", symbol: "", logo: "", decimals: 0, updatedAt: "0" };
}
export const TokenMeta = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        if (message.symbol !== "") {
            writer.uint32(26).string(message.symbol);
        }
        if (message.logo !== "") {
            writer.uint32(34).string(message.logo);
        }
        if (message.decimals !== 0) {
            writer.uint32(40).sint32(message.decimals);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(48).sint64(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenMeta();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                case 3:
                    message.symbol = reader.string();
                    break;
                case 4:
                    message.logo = reader.string();
                    break;
                case 5:
                    message.decimals = reader.sint32();
                    break;
                case 6:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            address: isSet(object.address) ? String(object.address) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            logo: isSet(object.logo) ? String(object.logo) : "",
            decimals: isSet(object.decimals) ? Number(object.decimals) : 0,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.address !== undefined && (obj.address = message.address);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.logo !== undefined && (obj.logo = message.logo);
        message.decimals !== undefined && (obj.decimals = Math.round(message.decimals));
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return TokenMeta.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTokenMeta();
        message.name = object.name ?? "";
        message.address = object.address ?? "";
        message.symbol = object.symbol ?? "";
        message.logo = object.logo ?? "";
        message.decimals = object.decimals ?? 0;
        message.updatedAt = object.updatedAt ?? "0";
        return message;
    },
};
function createBaseMarketRequest() {
    return { marketId: "" };
}
export const MarketRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketId: isSet(object.marketId) ? String(object.marketId) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return MarketRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketRequest();
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseMarketResponse() {
    return { market: undefined };
}
export const MarketResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            SpotMarketInfo.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = SpotMarketInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { market: isSet(object.market) ? SpotMarketInfo.fromJSON(object.market) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined && (obj.market = message.market ? SpotMarketInfo.toJSON(message.market) : undefined);
        return obj;
    },
    create(base) {
        return MarketResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketResponse();
        message.market = (object.market !== undefined && object.market !== null)
            ? SpotMarketInfo.fromPartial(object.market)
            : undefined;
        return message;
    },
};
function createBaseStreamMarketsRequest() {
    return { marketIds: [] };
}
export const StreamMarketsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamMarketsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamMarketsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamMarketsRequest();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamMarketsResponse() {
    return { market: undefined, operationType: "", timestamp: "0" };
}
export const StreamMarketsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            SpotMarketInfo.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamMarketsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = SpotMarketInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            market: isSet(object.market) ? SpotMarketInfo.fromJSON(object.market) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined && (obj.market = message.market ? SpotMarketInfo.toJSON(message.market) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamMarketsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamMarketsResponse();
        message.market = (object.market !== undefined && object.market !== null)
            ? SpotMarketInfo.fromPartial(object.market)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseOrderbookV2Request() {
    return { marketId: "" };
}
export const OrderbookV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketId: isSet(object.marketId) ? String(object.marketId) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return OrderbookV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookV2Request();
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseOrderbookV2Response() {
    return { orderbook: undefined };
}
export const OrderbookV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderbook !== undefined) {
            SpotLimitOrderbookV2.encode(message.orderbook, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbook = SpotLimitOrderbookV2.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { orderbook: isSet(object.orderbook) ? SpotLimitOrderbookV2.fromJSON(object.orderbook) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? SpotLimitOrderbookV2.toJSON(message.orderbook) : undefined);
        return obj;
    },
    create(base) {
        return OrderbookV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookV2Response();
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? SpotLimitOrderbookV2.fromPartial(object.orderbook)
            : undefined;
        return message;
    },
};
function createBaseSpotLimitOrderbookV2() {
    return { buys: [], sells: [], sequence: "0", timestamp: "0" };
}
export const SpotLimitOrderbookV2 = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.buys) {
            PriceLevel.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.sells) {
            PriceLevel.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotLimitOrderbookV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.buys.push(PriceLevel.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.sells.push(PriceLevel.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            buys: Array.isArray(object?.buys) ? object.buys.map((e) => PriceLevel.fromJSON(e)) : [],
            sells: Array.isArray(object?.sells) ? object.sells.map((e) => PriceLevel.fromJSON(e)) : [],
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.buys) {
            obj.buys = message.buys.map((e) => e ? PriceLevel.toJSON(e) : undefined);
        }
        else {
            obj.buys = [];
        }
        if (message.sells) {
            obj.sells = message.sells.map((e) => e ? PriceLevel.toJSON(e) : undefined);
        }
        else {
            obj.sells = [];
        }
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return SpotLimitOrderbookV2.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotLimitOrderbookV2();
        message.buys = object.buys?.map((e) => PriceLevel.fromPartial(e)) || [];
        message.sells = object.sells?.map((e) => PriceLevel.fromPartial(e)) || [];
        message.sequence = object.sequence ?? "0";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBasePriceLevel() {
    return { price: "", quantity: "", timestamp: "0" };
}
export const PriceLevel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceLevel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PriceLevel.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceLevel();
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseOrderbooksV2Request() {
    return { marketIds: [] };
}
export const OrderbooksV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbooksV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return OrderbooksV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbooksV2Request();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseOrderbooksV2Response() {
    return { orderbooks: [] };
}
export const OrderbooksV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orderbooks) {
            SingleSpotLimitOrderbookV2.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbooksV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbooks.push(SingleSpotLimitOrderbookV2.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderbooks: Array.isArray(object?.orderbooks)
                ? object.orderbooks.map((e) => SingleSpotLimitOrderbookV2.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orderbooks) {
            obj.orderbooks = message.orderbooks.map((e) => e ? SingleSpotLimitOrderbookV2.toJSON(e) : undefined);
        }
        else {
            obj.orderbooks = [];
        }
        return obj;
    },
    create(base) {
        return OrderbooksV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbooksV2Response();
        message.orderbooks = object.orderbooks?.map((e) => SingleSpotLimitOrderbookV2.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSingleSpotLimitOrderbookV2() {
    return { marketId: "", orderbook: undefined };
}
export const SingleSpotLimitOrderbookV2 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderbook !== undefined) {
            SpotLimitOrderbookV2.encode(message.orderbook, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSingleSpotLimitOrderbookV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderbook = SpotLimitOrderbookV2.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderbook: isSet(object.orderbook) ? SpotLimitOrderbookV2.fromJSON(object.orderbook) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? SpotLimitOrderbookV2.toJSON(message.orderbook) : undefined);
        return obj;
    },
    create(base) {
        return SingleSpotLimitOrderbookV2.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSingleSpotLimitOrderbookV2();
        message.marketId = object.marketId ?? "";
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? SpotLimitOrderbookV2.fromPartial(object.orderbook)
            : undefined;
        return message;
    },
};
function createBaseStreamOrderbookV2Request() {
    return { marketIds: [] };
}
export const StreamOrderbookV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamOrderbookV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookV2Request();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrderbookV2Response() {
    return { orderbook: undefined, operationType: "", timestamp: "0", marketId: "" };
}
export const StreamOrderbookV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderbook !== undefined) {
            SpotLimitOrderbookV2.encode(message.orderbook, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        if (message.marketId !== "") {
            writer.uint32(34).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbook = SpotLimitOrderbookV2.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                case 4:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderbook: isSet(object.orderbook) ? SpotLimitOrderbookV2.fromJSON(object.orderbook) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? SpotLimitOrderbookV2.toJSON(message.orderbook) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return StreamOrderbookV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookV2Response();
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? SpotLimitOrderbookV2.fromPartial(object.orderbook)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseStreamOrderbookUpdateRequest() {
    return { marketIds: [] };
}
export const StreamOrderbookUpdateRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookUpdateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamOrderbookUpdateRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookUpdateRequest();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrderbookUpdateResponse() {
    return { orderbookLevelUpdates: undefined, operationType: "", timestamp: "0", marketId: "" };
}
export const StreamOrderbookUpdateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderbookLevelUpdates !== undefined) {
            OrderbookLevelUpdates.encode(message.orderbookLevelUpdates, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        if (message.marketId !== "") {
            writer.uint32(34).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookUpdateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbookLevelUpdates = OrderbookLevelUpdates.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                case 4:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderbookLevelUpdates: isSet(object.orderbookLevelUpdates)
                ? OrderbookLevelUpdates.fromJSON(object.orderbookLevelUpdates)
                : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderbookLevelUpdates !== undefined && (obj.orderbookLevelUpdates = message.orderbookLevelUpdates
            ? OrderbookLevelUpdates.toJSON(message.orderbookLevelUpdates)
            : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return StreamOrderbookUpdateResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookUpdateResponse();
        message.orderbookLevelUpdates =
            (object.orderbookLevelUpdates !== undefined && object.orderbookLevelUpdates !== null)
                ? OrderbookLevelUpdates.fromPartial(object.orderbookLevelUpdates)
                : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseOrderbookLevelUpdates() {
    return { marketId: "", sequence: "0", buys: [], sells: [], updatedAt: "0" };
}
export const OrderbookLevelUpdates = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.sequence !== "0") {
            writer.uint32(16).uint64(message.sequence);
        }
        for (const v of message.buys) {
            PriceLevelUpdate.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.sells) {
            PriceLevelUpdate.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== "0") {
            writer.uint32(40).sint64(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookLevelUpdates();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 3:
                    message.buys.push(PriceLevelUpdate.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.sells.push(PriceLevelUpdate.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            buys: Array.isArray(object?.buys) ? object.buys.map((e) => PriceLevelUpdate.fromJSON(e)) : [],
            sells: Array.isArray(object?.sells) ? object.sells.map((e) => PriceLevelUpdate.fromJSON(e)) : [],
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        if (message.buys) {
            obj.buys = message.buys.map((e) => e ? PriceLevelUpdate.toJSON(e) : undefined);
        }
        else {
            obj.buys = [];
        }
        if (message.sells) {
            obj.sells = message.sells.map((e) => e ? PriceLevelUpdate.toJSON(e) : undefined);
        }
        else {
            obj.sells = [];
        }
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return OrderbookLevelUpdates.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookLevelUpdates();
        message.marketId = object.marketId ?? "";
        message.sequence = object.sequence ?? "0";
        message.buys = object.buys?.map((e) => PriceLevelUpdate.fromPartial(e)) || [];
        message.sells = object.sells?.map((e) => PriceLevelUpdate.fromPartial(e)) || [];
        message.updatedAt = object.updatedAt ?? "0";
        return message;
    },
};
function createBasePriceLevelUpdate() {
    return { price: "", quantity: "", isActive: false, timestamp: "0" };
}
export const PriceLevelUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.isActive === true) {
            writer.uint32(24).bool(message.isActive);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceLevelUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.isActive = reader.bool();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PriceLevelUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceLevelUpdate();
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.isActive = object.isActive ?? false;
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseOrdersRequest() {
    return {
        marketId: "",
        orderSide: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        includeInactive: false,
        subaccountTotalOrders: false,
        tradeId: "",
        cid: "",
    };
}
export const OrdersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(40).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(48).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(56).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(66).string(v);
        }
        if (message.includeInactive === true) {
            writer.uint32(72).bool(message.includeInactive);
        }
        if (message.subaccountTotalOrders === true) {
            writer.uint32(80).bool(message.subaccountTotalOrders);
        }
        if (message.tradeId !== "") {
            writer.uint32(90).string(message.tradeId);
        }
        if (message.cid !== "") {
            writer.uint32(98).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                case 5:
                    message.limit = reader.sint32();
                    break;
                case 6:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.marketIds.push(reader.string());
                    break;
                case 9:
                    message.includeInactive = reader.bool();
                    break;
                case 10:
                    message.subaccountTotalOrders = reader.bool();
                    break;
                case 11:
                    message.tradeId = reader.string();
                    break;
                case 12:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            includeInactive: isSet(object.includeInactive) ? Boolean(object.includeInactive) : false,
            subaccountTotalOrders: isSet(object.subaccountTotalOrders) ? Boolean(object.subaccountTotalOrders) : false,
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.includeInactive !== undefined && (obj.includeInactive = message.includeInactive);
        message.subaccountTotalOrders !== undefined && (obj.subaccountTotalOrders = message.subaccountTotalOrders);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return OrdersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersRequest();
        message.marketId = object.marketId ?? "";
        message.orderSide = object.orderSide ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.includeInactive = object.includeInactive ?? false;
        message.subaccountTotalOrders = object.subaccountTotalOrders ?? false;
        message.tradeId = object.tradeId ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseOrdersResponse() {
    return { orders: [], paging: undefined };
}
export const OrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            SpotLimitOrder.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(SpotLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => SpotLimitOrder.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? SpotLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return OrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersResponse();
        message.orders = object.orders?.map((e) => SpotLimitOrder.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseSpotLimitOrder() {
    return {
        orderHash: "",
        orderSide: "",
        marketId: "",
        subaccountId: "",
        price: "",
        quantity: "",
        unfilledQuantity: "",
        triggerPrice: "",
        feeRecipient: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        txHash: "",
        cid: "",
    };
}
export const SpotLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.price !== "") {
            writer.uint32(42).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(50).string(message.quantity);
        }
        if (message.unfilledQuantity !== "") {
            writer.uint32(58).string(message.unfilledQuantity);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(66).string(message.triggerPrice);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(74).string(message.feeRecipient);
        }
        if (message.state !== "") {
            writer.uint32(82).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(88).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(96).sint64(message.updatedAt);
        }
        if (message.txHash !== "") {
            writer.uint32(106).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.price = reader.string();
                    break;
                case 6:
                    message.quantity = reader.string();
                    break;
                case 7:
                    message.unfilledQuantity = reader.string();
                    break;
                case 8:
                    message.triggerPrice = reader.string();
                    break;
                case 9:
                    message.feeRecipient = reader.string();
                    break;
                case 10:
                    message.state = reader.string();
                    break;
                case 11:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 12:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.txHash = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            unfilledQuantity: isSet(object.unfilledQuantity) ? String(object.unfilledQuantity) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.unfilledQuantity !== undefined && (obj.unfilledQuantity = message.unfilledQuantity);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SpotLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotLimitOrder();
        message.orderHash = object.orderHash ?? "";
        message.orderSide = object.orderSide ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.unfilledQuantity = object.unfilledQuantity ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBasePaging() {
    return { total: "0", from: 0, to: 0, countBySubaccount: "0", next: [] };
}
export const Paging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== "0") {
            writer.uint32(8).sint64(message.total);
        }
        if (message.from !== 0) {
            writer.uint32(16).sint32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(24).sint32(message.to);
        }
        if (message.countBySubaccount !== "0") {
            writer.uint32(32).sint64(message.countBySubaccount);
        }
        for (const v of message.next) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = longToString(reader.sint64());
                    break;
                case 2:
                    message.from = reader.sint32();
                    break;
                case 3:
                    message.to = reader.sint32();
                    break;
                case 4:
                    message.countBySubaccount = longToString(reader.sint64());
                    break;
                case 5:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? String(object.total) : "0",
            from: isSet(object.from) ? Number(object.from) : 0,
            to: isSet(object.to) ? Number(object.to) : 0,
            countBySubaccount: isSet(object.countBySubaccount) ? String(object.countBySubaccount) : "0",
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = message.total);
        message.from !== undefined && (obj.from = Math.round(message.from));
        message.to !== undefined && (obj.to = Math.round(message.to));
        message.countBySubaccount !== undefined && (obj.countBySubaccount = message.countBySubaccount);
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return Paging.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePaging();
        message.total = object.total ?? "0";
        message.from = object.from ?? 0;
        message.to = object.to ?? 0;
        message.countBySubaccount = object.countBySubaccount ?? "0";
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrdersRequest() {
    return {
        marketId: "",
        orderSide: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        includeInactive: false,
        subaccountTotalOrders: false,
        tradeId: "",
        cid: "",
    };
}
export const StreamOrdersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(40).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(48).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(56).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(66).string(v);
        }
        if (message.includeInactive === true) {
            writer.uint32(72).bool(message.includeInactive);
        }
        if (message.subaccountTotalOrders === true) {
            writer.uint32(80).bool(message.subaccountTotalOrders);
        }
        if (message.tradeId !== "") {
            writer.uint32(90).string(message.tradeId);
        }
        if (message.cid !== "") {
            writer.uint32(98).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                case 5:
                    message.limit = reader.sint32();
                    break;
                case 6:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.marketIds.push(reader.string());
                    break;
                case 9:
                    message.includeInactive = reader.bool();
                    break;
                case 10:
                    message.subaccountTotalOrders = reader.bool();
                    break;
                case 11:
                    message.tradeId = reader.string();
                    break;
                case 12:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            includeInactive: isSet(object.includeInactive) ? Boolean(object.includeInactive) : false,
            subaccountTotalOrders: isSet(object.subaccountTotalOrders) ? Boolean(object.subaccountTotalOrders) : false,
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.includeInactive !== undefined && (obj.includeInactive = message.includeInactive);
        message.subaccountTotalOrders !== undefined && (obj.subaccountTotalOrders = message.subaccountTotalOrders);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return StreamOrdersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersRequest();
        message.marketId = object.marketId ?? "";
        message.orderSide = object.orderSide ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.includeInactive = object.includeInactive ?? false;
        message.subaccountTotalOrders = object.subaccountTotalOrders ?? false;
        message.tradeId = object.tradeId ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseStreamOrdersResponse() {
    return { order: undefined, operationType: "", timestamp: "0" };
}
export const StreamOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.order !== undefined) {
            SpotLimitOrder.encode(message.order, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.order = SpotLimitOrder.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            order: isSet(object.order) ? SpotLimitOrder.fromJSON(object.order) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.order !== undefined && (obj.order = message.order ? SpotLimitOrder.toJSON(message.order) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersResponse();
        message.order = (object.order !== undefined && object.order !== null)
            ? SpotLimitOrder.fromPartial(object.order)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseTradesRequest() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const TradesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return TradesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesRequest();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseTradesResponse() {
    return { trades: [], paging: undefined };
}
export const TradesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.trades) {
            SpotTrade.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trades.push(SpotTrade.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trades: Array.isArray(object?.trades) ? object.trades.map((e) => SpotTrade.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? SpotTrade.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return TradesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesResponse();
        message.trades = object.trades?.map((e) => SpotTrade.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseSpotTrade() {
    return {
        orderHash: "",
        subaccountId: "",
        marketId: "",
        tradeExecutionType: "",
        tradeDirection: "",
        price: undefined,
        fee: "",
        executedAt: "0",
        feeRecipient: "",
        tradeId: "",
        executionSide: "",
        cid: "",
    };
}
export const SpotTrade = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.tradeExecutionType !== "") {
            writer.uint32(34).string(message.tradeExecutionType);
        }
        if (message.tradeDirection !== "") {
            writer.uint32(42).string(message.tradeDirection);
        }
        if (message.price !== undefined) {
            PriceLevel.encode(message.price, writer.uint32(50).fork()).ldelim();
        }
        if (message.fee !== "") {
            writer.uint32(58).string(message.fee);
        }
        if (message.executedAt !== "0") {
            writer.uint32(64).sint64(message.executedAt);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(74).string(message.feeRecipient);
        }
        if (message.tradeId !== "") {
            writer.uint32(82).string(message.tradeId);
        }
        if (message.executionSide !== "") {
            writer.uint32(90).string(message.executionSide);
        }
        if (message.cid !== "") {
            writer.uint32(98).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotTrade();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.tradeExecutionType = reader.string();
                    break;
                case 5:
                    message.tradeDirection = reader.string();
                    break;
                case 6:
                    message.price = PriceLevel.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.fee = reader.string();
                    break;
                case 8:
                    message.executedAt = longToString(reader.sint64());
                    break;
                case 9:
                    message.feeRecipient = reader.string();
                    break;
                case 10:
                    message.tradeId = reader.string();
                    break;
                case 11:
                    message.executionSide = reader.string();
                    break;
                case 12:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            tradeExecutionType: isSet(object.tradeExecutionType) ? String(object.tradeExecutionType) : "",
            tradeDirection: isSet(object.tradeDirection) ? String(object.tradeDirection) : "",
            price: isSet(object.price) ? PriceLevel.fromJSON(object.price) : undefined,
            fee: isSet(object.fee) ? String(object.fee) : "",
            executedAt: isSet(object.executedAt) ? String(object.executedAt) : "0",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.tradeExecutionType !== undefined && (obj.tradeExecutionType = message.tradeExecutionType);
        message.tradeDirection !== undefined && (obj.tradeDirection = message.tradeDirection);
        message.price !== undefined && (obj.price = message.price ? PriceLevel.toJSON(message.price) : undefined);
        message.fee !== undefined && (obj.fee = message.fee);
        message.executedAt !== undefined && (obj.executedAt = message.executedAt);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SpotTrade.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotTrade();
        message.orderHash = object.orderHash ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.tradeExecutionType = object.tradeExecutionType ?? "";
        message.tradeDirection = object.tradeDirection ?? "";
        message.price = (object.price !== undefined && object.price !== null)
            ? PriceLevel.fromPartial(object.price)
            : undefined;
        message.fee = object.fee ?? "";
        message.executedAt = object.executedAt ?? "0";
        message.feeRecipient = object.feeRecipient ?? "";
        message.tradeId = object.tradeId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseStreamTradesRequest() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const StreamTradesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return StreamTradesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesRequest();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseStreamTradesResponse() {
    return { trade: undefined, operationType: "", timestamp: "0" };
}
export const StreamTradesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.trade !== undefined) {
            SpotTrade.encode(message.trade, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trade = SpotTrade.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trade: isSet(object.trade) ? SpotTrade.fromJSON(object.trade) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.trade !== undefined && (obj.trade = message.trade ? SpotTrade.toJSON(message.trade) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamTradesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesResponse();
        message.trade = (object.trade !== undefined && object.trade !== null)
            ? SpotTrade.fromPartial(object.trade)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseTradesV2Request() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const TradesV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return TradesV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesV2Request();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseTradesV2Response() {
    return { trades: [], paging: undefined };
}
export const TradesV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.trades) {
            SpotTrade.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trades.push(SpotTrade.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trades: Array.isArray(object?.trades) ? object.trades.map((e) => SpotTrade.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? SpotTrade.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return TradesV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesV2Response();
        message.trades = object.trades?.map((e) => SpotTrade.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseStreamTradesV2Request() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const StreamTradesV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return StreamTradesV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesV2Request();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseStreamTradesV2Response() {
    return { trade: undefined, operationType: "", timestamp: "0" };
}
export const StreamTradesV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.trade !== undefined) {
            SpotTrade.encode(message.trade, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trade = SpotTrade.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trade: isSet(object.trade) ? SpotTrade.fromJSON(object.trade) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.trade !== undefined && (obj.trade = message.trade ? SpotTrade.toJSON(message.trade) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamTradesV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesV2Response();
        message.trade = (object.trade !== undefined && object.trade !== null)
            ? SpotTrade.fromPartial(object.trade)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSubaccountOrdersListRequest() {
    return { subaccountId: "", marketId: "", skip: "0", limit: 0 };
}
export const SubaccountOrdersListRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrdersListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return SubaccountOrdersListRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrdersListRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseSubaccountOrdersListResponse() {
    return { orders: [], paging: undefined };
}
export const SubaccountOrdersListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            SpotLimitOrder.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrdersListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(SpotLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => SpotLimitOrder.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? SpotLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return SubaccountOrdersListResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrdersListResponse();
        message.orders = object.orders?.map((e) => SpotLimitOrder.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseSubaccountTradesListRequest() {
    return { subaccountId: "", marketId: "", executionType: "", direction: "", skip: "0", limit: 0 };
}
export const SubaccountTradesListRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.executionType !== "") {
            writer.uint32(26).string(message.executionType);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountTradesListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.executionType = reader.string();
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.direction !== undefined && (obj.direction = message.direction);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return SubaccountTradesListRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountTradesListRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.executionType = object.executionType ?? "";
        message.direction = object.direction ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseSubaccountTradesListResponse() {
    return { trades: [] };
}
export const SubaccountTradesListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.trades) {
            SpotTrade.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountTradesListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trades.push(SpotTrade.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { trades: Array.isArray(object?.trades) ? object.trades.map((e) => SpotTrade.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? SpotTrade.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        return obj;
    },
    create(base) {
        return SubaccountTradesListResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountTradesListResponse();
        message.trades = object.trades?.map((e) => SpotTrade.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOrdersHistoryRequest() {
    return {
        subaccountId: "",
        marketId: "",
        skip: "0",
        limit: 0,
        orderTypes: [],
        direction: "",
        startTime: "0",
        endTime: "0",
        state: "",
        executionTypes: [],
        marketIds: [],
        tradeId: "",
        activeMarketsOnly: false,
        cid: "",
    };
}
export const OrdersHistoryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        for (const v of message.orderTypes) {
            writer.uint32(42).string(v);
        }
        if (message.direction !== "") {
            writer.uint32(50).string(message.direction);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        if (message.state !== "") {
            writer.uint32(74).string(message.state);
        }
        for (const v of message.executionTypes) {
            writer.uint32(82).string(v);
        }
        for (const v of message.marketIds) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.activeMarketsOnly === true) {
            writer.uint32(104).bool(message.activeMarketsOnly);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersHistoryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.orderTypes.push(reader.string());
                    break;
                case 6:
                    message.direction = reader.string();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.state = reader.string();
                    break;
                case 10:
                    message.executionTypes.push(reader.string());
                    break;
                case 11:
                    message.marketIds.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.activeMarketsOnly = reader.bool();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            orderTypes: Array.isArray(object?.orderTypes) ? object.orderTypes.map((e) => String(e)) : [],
            direction: isSet(object.direction) ? String(object.direction) : "",
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            state: isSet(object.state) ? String(object.state) : "",
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            activeMarketsOnly: isSet(object.activeMarketsOnly) ? Boolean(object.activeMarketsOnly) : false,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        if (message.orderTypes) {
            obj.orderTypes = message.orderTypes.map((e) => e);
        }
        else {
            obj.orderTypes = [];
        }
        message.direction !== undefined && (obj.direction = message.direction);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        message.state !== undefined && (obj.state = message.state);
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.activeMarketsOnly !== undefined && (obj.activeMarketsOnly = message.activeMarketsOnly);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return OrdersHistoryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersHistoryRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.orderTypes = object.orderTypes?.map((e) => e) || [];
        message.direction = object.direction ?? "";
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.state = object.state ?? "";
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.activeMarketsOnly = object.activeMarketsOnly ?? false;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseOrdersHistoryResponse() {
    return { orders: [], paging: undefined };
}
export const OrdersHistoryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            SpotOrderHistory.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersHistoryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(SpotOrderHistory.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => SpotOrderHistory.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? SpotOrderHistory.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return OrdersHistoryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersHistoryResponse();
        message.orders = object.orders?.map((e) => SpotOrderHistory.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseSpotOrderHistory() {
    return {
        orderHash: "",
        marketId: "",
        isActive: false,
        subaccountId: "",
        executionType: "",
        orderType: "",
        price: "",
        triggerPrice: "",
        quantity: "",
        filledQuantity: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        direction: "",
        txHash: "",
        cid: "",
    };
}
export const SpotOrderHistory = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.isActive === true) {
            writer.uint32(24).bool(message.isActive);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.executionType !== "") {
            writer.uint32(42).string(message.executionType);
        }
        if (message.orderType !== "") {
            writer.uint32(50).string(message.orderType);
        }
        if (message.price !== "") {
            writer.uint32(58).string(message.price);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(66).string(message.triggerPrice);
        }
        if (message.quantity !== "") {
            writer.uint32(74).string(message.quantity);
        }
        if (message.filledQuantity !== "") {
            writer.uint32(82).string(message.filledQuantity);
        }
        if (message.state !== "") {
            writer.uint32(90).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(96).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(104).sint64(message.updatedAt);
        }
        if (message.direction !== "") {
            writer.uint32(114).string(message.direction);
        }
        if (message.txHash !== "") {
            writer.uint32(122).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(130).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotOrderHistory();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.isActive = reader.bool();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.executionType = reader.string();
                    break;
                case 6:
                    message.orderType = reader.string();
                    break;
                case 7:
                    message.price = reader.string();
                    break;
                case 8:
                    message.triggerPrice = reader.string();
                    break;
                case 9:
                    message.quantity = reader.string();
                    break;
                case 10:
                    message.filledQuantity = reader.string();
                    break;
                case 11:
                    message.state = reader.string();
                    break;
                case 12:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.direction = reader.string();
                    break;
                case 15:
                    message.txHash = reader.string();
                    break;
                case 16:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            price: isSet(object.price) ? String(object.price) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            filledQuantity: isSet(object.filledQuantity) ? String(object.filledQuantity) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            direction: isSet(object.direction) ? String(object.direction) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.price !== undefined && (obj.price = message.price);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.filledQuantity !== undefined && (obj.filledQuantity = message.filledQuantity);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.direction !== undefined && (obj.direction = message.direction);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SpotOrderHistory.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotOrderHistory();
        message.orderHash = object.orderHash ?? "";
        message.marketId = object.marketId ?? "";
        message.isActive = object.isActive ?? false;
        message.subaccountId = object.subaccountId ?? "";
        message.executionType = object.executionType ?? "";
        message.orderType = object.orderType ?? "";
        message.price = object.price ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.quantity = object.quantity ?? "";
        message.filledQuantity = object.filledQuantity ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.direction = object.direction ?? "";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseStreamOrdersHistoryRequest() {
    return { subaccountId: "", marketId: "", orderTypes: [], direction: "", state: "", executionTypes: [] };
}
export const StreamOrdersHistoryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        for (const v of message.orderTypes) {
            writer.uint32(26).string(v);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.state !== "") {
            writer.uint32(42).string(message.state);
        }
        for (const v of message.executionTypes) {
            writer.uint32(50).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersHistoryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.orderTypes.push(reader.string());
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.state = reader.string();
                    break;
                case 6:
                    message.executionTypes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderTypes: Array.isArray(object?.orderTypes) ? object.orderTypes.map((e) => String(e)) : [],
            direction: isSet(object.direction) ? String(object.direction) : "",
            state: isSet(object.state) ? String(object.state) : "",
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.orderTypes) {
            obj.orderTypes = message.orderTypes.map((e) => e);
        }
        else {
            obj.orderTypes = [];
        }
        message.direction !== undefined && (obj.direction = message.direction);
        message.state !== undefined && (obj.state = message.state);
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        return obj;
    },
    create(base) {
        return StreamOrdersHistoryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersHistoryRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.orderTypes = object.orderTypes?.map((e) => e) || [];
        message.direction = object.direction ?? "";
        message.state = object.state ?? "";
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrdersHistoryResponse() {
    return { order: undefined, operationType: "", timestamp: "0" };
}
export const StreamOrdersHistoryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.order !== undefined) {
            SpotOrderHistory.encode(message.order, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersHistoryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.order = SpotOrderHistory.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            order: isSet(object.order) ? SpotOrderHistory.fromJSON(object.order) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.order !== undefined && (obj.order = message.order ? SpotOrderHistory.toJSON(message.order) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamOrdersHistoryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersHistoryResponse();
        message.order = (object.order !== undefined && object.order !== null)
            ? SpotOrderHistory.fromPartial(object.order)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseAtomicSwapHistoryRequest() {
    return { address: "", contractAddress: "", skip: 0, limit: 0, fromNumber: 0, toNumber: 0 };
}
export const AtomicSwapHistoryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.contractAddress !== "") {
            writer.uint32(18).string(message.contractAddress);
        }
        if (message.skip !== 0) {
            writer.uint32(24).sint32(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.fromNumber !== 0) {
            writer.uint32(40).sint32(message.fromNumber);
        }
        if (message.toNumber !== 0) {
            writer.uint32(48).sint32(message.toNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAtomicSwapHistoryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.contractAddress = reader.string();
                    break;
                case 3:
                    message.skip = reader.sint32();
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.fromNumber = reader.sint32();
                    break;
                case 6:
                    message.toNumber = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            skip: isSet(object.skip) ? Number(object.skip) : 0,
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            fromNumber: isSet(object.fromNumber) ? Number(object.fromNumber) : 0,
            toNumber: isSet(object.toNumber) ? Number(object.toNumber) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.skip !== undefined && (obj.skip = Math.round(message.skip));
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.fromNumber !== undefined && (obj.fromNumber = Math.round(message.fromNumber));
        message.toNumber !== undefined && (obj.toNumber = Math.round(message.toNumber));
        return obj;
    },
    create(base) {
        return AtomicSwapHistoryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAtomicSwapHistoryRequest();
        message.address = object.address ?? "";
        message.contractAddress = object.contractAddress ?? "";
        message.skip = object.skip ?? 0;
        message.limit = object.limit ?? 0;
        message.fromNumber = object.fromNumber ?? 0;
        message.toNumber = object.toNumber ?? 0;
        return message;
    },
};
function createBaseAtomicSwapHistoryResponse() {
    return { paging: undefined, data: [] };
}
export const AtomicSwapHistoryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            AtomicSwap.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAtomicSwapHistoryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(AtomicSwap.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => AtomicSwap.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? AtomicSwap.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return AtomicSwapHistoryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAtomicSwapHistoryResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => AtomicSwap.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAtomicSwap() {
    return {
        sender: "",
        route: "",
        sourceCoin: undefined,
        destCoin: undefined,
        fees: [],
        contractAddress: "",
        indexBySender: 0,
        indexBySenderContract: 0,
        txHash: "",
        executedAt: "0",
        refundAmount: "",
    };
}
export const AtomicSwap = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.route !== "") {
            writer.uint32(18).string(message.route);
        }
        if (message.sourceCoin !== undefined) {
            Coin.encode(message.sourceCoin, writer.uint32(26).fork()).ldelim();
        }
        if (message.destCoin !== undefined) {
            Coin.encode(message.destCoin, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.fees) {
            Coin.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.contractAddress !== "") {
            writer.uint32(50).string(message.contractAddress);
        }
        if (message.indexBySender !== 0) {
            writer.uint32(56).sint32(message.indexBySender);
        }
        if (message.indexBySenderContract !== 0) {
            writer.uint32(64).sint32(message.indexBySenderContract);
        }
        if (message.txHash !== "") {
            writer.uint32(74).string(message.txHash);
        }
        if (message.executedAt !== "0") {
            writer.uint32(80).sint64(message.executedAt);
        }
        if (message.refundAmount !== "") {
            writer.uint32(90).string(message.refundAmount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAtomicSwap();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.route = reader.string();
                    break;
                case 3:
                    message.sourceCoin = Coin.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.destCoin = Coin.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.fees.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.contractAddress = reader.string();
                    break;
                case 7:
                    message.indexBySender = reader.sint32();
                    break;
                case 8:
                    message.indexBySenderContract = reader.sint32();
                    break;
                case 9:
                    message.txHash = reader.string();
                    break;
                case 10:
                    message.executedAt = longToString(reader.sint64());
                    break;
                case 11:
                    message.refundAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            route: isSet(object.route) ? String(object.route) : "",
            sourceCoin: isSet(object.sourceCoin) ? Coin.fromJSON(object.sourceCoin) : undefined,
            destCoin: isSet(object.destCoin) ? Coin.fromJSON(object.destCoin) : undefined,
            fees: Array.isArray(object?.fees) ? object.fees.map((e) => Coin.fromJSON(e)) : [],
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            indexBySender: isSet(object.indexBySender) ? Number(object.indexBySender) : 0,
            indexBySenderContract: isSet(object.indexBySenderContract) ? Number(object.indexBySenderContract) : 0,
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            executedAt: isSet(object.executedAt) ? String(object.executedAt) : "0",
            refundAmount: isSet(object.refundAmount) ? String(object.refundAmount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.route !== undefined && (obj.route = message.route);
        message.sourceCoin !== undefined &&
            (obj.sourceCoin = message.sourceCoin ? Coin.toJSON(message.sourceCoin) : undefined);
        message.destCoin !== undefined && (obj.destCoin = message.destCoin ? Coin.toJSON(message.destCoin) : undefined);
        if (message.fees) {
            obj.fees = message.fees.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.fees = [];
        }
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.indexBySender !== undefined && (obj.indexBySender = Math.round(message.indexBySender));
        message.indexBySenderContract !== undefined &&
            (obj.indexBySenderContract = Math.round(message.indexBySenderContract));
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.executedAt !== undefined && (obj.executedAt = message.executedAt);
        message.refundAmount !== undefined && (obj.refundAmount = message.refundAmount);
        return obj;
    },
    create(base) {
        return AtomicSwap.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAtomicSwap();
        message.sender = object.sender ?? "";
        message.route = object.route ?? "";
        message.sourceCoin = (object.sourceCoin !== undefined && object.sourceCoin !== null)
            ? Coin.fromPartial(object.sourceCoin)
            : undefined;
        message.destCoin = (object.destCoin !== undefined && object.destCoin !== null)
            ? Coin.fromPartial(object.destCoin)
            : undefined;
        message.fees = object.fees?.map((e) => Coin.fromPartial(e)) || [];
        message.contractAddress = object.contractAddress ?? "";
        message.indexBySender = object.indexBySender ?? 0;
        message.indexBySenderContract = object.indexBySenderContract ?? 0;
        message.txHash = object.txHash ?? "";
        message.executedAt = object.executedAt ?? "0";
        message.refundAmount = object.refundAmount ?? "";
        return message;
    },
};
function createBaseCoin() {
    return { denom: "", amount: "" };
}
export const Coin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return Coin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCoin();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
export class InjectiveSpotExchangeRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Markets = this.Markets.bind(this);
        this.Market = this.Market.bind(this);
        this.StreamMarkets = this.StreamMarkets.bind(this);
        this.OrderbookV2 = this.OrderbookV2.bind(this);
        this.OrderbooksV2 = this.OrderbooksV2.bind(this);
        this.StreamOrderbookV2 = this.StreamOrderbookV2.bind(this);
        this.StreamOrderbookUpdate = this.StreamOrderbookUpdate.bind(this);
        this.Orders = this.Orders.bind(this);
        this.StreamOrders = this.StreamOrders.bind(this);
        this.Trades = this.Trades.bind(this);
        this.StreamTrades = this.StreamTrades.bind(this);
        this.TradesV2 = this.TradesV2.bind(this);
        this.StreamTradesV2 = this.StreamTradesV2.bind(this);
        this.SubaccountOrdersList = this.SubaccountOrdersList.bind(this);
        this.SubaccountTradesList = this.SubaccountTradesList.bind(this);
        this.OrdersHistory = this.OrdersHistory.bind(this);
        this.StreamOrdersHistory = this.StreamOrdersHistory.bind(this);
        this.AtomicSwapHistory = this.AtomicSwapHistory.bind(this);
    }
    Markets(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCMarketsDesc, MarketsRequest.fromPartial(request), metadata);
    }
    Market(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCMarketDesc, MarketRequest.fromPartial(request), metadata);
    }
    StreamMarkets(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamMarketsDesc, StreamMarketsRequest.fromPartial(request), metadata);
    }
    OrderbookV2(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCOrderbookV2Desc, OrderbookV2Request.fromPartial(request), metadata);
    }
    OrderbooksV2(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCOrderbooksV2Desc, OrderbooksV2Request.fromPartial(request), metadata);
    }
    StreamOrderbookV2(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamOrderbookV2Desc, StreamOrderbookV2Request.fromPartial(request), metadata);
    }
    StreamOrderbookUpdate(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamOrderbookUpdateDesc, StreamOrderbookUpdateRequest.fromPartial(request), metadata);
    }
    Orders(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCOrdersDesc, OrdersRequest.fromPartial(request), metadata);
    }
    StreamOrders(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamOrdersDesc, StreamOrdersRequest.fromPartial(request), metadata);
    }
    Trades(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCTradesDesc, TradesRequest.fromPartial(request), metadata);
    }
    StreamTrades(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamTradesDesc, StreamTradesRequest.fromPartial(request), metadata);
    }
    TradesV2(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCTradesV2Desc, TradesV2Request.fromPartial(request), metadata);
    }
    StreamTradesV2(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamTradesV2Desc, StreamTradesV2Request.fromPartial(request), metadata);
    }
    SubaccountOrdersList(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCSubaccountOrdersListDesc, SubaccountOrdersListRequest.fromPartial(request), metadata);
    }
    SubaccountTradesList(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCSubaccountTradesListDesc, SubaccountTradesListRequest.fromPartial(request), metadata);
    }
    OrdersHistory(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCOrdersHistoryDesc, OrdersHistoryRequest.fromPartial(request), metadata);
    }
    StreamOrdersHistory(request, metadata) {
        return this.rpc.invoke(InjectiveSpotExchangeRPCStreamOrdersHistoryDesc, StreamOrdersHistoryRequest.fromPartial(request), metadata);
    }
    AtomicSwapHistory(request, metadata) {
        return this.rpc.unary(InjectiveSpotExchangeRPCAtomicSwapHistoryDesc, AtomicSwapHistoryRequest.fromPartial(request), metadata);
    }
}
export const InjectiveSpotExchangeRPCDesc = { serviceName: "injective_spot_exchange_rpc.InjectiveSpotExchangeRPC" };
export const InjectiveSpotExchangeRPCMarketsDesc = {
    methodName: "Markets",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MarketsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MarketsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCMarketDesc = {
    methodName: "Market",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MarketRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamMarketsDesc = {
    methodName: "StreamMarkets",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamMarketsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamMarketsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCOrderbookV2Desc = {
    methodName: "OrderbookV2",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrderbookV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrderbookV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCOrderbooksV2Desc = {
    methodName: "OrderbooksV2",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrderbooksV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrderbooksV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamOrderbookV2Desc = {
    methodName: "StreamOrderbookV2",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrderbookV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrderbookV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamOrderbookUpdateDesc = {
    methodName: "StreamOrderbookUpdate",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrderbookUpdateRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrderbookUpdateResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCOrdersDesc = {
    methodName: "Orders",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrdersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamOrdersDesc = {
    methodName: "StreamOrders",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrdersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCTradesDesc = {
    methodName: "Trades",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return TradesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = TradesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamTradesDesc = {
    methodName: "StreamTrades",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamTradesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamTradesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCTradesV2Desc = {
    methodName: "TradesV2",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return TradesV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = TradesV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamTradesV2Desc = {
    methodName: "StreamTradesV2",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamTradesV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamTradesV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCSubaccountOrdersListDesc = {
    methodName: "SubaccountOrdersList",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountOrdersListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountOrdersListResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCSubaccountTradesListDesc = {
    methodName: "SubaccountTradesList",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountTradesListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountTradesListResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCOrdersHistoryDesc = {
    methodName: "OrdersHistory",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrdersHistoryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrdersHistoryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCStreamOrdersHistoryDesc = {
    methodName: "StreamOrdersHistory",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrdersHistoryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrdersHistoryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveSpotExchangeRPCAtomicSwapHistoryDesc = {
    methodName: "AtomicSwapHistory",
    service: InjectiveSpotExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return AtomicSwapHistoryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = AtomicSwapHistoryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => {
                    if (!observer.closed) {
                        return client.close();
                    }
                });
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
