/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective_trading_rpc";
function createBaseListTradingStrategiesRequest() {
    return {
        state: "",
        marketId: "",
        subaccountId: "",
        accountAddress: "",
        pendingExecution: false,
        startTime: "0",
        endTime: "0",
        limit: 0,
        skip: "0",
        strategyType: [],
        marketType: "",
        lastExecutedTime: "0",
        withTvl: false,
        isTrailingStrategy: false,
        withPerformance: false,
    };
}
export const ListTradingStrategiesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== "") {
            writer.uint32(10).string(message.state);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(34).string(message.accountAddress);
        }
        if (message.pendingExecution === true) {
            writer.uint32(40).bool(message.pendingExecution);
        }
        if (message.startTime !== "0") {
            writer.uint32(48).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(56).sint64(message.endTime);
        }
        if (message.limit !== 0) {
            writer.uint32(64).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(72).uint64(message.skip);
        }
        for (const v of message.strategyType) {
            writer.uint32(82).string(v);
        }
        if (message.marketType !== "") {
            writer.uint32(90).string(message.marketType);
        }
        if (message.lastExecutedTime !== "0") {
            writer.uint32(96).sint64(message.lastExecutedTime);
        }
        if (message.withTvl === true) {
            writer.uint32(104).bool(message.withTvl);
        }
        if (message.isTrailingStrategy === true) {
            writer.uint32(112).bool(message.isTrailingStrategy);
        }
        if (message.withPerformance === true) {
            writer.uint32(120).bool(message.withPerformance);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListTradingStrategiesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.accountAddress = reader.string();
                    break;
                case 5:
                    message.pendingExecution = reader.bool();
                    break;
                case 6:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.limit = reader.sint32();
                    break;
                case 9:
                    message.skip = longToString(reader.uint64());
                    break;
                case 10:
                    message.strategyType.push(reader.string());
                    break;
                case 11:
                    message.marketType = reader.string();
                    break;
                case 12:
                    message.lastExecutedTime = longToString(reader.sint64());
                    break;
                case 13:
                    message.withTvl = reader.bool();
                    break;
                case 14:
                    message.isTrailingStrategy = reader.bool();
                    break;
                case 15:
                    message.withPerformance = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            state: isSet(object.state) ? String(object.state) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            pendingExecution: isSet(object.pendingExecution) ? Boolean(object.pendingExecution) : false,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            strategyType: Array.isArray(object?.strategyType) ? object.strategyType.map((e) => String(e)) : [],
            marketType: isSet(object.marketType) ? String(object.marketType) : "",
            lastExecutedTime: isSet(object.lastExecutedTime) ? String(object.lastExecutedTime) : "0",
            withTvl: isSet(object.withTvl) ? Boolean(object.withTvl) : false,
            isTrailingStrategy: isSet(object.isTrailingStrategy) ? Boolean(object.isTrailingStrategy) : false,
            withPerformance: isSet(object.withPerformance) ? Boolean(object.withPerformance) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.state !== undefined && (obj.state = message.state);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.pendingExecution !== undefined && (obj.pendingExecution = message.pendingExecution);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        if (message.strategyType) {
            obj.strategyType = message.strategyType.map((e) => e);
        }
        else {
            obj.strategyType = [];
        }
        message.marketType !== undefined && (obj.marketType = message.marketType);
        message.lastExecutedTime !== undefined && (obj.lastExecutedTime = message.lastExecutedTime);
        message.withTvl !== undefined && (obj.withTvl = message.withTvl);
        message.isTrailingStrategy !== undefined && (obj.isTrailingStrategy = message.isTrailingStrategy);
        message.withPerformance !== undefined && (obj.withPerformance = message.withPerformance);
        return obj;
    },
    create(base) {
        return ListTradingStrategiesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListTradingStrategiesRequest();
        message.state = object.state ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.pendingExecution = object.pendingExecution ?? false;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        message.strategyType = object.strategyType?.map((e) => e) || [];
        message.marketType = object.marketType ?? "";
        message.lastExecutedTime = object.lastExecutedTime ?? "0";
        message.withTvl = object.withTvl ?? false;
        message.isTrailingStrategy = object.isTrailingStrategy ?? false;
        message.withPerformance = object.withPerformance ?? false;
        return message;
    },
};
function createBaseListTradingStrategiesResponse() {
    return { strategies: [], paging: undefined };
}
export const ListTradingStrategiesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.strategies) {
            TradingStrategy.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListTradingStrategiesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.strategies.push(TradingStrategy.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            strategies: Array.isArray(object?.strategies)
                ? object.strategies.map((e) => TradingStrategy.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.strategies) {
            obj.strategies = message.strategies.map((e) => e ? TradingStrategy.toJSON(e) : undefined);
        }
        else {
            obj.strategies = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return ListTradingStrategiesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListTradingStrategiesResponse();
        message.strategies = object.strategies?.map((e) => TradingStrategy.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseTradingStrategy() {
    return {
        state: "",
        marketId: "",
        subaccountId: "",
        accountAddress: "",
        contractAddress: "",
        executionPrice: "",
        baseQuantity: "",
        quoteQuantity: "",
        lowerBound: "",
        upperBound: "",
        stopLoss: "",
        takeProfit: "",
        swapFee: "",
        baseDeposit: "",
        quoteDeposit: "",
        marketMidPrice: "",
        subscriptionQuoteQuantity: "",
        subscriptionBaseQuantity: "",
        numberOfGridLevels: "",
        shouldExitWithQuoteOnly: false,
        stopReason: "",
        pendingExecution: false,
        createdHeight: "0",
        removedHeight: "0",
        createdAt: "0",
        updatedAt: "0",
        exitType: "",
        stopLossConfig: undefined,
        takeProfitConfig: undefined,
        strategyType: "",
        contractVersion: "",
        contractName: "",
        marketType: "",
        lastExecutedAt: "0",
        trailUpPrice: "",
        trailDownPrice: "",
        trailUpCounter: "0",
        trailDownCounter: "0",
        tvl: "",
        pnl: "",
        pnlPerc: "",
        pnlUpdatedAt: "0",
        performance: "",
        roi: "",
    };
}
export const TradingStrategy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== "") {
            writer.uint32(10).string(message.state);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(34).string(message.accountAddress);
        }
        if (message.contractAddress !== "") {
            writer.uint32(42).string(message.contractAddress);
        }
        if (message.executionPrice !== "") {
            writer.uint32(50).string(message.executionPrice);
        }
        if (message.baseQuantity !== "") {
            writer.uint32(58).string(message.baseQuantity);
        }
        if (message.quoteQuantity !== "") {
            writer.uint32(162).string(message.quoteQuantity);
        }
        if (message.lowerBound !== "") {
            writer.uint32(66).string(message.lowerBound);
        }
        if (message.upperBound !== "") {
            writer.uint32(74).string(message.upperBound);
        }
        if (message.stopLoss !== "") {
            writer.uint32(82).string(message.stopLoss);
        }
        if (message.takeProfit !== "") {
            writer.uint32(90).string(message.takeProfit);
        }
        if (message.swapFee !== "") {
            writer.uint32(98).string(message.swapFee);
        }
        if (message.baseDeposit !== "") {
            writer.uint32(138).string(message.baseDeposit);
        }
        if (message.quoteDeposit !== "") {
            writer.uint32(146).string(message.quoteDeposit);
        }
        if (message.marketMidPrice !== "") {
            writer.uint32(154).string(message.marketMidPrice);
        }
        if (message.subscriptionQuoteQuantity !== "") {
            writer.uint32(170).string(message.subscriptionQuoteQuantity);
        }
        if (message.subscriptionBaseQuantity !== "") {
            writer.uint32(178).string(message.subscriptionBaseQuantity);
        }
        if (message.numberOfGridLevels !== "") {
            writer.uint32(186).string(message.numberOfGridLevels);
        }
        if (message.shouldExitWithQuoteOnly === true) {
            writer.uint32(192).bool(message.shouldExitWithQuoteOnly);
        }
        if (message.stopReason !== "") {
            writer.uint32(202).string(message.stopReason);
        }
        if (message.pendingExecution === true) {
            writer.uint32(208).bool(message.pendingExecution);
        }
        if (message.createdHeight !== "0") {
            writer.uint32(104).sint64(message.createdHeight);
        }
        if (message.removedHeight !== "0") {
            writer.uint32(112).sint64(message.removedHeight);
        }
        if (message.createdAt !== "0") {
            writer.uint32(120).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(128).sint64(message.updatedAt);
        }
        if (message.exitType !== "") {
            writer.uint32(218).string(message.exitType);
        }
        if (message.stopLossConfig !== undefined) {
            ExitConfig.encode(message.stopLossConfig, writer.uint32(226).fork()).ldelim();
        }
        if (message.takeProfitConfig !== undefined) {
            ExitConfig.encode(message.takeProfitConfig, writer.uint32(234).fork()).ldelim();
        }
        if (message.strategyType !== "") {
            writer.uint32(242).string(message.strategyType);
        }
        if (message.contractVersion !== "") {
            writer.uint32(250).string(message.contractVersion);
        }
        if (message.contractName !== "") {
            writer.uint32(258).string(message.contractName);
        }
        if (message.marketType !== "") {
            writer.uint32(266).string(message.marketType);
        }
        if (message.lastExecutedAt !== "0") {
            writer.uint32(272).sint64(message.lastExecutedAt);
        }
        if (message.trailUpPrice !== "") {
            writer.uint32(282).string(message.trailUpPrice);
        }
        if (message.trailDownPrice !== "") {
            writer.uint32(290).string(message.trailDownPrice);
        }
        if (message.trailUpCounter !== "0") {
            writer.uint32(296).sint64(message.trailUpCounter);
        }
        if (message.trailDownCounter !== "0") {
            writer.uint32(304).sint64(message.trailDownCounter);
        }
        if (message.tvl !== "") {
            writer.uint32(314).string(message.tvl);
        }
        if (message.pnl !== "") {
            writer.uint32(322).string(message.pnl);
        }
        if (message.pnlPerc !== "") {
            writer.uint32(330).string(message.pnlPerc);
        }
        if (message.pnlUpdatedAt !== "0") {
            writer.uint32(336).sint64(message.pnlUpdatedAt);
        }
        if (message.performance !== "") {
            writer.uint32(346).string(message.performance);
        }
        if (message.roi !== "") {
            writer.uint32(354).string(message.roi);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingStrategy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.accountAddress = reader.string();
                    break;
                case 5:
                    message.contractAddress = reader.string();
                    break;
                case 6:
                    message.executionPrice = reader.string();
                    break;
                case 7:
                    message.baseQuantity = reader.string();
                    break;
                case 20:
                    message.quoteQuantity = reader.string();
                    break;
                case 8:
                    message.lowerBound = reader.string();
                    break;
                case 9:
                    message.upperBound = reader.string();
                    break;
                case 10:
                    message.stopLoss = reader.string();
                    break;
                case 11:
                    message.takeProfit = reader.string();
                    break;
                case 12:
                    message.swapFee = reader.string();
                    break;
                case 17:
                    message.baseDeposit = reader.string();
                    break;
                case 18:
                    message.quoteDeposit = reader.string();
                    break;
                case 19:
                    message.marketMidPrice = reader.string();
                    break;
                case 21:
                    message.subscriptionQuoteQuantity = reader.string();
                    break;
                case 22:
                    message.subscriptionBaseQuantity = reader.string();
                    break;
                case 23:
                    message.numberOfGridLevels = reader.string();
                    break;
                case 24:
                    message.shouldExitWithQuoteOnly = reader.bool();
                    break;
                case 25:
                    message.stopReason = reader.string();
                    break;
                case 26:
                    message.pendingExecution = reader.bool();
                    break;
                case 13:
                    message.createdHeight = longToString(reader.sint64());
                    break;
                case 14:
                    message.removedHeight = longToString(reader.sint64());
                    break;
                case 15:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 16:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 27:
                    message.exitType = reader.string();
                    break;
                case 28:
                    message.stopLossConfig = ExitConfig.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.takeProfitConfig = ExitConfig.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.strategyType = reader.string();
                    break;
                case 31:
                    message.contractVersion = reader.string();
                    break;
                case 32:
                    message.contractName = reader.string();
                    break;
                case 33:
                    message.marketType = reader.string();
                    break;
                case 34:
                    message.lastExecutedAt = longToString(reader.sint64());
                    break;
                case 35:
                    message.trailUpPrice = reader.string();
                    break;
                case 36:
                    message.trailDownPrice = reader.string();
                    break;
                case 37:
                    message.trailUpCounter = longToString(reader.sint64());
                    break;
                case 38:
                    message.trailDownCounter = longToString(reader.sint64());
                    break;
                case 39:
                    message.tvl = reader.string();
                    break;
                case 40:
                    message.pnl = reader.string();
                    break;
                case 41:
                    message.pnlPerc = reader.string();
                    break;
                case 42:
                    message.pnlUpdatedAt = longToString(reader.sint64());
                    break;
                case 43:
                    message.performance = reader.string();
                    break;
                case 44:
                    message.roi = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            state: isSet(object.state) ? String(object.state) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            executionPrice: isSet(object.executionPrice) ? String(object.executionPrice) : "",
            baseQuantity: isSet(object.baseQuantity) ? String(object.baseQuantity) : "",
            quoteQuantity: isSet(object.quoteQuantity) ? String(object.quoteQuantity) : "",
            lowerBound: isSet(object.lowerBound) ? String(object.lowerBound) : "",
            upperBound: isSet(object.upperBound) ? String(object.upperBound) : "",
            stopLoss: isSet(object.stopLoss) ? String(object.stopLoss) : "",
            takeProfit: isSet(object.takeProfit) ? String(object.takeProfit) : "",
            swapFee: isSet(object.swapFee) ? String(object.swapFee) : "",
            baseDeposit: isSet(object.baseDeposit) ? String(object.baseDeposit) : "",
            quoteDeposit: isSet(object.quoteDeposit) ? String(object.quoteDeposit) : "",
            marketMidPrice: isSet(object.marketMidPrice) ? String(object.marketMidPrice) : "",
            subscriptionQuoteQuantity: isSet(object.subscriptionQuoteQuantity)
                ? String(object.subscriptionQuoteQuantity)
                : "",
            subscriptionBaseQuantity: isSet(object.subscriptionBaseQuantity) ? String(object.subscriptionBaseQuantity) : "",
            numberOfGridLevels: isSet(object.numberOfGridLevels) ? String(object.numberOfGridLevels) : "",
            shouldExitWithQuoteOnly: isSet(object.shouldExitWithQuoteOnly) ? Boolean(object.shouldExitWithQuoteOnly) : false,
            stopReason: isSet(object.stopReason) ? String(object.stopReason) : "",
            pendingExecution: isSet(object.pendingExecution) ? Boolean(object.pendingExecution) : false,
            createdHeight: isSet(object.createdHeight) ? String(object.createdHeight) : "0",
            removedHeight: isSet(object.removedHeight) ? String(object.removedHeight) : "0",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            exitType: isSet(object.exitType) ? String(object.exitType) : "",
            stopLossConfig: isSet(object.stopLossConfig) ? ExitConfig.fromJSON(object.stopLossConfig) : undefined,
            takeProfitConfig: isSet(object.takeProfitConfig) ? ExitConfig.fromJSON(object.takeProfitConfig) : undefined,
            strategyType: isSet(object.strategyType) ? String(object.strategyType) : "",
            contractVersion: isSet(object.contractVersion) ? String(object.contractVersion) : "",
            contractName: isSet(object.contractName) ? String(object.contractName) : "",
            marketType: isSet(object.marketType) ? String(object.marketType) : "",
            lastExecutedAt: isSet(object.lastExecutedAt) ? String(object.lastExecutedAt) : "0",
            trailUpPrice: isSet(object.trailUpPrice) ? String(object.trailUpPrice) : "",
            trailDownPrice: isSet(object.trailDownPrice) ? String(object.trailDownPrice) : "",
            trailUpCounter: isSet(object.trailUpCounter) ? String(object.trailUpCounter) : "0",
            trailDownCounter: isSet(object.trailDownCounter) ? String(object.trailDownCounter) : "0",
            tvl: isSet(object.tvl) ? String(object.tvl) : "",
            pnl: isSet(object.pnl) ? String(object.pnl) : "",
            pnlPerc: isSet(object.pnlPerc) ? String(object.pnlPerc) : "",
            pnlUpdatedAt: isSet(object.pnlUpdatedAt) ? String(object.pnlUpdatedAt) : "0",
            performance: isSet(object.performance) ? String(object.performance) : "",
            roi: isSet(object.roi) ? String(object.roi) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.state !== undefined && (obj.state = message.state);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.executionPrice !== undefined && (obj.executionPrice = message.executionPrice);
        message.baseQuantity !== undefined && (obj.baseQuantity = message.baseQuantity);
        message.quoteQuantity !== undefined && (obj.quoteQuantity = message.quoteQuantity);
        message.lowerBound !== undefined && (obj.lowerBound = message.lowerBound);
        message.upperBound !== undefined && (obj.upperBound = message.upperBound);
        message.stopLoss !== undefined && (obj.stopLoss = message.stopLoss);
        message.takeProfit !== undefined && (obj.takeProfit = message.takeProfit);
        message.swapFee !== undefined && (obj.swapFee = message.swapFee);
        message.baseDeposit !== undefined && (obj.baseDeposit = message.baseDeposit);
        message.quoteDeposit !== undefined && (obj.quoteDeposit = message.quoteDeposit);
        message.marketMidPrice !== undefined && (obj.marketMidPrice = message.marketMidPrice);
        message.subscriptionQuoteQuantity !== undefined &&
            (obj.subscriptionQuoteQuantity = message.subscriptionQuoteQuantity);
        message.subscriptionBaseQuantity !== undefined && (obj.subscriptionBaseQuantity = message.subscriptionBaseQuantity);
        message.numberOfGridLevels !== undefined && (obj.numberOfGridLevels = message.numberOfGridLevels);
        message.shouldExitWithQuoteOnly !== undefined && (obj.shouldExitWithQuoteOnly = message.shouldExitWithQuoteOnly);
        message.stopReason !== undefined && (obj.stopReason = message.stopReason);
        message.pendingExecution !== undefined && (obj.pendingExecution = message.pendingExecution);
        message.createdHeight !== undefined && (obj.createdHeight = message.createdHeight);
        message.removedHeight !== undefined && (obj.removedHeight = message.removedHeight);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.exitType !== undefined && (obj.exitType = message.exitType);
        message.stopLossConfig !== undefined &&
            (obj.stopLossConfig = message.stopLossConfig ? ExitConfig.toJSON(message.stopLossConfig) : undefined);
        message.takeProfitConfig !== undefined &&
            (obj.takeProfitConfig = message.takeProfitConfig ? ExitConfig.toJSON(message.takeProfitConfig) : undefined);
        message.strategyType !== undefined && (obj.strategyType = message.strategyType);
        message.contractVersion !== undefined && (obj.contractVersion = message.contractVersion);
        message.contractName !== undefined && (obj.contractName = message.contractName);
        message.marketType !== undefined && (obj.marketType = message.marketType);
        message.lastExecutedAt !== undefined && (obj.lastExecutedAt = message.lastExecutedAt);
        message.trailUpPrice !== undefined && (obj.trailUpPrice = message.trailUpPrice);
        message.trailDownPrice !== undefined && (obj.trailDownPrice = message.trailDownPrice);
        message.trailUpCounter !== undefined && (obj.trailUpCounter = message.trailUpCounter);
        message.trailDownCounter !== undefined && (obj.trailDownCounter = message.trailDownCounter);
        message.tvl !== undefined && (obj.tvl = message.tvl);
        message.pnl !== undefined && (obj.pnl = message.pnl);
        message.pnlPerc !== undefined && (obj.pnlPerc = message.pnlPerc);
        message.pnlUpdatedAt !== undefined && (obj.pnlUpdatedAt = message.pnlUpdatedAt);
        message.performance !== undefined && (obj.performance = message.performance);
        message.roi !== undefined && (obj.roi = message.roi);
        return obj;
    },
    create(base) {
        return TradingStrategy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingStrategy();
        message.state = object.state ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.contractAddress = object.contractAddress ?? "";
        message.executionPrice = object.executionPrice ?? "";
        message.baseQuantity = object.baseQuantity ?? "";
        message.quoteQuantity = object.quoteQuantity ?? "";
        message.lowerBound = object.lowerBound ?? "";
        message.upperBound = object.upperBound ?? "";
        message.stopLoss = object.stopLoss ?? "";
        message.takeProfit = object.takeProfit ?? "";
        message.swapFee = object.swapFee ?? "";
        message.baseDeposit = object.baseDeposit ?? "";
        message.quoteDeposit = object.quoteDeposit ?? "";
        message.marketMidPrice = object.marketMidPrice ?? "";
        message.subscriptionQuoteQuantity = object.subscriptionQuoteQuantity ?? "";
        message.subscriptionBaseQuantity = object.subscriptionBaseQuantity ?? "";
        message.numberOfGridLevels = object.numberOfGridLevels ?? "";
        message.shouldExitWithQuoteOnly = object.shouldExitWithQuoteOnly ?? false;
        message.stopReason = object.stopReason ?? "";
        message.pendingExecution = object.pendingExecution ?? false;
        message.createdHeight = object.createdHeight ?? "0";
        message.removedHeight = object.removedHeight ?? "0";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.exitType = object.exitType ?? "";
        message.stopLossConfig = (object.stopLossConfig !== undefined && object.stopLossConfig !== null)
            ? ExitConfig.fromPartial(object.stopLossConfig)
            : undefined;
        message.takeProfitConfig = (object.takeProfitConfig !== undefined && object.takeProfitConfig !== null)
            ? ExitConfig.fromPartial(object.takeProfitConfig)
            : undefined;
        message.strategyType = object.strategyType ?? "";
        message.contractVersion = object.contractVersion ?? "";
        message.contractName = object.contractName ?? "";
        message.marketType = object.marketType ?? "";
        message.lastExecutedAt = object.lastExecutedAt ?? "0";
        message.trailUpPrice = object.trailUpPrice ?? "";
        message.trailDownPrice = object.trailDownPrice ?? "";
        message.trailUpCounter = object.trailUpCounter ?? "0";
        message.trailDownCounter = object.trailDownCounter ?? "0";
        message.tvl = object.tvl ?? "";
        message.pnl = object.pnl ?? "";
        message.pnlPerc = object.pnlPerc ?? "";
        message.pnlUpdatedAt = object.pnlUpdatedAt ?? "0";
        message.performance = object.performance ?? "";
        message.roi = object.roi ?? "";
        return message;
    },
};
function createBaseExitConfig() {
    return { exitType: "", exitPrice: "" };
}
export const ExitConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.exitType !== "") {
            writer.uint32(10).string(message.exitType);
        }
        if (message.exitPrice !== "") {
            writer.uint32(18).string(message.exitPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExitConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.exitType = reader.string();
                    break;
                case 2:
                    message.exitPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            exitType: isSet(object.exitType) ? String(object.exitType) : "",
            exitPrice: isSet(object.exitPrice) ? String(object.exitPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.exitType !== undefined && (obj.exitType = message.exitType);
        message.exitPrice !== undefined && (obj.exitPrice = message.exitPrice);
        return obj;
    },
    create(base) {
        return ExitConfig.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExitConfig();
        message.exitType = object.exitType ?? "";
        message.exitPrice = object.exitPrice ?? "";
        return message;
    },
};
function createBasePaging() {
    return { total: "0", from: 0, to: 0, countBySubaccount: "0", next: [] };
}
export const Paging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== "0") {
            writer.uint32(8).sint64(message.total);
        }
        if (message.from !== 0) {
            writer.uint32(16).sint32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(24).sint32(message.to);
        }
        if (message.countBySubaccount !== "0") {
            writer.uint32(32).sint64(message.countBySubaccount);
        }
        for (const v of message.next) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = longToString(reader.sint64());
                    break;
                case 2:
                    message.from = reader.sint32();
                    break;
                case 3:
                    message.to = reader.sint32();
                    break;
                case 4:
                    message.countBySubaccount = longToString(reader.sint64());
                    break;
                case 5:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? String(object.total) : "0",
            from: isSet(object.from) ? Number(object.from) : 0,
            to: isSet(object.to) ? Number(object.to) : 0,
            countBySubaccount: isSet(object.countBySubaccount) ? String(object.countBySubaccount) : "0",
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = message.total);
        message.from !== undefined && (obj.from = Math.round(message.from));
        message.to !== undefined && (obj.to = Math.round(message.to));
        message.countBySubaccount !== undefined && (obj.countBySubaccount = message.countBySubaccount);
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return Paging.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePaging();
        message.total = object.total ?? "0";
        message.from = object.from ?? 0;
        message.to = object.to ?? 0;
        message.countBySubaccount = object.countBySubaccount ?? "0";
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseGetTradingStatsRequest() {
    return {};
}
export const GetTradingStatsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTradingStatsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return GetTradingStatsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseGetTradingStatsRequest();
        return message;
    },
};
function createBaseGetTradingStatsResponse() {
    return { activeTradingStrategies: "0", totalTradingStrategiesCreated: "0", totalTvl: "", markets: [] };
}
export const GetTradingStatsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.activeTradingStrategies !== "0") {
            writer.uint32(8).uint64(message.activeTradingStrategies);
        }
        if (message.totalTradingStrategiesCreated !== "0") {
            writer.uint32(16).uint64(message.totalTradingStrategiesCreated);
        }
        if (message.totalTvl !== "") {
            writer.uint32(26).string(message.totalTvl);
        }
        for (const v of message.markets) {
            Market.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTradingStatsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.activeTradingStrategies = longToString(reader.uint64());
                    break;
                case 2:
                    message.totalTradingStrategiesCreated = longToString(reader.uint64());
                    break;
                case 3:
                    message.totalTvl = reader.string();
                    break;
                case 4:
                    message.markets.push(Market.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            activeTradingStrategies: isSet(object.activeTradingStrategies) ? String(object.activeTradingStrategies) : "0",
            totalTradingStrategiesCreated: isSet(object.totalTradingStrategiesCreated)
                ? String(object.totalTradingStrategiesCreated)
                : "0",
            totalTvl: isSet(object.totalTvl) ? String(object.totalTvl) : "",
            markets: Array.isArray(object?.markets) ? object.markets.map((e) => Market.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.activeTradingStrategies !== undefined && (obj.activeTradingStrategies = message.activeTradingStrategies);
        message.totalTradingStrategiesCreated !== undefined &&
            (obj.totalTradingStrategiesCreated = message.totalTradingStrategiesCreated);
        message.totalTvl !== undefined && (obj.totalTvl = message.totalTvl);
        if (message.markets) {
            obj.markets = message.markets.map((e) => e ? Market.toJSON(e) : undefined);
        }
        else {
            obj.markets = [];
        }
        return obj;
    },
    create(base) {
        return GetTradingStatsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetTradingStatsResponse();
        message.activeTradingStrategies = object.activeTradingStrategies ?? "0";
        message.totalTradingStrategiesCreated = object.totalTradingStrategiesCreated ?? "0";
        message.totalTvl = object.totalTvl ?? "";
        message.markets = object.markets?.map((e) => Market.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMarket() {
    return { marketId: "", activeTradingStrategies: "0" };
}
export const Market = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.activeTradingStrategies !== "0") {
            writer.uint32(16).uint64(message.activeTradingStrategies);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.activeTradingStrategies = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            activeTradingStrategies: isSet(object.activeTradingStrategies) ? String(object.activeTradingStrategies) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.activeTradingStrategies !== undefined && (obj.activeTradingStrategies = message.activeTradingStrategies);
        return obj;
    },
    create(base) {
        return Market.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarket();
        message.marketId = object.marketId ?? "";
        message.activeTradingStrategies = object.activeTradingStrategies ?? "0";
        return message;
    },
};
export class InjectiveTradingRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.ListTradingStrategies = this.ListTradingStrategies.bind(this);
        this.GetTradingStats = this.GetTradingStats.bind(this);
    }
    ListTradingStrategies(request, metadata) {
        return this.rpc.unary(InjectiveTradingRPCListTradingStrategiesDesc, ListTradingStrategiesRequest.fromPartial(request), metadata);
    }
    GetTradingStats(request, metadata) {
        return this.rpc.unary(InjectiveTradingRPCGetTradingStatsDesc, GetTradingStatsRequest.fromPartial(request), metadata);
    }
}
export const InjectiveTradingRPCDesc = { serviceName: "injective_trading_rpc.InjectiveTradingRPC" };
export const InjectiveTradingRPCListTradingStrategiesDesc = {
    methodName: "ListTradingStrategies",
    service: InjectiveTradingRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return ListTradingStrategiesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = ListTradingStrategiesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveTradingRPCGetTradingStatsDesc = {
    methodName: "GetTradingStats",
    service: InjectiveTradingRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetTradingStatsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetTradingStatsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
