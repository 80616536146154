/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "injective_derivative_exchange_rpc";
function createBaseMarketsRequest() {
    return { marketStatus: "", quoteDenom: "", marketStatuses: [] };
}
export const MarketsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketStatus !== "") {
            writer.uint32(10).string(message.marketStatus);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(18).string(message.quoteDenom);
        }
        for (const v of message.marketStatuses) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketStatus = reader.string();
                    break;
                case 2:
                    message.quoteDenom = reader.string();
                    break;
                case 3:
                    message.marketStatuses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketStatus: isSet(object.marketStatus) ? String(object.marketStatus) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            marketStatuses: Array.isArray(object?.marketStatuses) ? object.marketStatuses.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketStatus !== undefined && (obj.marketStatus = message.marketStatus);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        if (message.marketStatuses) {
            obj.marketStatuses = message.marketStatuses.map((e) => e);
        }
        else {
            obj.marketStatuses = [];
        }
        return obj;
    },
    create(base) {
        return MarketsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketsRequest();
        message.marketStatus = object.marketStatus ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.marketStatuses = object.marketStatuses?.map((e) => e) || [];
        return message;
    },
};
function createBaseMarketsResponse() {
    return { markets: [] };
}
export const MarketsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.markets) {
            DerivativeMarketInfo.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.markets.push(DerivativeMarketInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            markets: Array.isArray(object?.markets) ? object.markets.map((e) => DerivativeMarketInfo.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.markets) {
            obj.markets = message.markets.map((e) => e ? DerivativeMarketInfo.toJSON(e) : undefined);
        }
        else {
            obj.markets = [];
        }
        return obj;
    },
    create(base) {
        return MarketsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketsResponse();
        message.markets = object.markets?.map((e) => DerivativeMarketInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseDerivativeMarketInfo() {
    return {
        marketId: "",
        marketStatus: "",
        ticker: "",
        oracleBase: "",
        oracleQuote: "",
        oracleType: "",
        oracleScaleFactor: 0,
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        quoteDenom: "",
        quoteTokenMeta: undefined,
        makerFeeRate: "",
        takerFeeRate: "",
        serviceProviderFee: "",
        isPerpetual: false,
        minPriceTickSize: "",
        minQuantityTickSize: "",
        perpetualMarketInfo: undefined,
        perpetualMarketFunding: undefined,
        expiryFuturesMarketInfo: undefined,
        minNotional: "",
    };
}
export const DerivativeMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.marketStatus !== "") {
            writer.uint32(18).string(message.marketStatus);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.oracleBase !== "") {
            writer.uint32(34).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(42).string(message.oracleQuote);
        }
        if (message.oracleType !== "") {
            writer.uint32(50).string(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(56).uint32(message.oracleScaleFactor);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(66).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(74).string(message.maintenanceMarginRatio);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(82).string(message.quoteDenom);
        }
        if (message.quoteTokenMeta !== undefined) {
            TokenMeta.encode(message.quoteTokenMeta, writer.uint32(90).fork()).ldelim();
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(98).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(106).string(message.takerFeeRate);
        }
        if (message.serviceProviderFee !== "") {
            writer.uint32(114).string(message.serviceProviderFee);
        }
        if (message.isPerpetual === true) {
            writer.uint32(120).bool(message.isPerpetual);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(130).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(138).string(message.minQuantityTickSize);
        }
        if (message.perpetualMarketInfo !== undefined) {
            PerpetualMarketInfo.encode(message.perpetualMarketInfo, writer.uint32(146).fork()).ldelim();
        }
        if (message.perpetualMarketFunding !== undefined) {
            PerpetualMarketFunding.encode(message.perpetualMarketFunding, writer.uint32(154).fork()).ldelim();
        }
        if (message.expiryFuturesMarketInfo !== undefined) {
            ExpiryFuturesMarketInfo.encode(message.expiryFuturesMarketInfo, writer.uint32(162).fork()).ldelim();
        }
        if (message.minNotional !== "") {
            writer.uint32(170).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.marketStatus = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.oracleBase = reader.string();
                    break;
                case 5:
                    message.oracleQuote = reader.string();
                    break;
                case 6:
                    message.oracleType = reader.string();
                    break;
                case 7:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 8:
                    message.initialMarginRatio = reader.string();
                    break;
                case 9:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 10:
                    message.quoteDenom = reader.string();
                    break;
                case 11:
                    message.quoteTokenMeta = TokenMeta.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.makerFeeRate = reader.string();
                    break;
                case 13:
                    message.takerFeeRate = reader.string();
                    break;
                case 14:
                    message.serviceProviderFee = reader.string();
                    break;
                case 15:
                    message.isPerpetual = reader.bool();
                    break;
                case 16:
                    message.minPriceTickSize = reader.string();
                    break;
                case 17:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 18:
                    message.perpetualMarketInfo = PerpetualMarketInfo.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.perpetualMarketFunding = PerpetualMarketFunding.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.expiryFuturesMarketInfo = ExpiryFuturesMarketInfo.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            marketStatus: isSet(object.marketStatus) ? String(object.marketStatus) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleType: isSet(object.oracleType) ? String(object.oracleType) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            quoteTokenMeta: isSet(object.quoteTokenMeta) ? TokenMeta.fromJSON(object.quoteTokenMeta) : undefined,
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            serviceProviderFee: isSet(object.serviceProviderFee) ? String(object.serviceProviderFee) : "",
            isPerpetual: isSet(object.isPerpetual) ? Boolean(object.isPerpetual) : false,
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            perpetualMarketInfo: isSet(object.perpetualMarketInfo)
                ? PerpetualMarketInfo.fromJSON(object.perpetualMarketInfo)
                : undefined,
            perpetualMarketFunding: isSet(object.perpetualMarketFunding)
                ? PerpetualMarketFunding.fromJSON(object.perpetualMarketFunding)
                : undefined,
            expiryFuturesMarketInfo: isSet(object.expiryFuturesMarketInfo)
                ? ExpiryFuturesMarketInfo.fromJSON(object.expiryFuturesMarketInfo)
                : undefined,
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.marketStatus !== undefined && (obj.marketStatus = message.marketStatus);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleType !== undefined && (obj.oracleType = message.oracleType);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.quoteTokenMeta !== undefined &&
            (obj.quoteTokenMeta = message.quoteTokenMeta ? TokenMeta.toJSON(message.quoteTokenMeta) : undefined);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.serviceProviderFee !== undefined && (obj.serviceProviderFee = message.serviceProviderFee);
        message.isPerpetual !== undefined && (obj.isPerpetual = message.isPerpetual);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.perpetualMarketInfo !== undefined && (obj.perpetualMarketInfo = message.perpetualMarketInfo
            ? PerpetualMarketInfo.toJSON(message.perpetualMarketInfo)
            : undefined);
        message.perpetualMarketFunding !== undefined && (obj.perpetualMarketFunding = message.perpetualMarketFunding
            ? PerpetualMarketFunding.toJSON(message.perpetualMarketFunding)
            : undefined);
        message.expiryFuturesMarketInfo !== undefined && (obj.expiryFuturesMarketInfo = message.expiryFuturesMarketInfo
            ? ExpiryFuturesMarketInfo.toJSON(message.expiryFuturesMarketInfo)
            : undefined);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return DerivativeMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarketInfo();
        message.marketId = object.marketId ?? "";
        message.marketStatus = object.marketStatus ?? "";
        message.ticker = object.ticker ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleType = object.oracleType ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.quoteTokenMeta = (object.quoteTokenMeta !== undefined && object.quoteTokenMeta !== null)
            ? TokenMeta.fromPartial(object.quoteTokenMeta)
            : undefined;
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.serviceProviderFee = object.serviceProviderFee ?? "";
        message.isPerpetual = object.isPerpetual ?? false;
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.perpetualMarketInfo = (object.perpetualMarketInfo !== undefined && object.perpetualMarketInfo !== null)
            ? PerpetualMarketInfo.fromPartial(object.perpetualMarketInfo)
            : undefined;
        message.perpetualMarketFunding =
            (object.perpetualMarketFunding !== undefined && object.perpetualMarketFunding !== null)
                ? PerpetualMarketFunding.fromPartial(object.perpetualMarketFunding)
                : undefined;
        message.expiryFuturesMarketInfo =
            (object.expiryFuturesMarketInfo !== undefined && object.expiryFuturesMarketInfo !== null)
                ? ExpiryFuturesMarketInfo.fromPartial(object.expiryFuturesMarketInfo)
                : undefined;
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBaseTokenMeta() {
    return { name: "", address: "", symbol: "", logo: "", decimals: 0, updatedAt: "0" };
}
export const TokenMeta = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        if (message.symbol !== "") {
            writer.uint32(26).string(message.symbol);
        }
        if (message.logo !== "") {
            writer.uint32(34).string(message.logo);
        }
        if (message.decimals !== 0) {
            writer.uint32(40).sint32(message.decimals);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(48).sint64(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenMeta();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                case 3:
                    message.symbol = reader.string();
                    break;
                case 4:
                    message.logo = reader.string();
                    break;
                case 5:
                    message.decimals = reader.sint32();
                    break;
                case 6:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            address: isSet(object.address) ? String(object.address) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            logo: isSet(object.logo) ? String(object.logo) : "",
            decimals: isSet(object.decimals) ? Number(object.decimals) : 0,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.address !== undefined && (obj.address = message.address);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.logo !== undefined && (obj.logo = message.logo);
        message.decimals !== undefined && (obj.decimals = Math.round(message.decimals));
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return TokenMeta.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTokenMeta();
        message.name = object.name ?? "";
        message.address = object.address ?? "";
        message.symbol = object.symbol ?? "";
        message.logo = object.logo ?? "";
        message.decimals = object.decimals ?? 0;
        message.updatedAt = object.updatedAt ?? "0";
        return message;
    },
};
function createBasePerpetualMarketInfo() {
    return { hourlyFundingRateCap: "", hourlyInterestRate: "", nextFundingTimestamp: "0", fundingInterval: "0" };
}
export const PerpetualMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.hourlyFundingRateCap !== "") {
            writer.uint32(10).string(message.hourlyFundingRateCap);
        }
        if (message.hourlyInterestRate !== "") {
            writer.uint32(18).string(message.hourlyInterestRate);
        }
        if (message.nextFundingTimestamp !== "0") {
            writer.uint32(24).sint64(message.nextFundingTimestamp);
        }
        if (message.fundingInterval !== "0") {
            writer.uint32(32).sint64(message.fundingInterval);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerpetualMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hourlyFundingRateCap = reader.string();
                    break;
                case 2:
                    message.hourlyInterestRate = reader.string();
                    break;
                case 3:
                    message.nextFundingTimestamp = longToString(reader.sint64());
                    break;
                case 4:
                    message.fundingInterval = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            hourlyFundingRateCap: isSet(object.hourlyFundingRateCap) ? String(object.hourlyFundingRateCap) : "",
            hourlyInterestRate: isSet(object.hourlyInterestRate) ? String(object.hourlyInterestRate) : "",
            nextFundingTimestamp: isSet(object.nextFundingTimestamp) ? String(object.nextFundingTimestamp) : "0",
            fundingInterval: isSet(object.fundingInterval) ? String(object.fundingInterval) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.hourlyFundingRateCap !== undefined && (obj.hourlyFundingRateCap = message.hourlyFundingRateCap);
        message.hourlyInterestRate !== undefined && (obj.hourlyInterestRate = message.hourlyInterestRate);
        message.nextFundingTimestamp !== undefined && (obj.nextFundingTimestamp = message.nextFundingTimestamp);
        message.fundingInterval !== undefined && (obj.fundingInterval = message.fundingInterval);
        return obj;
    },
    create(base) {
        return PerpetualMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePerpetualMarketInfo();
        message.hourlyFundingRateCap = object.hourlyFundingRateCap ?? "";
        message.hourlyInterestRate = object.hourlyInterestRate ?? "";
        message.nextFundingTimestamp = object.nextFundingTimestamp ?? "0";
        message.fundingInterval = object.fundingInterval ?? "0";
        return message;
    },
};
function createBasePerpetualMarketFunding() {
    return { cumulativeFunding: "", cumulativePrice: "", lastTimestamp: "0" };
}
export const PerpetualMarketFunding = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cumulativeFunding !== "") {
            writer.uint32(10).string(message.cumulativeFunding);
        }
        if (message.cumulativePrice !== "") {
            writer.uint32(18).string(message.cumulativePrice);
        }
        if (message.lastTimestamp !== "0") {
            writer.uint32(24).sint64(message.lastTimestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerpetualMarketFunding();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cumulativeFunding = reader.string();
                    break;
                case 2:
                    message.cumulativePrice = reader.string();
                    break;
                case 3:
                    message.lastTimestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cumulativeFunding: isSet(object.cumulativeFunding) ? String(object.cumulativeFunding) : "",
            cumulativePrice: isSet(object.cumulativePrice) ? String(object.cumulativePrice) : "",
            lastTimestamp: isSet(object.lastTimestamp) ? String(object.lastTimestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.cumulativeFunding !== undefined && (obj.cumulativeFunding = message.cumulativeFunding);
        message.cumulativePrice !== undefined && (obj.cumulativePrice = message.cumulativePrice);
        message.lastTimestamp !== undefined && (obj.lastTimestamp = message.lastTimestamp);
        return obj;
    },
    create(base) {
        return PerpetualMarketFunding.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePerpetualMarketFunding();
        message.cumulativeFunding = object.cumulativeFunding ?? "";
        message.cumulativePrice = object.cumulativePrice ?? "";
        message.lastTimestamp = object.lastTimestamp ?? "0";
        return message;
    },
};
function createBaseExpiryFuturesMarketInfo() {
    return { expirationTimestamp: "0", settlementPrice: "" };
}
export const ExpiryFuturesMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.expirationTimestamp !== "0") {
            writer.uint32(8).sint64(message.expirationTimestamp);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(18).string(message.settlementPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExpiryFuturesMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.expirationTimestamp = longToString(reader.sint64());
                    break;
                case 2:
                    message.settlementPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        return obj;
    },
    create(base) {
        return ExpiryFuturesMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExpiryFuturesMarketInfo();
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementPrice = object.settlementPrice ?? "";
        return message;
    },
};
function createBaseMarketRequest() {
    return { marketId: "" };
}
export const MarketRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketId: isSet(object.marketId) ? String(object.marketId) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return MarketRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketRequest();
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseMarketResponse() {
    return { market: undefined };
}
export const MarketResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            DerivativeMarketInfo.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = DerivativeMarketInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { market: isSet(object.market) ? DerivativeMarketInfo.fromJSON(object.market) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined &&
            (obj.market = message.market ? DerivativeMarketInfo.toJSON(message.market) : undefined);
        return obj;
    },
    create(base) {
        return MarketResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketResponse();
        message.market = (object.market !== undefined && object.market !== null)
            ? DerivativeMarketInfo.fromPartial(object.market)
            : undefined;
        return message;
    },
};
function createBaseStreamMarketRequest() {
    return { marketIds: [] };
}
export const StreamMarketRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamMarketRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamMarketRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamMarketRequest();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamMarketResponse() {
    return { market: undefined, operationType: "", timestamp: "0" };
}
export const StreamMarketResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            DerivativeMarketInfo.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = DerivativeMarketInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            market: isSet(object.market) ? DerivativeMarketInfo.fromJSON(object.market) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined &&
            (obj.market = message.market ? DerivativeMarketInfo.toJSON(message.market) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamMarketResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamMarketResponse();
        message.market = (object.market !== undefined && object.market !== null)
            ? DerivativeMarketInfo.fromPartial(object.market)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseBinaryOptionsMarketsRequest() {
    return { marketStatus: "", quoteDenom: "", skip: "0", limit: 0 };
}
export const BinaryOptionsMarketsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketStatus !== "") {
            writer.uint32(10).string(message.marketStatus);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(18).string(message.quoteDenom);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketStatus = reader.string();
                    break;
                case 2:
                    message.quoteDenom = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketStatus: isSet(object.marketStatus) ? String(object.marketStatus) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketStatus !== undefined && (obj.marketStatus = message.marketStatus);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketsRequest();
        message.marketStatus = object.marketStatus ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseBinaryOptionsMarketsResponse() {
    return { markets: [], paging: undefined };
}
export const BinaryOptionsMarketsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.markets) {
            BinaryOptionsMarketInfo.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.markets.push(BinaryOptionsMarketInfo.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            markets: Array.isArray(object?.markets)
                ? object.markets.map((e) => BinaryOptionsMarketInfo.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.markets) {
            obj.markets = message.markets.map((e) => e ? BinaryOptionsMarketInfo.toJSON(e) : undefined);
        }
        else {
            obj.markets = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketsResponse();
        message.markets = object.markets?.map((e) => BinaryOptionsMarketInfo.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseBinaryOptionsMarketInfo() {
    return {
        marketId: "",
        marketStatus: "",
        ticker: "",
        oracleSymbol: "",
        oracleProvider: "",
        oracleType: "",
        oracleScaleFactor: 0,
        expirationTimestamp: "0",
        settlementTimestamp: "0",
        quoteDenom: "",
        quoteTokenMeta: undefined,
        makerFeeRate: "",
        takerFeeRate: "",
        serviceProviderFee: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        settlementPrice: "",
        minNotional: "",
    };
}
export const BinaryOptionsMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.marketStatus !== "") {
            writer.uint32(18).string(message.marketStatus);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.oracleSymbol !== "") {
            writer.uint32(34).string(message.oracleSymbol);
        }
        if (message.oracleProvider !== "") {
            writer.uint32(42).string(message.oracleProvider);
        }
        if (message.oracleType !== "") {
            writer.uint32(50).string(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(56).uint32(message.oracleScaleFactor);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(64).sint64(message.expirationTimestamp);
        }
        if (message.settlementTimestamp !== "0") {
            writer.uint32(72).sint64(message.settlementTimestamp);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(82).string(message.quoteDenom);
        }
        if (message.quoteTokenMeta !== undefined) {
            TokenMeta.encode(message.quoteTokenMeta, writer.uint32(90).fork()).ldelim();
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(98).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(106).string(message.takerFeeRate);
        }
        if (message.serviceProviderFee !== "") {
            writer.uint32(114).string(message.serviceProviderFee);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(122).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(130).string(message.minQuantityTickSize);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(138).string(message.settlementPrice);
        }
        if (message.minNotional !== "") {
            writer.uint32(146).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.marketStatus = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.oracleSymbol = reader.string();
                    break;
                case 5:
                    message.oracleProvider = reader.string();
                    break;
                case 6:
                    message.oracleType = reader.string();
                    break;
                case 7:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 8:
                    message.expirationTimestamp = longToString(reader.sint64());
                    break;
                case 9:
                    message.settlementTimestamp = longToString(reader.sint64());
                    break;
                case 10:
                    message.quoteDenom = reader.string();
                    break;
                case 11:
                    message.quoteTokenMeta = TokenMeta.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.makerFeeRate = reader.string();
                    break;
                case 13:
                    message.takerFeeRate = reader.string();
                    break;
                case 14:
                    message.serviceProviderFee = reader.string();
                    break;
                case 15:
                    message.minPriceTickSize = reader.string();
                    break;
                case 16:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 17:
                    message.settlementPrice = reader.string();
                    break;
                case 18:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            marketStatus: isSet(object.marketStatus) ? String(object.marketStatus) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            oracleSymbol: isSet(object.oracleSymbol) ? String(object.oracleSymbol) : "",
            oracleProvider: isSet(object.oracleProvider) ? String(object.oracleProvider) : "",
            oracleType: isSet(object.oracleType) ? String(object.oracleType) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementTimestamp: isSet(object.settlementTimestamp) ? String(object.settlementTimestamp) : "0",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            quoteTokenMeta: isSet(object.quoteTokenMeta) ? TokenMeta.fromJSON(object.quoteTokenMeta) : undefined,
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            serviceProviderFee: isSet(object.serviceProviderFee) ? String(object.serviceProviderFee) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.marketStatus !== undefined && (obj.marketStatus = message.marketStatus);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.oracleSymbol !== undefined && (obj.oracleSymbol = message.oracleSymbol);
        message.oracleProvider !== undefined && (obj.oracleProvider = message.oracleProvider);
        message.oracleType !== undefined && (obj.oracleType = message.oracleType);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementTimestamp !== undefined && (obj.settlementTimestamp = message.settlementTimestamp);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.quoteTokenMeta !== undefined &&
            (obj.quoteTokenMeta = message.quoteTokenMeta ? TokenMeta.toJSON(message.quoteTokenMeta) : undefined);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.serviceProviderFee !== undefined && (obj.serviceProviderFee = message.serviceProviderFee);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketInfo();
        message.marketId = object.marketId ?? "";
        message.marketStatus = object.marketStatus ?? "";
        message.ticker = object.ticker ?? "";
        message.oracleSymbol = object.oracleSymbol ?? "";
        message.oracleProvider = object.oracleProvider ?? "";
        message.oracleType = object.oracleType ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementTimestamp = object.settlementTimestamp ?? "0";
        message.quoteDenom = object.quoteDenom ?? "";
        message.quoteTokenMeta = (object.quoteTokenMeta !== undefined && object.quoteTokenMeta !== null)
            ? TokenMeta.fromPartial(object.quoteTokenMeta)
            : undefined;
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.serviceProviderFee = object.serviceProviderFee ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.settlementPrice = object.settlementPrice ?? "";
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBasePaging() {
    return { total: "0", from: 0, to: 0, countBySubaccount: "0", next: [] };
}
export const Paging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== "0") {
            writer.uint32(8).sint64(message.total);
        }
        if (message.from !== 0) {
            writer.uint32(16).sint32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(24).sint32(message.to);
        }
        if (message.countBySubaccount !== "0") {
            writer.uint32(32).sint64(message.countBySubaccount);
        }
        for (const v of message.next) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = longToString(reader.sint64());
                    break;
                case 2:
                    message.from = reader.sint32();
                    break;
                case 3:
                    message.to = reader.sint32();
                    break;
                case 4:
                    message.countBySubaccount = longToString(reader.sint64());
                    break;
                case 5:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? String(object.total) : "0",
            from: isSet(object.from) ? Number(object.from) : 0,
            to: isSet(object.to) ? Number(object.to) : 0,
            countBySubaccount: isSet(object.countBySubaccount) ? String(object.countBySubaccount) : "0",
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = message.total);
        message.from !== undefined && (obj.from = Math.round(message.from));
        message.to !== undefined && (obj.to = Math.round(message.to));
        message.countBySubaccount !== undefined && (obj.countBySubaccount = message.countBySubaccount);
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return Paging.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePaging();
        message.total = object.total ?? "0";
        message.from = object.from ?? 0;
        message.to = object.to ?? 0;
        message.countBySubaccount = object.countBySubaccount ?? "0";
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseBinaryOptionsMarketRequest() {
    return { marketId: "" };
}
export const BinaryOptionsMarketRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketId: isSet(object.marketId) ? String(object.marketId) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketRequest();
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseBinaryOptionsMarketResponse() {
    return { market: undefined };
}
export const BinaryOptionsMarketResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            BinaryOptionsMarketInfo.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = BinaryOptionsMarketInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { market: isSet(object.market) ? BinaryOptionsMarketInfo.fromJSON(object.market) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined &&
            (obj.market = message.market ? BinaryOptionsMarketInfo.toJSON(message.market) : undefined);
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketResponse();
        message.market = (object.market !== undefined && object.market !== null)
            ? BinaryOptionsMarketInfo.fromPartial(object.market)
            : undefined;
        return message;
    },
};
function createBaseOrderbookV2Request() {
    return { marketId: "" };
}
export const OrderbookV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketId: isSet(object.marketId) ? String(object.marketId) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return OrderbookV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookV2Request();
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseOrderbookV2Response() {
    return { orderbook: undefined };
}
export const OrderbookV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderbook !== undefined) {
            DerivativeLimitOrderbookV2.encode(message.orderbook, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbook = DerivativeLimitOrderbookV2.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { orderbook: isSet(object.orderbook) ? DerivativeLimitOrderbookV2.fromJSON(object.orderbook) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? DerivativeLimitOrderbookV2.toJSON(message.orderbook) : undefined);
        return obj;
    },
    create(base) {
        return OrderbookV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookV2Response();
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? DerivativeLimitOrderbookV2.fromPartial(object.orderbook)
            : undefined;
        return message;
    },
};
function createBaseDerivativeLimitOrderbookV2() {
    return { buys: [], sells: [], sequence: "0", timestamp: "0" };
}
export const DerivativeLimitOrderbookV2 = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.buys) {
            PriceLevel.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.sells) {
            PriceLevel.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeLimitOrderbookV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.buys.push(PriceLevel.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.sells.push(PriceLevel.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            buys: Array.isArray(object?.buys) ? object.buys.map((e) => PriceLevel.fromJSON(e)) : [],
            sells: Array.isArray(object?.sells) ? object.sells.map((e) => PriceLevel.fromJSON(e)) : [],
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.buys) {
            obj.buys = message.buys.map((e) => e ? PriceLevel.toJSON(e) : undefined);
        }
        else {
            obj.buys = [];
        }
        if (message.sells) {
            obj.sells = message.sells.map((e) => e ? PriceLevel.toJSON(e) : undefined);
        }
        else {
            obj.sells = [];
        }
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return DerivativeLimitOrderbookV2.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeLimitOrderbookV2();
        message.buys = object.buys?.map((e) => PriceLevel.fromPartial(e)) || [];
        message.sells = object.sells?.map((e) => PriceLevel.fromPartial(e)) || [];
        message.sequence = object.sequence ?? "0";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBasePriceLevel() {
    return { price: "", quantity: "", timestamp: "0" };
}
export const PriceLevel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceLevel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PriceLevel.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceLevel();
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseOrderbooksV2Request() {
    return { marketIds: [] };
}
export const OrderbooksV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbooksV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return OrderbooksV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbooksV2Request();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseOrderbooksV2Response() {
    return { orderbooks: [] };
}
export const OrderbooksV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orderbooks) {
            SingleDerivativeLimitOrderbookV2.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbooksV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbooks.push(SingleDerivativeLimitOrderbookV2.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderbooks: Array.isArray(object?.orderbooks)
                ? object.orderbooks.map((e) => SingleDerivativeLimitOrderbookV2.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orderbooks) {
            obj.orderbooks = message.orderbooks.map((e) => e ? SingleDerivativeLimitOrderbookV2.toJSON(e) : undefined);
        }
        else {
            obj.orderbooks = [];
        }
        return obj;
    },
    create(base) {
        return OrderbooksV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbooksV2Response();
        message.orderbooks = object.orderbooks?.map((e) => SingleDerivativeLimitOrderbookV2.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSingleDerivativeLimitOrderbookV2() {
    return { marketId: "", orderbook: undefined };
}
export const SingleDerivativeLimitOrderbookV2 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderbook !== undefined) {
            DerivativeLimitOrderbookV2.encode(message.orderbook, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSingleDerivativeLimitOrderbookV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderbook = DerivativeLimitOrderbookV2.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderbook: isSet(object.orderbook) ? DerivativeLimitOrderbookV2.fromJSON(object.orderbook) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? DerivativeLimitOrderbookV2.toJSON(message.orderbook) : undefined);
        return obj;
    },
    create(base) {
        return SingleDerivativeLimitOrderbookV2.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSingleDerivativeLimitOrderbookV2();
        message.marketId = object.marketId ?? "";
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? DerivativeLimitOrderbookV2.fromPartial(object.orderbook)
            : undefined;
        return message;
    },
};
function createBaseStreamOrderbookV2Request() {
    return { marketIds: [] };
}
export const StreamOrderbookV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamOrderbookV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookV2Request();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrderbookV2Response() {
    return { orderbook: undefined, operationType: "", timestamp: "0", marketId: "" };
}
export const StreamOrderbookV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderbook !== undefined) {
            DerivativeLimitOrderbookV2.encode(message.orderbook, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        if (message.marketId !== "") {
            writer.uint32(34).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbook = DerivativeLimitOrderbookV2.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                case 4:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderbook: isSet(object.orderbook) ? DerivativeLimitOrderbookV2.fromJSON(object.orderbook) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? DerivativeLimitOrderbookV2.toJSON(message.orderbook) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return StreamOrderbookV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookV2Response();
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? DerivativeLimitOrderbookV2.fromPartial(object.orderbook)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseStreamOrderbookUpdateRequest() {
    return { marketIds: [] };
}
export const StreamOrderbookUpdateRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookUpdateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamOrderbookUpdateRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookUpdateRequest();
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrderbookUpdateResponse() {
    return { orderbookLevelUpdates: undefined, operationType: "", timestamp: "0", marketId: "" };
}
export const StreamOrderbookUpdateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderbookLevelUpdates !== undefined) {
            OrderbookLevelUpdates.encode(message.orderbookLevelUpdates, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        if (message.marketId !== "") {
            writer.uint32(34).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrderbookUpdateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderbookLevelUpdates = OrderbookLevelUpdates.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                case 4:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderbookLevelUpdates: isSet(object.orderbookLevelUpdates)
                ? OrderbookLevelUpdates.fromJSON(object.orderbookLevelUpdates)
                : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderbookLevelUpdates !== undefined && (obj.orderbookLevelUpdates = message.orderbookLevelUpdates
            ? OrderbookLevelUpdates.toJSON(message.orderbookLevelUpdates)
            : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return StreamOrderbookUpdateResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrderbookUpdateResponse();
        message.orderbookLevelUpdates =
            (object.orderbookLevelUpdates !== undefined && object.orderbookLevelUpdates !== null)
                ? OrderbookLevelUpdates.fromPartial(object.orderbookLevelUpdates)
                : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseOrderbookLevelUpdates() {
    return { marketId: "", sequence: "0", buys: [], sells: [], updatedAt: "0" };
}
export const OrderbookLevelUpdates = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.sequence !== "0") {
            writer.uint32(16).uint64(message.sequence);
        }
        for (const v of message.buys) {
            PriceLevelUpdate.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.sells) {
            PriceLevelUpdate.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.updatedAt !== "0") {
            writer.uint32(40).sint64(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookLevelUpdates();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 3:
                    message.buys.push(PriceLevelUpdate.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.sells.push(PriceLevelUpdate.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            buys: Array.isArray(object?.buys) ? object.buys.map((e) => PriceLevelUpdate.fromJSON(e)) : [],
            sells: Array.isArray(object?.sells) ? object.sells.map((e) => PriceLevelUpdate.fromJSON(e)) : [],
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        if (message.buys) {
            obj.buys = message.buys.map((e) => e ? PriceLevelUpdate.toJSON(e) : undefined);
        }
        else {
            obj.buys = [];
        }
        if (message.sells) {
            obj.sells = message.sells.map((e) => e ? PriceLevelUpdate.toJSON(e) : undefined);
        }
        else {
            obj.sells = [];
        }
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return OrderbookLevelUpdates.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookLevelUpdates();
        message.marketId = object.marketId ?? "";
        message.sequence = object.sequence ?? "0";
        message.buys = object.buys?.map((e) => PriceLevelUpdate.fromPartial(e)) || [];
        message.sells = object.sells?.map((e) => PriceLevelUpdate.fromPartial(e)) || [];
        message.updatedAt = object.updatedAt ?? "0";
        return message;
    },
};
function createBasePriceLevelUpdate() {
    return { price: "", quantity: "", isActive: false, timestamp: "0" };
}
export const PriceLevelUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.isActive === true) {
            writer.uint32(24).bool(message.isActive);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceLevelUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.isActive = reader.bool();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PriceLevelUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceLevelUpdate();
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.isActive = object.isActive ?? false;
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseOrdersRequest() {
    return {
        marketId: "",
        orderSide: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        isConditional: "",
        orderType: "",
        includeInactive: false,
        subaccountTotalOrders: false,
        tradeId: "",
        cid: "",
    };
}
export const OrdersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(40).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(48).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(56).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(66).string(v);
        }
        if (message.isConditional !== "") {
            writer.uint32(74).string(message.isConditional);
        }
        if (message.orderType !== "") {
            writer.uint32(82).string(message.orderType);
        }
        if (message.includeInactive === true) {
            writer.uint32(88).bool(message.includeInactive);
        }
        if (message.subaccountTotalOrders === true) {
            writer.uint32(96).bool(message.subaccountTotalOrders);
        }
        if (message.tradeId !== "") {
            writer.uint32(106).string(message.tradeId);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                case 5:
                    message.limit = reader.sint32();
                    break;
                case 6:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.marketIds.push(reader.string());
                    break;
                case 9:
                    message.isConditional = reader.string();
                    break;
                case 10:
                    message.orderType = reader.string();
                    break;
                case 11:
                    message.includeInactive = reader.bool();
                    break;
                case 12:
                    message.subaccountTotalOrders = reader.bool();
                    break;
                case 13:
                    message.tradeId = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            isConditional: isSet(object.isConditional) ? String(object.isConditional) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            includeInactive: isSet(object.includeInactive) ? Boolean(object.includeInactive) : false,
            subaccountTotalOrders: isSet(object.subaccountTotalOrders) ? Boolean(object.subaccountTotalOrders) : false,
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.includeInactive !== undefined && (obj.includeInactive = message.includeInactive);
        message.subaccountTotalOrders !== undefined && (obj.subaccountTotalOrders = message.subaccountTotalOrders);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return OrdersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersRequest();
        message.marketId = object.marketId ?? "";
        message.orderSide = object.orderSide ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.isConditional = object.isConditional ?? "";
        message.orderType = object.orderType ?? "";
        message.includeInactive = object.includeInactive ?? false;
        message.subaccountTotalOrders = object.subaccountTotalOrders ?? false;
        message.tradeId = object.tradeId ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseOrdersResponse() {
    return { orders: [], paging: undefined };
}
export const OrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            DerivativeLimitOrder.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => DerivativeLimitOrder.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return OrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersResponse();
        message.orders = object.orders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseDerivativeLimitOrder() {
    return {
        orderHash: "",
        orderSide: "",
        marketId: "",
        subaccountId: "",
        isReduceOnly: false,
        margin: "",
        price: "",
        quantity: "",
        unfilledQuantity: "",
        triggerPrice: "",
        feeRecipient: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        orderNumber: "0",
        orderType: "",
        isConditional: false,
        triggerAt: "0",
        placedOrderHash: "",
        executionType: "",
        txHash: "",
        cid: "",
    };
}
export const DerivativeLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.isReduceOnly === true) {
            writer.uint32(40).bool(message.isReduceOnly);
        }
        if (message.margin !== "") {
            writer.uint32(50).string(message.margin);
        }
        if (message.price !== "") {
            writer.uint32(58).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(66).string(message.quantity);
        }
        if (message.unfilledQuantity !== "") {
            writer.uint32(74).string(message.unfilledQuantity);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(82).string(message.triggerPrice);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(90).string(message.feeRecipient);
        }
        if (message.state !== "") {
            writer.uint32(98).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(104).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(112).sint64(message.updatedAt);
        }
        if (message.orderNumber !== "0") {
            writer.uint32(120).sint64(message.orderNumber);
        }
        if (message.orderType !== "") {
            writer.uint32(130).string(message.orderType);
        }
        if (message.isConditional === true) {
            writer.uint32(136).bool(message.isConditional);
        }
        if (message.triggerAt !== "0") {
            writer.uint32(144).uint64(message.triggerAt);
        }
        if (message.placedOrderHash !== "") {
            writer.uint32(154).string(message.placedOrderHash);
        }
        if (message.executionType !== "") {
            writer.uint32(162).string(message.executionType);
        }
        if (message.txHash !== "") {
            writer.uint32(170).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(178).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.isReduceOnly = reader.bool();
                    break;
                case 6:
                    message.margin = reader.string();
                    break;
                case 7:
                    message.price = reader.string();
                    break;
                case 8:
                    message.quantity = reader.string();
                    break;
                case 9:
                    message.unfilledQuantity = reader.string();
                    break;
                case 10:
                    message.triggerPrice = reader.string();
                    break;
                case 11:
                    message.feeRecipient = reader.string();
                    break;
                case 12:
                    message.state = reader.string();
                    break;
                case 13:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 15:
                    message.orderNumber = longToString(reader.sint64());
                    break;
                case 16:
                    message.orderType = reader.string();
                    break;
                case 17:
                    message.isConditional = reader.bool();
                    break;
                case 18:
                    message.triggerAt = longToString(reader.uint64());
                    break;
                case 19:
                    message.placedOrderHash = reader.string();
                    break;
                case 20:
                    message.executionType = reader.string();
                    break;
                case 21:
                    message.txHash = reader.string();
                    break;
                case 22:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            isReduceOnly: isSet(object.isReduceOnly) ? Boolean(object.isReduceOnly) : false,
            margin: isSet(object.margin) ? String(object.margin) : "",
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            unfilledQuantity: isSet(object.unfilledQuantity) ? String(object.unfilledQuantity) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            orderNumber: isSet(object.orderNumber) ? String(object.orderNumber) : "0",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            isConditional: isSet(object.isConditional) ? Boolean(object.isConditional) : false,
            triggerAt: isSet(object.triggerAt) ? String(object.triggerAt) : "0",
            placedOrderHash: isSet(object.placedOrderHash) ? String(object.placedOrderHash) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.isReduceOnly !== undefined && (obj.isReduceOnly = message.isReduceOnly);
        message.margin !== undefined && (obj.margin = message.margin);
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.unfilledQuantity !== undefined && (obj.unfilledQuantity = message.unfilledQuantity);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.orderNumber !== undefined && (obj.orderNumber = message.orderNumber);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.triggerAt !== undefined && (obj.triggerAt = message.triggerAt);
        message.placedOrderHash !== undefined && (obj.placedOrderHash = message.placedOrderHash);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return DerivativeLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeLimitOrder();
        message.orderHash = object.orderHash ?? "";
        message.orderSide = object.orderSide ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.isReduceOnly = object.isReduceOnly ?? false;
        message.margin = object.margin ?? "";
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.unfilledQuantity = object.unfilledQuantity ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.orderNumber = object.orderNumber ?? "0";
        message.orderType = object.orderType ?? "";
        message.isConditional = object.isConditional ?? false;
        message.triggerAt = object.triggerAt ?? "0";
        message.placedOrderHash = object.placedOrderHash ?? "";
        message.executionType = object.executionType ?? "";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBasePositionsRequest() {
    return {
        subaccountId: "",
        marketId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        direction: "",
        subaccountTotalPositions: false,
        accountAddress: "",
    };
}
export const PositionsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(40).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(48).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(58).string(v);
        }
        if (message.direction !== "") {
            writer.uint32(66).string(message.direction);
        }
        if (message.subaccountTotalPositions === true) {
            writer.uint32(72).bool(message.subaccountTotalPositions);
        }
        if (message.accountAddress !== "") {
            writer.uint32(82).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 6:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.marketIds.push(reader.string());
                    break;
                case 8:
                    message.direction = reader.string();
                    break;
                case 9:
                    message.subaccountTotalPositions = reader.bool();
                    break;
                case 10:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountTotalPositions: isSet(object.subaccountTotalPositions)
                ? Boolean(object.subaccountTotalPositions)
                : false,
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountTotalPositions !== undefined && (obj.subaccountTotalPositions = message.subaccountTotalPositions);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return PositionsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionsRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.direction = object.direction ?? "";
        message.subaccountTotalPositions = object.subaccountTotalPositions ?? false;
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBasePositionsResponse() {
    return { positions: [], paging: undefined };
}
export const PositionsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.positions) {
            DerivativePosition.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.positions.push(DerivativePosition.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            positions: Array.isArray(object?.positions)
                ? object.positions.map((e) => DerivativePosition.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.positions) {
            obj.positions = message.positions.map((e) => e ? DerivativePosition.toJSON(e) : undefined);
        }
        else {
            obj.positions = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return PositionsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionsResponse();
        message.positions = object.positions?.map((e) => DerivativePosition.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseDerivativePosition() {
    return {
        ticker: "",
        marketId: "",
        subaccountId: "",
        direction: "",
        quantity: "",
        entryPrice: "",
        margin: "",
        liquidationPrice: "",
        markPrice: "",
        aggregateReduceOnlyQuantity: "",
        updatedAt: "0",
        createdAt: "0",
    };
}
export const DerivativePosition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticker !== "") {
            writer.uint32(10).string(message.ticker);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.quantity !== "") {
            writer.uint32(42).string(message.quantity);
        }
        if (message.entryPrice !== "") {
            writer.uint32(50).string(message.entryPrice);
        }
        if (message.margin !== "") {
            writer.uint32(58).string(message.margin);
        }
        if (message.liquidationPrice !== "") {
            writer.uint32(66).string(message.liquidationPrice);
        }
        if (message.markPrice !== "") {
            writer.uint32(74).string(message.markPrice);
        }
        if (message.aggregateReduceOnlyQuantity !== "") {
            writer.uint32(90).string(message.aggregateReduceOnlyQuantity);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(96).sint64(message.updatedAt);
        }
        if (message.createdAt !== "0") {
            writer.uint32(104).sint64(message.createdAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativePosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticker = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.quantity = reader.string();
                    break;
                case 6:
                    message.entryPrice = reader.string();
                    break;
                case 7:
                    message.margin = reader.string();
                    break;
                case 8:
                    message.liquidationPrice = reader.string();
                    break;
                case 9:
                    message.markPrice = reader.string();
                    break;
                case 11:
                    message.aggregateReduceOnlyQuantity = reader.string();
                    break;
                case 12:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.createdAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            entryPrice: isSet(object.entryPrice) ? String(object.entryPrice) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
            liquidationPrice: isSet(object.liquidationPrice) ? String(object.liquidationPrice) : "",
            markPrice: isSet(object.markPrice) ? String(object.markPrice) : "",
            aggregateReduceOnlyQuantity: isSet(object.aggregateReduceOnlyQuantity)
                ? String(object.aggregateReduceOnlyQuantity)
                : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.direction !== undefined && (obj.direction = message.direction);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.entryPrice !== undefined && (obj.entryPrice = message.entryPrice);
        message.margin !== undefined && (obj.margin = message.margin);
        message.liquidationPrice !== undefined && (obj.liquidationPrice = message.liquidationPrice);
        message.markPrice !== undefined && (obj.markPrice = message.markPrice);
        message.aggregateReduceOnlyQuantity !== undefined &&
            (obj.aggregateReduceOnlyQuantity = message.aggregateReduceOnlyQuantity);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        return obj;
    },
    create(base) {
        return DerivativePosition.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativePosition();
        message.ticker = object.ticker ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.direction = object.direction ?? "";
        message.quantity = object.quantity ?? "";
        message.entryPrice = object.entryPrice ?? "";
        message.margin = object.margin ?? "";
        message.liquidationPrice = object.liquidationPrice ?? "";
        message.markPrice = object.markPrice ?? "";
        message.aggregateReduceOnlyQuantity = object.aggregateReduceOnlyQuantity ?? "";
        message.updatedAt = object.updatedAt ?? "0";
        message.createdAt = object.createdAt ?? "0";
        return message;
    },
};
function createBasePositionsV2Request() {
    return {
        subaccountId: "",
        marketId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        direction: "",
        subaccountTotalPositions: false,
        accountAddress: "",
    };
}
export const PositionsV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(40).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(48).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(58).string(v);
        }
        if (message.direction !== "") {
            writer.uint32(66).string(message.direction);
        }
        if (message.subaccountTotalPositions === true) {
            writer.uint32(72).bool(message.subaccountTotalPositions);
        }
        if (message.accountAddress !== "") {
            writer.uint32(82).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionsV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 6:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.marketIds.push(reader.string());
                    break;
                case 8:
                    message.direction = reader.string();
                    break;
                case 9:
                    message.subaccountTotalPositions = reader.bool();
                    break;
                case 10:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountTotalPositions: isSet(object.subaccountTotalPositions)
                ? Boolean(object.subaccountTotalPositions)
                : false,
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountTotalPositions !== undefined && (obj.subaccountTotalPositions = message.subaccountTotalPositions);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return PositionsV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionsV2Request();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.direction = object.direction ?? "";
        message.subaccountTotalPositions = object.subaccountTotalPositions ?? false;
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBasePositionsV2Response() {
    return { positions: [], paging: undefined };
}
export const PositionsV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.positions) {
            DerivativePositionV2.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionsV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.positions.push(DerivativePositionV2.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            positions: Array.isArray(object?.positions)
                ? object.positions.map((e) => DerivativePositionV2.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.positions) {
            obj.positions = message.positions.map((e) => e ? DerivativePositionV2.toJSON(e) : undefined);
        }
        else {
            obj.positions = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return PositionsV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionsV2Response();
        message.positions = object.positions?.map((e) => DerivativePositionV2.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseDerivativePositionV2() {
    return {
        ticker: "",
        marketId: "",
        subaccountId: "",
        direction: "",
        quantity: "",
        entryPrice: "",
        margin: "",
        liquidationPrice: "",
        markPrice: "",
        updatedAt: "0",
        denom: "",
    };
}
export const DerivativePositionV2 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticker !== "") {
            writer.uint32(10).string(message.ticker);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.quantity !== "") {
            writer.uint32(42).string(message.quantity);
        }
        if (message.entryPrice !== "") {
            writer.uint32(50).string(message.entryPrice);
        }
        if (message.margin !== "") {
            writer.uint32(58).string(message.margin);
        }
        if (message.liquidationPrice !== "") {
            writer.uint32(66).string(message.liquidationPrice);
        }
        if (message.markPrice !== "") {
            writer.uint32(74).string(message.markPrice);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(88).sint64(message.updatedAt);
        }
        if (message.denom !== "") {
            writer.uint32(98).string(message.denom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativePositionV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticker = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.quantity = reader.string();
                    break;
                case 6:
                    message.entryPrice = reader.string();
                    break;
                case 7:
                    message.margin = reader.string();
                    break;
                case 8:
                    message.liquidationPrice = reader.string();
                    break;
                case 9:
                    message.markPrice = reader.string();
                    break;
                case 11:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 12:
                    message.denom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            entryPrice: isSet(object.entryPrice) ? String(object.entryPrice) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
            liquidationPrice: isSet(object.liquidationPrice) ? String(object.liquidationPrice) : "",
            markPrice: isSet(object.markPrice) ? String(object.markPrice) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            denom: isSet(object.denom) ? String(object.denom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.direction !== undefined && (obj.direction = message.direction);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.entryPrice !== undefined && (obj.entryPrice = message.entryPrice);
        message.margin !== undefined && (obj.margin = message.margin);
        message.liquidationPrice !== undefined && (obj.liquidationPrice = message.liquidationPrice);
        message.markPrice !== undefined && (obj.markPrice = message.markPrice);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.denom !== undefined && (obj.denom = message.denom);
        return obj;
    },
    create(base) {
        return DerivativePositionV2.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativePositionV2();
        message.ticker = object.ticker ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.direction = object.direction ?? "";
        message.quantity = object.quantity ?? "";
        message.entryPrice = object.entryPrice ?? "";
        message.margin = object.margin ?? "";
        message.liquidationPrice = object.liquidationPrice ?? "";
        message.markPrice = object.markPrice ?? "";
        message.updatedAt = object.updatedAt ?? "0";
        message.denom = object.denom ?? "";
        return message;
    },
};
function createBaseLiquidablePositionsRequest() {
    return { marketId: "", skip: "0", limit: 0 };
}
export const LiquidablePositionsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(16).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLiquidablePositionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.skip = longToString(reader.uint64());
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return LiquidablePositionsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLiquidablePositionsRequest();
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseLiquidablePositionsResponse() {
    return { positions: [] };
}
export const LiquidablePositionsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.positions) {
            DerivativePosition.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLiquidablePositionsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.positions.push(DerivativePosition.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            positions: Array.isArray(object?.positions)
                ? object.positions.map((e) => DerivativePosition.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.positions) {
            obj.positions = message.positions.map((e) => e ? DerivativePosition.toJSON(e) : undefined);
        }
        else {
            obj.positions = [];
        }
        return obj;
    },
    create(base) {
        return LiquidablePositionsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLiquidablePositionsResponse();
        message.positions = object.positions?.map((e) => DerivativePosition.fromPartial(e)) || [];
        return message;
    },
};
function createBaseFundingPaymentsRequest() {
    return { subaccountId: "", marketId: "", skip: "0", limit: 0, endTime: "0", marketIds: [] };
}
export const FundingPaymentsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.endTime !== "0") {
            writer.uint32(40).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(50).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingPaymentsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 6:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return FundingPaymentsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingPaymentsRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseFundingPaymentsResponse() {
    return { payments: [], paging: undefined };
}
export const FundingPaymentsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.payments) {
            FundingPayment.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingPaymentsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.payments.push(FundingPayment.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            payments: Array.isArray(object?.payments) ? object.payments.map((e) => FundingPayment.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.payments) {
            obj.payments = message.payments.map((e) => e ? FundingPayment.toJSON(e) : undefined);
        }
        else {
            obj.payments = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return FundingPaymentsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingPaymentsResponse();
        message.payments = object.payments?.map((e) => FundingPayment.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseFundingPayment() {
    return { marketId: "", subaccountId: "", amount: "", timestamp: "0" };
}
export const FundingPayment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.amount !== "") {
            writer.uint32(26).string(message.amount);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingPayment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.amount = reader.string();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.amount !== undefined && (obj.amount = message.amount);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return FundingPayment.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingPayment();
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.amount = object.amount ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseFundingRatesRequest() {
    return { marketId: "", skip: "0", limit: 0, endTime: "0" };
}
export const FundingRatesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(16).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.endTime !== "0") {
            writer.uint32(32).sint64(message.endTime);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingRatesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.skip = longToString(reader.uint64());
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.endTime = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.endTime !== undefined && (obj.endTime = message.endTime);
        return obj;
    },
    create(base) {
        return FundingRatesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingRatesRequest();
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.endTime = object.endTime ?? "0";
        return message;
    },
};
function createBaseFundingRatesResponse() {
    return { fundingRates: [], paging: undefined };
}
export const FundingRatesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.fundingRates) {
            FundingRate.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingRatesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fundingRates.push(FundingRate.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            fundingRates: Array.isArray(object?.fundingRates)
                ? object.fundingRates.map((e) => FundingRate.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.fundingRates) {
            obj.fundingRates = message.fundingRates.map((e) => e ? FundingRate.toJSON(e) : undefined);
        }
        else {
            obj.fundingRates = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return FundingRatesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingRatesResponse();
        message.fundingRates = object.fundingRates?.map((e) => FundingRate.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseFundingRate() {
    return { marketId: "", rate: "", timestamp: "0" };
}
export const FundingRate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.rate !== "") {
            writer.uint32(18).string(message.rate);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingRate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.rate = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            rate: isSet(object.rate) ? String(object.rate) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.rate !== undefined && (obj.rate = message.rate);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return FundingRate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingRate();
        message.marketId = object.marketId ?? "";
        message.rate = object.rate ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseStreamPositionsRequest() {
    return { subaccountId: "", marketId: "", marketIds: [], subaccountIds: [], accountAddress: "" };
}
export const StreamPositionsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        for (const v of message.marketIds) {
            writer.uint32(26).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(34).string(v);
        }
        if (message.accountAddress !== "") {
            writer.uint32(42).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamPositionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.marketIds.push(reader.string());
                    break;
                case 4:
                    message.subaccountIds.push(reader.string());
                    break;
                case 5:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return StreamPositionsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamPositionsRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBaseStreamPositionsResponse() {
    return { position: undefined, timestamp: "0" };
}
export const StreamPositionsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.position !== undefined) {
            DerivativePosition.encode(message.position, writer.uint32(10).fork()).ldelim();
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamPositionsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.position = DerivativePosition.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            position: isSet(object.position) ? DerivativePosition.fromJSON(object.position) : undefined,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.position !== undefined &&
            (obj.position = message.position ? DerivativePosition.toJSON(message.position) : undefined);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamPositionsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamPositionsResponse();
        message.position = (object.position !== undefined && object.position !== null)
            ? DerivativePosition.fromPartial(object.position)
            : undefined;
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseStreamOrdersRequest() {
    return {
        marketId: "",
        orderSide: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        isConditional: "",
        orderType: "",
        includeInactive: false,
        subaccountTotalOrders: false,
        tradeId: "",
        cid: "",
    };
}
export const StreamOrdersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(40).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(48).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(56).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(66).string(v);
        }
        if (message.isConditional !== "") {
            writer.uint32(74).string(message.isConditional);
        }
        if (message.orderType !== "") {
            writer.uint32(82).string(message.orderType);
        }
        if (message.includeInactive === true) {
            writer.uint32(88).bool(message.includeInactive);
        }
        if (message.subaccountTotalOrders === true) {
            writer.uint32(96).bool(message.subaccountTotalOrders);
        }
        if (message.tradeId !== "") {
            writer.uint32(106).string(message.tradeId);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                case 5:
                    message.limit = reader.sint32();
                    break;
                case 6:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.marketIds.push(reader.string());
                    break;
                case 9:
                    message.isConditional = reader.string();
                    break;
                case 10:
                    message.orderType = reader.string();
                    break;
                case 11:
                    message.includeInactive = reader.bool();
                    break;
                case 12:
                    message.subaccountTotalOrders = reader.bool();
                    break;
                case 13:
                    message.tradeId = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            isConditional: isSet(object.isConditional) ? String(object.isConditional) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            includeInactive: isSet(object.includeInactive) ? Boolean(object.includeInactive) : false,
            subaccountTotalOrders: isSet(object.subaccountTotalOrders) ? Boolean(object.subaccountTotalOrders) : false,
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.includeInactive !== undefined && (obj.includeInactive = message.includeInactive);
        message.subaccountTotalOrders !== undefined && (obj.subaccountTotalOrders = message.subaccountTotalOrders);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return StreamOrdersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersRequest();
        message.marketId = object.marketId ?? "";
        message.orderSide = object.orderSide ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.isConditional = object.isConditional ?? "";
        message.orderType = object.orderType ?? "";
        message.includeInactive = object.includeInactive ?? false;
        message.subaccountTotalOrders = object.subaccountTotalOrders ?? false;
        message.tradeId = object.tradeId ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseStreamOrdersResponse() {
    return { order: undefined, operationType: "", timestamp: "0" };
}
export const StreamOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.order !== undefined) {
            DerivativeLimitOrder.encode(message.order, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.order = DerivativeLimitOrder.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            order: isSet(object.order) ? DerivativeLimitOrder.fromJSON(object.order) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.order !== undefined && (obj.order = message.order ? DerivativeLimitOrder.toJSON(message.order) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersResponse();
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeLimitOrder.fromPartial(object.order)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseTradesRequest() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const TradesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return TradesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesRequest();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseTradesResponse() {
    return { trades: [], paging: undefined };
}
export const TradesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.trades) {
            DerivativeTrade.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trades.push(DerivativeTrade.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trades: Array.isArray(object?.trades) ? object.trades.map((e) => DerivativeTrade.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? DerivativeTrade.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return TradesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesResponse();
        message.trades = object.trades?.map((e) => DerivativeTrade.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseDerivativeTrade() {
    return {
        orderHash: "",
        subaccountId: "",
        marketId: "",
        tradeExecutionType: "",
        isLiquidation: false,
        positionDelta: undefined,
        payout: "",
        fee: "",
        executedAt: "0",
        feeRecipient: "",
        tradeId: "",
        executionSide: "",
        cid: "",
    };
}
export const DerivativeTrade = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.tradeExecutionType !== "") {
            writer.uint32(34).string(message.tradeExecutionType);
        }
        if (message.isLiquidation === true) {
            writer.uint32(40).bool(message.isLiquidation);
        }
        if (message.positionDelta !== undefined) {
            PositionDelta.encode(message.positionDelta, writer.uint32(50).fork()).ldelim();
        }
        if (message.payout !== "") {
            writer.uint32(58).string(message.payout);
        }
        if (message.fee !== "") {
            writer.uint32(66).string(message.fee);
        }
        if (message.executedAt !== "0") {
            writer.uint32(72).sint64(message.executedAt);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(82).string(message.feeRecipient);
        }
        if (message.tradeId !== "") {
            writer.uint32(90).string(message.tradeId);
        }
        if (message.executionSide !== "") {
            writer.uint32(98).string(message.executionSide);
        }
        if (message.cid !== "") {
            writer.uint32(106).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeTrade();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.tradeExecutionType = reader.string();
                    break;
                case 5:
                    message.isLiquidation = reader.bool();
                    break;
                case 6:
                    message.positionDelta = PositionDelta.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.payout = reader.string();
                    break;
                case 8:
                    message.fee = reader.string();
                    break;
                case 9:
                    message.executedAt = longToString(reader.sint64());
                    break;
                case 10:
                    message.feeRecipient = reader.string();
                    break;
                case 11:
                    message.tradeId = reader.string();
                    break;
                case 12:
                    message.executionSide = reader.string();
                    break;
                case 13:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            tradeExecutionType: isSet(object.tradeExecutionType) ? String(object.tradeExecutionType) : "",
            isLiquidation: isSet(object.isLiquidation) ? Boolean(object.isLiquidation) : false,
            positionDelta: isSet(object.positionDelta) ? PositionDelta.fromJSON(object.positionDelta) : undefined,
            payout: isSet(object.payout) ? String(object.payout) : "",
            fee: isSet(object.fee) ? String(object.fee) : "",
            executedAt: isSet(object.executedAt) ? String(object.executedAt) : "0",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.tradeExecutionType !== undefined && (obj.tradeExecutionType = message.tradeExecutionType);
        message.isLiquidation !== undefined && (obj.isLiquidation = message.isLiquidation);
        message.positionDelta !== undefined &&
            (obj.positionDelta = message.positionDelta ? PositionDelta.toJSON(message.positionDelta) : undefined);
        message.payout !== undefined && (obj.payout = message.payout);
        message.fee !== undefined && (obj.fee = message.fee);
        message.executedAt !== undefined && (obj.executedAt = message.executedAt);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return DerivativeTrade.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeTrade();
        message.orderHash = object.orderHash ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.tradeExecutionType = object.tradeExecutionType ?? "";
        message.isLiquidation = object.isLiquidation ?? false;
        message.positionDelta = (object.positionDelta !== undefined && object.positionDelta !== null)
            ? PositionDelta.fromPartial(object.positionDelta)
            : undefined;
        message.payout = object.payout ?? "";
        message.fee = object.fee ?? "";
        message.executedAt = object.executedAt ?? "0";
        message.feeRecipient = object.feeRecipient ?? "";
        message.tradeId = object.tradeId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBasePositionDelta() {
    return { tradeDirection: "", executionPrice: "", executionQuantity: "", executionMargin: "" };
}
export const PositionDelta = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tradeDirection !== "") {
            writer.uint32(10).string(message.tradeDirection);
        }
        if (message.executionPrice !== "") {
            writer.uint32(18).string(message.executionPrice);
        }
        if (message.executionQuantity !== "") {
            writer.uint32(26).string(message.executionQuantity);
        }
        if (message.executionMargin !== "") {
            writer.uint32(34).string(message.executionMargin);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionDelta();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tradeDirection = reader.string();
                    break;
                case 2:
                    message.executionPrice = reader.string();
                    break;
                case 3:
                    message.executionQuantity = reader.string();
                    break;
                case 4:
                    message.executionMargin = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tradeDirection: isSet(object.tradeDirection) ? String(object.tradeDirection) : "",
            executionPrice: isSet(object.executionPrice) ? String(object.executionPrice) : "",
            executionQuantity: isSet(object.executionQuantity) ? String(object.executionQuantity) : "",
            executionMargin: isSet(object.executionMargin) ? String(object.executionMargin) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.tradeDirection !== undefined && (obj.tradeDirection = message.tradeDirection);
        message.executionPrice !== undefined && (obj.executionPrice = message.executionPrice);
        message.executionQuantity !== undefined && (obj.executionQuantity = message.executionQuantity);
        message.executionMargin !== undefined && (obj.executionMargin = message.executionMargin);
        return obj;
    },
    create(base) {
        return PositionDelta.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionDelta();
        message.tradeDirection = object.tradeDirection ?? "";
        message.executionPrice = object.executionPrice ?? "";
        message.executionQuantity = object.executionQuantity ?? "";
        message.executionMargin = object.executionMargin ?? "";
        return message;
    },
};
function createBaseTradesV2Request() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const TradesV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return TradesV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesV2Request();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseTradesV2Response() {
    return { trades: [], paging: undefined };
}
export const TradesV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.trades) {
            DerivativeTrade.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradesV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trades.push(DerivativeTrade.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trades: Array.isArray(object?.trades) ? object.trades.map((e) => DerivativeTrade.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? DerivativeTrade.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return TradesV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradesV2Response();
        message.trades = object.trades?.map((e) => DerivativeTrade.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseStreamTradesRequest() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const StreamTradesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return StreamTradesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesRequest();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseStreamTradesResponse() {
    return { trade: undefined, operationType: "", timestamp: "0" };
}
export const StreamTradesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.trade !== undefined) {
            DerivativeTrade.encode(message.trade, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trade = DerivativeTrade.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trade: isSet(object.trade) ? DerivativeTrade.fromJSON(object.trade) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.trade !== undefined && (obj.trade = message.trade ? DerivativeTrade.toJSON(message.trade) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamTradesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesResponse();
        message.trade = (object.trade !== undefined && object.trade !== null)
            ? DerivativeTrade.fromPartial(object.trade)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseStreamTradesV2Request() {
    return {
        marketId: "",
        executionSide: "",
        direction: "",
        subaccountId: "",
        skip: "0",
        limit: 0,
        startTime: "0",
        endTime: "0",
        marketIds: [],
        subaccountIds: [],
        executionTypes: [],
        tradeId: "",
        accountAddress: "",
        cid: "",
        feeRecipient: "",
    };
}
export const StreamTradesV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.executionSide !== "") {
            writer.uint32(18).string(message.executionSide);
        }
        if (message.direction !== "") {
            writer.uint32(26).string(message.direction);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        for (const v of message.marketIds) {
            writer.uint32(74).string(v);
        }
        for (const v of message.subaccountIds) {
            writer.uint32(82).string(v);
        }
        for (const v of message.executionTypes) {
            writer.uint32(90).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(98).string(message.tradeId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(106).string(message.accountAddress);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(122).string(message.feeRecipient);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.executionSide = reader.string();
                    break;
                case 3:
                    message.direction = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.marketIds.push(reader.string());
                    break;
                case 10:
                    message.subaccountIds.push(reader.string());
                    break;
                case 11:
                    message.executionTypes.push(reader.string());
                    break;
                case 12:
                    message.tradeId = reader.string();
                    break;
                case 13:
                    message.accountAddress = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                case 15:
                    message.feeRecipient = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            subaccountIds: Array.isArray(object?.subaccountIds) ? object.subaccountIds.map((e) => String(e)) : [],
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.direction !== undefined && (obj.direction = message.direction);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => e);
        }
        else {
            obj.subaccountIds = [];
        }
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.cid !== undefined && (obj.cid = message.cid);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        return obj;
    },
    create(base) {
        return StreamTradesV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesV2Request();
        message.marketId = object.marketId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.direction = object.direction ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.cid = object.cid ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        return message;
    },
};
function createBaseStreamTradesV2Response() {
    return { trade: undefined, operationType: "", timestamp: "0" };
}
export const StreamTradesV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.trade !== undefined) {
            DerivativeTrade.encode(message.trade, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTradesV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trade = DerivativeTrade.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            trade: isSet(object.trade) ? DerivativeTrade.fromJSON(object.trade) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.trade !== undefined && (obj.trade = message.trade ? DerivativeTrade.toJSON(message.trade) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamTradesV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTradesV2Response();
        message.trade = (object.trade !== undefined && object.trade !== null)
            ? DerivativeTrade.fromPartial(object.trade)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSubaccountOrdersListRequest() {
    return { subaccountId: "", marketId: "", skip: "0", limit: 0 };
}
export const SubaccountOrdersListRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrdersListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return SubaccountOrdersListRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrdersListRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseSubaccountOrdersListResponse() {
    return { orders: [], paging: undefined };
}
export const SubaccountOrdersListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            DerivativeLimitOrder.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrdersListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => DerivativeLimitOrder.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return SubaccountOrdersListResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrdersListResponse();
        message.orders = object.orders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseSubaccountTradesListRequest() {
    return { subaccountId: "", marketId: "", executionType: "", direction: "", skip: "0", limit: 0 };
}
export const SubaccountTradesListRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.executionType !== "") {
            writer.uint32(26).string(message.executionType);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(48).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountTradesListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.executionType = reader.string();
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.direction !== undefined && (obj.direction = message.direction);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return SubaccountTradesListRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountTradesListRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.executionType = object.executionType ?? "";
        message.direction = object.direction ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseSubaccountTradesListResponse() {
    return { trades: [] };
}
export const SubaccountTradesListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.trades) {
            DerivativeTrade.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountTradesListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.trades.push(DerivativeTrade.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { trades: Array.isArray(object?.trades) ? object.trades.map((e) => DerivativeTrade.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? DerivativeTrade.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        return obj;
    },
    create(base) {
        return SubaccountTradesListResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountTradesListResponse();
        message.trades = object.trades?.map((e) => DerivativeTrade.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOrdersHistoryRequest() {
    return {
        subaccountId: "",
        marketId: "",
        skip: "0",
        limit: 0,
        orderTypes: [],
        direction: "",
        startTime: "0",
        endTime: "0",
        isConditional: "",
        orderType: "",
        state: "",
        executionTypes: [],
        marketIds: [],
        tradeId: "",
        activeMarketsOnly: false,
        cid: "",
    };
}
export const OrdersHistoryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        for (const v of message.orderTypes) {
            writer.uint32(42).string(v);
        }
        if (message.direction !== "") {
            writer.uint32(50).string(message.direction);
        }
        if (message.startTime !== "0") {
            writer.uint32(56).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(64).sint64(message.endTime);
        }
        if (message.isConditional !== "") {
            writer.uint32(74).string(message.isConditional);
        }
        if (message.orderType !== "") {
            writer.uint32(82).string(message.orderType);
        }
        if (message.state !== "") {
            writer.uint32(90).string(message.state);
        }
        for (const v of message.executionTypes) {
            writer.uint32(98).string(v);
        }
        for (const v of message.marketIds) {
            writer.uint32(106).string(v);
        }
        if (message.tradeId !== "") {
            writer.uint32(114).string(message.tradeId);
        }
        if (message.activeMarketsOnly === true) {
            writer.uint32(120).bool(message.activeMarketsOnly);
        }
        if (message.cid !== "") {
            writer.uint32(130).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersHistoryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.orderTypes.push(reader.string());
                    break;
                case 6:
                    message.direction = reader.string();
                    break;
                case 7:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 9:
                    message.isConditional = reader.string();
                    break;
                case 10:
                    message.orderType = reader.string();
                    break;
                case 11:
                    message.state = reader.string();
                    break;
                case 12:
                    message.executionTypes.push(reader.string());
                    break;
                case 13:
                    message.marketIds.push(reader.string());
                    break;
                case 14:
                    message.tradeId = reader.string();
                    break;
                case 15:
                    message.activeMarketsOnly = reader.bool();
                    break;
                case 16:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            orderTypes: Array.isArray(object?.orderTypes) ? object.orderTypes.map((e) => String(e)) : [],
            direction: isSet(object.direction) ? String(object.direction) : "",
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            isConditional: isSet(object.isConditional) ? String(object.isConditional) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            state: isSet(object.state) ? String(object.state) : "",
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            activeMarketsOnly: isSet(object.activeMarketsOnly) ? Boolean(object.activeMarketsOnly) : false,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        if (message.orderTypes) {
            obj.orderTypes = message.orderTypes.map((e) => e);
        }
        else {
            obj.orderTypes = [];
        }
        message.direction !== undefined && (obj.direction = message.direction);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.state !== undefined && (obj.state = message.state);
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.activeMarketsOnly !== undefined && (obj.activeMarketsOnly = message.activeMarketsOnly);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return OrdersHistoryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersHistoryRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.orderTypes = object.orderTypes?.map((e) => e) || [];
        message.direction = object.direction ?? "";
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.isConditional = object.isConditional ?? "";
        message.orderType = object.orderType ?? "";
        message.state = object.state ?? "";
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        message.marketIds = object.marketIds?.map((e) => e) || [];
        message.tradeId = object.tradeId ?? "";
        message.activeMarketsOnly = object.activeMarketsOnly ?? false;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseOrdersHistoryResponse() {
    return { orders: [], paging: undefined };
}
export const OrdersHistoryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orders) {
            DerivativeOrderHistory.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrdersHistoryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(DerivativeOrderHistory.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => DerivativeOrderHistory.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? DerivativeOrderHistory.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return OrdersHistoryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrdersHistoryResponse();
        message.orders = object.orders?.map((e) => DerivativeOrderHistory.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseDerivativeOrderHistory() {
    return {
        orderHash: "",
        marketId: "",
        isActive: false,
        subaccountId: "",
        executionType: "",
        orderType: "",
        price: "",
        triggerPrice: "",
        quantity: "",
        filledQuantity: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        isReduceOnly: false,
        direction: "",
        isConditional: false,
        triggerAt: "0",
        placedOrderHash: "",
        margin: "",
        txHash: "",
        cid: "",
    };
}
export const DerivativeOrderHistory = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.isActive === true) {
            writer.uint32(24).bool(message.isActive);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.executionType !== "") {
            writer.uint32(42).string(message.executionType);
        }
        if (message.orderType !== "") {
            writer.uint32(50).string(message.orderType);
        }
        if (message.price !== "") {
            writer.uint32(58).string(message.price);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(66).string(message.triggerPrice);
        }
        if (message.quantity !== "") {
            writer.uint32(74).string(message.quantity);
        }
        if (message.filledQuantity !== "") {
            writer.uint32(82).string(message.filledQuantity);
        }
        if (message.state !== "") {
            writer.uint32(90).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(96).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(104).sint64(message.updatedAt);
        }
        if (message.isReduceOnly === true) {
            writer.uint32(112).bool(message.isReduceOnly);
        }
        if (message.direction !== "") {
            writer.uint32(122).string(message.direction);
        }
        if (message.isConditional === true) {
            writer.uint32(128).bool(message.isConditional);
        }
        if (message.triggerAt !== "0") {
            writer.uint32(136).uint64(message.triggerAt);
        }
        if (message.placedOrderHash !== "") {
            writer.uint32(146).string(message.placedOrderHash);
        }
        if (message.margin !== "") {
            writer.uint32(154).string(message.margin);
        }
        if (message.txHash !== "") {
            writer.uint32(162).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(170).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeOrderHistory();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.isActive = reader.bool();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.executionType = reader.string();
                    break;
                case 6:
                    message.orderType = reader.string();
                    break;
                case 7:
                    message.price = reader.string();
                    break;
                case 8:
                    message.triggerPrice = reader.string();
                    break;
                case 9:
                    message.quantity = reader.string();
                    break;
                case 10:
                    message.filledQuantity = reader.string();
                    break;
                case 11:
                    message.state = reader.string();
                    break;
                case 12:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.isReduceOnly = reader.bool();
                    break;
                case 15:
                    message.direction = reader.string();
                    break;
                case 16:
                    message.isConditional = reader.bool();
                    break;
                case 17:
                    message.triggerAt = longToString(reader.uint64());
                    break;
                case 18:
                    message.placedOrderHash = reader.string();
                    break;
                case 19:
                    message.margin = reader.string();
                    break;
                case 20:
                    message.txHash = reader.string();
                    break;
                case 21:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            price: isSet(object.price) ? String(object.price) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            filledQuantity: isSet(object.filledQuantity) ? String(object.filledQuantity) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            isReduceOnly: isSet(object.isReduceOnly) ? Boolean(object.isReduceOnly) : false,
            direction: isSet(object.direction) ? String(object.direction) : "",
            isConditional: isSet(object.isConditional) ? Boolean(object.isConditional) : false,
            triggerAt: isSet(object.triggerAt) ? String(object.triggerAt) : "0",
            placedOrderHash: isSet(object.placedOrderHash) ? String(object.placedOrderHash) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.price !== undefined && (obj.price = message.price);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.filledQuantity !== undefined && (obj.filledQuantity = message.filledQuantity);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.isReduceOnly !== undefined && (obj.isReduceOnly = message.isReduceOnly);
        message.direction !== undefined && (obj.direction = message.direction);
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.triggerAt !== undefined && (obj.triggerAt = message.triggerAt);
        message.placedOrderHash !== undefined && (obj.placedOrderHash = message.placedOrderHash);
        message.margin !== undefined && (obj.margin = message.margin);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return DerivativeOrderHistory.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeOrderHistory();
        message.orderHash = object.orderHash ?? "";
        message.marketId = object.marketId ?? "";
        message.isActive = object.isActive ?? false;
        message.subaccountId = object.subaccountId ?? "";
        message.executionType = object.executionType ?? "";
        message.orderType = object.orderType ?? "";
        message.price = object.price ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.quantity = object.quantity ?? "";
        message.filledQuantity = object.filledQuantity ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.isReduceOnly = object.isReduceOnly ?? false;
        message.direction = object.direction ?? "";
        message.isConditional = object.isConditional ?? false;
        message.triggerAt = object.triggerAt ?? "0";
        message.placedOrderHash = object.placedOrderHash ?? "";
        message.margin = object.margin ?? "";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseStreamOrdersHistoryRequest() {
    return { subaccountId: "", marketId: "", orderTypes: [], direction: "", state: "", executionTypes: [] };
}
export const StreamOrdersHistoryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        for (const v of message.orderTypes) {
            writer.uint32(26).string(v);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.state !== "") {
            writer.uint32(42).string(message.state);
        }
        for (const v of message.executionTypes) {
            writer.uint32(50).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersHistoryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.orderTypes.push(reader.string());
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.state = reader.string();
                    break;
                case 6:
                    message.executionTypes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderTypes: Array.isArray(object?.orderTypes) ? object.orderTypes.map((e) => String(e)) : [],
            direction: isSet(object.direction) ? String(object.direction) : "",
            state: isSet(object.state) ? String(object.state) : "",
            executionTypes: Array.isArray(object?.executionTypes) ? object.executionTypes.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.orderTypes) {
            obj.orderTypes = message.orderTypes.map((e) => e);
        }
        else {
            obj.orderTypes = [];
        }
        message.direction !== undefined && (obj.direction = message.direction);
        message.state !== undefined && (obj.state = message.state);
        if (message.executionTypes) {
            obj.executionTypes = message.executionTypes.map((e) => e);
        }
        else {
            obj.executionTypes = [];
        }
        return obj;
    },
    create(base) {
        return StreamOrdersHistoryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersHistoryRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.orderTypes = object.orderTypes?.map((e) => e) || [];
        message.direction = object.direction ?? "";
        message.state = object.state ?? "";
        message.executionTypes = object.executionTypes?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamOrdersHistoryResponse() {
    return { order: undefined, operationType: "", timestamp: "0" };
}
export const StreamOrdersHistoryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.order !== undefined) {
            DerivativeOrderHistory.encode(message.order, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamOrdersHistoryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.order = DerivativeOrderHistory.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            order: isSet(object.order) ? DerivativeOrderHistory.fromJSON(object.order) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.order !== undefined &&
            (obj.order = message.order ? DerivativeOrderHistory.toJSON(message.order) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamOrdersHistoryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamOrdersHistoryResponse();
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrderHistory.fromPartial(object.order)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
export class InjectiveDerivativeExchangeRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Markets = this.Markets.bind(this);
        this.Market = this.Market.bind(this);
        this.StreamMarket = this.StreamMarket.bind(this);
        this.BinaryOptionsMarkets = this.BinaryOptionsMarkets.bind(this);
        this.BinaryOptionsMarket = this.BinaryOptionsMarket.bind(this);
        this.OrderbookV2 = this.OrderbookV2.bind(this);
        this.OrderbooksV2 = this.OrderbooksV2.bind(this);
        this.StreamOrderbookV2 = this.StreamOrderbookV2.bind(this);
        this.StreamOrderbookUpdate = this.StreamOrderbookUpdate.bind(this);
        this.Orders = this.Orders.bind(this);
        this.Positions = this.Positions.bind(this);
        this.PositionsV2 = this.PositionsV2.bind(this);
        this.LiquidablePositions = this.LiquidablePositions.bind(this);
        this.FundingPayments = this.FundingPayments.bind(this);
        this.FundingRates = this.FundingRates.bind(this);
        this.StreamPositions = this.StreamPositions.bind(this);
        this.StreamOrders = this.StreamOrders.bind(this);
        this.Trades = this.Trades.bind(this);
        this.TradesV2 = this.TradesV2.bind(this);
        this.StreamTrades = this.StreamTrades.bind(this);
        this.StreamTradesV2 = this.StreamTradesV2.bind(this);
        this.SubaccountOrdersList = this.SubaccountOrdersList.bind(this);
        this.SubaccountTradesList = this.SubaccountTradesList.bind(this);
        this.OrdersHistory = this.OrdersHistory.bind(this);
        this.StreamOrdersHistory = this.StreamOrdersHistory.bind(this);
    }
    Markets(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCMarketsDesc, MarketsRequest.fromPartial(request), metadata);
    }
    Market(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCMarketDesc, MarketRequest.fromPartial(request), metadata);
    }
    StreamMarket(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamMarketDesc, StreamMarketRequest.fromPartial(request), metadata);
    }
    BinaryOptionsMarkets(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCBinaryOptionsMarketsDesc, BinaryOptionsMarketsRequest.fromPartial(request), metadata);
    }
    BinaryOptionsMarket(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCBinaryOptionsMarketDesc, BinaryOptionsMarketRequest.fromPartial(request), metadata);
    }
    OrderbookV2(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCOrderbookV2Desc, OrderbookV2Request.fromPartial(request), metadata);
    }
    OrderbooksV2(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCOrderbooksV2Desc, OrderbooksV2Request.fromPartial(request), metadata);
    }
    StreamOrderbookV2(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamOrderbookV2Desc, StreamOrderbookV2Request.fromPartial(request), metadata);
    }
    StreamOrderbookUpdate(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamOrderbookUpdateDesc, StreamOrderbookUpdateRequest.fromPartial(request), metadata);
    }
    Orders(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCOrdersDesc, OrdersRequest.fromPartial(request), metadata);
    }
    Positions(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCPositionsDesc, PositionsRequest.fromPartial(request), metadata);
    }
    PositionsV2(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCPositionsV2Desc, PositionsV2Request.fromPartial(request), metadata);
    }
    LiquidablePositions(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCLiquidablePositionsDesc, LiquidablePositionsRequest.fromPartial(request), metadata);
    }
    FundingPayments(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCFundingPaymentsDesc, FundingPaymentsRequest.fromPartial(request), metadata);
    }
    FundingRates(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCFundingRatesDesc, FundingRatesRequest.fromPartial(request), metadata);
    }
    StreamPositions(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamPositionsDesc, StreamPositionsRequest.fromPartial(request), metadata);
    }
    StreamOrders(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamOrdersDesc, StreamOrdersRequest.fromPartial(request), metadata);
    }
    Trades(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCTradesDesc, TradesRequest.fromPartial(request), metadata);
    }
    TradesV2(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCTradesV2Desc, TradesV2Request.fromPartial(request), metadata);
    }
    StreamTrades(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamTradesDesc, StreamTradesRequest.fromPartial(request), metadata);
    }
    StreamTradesV2(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamTradesV2Desc, StreamTradesV2Request.fromPartial(request), metadata);
    }
    SubaccountOrdersList(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCSubaccountOrdersListDesc, SubaccountOrdersListRequest.fromPartial(request), metadata);
    }
    SubaccountTradesList(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCSubaccountTradesListDesc, SubaccountTradesListRequest.fromPartial(request), metadata);
    }
    OrdersHistory(request, metadata) {
        return this.rpc.unary(InjectiveDerivativeExchangeRPCOrdersHistoryDesc, OrdersHistoryRequest.fromPartial(request), metadata);
    }
    StreamOrdersHistory(request, metadata) {
        return this.rpc.invoke(InjectiveDerivativeExchangeRPCStreamOrdersHistoryDesc, StreamOrdersHistoryRequest.fromPartial(request), metadata);
    }
}
export const InjectiveDerivativeExchangeRPCDesc = {
    serviceName: "injective_derivative_exchange_rpc.InjectiveDerivativeExchangeRPC",
};
export const InjectiveDerivativeExchangeRPCMarketsDesc = {
    methodName: "Markets",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MarketsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MarketsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCMarketDesc = {
    methodName: "Market",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MarketRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamMarketDesc = {
    methodName: "StreamMarket",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamMarketRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamMarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCBinaryOptionsMarketsDesc = {
    methodName: "BinaryOptionsMarkets",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return BinaryOptionsMarketsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = BinaryOptionsMarketsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCBinaryOptionsMarketDesc = {
    methodName: "BinaryOptionsMarket",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return BinaryOptionsMarketRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = BinaryOptionsMarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCOrderbookV2Desc = {
    methodName: "OrderbookV2",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrderbookV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrderbookV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCOrderbooksV2Desc = {
    methodName: "OrderbooksV2",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrderbooksV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrderbooksV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamOrderbookV2Desc = {
    methodName: "StreamOrderbookV2",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrderbookV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrderbookV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamOrderbookUpdateDesc = {
    methodName: "StreamOrderbookUpdate",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrderbookUpdateRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrderbookUpdateResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCOrdersDesc = {
    methodName: "Orders",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrdersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCPositionsDesc = {
    methodName: "Positions",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PositionsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PositionsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCPositionsV2Desc = {
    methodName: "PositionsV2",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PositionsV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PositionsV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCLiquidablePositionsDesc = {
    methodName: "LiquidablePositions",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return LiquidablePositionsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = LiquidablePositionsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCFundingPaymentsDesc = {
    methodName: "FundingPayments",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return FundingPaymentsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = FundingPaymentsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCFundingRatesDesc = {
    methodName: "FundingRates",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return FundingRatesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = FundingRatesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamPositionsDesc = {
    methodName: "StreamPositions",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamPositionsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamPositionsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamOrdersDesc = {
    methodName: "StreamOrders",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrdersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCTradesDesc = {
    methodName: "Trades",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return TradesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = TradesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCTradesV2Desc = {
    methodName: "TradesV2",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return TradesV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = TradesV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamTradesDesc = {
    methodName: "StreamTrades",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamTradesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamTradesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamTradesV2Desc = {
    methodName: "StreamTradesV2",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamTradesV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamTradesV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCSubaccountOrdersListDesc = {
    methodName: "SubaccountOrdersList",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountOrdersListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountOrdersListResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCSubaccountTradesListDesc = {
    methodName: "SubaccountTradesList",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountTradesListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountTradesListResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCOrdersHistoryDesc = {
    methodName: "OrdersHistory",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrdersHistoryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrdersHistoryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveDerivativeExchangeRPCStreamOrdersHistoryDesc = {
    methodName: "StreamOrdersHistory",
    service: InjectiveDerivativeExchangeRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamOrdersHistoryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamOrdersHistoryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => {
                    if (!observer.closed) {
                        return client.close();
                    }
                });
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
