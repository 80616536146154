/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { Duration } from "../../../google/protobuf/duration.js";
import { Timestamp } from "../../../google/protobuf/timestamp.js";
import { oracleTypeFromJSON, oracleTypeToJSON } from "../../oracle/v1beta1/oracle.js";
export const protobufPackage = "injective.insurance.v1beta1";
function createBaseParams() {
    return { defaultRedemptionNoticePeriodDuration: undefined };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.defaultRedemptionNoticePeriodDuration !== undefined) {
            Duration.encode(message.defaultRedemptionNoticePeriodDuration, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.defaultRedemptionNoticePeriodDuration = Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            defaultRedemptionNoticePeriodDuration: isSet(object.defaultRedemptionNoticePeriodDuration)
                ? Duration.fromJSON(object.defaultRedemptionNoticePeriodDuration)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.defaultRedemptionNoticePeriodDuration !== undefined &&
            (obj.defaultRedemptionNoticePeriodDuration = message.defaultRedemptionNoticePeriodDuration
                ? Duration.toJSON(message.defaultRedemptionNoticePeriodDuration)
                : undefined);
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.defaultRedemptionNoticePeriodDuration =
            (object.defaultRedemptionNoticePeriodDuration !== undefined &&
                object.defaultRedemptionNoticePeriodDuration !== null)
                ? Duration.fromPartial(object.defaultRedemptionNoticePeriodDuration)
                : undefined;
        return message;
    },
};
function createBaseInsuranceFund() {
    return {
        depositDenom: "",
        insurancePoolTokenDenom: "",
        redemptionNoticePeriodDuration: undefined,
        balance: "",
        totalShare: "",
        marketId: "",
        marketTicker: "",
        oracleBase: "",
        oracleQuote: "",
        oracleType: 0,
        expiry: "0",
    };
}
export const InsuranceFund = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.depositDenom !== "") {
            writer.uint32(10).string(message.depositDenom);
        }
        if (message.insurancePoolTokenDenom !== "") {
            writer.uint32(18).string(message.insurancePoolTokenDenom);
        }
        if (message.redemptionNoticePeriodDuration !== undefined) {
            Duration.encode(message.redemptionNoticePeriodDuration, writer.uint32(26).fork()).ldelim();
        }
        if (message.balance !== "") {
            writer.uint32(34).string(message.balance);
        }
        if (message.totalShare !== "") {
            writer.uint32(42).string(message.totalShare);
        }
        if (message.marketId !== "") {
            writer.uint32(50).string(message.marketId);
        }
        if (message.marketTicker !== "") {
            writer.uint32(58).string(message.marketTicker);
        }
        if (message.oracleBase !== "") {
            writer.uint32(66).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(74).string(message.oracleQuote);
        }
        if (message.oracleType !== 0) {
            writer.uint32(80).int32(message.oracleType);
        }
        if (message.expiry !== "0") {
            writer.uint32(88).int64(message.expiry);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInsuranceFund();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.depositDenom = reader.string();
                    break;
                case 2:
                    message.insurancePoolTokenDenom = reader.string();
                    break;
                case 3:
                    message.redemptionNoticePeriodDuration = Duration.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.balance = reader.string();
                    break;
                case 5:
                    message.totalShare = reader.string();
                    break;
                case 6:
                    message.marketId = reader.string();
                    break;
                case 7:
                    message.marketTicker = reader.string();
                    break;
                case 8:
                    message.oracleBase = reader.string();
                    break;
                case 9:
                    message.oracleQuote = reader.string();
                    break;
                case 10:
                    message.oracleType = reader.int32();
                    break;
                case 11:
                    message.expiry = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            depositDenom: isSet(object.depositDenom) ? String(object.depositDenom) : "",
            insurancePoolTokenDenom: isSet(object.insurancePoolTokenDenom) ? String(object.insurancePoolTokenDenom) : "",
            redemptionNoticePeriodDuration: isSet(object.redemptionNoticePeriodDuration)
                ? Duration.fromJSON(object.redemptionNoticePeriodDuration)
                : undefined,
            balance: isSet(object.balance) ? String(object.balance) : "",
            totalShare: isSet(object.totalShare) ? String(object.totalShare) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            marketTicker: isSet(object.marketTicker) ? String(object.marketTicker) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            expiry: isSet(object.expiry) ? String(object.expiry) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.depositDenom !== undefined && (obj.depositDenom = message.depositDenom);
        message.insurancePoolTokenDenom !== undefined && (obj.insurancePoolTokenDenom = message.insurancePoolTokenDenom);
        message.redemptionNoticePeriodDuration !== undefined &&
            (obj.redemptionNoticePeriodDuration = message.redemptionNoticePeriodDuration
                ? Duration.toJSON(message.redemptionNoticePeriodDuration)
                : undefined);
        message.balance !== undefined && (obj.balance = message.balance);
        message.totalShare !== undefined && (obj.totalShare = message.totalShare);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.marketTicker !== undefined && (obj.marketTicker = message.marketTicker);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.expiry !== undefined && (obj.expiry = message.expiry);
        return obj;
    },
    create(base) {
        return InsuranceFund.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInsuranceFund();
        message.depositDenom = object.depositDenom ?? "";
        message.insurancePoolTokenDenom = object.insurancePoolTokenDenom ?? "";
        message.redemptionNoticePeriodDuration =
            (object.redemptionNoticePeriodDuration !== undefined && object.redemptionNoticePeriodDuration !== null)
                ? Duration.fromPartial(object.redemptionNoticePeriodDuration)
                : undefined;
        message.balance = object.balance ?? "";
        message.totalShare = object.totalShare ?? "";
        message.marketId = object.marketId ?? "";
        message.marketTicker = object.marketTicker ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleType = object.oracleType ?? 0;
        message.expiry = object.expiry ?? "0";
        return message;
    },
};
function createBaseRedemptionSchedule() {
    return { id: "0", marketId: "", redeemer: "", claimableRedemptionTime: undefined, redemptionAmount: undefined };
}
export const RedemptionSchedule = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "0") {
            writer.uint32(8).uint64(message.id);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.redeemer !== "") {
            writer.uint32(26).string(message.redeemer);
        }
        if (message.claimableRedemptionTime !== undefined) {
            Timestamp.encode(toTimestamp(message.claimableRedemptionTime), writer.uint32(34).fork()).ldelim();
        }
        if (message.redemptionAmount !== undefined) {
            Coin.encode(message.redemptionAmount, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRedemptionSchedule();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.uint64());
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.redeemer = reader.string();
                    break;
                case 4:
                    message.claimableRedemptionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.redemptionAmount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            redeemer: isSet(object.redeemer) ? String(object.redeemer) : "",
            claimableRedemptionTime: isSet(object.claimableRedemptionTime)
                ? fromJsonTimestamp(object.claimableRedemptionTime)
                : undefined,
            redemptionAmount: isSet(object.redemptionAmount) ? Coin.fromJSON(object.redemptionAmount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.redeemer !== undefined && (obj.redeemer = message.redeemer);
        message.claimableRedemptionTime !== undefined &&
            (obj.claimableRedemptionTime = message.claimableRedemptionTime.toISOString());
        message.redemptionAmount !== undefined &&
            (obj.redemptionAmount = message.redemptionAmount ? Coin.toJSON(message.redemptionAmount) : undefined);
        return obj;
    },
    create(base) {
        return RedemptionSchedule.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRedemptionSchedule();
        message.id = object.id ?? "0";
        message.marketId = object.marketId ?? "";
        message.redeemer = object.redeemer ?? "";
        message.claimableRedemptionTime = object.claimableRedemptionTime ?? undefined;
        message.redemptionAmount = (object.redemptionAmount !== undefined && object.redemptionAmount !== null)
            ? Coin.fromPartial(object.redemptionAmount)
            : undefined;
        return message;
    },
};
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1_000).toString();
    const nanos = (date.getTime() % 1_000) * 1_000_000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = Number(t.seconds) * 1_000;
    millis += t.nanos / 1_000_000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
