/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective_archiver_rpc";
function createBaseBalanceRequest() {
    return { account: "", resolution: "" };
}
export const BalanceRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.resolution !== "") {
            writer.uint32(18).string(message.resolution);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBalanceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.resolution = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            resolution: isSet(object.resolution) ? String(object.resolution) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.resolution !== undefined && (obj.resolution = message.resolution);
        return obj;
    },
    create(base) {
        return BalanceRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBalanceRequest();
        message.account = object.account ?? "";
        message.resolution = object.resolution ?? "";
        return message;
    },
};
function createBaseBalanceResponse() {
    return { historicalBalance: undefined };
}
export const BalanceResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.historicalBalance !== undefined) {
            HistoricalBalance.encode(message.historicalBalance, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBalanceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.historicalBalance = HistoricalBalance.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            historicalBalance: isSet(object.historicalBalance)
                ? HistoricalBalance.fromJSON(object.historicalBalance)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.historicalBalance !== undefined && (obj.historicalBalance = message.historicalBalance
            ? HistoricalBalance.toJSON(message.historicalBalance)
            : undefined);
        return obj;
    },
    create(base) {
        return BalanceResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBalanceResponse();
        message.historicalBalance = (object.historicalBalance !== undefined && object.historicalBalance !== null)
            ? HistoricalBalance.fromPartial(object.historicalBalance)
            : undefined;
        return message;
    },
};
function createBaseHistoricalBalance() {
    return { t: [], v: [] };
}
export const HistoricalBalance = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.t) {
            writer.sint32(v);
        }
        writer.ldelim();
        writer.uint32(18).fork();
        for (const v of message.v) {
            writer.double(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHistoricalBalance();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.t.push(reader.sint32());
                        }
                    }
                    else {
                        message.t.push(reader.sint32());
                    }
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.v.push(reader.double());
                        }
                    }
                    else {
                        message.v.push(reader.double());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            t: Array.isArray(object?.t) ? object.t.map((e) => Number(e)) : [],
            v: Array.isArray(object?.v) ? object.v.map((e) => Number(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.t) {
            obj.t = message.t.map((e) => Math.round(e));
        }
        else {
            obj.t = [];
        }
        if (message.v) {
            obj.v = message.v.map((e) => e);
        }
        else {
            obj.v = [];
        }
        return obj;
    },
    create(base) {
        return HistoricalBalance.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseHistoricalBalance();
        message.t = object.t?.map((e) => e) || [];
        message.v = object.v?.map((e) => e) || [];
        return message;
    },
};
function createBaseRpnlRequest() {
    return { account: "", resolution: "" };
}
export const RpnlRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.resolution !== "") {
            writer.uint32(18).string(message.resolution);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRpnlRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.resolution = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            resolution: isSet(object.resolution) ? String(object.resolution) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.resolution !== undefined && (obj.resolution = message.resolution);
        return obj;
    },
    create(base) {
        return RpnlRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRpnlRequest();
        message.account = object.account ?? "";
        message.resolution = object.resolution ?? "";
        return message;
    },
};
function createBaseRpnlResponse() {
    return { historicalRpnl: undefined };
}
export const RpnlResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.historicalRpnl !== undefined) {
            HistoricalRPNL.encode(message.historicalRpnl, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRpnlResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.historicalRpnl = HistoricalRPNL.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            historicalRpnl: isSet(object.historicalRpnl) ? HistoricalRPNL.fromJSON(object.historicalRpnl) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.historicalRpnl !== undefined &&
            (obj.historicalRpnl = message.historicalRpnl ? HistoricalRPNL.toJSON(message.historicalRpnl) : undefined);
        return obj;
    },
    create(base) {
        return RpnlResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRpnlResponse();
        message.historicalRpnl = (object.historicalRpnl !== undefined && object.historicalRpnl !== null)
            ? HistoricalRPNL.fromPartial(object.historicalRpnl)
            : undefined;
        return message;
    },
};
function createBaseHistoricalRPNL() {
    return { t: [], v: [] };
}
export const HistoricalRPNL = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.t) {
            writer.sint32(v);
        }
        writer.ldelim();
        writer.uint32(18).fork();
        for (const v of message.v) {
            writer.double(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHistoricalRPNL();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.t.push(reader.sint32());
                        }
                    }
                    else {
                        message.t.push(reader.sint32());
                    }
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.v.push(reader.double());
                        }
                    }
                    else {
                        message.v.push(reader.double());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            t: Array.isArray(object?.t) ? object.t.map((e) => Number(e)) : [],
            v: Array.isArray(object?.v) ? object.v.map((e) => Number(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.t) {
            obj.t = message.t.map((e) => Math.round(e));
        }
        else {
            obj.t = [];
        }
        if (message.v) {
            obj.v = message.v.map((e) => e);
        }
        else {
            obj.v = [];
        }
        return obj;
    },
    create(base) {
        return HistoricalRPNL.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseHistoricalRPNL();
        message.t = object.t?.map((e) => e) || [];
        message.v = object.v?.map((e) => e) || [];
        return message;
    },
};
function createBaseVolumesRequest() {
    return { account: "", resolution: "" };
}
export const VolumesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.resolution !== "") {
            writer.uint32(18).string(message.resolution);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolumesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.resolution = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            resolution: isSet(object.resolution) ? String(object.resolution) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.resolution !== undefined && (obj.resolution = message.resolution);
        return obj;
    },
    create(base) {
        return VolumesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolumesRequest();
        message.account = object.account ?? "";
        message.resolution = object.resolution ?? "";
        return message;
    },
};
function createBaseVolumesResponse() {
    return { historicalVolumes: undefined };
}
export const VolumesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.historicalVolumes !== undefined) {
            HistoricalVolumes.encode(message.historicalVolumes, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolumesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.historicalVolumes = HistoricalVolumes.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            historicalVolumes: isSet(object.historicalVolumes)
                ? HistoricalVolumes.fromJSON(object.historicalVolumes)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.historicalVolumes !== undefined && (obj.historicalVolumes = message.historicalVolumes
            ? HistoricalVolumes.toJSON(message.historicalVolumes)
            : undefined);
        return obj;
    },
    create(base) {
        return VolumesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolumesResponse();
        message.historicalVolumes = (object.historicalVolumes !== undefined && object.historicalVolumes !== null)
            ? HistoricalVolumes.fromPartial(object.historicalVolumes)
            : undefined;
        return message;
    },
};
function createBaseHistoricalVolumes() {
    return { t: [], v: [] };
}
export const HistoricalVolumes = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.t) {
            writer.sint32(v);
        }
        writer.ldelim();
        writer.uint32(18).fork();
        for (const v of message.v) {
            writer.double(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHistoricalVolumes();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.t.push(reader.sint32());
                        }
                    }
                    else {
                        message.t.push(reader.sint32());
                    }
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.v.push(reader.double());
                        }
                    }
                    else {
                        message.v.push(reader.double());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            t: Array.isArray(object?.t) ? object.t.map((e) => Number(e)) : [],
            v: Array.isArray(object?.v) ? object.v.map((e) => Number(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.t) {
            obj.t = message.t.map((e) => Math.round(e));
        }
        else {
            obj.t = [];
        }
        if (message.v) {
            obj.v = message.v.map((e) => e);
        }
        else {
            obj.v = [];
        }
        return obj;
    },
    create(base) {
        return HistoricalVolumes.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseHistoricalVolumes();
        message.t = object.t?.map((e) => e) || [];
        message.v = object.v?.map((e) => e) || [];
        return message;
    },
};
function createBasePnlLeaderboardRequest() {
    return { startDate: "0", endDate: "0", limit: 0, account: "" };
}
export const PnlLeaderboardRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.startDate !== "0") {
            writer.uint32(8).sint64(message.startDate);
        }
        if (message.endDate !== "0") {
            writer.uint32(16).sint64(message.endDate);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.account !== "") {
            writer.uint32(34).string(message.account);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePnlLeaderboardRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.startDate = longToString(reader.sint64());
                    break;
                case 2:
                    message.endDate = longToString(reader.sint64());
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.account = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            startDate: isSet(object.startDate) ? String(object.startDate) : "0",
            endDate: isSet(object.endDate) ? String(object.endDate) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            account: isSet(object.account) ? String(object.account) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.startDate !== undefined && (obj.startDate = message.startDate);
        message.endDate !== undefined && (obj.endDate = message.endDate);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.account !== undefined && (obj.account = message.account);
        return obj;
    },
    create(base) {
        return PnlLeaderboardRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePnlLeaderboardRequest();
        message.startDate = object.startDate ?? "0";
        message.endDate = object.endDate ?? "0";
        message.limit = object.limit ?? 0;
        message.account = object.account ?? "";
        return message;
    },
};
function createBasePnlLeaderboardResponse() {
    return { firstDate: "", lastDate: "", leaders: [], accountRow: undefined };
}
export const PnlLeaderboardResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.firstDate !== "") {
            writer.uint32(10).string(message.firstDate);
        }
        if (message.lastDate !== "") {
            writer.uint32(18).string(message.lastDate);
        }
        for (const v of message.leaders) {
            LeaderboardRow.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.accountRow !== undefined) {
            LeaderboardRow.encode(message.accountRow, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePnlLeaderboardResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.firstDate = reader.string();
                    break;
                case 2:
                    message.lastDate = reader.string();
                    break;
                case 3:
                    message.leaders.push(LeaderboardRow.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.accountRow = LeaderboardRow.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            firstDate: isSet(object.firstDate) ? String(object.firstDate) : "",
            lastDate: isSet(object.lastDate) ? String(object.lastDate) : "",
            leaders: Array.isArray(object?.leaders) ? object.leaders.map((e) => LeaderboardRow.fromJSON(e)) : [],
            accountRow: isSet(object.accountRow) ? LeaderboardRow.fromJSON(object.accountRow) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.firstDate !== undefined && (obj.firstDate = message.firstDate);
        message.lastDate !== undefined && (obj.lastDate = message.lastDate);
        if (message.leaders) {
            obj.leaders = message.leaders.map((e) => e ? LeaderboardRow.toJSON(e) : undefined);
        }
        else {
            obj.leaders = [];
        }
        message.accountRow !== undefined &&
            (obj.accountRow = message.accountRow ? LeaderboardRow.toJSON(message.accountRow) : undefined);
        return obj;
    },
    create(base) {
        return PnlLeaderboardResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePnlLeaderboardResponse();
        message.firstDate = object.firstDate ?? "";
        message.lastDate = object.lastDate ?? "";
        message.leaders = object.leaders?.map((e) => LeaderboardRow.fromPartial(e)) || [];
        message.accountRow = (object.accountRow !== undefined && object.accountRow !== null)
            ? LeaderboardRow.fromPartial(object.accountRow)
            : undefined;
        return message;
    },
};
function createBaseLeaderboardRow() {
    return { account: "", pnl: 0, volume: 0, rank: 0 };
}
export const LeaderboardRow = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.pnl !== 0) {
            writer.uint32(17).double(message.pnl);
        }
        if (message.volume !== 0) {
            writer.uint32(25).double(message.volume);
        }
        if (message.rank !== 0) {
            writer.uint32(32).sint32(message.rank);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLeaderboardRow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.pnl = reader.double();
                    break;
                case 3:
                    message.volume = reader.double();
                    break;
                case 4:
                    message.rank = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            pnl: isSet(object.pnl) ? Number(object.pnl) : 0,
            volume: isSet(object.volume) ? Number(object.volume) : 0,
            rank: isSet(object.rank) ? Number(object.rank) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.pnl !== undefined && (obj.pnl = message.pnl);
        message.volume !== undefined && (obj.volume = message.volume);
        message.rank !== undefined && (obj.rank = Math.round(message.rank));
        return obj;
    },
    create(base) {
        return LeaderboardRow.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLeaderboardRow();
        message.account = object.account ?? "";
        message.pnl = object.pnl ?? 0;
        message.volume = object.volume ?? 0;
        message.rank = object.rank ?? 0;
        return message;
    },
};
function createBaseVolLeaderboardRequest() {
    return { startDate: "0", endDate: "0", limit: 0, account: "" };
}
export const VolLeaderboardRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.startDate !== "0") {
            writer.uint32(8).sint64(message.startDate);
        }
        if (message.endDate !== "0") {
            writer.uint32(16).sint64(message.endDate);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.account !== "") {
            writer.uint32(34).string(message.account);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolLeaderboardRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.startDate = longToString(reader.sint64());
                    break;
                case 2:
                    message.endDate = longToString(reader.sint64());
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.account = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            startDate: isSet(object.startDate) ? String(object.startDate) : "0",
            endDate: isSet(object.endDate) ? String(object.endDate) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            account: isSet(object.account) ? String(object.account) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.startDate !== undefined && (obj.startDate = message.startDate);
        message.endDate !== undefined && (obj.endDate = message.endDate);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.account !== undefined && (obj.account = message.account);
        return obj;
    },
    create(base) {
        return VolLeaderboardRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolLeaderboardRequest();
        message.startDate = object.startDate ?? "0";
        message.endDate = object.endDate ?? "0";
        message.limit = object.limit ?? 0;
        message.account = object.account ?? "";
        return message;
    },
};
function createBaseVolLeaderboardResponse() {
    return { firstDate: "", lastDate: "", leaders: [], accountRow: undefined };
}
export const VolLeaderboardResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.firstDate !== "") {
            writer.uint32(10).string(message.firstDate);
        }
        if (message.lastDate !== "") {
            writer.uint32(18).string(message.lastDate);
        }
        for (const v of message.leaders) {
            LeaderboardRow.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.accountRow !== undefined) {
            LeaderboardRow.encode(message.accountRow, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolLeaderboardResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.firstDate = reader.string();
                    break;
                case 2:
                    message.lastDate = reader.string();
                    break;
                case 3:
                    message.leaders.push(LeaderboardRow.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.accountRow = LeaderboardRow.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            firstDate: isSet(object.firstDate) ? String(object.firstDate) : "",
            lastDate: isSet(object.lastDate) ? String(object.lastDate) : "",
            leaders: Array.isArray(object?.leaders) ? object.leaders.map((e) => LeaderboardRow.fromJSON(e)) : [],
            accountRow: isSet(object.accountRow) ? LeaderboardRow.fromJSON(object.accountRow) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.firstDate !== undefined && (obj.firstDate = message.firstDate);
        message.lastDate !== undefined && (obj.lastDate = message.lastDate);
        if (message.leaders) {
            obj.leaders = message.leaders.map((e) => e ? LeaderboardRow.toJSON(e) : undefined);
        }
        else {
            obj.leaders = [];
        }
        message.accountRow !== undefined &&
            (obj.accountRow = message.accountRow ? LeaderboardRow.toJSON(message.accountRow) : undefined);
        return obj;
    },
    create(base) {
        return VolLeaderboardResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolLeaderboardResponse();
        message.firstDate = object.firstDate ?? "";
        message.lastDate = object.lastDate ?? "";
        message.leaders = object.leaders?.map((e) => LeaderboardRow.fromPartial(e)) || [];
        message.accountRow = (object.accountRow !== undefined && object.accountRow !== null)
            ? LeaderboardRow.fromPartial(object.accountRow)
            : undefined;
        return message;
    },
};
function createBasePnlLeaderboardFixedResolutionRequest() {
    return { resolution: "", limit: 0, account: "" };
}
export const PnlLeaderboardFixedResolutionRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.resolution !== "") {
            writer.uint32(10).string(message.resolution);
        }
        if (message.limit !== 0) {
            writer.uint32(16).sint32(message.limit);
        }
        if (message.account !== "") {
            writer.uint32(26).string(message.account);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePnlLeaderboardFixedResolutionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.resolution = reader.string();
                    break;
                case 2:
                    message.limit = reader.sint32();
                    break;
                case 3:
                    message.account = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            resolution: isSet(object.resolution) ? String(object.resolution) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            account: isSet(object.account) ? String(object.account) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.resolution !== undefined && (obj.resolution = message.resolution);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.account !== undefined && (obj.account = message.account);
        return obj;
    },
    create(base) {
        return PnlLeaderboardFixedResolutionRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePnlLeaderboardFixedResolutionRequest();
        message.resolution = object.resolution ?? "";
        message.limit = object.limit ?? 0;
        message.account = object.account ?? "";
        return message;
    },
};
function createBasePnlLeaderboardFixedResolutionResponse() {
    return { firstDate: "", lastDate: "", leaders: [], accountRow: undefined };
}
export const PnlLeaderboardFixedResolutionResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.firstDate !== "") {
            writer.uint32(10).string(message.firstDate);
        }
        if (message.lastDate !== "") {
            writer.uint32(18).string(message.lastDate);
        }
        for (const v of message.leaders) {
            LeaderboardRow.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.accountRow !== undefined) {
            LeaderboardRow.encode(message.accountRow, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePnlLeaderboardFixedResolutionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.firstDate = reader.string();
                    break;
                case 2:
                    message.lastDate = reader.string();
                    break;
                case 3:
                    message.leaders.push(LeaderboardRow.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.accountRow = LeaderboardRow.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            firstDate: isSet(object.firstDate) ? String(object.firstDate) : "",
            lastDate: isSet(object.lastDate) ? String(object.lastDate) : "",
            leaders: Array.isArray(object?.leaders) ? object.leaders.map((e) => LeaderboardRow.fromJSON(e)) : [],
            accountRow: isSet(object.accountRow) ? LeaderboardRow.fromJSON(object.accountRow) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.firstDate !== undefined && (obj.firstDate = message.firstDate);
        message.lastDate !== undefined && (obj.lastDate = message.lastDate);
        if (message.leaders) {
            obj.leaders = message.leaders.map((e) => e ? LeaderboardRow.toJSON(e) : undefined);
        }
        else {
            obj.leaders = [];
        }
        message.accountRow !== undefined &&
            (obj.accountRow = message.accountRow ? LeaderboardRow.toJSON(message.accountRow) : undefined);
        return obj;
    },
    create(base) {
        return PnlLeaderboardFixedResolutionResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePnlLeaderboardFixedResolutionResponse();
        message.firstDate = object.firstDate ?? "";
        message.lastDate = object.lastDate ?? "";
        message.leaders = object.leaders?.map((e) => LeaderboardRow.fromPartial(e)) || [];
        message.accountRow = (object.accountRow !== undefined && object.accountRow !== null)
            ? LeaderboardRow.fromPartial(object.accountRow)
            : undefined;
        return message;
    },
};
function createBaseVolLeaderboardFixedResolutionRequest() {
    return { resolution: "", limit: 0, account: "" };
}
export const VolLeaderboardFixedResolutionRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.resolution !== "") {
            writer.uint32(10).string(message.resolution);
        }
        if (message.limit !== 0) {
            writer.uint32(16).sint32(message.limit);
        }
        if (message.account !== "") {
            writer.uint32(26).string(message.account);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolLeaderboardFixedResolutionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.resolution = reader.string();
                    break;
                case 2:
                    message.limit = reader.sint32();
                    break;
                case 3:
                    message.account = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            resolution: isSet(object.resolution) ? String(object.resolution) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            account: isSet(object.account) ? String(object.account) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.resolution !== undefined && (obj.resolution = message.resolution);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.account !== undefined && (obj.account = message.account);
        return obj;
    },
    create(base) {
        return VolLeaderboardFixedResolutionRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolLeaderboardFixedResolutionRequest();
        message.resolution = object.resolution ?? "";
        message.limit = object.limit ?? 0;
        message.account = object.account ?? "";
        return message;
    },
};
function createBaseVolLeaderboardFixedResolutionResponse() {
    return { firstDate: "", lastDate: "", leaders: [], accountRow: undefined };
}
export const VolLeaderboardFixedResolutionResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.firstDate !== "") {
            writer.uint32(10).string(message.firstDate);
        }
        if (message.lastDate !== "") {
            writer.uint32(18).string(message.lastDate);
        }
        for (const v of message.leaders) {
            LeaderboardRow.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.accountRow !== undefined) {
            LeaderboardRow.encode(message.accountRow, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolLeaderboardFixedResolutionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.firstDate = reader.string();
                    break;
                case 2:
                    message.lastDate = reader.string();
                    break;
                case 3:
                    message.leaders.push(LeaderboardRow.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.accountRow = LeaderboardRow.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            firstDate: isSet(object.firstDate) ? String(object.firstDate) : "",
            lastDate: isSet(object.lastDate) ? String(object.lastDate) : "",
            leaders: Array.isArray(object?.leaders) ? object.leaders.map((e) => LeaderboardRow.fromJSON(e)) : [],
            accountRow: isSet(object.accountRow) ? LeaderboardRow.fromJSON(object.accountRow) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.firstDate !== undefined && (obj.firstDate = message.firstDate);
        message.lastDate !== undefined && (obj.lastDate = message.lastDate);
        if (message.leaders) {
            obj.leaders = message.leaders.map((e) => e ? LeaderboardRow.toJSON(e) : undefined);
        }
        else {
            obj.leaders = [];
        }
        message.accountRow !== undefined &&
            (obj.accountRow = message.accountRow ? LeaderboardRow.toJSON(message.accountRow) : undefined);
        return obj;
    },
    create(base) {
        return VolLeaderboardFixedResolutionResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolLeaderboardFixedResolutionResponse();
        message.firstDate = object.firstDate ?? "";
        message.lastDate = object.lastDate ?? "";
        message.leaders = object.leaders?.map((e) => LeaderboardRow.fromPartial(e)) || [];
        message.accountRow = (object.accountRow !== undefined && object.accountRow !== null)
            ? LeaderboardRow.fromPartial(object.accountRow)
            : undefined;
        return message;
    },
};
function createBaseDenomHoldersRequest() {
    return { denom: "", token: "", limit: 0 };
}
export const DenomHoldersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.token !== "") {
            writer.uint32(18).string(message.token);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDenomHoldersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            token: isSet(object.token) ? String(object.token) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.token !== undefined && (obj.token = message.token);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return DenomHoldersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDenomHoldersRequest();
        message.denom = object.denom ?? "";
        message.token = object.token ?? "";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseDenomHoldersResponse() {
    return { holders: [], next: [] };
}
export const DenomHoldersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.holders) {
            Holder.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.next) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDenomHoldersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.holders.push(Holder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            holders: Array.isArray(object?.holders) ? object.holders.map((e) => Holder.fromJSON(e)) : [],
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.holders) {
            obj.holders = message.holders.map((e) => e ? Holder.toJSON(e) : undefined);
        }
        else {
            obj.holders = [];
        }
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return DenomHoldersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDenomHoldersResponse();
        message.holders = object.holders?.map((e) => Holder.fromPartial(e)) || [];
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseHolder() {
    return { accountAddress: "", balance: "" };
}
export const Holder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountAddress !== "") {
            writer.uint32(10).string(message.accountAddress);
        }
        if (message.balance !== "") {
            writer.uint32(18).string(message.balance);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHolder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountAddress = reader.string();
                    break;
                case 2:
                    message.balance = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            balance: isSet(object.balance) ? String(object.balance) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.balance !== undefined && (obj.balance = message.balance);
        return obj;
    },
    create(base) {
        return Holder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseHolder();
        message.accountAddress = object.accountAddress ?? "";
        message.balance = object.balance ?? "";
        return message;
    },
};
export class InjectiveArchiverRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Balance = this.Balance.bind(this);
        this.Rpnl = this.Rpnl.bind(this);
        this.Volumes = this.Volumes.bind(this);
        this.PnlLeaderboard = this.PnlLeaderboard.bind(this);
        this.VolLeaderboard = this.VolLeaderboard.bind(this);
        this.PnlLeaderboardFixedResolution = this.PnlLeaderboardFixedResolution.bind(this);
        this.VolLeaderboardFixedResolution = this.VolLeaderboardFixedResolution.bind(this);
        this.DenomHolders = this.DenomHolders.bind(this);
    }
    Balance(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCBalanceDesc, BalanceRequest.fromPartial(request), metadata);
    }
    Rpnl(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCRpnlDesc, RpnlRequest.fromPartial(request), metadata);
    }
    Volumes(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCVolumesDesc, VolumesRequest.fromPartial(request), metadata);
    }
    PnlLeaderboard(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCPnlLeaderboardDesc, PnlLeaderboardRequest.fromPartial(request), metadata);
    }
    VolLeaderboard(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCVolLeaderboardDesc, VolLeaderboardRequest.fromPartial(request), metadata);
    }
    PnlLeaderboardFixedResolution(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCPnlLeaderboardFixedResolutionDesc, PnlLeaderboardFixedResolutionRequest.fromPartial(request), metadata);
    }
    VolLeaderboardFixedResolution(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCVolLeaderboardFixedResolutionDesc, VolLeaderboardFixedResolutionRequest.fromPartial(request), metadata);
    }
    DenomHolders(request, metadata) {
        return this.rpc.unary(InjectiveArchiverRPCDenomHoldersDesc, DenomHoldersRequest.fromPartial(request), metadata);
    }
}
export const InjectiveArchiverRPCDesc = { serviceName: "injective_archiver_rpc.InjectiveArchiverRPC" };
export const InjectiveArchiverRPCBalanceDesc = {
    methodName: "Balance",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return BalanceRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = BalanceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCRpnlDesc = {
    methodName: "Rpnl",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return RpnlRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = RpnlResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCVolumesDesc = {
    methodName: "Volumes",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return VolumesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = VolumesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCPnlLeaderboardDesc = {
    methodName: "PnlLeaderboard",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PnlLeaderboardRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PnlLeaderboardResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCVolLeaderboardDesc = {
    methodName: "VolLeaderboard",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return VolLeaderboardRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = VolLeaderboardResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCPnlLeaderboardFixedResolutionDesc = {
    methodName: "PnlLeaderboardFixedResolution",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PnlLeaderboardFixedResolutionRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PnlLeaderboardFixedResolutionResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCVolLeaderboardFixedResolutionDesc = {
    methodName: "VolLeaderboardFixedResolution",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return VolLeaderboardFixedResolutionRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = VolLeaderboardFixedResolutionResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveArchiverRPCDenomHoldersDesc = {
    methodName: "DenomHolders",
    service: InjectiveArchiverRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return DenomHoldersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = DenomHoldersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
