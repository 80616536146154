/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal.js";
import { ExecTxResult, ResponseCheckTx } from "../../../abci/v1beta3/types.js";
import { RequestBroadcastTx, RequestPing, ResponsePing } from "../v1beta1/types.js";
export const protobufPackage = "cometbft.rpc.grpc.v1beta3";
function createBaseResponseBroadcastTx() {
    return { checkTx: undefined, txResult: undefined };
}
export const ResponseBroadcastTx = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.checkTx !== undefined) {
            ResponseCheckTx.encode(message.checkTx, writer.uint32(10).fork()).ldelim();
        }
        if (message.txResult !== undefined) {
            ExecTxResult.encode(message.txResult, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponseBroadcastTx();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.checkTx = ResponseCheckTx.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.txResult = ExecTxResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            checkTx: isSet(object.checkTx) ? ResponseCheckTx.fromJSON(object.checkTx) : undefined,
            txResult: isSet(object.txResult) ? ExecTxResult.fromJSON(object.txResult) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.checkTx !== undefined &&
            (obj.checkTx = message.checkTx ? ResponseCheckTx.toJSON(message.checkTx) : undefined);
        message.txResult !== undefined &&
            (obj.txResult = message.txResult ? ExecTxResult.toJSON(message.txResult) : undefined);
        return obj;
    },
    create(base) {
        return ResponseBroadcastTx.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseResponseBroadcastTx();
        message.checkTx = (object.checkTx !== undefined && object.checkTx !== null)
            ? ResponseCheckTx.fromPartial(object.checkTx)
            : undefined;
        message.txResult = (object.txResult !== undefined && object.txResult !== null)
            ? ExecTxResult.fromPartial(object.txResult)
            : undefined;
        return message;
    },
};
export class BroadcastAPIClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Ping = this.Ping.bind(this);
        this.BroadcastTx = this.BroadcastTx.bind(this);
    }
    Ping(request, metadata) {
        return this.rpc.unary(BroadcastAPIPingDesc, RequestPing.fromPartial(request), metadata);
    }
    BroadcastTx(request, metadata) {
        return this.rpc.unary(BroadcastAPIBroadcastTxDesc, RequestBroadcastTx.fromPartial(request), metadata);
    }
}
export const BroadcastAPIDesc = { serviceName: "cometbft.rpc.grpc.v1beta3.BroadcastAPI" };
export const BroadcastAPIPingDesc = {
    methodName: "Ping",
    service: BroadcastAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return RequestPing.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = ResponsePing.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const BroadcastAPIBroadcastTxDesc = {
    methodName: "BroadcastTx",
    service: BroadcastAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return RequestBroadcastTx.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = ResponseBroadcastTx.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
