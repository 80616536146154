/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal.js";
import { Params } from "./params.js";
import { AddressRoles, Namespace, Role } from "./permissions.js";
export const protobufPackage = "injective.permissions.v1beta1";
function createBaseMsgUpdateParams() {
    return { authority: "", params: undefined };
}
export const MsgUpdateParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.authority !== "") {
            writer.uint32(10).string(message.authority);
        }
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.authority = reader.string();
                    break;
                case 2:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            authority: isSet(object.authority) ? String(object.authority) : "",
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.authority !== undefined && (obj.authority = message.authority);
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return MsgUpdateParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateParams();
        message.authority = object.authority ?? "";
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseMsgUpdateParamsResponse() {
    return {};
}
export const MsgUpdateParamsResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateParamsResponse();
        return message;
    },
};
function createBaseMsgCreateNamespace() {
    return { sender: "", namespace: undefined };
}
export const MsgCreateNamespace = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.namespace !== undefined) {
            Namespace.encode(message.namespace, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateNamespace();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.namespace = Namespace.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            namespace: isSet(object.namespace) ? Namespace.fromJSON(object.namespace) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.namespace !== undefined &&
            (obj.namespace = message.namespace ? Namespace.toJSON(message.namespace) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateNamespace.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateNamespace();
        message.sender = object.sender ?? "";
        message.namespace = (object.namespace !== undefined && object.namespace !== null)
            ? Namespace.fromPartial(object.namespace)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateNamespaceResponse() {
    return {};
}
export const MsgCreateNamespaceResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateNamespaceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgCreateNamespaceResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgCreateNamespaceResponse();
        return message;
    },
};
function createBaseMsgDeleteNamespace() {
    return { sender: "", namespaceDenom: "" };
}
export const MsgDeleteNamespace = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.namespaceDenom !== "") {
            writer.uint32(18).string(message.namespaceDenom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDeleteNamespace();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.namespaceDenom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            namespaceDenom: isSet(object.namespaceDenom) ? String(object.namespaceDenom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.namespaceDenom !== undefined && (obj.namespaceDenom = message.namespaceDenom);
        return obj;
    },
    create(base) {
        return MsgDeleteNamespace.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgDeleteNamespace();
        message.sender = object.sender ?? "";
        message.namespaceDenom = object.namespaceDenom ?? "";
        return message;
    },
};
function createBaseMsgDeleteNamespaceResponse() {
    return {};
}
export const MsgDeleteNamespaceResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDeleteNamespaceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgDeleteNamespaceResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgDeleteNamespaceResponse();
        return message;
    },
};
function createBaseMsgUpdateNamespace() {
    return {
        sender: "",
        namespaceDenom: "",
        wasmHook: undefined,
        mintsPaused: undefined,
        sendsPaused: undefined,
        burnsPaused: undefined,
    };
}
export const MsgUpdateNamespace = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.namespaceDenom !== "") {
            writer.uint32(18).string(message.namespaceDenom);
        }
        if (message.wasmHook !== undefined) {
            MsgUpdateNamespace_MsgSetWasmHook.encode(message.wasmHook, writer.uint32(26).fork()).ldelim();
        }
        if (message.mintsPaused !== undefined) {
            MsgUpdateNamespace_MsgSetMintsPaused.encode(message.mintsPaused, writer.uint32(34).fork()).ldelim();
        }
        if (message.sendsPaused !== undefined) {
            MsgUpdateNamespace_MsgSetSendsPaused.encode(message.sendsPaused, writer.uint32(42).fork()).ldelim();
        }
        if (message.burnsPaused !== undefined) {
            MsgUpdateNamespace_MsgSetBurnsPaused.encode(message.burnsPaused, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespace();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.namespaceDenom = reader.string();
                    break;
                case 3:
                    message.wasmHook = MsgUpdateNamespace_MsgSetWasmHook.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.mintsPaused = MsgUpdateNamespace_MsgSetMintsPaused.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.sendsPaused = MsgUpdateNamespace_MsgSetSendsPaused.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.burnsPaused = MsgUpdateNamespace_MsgSetBurnsPaused.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            namespaceDenom: isSet(object.namespaceDenom) ? String(object.namespaceDenom) : "",
            wasmHook: isSet(object.wasmHook) ? MsgUpdateNamespace_MsgSetWasmHook.fromJSON(object.wasmHook) : undefined,
            mintsPaused: isSet(object.mintsPaused)
                ? MsgUpdateNamespace_MsgSetMintsPaused.fromJSON(object.mintsPaused)
                : undefined,
            sendsPaused: isSet(object.sendsPaused)
                ? MsgUpdateNamespace_MsgSetSendsPaused.fromJSON(object.sendsPaused)
                : undefined,
            burnsPaused: isSet(object.burnsPaused)
                ? MsgUpdateNamespace_MsgSetBurnsPaused.fromJSON(object.burnsPaused)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.namespaceDenom !== undefined && (obj.namespaceDenom = message.namespaceDenom);
        message.wasmHook !== undefined &&
            (obj.wasmHook = message.wasmHook ? MsgUpdateNamespace_MsgSetWasmHook.toJSON(message.wasmHook) : undefined);
        message.mintsPaused !== undefined && (obj.mintsPaused = message.mintsPaused
            ? MsgUpdateNamespace_MsgSetMintsPaused.toJSON(message.mintsPaused)
            : undefined);
        message.sendsPaused !== undefined && (obj.sendsPaused = message.sendsPaused
            ? MsgUpdateNamespace_MsgSetSendsPaused.toJSON(message.sendsPaused)
            : undefined);
        message.burnsPaused !== undefined && (obj.burnsPaused = message.burnsPaused
            ? MsgUpdateNamespace_MsgSetBurnsPaused.toJSON(message.burnsPaused)
            : undefined);
        return obj;
    },
    create(base) {
        return MsgUpdateNamespace.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateNamespace();
        message.sender = object.sender ?? "";
        message.namespaceDenom = object.namespaceDenom ?? "";
        message.wasmHook = (object.wasmHook !== undefined && object.wasmHook !== null)
            ? MsgUpdateNamespace_MsgSetWasmHook.fromPartial(object.wasmHook)
            : undefined;
        message.mintsPaused = (object.mintsPaused !== undefined && object.mintsPaused !== null)
            ? MsgUpdateNamespace_MsgSetMintsPaused.fromPartial(object.mintsPaused)
            : undefined;
        message.sendsPaused = (object.sendsPaused !== undefined && object.sendsPaused !== null)
            ? MsgUpdateNamespace_MsgSetSendsPaused.fromPartial(object.sendsPaused)
            : undefined;
        message.burnsPaused = (object.burnsPaused !== undefined && object.burnsPaused !== null)
            ? MsgUpdateNamespace_MsgSetBurnsPaused.fromPartial(object.burnsPaused)
            : undefined;
        return message;
    },
};
function createBaseMsgUpdateNamespace_MsgSetWasmHook() {
    return { newValue: "" };
}
export const MsgUpdateNamespace_MsgSetWasmHook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.newValue !== "") {
            writer.uint32(10).string(message.newValue);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespace_MsgSetWasmHook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.newValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { newValue: isSet(object.newValue) ? String(object.newValue) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.newValue !== undefined && (obj.newValue = message.newValue);
        return obj;
    },
    create(base) {
        return MsgUpdateNamespace_MsgSetWasmHook.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateNamespace_MsgSetWasmHook();
        message.newValue = object.newValue ?? "";
        return message;
    },
};
function createBaseMsgUpdateNamespace_MsgSetMintsPaused() {
    return { newValue: false };
}
export const MsgUpdateNamespace_MsgSetMintsPaused = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.newValue === true) {
            writer.uint32(8).bool(message.newValue);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespace_MsgSetMintsPaused();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.newValue = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { newValue: isSet(object.newValue) ? Boolean(object.newValue) : false };
    },
    toJSON(message) {
        const obj = {};
        message.newValue !== undefined && (obj.newValue = message.newValue);
        return obj;
    },
    create(base) {
        return MsgUpdateNamespace_MsgSetMintsPaused.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateNamespace_MsgSetMintsPaused();
        message.newValue = object.newValue ?? false;
        return message;
    },
};
function createBaseMsgUpdateNamespace_MsgSetSendsPaused() {
    return { newValue: false };
}
export const MsgUpdateNamespace_MsgSetSendsPaused = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.newValue === true) {
            writer.uint32(8).bool(message.newValue);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespace_MsgSetSendsPaused();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.newValue = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { newValue: isSet(object.newValue) ? Boolean(object.newValue) : false };
    },
    toJSON(message) {
        const obj = {};
        message.newValue !== undefined && (obj.newValue = message.newValue);
        return obj;
    },
    create(base) {
        return MsgUpdateNamespace_MsgSetSendsPaused.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateNamespace_MsgSetSendsPaused();
        message.newValue = object.newValue ?? false;
        return message;
    },
};
function createBaseMsgUpdateNamespace_MsgSetBurnsPaused() {
    return { newValue: false };
}
export const MsgUpdateNamespace_MsgSetBurnsPaused = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.newValue === true) {
            writer.uint32(8).bool(message.newValue);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespace_MsgSetBurnsPaused();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.newValue = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { newValue: isSet(object.newValue) ? Boolean(object.newValue) : false };
    },
    toJSON(message) {
        const obj = {};
        message.newValue !== undefined && (obj.newValue = message.newValue);
        return obj;
    },
    create(base) {
        return MsgUpdateNamespace_MsgSetBurnsPaused.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateNamespace_MsgSetBurnsPaused();
        message.newValue = object.newValue ?? false;
        return message;
    },
};
function createBaseMsgUpdateNamespaceResponse() {
    return {};
}
export const MsgUpdateNamespaceResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespaceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateNamespaceResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateNamespaceResponse();
        return message;
    },
};
function createBaseMsgUpdateNamespaceRoles() {
    return { sender: "", namespaceDenom: "", rolePermissions: [], addressRoles: [] };
}
export const MsgUpdateNamespaceRoles = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.namespaceDenom !== "") {
            writer.uint32(18).string(message.namespaceDenom);
        }
        for (const v of message.rolePermissions) {
            Role.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.addressRoles) {
            AddressRoles.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespaceRoles();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.namespaceDenom = reader.string();
                    break;
                case 3:
                    message.rolePermissions.push(Role.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.addressRoles.push(AddressRoles.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            namespaceDenom: isSet(object.namespaceDenom) ? String(object.namespaceDenom) : "",
            rolePermissions: Array.isArray(object?.rolePermissions)
                ? object.rolePermissions.map((e) => Role.fromJSON(e))
                : [],
            addressRoles: Array.isArray(object?.addressRoles)
                ? object.addressRoles.map((e) => AddressRoles.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.namespaceDenom !== undefined && (obj.namespaceDenom = message.namespaceDenom);
        if (message.rolePermissions) {
            obj.rolePermissions = message.rolePermissions.map((e) => e ? Role.toJSON(e) : undefined);
        }
        else {
            obj.rolePermissions = [];
        }
        if (message.addressRoles) {
            obj.addressRoles = message.addressRoles.map((e) => e ? AddressRoles.toJSON(e) : undefined);
        }
        else {
            obj.addressRoles = [];
        }
        return obj;
    },
    create(base) {
        return MsgUpdateNamespaceRoles.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateNamespaceRoles();
        message.sender = object.sender ?? "";
        message.namespaceDenom = object.namespaceDenom ?? "";
        message.rolePermissions = object.rolePermissions?.map((e) => Role.fromPartial(e)) || [];
        message.addressRoles = object.addressRoles?.map((e) => AddressRoles.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgUpdateNamespaceRolesResponse() {
    return {};
}
export const MsgUpdateNamespaceRolesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateNamespaceRolesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateNamespaceRolesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateNamespaceRolesResponse();
        return message;
    },
};
function createBaseMsgRevokeNamespaceRoles() {
    return { sender: "", namespaceDenom: "", addressRolesToRevoke: [] };
}
export const MsgRevokeNamespaceRoles = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.namespaceDenom !== "") {
            writer.uint32(18).string(message.namespaceDenom);
        }
        for (const v of message.addressRolesToRevoke) {
            AddressRoles.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRevokeNamespaceRoles();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.namespaceDenom = reader.string();
                    break;
                case 3:
                    message.addressRolesToRevoke.push(AddressRoles.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            namespaceDenom: isSet(object.namespaceDenom) ? String(object.namespaceDenom) : "",
            addressRolesToRevoke: Array.isArray(object?.addressRolesToRevoke)
                ? object.addressRolesToRevoke.map((e) => AddressRoles.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.namespaceDenom !== undefined && (obj.namespaceDenom = message.namespaceDenom);
        if (message.addressRolesToRevoke) {
            obj.addressRolesToRevoke = message.addressRolesToRevoke.map((e) => e ? AddressRoles.toJSON(e) : undefined);
        }
        else {
            obj.addressRolesToRevoke = [];
        }
        return obj;
    },
    create(base) {
        return MsgRevokeNamespaceRoles.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRevokeNamespaceRoles();
        message.sender = object.sender ?? "";
        message.namespaceDenom = object.namespaceDenom ?? "";
        message.addressRolesToRevoke = object.addressRolesToRevoke?.map((e) => AddressRoles.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgRevokeNamespaceRolesResponse() {
    return {};
}
export const MsgRevokeNamespaceRolesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRevokeNamespaceRolesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRevokeNamespaceRolesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRevokeNamespaceRolesResponse();
        return message;
    },
};
function createBaseMsgClaimVoucher() {
    return { sender: "", denom: "" };
}
export const MsgClaimVoucher = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgClaimVoucher();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.denom !== undefined && (obj.denom = message.denom);
        return obj;
    },
    create(base) {
        return MsgClaimVoucher.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgClaimVoucher();
        message.sender = object.sender ?? "";
        message.denom = object.denom ?? "";
        return message;
    },
};
function createBaseMsgClaimVoucherResponse() {
    return {};
}
export const MsgClaimVoucherResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgClaimVoucherResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgClaimVoucherResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgClaimVoucherResponse();
        return message;
    },
};
export class MsgClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.UpdateParams = this.UpdateParams.bind(this);
        this.CreateNamespace = this.CreateNamespace.bind(this);
        this.DeleteNamespace = this.DeleteNamespace.bind(this);
        this.UpdateNamespace = this.UpdateNamespace.bind(this);
        this.UpdateNamespaceRoles = this.UpdateNamespaceRoles.bind(this);
        this.RevokeNamespaceRoles = this.RevokeNamespaceRoles.bind(this);
        this.ClaimVoucher = this.ClaimVoucher.bind(this);
    }
    UpdateParams(request, metadata) {
        return this.rpc.unary(MsgUpdateParamsDesc, MsgUpdateParams.fromPartial(request), metadata);
    }
    CreateNamespace(request, metadata) {
        return this.rpc.unary(MsgCreateNamespaceDesc, MsgCreateNamespace.fromPartial(request), metadata);
    }
    DeleteNamespace(request, metadata) {
        return this.rpc.unary(MsgDeleteNamespaceDesc, MsgDeleteNamespace.fromPartial(request), metadata);
    }
    UpdateNamespace(request, metadata) {
        return this.rpc.unary(MsgUpdateNamespaceDesc, MsgUpdateNamespace.fromPartial(request), metadata);
    }
    UpdateNamespaceRoles(request, metadata) {
        return this.rpc.unary(MsgUpdateNamespaceRolesDesc, MsgUpdateNamespaceRoles.fromPartial(request), metadata);
    }
    RevokeNamespaceRoles(request, metadata) {
        return this.rpc.unary(MsgRevokeNamespaceRolesDesc, MsgRevokeNamespaceRoles.fromPartial(request), metadata);
    }
    ClaimVoucher(request, metadata) {
        return this.rpc.unary(MsgClaimVoucherDesc, MsgClaimVoucher.fromPartial(request), metadata);
    }
}
export const MsgDesc = { serviceName: "injective.permissions.v1beta1.Msg" };
export const MsgUpdateParamsDesc = {
    methodName: "UpdateParams",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateParams.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateNamespaceDesc = {
    methodName: "CreateNamespace",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateNamespace.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateNamespaceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgDeleteNamespaceDesc = {
    methodName: "DeleteNamespace",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgDeleteNamespace.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgDeleteNamespaceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateNamespaceDesc = {
    methodName: "UpdateNamespace",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateNamespace.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateNamespaceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateNamespaceRolesDesc = {
    methodName: "UpdateNamespaceRoles",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateNamespaceRoles.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateNamespaceRolesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRevokeNamespaceRolesDesc = {
    methodName: "RevokeNamespaceRoles",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRevokeNamespaceRoles.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRevokeNamespaceRolesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgClaimVoucherDesc = {
    methodName: "ClaimVoucher",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgClaimVoucher.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgClaimVoucherResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
