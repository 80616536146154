/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Duration } from "../../../google/protobuf/duration.js";
import { Int64Value } from "../../../google/protobuf/wrappers.js";
export const protobufPackage = "cometbft.types.v1";
function createBaseConsensusParams() {
    return {
        block: undefined,
        evidence: undefined,
        validator: undefined,
        version: undefined,
        abci: undefined,
        synchrony: undefined,
        feature: undefined,
    };
}
export const ConsensusParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.block !== undefined) {
            BlockParams.encode(message.block, writer.uint32(10).fork()).ldelim();
        }
        if (message.evidence !== undefined) {
            EvidenceParams.encode(message.evidence, writer.uint32(18).fork()).ldelim();
        }
        if (message.validator !== undefined) {
            ValidatorParams.encode(message.validator, writer.uint32(26).fork()).ldelim();
        }
        if (message.version !== undefined) {
            VersionParams.encode(message.version, writer.uint32(34).fork()).ldelim();
        }
        if (message.abci !== undefined) {
            ABCIParams.encode(message.abci, writer.uint32(42).fork()).ldelim();
        }
        if (message.synchrony !== undefined) {
            SynchronyParams.encode(message.synchrony, writer.uint32(50).fork()).ldelim();
        }
        if (message.feature !== undefined) {
            FeatureParams.encode(message.feature, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConsensusParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.block = BlockParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.evidence = EvidenceParams.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.validator = ValidatorParams.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.version = VersionParams.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.abci = ABCIParams.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.synchrony = SynchronyParams.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.feature = FeatureParams.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            block: isSet(object.block) ? BlockParams.fromJSON(object.block) : undefined,
            evidence: isSet(object.evidence) ? EvidenceParams.fromJSON(object.evidence) : undefined,
            validator: isSet(object.validator) ? ValidatorParams.fromJSON(object.validator) : undefined,
            version: isSet(object.version) ? VersionParams.fromJSON(object.version) : undefined,
            abci: isSet(object.abci) ? ABCIParams.fromJSON(object.abci) : undefined,
            synchrony: isSet(object.synchrony) ? SynchronyParams.fromJSON(object.synchrony) : undefined,
            feature: isSet(object.feature) ? FeatureParams.fromJSON(object.feature) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.block !== undefined && (obj.block = message.block ? BlockParams.toJSON(message.block) : undefined);
        message.evidence !== undefined &&
            (obj.evidence = message.evidence ? EvidenceParams.toJSON(message.evidence) : undefined);
        message.validator !== undefined &&
            (obj.validator = message.validator ? ValidatorParams.toJSON(message.validator) : undefined);
        message.version !== undefined &&
            (obj.version = message.version ? VersionParams.toJSON(message.version) : undefined);
        message.abci !== undefined && (obj.abci = message.abci ? ABCIParams.toJSON(message.abci) : undefined);
        message.synchrony !== undefined &&
            (obj.synchrony = message.synchrony ? SynchronyParams.toJSON(message.synchrony) : undefined);
        message.feature !== undefined &&
            (obj.feature = message.feature ? FeatureParams.toJSON(message.feature) : undefined);
        return obj;
    },
    create(base) {
        return ConsensusParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseConsensusParams();
        message.block = (object.block !== undefined && object.block !== null)
            ? BlockParams.fromPartial(object.block)
            : undefined;
        message.evidence = (object.evidence !== undefined && object.evidence !== null)
            ? EvidenceParams.fromPartial(object.evidence)
            : undefined;
        message.validator = (object.validator !== undefined && object.validator !== null)
            ? ValidatorParams.fromPartial(object.validator)
            : undefined;
        message.version = (object.version !== undefined && object.version !== null)
            ? VersionParams.fromPartial(object.version)
            : undefined;
        message.abci = (object.abci !== undefined && object.abci !== null)
            ? ABCIParams.fromPartial(object.abci)
            : undefined;
        message.synchrony = (object.synchrony !== undefined && object.synchrony !== null)
            ? SynchronyParams.fromPartial(object.synchrony)
            : undefined;
        message.feature = (object.feature !== undefined && object.feature !== null)
            ? FeatureParams.fromPartial(object.feature)
            : undefined;
        return message;
    },
};
function createBaseBlockParams() {
    return { maxBytes: "0", maxGas: "0" };
}
export const BlockParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.maxBytes !== "0") {
            writer.uint32(8).int64(message.maxBytes);
        }
        if (message.maxGas !== "0") {
            writer.uint32(16).int64(message.maxGas);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.maxBytes = longToString(reader.int64());
                    break;
                case 2:
                    message.maxGas = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            maxBytes: isSet(object.maxBytes) ? String(object.maxBytes) : "0",
            maxGas: isSet(object.maxGas) ? String(object.maxGas) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.maxBytes !== undefined && (obj.maxBytes = message.maxBytes);
        message.maxGas !== undefined && (obj.maxGas = message.maxGas);
        return obj;
    },
    create(base) {
        return BlockParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockParams();
        message.maxBytes = object.maxBytes ?? "0";
        message.maxGas = object.maxGas ?? "0";
        return message;
    },
};
function createBaseEvidenceParams() {
    return { maxAgeNumBlocks: "0", maxAgeDuration: undefined, maxBytes: "0" };
}
export const EvidenceParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.maxAgeNumBlocks !== "0") {
            writer.uint32(8).int64(message.maxAgeNumBlocks);
        }
        if (message.maxAgeDuration !== undefined) {
            Duration.encode(message.maxAgeDuration, writer.uint32(18).fork()).ldelim();
        }
        if (message.maxBytes !== "0") {
            writer.uint32(24).int64(message.maxBytes);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEvidenceParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.maxAgeNumBlocks = longToString(reader.int64());
                    break;
                case 2:
                    message.maxAgeDuration = Duration.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.maxBytes = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            maxAgeNumBlocks: isSet(object.maxAgeNumBlocks) ? String(object.maxAgeNumBlocks) : "0",
            maxAgeDuration: isSet(object.maxAgeDuration) ? Duration.fromJSON(object.maxAgeDuration) : undefined,
            maxBytes: isSet(object.maxBytes) ? String(object.maxBytes) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.maxAgeNumBlocks !== undefined && (obj.maxAgeNumBlocks = message.maxAgeNumBlocks);
        message.maxAgeDuration !== undefined &&
            (obj.maxAgeDuration = message.maxAgeDuration ? Duration.toJSON(message.maxAgeDuration) : undefined);
        message.maxBytes !== undefined && (obj.maxBytes = message.maxBytes);
        return obj;
    },
    create(base) {
        return EvidenceParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEvidenceParams();
        message.maxAgeNumBlocks = object.maxAgeNumBlocks ?? "0";
        message.maxAgeDuration = (object.maxAgeDuration !== undefined && object.maxAgeDuration !== null)
            ? Duration.fromPartial(object.maxAgeDuration)
            : undefined;
        message.maxBytes = object.maxBytes ?? "0";
        return message;
    },
};
function createBaseValidatorParams() {
    return { pubKeyTypes: [] };
}
export const ValidatorParams = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.pubKeyTypes) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidatorParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pubKeyTypes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { pubKeyTypes: Array.isArray(object?.pubKeyTypes) ? object.pubKeyTypes.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.pubKeyTypes) {
            obj.pubKeyTypes = message.pubKeyTypes.map((e) => e);
        }
        else {
            obj.pubKeyTypes = [];
        }
        return obj;
    },
    create(base) {
        return ValidatorParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidatorParams();
        message.pubKeyTypes = object.pubKeyTypes?.map((e) => e) || [];
        return message;
    },
};
function createBaseVersionParams() {
    return { app: "0" };
}
export const VersionParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.app !== "0") {
            writer.uint32(8).uint64(message.app);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVersionParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.app = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { app: isSet(object.app) ? String(object.app) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.app !== undefined && (obj.app = message.app);
        return obj;
    },
    create(base) {
        return VersionParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVersionParams();
        message.app = object.app ?? "0";
        return message;
    },
};
function createBaseHashedParams() {
    return { blockMaxBytes: "0", blockMaxGas: "0" };
}
export const HashedParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.blockMaxBytes !== "0") {
            writer.uint32(8).int64(message.blockMaxBytes);
        }
        if (message.blockMaxGas !== "0") {
            writer.uint32(16).int64(message.blockMaxGas);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHashedParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.blockMaxBytes = longToString(reader.int64());
                    break;
                case 2:
                    message.blockMaxGas = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            blockMaxBytes: isSet(object.blockMaxBytes) ? String(object.blockMaxBytes) : "0",
            blockMaxGas: isSet(object.blockMaxGas) ? String(object.blockMaxGas) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.blockMaxBytes !== undefined && (obj.blockMaxBytes = message.blockMaxBytes);
        message.blockMaxGas !== undefined && (obj.blockMaxGas = message.blockMaxGas);
        return obj;
    },
    create(base) {
        return HashedParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseHashedParams();
        message.blockMaxBytes = object.blockMaxBytes ?? "0";
        message.blockMaxGas = object.blockMaxGas ?? "0";
        return message;
    },
};
function createBaseSynchronyParams() {
    return { precision: undefined, messageDelay: undefined };
}
export const SynchronyParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.precision !== undefined) {
            Duration.encode(message.precision, writer.uint32(10).fork()).ldelim();
        }
        if (message.messageDelay !== undefined) {
            Duration.encode(message.messageDelay, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSynchronyParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.precision = Duration.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.messageDelay = Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            precision: isSet(object.precision) ? Duration.fromJSON(object.precision) : undefined,
            messageDelay: isSet(object.messageDelay) ? Duration.fromJSON(object.messageDelay) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.precision !== undefined &&
            (obj.precision = message.precision ? Duration.toJSON(message.precision) : undefined);
        message.messageDelay !== undefined &&
            (obj.messageDelay = message.messageDelay ? Duration.toJSON(message.messageDelay) : undefined);
        return obj;
    },
    create(base) {
        return SynchronyParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSynchronyParams();
        message.precision = (object.precision !== undefined && object.precision !== null)
            ? Duration.fromPartial(object.precision)
            : undefined;
        message.messageDelay = (object.messageDelay !== undefined && object.messageDelay !== null)
            ? Duration.fromPartial(object.messageDelay)
            : undefined;
        return message;
    },
};
function createBaseFeatureParams() {
    return { voteExtensionsEnableHeight: undefined, pbtsEnableHeight: undefined };
}
export const FeatureParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.voteExtensionsEnableHeight !== undefined) {
            Int64Value.encode({ value: message.voteExtensionsEnableHeight }, writer.uint32(10).fork()).ldelim();
        }
        if (message.pbtsEnableHeight !== undefined) {
            Int64Value.encode({ value: message.pbtsEnableHeight }, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeatureParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.voteExtensionsEnableHeight = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                case 2:
                    message.pbtsEnableHeight = Int64Value.decode(reader, reader.uint32()).value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            voteExtensionsEnableHeight: isSet(object.voteExtensionsEnableHeight)
                ? String(object.voteExtensionsEnableHeight)
                : undefined,
            pbtsEnableHeight: isSet(object.pbtsEnableHeight) ? String(object.pbtsEnableHeight) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.voteExtensionsEnableHeight !== undefined &&
            (obj.voteExtensionsEnableHeight = message.voteExtensionsEnableHeight);
        message.pbtsEnableHeight !== undefined && (obj.pbtsEnableHeight = message.pbtsEnableHeight);
        return obj;
    },
    create(base) {
        return FeatureParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeatureParams();
        message.voteExtensionsEnableHeight = object.voteExtensionsEnableHeight ?? undefined;
        message.pbtsEnableHeight = object.pbtsEnableHeight ?? undefined;
        return message;
    },
};
function createBaseABCIParams() {
    return { voteExtensionsEnableHeight: "0" };
}
export const ABCIParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.voteExtensionsEnableHeight !== "0") {
            writer.uint32(8).int64(message.voteExtensionsEnableHeight);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseABCIParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.voteExtensionsEnableHeight = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            voteExtensionsEnableHeight: isSet(object.voteExtensionsEnableHeight)
                ? String(object.voteExtensionsEnableHeight)
                : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.voteExtensionsEnableHeight !== undefined &&
            (obj.voteExtensionsEnableHeight = message.voteExtensionsEnableHeight);
        return obj;
    },
    create(base) {
        return ABCIParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseABCIParams();
        message.voteExtensionsEnableHeight = object.voteExtensionsEnableHeight ?? "0";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
