/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { oracleTypeFromJSON, oracleTypeToJSON } from "../../oracle/v1beta1/oracle.js";
export const protobufPackage = "injective.exchange.v1beta1";
export var AtomicMarketOrderAccessLevel;
(function (AtomicMarketOrderAccessLevel) {
    AtomicMarketOrderAccessLevel[AtomicMarketOrderAccessLevel["Nobody"] = 0] = "Nobody";
    /** BeginBlockerSmartContractsOnly - currently unsupported */
    AtomicMarketOrderAccessLevel[AtomicMarketOrderAccessLevel["BeginBlockerSmartContractsOnly"] = 1] = "BeginBlockerSmartContractsOnly";
    AtomicMarketOrderAccessLevel[AtomicMarketOrderAccessLevel["SmartContractsOnly"] = 2] = "SmartContractsOnly";
    AtomicMarketOrderAccessLevel[AtomicMarketOrderAccessLevel["Everyone"] = 3] = "Everyone";
    AtomicMarketOrderAccessLevel[AtomicMarketOrderAccessLevel["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(AtomicMarketOrderAccessLevel || (AtomicMarketOrderAccessLevel = {}));
export function atomicMarketOrderAccessLevelFromJSON(object) {
    switch (object) {
        case 0:
        case "Nobody":
            return AtomicMarketOrderAccessLevel.Nobody;
        case 1:
        case "BeginBlockerSmartContractsOnly":
            return AtomicMarketOrderAccessLevel.BeginBlockerSmartContractsOnly;
        case 2:
        case "SmartContractsOnly":
            return AtomicMarketOrderAccessLevel.SmartContractsOnly;
        case 3:
        case "Everyone":
            return AtomicMarketOrderAccessLevel.Everyone;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AtomicMarketOrderAccessLevel.UNRECOGNIZED;
    }
}
export function atomicMarketOrderAccessLevelToJSON(object) {
    switch (object) {
        case AtomicMarketOrderAccessLevel.Nobody:
            return "Nobody";
        case AtomicMarketOrderAccessLevel.BeginBlockerSmartContractsOnly:
            return "BeginBlockerSmartContractsOnly";
        case AtomicMarketOrderAccessLevel.SmartContractsOnly:
            return "SmartContractsOnly";
        case AtomicMarketOrderAccessLevel.Everyone:
            return "Everyone";
        case AtomicMarketOrderAccessLevel.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var MarketStatus;
(function (MarketStatus) {
    MarketStatus[MarketStatus["Unspecified"] = 0] = "Unspecified";
    MarketStatus[MarketStatus["Active"] = 1] = "Active";
    MarketStatus[MarketStatus["Paused"] = 2] = "Paused";
    MarketStatus[MarketStatus["Demolished"] = 3] = "Demolished";
    MarketStatus[MarketStatus["Expired"] = 4] = "Expired";
    MarketStatus[MarketStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MarketStatus || (MarketStatus = {}));
export function marketStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "Unspecified":
            return MarketStatus.Unspecified;
        case 1:
        case "Active":
            return MarketStatus.Active;
        case 2:
        case "Paused":
            return MarketStatus.Paused;
        case 3:
        case "Demolished":
            return MarketStatus.Demolished;
        case 4:
        case "Expired":
            return MarketStatus.Expired;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MarketStatus.UNRECOGNIZED;
    }
}
export function marketStatusToJSON(object) {
    switch (object) {
        case MarketStatus.Unspecified:
            return "Unspecified";
        case MarketStatus.Active:
            return "Active";
        case MarketStatus.Paused:
            return "Paused";
        case MarketStatus.Demolished:
            return "Demolished";
        case MarketStatus.Expired:
            return "Expired";
        case MarketStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var OrderType;
(function (OrderType) {
    OrderType[OrderType["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    OrderType[OrderType["BUY"] = 1] = "BUY";
    OrderType[OrderType["SELL"] = 2] = "SELL";
    OrderType[OrderType["STOP_BUY"] = 3] = "STOP_BUY";
    OrderType[OrderType["STOP_SELL"] = 4] = "STOP_SELL";
    OrderType[OrderType["TAKE_BUY"] = 5] = "TAKE_BUY";
    OrderType[OrderType["TAKE_SELL"] = 6] = "TAKE_SELL";
    OrderType[OrderType["BUY_PO"] = 7] = "BUY_PO";
    OrderType[OrderType["SELL_PO"] = 8] = "SELL_PO";
    OrderType[OrderType["BUY_ATOMIC"] = 9] = "BUY_ATOMIC";
    OrderType[OrderType["SELL_ATOMIC"] = 10] = "SELL_ATOMIC";
    OrderType[OrderType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(OrderType || (OrderType = {}));
export function orderTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "UNSPECIFIED":
            return OrderType.UNSPECIFIED;
        case 1:
        case "BUY":
            return OrderType.BUY;
        case 2:
        case "SELL":
            return OrderType.SELL;
        case 3:
        case "STOP_BUY":
            return OrderType.STOP_BUY;
        case 4:
        case "STOP_SELL":
            return OrderType.STOP_SELL;
        case 5:
        case "TAKE_BUY":
            return OrderType.TAKE_BUY;
        case 6:
        case "TAKE_SELL":
            return OrderType.TAKE_SELL;
        case 7:
        case "BUY_PO":
            return OrderType.BUY_PO;
        case 8:
        case "SELL_PO":
            return OrderType.SELL_PO;
        case 9:
        case "BUY_ATOMIC":
            return OrderType.BUY_ATOMIC;
        case 10:
        case "SELL_ATOMIC":
            return OrderType.SELL_ATOMIC;
        case -1:
        case "UNRECOGNIZED":
        default:
            return OrderType.UNRECOGNIZED;
    }
}
export function orderTypeToJSON(object) {
    switch (object) {
        case OrderType.UNSPECIFIED:
            return "UNSPECIFIED";
        case OrderType.BUY:
            return "BUY";
        case OrderType.SELL:
            return "SELL";
        case OrderType.STOP_BUY:
            return "STOP_BUY";
        case OrderType.STOP_SELL:
            return "STOP_SELL";
        case OrderType.TAKE_BUY:
            return "TAKE_BUY";
        case OrderType.TAKE_SELL:
            return "TAKE_SELL";
        case OrderType.BUY_PO:
            return "BUY_PO";
        case OrderType.SELL_PO:
            return "SELL_PO";
        case OrderType.BUY_ATOMIC:
            return "BUY_ATOMIC";
        case OrderType.SELL_ATOMIC:
            return "SELL_ATOMIC";
        case OrderType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var ExecutionType;
(function (ExecutionType) {
    ExecutionType[ExecutionType["UnspecifiedExecutionType"] = 0] = "UnspecifiedExecutionType";
    ExecutionType[ExecutionType["Market"] = 1] = "Market";
    ExecutionType[ExecutionType["LimitFill"] = 2] = "LimitFill";
    ExecutionType[ExecutionType["LimitMatchRestingOrder"] = 3] = "LimitMatchRestingOrder";
    ExecutionType[ExecutionType["LimitMatchNewOrder"] = 4] = "LimitMatchNewOrder";
    ExecutionType[ExecutionType["MarketLiquidation"] = 5] = "MarketLiquidation";
    ExecutionType[ExecutionType["ExpiryMarketSettlement"] = 6] = "ExpiryMarketSettlement";
    ExecutionType[ExecutionType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ExecutionType || (ExecutionType = {}));
export function executionTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "UnspecifiedExecutionType":
            return ExecutionType.UnspecifiedExecutionType;
        case 1:
        case "Market":
            return ExecutionType.Market;
        case 2:
        case "LimitFill":
            return ExecutionType.LimitFill;
        case 3:
        case "LimitMatchRestingOrder":
            return ExecutionType.LimitMatchRestingOrder;
        case 4:
        case "LimitMatchNewOrder":
            return ExecutionType.LimitMatchNewOrder;
        case 5:
        case "MarketLiquidation":
            return ExecutionType.MarketLiquidation;
        case 6:
        case "ExpiryMarketSettlement":
            return ExecutionType.ExpiryMarketSettlement;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ExecutionType.UNRECOGNIZED;
    }
}
export function executionTypeToJSON(object) {
    switch (object) {
        case ExecutionType.UnspecifiedExecutionType:
            return "UnspecifiedExecutionType";
        case ExecutionType.Market:
            return "Market";
        case ExecutionType.LimitFill:
            return "LimitFill";
        case ExecutionType.LimitMatchRestingOrder:
            return "LimitMatchRestingOrder";
        case ExecutionType.LimitMatchNewOrder:
            return "LimitMatchNewOrder";
        case ExecutionType.MarketLiquidation:
            return "MarketLiquidation";
        case ExecutionType.ExpiryMarketSettlement:
            return "ExpiryMarketSettlement";
        case ExecutionType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var OrderMask;
(function (OrderMask) {
    OrderMask[OrderMask["UNUSED"] = 0] = "UNUSED";
    OrderMask[OrderMask["ANY"] = 1] = "ANY";
    OrderMask[OrderMask["REGULAR"] = 2] = "REGULAR";
    OrderMask[OrderMask["CONDITIONAL"] = 4] = "CONDITIONAL";
    /** DIRECTION_BUY_OR_HIGHER - for conditional orders means HIGHER */
    OrderMask[OrderMask["DIRECTION_BUY_OR_HIGHER"] = 8] = "DIRECTION_BUY_OR_HIGHER";
    /** DIRECTION_SELL_OR_LOWER - for conditional orders means LOWER */
    OrderMask[OrderMask["DIRECTION_SELL_OR_LOWER"] = 16] = "DIRECTION_SELL_OR_LOWER";
    OrderMask[OrderMask["TYPE_MARKET"] = 32] = "TYPE_MARKET";
    OrderMask[OrderMask["TYPE_LIMIT"] = 64] = "TYPE_LIMIT";
    OrderMask[OrderMask["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(OrderMask || (OrderMask = {}));
export function orderMaskFromJSON(object) {
    switch (object) {
        case 0:
        case "UNUSED":
            return OrderMask.UNUSED;
        case 1:
        case "ANY":
            return OrderMask.ANY;
        case 2:
        case "REGULAR":
            return OrderMask.REGULAR;
        case 4:
        case "CONDITIONAL":
            return OrderMask.CONDITIONAL;
        case 8:
        case "DIRECTION_BUY_OR_HIGHER":
            return OrderMask.DIRECTION_BUY_OR_HIGHER;
        case 16:
        case "DIRECTION_SELL_OR_LOWER":
            return OrderMask.DIRECTION_SELL_OR_LOWER;
        case 32:
        case "TYPE_MARKET":
            return OrderMask.TYPE_MARKET;
        case 64:
        case "TYPE_LIMIT":
            return OrderMask.TYPE_LIMIT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return OrderMask.UNRECOGNIZED;
    }
}
export function orderMaskToJSON(object) {
    switch (object) {
        case OrderMask.UNUSED:
            return "UNUSED";
        case OrderMask.ANY:
            return "ANY";
        case OrderMask.REGULAR:
            return "REGULAR";
        case OrderMask.CONDITIONAL:
            return "CONDITIONAL";
        case OrderMask.DIRECTION_BUY_OR_HIGHER:
            return "DIRECTION_BUY_OR_HIGHER";
        case OrderMask.DIRECTION_SELL_OR_LOWER:
            return "DIRECTION_SELL_OR_LOWER";
        case OrderMask.TYPE_MARKET:
            return "TYPE_MARKET";
        case OrderMask.TYPE_LIMIT:
            return "TYPE_LIMIT";
        case OrderMask.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseParams() {
    return {
        spotMarketInstantListingFee: undefined,
        derivativeMarketInstantListingFee: undefined,
        defaultSpotMakerFeeRate: "",
        defaultSpotTakerFeeRate: "",
        defaultDerivativeMakerFeeRate: "",
        defaultDerivativeTakerFeeRate: "",
        defaultInitialMarginRatio: "",
        defaultMaintenanceMarginRatio: "",
        defaultFundingInterval: "0",
        fundingMultiple: "0",
        relayerFeeShareRate: "",
        defaultHourlyFundingRateCap: "",
        defaultHourlyInterestRate: "",
        maxDerivativeOrderSideCount: 0,
        injRewardStakedRequirementThreshold: "",
        tradingRewardsVestingDuration: "0",
        liquidatorRewardShareRate: "",
        binaryOptionsMarketInstantListingFee: undefined,
        atomicMarketOrderAccessLevel: 0,
        spotAtomicMarketOrderFeeMultiplier: "",
        derivativeAtomicMarketOrderFeeMultiplier: "",
        binaryOptionsAtomicMarketOrderFeeMultiplier: "",
        minimalProtocolFeeRate: "",
        isInstantDerivativeMarketLaunchEnabled: false,
        postOnlyModeHeightThreshold: "0",
        marginDecreasePriceTimestampThresholdSeconds: "0",
        exchangeAdmins: [],
        injAuctionMaxCap: "",
    };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.spotMarketInstantListingFee !== undefined) {
            Coin.encode(message.spotMarketInstantListingFee, writer.uint32(10).fork()).ldelim();
        }
        if (message.derivativeMarketInstantListingFee !== undefined) {
            Coin.encode(message.derivativeMarketInstantListingFee, writer.uint32(18).fork()).ldelim();
        }
        if (message.defaultSpotMakerFeeRate !== "") {
            writer.uint32(26).string(message.defaultSpotMakerFeeRate);
        }
        if (message.defaultSpotTakerFeeRate !== "") {
            writer.uint32(34).string(message.defaultSpotTakerFeeRate);
        }
        if (message.defaultDerivativeMakerFeeRate !== "") {
            writer.uint32(42).string(message.defaultDerivativeMakerFeeRate);
        }
        if (message.defaultDerivativeTakerFeeRate !== "") {
            writer.uint32(50).string(message.defaultDerivativeTakerFeeRate);
        }
        if (message.defaultInitialMarginRatio !== "") {
            writer.uint32(58).string(message.defaultInitialMarginRatio);
        }
        if (message.defaultMaintenanceMarginRatio !== "") {
            writer.uint32(66).string(message.defaultMaintenanceMarginRatio);
        }
        if (message.defaultFundingInterval !== "0") {
            writer.uint32(72).int64(message.defaultFundingInterval);
        }
        if (message.fundingMultiple !== "0") {
            writer.uint32(80).int64(message.fundingMultiple);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(90).string(message.relayerFeeShareRate);
        }
        if (message.defaultHourlyFundingRateCap !== "") {
            writer.uint32(98).string(message.defaultHourlyFundingRateCap);
        }
        if (message.defaultHourlyInterestRate !== "") {
            writer.uint32(106).string(message.defaultHourlyInterestRate);
        }
        if (message.maxDerivativeOrderSideCount !== 0) {
            writer.uint32(112).uint32(message.maxDerivativeOrderSideCount);
        }
        if (message.injRewardStakedRequirementThreshold !== "") {
            writer.uint32(122).string(message.injRewardStakedRequirementThreshold);
        }
        if (message.tradingRewardsVestingDuration !== "0") {
            writer.uint32(128).int64(message.tradingRewardsVestingDuration);
        }
        if (message.liquidatorRewardShareRate !== "") {
            writer.uint32(138).string(message.liquidatorRewardShareRate);
        }
        if (message.binaryOptionsMarketInstantListingFee !== undefined) {
            Coin.encode(message.binaryOptionsMarketInstantListingFee, writer.uint32(146).fork()).ldelim();
        }
        if (message.atomicMarketOrderAccessLevel !== 0) {
            writer.uint32(152).int32(message.atomicMarketOrderAccessLevel);
        }
        if (message.spotAtomicMarketOrderFeeMultiplier !== "") {
            writer.uint32(162).string(message.spotAtomicMarketOrderFeeMultiplier);
        }
        if (message.derivativeAtomicMarketOrderFeeMultiplier !== "") {
            writer.uint32(170).string(message.derivativeAtomicMarketOrderFeeMultiplier);
        }
        if (message.binaryOptionsAtomicMarketOrderFeeMultiplier !== "") {
            writer.uint32(178).string(message.binaryOptionsAtomicMarketOrderFeeMultiplier);
        }
        if (message.minimalProtocolFeeRate !== "") {
            writer.uint32(186).string(message.minimalProtocolFeeRate);
        }
        if (message.isInstantDerivativeMarketLaunchEnabled === true) {
            writer.uint32(192).bool(message.isInstantDerivativeMarketLaunchEnabled);
        }
        if (message.postOnlyModeHeightThreshold !== "0") {
            writer.uint32(200).int64(message.postOnlyModeHeightThreshold);
        }
        if (message.marginDecreasePriceTimestampThresholdSeconds !== "0") {
            writer.uint32(208).int64(message.marginDecreasePriceTimestampThresholdSeconds);
        }
        for (const v of message.exchangeAdmins) {
            writer.uint32(218).string(v);
        }
        if (message.injAuctionMaxCap !== "") {
            writer.uint32(226).string(message.injAuctionMaxCap);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotMarketInstantListingFee = Coin.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.derivativeMarketInstantListingFee = Coin.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.defaultSpotMakerFeeRate = reader.string();
                    break;
                case 4:
                    message.defaultSpotTakerFeeRate = reader.string();
                    break;
                case 5:
                    message.defaultDerivativeMakerFeeRate = reader.string();
                    break;
                case 6:
                    message.defaultDerivativeTakerFeeRate = reader.string();
                    break;
                case 7:
                    message.defaultInitialMarginRatio = reader.string();
                    break;
                case 8:
                    message.defaultMaintenanceMarginRatio = reader.string();
                    break;
                case 9:
                    message.defaultFundingInterval = longToString(reader.int64());
                    break;
                case 10:
                    message.fundingMultiple = longToString(reader.int64());
                    break;
                case 11:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 12:
                    message.defaultHourlyFundingRateCap = reader.string();
                    break;
                case 13:
                    message.defaultHourlyInterestRate = reader.string();
                    break;
                case 14:
                    message.maxDerivativeOrderSideCount = reader.uint32();
                    break;
                case 15:
                    message.injRewardStakedRequirementThreshold = reader.string();
                    break;
                case 16:
                    message.tradingRewardsVestingDuration = longToString(reader.int64());
                    break;
                case 17:
                    message.liquidatorRewardShareRate = reader.string();
                    break;
                case 18:
                    message.binaryOptionsMarketInstantListingFee = Coin.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.atomicMarketOrderAccessLevel = reader.int32();
                    break;
                case 20:
                    message.spotAtomicMarketOrderFeeMultiplier = reader.string();
                    break;
                case 21:
                    message.derivativeAtomicMarketOrderFeeMultiplier = reader.string();
                    break;
                case 22:
                    message.binaryOptionsAtomicMarketOrderFeeMultiplier = reader.string();
                    break;
                case 23:
                    message.minimalProtocolFeeRate = reader.string();
                    break;
                case 24:
                    message.isInstantDerivativeMarketLaunchEnabled = reader.bool();
                    break;
                case 25:
                    message.postOnlyModeHeightThreshold = longToString(reader.int64());
                    break;
                case 26:
                    message.marginDecreasePriceTimestampThresholdSeconds = longToString(reader.int64());
                    break;
                case 27:
                    message.exchangeAdmins.push(reader.string());
                    break;
                case 28:
                    message.injAuctionMaxCap = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotMarketInstantListingFee: isSet(object.spotMarketInstantListingFee)
                ? Coin.fromJSON(object.spotMarketInstantListingFee)
                : undefined,
            derivativeMarketInstantListingFee: isSet(object.derivativeMarketInstantListingFee)
                ? Coin.fromJSON(object.derivativeMarketInstantListingFee)
                : undefined,
            defaultSpotMakerFeeRate: isSet(object.defaultSpotMakerFeeRate) ? String(object.defaultSpotMakerFeeRate) : "",
            defaultSpotTakerFeeRate: isSet(object.defaultSpotTakerFeeRate) ? String(object.defaultSpotTakerFeeRate) : "",
            defaultDerivativeMakerFeeRate: isSet(object.defaultDerivativeMakerFeeRate)
                ? String(object.defaultDerivativeMakerFeeRate)
                : "",
            defaultDerivativeTakerFeeRate: isSet(object.defaultDerivativeTakerFeeRate)
                ? String(object.defaultDerivativeTakerFeeRate)
                : "",
            defaultInitialMarginRatio: isSet(object.defaultInitialMarginRatio)
                ? String(object.defaultInitialMarginRatio)
                : "",
            defaultMaintenanceMarginRatio: isSet(object.defaultMaintenanceMarginRatio)
                ? String(object.defaultMaintenanceMarginRatio)
                : "",
            defaultFundingInterval: isSet(object.defaultFundingInterval) ? String(object.defaultFundingInterval) : "0",
            fundingMultiple: isSet(object.fundingMultiple) ? String(object.fundingMultiple) : "0",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            defaultHourlyFundingRateCap: isSet(object.defaultHourlyFundingRateCap)
                ? String(object.defaultHourlyFundingRateCap)
                : "",
            defaultHourlyInterestRate: isSet(object.defaultHourlyInterestRate)
                ? String(object.defaultHourlyInterestRate)
                : "",
            maxDerivativeOrderSideCount: isSet(object.maxDerivativeOrderSideCount)
                ? Number(object.maxDerivativeOrderSideCount)
                : 0,
            injRewardStakedRequirementThreshold: isSet(object.injRewardStakedRequirementThreshold)
                ? String(object.injRewardStakedRequirementThreshold)
                : "",
            tradingRewardsVestingDuration: isSet(object.tradingRewardsVestingDuration)
                ? String(object.tradingRewardsVestingDuration)
                : "0",
            liquidatorRewardShareRate: isSet(object.liquidatorRewardShareRate)
                ? String(object.liquidatorRewardShareRate)
                : "",
            binaryOptionsMarketInstantListingFee: isSet(object.binaryOptionsMarketInstantListingFee)
                ? Coin.fromJSON(object.binaryOptionsMarketInstantListingFee)
                : undefined,
            atomicMarketOrderAccessLevel: isSet(object.atomicMarketOrderAccessLevel)
                ? atomicMarketOrderAccessLevelFromJSON(object.atomicMarketOrderAccessLevel)
                : 0,
            spotAtomicMarketOrderFeeMultiplier: isSet(object.spotAtomicMarketOrderFeeMultiplier)
                ? String(object.spotAtomicMarketOrderFeeMultiplier)
                : "",
            derivativeAtomicMarketOrderFeeMultiplier: isSet(object.derivativeAtomicMarketOrderFeeMultiplier)
                ? String(object.derivativeAtomicMarketOrderFeeMultiplier)
                : "",
            binaryOptionsAtomicMarketOrderFeeMultiplier: isSet(object.binaryOptionsAtomicMarketOrderFeeMultiplier)
                ? String(object.binaryOptionsAtomicMarketOrderFeeMultiplier)
                : "",
            minimalProtocolFeeRate: isSet(object.minimalProtocolFeeRate) ? String(object.minimalProtocolFeeRate) : "",
            isInstantDerivativeMarketLaunchEnabled: isSet(object.isInstantDerivativeMarketLaunchEnabled)
                ? Boolean(object.isInstantDerivativeMarketLaunchEnabled)
                : false,
            postOnlyModeHeightThreshold: isSet(object.postOnlyModeHeightThreshold)
                ? String(object.postOnlyModeHeightThreshold)
                : "0",
            marginDecreasePriceTimestampThresholdSeconds: isSet(object.marginDecreasePriceTimestampThresholdSeconds)
                ? String(object.marginDecreasePriceTimestampThresholdSeconds)
                : "0",
            exchangeAdmins: Array.isArray(object?.exchangeAdmins)
                ? object.exchangeAdmins.map((e) => String(e))
                : [],
            injAuctionMaxCap: isSet(object.injAuctionMaxCap) ? String(object.injAuctionMaxCap) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.spotMarketInstantListingFee !== undefined &&
            (obj.spotMarketInstantListingFee = message.spotMarketInstantListingFee
                ? Coin.toJSON(message.spotMarketInstantListingFee)
                : undefined);
        message.derivativeMarketInstantListingFee !== undefined &&
            (obj.derivativeMarketInstantListingFee = message.derivativeMarketInstantListingFee
                ? Coin.toJSON(message.derivativeMarketInstantListingFee)
                : undefined);
        message.defaultSpotMakerFeeRate !== undefined && (obj.defaultSpotMakerFeeRate = message.defaultSpotMakerFeeRate);
        message.defaultSpotTakerFeeRate !== undefined && (obj.defaultSpotTakerFeeRate = message.defaultSpotTakerFeeRate);
        message.defaultDerivativeMakerFeeRate !== undefined &&
            (obj.defaultDerivativeMakerFeeRate = message.defaultDerivativeMakerFeeRate);
        message.defaultDerivativeTakerFeeRate !== undefined &&
            (obj.defaultDerivativeTakerFeeRate = message.defaultDerivativeTakerFeeRate);
        message.defaultInitialMarginRatio !== undefined &&
            (obj.defaultInitialMarginRatio = message.defaultInitialMarginRatio);
        message.defaultMaintenanceMarginRatio !== undefined &&
            (obj.defaultMaintenanceMarginRatio = message.defaultMaintenanceMarginRatio);
        message.defaultFundingInterval !== undefined && (obj.defaultFundingInterval = message.defaultFundingInterval);
        message.fundingMultiple !== undefined && (obj.fundingMultiple = message.fundingMultiple);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.defaultHourlyFundingRateCap !== undefined &&
            (obj.defaultHourlyFundingRateCap = message.defaultHourlyFundingRateCap);
        message.defaultHourlyInterestRate !== undefined &&
            (obj.defaultHourlyInterestRate = message.defaultHourlyInterestRate);
        message.maxDerivativeOrderSideCount !== undefined &&
            (obj.maxDerivativeOrderSideCount = Math.round(message.maxDerivativeOrderSideCount));
        message.injRewardStakedRequirementThreshold !== undefined &&
            (obj.injRewardStakedRequirementThreshold = message.injRewardStakedRequirementThreshold);
        message.tradingRewardsVestingDuration !== undefined &&
            (obj.tradingRewardsVestingDuration = message.tradingRewardsVestingDuration);
        message.liquidatorRewardShareRate !== undefined &&
            (obj.liquidatorRewardShareRate = message.liquidatorRewardShareRate);
        message.binaryOptionsMarketInstantListingFee !== undefined &&
            (obj.binaryOptionsMarketInstantListingFee = message.binaryOptionsMarketInstantListingFee
                ? Coin.toJSON(message.binaryOptionsMarketInstantListingFee)
                : undefined);
        message.atomicMarketOrderAccessLevel !== undefined &&
            (obj.atomicMarketOrderAccessLevel = atomicMarketOrderAccessLevelToJSON(message.atomicMarketOrderAccessLevel));
        message.spotAtomicMarketOrderFeeMultiplier !== undefined &&
            (obj.spotAtomicMarketOrderFeeMultiplier = message.spotAtomicMarketOrderFeeMultiplier);
        message.derivativeAtomicMarketOrderFeeMultiplier !== undefined &&
            (obj.derivativeAtomicMarketOrderFeeMultiplier = message.derivativeAtomicMarketOrderFeeMultiplier);
        message.binaryOptionsAtomicMarketOrderFeeMultiplier !== undefined &&
            (obj.binaryOptionsAtomicMarketOrderFeeMultiplier = message.binaryOptionsAtomicMarketOrderFeeMultiplier);
        message.minimalProtocolFeeRate !== undefined && (obj.minimalProtocolFeeRate = message.minimalProtocolFeeRate);
        message.isInstantDerivativeMarketLaunchEnabled !== undefined &&
            (obj.isInstantDerivativeMarketLaunchEnabled = message.isInstantDerivativeMarketLaunchEnabled);
        message.postOnlyModeHeightThreshold !== undefined &&
            (obj.postOnlyModeHeightThreshold = message.postOnlyModeHeightThreshold);
        message.marginDecreasePriceTimestampThresholdSeconds !== undefined &&
            (obj.marginDecreasePriceTimestampThresholdSeconds = message.marginDecreasePriceTimestampThresholdSeconds);
        if (message.exchangeAdmins) {
            obj.exchangeAdmins = message.exchangeAdmins.map((e) => e);
        }
        else {
            obj.exchangeAdmins = [];
        }
        message.injAuctionMaxCap !== undefined && (obj.injAuctionMaxCap = message.injAuctionMaxCap);
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.spotMarketInstantListingFee =
            (object.spotMarketInstantListingFee !== undefined && object.spotMarketInstantListingFee !== null)
                ? Coin.fromPartial(object.spotMarketInstantListingFee)
                : undefined;
        message.derivativeMarketInstantListingFee =
            (object.derivativeMarketInstantListingFee !== undefined && object.derivativeMarketInstantListingFee !== null)
                ? Coin.fromPartial(object.derivativeMarketInstantListingFee)
                : undefined;
        message.defaultSpotMakerFeeRate = object.defaultSpotMakerFeeRate ?? "";
        message.defaultSpotTakerFeeRate = object.defaultSpotTakerFeeRate ?? "";
        message.defaultDerivativeMakerFeeRate = object.defaultDerivativeMakerFeeRate ?? "";
        message.defaultDerivativeTakerFeeRate = object.defaultDerivativeTakerFeeRate ?? "";
        message.defaultInitialMarginRatio = object.defaultInitialMarginRatio ?? "";
        message.defaultMaintenanceMarginRatio = object.defaultMaintenanceMarginRatio ?? "";
        message.defaultFundingInterval = object.defaultFundingInterval ?? "0";
        message.fundingMultiple = object.fundingMultiple ?? "0";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.defaultHourlyFundingRateCap = object.defaultHourlyFundingRateCap ?? "";
        message.defaultHourlyInterestRate = object.defaultHourlyInterestRate ?? "";
        message.maxDerivativeOrderSideCount = object.maxDerivativeOrderSideCount ?? 0;
        message.injRewardStakedRequirementThreshold = object.injRewardStakedRequirementThreshold ?? "";
        message.tradingRewardsVestingDuration = object.tradingRewardsVestingDuration ?? "0";
        message.liquidatorRewardShareRate = object.liquidatorRewardShareRate ?? "";
        message.binaryOptionsMarketInstantListingFee =
            (object.binaryOptionsMarketInstantListingFee !== undefined &&
                object.binaryOptionsMarketInstantListingFee !== null)
                ? Coin.fromPartial(object.binaryOptionsMarketInstantListingFee)
                : undefined;
        message.atomicMarketOrderAccessLevel = object.atomicMarketOrderAccessLevel ?? 0;
        message.spotAtomicMarketOrderFeeMultiplier = object.spotAtomicMarketOrderFeeMultiplier ?? "";
        message.derivativeAtomicMarketOrderFeeMultiplier = object.derivativeAtomicMarketOrderFeeMultiplier ?? "";
        message.binaryOptionsAtomicMarketOrderFeeMultiplier = object.binaryOptionsAtomicMarketOrderFeeMultiplier ?? "";
        message.minimalProtocolFeeRate = object.minimalProtocolFeeRate ?? "";
        message.isInstantDerivativeMarketLaunchEnabled = object.isInstantDerivativeMarketLaunchEnabled ?? false;
        message.postOnlyModeHeightThreshold = object.postOnlyModeHeightThreshold ?? "0";
        message.marginDecreasePriceTimestampThresholdSeconds = object.marginDecreasePriceTimestampThresholdSeconds ?? "0";
        message.exchangeAdmins = object.exchangeAdmins?.map((e) => e) || [];
        message.injAuctionMaxCap = object.injAuctionMaxCap ?? "";
        return message;
    },
};
function createBaseMarketFeeMultiplier() {
    return { marketId: "", feeMultiplier: "" };
}
export const MarketFeeMultiplier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.feeMultiplier !== "") {
            writer.uint32(18).string(message.feeMultiplier);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketFeeMultiplier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.feeMultiplier = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            feeMultiplier: isSet(object.feeMultiplier) ? String(object.feeMultiplier) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.feeMultiplier !== undefined && (obj.feeMultiplier = message.feeMultiplier);
        return obj;
    },
    create(base) {
        return MarketFeeMultiplier.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketFeeMultiplier();
        message.marketId = object.marketId ?? "";
        message.feeMultiplier = object.feeMultiplier ?? "";
        return message;
    },
};
function createBaseDerivativeMarket() {
    return {
        ticker: "",
        oracleBase: "",
        oracleQuote: "",
        oracleType: 0,
        oracleScaleFactor: 0,
        quoteDenom: "",
        marketId: "",
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        makerFeeRate: "",
        takerFeeRate: "",
        relayerFeeShareRate: "",
        isPerpetual: false,
        status: 0,
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
        admin: "",
        adminPermissions: 0,
        quoteDecimals: 0,
    };
}
export const DerivativeMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticker !== "") {
            writer.uint32(10).string(message.ticker);
        }
        if (message.oracleBase !== "") {
            writer.uint32(18).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(26).string(message.oracleQuote);
        }
        if (message.oracleType !== 0) {
            writer.uint32(32).int32(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(40).uint32(message.oracleScaleFactor);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(50).string(message.quoteDenom);
        }
        if (message.marketId !== "") {
            writer.uint32(58).string(message.marketId);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(66).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(74).string(message.maintenanceMarginRatio);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(82).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(90).string(message.takerFeeRate);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(98).string(message.relayerFeeShareRate);
        }
        if (message.isPerpetual === true) {
            writer.uint32(104).bool(message.isPerpetual);
        }
        if (message.status !== 0) {
            writer.uint32(112).int32(message.status);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(122).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(130).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(138).string(message.minNotional);
        }
        if (message.admin !== "") {
            writer.uint32(146).string(message.admin);
        }
        if (message.adminPermissions !== 0) {
            writer.uint32(152).uint32(message.adminPermissions);
        }
        if (message.quoteDecimals !== 0) {
            writer.uint32(160).uint32(message.quoteDecimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticker = reader.string();
                    break;
                case 2:
                    message.oracleBase = reader.string();
                    break;
                case 3:
                    message.oracleQuote = reader.string();
                    break;
                case 4:
                    message.oracleType = reader.int32();
                    break;
                case 5:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 6:
                    message.quoteDenom = reader.string();
                    break;
                case 7:
                    message.marketId = reader.string();
                    break;
                case 8:
                    message.initialMarginRatio = reader.string();
                    break;
                case 9:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 10:
                    message.makerFeeRate = reader.string();
                    break;
                case 11:
                    message.takerFeeRate = reader.string();
                    break;
                case 12:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 13:
                    message.isPerpetual = reader.bool();
                    break;
                case 14:
                    message.status = reader.int32();
                    break;
                case 15:
                    message.minPriceTickSize = reader.string();
                    break;
                case 16:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 17:
                    message.minNotional = reader.string();
                    break;
                case 18:
                    message.admin = reader.string();
                    break;
                case 19:
                    message.adminPermissions = reader.uint32();
                    break;
                case 20:
                    message.quoteDecimals = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            isPerpetual: isSet(object.isPerpetual) ? Boolean(object.isPerpetual) : false,
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            admin: isSet(object.admin) ? String(object.admin) : "",
            adminPermissions: isSet(object.adminPermissions) ? Number(object.adminPermissions) : 0,
            quoteDecimals: isSet(object.quoteDecimals) ? Number(object.quoteDecimals) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.isPerpetual !== undefined && (obj.isPerpetual = message.isPerpetual);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.admin !== undefined && (obj.admin = message.admin);
        message.adminPermissions !== undefined && (obj.adminPermissions = Math.round(message.adminPermissions));
        message.quoteDecimals !== undefined && (obj.quoteDecimals = Math.round(message.quoteDecimals));
        return obj;
    },
    create(base) {
        return DerivativeMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarket();
        message.ticker = object.ticker ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleType = object.oracleType ?? 0;
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.quoteDenom = object.quoteDenom ?? "";
        message.marketId = object.marketId ?? "";
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.isPerpetual = object.isPerpetual ?? false;
        message.status = object.status ?? 0;
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        message.admin = object.admin ?? "";
        message.adminPermissions = object.adminPermissions ?? 0;
        message.quoteDecimals = object.quoteDecimals ?? 0;
        return message;
    },
};
function createBaseBinaryOptionsMarket() {
    return {
        ticker: "",
        oracleSymbol: "",
        oracleProvider: "",
        oracleType: 0,
        oracleScaleFactor: 0,
        expirationTimestamp: "0",
        settlementTimestamp: "0",
        admin: "",
        quoteDenom: "",
        marketId: "",
        makerFeeRate: "",
        takerFeeRate: "",
        relayerFeeShareRate: "",
        status: 0,
        minPriceTickSize: "",
        minQuantityTickSize: "",
        settlementPrice: "",
        minNotional: "",
        adminPermissions: 0,
        quoteDecimals: 0,
    };
}
export const BinaryOptionsMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticker !== "") {
            writer.uint32(10).string(message.ticker);
        }
        if (message.oracleSymbol !== "") {
            writer.uint32(18).string(message.oracleSymbol);
        }
        if (message.oracleProvider !== "") {
            writer.uint32(26).string(message.oracleProvider);
        }
        if (message.oracleType !== 0) {
            writer.uint32(32).int32(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(40).uint32(message.oracleScaleFactor);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(48).int64(message.expirationTimestamp);
        }
        if (message.settlementTimestamp !== "0") {
            writer.uint32(56).int64(message.settlementTimestamp);
        }
        if (message.admin !== "") {
            writer.uint32(66).string(message.admin);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(74).string(message.quoteDenom);
        }
        if (message.marketId !== "") {
            writer.uint32(82).string(message.marketId);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(90).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(98).string(message.takerFeeRate);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(106).string(message.relayerFeeShareRate);
        }
        if (message.status !== 0) {
            writer.uint32(112).int32(message.status);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(122).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(130).string(message.minQuantityTickSize);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(138).string(message.settlementPrice);
        }
        if (message.minNotional !== "") {
            writer.uint32(146).string(message.minNotional);
        }
        if (message.adminPermissions !== 0) {
            writer.uint32(152).uint32(message.adminPermissions);
        }
        if (message.quoteDecimals !== 0) {
            writer.uint32(160).uint32(message.quoteDecimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticker = reader.string();
                    break;
                case 2:
                    message.oracleSymbol = reader.string();
                    break;
                case 3:
                    message.oracleProvider = reader.string();
                    break;
                case 4:
                    message.oracleType = reader.int32();
                    break;
                case 5:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 6:
                    message.expirationTimestamp = longToString(reader.int64());
                    break;
                case 7:
                    message.settlementTimestamp = longToString(reader.int64());
                    break;
                case 8:
                    message.admin = reader.string();
                    break;
                case 9:
                    message.quoteDenom = reader.string();
                    break;
                case 10:
                    message.marketId = reader.string();
                    break;
                case 11:
                    message.makerFeeRate = reader.string();
                    break;
                case 12:
                    message.takerFeeRate = reader.string();
                    break;
                case 13:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 14:
                    message.status = reader.int32();
                    break;
                case 15:
                    message.minPriceTickSize = reader.string();
                    break;
                case 16:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 17:
                    message.settlementPrice = reader.string();
                    break;
                case 18:
                    message.minNotional = reader.string();
                    break;
                case 19:
                    message.adminPermissions = reader.uint32();
                    break;
                case 20:
                    message.quoteDecimals = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            oracleSymbol: isSet(object.oracleSymbol) ? String(object.oracleSymbol) : "",
            oracleProvider: isSet(object.oracleProvider) ? String(object.oracleProvider) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementTimestamp: isSet(object.settlementTimestamp) ? String(object.settlementTimestamp) : "0",
            admin: isSet(object.admin) ? String(object.admin) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminPermissions: isSet(object.adminPermissions) ? Number(object.adminPermissions) : 0,
            quoteDecimals: isSet(object.quoteDecimals) ? Number(object.quoteDecimals) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.oracleSymbol !== undefined && (obj.oracleSymbol = message.oracleSymbol);
        message.oracleProvider !== undefined && (obj.oracleProvider = message.oracleProvider);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementTimestamp !== undefined && (obj.settlementTimestamp = message.settlementTimestamp);
        message.admin !== undefined && (obj.admin = message.admin);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminPermissions !== undefined && (obj.adminPermissions = Math.round(message.adminPermissions));
        message.quoteDecimals !== undefined && (obj.quoteDecimals = Math.round(message.quoteDecimals));
        return obj;
    },
    create(base) {
        return BinaryOptionsMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarket();
        message.ticker = object.ticker ?? "";
        message.oracleSymbol = object.oracleSymbol ?? "";
        message.oracleProvider = object.oracleProvider ?? "";
        message.oracleType = object.oracleType ?? 0;
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementTimestamp = object.settlementTimestamp ?? "0";
        message.admin = object.admin ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.marketId = object.marketId ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.status = object.status ?? 0;
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.settlementPrice = object.settlementPrice ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminPermissions = object.adminPermissions ?? 0;
        message.quoteDecimals = object.quoteDecimals ?? 0;
        return message;
    },
};
function createBaseExpiryFuturesMarketInfo() {
    return {
        marketId: "",
        expirationTimestamp: "0",
        twapStartTimestamp: "0",
        expirationTwapStartPriceCumulative: "",
        settlementPrice: "",
    };
}
export const ExpiryFuturesMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(16).int64(message.expirationTimestamp);
        }
        if (message.twapStartTimestamp !== "0") {
            writer.uint32(24).int64(message.twapStartTimestamp);
        }
        if (message.expirationTwapStartPriceCumulative !== "") {
            writer.uint32(34).string(message.expirationTwapStartPriceCumulative);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(42).string(message.settlementPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExpiryFuturesMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.expirationTimestamp = longToString(reader.int64());
                    break;
                case 3:
                    message.twapStartTimestamp = longToString(reader.int64());
                    break;
                case 4:
                    message.expirationTwapStartPriceCumulative = reader.string();
                    break;
                case 5:
                    message.settlementPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            twapStartTimestamp: isSet(object.twapStartTimestamp) ? String(object.twapStartTimestamp) : "0",
            expirationTwapStartPriceCumulative: isSet(object.expirationTwapStartPriceCumulative)
                ? String(object.expirationTwapStartPriceCumulative)
                : "",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.twapStartTimestamp !== undefined && (obj.twapStartTimestamp = message.twapStartTimestamp);
        message.expirationTwapStartPriceCumulative !== undefined &&
            (obj.expirationTwapStartPriceCumulative = message.expirationTwapStartPriceCumulative);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        return obj;
    },
    create(base) {
        return ExpiryFuturesMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExpiryFuturesMarketInfo();
        message.marketId = object.marketId ?? "";
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.twapStartTimestamp = object.twapStartTimestamp ?? "0";
        message.expirationTwapStartPriceCumulative = object.expirationTwapStartPriceCumulative ?? "";
        message.settlementPrice = object.settlementPrice ?? "";
        return message;
    },
};
function createBasePerpetualMarketInfo() {
    return {
        marketId: "",
        hourlyFundingRateCap: "",
        hourlyInterestRate: "",
        nextFundingTimestamp: "0",
        fundingInterval: "0",
    };
}
export const PerpetualMarketInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.hourlyFundingRateCap !== "") {
            writer.uint32(18).string(message.hourlyFundingRateCap);
        }
        if (message.hourlyInterestRate !== "") {
            writer.uint32(26).string(message.hourlyInterestRate);
        }
        if (message.nextFundingTimestamp !== "0") {
            writer.uint32(32).int64(message.nextFundingTimestamp);
        }
        if (message.fundingInterval !== "0") {
            writer.uint32(40).int64(message.fundingInterval);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerpetualMarketInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.hourlyFundingRateCap = reader.string();
                    break;
                case 3:
                    message.hourlyInterestRate = reader.string();
                    break;
                case 4:
                    message.nextFundingTimestamp = longToString(reader.int64());
                    break;
                case 5:
                    message.fundingInterval = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            hourlyFundingRateCap: isSet(object.hourlyFundingRateCap) ? String(object.hourlyFundingRateCap) : "",
            hourlyInterestRate: isSet(object.hourlyInterestRate) ? String(object.hourlyInterestRate) : "",
            nextFundingTimestamp: isSet(object.nextFundingTimestamp) ? String(object.nextFundingTimestamp) : "0",
            fundingInterval: isSet(object.fundingInterval) ? String(object.fundingInterval) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.hourlyFundingRateCap !== undefined && (obj.hourlyFundingRateCap = message.hourlyFundingRateCap);
        message.hourlyInterestRate !== undefined && (obj.hourlyInterestRate = message.hourlyInterestRate);
        message.nextFundingTimestamp !== undefined && (obj.nextFundingTimestamp = message.nextFundingTimestamp);
        message.fundingInterval !== undefined && (obj.fundingInterval = message.fundingInterval);
        return obj;
    },
    create(base) {
        return PerpetualMarketInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePerpetualMarketInfo();
        message.marketId = object.marketId ?? "";
        message.hourlyFundingRateCap = object.hourlyFundingRateCap ?? "";
        message.hourlyInterestRate = object.hourlyInterestRate ?? "";
        message.nextFundingTimestamp = object.nextFundingTimestamp ?? "0";
        message.fundingInterval = object.fundingInterval ?? "0";
        return message;
    },
};
function createBasePerpetualMarketFunding() {
    return { cumulativeFunding: "", cumulativePrice: "", lastTimestamp: "0" };
}
export const PerpetualMarketFunding = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cumulativeFunding !== "") {
            writer.uint32(10).string(message.cumulativeFunding);
        }
        if (message.cumulativePrice !== "") {
            writer.uint32(18).string(message.cumulativePrice);
        }
        if (message.lastTimestamp !== "0") {
            writer.uint32(24).int64(message.lastTimestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerpetualMarketFunding();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cumulativeFunding = reader.string();
                    break;
                case 2:
                    message.cumulativePrice = reader.string();
                    break;
                case 3:
                    message.lastTimestamp = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cumulativeFunding: isSet(object.cumulativeFunding) ? String(object.cumulativeFunding) : "",
            cumulativePrice: isSet(object.cumulativePrice) ? String(object.cumulativePrice) : "",
            lastTimestamp: isSet(object.lastTimestamp) ? String(object.lastTimestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.cumulativeFunding !== undefined && (obj.cumulativeFunding = message.cumulativeFunding);
        message.cumulativePrice !== undefined && (obj.cumulativePrice = message.cumulativePrice);
        message.lastTimestamp !== undefined && (obj.lastTimestamp = message.lastTimestamp);
        return obj;
    },
    create(base) {
        return PerpetualMarketFunding.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePerpetualMarketFunding();
        message.cumulativeFunding = object.cumulativeFunding ?? "";
        message.cumulativePrice = object.cumulativePrice ?? "";
        message.lastTimestamp = object.lastTimestamp ?? "0";
        return message;
    },
};
function createBaseDerivativeMarketSettlementInfo() {
    return { marketId: "", settlementPrice: "" };
}
export const DerivativeMarketSettlementInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(18).string(message.settlementPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarketSettlementInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.settlementPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        return obj;
    },
    create(base) {
        return DerivativeMarketSettlementInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarketSettlementInfo();
        message.marketId = object.marketId ?? "";
        message.settlementPrice = object.settlementPrice ?? "";
        return message;
    },
};
function createBaseNextFundingTimestamp() {
    return { nextTimestamp: "0" };
}
export const NextFundingTimestamp = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nextTimestamp !== "0") {
            writer.uint32(8).int64(message.nextTimestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNextFundingTimestamp();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nextTimestamp = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { nextTimestamp: isSet(object.nextTimestamp) ? String(object.nextTimestamp) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.nextTimestamp !== undefined && (obj.nextTimestamp = message.nextTimestamp);
        return obj;
    },
    create(base) {
        return NextFundingTimestamp.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseNextFundingTimestamp();
        message.nextTimestamp = object.nextTimestamp ?? "0";
        return message;
    },
};
function createBaseMidPriceAndTOB() {
    return { midPrice: "", bestBuyPrice: "", bestSellPrice: "" };
}
export const MidPriceAndTOB = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.midPrice !== "") {
            writer.uint32(10).string(message.midPrice);
        }
        if (message.bestBuyPrice !== "") {
            writer.uint32(18).string(message.bestBuyPrice);
        }
        if (message.bestSellPrice !== "") {
            writer.uint32(26).string(message.bestSellPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMidPriceAndTOB();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.midPrice = reader.string();
                    break;
                case 2:
                    message.bestBuyPrice = reader.string();
                    break;
                case 3:
                    message.bestSellPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            midPrice: isSet(object.midPrice) ? String(object.midPrice) : "",
            bestBuyPrice: isSet(object.bestBuyPrice) ? String(object.bestBuyPrice) : "",
            bestSellPrice: isSet(object.bestSellPrice) ? String(object.bestSellPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.midPrice !== undefined && (obj.midPrice = message.midPrice);
        message.bestBuyPrice !== undefined && (obj.bestBuyPrice = message.bestBuyPrice);
        message.bestSellPrice !== undefined && (obj.bestSellPrice = message.bestSellPrice);
        return obj;
    },
    create(base) {
        return MidPriceAndTOB.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMidPriceAndTOB();
        message.midPrice = object.midPrice ?? "";
        message.bestBuyPrice = object.bestBuyPrice ?? "";
        message.bestSellPrice = object.bestSellPrice ?? "";
        return message;
    },
};
function createBaseSpotMarket() {
    return {
        ticker: "",
        baseDenom: "",
        quoteDenom: "",
        makerFeeRate: "",
        takerFeeRate: "",
        relayerFeeShareRate: "",
        marketId: "",
        status: 0,
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
        admin: "",
        adminPermissions: 0,
        baseDecimals: 0,
        quoteDecimals: 0,
    };
}
export const SpotMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticker !== "") {
            writer.uint32(10).string(message.ticker);
        }
        if (message.baseDenom !== "") {
            writer.uint32(18).string(message.baseDenom);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(26).string(message.quoteDenom);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(34).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(42).string(message.takerFeeRate);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(50).string(message.relayerFeeShareRate);
        }
        if (message.marketId !== "") {
            writer.uint32(58).string(message.marketId);
        }
        if (message.status !== 0) {
            writer.uint32(64).int32(message.status);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(74).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(82).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(90).string(message.minNotional);
        }
        if (message.admin !== "") {
            writer.uint32(98).string(message.admin);
        }
        if (message.adminPermissions !== 0) {
            writer.uint32(104).uint32(message.adminPermissions);
        }
        if (message.baseDecimals !== 0) {
            writer.uint32(112).uint32(message.baseDecimals);
        }
        if (message.quoteDecimals !== 0) {
            writer.uint32(120).uint32(message.quoteDecimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticker = reader.string();
                    break;
                case 2:
                    message.baseDenom = reader.string();
                    break;
                case 3:
                    message.quoteDenom = reader.string();
                    break;
                case 4:
                    message.makerFeeRate = reader.string();
                    break;
                case 5:
                    message.takerFeeRate = reader.string();
                    break;
                case 6:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 7:
                    message.marketId = reader.string();
                    break;
                case 8:
                    message.status = reader.int32();
                    break;
                case 9:
                    message.minPriceTickSize = reader.string();
                    break;
                case 10:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 11:
                    message.minNotional = reader.string();
                    break;
                case 12:
                    message.admin = reader.string();
                    break;
                case 13:
                    message.adminPermissions = reader.uint32();
                    break;
                case 14:
                    message.baseDecimals = reader.uint32();
                    break;
                case 15:
                    message.quoteDecimals = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            baseDenom: isSet(object.baseDenom) ? String(object.baseDenom) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            admin: isSet(object.admin) ? String(object.admin) : "",
            adminPermissions: isSet(object.adminPermissions) ? Number(object.adminPermissions) : 0,
            baseDecimals: isSet(object.baseDecimals) ? Number(object.baseDecimals) : 0,
            quoteDecimals: isSet(object.quoteDecimals) ? Number(object.quoteDecimals) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.baseDenom !== undefined && (obj.baseDenom = message.baseDenom);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.admin !== undefined && (obj.admin = message.admin);
        message.adminPermissions !== undefined && (obj.adminPermissions = Math.round(message.adminPermissions));
        message.baseDecimals !== undefined && (obj.baseDecimals = Math.round(message.baseDecimals));
        message.quoteDecimals !== undefined && (obj.quoteDecimals = Math.round(message.quoteDecimals));
        return obj;
    },
    create(base) {
        return SpotMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotMarket();
        message.ticker = object.ticker ?? "";
        message.baseDenom = object.baseDenom ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.marketId = object.marketId ?? "";
        message.status = object.status ?? 0;
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        message.admin = object.admin ?? "";
        message.adminPermissions = object.adminPermissions ?? 0;
        message.baseDecimals = object.baseDecimals ?? 0;
        message.quoteDecimals = object.quoteDecimals ?? 0;
        return message;
    },
};
function createBaseDeposit() {
    return { availableBalance: "", totalBalance: "" };
}
export const Deposit = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.availableBalance !== "") {
            writer.uint32(10).string(message.availableBalance);
        }
        if (message.totalBalance !== "") {
            writer.uint32(18).string(message.totalBalance);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeposit();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.availableBalance = reader.string();
                    break;
                case 2:
                    message.totalBalance = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            availableBalance: isSet(object.availableBalance) ? String(object.availableBalance) : "",
            totalBalance: isSet(object.totalBalance) ? String(object.totalBalance) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.availableBalance !== undefined && (obj.availableBalance = message.availableBalance);
        message.totalBalance !== undefined && (obj.totalBalance = message.totalBalance);
        return obj;
    },
    create(base) {
        return Deposit.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDeposit();
        message.availableBalance = object.availableBalance ?? "";
        message.totalBalance = object.totalBalance ?? "";
        return message;
    },
};
function createBaseSubaccountTradeNonce() {
    return { nonce: 0 };
}
export const SubaccountTradeNonce = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nonce !== 0) {
            writer.uint32(8).uint32(message.nonce);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountTradeNonce();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nonce = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { nonce: isSet(object.nonce) ? Number(object.nonce) : 0 };
    },
    toJSON(message) {
        const obj = {};
        message.nonce !== undefined && (obj.nonce = Math.round(message.nonce));
        return obj;
    },
    create(base) {
        return SubaccountTradeNonce.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountTradeNonce();
        message.nonce = object.nonce ?? 0;
        return message;
    },
};
function createBaseOrderInfo() {
    return { subaccountId: "", feeRecipient: "", price: "", quantity: "", cid: "" };
}
export const OrderInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(18).string(message.feeRecipient);
        }
        if (message.price !== "") {
            writer.uint32(26).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(34).string(message.quantity);
        }
        if (message.cid !== "") {
            writer.uint32(42).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.feeRecipient = reader.string();
                    break;
                case 3:
                    message.price = reader.string();
                    break;
                case 4:
                    message.quantity = reader.string();
                    break;
                case 5:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return OrderInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderInfo();
        message.subaccountId = object.subaccountId ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseSpotOrder() {
    return { marketId: "", orderInfo: undefined, orderType: 0, triggerPrice: "" };
}
export const SpotOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderInfo !== undefined) {
            OrderInfo.encode(message.orderInfo, writer.uint32(18).fork()).ldelim();
        }
        if (message.orderType !== 0) {
            writer.uint32(24).int32(message.orderType);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(34).string(message.triggerPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderInfo = OrderInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.orderType = reader.int32();
                    break;
                case 4:
                    message.triggerPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderInfo: isSet(object.orderInfo) ? OrderInfo.fromJSON(object.orderInfo) : undefined,
            orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderInfo !== undefined &&
            (obj.orderInfo = message.orderInfo ? OrderInfo.toJSON(message.orderInfo) : undefined);
        message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        return obj;
    },
    create(base) {
        return SpotOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotOrder();
        message.marketId = object.marketId ?? "";
        message.orderInfo = (object.orderInfo !== undefined && object.orderInfo !== null)
            ? OrderInfo.fromPartial(object.orderInfo)
            : undefined;
        message.orderType = object.orderType ?? 0;
        message.triggerPrice = object.triggerPrice ?? "";
        return message;
    },
};
function createBaseSpotLimitOrder() {
    return { orderInfo: undefined, orderType: 0, fillable: "", triggerPrice: "", orderHash: new Uint8Array() };
}
export const SpotLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderInfo !== undefined) {
            OrderInfo.encode(message.orderInfo, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderType !== 0) {
            writer.uint32(16).int32(message.orderType);
        }
        if (message.fillable !== "") {
            writer.uint32(26).string(message.fillable);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(34).string(message.triggerPrice);
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(42).bytes(message.orderHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderInfo = OrderInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.orderType = reader.int32();
                    break;
                case 3:
                    message.fillable = reader.string();
                    break;
                case 4:
                    message.triggerPrice = reader.string();
                    break;
                case 5:
                    message.orderHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderInfo: isSet(object.orderInfo) ? OrderInfo.fromJSON(object.orderInfo) : undefined,
            orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
            fillable: isSet(object.fillable) ? String(object.fillable) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderInfo !== undefined &&
            (obj.orderInfo = message.orderInfo ? OrderInfo.toJSON(message.orderInfo) : undefined);
        message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
        message.fillable !== undefined && (obj.fillable = message.fillable);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return SpotLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotLimitOrder();
        message.orderInfo = (object.orderInfo !== undefined && object.orderInfo !== null)
            ? OrderInfo.fromPartial(object.orderInfo)
            : undefined;
        message.orderType = object.orderType ?? 0;
        message.fillable = object.fillable ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.orderHash = object.orderHash ?? new Uint8Array();
        return message;
    },
};
function createBaseSpotMarketOrder() {
    return { orderInfo: undefined, balanceHold: "", orderHash: new Uint8Array(), orderType: 0, triggerPrice: "" };
}
export const SpotMarketOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderInfo !== undefined) {
            OrderInfo.encode(message.orderInfo, writer.uint32(10).fork()).ldelim();
        }
        if (message.balanceHold !== "") {
            writer.uint32(18).string(message.balanceHold);
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(26).bytes(message.orderHash);
        }
        if (message.orderType !== 0) {
            writer.uint32(32).int32(message.orderType);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(42).string(message.triggerPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotMarketOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderInfo = OrderInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.balanceHold = reader.string();
                    break;
                case 3:
                    message.orderHash = reader.bytes();
                    break;
                case 4:
                    message.orderType = reader.int32();
                    break;
                case 5:
                    message.triggerPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderInfo: isSet(object.orderInfo) ? OrderInfo.fromJSON(object.orderInfo) : undefined,
            balanceHold: isSet(object.balanceHold) ? String(object.balanceHold) : "",
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
            orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderInfo !== undefined &&
            (obj.orderInfo = message.orderInfo ? OrderInfo.toJSON(message.orderInfo) : undefined);
        message.balanceHold !== undefined && (obj.balanceHold = message.balanceHold);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        return obj;
    },
    create(base) {
        return SpotMarketOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotMarketOrder();
        message.orderInfo = (object.orderInfo !== undefined && object.orderInfo !== null)
            ? OrderInfo.fromPartial(object.orderInfo)
            : undefined;
        message.balanceHold = object.balanceHold ?? "";
        message.orderHash = object.orderHash ?? new Uint8Array();
        message.orderType = object.orderType ?? 0;
        message.triggerPrice = object.triggerPrice ?? "";
        return message;
    },
};
function createBaseDerivativeOrder() {
    return { marketId: "", orderInfo: undefined, orderType: 0, margin: "", triggerPrice: "" };
}
export const DerivativeOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.orderInfo !== undefined) {
            OrderInfo.encode(message.orderInfo, writer.uint32(18).fork()).ldelim();
        }
        if (message.orderType !== 0) {
            writer.uint32(24).int32(message.orderType);
        }
        if (message.margin !== "") {
            writer.uint32(34).string(message.margin);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(42).string(message.triggerPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.orderInfo = OrderInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.orderType = reader.int32();
                    break;
                case 4:
                    message.margin = reader.string();
                    break;
                case 5:
                    message.triggerPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderInfo: isSet(object.orderInfo) ? OrderInfo.fromJSON(object.orderInfo) : undefined,
            orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
            margin: isSet(object.margin) ? String(object.margin) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderInfo !== undefined &&
            (obj.orderInfo = message.orderInfo ? OrderInfo.toJSON(message.orderInfo) : undefined);
        message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
        message.margin !== undefined && (obj.margin = message.margin);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        return obj;
    },
    create(base) {
        return DerivativeOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeOrder();
        message.marketId = object.marketId ?? "";
        message.orderInfo = (object.orderInfo !== undefined && object.orderInfo !== null)
            ? OrderInfo.fromPartial(object.orderInfo)
            : undefined;
        message.orderType = object.orderType ?? 0;
        message.margin = object.margin ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        return message;
    },
};
function createBaseSubaccountOrderbookMetadata() {
    return {
        vanillaLimitOrderCount: 0,
        reduceOnlyLimitOrderCount: 0,
        aggregateReduceOnlyQuantity: "",
        aggregateVanillaQuantity: "",
        vanillaConditionalOrderCount: 0,
        reduceOnlyConditionalOrderCount: 0,
    };
}
export const SubaccountOrderbookMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.vanillaLimitOrderCount !== 0) {
            writer.uint32(8).uint32(message.vanillaLimitOrderCount);
        }
        if (message.reduceOnlyLimitOrderCount !== 0) {
            writer.uint32(16).uint32(message.reduceOnlyLimitOrderCount);
        }
        if (message.aggregateReduceOnlyQuantity !== "") {
            writer.uint32(26).string(message.aggregateReduceOnlyQuantity);
        }
        if (message.aggregateVanillaQuantity !== "") {
            writer.uint32(34).string(message.aggregateVanillaQuantity);
        }
        if (message.vanillaConditionalOrderCount !== 0) {
            writer.uint32(40).uint32(message.vanillaConditionalOrderCount);
        }
        if (message.reduceOnlyConditionalOrderCount !== 0) {
            writer.uint32(48).uint32(message.reduceOnlyConditionalOrderCount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrderbookMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vanillaLimitOrderCount = reader.uint32();
                    break;
                case 2:
                    message.reduceOnlyLimitOrderCount = reader.uint32();
                    break;
                case 3:
                    message.aggregateReduceOnlyQuantity = reader.string();
                    break;
                case 4:
                    message.aggregateVanillaQuantity = reader.string();
                    break;
                case 5:
                    message.vanillaConditionalOrderCount = reader.uint32();
                    break;
                case 6:
                    message.reduceOnlyConditionalOrderCount = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            vanillaLimitOrderCount: isSet(object.vanillaLimitOrderCount) ? Number(object.vanillaLimitOrderCount) : 0,
            reduceOnlyLimitOrderCount: isSet(object.reduceOnlyLimitOrderCount) ? Number(object.reduceOnlyLimitOrderCount) : 0,
            aggregateReduceOnlyQuantity: isSet(object.aggregateReduceOnlyQuantity)
                ? String(object.aggregateReduceOnlyQuantity)
                : "",
            aggregateVanillaQuantity: isSet(object.aggregateVanillaQuantity) ? String(object.aggregateVanillaQuantity) : "",
            vanillaConditionalOrderCount: isSet(object.vanillaConditionalOrderCount)
                ? Number(object.vanillaConditionalOrderCount)
                : 0,
            reduceOnlyConditionalOrderCount: isSet(object.reduceOnlyConditionalOrderCount)
                ? Number(object.reduceOnlyConditionalOrderCount)
                : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.vanillaLimitOrderCount !== undefined &&
            (obj.vanillaLimitOrderCount = Math.round(message.vanillaLimitOrderCount));
        message.reduceOnlyLimitOrderCount !== undefined &&
            (obj.reduceOnlyLimitOrderCount = Math.round(message.reduceOnlyLimitOrderCount));
        message.aggregateReduceOnlyQuantity !== undefined &&
            (obj.aggregateReduceOnlyQuantity = message.aggregateReduceOnlyQuantity);
        message.aggregateVanillaQuantity !== undefined && (obj.aggregateVanillaQuantity = message.aggregateVanillaQuantity);
        message.vanillaConditionalOrderCount !== undefined &&
            (obj.vanillaConditionalOrderCount = Math.round(message.vanillaConditionalOrderCount));
        message.reduceOnlyConditionalOrderCount !== undefined &&
            (obj.reduceOnlyConditionalOrderCount = Math.round(message.reduceOnlyConditionalOrderCount));
        return obj;
    },
    create(base) {
        return SubaccountOrderbookMetadata.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrderbookMetadata();
        message.vanillaLimitOrderCount = object.vanillaLimitOrderCount ?? 0;
        message.reduceOnlyLimitOrderCount = object.reduceOnlyLimitOrderCount ?? 0;
        message.aggregateReduceOnlyQuantity = object.aggregateReduceOnlyQuantity ?? "";
        message.aggregateVanillaQuantity = object.aggregateVanillaQuantity ?? "";
        message.vanillaConditionalOrderCount = object.vanillaConditionalOrderCount ?? 0;
        message.reduceOnlyConditionalOrderCount = object.reduceOnlyConditionalOrderCount ?? 0;
        return message;
    },
};
function createBaseSubaccountOrder() {
    return { price: "", quantity: "", isReduceOnly: false, cid: "" };
}
export const SubaccountOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.isReduceOnly === true) {
            writer.uint32(24).bool(message.isReduceOnly);
        }
        if (message.cid !== "") {
            writer.uint32(34).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.isReduceOnly = reader.bool();
                    break;
                case 4:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            isReduceOnly: isSet(object.isReduceOnly) ? Boolean(object.isReduceOnly) : false,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.isReduceOnly !== undefined && (obj.isReduceOnly = message.isReduceOnly);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SubaccountOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrder();
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.isReduceOnly = object.isReduceOnly ?? false;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseSubaccountOrderData() {
    return { order: undefined, orderHash: new Uint8Array() };
}
export const SubaccountOrderData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.order !== undefined) {
            SubaccountOrder.encode(message.order, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(18).bytes(message.orderHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrderData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.order = SubaccountOrder.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.orderHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            order: isSet(object.order) ? SubaccountOrder.fromJSON(object.order) : undefined,
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.order !== undefined && (obj.order = message.order ? SubaccountOrder.toJSON(message.order) : undefined);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return SubaccountOrderData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrderData();
        message.order = (object.order !== undefined && object.order !== null)
            ? SubaccountOrder.fromPartial(object.order)
            : undefined;
        message.orderHash = object.orderHash ?? new Uint8Array();
        return message;
    },
};
function createBaseDerivativeLimitOrder() {
    return {
        orderInfo: undefined,
        orderType: 0,
        margin: "",
        fillable: "",
        triggerPrice: "",
        orderHash: new Uint8Array(),
    };
}
export const DerivativeLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderInfo !== undefined) {
            OrderInfo.encode(message.orderInfo, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderType !== 0) {
            writer.uint32(16).int32(message.orderType);
        }
        if (message.margin !== "") {
            writer.uint32(26).string(message.margin);
        }
        if (message.fillable !== "") {
            writer.uint32(34).string(message.fillable);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(42).string(message.triggerPrice);
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(50).bytes(message.orderHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderInfo = OrderInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.orderType = reader.int32();
                    break;
                case 3:
                    message.margin = reader.string();
                    break;
                case 4:
                    message.fillable = reader.string();
                    break;
                case 5:
                    message.triggerPrice = reader.string();
                    break;
                case 6:
                    message.orderHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderInfo: isSet(object.orderInfo) ? OrderInfo.fromJSON(object.orderInfo) : undefined,
            orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
            margin: isSet(object.margin) ? String(object.margin) : "",
            fillable: isSet(object.fillable) ? String(object.fillable) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderInfo !== undefined &&
            (obj.orderInfo = message.orderInfo ? OrderInfo.toJSON(message.orderInfo) : undefined);
        message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
        message.margin !== undefined && (obj.margin = message.margin);
        message.fillable !== undefined && (obj.fillable = message.fillable);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return DerivativeLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeLimitOrder();
        message.orderInfo = (object.orderInfo !== undefined && object.orderInfo !== null)
            ? OrderInfo.fromPartial(object.orderInfo)
            : undefined;
        message.orderType = object.orderType ?? 0;
        message.margin = object.margin ?? "";
        message.fillable = object.fillable ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.orderHash = object.orderHash ?? new Uint8Array();
        return message;
    },
};
function createBaseDerivativeMarketOrder() {
    return {
        orderInfo: undefined,
        orderType: 0,
        margin: "",
        marginHold: "",
        triggerPrice: "",
        orderHash: new Uint8Array(),
    };
}
export const DerivativeMarketOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderInfo !== undefined) {
            OrderInfo.encode(message.orderInfo, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderType !== 0) {
            writer.uint32(16).int32(message.orderType);
        }
        if (message.margin !== "") {
            writer.uint32(26).string(message.margin);
        }
        if (message.marginHold !== "") {
            writer.uint32(34).string(message.marginHold);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(42).string(message.triggerPrice);
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(50).bytes(message.orderHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarketOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderInfo = OrderInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.orderType = reader.int32();
                    break;
                case 3:
                    message.margin = reader.string();
                    break;
                case 4:
                    message.marginHold = reader.string();
                    break;
                case 5:
                    message.triggerPrice = reader.string();
                    break;
                case 6:
                    message.orderHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderInfo: isSet(object.orderInfo) ? OrderInfo.fromJSON(object.orderInfo) : undefined,
            orderType: isSet(object.orderType) ? orderTypeFromJSON(object.orderType) : 0,
            margin: isSet(object.margin) ? String(object.margin) : "",
            marginHold: isSet(object.marginHold) ? String(object.marginHold) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderInfo !== undefined &&
            (obj.orderInfo = message.orderInfo ? OrderInfo.toJSON(message.orderInfo) : undefined);
        message.orderType !== undefined && (obj.orderType = orderTypeToJSON(message.orderType));
        message.margin !== undefined && (obj.margin = message.margin);
        message.marginHold !== undefined && (obj.marginHold = message.marginHold);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return DerivativeMarketOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarketOrder();
        message.orderInfo = (object.orderInfo !== undefined && object.orderInfo !== null)
            ? OrderInfo.fromPartial(object.orderInfo)
            : undefined;
        message.orderType = object.orderType ?? 0;
        message.margin = object.margin ?? "";
        message.marginHold = object.marginHold ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.orderHash = object.orderHash ?? new Uint8Array();
        return message;
    },
};
function createBasePosition() {
    return { isLong: false, quantity: "", entryPrice: "", margin: "", cumulativeFundingEntry: "" };
}
export const Position = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.isLong === true) {
            writer.uint32(8).bool(message.isLong);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.entryPrice !== "") {
            writer.uint32(26).string(message.entryPrice);
        }
        if (message.margin !== "") {
            writer.uint32(34).string(message.margin);
        }
        if (message.cumulativeFundingEntry !== "") {
            writer.uint32(42).string(message.cumulativeFundingEntry);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isLong = reader.bool();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.entryPrice = reader.string();
                    break;
                case 4:
                    message.margin = reader.string();
                    break;
                case 5:
                    message.cumulativeFundingEntry = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            isLong: isSet(object.isLong) ? Boolean(object.isLong) : false,
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            entryPrice: isSet(object.entryPrice) ? String(object.entryPrice) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
            cumulativeFundingEntry: isSet(object.cumulativeFundingEntry) ? String(object.cumulativeFundingEntry) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.isLong !== undefined && (obj.isLong = message.isLong);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.entryPrice !== undefined && (obj.entryPrice = message.entryPrice);
        message.margin !== undefined && (obj.margin = message.margin);
        message.cumulativeFundingEntry !== undefined && (obj.cumulativeFundingEntry = message.cumulativeFundingEntry);
        return obj;
    },
    create(base) {
        return Position.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePosition();
        message.isLong = object.isLong ?? false;
        message.quantity = object.quantity ?? "";
        message.entryPrice = object.entryPrice ?? "";
        message.margin = object.margin ?? "";
        message.cumulativeFundingEntry = object.cumulativeFundingEntry ?? "";
        return message;
    },
};
function createBaseMarketOrderIndicator() {
    return { marketId: "", isBuy: false };
}
export const MarketOrderIndicator = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isBuy === true) {
            writer.uint32(16).bool(message.isBuy);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketOrderIndicator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isBuy = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isBuy: isSet(object.isBuy) ? Boolean(object.isBuy) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isBuy !== undefined && (obj.isBuy = message.isBuy);
        return obj;
    },
    create(base) {
        return MarketOrderIndicator.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketOrderIndicator();
        message.marketId = object.marketId ?? "";
        message.isBuy = object.isBuy ?? false;
        return message;
    },
};
function createBaseTradeLog() {
    return {
        quantity: "",
        price: "",
        subaccountId: new Uint8Array(),
        fee: "",
        orderHash: new Uint8Array(),
        feeRecipientAddress: new Uint8Array(),
        cid: "",
    };
}
export const TradeLog = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.quantity !== "") {
            writer.uint32(10).string(message.quantity);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        if (message.subaccountId.length !== 0) {
            writer.uint32(26).bytes(message.subaccountId);
        }
        if (message.fee !== "") {
            writer.uint32(34).string(message.fee);
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(42).bytes(message.orderHash);
        }
        if (message.feeRecipientAddress.length !== 0) {
            writer.uint32(50).bytes(message.feeRecipientAddress);
        }
        if (message.cid !== "") {
            writer.uint32(58).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradeLog();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quantity = reader.string();
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.bytes();
                    break;
                case 4:
                    message.fee = reader.string();
                    break;
                case 5:
                    message.orderHash = reader.bytes();
                    break;
                case 6:
                    message.feeRecipientAddress = reader.bytes();
                    break;
                case 7:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            price: isSet(object.price) ? String(object.price) : "",
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
            fee: isSet(object.fee) ? String(object.fee) : "",
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
            feeRecipientAddress: isSet(object.feeRecipientAddress)
                ? bytesFromBase64(object.feeRecipientAddress)
                : new Uint8Array(),
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.price !== undefined && (obj.price = message.price);
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        message.fee !== undefined && (obj.fee = message.fee);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        message.feeRecipientAddress !== undefined &&
            (obj.feeRecipientAddress = base64FromBytes(message.feeRecipientAddress !== undefined ? message.feeRecipientAddress : new Uint8Array()));
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return TradeLog.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradeLog();
        message.quantity = object.quantity ?? "";
        message.price = object.price ?? "";
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        message.fee = object.fee ?? "";
        message.orderHash = object.orderHash ?? new Uint8Array();
        message.feeRecipientAddress = object.feeRecipientAddress ?? new Uint8Array();
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBasePositionDelta() {
    return { isLong: false, executionQuantity: "", executionMargin: "", executionPrice: "" };
}
export const PositionDelta = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.isLong === true) {
            writer.uint32(8).bool(message.isLong);
        }
        if (message.executionQuantity !== "") {
            writer.uint32(18).string(message.executionQuantity);
        }
        if (message.executionMargin !== "") {
            writer.uint32(26).string(message.executionMargin);
        }
        if (message.executionPrice !== "") {
            writer.uint32(34).string(message.executionPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionDelta();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isLong = reader.bool();
                    break;
                case 2:
                    message.executionQuantity = reader.string();
                    break;
                case 3:
                    message.executionMargin = reader.string();
                    break;
                case 4:
                    message.executionPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            isLong: isSet(object.isLong) ? Boolean(object.isLong) : false,
            executionQuantity: isSet(object.executionQuantity) ? String(object.executionQuantity) : "",
            executionMargin: isSet(object.executionMargin) ? String(object.executionMargin) : "",
            executionPrice: isSet(object.executionPrice) ? String(object.executionPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.isLong !== undefined && (obj.isLong = message.isLong);
        message.executionQuantity !== undefined && (obj.executionQuantity = message.executionQuantity);
        message.executionMargin !== undefined && (obj.executionMargin = message.executionMargin);
        message.executionPrice !== undefined && (obj.executionPrice = message.executionPrice);
        return obj;
    },
    create(base) {
        return PositionDelta.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionDelta();
        message.isLong = object.isLong ?? false;
        message.executionQuantity = object.executionQuantity ?? "";
        message.executionMargin = object.executionMargin ?? "";
        message.executionPrice = object.executionPrice ?? "";
        return message;
    },
};
function createBaseDerivativeTradeLog() {
    return {
        subaccountId: new Uint8Array(),
        positionDelta: undefined,
        payout: "",
        fee: "",
        orderHash: new Uint8Array(),
        feeRecipientAddress: new Uint8Array(),
        cid: "",
        pnl: "",
    };
}
export const DerivativeTradeLog = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId.length !== 0) {
            writer.uint32(10).bytes(message.subaccountId);
        }
        if (message.positionDelta !== undefined) {
            PositionDelta.encode(message.positionDelta, writer.uint32(18).fork()).ldelim();
        }
        if (message.payout !== "") {
            writer.uint32(26).string(message.payout);
        }
        if (message.fee !== "") {
            writer.uint32(34).string(message.fee);
        }
        if (message.orderHash.length !== 0) {
            writer.uint32(42).bytes(message.orderHash);
        }
        if (message.feeRecipientAddress.length !== 0) {
            writer.uint32(50).bytes(message.feeRecipientAddress);
        }
        if (message.cid !== "") {
            writer.uint32(58).string(message.cid);
        }
        if (message.pnl !== "") {
            writer.uint32(66).string(message.pnl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeTradeLog();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.bytes();
                    break;
                case 2:
                    message.positionDelta = PositionDelta.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.payout = reader.string();
                    break;
                case 4:
                    message.fee = reader.string();
                    break;
                case 5:
                    message.orderHash = reader.bytes();
                    break;
                case 6:
                    message.feeRecipientAddress = reader.bytes();
                    break;
                case 7:
                    message.cid = reader.string();
                    break;
                case 8:
                    message.pnl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
            positionDelta: isSet(object.positionDelta) ? PositionDelta.fromJSON(object.positionDelta) : undefined,
            payout: isSet(object.payout) ? String(object.payout) : "",
            fee: isSet(object.fee) ? String(object.fee) : "",
            orderHash: isSet(object.orderHash) ? bytesFromBase64(object.orderHash) : new Uint8Array(),
            feeRecipientAddress: isSet(object.feeRecipientAddress)
                ? bytesFromBase64(object.feeRecipientAddress)
                : new Uint8Array(),
            cid: isSet(object.cid) ? String(object.cid) : "",
            pnl: isSet(object.pnl) ? String(object.pnl) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        message.positionDelta !== undefined &&
            (obj.positionDelta = message.positionDelta ? PositionDelta.toJSON(message.positionDelta) : undefined);
        message.payout !== undefined && (obj.payout = message.payout);
        message.fee !== undefined && (obj.fee = message.fee);
        message.orderHash !== undefined &&
            (obj.orderHash = base64FromBytes(message.orderHash !== undefined ? message.orderHash : new Uint8Array()));
        message.feeRecipientAddress !== undefined &&
            (obj.feeRecipientAddress = base64FromBytes(message.feeRecipientAddress !== undefined ? message.feeRecipientAddress : new Uint8Array()));
        message.cid !== undefined && (obj.cid = message.cid);
        message.pnl !== undefined && (obj.pnl = message.pnl);
        return obj;
    },
    create(base) {
        return DerivativeTradeLog.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeTradeLog();
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        message.positionDelta = (object.positionDelta !== undefined && object.positionDelta !== null)
            ? PositionDelta.fromPartial(object.positionDelta)
            : undefined;
        message.payout = object.payout ?? "";
        message.fee = object.fee ?? "";
        message.orderHash = object.orderHash ?? new Uint8Array();
        message.feeRecipientAddress = object.feeRecipientAddress ?? new Uint8Array();
        message.cid = object.cid ?? "";
        message.pnl = object.pnl ?? "";
        return message;
    },
};
function createBaseSubaccountPosition() {
    return { position: undefined, subaccountId: new Uint8Array() };
}
export const SubaccountPosition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.position !== undefined) {
            Position.encode(message.position, writer.uint32(10).fork()).ldelim();
        }
        if (message.subaccountId.length !== 0) {
            writer.uint32(18).bytes(message.subaccountId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountPosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.position = Position.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.subaccountId = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            position: isSet(object.position) ? Position.fromJSON(object.position) : undefined,
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.position !== undefined && (obj.position = message.position ? Position.toJSON(message.position) : undefined);
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        return obj;
    },
    create(base) {
        return SubaccountPosition.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountPosition();
        message.position = (object.position !== undefined && object.position !== null)
            ? Position.fromPartial(object.position)
            : undefined;
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        return message;
    },
};
function createBaseSubaccountDeposit() {
    return { subaccountId: new Uint8Array(), deposit: undefined };
}
export const SubaccountDeposit = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId.length !== 0) {
            writer.uint32(10).bytes(message.subaccountId);
        }
        if (message.deposit !== undefined) {
            Deposit.encode(message.deposit, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountDeposit();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.bytes();
                    break;
                case 2:
                    message.deposit = Deposit.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
            deposit: isSet(object.deposit) ? Deposit.fromJSON(object.deposit) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        message.deposit !== undefined && (obj.deposit = message.deposit ? Deposit.toJSON(message.deposit) : undefined);
        return obj;
    },
    create(base) {
        return SubaccountDeposit.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountDeposit();
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        message.deposit = (object.deposit !== undefined && object.deposit !== null)
            ? Deposit.fromPartial(object.deposit)
            : undefined;
        return message;
    },
};
function createBaseDepositUpdate() {
    return { denom: "", deposits: [] };
}
export const DepositUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        for (const v of message.deposits) {
            SubaccountDeposit.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDepositUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.deposits.push(SubaccountDeposit.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            deposits: Array.isArray(object?.deposits) ? object.deposits.map((e) => SubaccountDeposit.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        if (message.deposits) {
            obj.deposits = message.deposits.map((e) => e ? SubaccountDeposit.toJSON(e) : undefined);
        }
        else {
            obj.deposits = [];
        }
        return obj;
    },
    create(base) {
        return DepositUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDepositUpdate();
        message.denom = object.denom ?? "";
        message.deposits = object.deposits?.map((e) => SubaccountDeposit.fromPartial(e)) || [];
        return message;
    },
};
function createBasePointsMultiplier() {
    return { makerPointsMultiplier: "", takerPointsMultiplier: "" };
}
export const PointsMultiplier = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.makerPointsMultiplier !== "") {
            writer.uint32(10).string(message.makerPointsMultiplier);
        }
        if (message.takerPointsMultiplier !== "") {
            writer.uint32(18).string(message.takerPointsMultiplier);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePointsMultiplier();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.makerPointsMultiplier = reader.string();
                    break;
                case 2:
                    message.takerPointsMultiplier = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            makerPointsMultiplier: isSet(object.makerPointsMultiplier) ? String(object.makerPointsMultiplier) : "",
            takerPointsMultiplier: isSet(object.takerPointsMultiplier) ? String(object.takerPointsMultiplier) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.makerPointsMultiplier !== undefined && (obj.makerPointsMultiplier = message.makerPointsMultiplier);
        message.takerPointsMultiplier !== undefined && (obj.takerPointsMultiplier = message.takerPointsMultiplier);
        return obj;
    },
    create(base) {
        return PointsMultiplier.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePointsMultiplier();
        message.makerPointsMultiplier = object.makerPointsMultiplier ?? "";
        message.takerPointsMultiplier = object.takerPointsMultiplier ?? "";
        return message;
    },
};
function createBaseTradingRewardCampaignBoostInfo() {
    return {
        boostedSpotMarketIds: [],
        spotMarketMultipliers: [],
        boostedDerivativeMarketIds: [],
        derivativeMarketMultipliers: [],
    };
}
export const TradingRewardCampaignBoostInfo = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.boostedSpotMarketIds) {
            writer.uint32(10).string(v);
        }
        for (const v of message.spotMarketMultipliers) {
            PointsMultiplier.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.boostedDerivativeMarketIds) {
            writer.uint32(26).string(v);
        }
        for (const v of message.derivativeMarketMultipliers) {
            PointsMultiplier.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardCampaignBoostInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.boostedSpotMarketIds.push(reader.string());
                    break;
                case 2:
                    message.spotMarketMultipliers.push(PointsMultiplier.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.boostedDerivativeMarketIds.push(reader.string());
                    break;
                case 4:
                    message.derivativeMarketMultipliers.push(PointsMultiplier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            boostedSpotMarketIds: Array.isArray(object?.boostedSpotMarketIds)
                ? object.boostedSpotMarketIds.map((e) => String(e))
                : [],
            spotMarketMultipliers: Array.isArray(object?.spotMarketMultipliers)
                ? object.spotMarketMultipliers.map((e) => PointsMultiplier.fromJSON(e))
                : [],
            boostedDerivativeMarketIds: Array.isArray(object?.boostedDerivativeMarketIds)
                ? object.boostedDerivativeMarketIds.map((e) => String(e))
                : [],
            derivativeMarketMultipliers: Array.isArray(object?.derivativeMarketMultipliers)
                ? object.derivativeMarketMultipliers.map((e) => PointsMultiplier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.boostedSpotMarketIds) {
            obj.boostedSpotMarketIds = message.boostedSpotMarketIds.map((e) => e);
        }
        else {
            obj.boostedSpotMarketIds = [];
        }
        if (message.spotMarketMultipliers) {
            obj.spotMarketMultipliers = message.spotMarketMultipliers.map((e) => e ? PointsMultiplier.toJSON(e) : undefined);
        }
        else {
            obj.spotMarketMultipliers = [];
        }
        if (message.boostedDerivativeMarketIds) {
            obj.boostedDerivativeMarketIds = message.boostedDerivativeMarketIds.map((e) => e);
        }
        else {
            obj.boostedDerivativeMarketIds = [];
        }
        if (message.derivativeMarketMultipliers) {
            obj.derivativeMarketMultipliers = message.derivativeMarketMultipliers.map((e) => e ? PointsMultiplier.toJSON(e) : undefined);
        }
        else {
            obj.derivativeMarketMultipliers = [];
        }
        return obj;
    },
    create(base) {
        return TradingRewardCampaignBoostInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardCampaignBoostInfo();
        message.boostedSpotMarketIds = object.boostedSpotMarketIds?.map((e) => e) || [];
        message.spotMarketMultipliers = object.spotMarketMultipliers?.map((e) => PointsMultiplier.fromPartial(e)) || [];
        message.boostedDerivativeMarketIds = object.boostedDerivativeMarketIds?.map((e) => e) || [];
        message.derivativeMarketMultipliers =
            object.derivativeMarketMultipliers?.map((e) => PointsMultiplier.fromPartial(e)) || [];
        return message;
    },
};
function createBaseCampaignRewardPool() {
    return { startTimestamp: "0", maxCampaignRewards: [] };
}
export const CampaignRewardPool = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.startTimestamp !== "0") {
            writer.uint32(8).int64(message.startTimestamp);
        }
        for (const v of message.maxCampaignRewards) {
            Coin.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignRewardPool();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.startTimestamp = longToString(reader.int64());
                    break;
                case 2:
                    message.maxCampaignRewards.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            startTimestamp: isSet(object.startTimestamp) ? String(object.startTimestamp) : "0",
            maxCampaignRewards: Array.isArray(object?.maxCampaignRewards)
                ? object.maxCampaignRewards.map((e) => Coin.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.startTimestamp !== undefined && (obj.startTimestamp = message.startTimestamp);
        if (message.maxCampaignRewards) {
            obj.maxCampaignRewards = message.maxCampaignRewards.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.maxCampaignRewards = [];
        }
        return obj;
    },
    create(base) {
        return CampaignRewardPool.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignRewardPool();
        message.startTimestamp = object.startTimestamp ?? "0";
        message.maxCampaignRewards = object.maxCampaignRewards?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseTradingRewardCampaignInfo() {
    return {
        campaignDurationSeconds: "0",
        quoteDenoms: [],
        tradingRewardBoostInfo: undefined,
        disqualifiedMarketIds: [],
    };
}
export const TradingRewardCampaignInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignDurationSeconds !== "0") {
            writer.uint32(8).int64(message.campaignDurationSeconds);
        }
        for (const v of message.quoteDenoms) {
            writer.uint32(18).string(v);
        }
        if (message.tradingRewardBoostInfo !== undefined) {
            TradingRewardCampaignBoostInfo.encode(message.tradingRewardBoostInfo, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.disqualifiedMarketIds) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardCampaignInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignDurationSeconds = longToString(reader.int64());
                    break;
                case 2:
                    message.quoteDenoms.push(reader.string());
                    break;
                case 3:
                    message.tradingRewardBoostInfo = TradingRewardCampaignBoostInfo.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.disqualifiedMarketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignDurationSeconds: isSet(object.campaignDurationSeconds) ? String(object.campaignDurationSeconds) : "0",
            quoteDenoms: Array.isArray(object?.quoteDenoms) ? object.quoteDenoms.map((e) => String(e)) : [],
            tradingRewardBoostInfo: isSet(object.tradingRewardBoostInfo)
                ? TradingRewardCampaignBoostInfo.fromJSON(object.tradingRewardBoostInfo)
                : undefined,
            disqualifiedMarketIds: Array.isArray(object?.disqualifiedMarketIds)
                ? object.disqualifiedMarketIds.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignDurationSeconds !== undefined && (obj.campaignDurationSeconds = message.campaignDurationSeconds);
        if (message.quoteDenoms) {
            obj.quoteDenoms = message.quoteDenoms.map((e) => e);
        }
        else {
            obj.quoteDenoms = [];
        }
        message.tradingRewardBoostInfo !== undefined && (obj.tradingRewardBoostInfo = message.tradingRewardBoostInfo
            ? TradingRewardCampaignBoostInfo.toJSON(message.tradingRewardBoostInfo)
            : undefined);
        if (message.disqualifiedMarketIds) {
            obj.disqualifiedMarketIds = message.disqualifiedMarketIds.map((e) => e);
        }
        else {
            obj.disqualifiedMarketIds = [];
        }
        return obj;
    },
    create(base) {
        return TradingRewardCampaignInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardCampaignInfo();
        message.campaignDurationSeconds = object.campaignDurationSeconds ?? "0";
        message.quoteDenoms = object.quoteDenoms?.map((e) => e) || [];
        message.tradingRewardBoostInfo =
            (object.tradingRewardBoostInfo !== undefined && object.tradingRewardBoostInfo !== null)
                ? TradingRewardCampaignBoostInfo.fromPartial(object.tradingRewardBoostInfo)
                : undefined;
        message.disqualifiedMarketIds = object.disqualifiedMarketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseFeeDiscountTierInfo() {
    return { makerDiscountRate: "", takerDiscountRate: "", stakedAmount: "", volume: "" };
}
export const FeeDiscountTierInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.makerDiscountRate !== "") {
            writer.uint32(10).string(message.makerDiscountRate);
        }
        if (message.takerDiscountRate !== "") {
            writer.uint32(18).string(message.takerDiscountRate);
        }
        if (message.stakedAmount !== "") {
            writer.uint32(26).string(message.stakedAmount);
        }
        if (message.volume !== "") {
            writer.uint32(34).string(message.volume);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeeDiscountTierInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.makerDiscountRate = reader.string();
                    break;
                case 2:
                    message.takerDiscountRate = reader.string();
                    break;
                case 3:
                    message.stakedAmount = reader.string();
                    break;
                case 4:
                    message.volume = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            makerDiscountRate: isSet(object.makerDiscountRate) ? String(object.makerDiscountRate) : "",
            takerDiscountRate: isSet(object.takerDiscountRate) ? String(object.takerDiscountRate) : "",
            stakedAmount: isSet(object.stakedAmount) ? String(object.stakedAmount) : "",
            volume: isSet(object.volume) ? String(object.volume) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.makerDiscountRate !== undefined && (obj.makerDiscountRate = message.makerDiscountRate);
        message.takerDiscountRate !== undefined && (obj.takerDiscountRate = message.takerDiscountRate);
        message.stakedAmount !== undefined && (obj.stakedAmount = message.stakedAmount);
        message.volume !== undefined && (obj.volume = message.volume);
        return obj;
    },
    create(base) {
        return FeeDiscountTierInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeeDiscountTierInfo();
        message.makerDiscountRate = object.makerDiscountRate ?? "";
        message.takerDiscountRate = object.takerDiscountRate ?? "";
        message.stakedAmount = object.stakedAmount ?? "";
        message.volume = object.volume ?? "";
        return message;
    },
};
function createBaseFeeDiscountSchedule() {
    return { bucketCount: "0", bucketDuration: "0", quoteDenoms: [], tierInfos: [], disqualifiedMarketIds: [] };
}
export const FeeDiscountSchedule = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bucketCount !== "0") {
            writer.uint32(8).uint64(message.bucketCount);
        }
        if (message.bucketDuration !== "0") {
            writer.uint32(16).int64(message.bucketDuration);
        }
        for (const v of message.quoteDenoms) {
            writer.uint32(26).string(v);
        }
        for (const v of message.tierInfos) {
            FeeDiscountTierInfo.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.disqualifiedMarketIds) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeeDiscountSchedule();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bucketCount = longToString(reader.uint64());
                    break;
                case 2:
                    message.bucketDuration = longToString(reader.int64());
                    break;
                case 3:
                    message.quoteDenoms.push(reader.string());
                    break;
                case 4:
                    message.tierInfos.push(FeeDiscountTierInfo.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.disqualifiedMarketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bucketCount: isSet(object.bucketCount) ? String(object.bucketCount) : "0",
            bucketDuration: isSet(object.bucketDuration) ? String(object.bucketDuration) : "0",
            quoteDenoms: Array.isArray(object?.quoteDenoms) ? object.quoteDenoms.map((e) => String(e)) : [],
            tierInfos: Array.isArray(object?.tierInfos)
                ? object.tierInfos.map((e) => FeeDiscountTierInfo.fromJSON(e))
                : [],
            disqualifiedMarketIds: Array.isArray(object?.disqualifiedMarketIds)
                ? object.disqualifiedMarketIds.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.bucketCount !== undefined && (obj.bucketCount = message.bucketCount);
        message.bucketDuration !== undefined && (obj.bucketDuration = message.bucketDuration);
        if (message.quoteDenoms) {
            obj.quoteDenoms = message.quoteDenoms.map((e) => e);
        }
        else {
            obj.quoteDenoms = [];
        }
        if (message.tierInfos) {
            obj.tierInfos = message.tierInfos.map((e) => e ? FeeDiscountTierInfo.toJSON(e) : undefined);
        }
        else {
            obj.tierInfos = [];
        }
        if (message.disqualifiedMarketIds) {
            obj.disqualifiedMarketIds = message.disqualifiedMarketIds.map((e) => e);
        }
        else {
            obj.disqualifiedMarketIds = [];
        }
        return obj;
    },
    create(base) {
        return FeeDiscountSchedule.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeeDiscountSchedule();
        message.bucketCount = object.bucketCount ?? "0";
        message.bucketDuration = object.bucketDuration ?? "0";
        message.quoteDenoms = object.quoteDenoms?.map((e) => e) || [];
        message.tierInfos = object.tierInfos?.map((e) => FeeDiscountTierInfo.fromPartial(e)) || [];
        message.disqualifiedMarketIds = object.disqualifiedMarketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseFeeDiscountTierTTL() {
    return { tier: "0", ttlTimestamp: "0" };
}
export const FeeDiscountTierTTL = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tier !== "0") {
            writer.uint32(8).uint64(message.tier);
        }
        if (message.ttlTimestamp !== "0") {
            writer.uint32(16).int64(message.ttlTimestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeeDiscountTierTTL();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tier = longToString(reader.uint64());
                    break;
                case 2:
                    message.ttlTimestamp = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tier: isSet(object.tier) ? String(object.tier) : "0",
            ttlTimestamp: isSet(object.ttlTimestamp) ? String(object.ttlTimestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.tier !== undefined && (obj.tier = message.tier);
        message.ttlTimestamp !== undefined && (obj.ttlTimestamp = message.ttlTimestamp);
        return obj;
    },
    create(base) {
        return FeeDiscountTierTTL.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeeDiscountTierTTL();
        message.tier = object.tier ?? "0";
        message.ttlTimestamp = object.ttlTimestamp ?? "0";
        return message;
    },
};
function createBaseVolumeRecord() {
    return { makerVolume: "", takerVolume: "" };
}
export const VolumeRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.makerVolume !== "") {
            writer.uint32(10).string(message.makerVolume);
        }
        if (message.takerVolume !== "") {
            writer.uint32(18).string(message.takerVolume);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVolumeRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.makerVolume = reader.string();
                    break;
                case 2:
                    message.takerVolume = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            makerVolume: isSet(object.makerVolume) ? String(object.makerVolume) : "",
            takerVolume: isSet(object.takerVolume) ? String(object.takerVolume) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.makerVolume !== undefined && (obj.makerVolume = message.makerVolume);
        message.takerVolume !== undefined && (obj.takerVolume = message.takerVolume);
        return obj;
    },
    create(base) {
        return VolumeRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVolumeRecord();
        message.makerVolume = object.makerVolume ?? "";
        message.takerVolume = object.takerVolume ?? "";
        return message;
    },
};
function createBaseAccountRewards() {
    return { account: "", rewards: [] };
}
export const AccountRewards = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        for (const v of message.rewards) {
            Coin.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccountRewards();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.rewards.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            rewards: Array.isArray(object?.rewards) ? object.rewards.map((e) => Coin.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        if (message.rewards) {
            obj.rewards = message.rewards.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.rewards = [];
        }
        return obj;
    },
    create(base) {
        return AccountRewards.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAccountRewards();
        message.account = object.account ?? "";
        message.rewards = object.rewards?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseTradeRecords() {
    return { marketId: "", latestTradeRecords: [] };
}
export const TradeRecords = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        for (const v of message.latestTradeRecords) {
            TradeRecord.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradeRecords();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.latestTradeRecords.push(TradeRecord.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            latestTradeRecords: Array.isArray(object?.latestTradeRecords)
                ? object.latestTradeRecords.map((e) => TradeRecord.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.latestTradeRecords) {
            obj.latestTradeRecords = message.latestTradeRecords.map((e) => e ? TradeRecord.toJSON(e) : undefined);
        }
        else {
            obj.latestTradeRecords = [];
        }
        return obj;
    },
    create(base) {
        return TradeRecords.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradeRecords();
        message.marketId = object.marketId ?? "";
        message.latestTradeRecords = object.latestTradeRecords?.map((e) => TradeRecord.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSubaccountIDs() {
    return { subaccountIds: [] };
}
export const SubaccountIDs = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.subaccountIds) {
            writer.uint32(10).bytes(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountIDs();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountIds.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountIds: Array.isArray(object?.subaccountIds)
                ? object.subaccountIds.map((e) => bytesFromBase64(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.subaccountIds) {
            obj.subaccountIds = message.subaccountIds.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.subaccountIds = [];
        }
        return obj;
    },
    create(base) {
        return SubaccountIDs.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountIDs();
        message.subaccountIds = object.subaccountIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseTradeRecord() {
    return { timestamp: "0", price: "", quantity: "" };
}
export const TradeRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.timestamp !== "0") {
            writer.uint32(8).int64(message.timestamp);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(26).string(message.quantity);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradeRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.timestamp = longToString(reader.int64());
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                case 3:
                    message.quantity = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        return obj;
    },
    create(base) {
        return TradeRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradeRecord();
        message.timestamp = object.timestamp ?? "0";
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        return message;
    },
};
function createBaseLevel() {
    return { p: "", q: "" };
}
export const Level = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.p !== "") {
            writer.uint32(10).string(message.p);
        }
        if (message.q !== "") {
            writer.uint32(18).string(message.q);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLevel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.p = reader.string();
                    break;
                case 2:
                    message.q = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { p: isSet(object.p) ? String(object.p) : "", q: isSet(object.q) ? String(object.q) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.p !== undefined && (obj.p = message.p);
        message.q !== undefined && (obj.q = message.q);
        return obj;
    },
    create(base) {
        return Level.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLevel();
        message.p = object.p ?? "";
        message.q = object.q ?? "";
        return message;
    },
};
function createBaseAggregateSubaccountVolumeRecord() {
    return { subaccountId: "", marketVolumes: [] };
}
export const AggregateSubaccountVolumeRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketVolumes) {
            MarketVolume.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAggregateSubaccountVolumeRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketVolumes.push(MarketVolume.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketVolumes: Array.isArray(object?.marketVolumes)
                ? object.marketVolumes.map((e) => MarketVolume.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketVolumes) {
            obj.marketVolumes = message.marketVolumes.map((e) => e ? MarketVolume.toJSON(e) : undefined);
        }
        else {
            obj.marketVolumes = [];
        }
        return obj;
    },
    create(base) {
        return AggregateSubaccountVolumeRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAggregateSubaccountVolumeRecord();
        message.subaccountId = object.subaccountId ?? "";
        message.marketVolumes = object.marketVolumes?.map((e) => MarketVolume.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAggregateAccountVolumeRecord() {
    return { account: "", marketVolumes: [] };
}
export const AggregateAccountVolumeRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        for (const v of message.marketVolumes) {
            MarketVolume.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAggregateAccountVolumeRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.marketVolumes.push(MarketVolume.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            marketVolumes: Array.isArray(object?.marketVolumes)
                ? object.marketVolumes.map((e) => MarketVolume.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        if (message.marketVolumes) {
            obj.marketVolumes = message.marketVolumes.map((e) => e ? MarketVolume.toJSON(e) : undefined);
        }
        else {
            obj.marketVolumes = [];
        }
        return obj;
    },
    create(base) {
        return AggregateAccountVolumeRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAggregateAccountVolumeRecord();
        message.account = object.account ?? "";
        message.marketVolumes = object.marketVolumes?.map((e) => MarketVolume.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMarketVolume() {
    return { marketId: "", volume: undefined };
}
export const MarketVolume = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.volume !== undefined) {
            VolumeRecord.encode(message.volume, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketVolume();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.volume = VolumeRecord.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            volume: isSet(object.volume) ? VolumeRecord.fromJSON(object.volume) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.volume !== undefined && (obj.volume = message.volume ? VolumeRecord.toJSON(message.volume) : undefined);
        return obj;
    },
    create(base) {
        return MarketVolume.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketVolume();
        message.marketId = object.marketId ?? "";
        message.volume = (object.volume !== undefined && object.volume !== null)
            ? VolumeRecord.fromPartial(object.volume)
            : undefined;
        return message;
    },
};
function createBaseDenomDecimals() {
    return { denom: "", decimals: "0" };
}
export const DenomDecimals = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.decimals !== "0") {
            writer.uint32(16).uint64(message.decimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDenomDecimals();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.decimals = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            decimals: isSet(object.decimals) ? String(object.decimals) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.decimals !== undefined && (obj.decimals = message.decimals);
        return obj;
    },
    create(base) {
        return DenomDecimals.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDenomDecimals();
        message.denom = object.denom ?? "";
        message.decimals = object.decimals ?? "0";
        return message;
    },
};
function createBaseGrantAuthorization() {
    return { grantee: "", amount: "" };
}
export const GrantAuthorization = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.grantee !== "") {
            writer.uint32(10).string(message.grantee);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGrantAuthorization();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.grantee = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            grantee: isSet(object.grantee) ? String(object.grantee) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.grantee !== undefined && (obj.grantee = message.grantee);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return GrantAuthorization.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGrantAuthorization();
        message.grantee = object.grantee ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseActiveGrant() {
    return { granter: "", amount: "" };
}
export const ActiveGrant = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.granter !== "") {
            writer.uint32(10).string(message.granter);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseActiveGrant();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.granter = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            granter: isSet(object.granter) ? String(object.granter) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.granter !== undefined && (obj.granter = message.granter);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return ActiveGrant.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseActiveGrant();
        message.granter = object.granter ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseEffectiveGrant() {
    return { granter: "", netGrantedStake: "", isValid: false };
}
export const EffectiveGrant = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.granter !== "") {
            writer.uint32(10).string(message.granter);
        }
        if (message.netGrantedStake !== "") {
            writer.uint32(18).string(message.netGrantedStake);
        }
        if (message.isValid === true) {
            writer.uint32(24).bool(message.isValid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEffectiveGrant();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.granter = reader.string();
                    break;
                case 2:
                    message.netGrantedStake = reader.string();
                    break;
                case 3:
                    message.isValid = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            granter: isSet(object.granter) ? String(object.granter) : "",
            netGrantedStake: isSet(object.netGrantedStake) ? String(object.netGrantedStake) : "",
            isValid: isSet(object.isValid) ? Boolean(object.isValid) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.granter !== undefined && (obj.granter = message.granter);
        message.netGrantedStake !== undefined && (obj.netGrantedStake = message.netGrantedStake);
        message.isValid !== undefined && (obj.isValid = message.isValid);
        return obj;
    },
    create(base) {
        return EffectiveGrant.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEffectiveGrant();
        message.granter = object.granter ?? "";
        message.netGrantedStake = object.netGrantedStake ?? "";
        message.isValid = object.isValid ?? false;
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
