/* eslint-disable */
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective.exchange.v1beta1";
function createBaseCreateSpotLimitOrderAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const CreateSpotLimitOrderAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateSpotLimitOrderAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return CreateSpotLimitOrderAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCreateSpotLimitOrderAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseCreateSpotMarketOrderAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const CreateSpotMarketOrderAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateSpotMarketOrderAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return CreateSpotMarketOrderAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCreateSpotMarketOrderAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseBatchCreateSpotLimitOrdersAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const BatchCreateSpotLimitOrdersAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchCreateSpotLimitOrdersAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return BatchCreateSpotLimitOrdersAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchCreateSpotLimitOrdersAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseCancelSpotOrderAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const CancelSpotOrderAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelSpotOrderAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return CancelSpotOrderAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCancelSpotOrderAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseBatchCancelSpotOrdersAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const BatchCancelSpotOrdersAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchCancelSpotOrdersAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return BatchCancelSpotOrdersAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchCancelSpotOrdersAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseCreateDerivativeLimitOrderAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const CreateDerivativeLimitOrderAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateDerivativeLimitOrderAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return CreateDerivativeLimitOrderAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCreateDerivativeLimitOrderAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseCreateDerivativeMarketOrderAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const CreateDerivativeMarketOrderAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateDerivativeMarketOrderAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return CreateDerivativeMarketOrderAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCreateDerivativeMarketOrderAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseBatchCreateDerivativeLimitOrdersAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const BatchCreateDerivativeLimitOrdersAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchCreateDerivativeLimitOrdersAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return BatchCreateDerivativeLimitOrdersAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchCreateDerivativeLimitOrdersAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseCancelDerivativeOrderAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const CancelDerivativeOrderAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelDerivativeOrderAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return CancelDerivativeOrderAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCancelDerivativeOrderAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseBatchCancelDerivativeOrdersAuthz() {
    return { subaccountId: "", marketIds: [] };
}
export const BatchCancelDerivativeOrdersAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.marketIds) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchCancelDerivativeOrdersAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketIds: Array.isArray(object?.marketIds) ? object.marketIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return BatchCancelDerivativeOrdersAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchCancelDerivativeOrdersAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.marketIds = object.marketIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseBatchUpdateOrdersAuthz() {
    return { subaccountId: "", spotMarkets: [], derivativeMarkets: [] };
}
export const BatchUpdateOrdersAuthz = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.spotMarkets) {
            writer.uint32(18).string(v);
        }
        for (const v of message.derivativeMarkets) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchUpdateOrdersAuthz();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.spotMarkets.push(reader.string());
                    break;
                case 3:
                    message.derivativeMarkets.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            spotMarkets: Array.isArray(object?.spotMarkets) ? object.spotMarkets.map((e) => String(e)) : [],
            derivativeMarkets: Array.isArray(object?.derivativeMarkets)
                ? object.derivativeMarkets.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.spotMarkets) {
            obj.spotMarkets = message.spotMarkets.map((e) => e);
        }
        else {
            obj.spotMarkets = [];
        }
        if (message.derivativeMarkets) {
            obj.derivativeMarkets = message.derivativeMarkets.map((e) => e);
        }
        else {
            obj.derivativeMarkets = [];
        }
        return obj;
    },
    create(base) {
        return BatchUpdateOrdersAuthz.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchUpdateOrdersAuthz();
        message.subaccountId = object.subaccountId ?? "";
        message.spotMarkets = object.spotMarkets?.map((e) => e) || [];
        message.derivativeMarkets = object.derivativeMarkets?.map((e) => e) || [];
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
