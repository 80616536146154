/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "injective_explorer_rpc";
function createBaseGetAccountTxsRequest() {
    return {
        address: "",
        before: "0",
        after: "0",
        limit: 0,
        skip: "0",
        type: "",
        module: "",
        fromNumber: "0",
        toNumber: "0",
        startTime: "0",
        endTime: "0",
        status: "",
    };
}
export const GetAccountTxsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.before !== "0") {
            writer.uint32(16).uint64(message.before);
        }
        if (message.after !== "0") {
            writer.uint32(24).uint64(message.after);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.type !== "") {
            writer.uint32(50).string(message.type);
        }
        if (message.module !== "") {
            writer.uint32(58).string(message.module);
        }
        if (message.fromNumber !== "0") {
            writer.uint32(64).sint64(message.fromNumber);
        }
        if (message.toNumber !== "0") {
            writer.uint32(72).sint64(message.toNumber);
        }
        if (message.startTime !== "0") {
            writer.uint32(80).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(88).sint64(message.endTime);
        }
        if (message.status !== "") {
            writer.uint32(98).string(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetAccountTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.before = longToString(reader.uint64());
                    break;
                case 3:
                    message.after = longToString(reader.uint64());
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.type = reader.string();
                    break;
                case 7:
                    message.module = reader.string();
                    break;
                case 8:
                    message.fromNumber = longToString(reader.sint64());
                    break;
                case 9:
                    message.toNumber = longToString(reader.sint64());
                    break;
                case 10:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 11:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 12:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            before: isSet(object.before) ? String(object.before) : "0",
            after: isSet(object.after) ? String(object.after) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            type: isSet(object.type) ? String(object.type) : "",
            module: isSet(object.module) ? String(object.module) : "",
            fromNumber: isSet(object.fromNumber) ? String(object.fromNumber) : "0",
            toNumber: isSet(object.toNumber) ? String(object.toNumber) : "0",
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            status: isSet(object.status) ? String(object.status) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.before !== undefined && (obj.before = message.before);
        message.after !== undefined && (obj.after = message.after);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        message.type !== undefined && (obj.type = message.type);
        message.module !== undefined && (obj.module = message.module);
        message.fromNumber !== undefined && (obj.fromNumber = message.fromNumber);
        message.toNumber !== undefined && (obj.toNumber = message.toNumber);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        message.status !== undefined && (obj.status = message.status);
        return obj;
    },
    create(base) {
        return GetAccountTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetAccountTxsRequest();
        message.address = object.address ?? "";
        message.before = object.before ?? "0";
        message.after = object.after ?? "0";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        message.type = object.type ?? "";
        message.module = object.module ?? "";
        message.fromNumber = object.fromNumber ?? "0";
        message.toNumber = object.toNumber ?? "0";
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.status = object.status ?? "";
        return message;
    },
};
function createBaseGetAccountTxsResponse() {
    return { paging: undefined, data: [] };
}
export const GetAccountTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            TxDetailData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetAccountTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(TxDetailData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => TxDetailData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? TxDetailData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetAccountTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetAccountTxsResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => TxDetailData.fromPartial(e)) || [];
        return message;
    },
};
function createBasePaging() {
    return { total: "0", from: 0, to: 0, countBySubaccount: "0", next: [] };
}
export const Paging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== "0") {
            writer.uint32(8).sint64(message.total);
        }
        if (message.from !== 0) {
            writer.uint32(16).sint32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(24).sint32(message.to);
        }
        if (message.countBySubaccount !== "0") {
            writer.uint32(32).sint64(message.countBySubaccount);
        }
        for (const v of message.next) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = longToString(reader.sint64());
                    break;
                case 2:
                    message.from = reader.sint32();
                    break;
                case 3:
                    message.to = reader.sint32();
                    break;
                case 4:
                    message.countBySubaccount = longToString(reader.sint64());
                    break;
                case 5:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? String(object.total) : "0",
            from: isSet(object.from) ? Number(object.from) : 0,
            to: isSet(object.to) ? Number(object.to) : 0,
            countBySubaccount: isSet(object.countBySubaccount) ? String(object.countBySubaccount) : "0",
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = message.total);
        message.from !== undefined && (obj.from = Math.round(message.from));
        message.to !== undefined && (obj.to = Math.round(message.to));
        message.countBySubaccount !== undefined && (obj.countBySubaccount = message.countBySubaccount);
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return Paging.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePaging();
        message.total = object.total ?? "0";
        message.from = object.from ?? 0;
        message.to = object.to ?? 0;
        message.countBySubaccount = object.countBySubaccount ?? "0";
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseTxDetailData() {
    return {
        id: "",
        blockNumber: "0",
        blockTimestamp: "",
        hash: "",
        code: 0,
        data: new Uint8Array(),
        info: "",
        gasWanted: "0",
        gasUsed: "0",
        gasFee: undefined,
        codespace: "",
        events: [],
        txType: "",
        messages: new Uint8Array(),
        signatures: [],
        memo: "",
        txNumber: "0",
        blockUnixTimestamp: "0",
        errorLog: "",
        logs: new Uint8Array(),
        claimIds: [],
    };
}
export const TxDetailData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.blockNumber !== "0") {
            writer.uint32(16).uint64(message.blockNumber);
        }
        if (message.blockTimestamp !== "") {
            writer.uint32(26).string(message.blockTimestamp);
        }
        if (message.hash !== "") {
            writer.uint32(34).string(message.hash);
        }
        if (message.code !== 0) {
            writer.uint32(40).uint32(message.code);
        }
        if (message.data.length !== 0) {
            writer.uint32(50).bytes(message.data);
        }
        if (message.info !== "") {
            writer.uint32(66).string(message.info);
        }
        if (message.gasWanted !== "0") {
            writer.uint32(72).sint64(message.gasWanted);
        }
        if (message.gasUsed !== "0") {
            writer.uint32(80).sint64(message.gasUsed);
        }
        if (message.gasFee !== undefined) {
            GasFee.encode(message.gasFee, writer.uint32(90).fork()).ldelim();
        }
        if (message.codespace !== "") {
            writer.uint32(98).string(message.codespace);
        }
        for (const v of message.events) {
            Event.encode(v, writer.uint32(106).fork()).ldelim();
        }
        if (message.txType !== "") {
            writer.uint32(114).string(message.txType);
        }
        if (message.messages.length !== 0) {
            writer.uint32(122).bytes(message.messages);
        }
        for (const v of message.signatures) {
            Signature.encode(v, writer.uint32(130).fork()).ldelim();
        }
        if (message.memo !== "") {
            writer.uint32(138).string(message.memo);
        }
        if (message.txNumber !== "0") {
            writer.uint32(144).uint64(message.txNumber);
        }
        if (message.blockUnixTimestamp !== "0") {
            writer.uint32(152).uint64(message.blockUnixTimestamp);
        }
        if (message.errorLog !== "") {
            writer.uint32(162).string(message.errorLog);
        }
        if (message.logs.length !== 0) {
            writer.uint32(170).bytes(message.logs);
        }
        writer.uint32(178).fork();
        for (const v of message.claimIds) {
            writer.sint64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTxDetailData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 3:
                    message.blockTimestamp = reader.string();
                    break;
                case 4:
                    message.hash = reader.string();
                    break;
                case 5:
                    message.code = reader.uint32();
                    break;
                case 6:
                    message.data = reader.bytes();
                    break;
                case 8:
                    message.info = reader.string();
                    break;
                case 9:
                    message.gasWanted = longToString(reader.sint64());
                    break;
                case 10:
                    message.gasUsed = longToString(reader.sint64());
                    break;
                case 11:
                    message.gasFee = GasFee.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.codespace = reader.string();
                    break;
                case 13:
                    message.events.push(Event.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.txType = reader.string();
                    break;
                case 15:
                    message.messages = reader.bytes();
                    break;
                case 16:
                    message.signatures.push(Signature.decode(reader, reader.uint32()));
                    break;
                case 17:
                    message.memo = reader.string();
                    break;
                case 18:
                    message.txNumber = longToString(reader.uint64());
                    break;
                case 19:
                    message.blockUnixTimestamp = longToString(reader.uint64());
                    break;
                case 20:
                    message.errorLog = reader.string();
                    break;
                case 21:
                    message.logs = reader.bytes();
                    break;
                case 22:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.claimIds.push(longToString(reader.sint64()));
                        }
                    }
                    else {
                        message.claimIds.push(longToString(reader.sint64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "",
            hash: isSet(object.hash) ? String(object.hash) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            info: isSet(object.info) ? String(object.info) : "",
            gasWanted: isSet(object.gasWanted) ? String(object.gasWanted) : "0",
            gasUsed: isSet(object.gasUsed) ? String(object.gasUsed) : "0",
            gasFee: isSet(object.gasFee) ? GasFee.fromJSON(object.gasFee) : undefined,
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            events: Array.isArray(object?.events) ? object.events.map((e) => Event.fromJSON(e)) : [],
            txType: isSet(object.txType) ? String(object.txType) : "",
            messages: isSet(object.messages) ? bytesFromBase64(object.messages) : new Uint8Array(),
            signatures: Array.isArray(object?.signatures) ? object.signatures.map((e) => Signature.fromJSON(e)) : [],
            memo: isSet(object.memo) ? String(object.memo) : "",
            txNumber: isSet(object.txNumber) ? String(object.txNumber) : "0",
            blockUnixTimestamp: isSet(object.blockUnixTimestamp) ? String(object.blockUnixTimestamp) : "0",
            errorLog: isSet(object.errorLog) ? String(object.errorLog) : "",
            logs: isSet(object.logs) ? bytesFromBase64(object.logs) : new Uint8Array(),
            claimIds: Array.isArray(object?.claimIds) ? object.claimIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        message.hash !== undefined && (obj.hash = message.hash);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.info !== undefined && (obj.info = message.info);
        message.gasWanted !== undefined && (obj.gasWanted = message.gasWanted);
        message.gasUsed !== undefined && (obj.gasUsed = message.gasUsed);
        message.gasFee !== undefined && (obj.gasFee = message.gasFee ? GasFee.toJSON(message.gasFee) : undefined);
        message.codespace !== undefined && (obj.codespace = message.codespace);
        if (message.events) {
            obj.events = message.events.map((e) => e ? Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        message.txType !== undefined && (obj.txType = message.txType);
        message.messages !== undefined &&
            (obj.messages = base64FromBytes(message.messages !== undefined ? message.messages : new Uint8Array()));
        if (message.signatures) {
            obj.signatures = message.signatures.map((e) => e ? Signature.toJSON(e) : undefined);
        }
        else {
            obj.signatures = [];
        }
        message.memo !== undefined && (obj.memo = message.memo);
        message.txNumber !== undefined && (obj.txNumber = message.txNumber);
        message.blockUnixTimestamp !== undefined && (obj.blockUnixTimestamp = message.blockUnixTimestamp);
        message.errorLog !== undefined && (obj.errorLog = message.errorLog);
        message.logs !== undefined &&
            (obj.logs = base64FromBytes(message.logs !== undefined ? message.logs : new Uint8Array()));
        if (message.claimIds) {
            obj.claimIds = message.claimIds.map((e) => e);
        }
        else {
            obj.claimIds = [];
        }
        return obj;
    },
    create(base) {
        return TxDetailData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTxDetailData();
        message.id = object.id ?? "";
        message.blockNumber = object.blockNumber ?? "0";
        message.blockTimestamp = object.blockTimestamp ?? "";
        message.hash = object.hash ?? "";
        message.code = object.code ?? 0;
        message.data = object.data ?? new Uint8Array();
        message.info = object.info ?? "";
        message.gasWanted = object.gasWanted ?? "0";
        message.gasUsed = object.gasUsed ?? "0";
        message.gasFee = (object.gasFee !== undefined && object.gasFee !== null)
            ? GasFee.fromPartial(object.gasFee)
            : undefined;
        message.codespace = object.codespace ?? "";
        message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
        message.txType = object.txType ?? "";
        message.messages = object.messages ?? new Uint8Array();
        message.signatures = object.signatures?.map((e) => Signature.fromPartial(e)) || [];
        message.memo = object.memo ?? "";
        message.txNumber = object.txNumber ?? "0";
        message.blockUnixTimestamp = object.blockUnixTimestamp ?? "0";
        message.errorLog = object.errorLog ?? "";
        message.logs = object.logs ?? new Uint8Array();
        message.claimIds = object.claimIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseGasFee() {
    return { amount: [], gasLimit: "0", payer: "", granter: "" };
}
export const GasFee = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.amount) {
            CosmosCoin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.gasLimit !== "0") {
            writer.uint32(16).uint64(message.gasLimit);
        }
        if (message.payer !== "") {
            writer.uint32(26).string(message.payer);
        }
        if (message.granter !== "") {
            writer.uint32(34).string(message.granter);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGasFee();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.amount.push(CosmosCoin.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.gasLimit = longToString(reader.uint64());
                    break;
                case 3:
                    message.payer = reader.string();
                    break;
                case 4:
                    message.granter = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            amount: Array.isArray(object?.amount) ? object.amount.map((e) => CosmosCoin.fromJSON(e)) : [],
            gasLimit: isSet(object.gasLimit) ? String(object.gasLimit) : "0",
            payer: isSet(object.payer) ? String(object.payer) : "",
            granter: isSet(object.granter) ? String(object.granter) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.amount) {
            obj.amount = message.amount.map((e) => e ? CosmosCoin.toJSON(e) : undefined);
        }
        else {
            obj.amount = [];
        }
        message.gasLimit !== undefined && (obj.gasLimit = message.gasLimit);
        message.payer !== undefined && (obj.payer = message.payer);
        message.granter !== undefined && (obj.granter = message.granter);
        return obj;
    },
    create(base) {
        return GasFee.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGasFee();
        message.amount = object.amount?.map((e) => CosmosCoin.fromPartial(e)) || [];
        message.gasLimit = object.gasLimit ?? "0";
        message.payer = object.payer ?? "";
        message.granter = object.granter ?? "";
        return message;
    },
};
function createBaseCosmosCoin() {
    return { denom: "", amount: "" };
}
export const CosmosCoin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCosmosCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return CosmosCoin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCosmosCoin();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseEvent() {
    return { type: "", attributes: {} };
}
export const Event = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        Object.entries(message.attributes).forEach(([key, value]) => {
            Event_AttributesEntry.encode({ key: key, value }, writer.uint32(18).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    const entry2 = Event_AttributesEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.attributes[entry2.key] = entry2.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
            attributes: isObject(object.attributes)
                ? Object.entries(object.attributes).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        obj.attributes = {};
        if (message.attributes) {
            Object.entries(message.attributes).forEach(([k, v]) => {
                obj.attributes[k] = v;
            });
        }
        return obj;
    },
    create(base) {
        return Event.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEvent();
        message.type = object.type ?? "";
        message.attributes = Object.entries(object.attributes ?? {}).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseEvent_AttributesEntry() {
    return { key: "", value: "" };
}
export const Event_AttributesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEvent_AttributesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    create(base) {
        return Event_AttributesEntry.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEvent_AttributesEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseSignature() {
    return { pubkey: "", address: "", sequence: "0", signature: "" };
}
export const Signature = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.pubkey !== "") {
            writer.uint32(10).string(message.pubkey);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        if (message.signature !== "") {
            writer.uint32(34).string(message.signature);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSignature();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pubkey = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 4:
                    message.signature = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            pubkey: isSet(object.pubkey) ? String(object.pubkey) : "",
            address: isSet(object.address) ? String(object.address) : "",
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            signature: isSet(object.signature) ? String(object.signature) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.pubkey !== undefined && (obj.pubkey = message.pubkey);
        message.address !== undefined && (obj.address = message.address);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.signature !== undefined && (obj.signature = message.signature);
        return obj;
    },
    create(base) {
        return Signature.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSignature();
        message.pubkey = object.pubkey ?? "";
        message.address = object.address ?? "";
        message.sequence = object.sequence ?? "0";
        message.signature = object.signature ?? "";
        return message;
    },
};
function createBaseGetContractTxsRequest() {
    return { address: "", limit: 0, skip: "0", fromNumber: "0", toNumber: "0" };
}
export const GetContractTxsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.limit !== 0) {
            writer.uint32(16).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(24).uint64(message.skip);
        }
        if (message.fromNumber !== "0") {
            writer.uint32(32).sint64(message.fromNumber);
        }
        if (message.toNumber !== "0") {
            writer.uint32(40).sint64(message.toNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetContractTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.limit = reader.sint32();
                    break;
                case 3:
                    message.skip = longToString(reader.uint64());
                    break;
                case 4:
                    message.fromNumber = longToString(reader.sint64());
                    break;
                case 5:
                    message.toNumber = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            fromNumber: isSet(object.fromNumber) ? String(object.fromNumber) : "0",
            toNumber: isSet(object.toNumber) ? String(object.toNumber) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        message.fromNumber !== undefined && (obj.fromNumber = message.fromNumber);
        message.toNumber !== undefined && (obj.toNumber = message.toNumber);
        return obj;
    },
    create(base) {
        return GetContractTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetContractTxsRequest();
        message.address = object.address ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        message.fromNumber = object.fromNumber ?? "0";
        message.toNumber = object.toNumber ?? "0";
        return message;
    },
};
function createBaseGetContractTxsResponse() {
    return { paging: undefined, data: [] };
}
export const GetContractTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            TxDetailData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetContractTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(TxDetailData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => TxDetailData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? TxDetailData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetContractTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetContractTxsResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => TxDetailData.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetContractTxsV2Request() {
    return { address: "", height: "0", from: "0", to: "0", limit: 0, token: "" };
}
export const GetContractTxsV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.height !== "0") {
            writer.uint32(16).uint64(message.height);
        }
        if (message.from !== "0") {
            writer.uint32(24).sint64(message.from);
        }
        if (message.to !== "0") {
            writer.uint32(32).sint64(message.to);
        }
        if (message.limit !== 0) {
            writer.uint32(40).sint32(message.limit);
        }
        if (message.token !== "") {
            writer.uint32(50).string(message.token);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetContractTxsV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.height = longToString(reader.uint64());
                    break;
                case 3:
                    message.from = longToString(reader.sint64());
                    break;
                case 4:
                    message.to = longToString(reader.sint64());
                    break;
                case 5:
                    message.limit = reader.sint32();
                    break;
                case 6:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            height: isSet(object.height) ? String(object.height) : "0",
            from: isSet(object.from) ? String(object.from) : "0",
            to: isSet(object.to) ? String(object.to) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            token: isSet(object.token) ? String(object.token) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.height !== undefined && (obj.height = message.height);
        message.from !== undefined && (obj.from = message.from);
        message.to !== undefined && (obj.to = message.to);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.token !== undefined && (obj.token = message.token);
        return obj;
    },
    create(base) {
        return GetContractTxsV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetContractTxsV2Request();
        message.address = object.address ?? "";
        message.height = object.height ?? "0";
        message.from = object.from ?? "0";
        message.to = object.to ?? "0";
        message.limit = object.limit ?? 0;
        message.token = object.token ?? "";
        return message;
    },
};
function createBaseGetContractTxsV2Response() {
    return { next: [], data: [] };
}
export const GetContractTxsV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.next) {
            writer.uint32(10).string(v);
        }
        for (const v of message.data) {
            TxDetailData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetContractTxsV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.next.push(reader.string());
                    break;
                case 2:
                    message.data.push(TxDetailData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
            data: Array.isArray(object?.data) ? object.data.map((e) => TxDetailData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        if (message.data) {
            obj.data = message.data.map((e) => e ? TxDetailData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetContractTxsV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetContractTxsV2Response();
        message.next = object.next?.map((e) => e) || [];
        message.data = object.data?.map((e) => TxDetailData.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetBlocksRequest() {
    return { before: "0", after: "0", limit: 0, from: "0", to: "0" };
}
export const GetBlocksRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.before !== "0") {
            writer.uint32(8).uint64(message.before);
        }
        if (message.after !== "0") {
            writer.uint32(16).uint64(message.after);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.from !== "0") {
            writer.uint32(32).uint64(message.from);
        }
        if (message.to !== "0") {
            writer.uint32(40).uint64(message.to);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBlocksRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.before = longToString(reader.uint64());
                    break;
                case 2:
                    message.after = longToString(reader.uint64());
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.from = longToString(reader.uint64());
                    break;
                case 5:
                    message.to = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            before: isSet(object.before) ? String(object.before) : "0",
            after: isSet(object.after) ? String(object.after) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            from: isSet(object.from) ? String(object.from) : "0",
            to: isSet(object.to) ? String(object.to) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.before !== undefined && (obj.before = message.before);
        message.after !== undefined && (obj.after = message.after);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.from !== undefined && (obj.from = message.from);
        message.to !== undefined && (obj.to = message.to);
        return obj;
    },
    create(base) {
        return GetBlocksRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBlocksRequest();
        message.before = object.before ?? "0";
        message.after = object.after ?? "0";
        message.limit = object.limit ?? 0;
        message.from = object.from ?? "0";
        message.to = object.to ?? "0";
        return message;
    },
};
function createBaseGetBlocksResponse() {
    return { paging: undefined, data: [] };
}
export const GetBlocksResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            BlockInfo.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBlocksResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(BlockInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => BlockInfo.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? BlockInfo.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetBlocksResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBlocksResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => BlockInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBlockInfo() {
    return {
        height: "0",
        proposer: "",
        moniker: "",
        blockHash: "",
        parentHash: "",
        numPreCommits: "0",
        numTxs: "0",
        txs: [],
        timestamp: "",
    };
}
export const BlockInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).uint64(message.height);
        }
        if (message.proposer !== "") {
            writer.uint32(18).string(message.proposer);
        }
        if (message.moniker !== "") {
            writer.uint32(26).string(message.moniker);
        }
        if (message.blockHash !== "") {
            writer.uint32(34).string(message.blockHash);
        }
        if (message.parentHash !== "") {
            writer.uint32(42).string(message.parentHash);
        }
        if (message.numPreCommits !== "0") {
            writer.uint32(48).sint64(message.numPreCommits);
        }
        if (message.numTxs !== "0") {
            writer.uint32(56).sint64(message.numTxs);
        }
        for (const v of message.txs) {
            TxDataRPC.encode(v, writer.uint32(66).fork()).ldelim();
        }
        if (message.timestamp !== "") {
            writer.uint32(74).string(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.uint64());
                    break;
                case 2:
                    message.proposer = reader.string();
                    break;
                case 3:
                    message.moniker = reader.string();
                    break;
                case 4:
                    message.blockHash = reader.string();
                    break;
                case 5:
                    message.parentHash = reader.string();
                    break;
                case 6:
                    message.numPreCommits = longToString(reader.sint64());
                    break;
                case 7:
                    message.numTxs = longToString(reader.sint64());
                    break;
                case 8:
                    message.txs.push(TxDataRPC.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.timestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            proposer: isSet(object.proposer) ? String(object.proposer) : "",
            moniker: isSet(object.moniker) ? String(object.moniker) : "",
            blockHash: isSet(object.blockHash) ? String(object.blockHash) : "",
            parentHash: isSet(object.parentHash) ? String(object.parentHash) : "",
            numPreCommits: isSet(object.numPreCommits) ? String(object.numPreCommits) : "0",
            numTxs: isSet(object.numTxs) ? String(object.numTxs) : "0",
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => TxDataRPC.fromJSON(e)) : [],
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.proposer !== undefined && (obj.proposer = message.proposer);
        message.moniker !== undefined && (obj.moniker = message.moniker);
        message.blockHash !== undefined && (obj.blockHash = message.blockHash);
        message.parentHash !== undefined && (obj.parentHash = message.parentHash);
        message.numPreCommits !== undefined && (obj.numPreCommits = message.numPreCommits);
        message.numTxs !== undefined && (obj.numTxs = message.numTxs);
        if (message.txs) {
            obj.txs = message.txs.map((e) => e ? TxDataRPC.toJSON(e) : undefined);
        }
        else {
            obj.txs = [];
        }
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return BlockInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockInfo();
        message.height = object.height ?? "0";
        message.proposer = object.proposer ?? "";
        message.moniker = object.moniker ?? "";
        message.blockHash = object.blockHash ?? "";
        message.parentHash = object.parentHash ?? "";
        message.numPreCommits = object.numPreCommits ?? "0";
        message.numTxs = object.numTxs ?? "0";
        message.txs = object.txs?.map((e) => TxDataRPC.fromPartial(e)) || [];
        message.timestamp = object.timestamp ?? "";
        return message;
    },
};
function createBaseTxDataRPC() {
    return {
        id: "",
        blockNumber: "0",
        blockTimestamp: "",
        hash: "",
        codespace: "",
        messages: "",
        txNumber: "0",
        errorLog: "",
        code: 0,
        claimIds: [],
    };
}
export const TxDataRPC = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.blockNumber !== "0") {
            writer.uint32(16).uint64(message.blockNumber);
        }
        if (message.blockTimestamp !== "") {
            writer.uint32(26).string(message.blockTimestamp);
        }
        if (message.hash !== "") {
            writer.uint32(34).string(message.hash);
        }
        if (message.codespace !== "") {
            writer.uint32(42).string(message.codespace);
        }
        if (message.messages !== "") {
            writer.uint32(50).string(message.messages);
        }
        if (message.txNumber !== "0") {
            writer.uint32(56).uint64(message.txNumber);
        }
        if (message.errorLog !== "") {
            writer.uint32(66).string(message.errorLog);
        }
        if (message.code !== 0) {
            writer.uint32(72).uint32(message.code);
        }
        writer.uint32(82).fork();
        for (const v of message.claimIds) {
            writer.sint64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTxDataRPC();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 3:
                    message.blockTimestamp = reader.string();
                    break;
                case 4:
                    message.hash = reader.string();
                    break;
                case 5:
                    message.codespace = reader.string();
                    break;
                case 6:
                    message.messages = reader.string();
                    break;
                case 7:
                    message.txNumber = longToString(reader.uint64());
                    break;
                case 8:
                    message.errorLog = reader.string();
                    break;
                case 9:
                    message.code = reader.uint32();
                    break;
                case 10:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.claimIds.push(longToString(reader.sint64()));
                        }
                    }
                    else {
                        message.claimIds.push(longToString(reader.sint64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "",
            hash: isSet(object.hash) ? String(object.hash) : "",
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            messages: isSet(object.messages) ? String(object.messages) : "",
            txNumber: isSet(object.txNumber) ? String(object.txNumber) : "0",
            errorLog: isSet(object.errorLog) ? String(object.errorLog) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            claimIds: Array.isArray(object?.claimIds) ? object.claimIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        message.hash !== undefined && (obj.hash = message.hash);
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.messages !== undefined && (obj.messages = message.messages);
        message.txNumber !== undefined && (obj.txNumber = message.txNumber);
        message.errorLog !== undefined && (obj.errorLog = message.errorLog);
        message.code !== undefined && (obj.code = Math.round(message.code));
        if (message.claimIds) {
            obj.claimIds = message.claimIds.map((e) => e);
        }
        else {
            obj.claimIds = [];
        }
        return obj;
    },
    create(base) {
        return TxDataRPC.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTxDataRPC();
        message.id = object.id ?? "";
        message.blockNumber = object.blockNumber ?? "0";
        message.blockTimestamp = object.blockTimestamp ?? "";
        message.hash = object.hash ?? "";
        message.codespace = object.codespace ?? "";
        message.messages = object.messages ?? "";
        message.txNumber = object.txNumber ?? "0";
        message.errorLog = object.errorLog ?? "";
        message.code = object.code ?? 0;
        message.claimIds = object.claimIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseGetBlockRequest() {
    return { id: "" };
}
export const GetBlockRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBlockRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { id: isSet(object.id) ? String(object.id) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        return obj;
    },
    create(base) {
        return GetBlockRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBlockRequest();
        message.id = object.id ?? "";
        return message;
    },
};
function createBaseGetBlockResponse() {
    return { s: "", errmsg: "", data: undefined };
}
export const GetBlockResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.s !== "") {
            writer.uint32(10).string(message.s);
        }
        if (message.errmsg !== "") {
            writer.uint32(18).string(message.errmsg);
        }
        if (message.data !== undefined) {
            BlockDetailInfo.encode(message.data, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBlockResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.s = reader.string();
                    break;
                case 2:
                    message.errmsg = reader.string();
                    break;
                case 3:
                    message.data = BlockDetailInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            s: isSet(object.s) ? String(object.s) : "",
            errmsg: isSet(object.errmsg) ? String(object.errmsg) : "",
            data: isSet(object.data) ? BlockDetailInfo.fromJSON(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.s !== undefined && (obj.s = message.s);
        message.errmsg !== undefined && (obj.errmsg = message.errmsg);
        message.data !== undefined && (obj.data = message.data ? BlockDetailInfo.toJSON(message.data) : undefined);
        return obj;
    },
    create(base) {
        return GetBlockResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBlockResponse();
        message.s = object.s ?? "";
        message.errmsg = object.errmsg ?? "";
        message.data = (object.data !== undefined && object.data !== null)
            ? BlockDetailInfo.fromPartial(object.data)
            : undefined;
        return message;
    },
};
function createBaseBlockDetailInfo() {
    return {
        height: "0",
        proposer: "",
        moniker: "",
        blockHash: "",
        parentHash: "",
        numPreCommits: "0",
        numTxs: "0",
        totalTxs: "0",
        txs: [],
        timestamp: "",
    };
}
export const BlockDetailInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).uint64(message.height);
        }
        if (message.proposer !== "") {
            writer.uint32(18).string(message.proposer);
        }
        if (message.moniker !== "") {
            writer.uint32(26).string(message.moniker);
        }
        if (message.blockHash !== "") {
            writer.uint32(34).string(message.blockHash);
        }
        if (message.parentHash !== "") {
            writer.uint32(42).string(message.parentHash);
        }
        if (message.numPreCommits !== "0") {
            writer.uint32(48).sint64(message.numPreCommits);
        }
        if (message.numTxs !== "0") {
            writer.uint32(56).sint64(message.numTxs);
        }
        if (message.totalTxs !== "0") {
            writer.uint32(64).sint64(message.totalTxs);
        }
        for (const v of message.txs) {
            TxData.encode(v, writer.uint32(74).fork()).ldelim();
        }
        if (message.timestamp !== "") {
            writer.uint32(82).string(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockDetailInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.uint64());
                    break;
                case 2:
                    message.proposer = reader.string();
                    break;
                case 3:
                    message.moniker = reader.string();
                    break;
                case 4:
                    message.blockHash = reader.string();
                    break;
                case 5:
                    message.parentHash = reader.string();
                    break;
                case 6:
                    message.numPreCommits = longToString(reader.sint64());
                    break;
                case 7:
                    message.numTxs = longToString(reader.sint64());
                    break;
                case 8:
                    message.totalTxs = longToString(reader.sint64());
                    break;
                case 9:
                    message.txs.push(TxData.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.timestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            proposer: isSet(object.proposer) ? String(object.proposer) : "",
            moniker: isSet(object.moniker) ? String(object.moniker) : "",
            blockHash: isSet(object.blockHash) ? String(object.blockHash) : "",
            parentHash: isSet(object.parentHash) ? String(object.parentHash) : "",
            numPreCommits: isSet(object.numPreCommits) ? String(object.numPreCommits) : "0",
            numTxs: isSet(object.numTxs) ? String(object.numTxs) : "0",
            totalTxs: isSet(object.totalTxs) ? String(object.totalTxs) : "0",
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => TxData.fromJSON(e)) : [],
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.proposer !== undefined && (obj.proposer = message.proposer);
        message.moniker !== undefined && (obj.moniker = message.moniker);
        message.blockHash !== undefined && (obj.blockHash = message.blockHash);
        message.parentHash !== undefined && (obj.parentHash = message.parentHash);
        message.numPreCommits !== undefined && (obj.numPreCommits = message.numPreCommits);
        message.numTxs !== undefined && (obj.numTxs = message.numTxs);
        message.totalTxs !== undefined && (obj.totalTxs = message.totalTxs);
        if (message.txs) {
            obj.txs = message.txs.map((e) => e ? TxData.toJSON(e) : undefined);
        }
        else {
            obj.txs = [];
        }
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return BlockDetailInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockDetailInfo();
        message.height = object.height ?? "0";
        message.proposer = object.proposer ?? "";
        message.moniker = object.moniker ?? "";
        message.blockHash = object.blockHash ?? "";
        message.parentHash = object.parentHash ?? "";
        message.numPreCommits = object.numPreCommits ?? "0";
        message.numTxs = object.numTxs ?? "0";
        message.totalTxs = object.totalTxs ?? "0";
        message.txs = object.txs?.map((e) => TxData.fromPartial(e)) || [];
        message.timestamp = object.timestamp ?? "";
        return message;
    },
};
function createBaseTxData() {
    return {
        id: "",
        blockNumber: "0",
        blockTimestamp: "",
        hash: "",
        codespace: "",
        messages: new Uint8Array(),
        txNumber: "0",
        errorLog: "",
        code: 0,
        txMsgTypes: new Uint8Array(),
        logs: new Uint8Array(),
        claimIds: [],
        signatures: [],
    };
}
export const TxData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.blockNumber !== "0") {
            writer.uint32(16).uint64(message.blockNumber);
        }
        if (message.blockTimestamp !== "") {
            writer.uint32(26).string(message.blockTimestamp);
        }
        if (message.hash !== "") {
            writer.uint32(34).string(message.hash);
        }
        if (message.codespace !== "") {
            writer.uint32(42).string(message.codespace);
        }
        if (message.messages.length !== 0) {
            writer.uint32(50).bytes(message.messages);
        }
        if (message.txNumber !== "0") {
            writer.uint32(56).uint64(message.txNumber);
        }
        if (message.errorLog !== "") {
            writer.uint32(66).string(message.errorLog);
        }
        if (message.code !== 0) {
            writer.uint32(72).uint32(message.code);
        }
        if (message.txMsgTypes.length !== 0) {
            writer.uint32(82).bytes(message.txMsgTypes);
        }
        if (message.logs.length !== 0) {
            writer.uint32(90).bytes(message.logs);
        }
        writer.uint32(98).fork();
        for (const v of message.claimIds) {
            writer.sint64(v);
        }
        writer.ldelim();
        for (const v of message.signatures) {
            Signature.encode(v, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTxData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 3:
                    message.blockTimestamp = reader.string();
                    break;
                case 4:
                    message.hash = reader.string();
                    break;
                case 5:
                    message.codespace = reader.string();
                    break;
                case 6:
                    message.messages = reader.bytes();
                    break;
                case 7:
                    message.txNumber = longToString(reader.uint64());
                    break;
                case 8:
                    message.errorLog = reader.string();
                    break;
                case 9:
                    message.code = reader.uint32();
                    break;
                case 10:
                    message.txMsgTypes = reader.bytes();
                    break;
                case 11:
                    message.logs = reader.bytes();
                    break;
                case 12:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.claimIds.push(longToString(reader.sint64()));
                        }
                    }
                    else {
                        message.claimIds.push(longToString(reader.sint64()));
                    }
                    break;
                case 13:
                    message.signatures.push(Signature.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "",
            hash: isSet(object.hash) ? String(object.hash) : "",
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            messages: isSet(object.messages) ? bytesFromBase64(object.messages) : new Uint8Array(),
            txNumber: isSet(object.txNumber) ? String(object.txNumber) : "0",
            errorLog: isSet(object.errorLog) ? String(object.errorLog) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            txMsgTypes: isSet(object.txMsgTypes) ? bytesFromBase64(object.txMsgTypes) : new Uint8Array(),
            logs: isSet(object.logs) ? bytesFromBase64(object.logs) : new Uint8Array(),
            claimIds: Array.isArray(object?.claimIds) ? object.claimIds.map((e) => String(e)) : [],
            signatures: Array.isArray(object?.signatures) ? object.signatures.map((e) => Signature.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        message.hash !== undefined && (obj.hash = message.hash);
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.messages !== undefined &&
            (obj.messages = base64FromBytes(message.messages !== undefined ? message.messages : new Uint8Array()));
        message.txNumber !== undefined && (obj.txNumber = message.txNumber);
        message.errorLog !== undefined && (obj.errorLog = message.errorLog);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.txMsgTypes !== undefined &&
            (obj.txMsgTypes = base64FromBytes(message.txMsgTypes !== undefined ? message.txMsgTypes : new Uint8Array()));
        message.logs !== undefined &&
            (obj.logs = base64FromBytes(message.logs !== undefined ? message.logs : new Uint8Array()));
        if (message.claimIds) {
            obj.claimIds = message.claimIds.map((e) => e);
        }
        else {
            obj.claimIds = [];
        }
        if (message.signatures) {
            obj.signatures = message.signatures.map((e) => e ? Signature.toJSON(e) : undefined);
        }
        else {
            obj.signatures = [];
        }
        return obj;
    },
    create(base) {
        return TxData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTxData();
        message.id = object.id ?? "";
        message.blockNumber = object.blockNumber ?? "0";
        message.blockTimestamp = object.blockTimestamp ?? "";
        message.hash = object.hash ?? "";
        message.codespace = object.codespace ?? "";
        message.messages = object.messages ?? new Uint8Array();
        message.txNumber = object.txNumber ?? "0";
        message.errorLog = object.errorLog ?? "";
        message.code = object.code ?? 0;
        message.txMsgTypes = object.txMsgTypes ?? new Uint8Array();
        message.logs = object.logs ?? new Uint8Array();
        message.claimIds = object.claimIds?.map((e) => e) || [];
        message.signatures = object.signatures?.map((e) => Signature.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetValidatorsRequest() {
    return {};
}
export const GetValidatorsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetValidatorsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return GetValidatorsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseGetValidatorsRequest();
        return message;
    },
};
function createBaseGetValidatorsResponse() {
    return { s: "", errmsg: "", data: [] };
}
export const GetValidatorsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.s !== "") {
            writer.uint32(10).string(message.s);
        }
        if (message.errmsg !== "") {
            writer.uint32(18).string(message.errmsg);
        }
        for (const v of message.data) {
            Validator.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetValidatorsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.s = reader.string();
                    break;
                case 2:
                    message.errmsg = reader.string();
                    break;
                case 3:
                    message.data.push(Validator.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            s: isSet(object.s) ? String(object.s) : "",
            errmsg: isSet(object.errmsg) ? String(object.errmsg) : "",
            data: Array.isArray(object?.data) ? object.data.map((e) => Validator.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.s !== undefined && (obj.s = message.s);
        message.errmsg !== undefined && (obj.errmsg = message.errmsg);
        if (message.data) {
            obj.data = message.data.map((e) => e ? Validator.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetValidatorsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetValidatorsResponse();
        message.s = object.s ?? "";
        message.errmsg = object.errmsg ?? "";
        message.data = object.data?.map((e) => Validator.fromPartial(e)) || [];
        return message;
    },
};
function createBaseValidator() {
    return {
        id: "",
        moniker: "",
        operatorAddress: "",
        consensusAddress: "",
        jailed: false,
        status: 0,
        tokens: "",
        delegatorShares: "",
        description: undefined,
        unbondingHeight: "0",
        unbondingTime: "",
        commissionRate: "",
        commissionMaxRate: "",
        commissionMaxChangeRate: "",
        commissionUpdateTime: "",
        proposed: "0",
        signed: "0",
        missed: "0",
        timestamp: "",
        uptimes: [],
        slashingEvents: [],
        uptimePercentage: 0,
        imageUrl: "",
    };
}
export const Validator = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.moniker !== "") {
            writer.uint32(18).string(message.moniker);
        }
        if (message.operatorAddress !== "") {
            writer.uint32(26).string(message.operatorAddress);
        }
        if (message.consensusAddress !== "") {
            writer.uint32(34).string(message.consensusAddress);
        }
        if (message.jailed === true) {
            writer.uint32(40).bool(message.jailed);
        }
        if (message.status !== 0) {
            writer.uint32(48).sint32(message.status);
        }
        if (message.tokens !== "") {
            writer.uint32(58).string(message.tokens);
        }
        if (message.delegatorShares !== "") {
            writer.uint32(66).string(message.delegatorShares);
        }
        if (message.description !== undefined) {
            ValidatorDescription.encode(message.description, writer.uint32(74).fork()).ldelim();
        }
        if (message.unbondingHeight !== "0") {
            writer.uint32(80).sint64(message.unbondingHeight);
        }
        if (message.unbondingTime !== "") {
            writer.uint32(90).string(message.unbondingTime);
        }
        if (message.commissionRate !== "") {
            writer.uint32(98).string(message.commissionRate);
        }
        if (message.commissionMaxRate !== "") {
            writer.uint32(106).string(message.commissionMaxRate);
        }
        if (message.commissionMaxChangeRate !== "") {
            writer.uint32(114).string(message.commissionMaxChangeRate);
        }
        if (message.commissionUpdateTime !== "") {
            writer.uint32(122).string(message.commissionUpdateTime);
        }
        if (message.proposed !== "0") {
            writer.uint32(128).uint64(message.proposed);
        }
        if (message.signed !== "0") {
            writer.uint32(136).uint64(message.signed);
        }
        if (message.missed !== "0") {
            writer.uint32(144).uint64(message.missed);
        }
        if (message.timestamp !== "") {
            writer.uint32(154).string(message.timestamp);
        }
        for (const v of message.uptimes) {
            ValidatorUptime.encode(v, writer.uint32(162).fork()).ldelim();
        }
        for (const v of message.slashingEvents) {
            SlashingEvent.encode(v, writer.uint32(170).fork()).ldelim();
        }
        if (message.uptimePercentage !== 0) {
            writer.uint32(177).double(message.uptimePercentage);
        }
        if (message.imageUrl !== "") {
            writer.uint32(186).string(message.imageUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.moniker = reader.string();
                    break;
                case 3:
                    message.operatorAddress = reader.string();
                    break;
                case 4:
                    message.consensusAddress = reader.string();
                    break;
                case 5:
                    message.jailed = reader.bool();
                    break;
                case 6:
                    message.status = reader.sint32();
                    break;
                case 7:
                    message.tokens = reader.string();
                    break;
                case 8:
                    message.delegatorShares = reader.string();
                    break;
                case 9:
                    message.description = ValidatorDescription.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.unbondingHeight = longToString(reader.sint64());
                    break;
                case 11:
                    message.unbondingTime = reader.string();
                    break;
                case 12:
                    message.commissionRate = reader.string();
                    break;
                case 13:
                    message.commissionMaxRate = reader.string();
                    break;
                case 14:
                    message.commissionMaxChangeRate = reader.string();
                    break;
                case 15:
                    message.commissionUpdateTime = reader.string();
                    break;
                case 16:
                    message.proposed = longToString(reader.uint64());
                    break;
                case 17:
                    message.signed = longToString(reader.uint64());
                    break;
                case 18:
                    message.missed = longToString(reader.uint64());
                    break;
                case 19:
                    message.timestamp = reader.string();
                    break;
                case 20:
                    message.uptimes.push(ValidatorUptime.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.slashingEvents.push(SlashingEvent.decode(reader, reader.uint32()));
                    break;
                case 22:
                    message.uptimePercentage = reader.double();
                    break;
                case 23:
                    message.imageUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            moniker: isSet(object.moniker) ? String(object.moniker) : "",
            operatorAddress: isSet(object.operatorAddress) ? String(object.operatorAddress) : "",
            consensusAddress: isSet(object.consensusAddress) ? String(object.consensusAddress) : "",
            jailed: isSet(object.jailed) ? Boolean(object.jailed) : false,
            status: isSet(object.status) ? Number(object.status) : 0,
            tokens: isSet(object.tokens) ? String(object.tokens) : "",
            delegatorShares: isSet(object.delegatorShares) ? String(object.delegatorShares) : "",
            description: isSet(object.description) ? ValidatorDescription.fromJSON(object.description) : undefined,
            unbondingHeight: isSet(object.unbondingHeight) ? String(object.unbondingHeight) : "0",
            unbondingTime: isSet(object.unbondingTime) ? String(object.unbondingTime) : "",
            commissionRate: isSet(object.commissionRate) ? String(object.commissionRate) : "",
            commissionMaxRate: isSet(object.commissionMaxRate) ? String(object.commissionMaxRate) : "",
            commissionMaxChangeRate: isSet(object.commissionMaxChangeRate) ? String(object.commissionMaxChangeRate) : "",
            commissionUpdateTime: isSet(object.commissionUpdateTime) ? String(object.commissionUpdateTime) : "",
            proposed: isSet(object.proposed) ? String(object.proposed) : "0",
            signed: isSet(object.signed) ? String(object.signed) : "0",
            missed: isSet(object.missed) ? String(object.missed) : "0",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
            uptimes: Array.isArray(object?.uptimes) ? object.uptimes.map((e) => ValidatorUptime.fromJSON(e)) : [],
            slashingEvents: Array.isArray(object?.slashingEvents)
                ? object.slashingEvents.map((e) => SlashingEvent.fromJSON(e))
                : [],
            uptimePercentage: isSet(object.uptimePercentage) ? Number(object.uptimePercentage) : 0,
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.moniker !== undefined && (obj.moniker = message.moniker);
        message.operatorAddress !== undefined && (obj.operatorAddress = message.operatorAddress);
        message.consensusAddress !== undefined && (obj.consensusAddress = message.consensusAddress);
        message.jailed !== undefined && (obj.jailed = message.jailed);
        message.status !== undefined && (obj.status = Math.round(message.status));
        message.tokens !== undefined && (obj.tokens = message.tokens);
        message.delegatorShares !== undefined && (obj.delegatorShares = message.delegatorShares);
        message.description !== undefined &&
            (obj.description = message.description ? ValidatorDescription.toJSON(message.description) : undefined);
        message.unbondingHeight !== undefined && (obj.unbondingHeight = message.unbondingHeight);
        message.unbondingTime !== undefined && (obj.unbondingTime = message.unbondingTime);
        message.commissionRate !== undefined && (obj.commissionRate = message.commissionRate);
        message.commissionMaxRate !== undefined && (obj.commissionMaxRate = message.commissionMaxRate);
        message.commissionMaxChangeRate !== undefined && (obj.commissionMaxChangeRate = message.commissionMaxChangeRate);
        message.commissionUpdateTime !== undefined && (obj.commissionUpdateTime = message.commissionUpdateTime);
        message.proposed !== undefined && (obj.proposed = message.proposed);
        message.signed !== undefined && (obj.signed = message.signed);
        message.missed !== undefined && (obj.missed = message.missed);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        if (message.uptimes) {
            obj.uptimes = message.uptimes.map((e) => e ? ValidatorUptime.toJSON(e) : undefined);
        }
        else {
            obj.uptimes = [];
        }
        if (message.slashingEvents) {
            obj.slashingEvents = message.slashingEvents.map((e) => e ? SlashingEvent.toJSON(e) : undefined);
        }
        else {
            obj.slashingEvents = [];
        }
        message.uptimePercentage !== undefined && (obj.uptimePercentage = message.uptimePercentage);
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        return obj;
    },
    create(base) {
        return Validator.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidator();
        message.id = object.id ?? "";
        message.moniker = object.moniker ?? "";
        message.operatorAddress = object.operatorAddress ?? "";
        message.consensusAddress = object.consensusAddress ?? "";
        message.jailed = object.jailed ?? false;
        message.status = object.status ?? 0;
        message.tokens = object.tokens ?? "";
        message.delegatorShares = object.delegatorShares ?? "";
        message.description = (object.description !== undefined && object.description !== null)
            ? ValidatorDescription.fromPartial(object.description)
            : undefined;
        message.unbondingHeight = object.unbondingHeight ?? "0";
        message.unbondingTime = object.unbondingTime ?? "";
        message.commissionRate = object.commissionRate ?? "";
        message.commissionMaxRate = object.commissionMaxRate ?? "";
        message.commissionMaxChangeRate = object.commissionMaxChangeRate ?? "";
        message.commissionUpdateTime = object.commissionUpdateTime ?? "";
        message.proposed = object.proposed ?? "0";
        message.signed = object.signed ?? "0";
        message.missed = object.missed ?? "0";
        message.timestamp = object.timestamp ?? "";
        message.uptimes = object.uptimes?.map((e) => ValidatorUptime.fromPartial(e)) || [];
        message.slashingEvents = object.slashingEvents?.map((e) => SlashingEvent.fromPartial(e)) || [];
        message.uptimePercentage = object.uptimePercentage ?? 0;
        message.imageUrl = object.imageUrl ?? "";
        return message;
    },
};
function createBaseValidatorDescription() {
    return { moniker: "", identity: "", website: "", securityContact: "", details: "", imageUrl: "" };
}
export const ValidatorDescription = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.moniker !== "") {
            writer.uint32(10).string(message.moniker);
        }
        if (message.identity !== "") {
            writer.uint32(18).string(message.identity);
        }
        if (message.website !== "") {
            writer.uint32(26).string(message.website);
        }
        if (message.securityContact !== "") {
            writer.uint32(34).string(message.securityContact);
        }
        if (message.details !== "") {
            writer.uint32(42).string(message.details);
        }
        if (message.imageUrl !== "") {
            writer.uint32(50).string(message.imageUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidatorDescription();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.moniker = reader.string();
                    break;
                case 2:
                    message.identity = reader.string();
                    break;
                case 3:
                    message.website = reader.string();
                    break;
                case 4:
                    message.securityContact = reader.string();
                    break;
                case 5:
                    message.details = reader.string();
                    break;
                case 6:
                    message.imageUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            moniker: isSet(object.moniker) ? String(object.moniker) : "",
            identity: isSet(object.identity) ? String(object.identity) : "",
            website: isSet(object.website) ? String(object.website) : "",
            securityContact: isSet(object.securityContact) ? String(object.securityContact) : "",
            details: isSet(object.details) ? String(object.details) : "",
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.moniker !== undefined && (obj.moniker = message.moniker);
        message.identity !== undefined && (obj.identity = message.identity);
        message.website !== undefined && (obj.website = message.website);
        message.securityContact !== undefined && (obj.securityContact = message.securityContact);
        message.details !== undefined && (obj.details = message.details);
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        return obj;
    },
    create(base) {
        return ValidatorDescription.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidatorDescription();
        message.moniker = object.moniker ?? "";
        message.identity = object.identity ?? "";
        message.website = object.website ?? "";
        message.securityContact = object.securityContact ?? "";
        message.details = object.details ?? "";
        message.imageUrl = object.imageUrl ?? "";
        return message;
    },
};
function createBaseValidatorUptime() {
    return { blockNumber: "0", status: "" };
}
export const ValidatorUptime = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.blockNumber !== "0") {
            writer.uint32(8).uint64(message.blockNumber);
        }
        if (message.status !== "") {
            writer.uint32(18).string(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidatorUptime();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            status: isSet(object.status) ? String(object.status) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.status !== undefined && (obj.status = message.status);
        return obj;
    },
    create(base) {
        return ValidatorUptime.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidatorUptime();
        message.blockNumber = object.blockNumber ?? "0";
        message.status = object.status ?? "";
        return message;
    },
};
function createBaseSlashingEvent() {
    return { blockNumber: "0", blockTimestamp: "", address: "", power: "0", reason: "", jailed: "", missedBlocks: "0" };
}
export const SlashingEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.blockNumber !== "0") {
            writer.uint32(8).uint64(message.blockNumber);
        }
        if (message.blockTimestamp !== "") {
            writer.uint32(18).string(message.blockTimestamp);
        }
        if (message.address !== "") {
            writer.uint32(26).string(message.address);
        }
        if (message.power !== "0") {
            writer.uint32(32).uint64(message.power);
        }
        if (message.reason !== "") {
            writer.uint32(42).string(message.reason);
        }
        if (message.jailed !== "") {
            writer.uint32(50).string(message.jailed);
        }
        if (message.missedBlocks !== "0") {
            writer.uint32(56).uint64(message.missedBlocks);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSlashingEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 2:
                    message.blockTimestamp = reader.string();
                    break;
                case 3:
                    message.address = reader.string();
                    break;
                case 4:
                    message.power = longToString(reader.uint64());
                    break;
                case 5:
                    message.reason = reader.string();
                    break;
                case 6:
                    message.jailed = reader.string();
                    break;
                case 7:
                    message.missedBlocks = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "",
            address: isSet(object.address) ? String(object.address) : "",
            power: isSet(object.power) ? String(object.power) : "0",
            reason: isSet(object.reason) ? String(object.reason) : "",
            jailed: isSet(object.jailed) ? String(object.jailed) : "",
            missedBlocks: isSet(object.missedBlocks) ? String(object.missedBlocks) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        message.address !== undefined && (obj.address = message.address);
        message.power !== undefined && (obj.power = message.power);
        message.reason !== undefined && (obj.reason = message.reason);
        message.jailed !== undefined && (obj.jailed = message.jailed);
        message.missedBlocks !== undefined && (obj.missedBlocks = message.missedBlocks);
        return obj;
    },
    create(base) {
        return SlashingEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSlashingEvent();
        message.blockNumber = object.blockNumber ?? "0";
        message.blockTimestamp = object.blockTimestamp ?? "";
        message.address = object.address ?? "";
        message.power = object.power ?? "0";
        message.reason = object.reason ?? "";
        message.jailed = object.jailed ?? "";
        message.missedBlocks = object.missedBlocks ?? "0";
        return message;
    },
};
function createBaseGetValidatorRequest() {
    return { address: "" };
}
export const GetValidatorRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetValidatorRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { address: isSet(object.address) ? String(object.address) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return GetValidatorRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetValidatorRequest();
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseGetValidatorResponse() {
    return { s: "", errmsg: "", data: undefined };
}
export const GetValidatorResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.s !== "") {
            writer.uint32(10).string(message.s);
        }
        if (message.errmsg !== "") {
            writer.uint32(18).string(message.errmsg);
        }
        if (message.data !== undefined) {
            Validator.encode(message.data, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetValidatorResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.s = reader.string();
                    break;
                case 2:
                    message.errmsg = reader.string();
                    break;
                case 3:
                    message.data = Validator.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            s: isSet(object.s) ? String(object.s) : "",
            errmsg: isSet(object.errmsg) ? String(object.errmsg) : "",
            data: isSet(object.data) ? Validator.fromJSON(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.s !== undefined && (obj.s = message.s);
        message.errmsg !== undefined && (obj.errmsg = message.errmsg);
        message.data !== undefined && (obj.data = message.data ? Validator.toJSON(message.data) : undefined);
        return obj;
    },
    create(base) {
        return GetValidatorResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetValidatorResponse();
        message.s = object.s ?? "";
        message.errmsg = object.errmsg ?? "";
        message.data = (object.data !== undefined && object.data !== null) ? Validator.fromPartial(object.data) : undefined;
        return message;
    },
};
function createBaseGetValidatorUptimeRequest() {
    return { address: "" };
}
export const GetValidatorUptimeRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetValidatorUptimeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { address: isSet(object.address) ? String(object.address) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return GetValidatorUptimeRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetValidatorUptimeRequest();
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseGetValidatorUptimeResponse() {
    return { s: "", errmsg: "", data: [] };
}
export const GetValidatorUptimeResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.s !== "") {
            writer.uint32(10).string(message.s);
        }
        if (message.errmsg !== "") {
            writer.uint32(18).string(message.errmsg);
        }
        for (const v of message.data) {
            ValidatorUptime.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetValidatorUptimeResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.s = reader.string();
                    break;
                case 2:
                    message.errmsg = reader.string();
                    break;
                case 3:
                    message.data.push(ValidatorUptime.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            s: isSet(object.s) ? String(object.s) : "",
            errmsg: isSet(object.errmsg) ? String(object.errmsg) : "",
            data: Array.isArray(object?.data) ? object.data.map((e) => ValidatorUptime.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.s !== undefined && (obj.s = message.s);
        message.errmsg !== undefined && (obj.errmsg = message.errmsg);
        if (message.data) {
            obj.data = message.data.map((e) => e ? ValidatorUptime.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetValidatorUptimeResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetValidatorUptimeResponse();
        message.s = object.s ?? "";
        message.errmsg = object.errmsg ?? "";
        message.data = object.data?.map((e) => ValidatorUptime.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetTxsRequest() {
    return {
        before: "0",
        after: "0",
        limit: 0,
        skip: "0",
        type: "",
        module: "",
        fromNumber: "0",
        toNumber: "0",
        startTime: "0",
        endTime: "0",
        status: "",
    };
}
export const GetTxsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.before !== "0") {
            writer.uint32(8).uint64(message.before);
        }
        if (message.after !== "0") {
            writer.uint32(16).uint64(message.after);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        if (message.type !== "") {
            writer.uint32(42).string(message.type);
        }
        if (message.module !== "") {
            writer.uint32(50).string(message.module);
        }
        if (message.fromNumber !== "0") {
            writer.uint32(56).sint64(message.fromNumber);
        }
        if (message.toNumber !== "0") {
            writer.uint32(64).sint64(message.toNumber);
        }
        if (message.startTime !== "0") {
            writer.uint32(72).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(80).sint64(message.endTime);
        }
        if (message.status !== "") {
            writer.uint32(90).string(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.before = longToString(reader.uint64());
                    break;
                case 2:
                    message.after = longToString(reader.uint64());
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                case 5:
                    message.type = reader.string();
                    break;
                case 6:
                    message.module = reader.string();
                    break;
                case 7:
                    message.fromNumber = longToString(reader.sint64());
                    break;
                case 8:
                    message.toNumber = longToString(reader.sint64());
                    break;
                case 9:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 10:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 11:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            before: isSet(object.before) ? String(object.before) : "0",
            after: isSet(object.after) ? String(object.after) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            type: isSet(object.type) ? String(object.type) : "",
            module: isSet(object.module) ? String(object.module) : "",
            fromNumber: isSet(object.fromNumber) ? String(object.fromNumber) : "0",
            toNumber: isSet(object.toNumber) ? String(object.toNumber) : "0",
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            status: isSet(object.status) ? String(object.status) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.before !== undefined && (obj.before = message.before);
        message.after !== undefined && (obj.after = message.after);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        message.type !== undefined && (obj.type = message.type);
        message.module !== undefined && (obj.module = message.module);
        message.fromNumber !== undefined && (obj.fromNumber = message.fromNumber);
        message.toNumber !== undefined && (obj.toNumber = message.toNumber);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        message.status !== undefined && (obj.status = message.status);
        return obj;
    },
    create(base) {
        return GetTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetTxsRequest();
        message.before = object.before ?? "0";
        message.after = object.after ?? "0";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        message.type = object.type ?? "";
        message.module = object.module ?? "";
        message.fromNumber = object.fromNumber ?? "0";
        message.toNumber = object.toNumber ?? "0";
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.status = object.status ?? "";
        return message;
    },
};
function createBaseGetTxsResponse() {
    return { paging: undefined, data: [] };
}
export const GetTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            TxData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(TxData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => TxData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? TxData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetTxsResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => TxData.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetTxByTxHashRequest() {
    return { hash: "" };
}
export const GetTxByTxHashRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.hash !== "") {
            writer.uint32(10).string(message.hash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTxByTxHashRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { hash: isSet(object.hash) ? String(object.hash) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.hash !== undefined && (obj.hash = message.hash);
        return obj;
    },
    create(base) {
        return GetTxByTxHashRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetTxByTxHashRequest();
        message.hash = object.hash ?? "";
        return message;
    },
};
function createBaseGetTxByTxHashResponse() {
    return { s: "", errmsg: "", data: undefined };
}
export const GetTxByTxHashResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.s !== "") {
            writer.uint32(10).string(message.s);
        }
        if (message.errmsg !== "") {
            writer.uint32(18).string(message.errmsg);
        }
        if (message.data !== undefined) {
            TxDetailData.encode(message.data, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTxByTxHashResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.s = reader.string();
                    break;
                case 2:
                    message.errmsg = reader.string();
                    break;
                case 3:
                    message.data = TxDetailData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            s: isSet(object.s) ? String(object.s) : "",
            errmsg: isSet(object.errmsg) ? String(object.errmsg) : "",
            data: isSet(object.data) ? TxDetailData.fromJSON(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.s !== undefined && (obj.s = message.s);
        message.errmsg !== undefined && (obj.errmsg = message.errmsg);
        message.data !== undefined && (obj.data = message.data ? TxDetailData.toJSON(message.data) : undefined);
        return obj;
    },
    create(base) {
        return GetTxByTxHashResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetTxByTxHashResponse();
        message.s = object.s ?? "";
        message.errmsg = object.errmsg ?? "";
        message.data = (object.data !== undefined && object.data !== null)
            ? TxDetailData.fromPartial(object.data)
            : undefined;
        return message;
    },
};
function createBaseGetPeggyDepositTxsRequest() {
    return { sender: "", receiver: "", limit: 0, skip: "0" };
}
export const GetPeggyDepositTxsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(18).string(message.receiver);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPeggyDepositTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.receiver = reader.string();
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        return obj;
    },
    create(base) {
        return GetPeggyDepositTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetPeggyDepositTxsRequest();
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        return message;
    },
};
function createBaseGetPeggyDepositTxsResponse() {
    return { field: [] };
}
export const GetPeggyDepositTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.field) {
            PeggyDepositTx.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPeggyDepositTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.field.push(PeggyDepositTx.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { field: Array.isArray(object?.field) ? object.field.map((e) => PeggyDepositTx.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.field) {
            obj.field = message.field.map((e) => e ? PeggyDepositTx.toJSON(e) : undefined);
        }
        else {
            obj.field = [];
        }
        return obj;
    },
    create(base) {
        return GetPeggyDepositTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetPeggyDepositTxsResponse();
        message.field = object.field?.map((e) => PeggyDepositTx.fromPartial(e)) || [];
        return message;
    },
};
function createBasePeggyDepositTx() {
    return {
        sender: "",
        receiver: "",
        eventNonce: "0",
        eventHeight: "0",
        amount: "",
        denom: "",
        orchestratorAddress: "",
        state: "",
        claimType: 0,
        txHashes: [],
        createdAt: "",
        updatedAt: "",
    };
}
export const PeggyDepositTx = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(18).string(message.receiver);
        }
        if (message.eventNonce !== "0") {
            writer.uint32(24).uint64(message.eventNonce);
        }
        if (message.eventHeight !== "0") {
            writer.uint32(32).uint64(message.eventHeight);
        }
        if (message.amount !== "") {
            writer.uint32(42).string(message.amount);
        }
        if (message.denom !== "") {
            writer.uint32(50).string(message.denom);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(58).string(message.orchestratorAddress);
        }
        if (message.state !== "") {
            writer.uint32(66).string(message.state);
        }
        if (message.claimType !== 0) {
            writer.uint32(72).sint32(message.claimType);
        }
        for (const v of message.txHashes) {
            writer.uint32(82).string(v);
        }
        if (message.createdAt !== "") {
            writer.uint32(90).string(message.createdAt);
        }
        if (message.updatedAt !== "") {
            writer.uint32(98).string(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePeggyDepositTx();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.receiver = reader.string();
                    break;
                case 3:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 4:
                    message.eventHeight = longToString(reader.uint64());
                    break;
                case 5:
                    message.amount = reader.string();
                    break;
                case 6:
                    message.denom = reader.string();
                    break;
                case 7:
                    message.orchestratorAddress = reader.string();
                    break;
                case 8:
                    message.state = reader.string();
                    break;
                case 9:
                    message.claimType = reader.sint32();
                    break;
                case 10:
                    message.txHashes.push(reader.string());
                    break;
                case 11:
                    message.createdAt = reader.string();
                    break;
                case 12:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            eventHeight: isSet(object.eventHeight) ? String(object.eventHeight) : "0",
            amount: isSet(object.amount) ? String(object.amount) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
            state: isSet(object.state) ? String(object.state) : "",
            claimType: isSet(object.claimType) ? Number(object.claimType) : 0,
            txHashes: Array.isArray(object?.txHashes) ? object.txHashes.map((e) => String(e)) : [],
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.eventHeight !== undefined && (obj.eventHeight = message.eventHeight);
        message.amount !== undefined && (obj.amount = message.amount);
        message.denom !== undefined && (obj.denom = message.denom);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        message.state !== undefined && (obj.state = message.state);
        message.claimType !== undefined && (obj.claimType = Math.round(message.claimType));
        if (message.txHashes) {
            obj.txHashes = message.txHashes.map((e) => e);
        }
        else {
            obj.txHashes = [];
        }
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return PeggyDepositTx.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePeggyDepositTx();
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.eventNonce = object.eventNonce ?? "0";
        message.eventHeight = object.eventHeight ?? "0";
        message.amount = object.amount ?? "";
        message.denom = object.denom ?? "";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        message.state = object.state ?? "";
        message.claimType = object.claimType ?? 0;
        message.txHashes = object.txHashes?.map((e) => e) || [];
        message.createdAt = object.createdAt ?? "";
        message.updatedAt = object.updatedAt ?? "";
        return message;
    },
};
function createBaseGetPeggyWithdrawalTxsRequest() {
    return { sender: "", receiver: "", limit: 0, skip: "0" };
}
export const GetPeggyWithdrawalTxsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(18).string(message.receiver);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPeggyWithdrawalTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.receiver = reader.string();
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        return obj;
    },
    create(base) {
        return GetPeggyWithdrawalTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetPeggyWithdrawalTxsRequest();
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        return message;
    },
};
function createBaseGetPeggyWithdrawalTxsResponse() {
    return { field: [] };
}
export const GetPeggyWithdrawalTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.field) {
            PeggyWithdrawalTx.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPeggyWithdrawalTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.field.push(PeggyWithdrawalTx.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { field: Array.isArray(object?.field) ? object.field.map((e) => PeggyWithdrawalTx.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.field) {
            obj.field = message.field.map((e) => e ? PeggyWithdrawalTx.toJSON(e) : undefined);
        }
        else {
            obj.field = [];
        }
        return obj;
    },
    create(base) {
        return GetPeggyWithdrawalTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetPeggyWithdrawalTxsResponse();
        message.field = object.field?.map((e) => PeggyWithdrawalTx.fromPartial(e)) || [];
        return message;
    },
};
function createBasePeggyWithdrawalTx() {
    return {
        sender: "",
        receiver: "",
        amount: "",
        denom: "",
        bridgeFee: "",
        outgoingTxId: "0",
        batchTimeout: "0",
        batchNonce: "0",
        orchestratorAddress: "",
        eventNonce: "0",
        eventHeight: "0",
        state: "",
        claimType: 0,
        txHashes: [],
        createdAt: "",
        updatedAt: "",
    };
}
export const PeggyWithdrawalTx = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(18).string(message.receiver);
        }
        if (message.amount !== "") {
            writer.uint32(26).string(message.amount);
        }
        if (message.denom !== "") {
            writer.uint32(34).string(message.denom);
        }
        if (message.bridgeFee !== "") {
            writer.uint32(42).string(message.bridgeFee);
        }
        if (message.outgoingTxId !== "0") {
            writer.uint32(48).uint64(message.outgoingTxId);
        }
        if (message.batchTimeout !== "0") {
            writer.uint32(56).uint64(message.batchTimeout);
        }
        if (message.batchNonce !== "0") {
            writer.uint32(64).uint64(message.batchNonce);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(74).string(message.orchestratorAddress);
        }
        if (message.eventNonce !== "0") {
            writer.uint32(80).uint64(message.eventNonce);
        }
        if (message.eventHeight !== "0") {
            writer.uint32(88).uint64(message.eventHeight);
        }
        if (message.state !== "") {
            writer.uint32(98).string(message.state);
        }
        if (message.claimType !== 0) {
            writer.uint32(104).sint32(message.claimType);
        }
        for (const v of message.txHashes) {
            writer.uint32(114).string(v);
        }
        if (message.createdAt !== "") {
            writer.uint32(122).string(message.createdAt);
        }
        if (message.updatedAt !== "") {
            writer.uint32(130).string(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePeggyWithdrawalTx();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.receiver = reader.string();
                    break;
                case 3:
                    message.amount = reader.string();
                    break;
                case 4:
                    message.denom = reader.string();
                    break;
                case 5:
                    message.bridgeFee = reader.string();
                    break;
                case 6:
                    message.outgoingTxId = longToString(reader.uint64());
                    break;
                case 7:
                    message.batchTimeout = longToString(reader.uint64());
                    break;
                case 8:
                    message.batchNonce = longToString(reader.uint64());
                    break;
                case 9:
                    message.orchestratorAddress = reader.string();
                    break;
                case 10:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 11:
                    message.eventHeight = longToString(reader.uint64());
                    break;
                case 12:
                    message.state = reader.string();
                    break;
                case 13:
                    message.claimType = reader.sint32();
                    break;
                case 14:
                    message.txHashes.push(reader.string());
                    break;
                case 15:
                    message.createdAt = reader.string();
                    break;
                case 16:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            bridgeFee: isSet(object.bridgeFee) ? String(object.bridgeFee) : "",
            outgoingTxId: isSet(object.outgoingTxId) ? String(object.outgoingTxId) : "0",
            batchTimeout: isSet(object.batchTimeout) ? String(object.batchTimeout) : "0",
            batchNonce: isSet(object.batchNonce) ? String(object.batchNonce) : "0",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            eventHeight: isSet(object.eventHeight) ? String(object.eventHeight) : "0",
            state: isSet(object.state) ? String(object.state) : "",
            claimType: isSet(object.claimType) ? Number(object.claimType) : 0,
            txHashes: Array.isArray(object?.txHashes) ? object.txHashes.map((e) => String(e)) : [],
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.amount !== undefined && (obj.amount = message.amount);
        message.denom !== undefined && (obj.denom = message.denom);
        message.bridgeFee !== undefined && (obj.bridgeFee = message.bridgeFee);
        message.outgoingTxId !== undefined && (obj.outgoingTxId = message.outgoingTxId);
        message.batchTimeout !== undefined && (obj.batchTimeout = message.batchTimeout);
        message.batchNonce !== undefined && (obj.batchNonce = message.batchNonce);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.eventHeight !== undefined && (obj.eventHeight = message.eventHeight);
        message.state !== undefined && (obj.state = message.state);
        message.claimType !== undefined && (obj.claimType = Math.round(message.claimType));
        if (message.txHashes) {
            obj.txHashes = message.txHashes.map((e) => e);
        }
        else {
            obj.txHashes = [];
        }
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return PeggyWithdrawalTx.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePeggyWithdrawalTx();
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.amount = object.amount ?? "";
        message.denom = object.denom ?? "";
        message.bridgeFee = object.bridgeFee ?? "";
        message.outgoingTxId = object.outgoingTxId ?? "0";
        message.batchTimeout = object.batchTimeout ?? "0";
        message.batchNonce = object.batchNonce ?? "0";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        message.eventNonce = object.eventNonce ?? "0";
        message.eventHeight = object.eventHeight ?? "0";
        message.state = object.state ?? "";
        message.claimType = object.claimType ?? 0;
        message.txHashes = object.txHashes?.map((e) => e) || [];
        message.createdAt = object.createdAt ?? "";
        message.updatedAt = object.updatedAt ?? "";
        return message;
    },
};
function createBaseGetIBCTransferTxsRequest() {
    return { sender: "", receiver: "", srcChannel: "", srcPort: "", destChannel: "", destPort: "", limit: 0, skip: "0" };
}
export const GetIBCTransferTxsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(18).string(message.receiver);
        }
        if (message.srcChannel !== "") {
            writer.uint32(26).string(message.srcChannel);
        }
        if (message.srcPort !== "") {
            writer.uint32(34).string(message.srcPort);
        }
        if (message.destChannel !== "") {
            writer.uint32(42).string(message.destChannel);
        }
        if (message.destPort !== "") {
            writer.uint32(50).string(message.destPort);
        }
        if (message.limit !== 0) {
            writer.uint32(56).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(64).uint64(message.skip);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetIBCTransferTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.receiver = reader.string();
                    break;
                case 3:
                    message.srcChannel = reader.string();
                    break;
                case 4:
                    message.srcPort = reader.string();
                    break;
                case 5:
                    message.destChannel = reader.string();
                    break;
                case 6:
                    message.destPort = reader.string();
                    break;
                case 7:
                    message.limit = reader.sint32();
                    break;
                case 8:
                    message.skip = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            srcChannel: isSet(object.srcChannel) ? String(object.srcChannel) : "",
            srcPort: isSet(object.srcPort) ? String(object.srcPort) : "",
            destChannel: isSet(object.destChannel) ? String(object.destChannel) : "",
            destPort: isSet(object.destPort) ? String(object.destPort) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.srcChannel !== undefined && (obj.srcChannel = message.srcChannel);
        message.srcPort !== undefined && (obj.srcPort = message.srcPort);
        message.destChannel !== undefined && (obj.destChannel = message.destChannel);
        message.destPort !== undefined && (obj.destPort = message.destPort);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        return obj;
    },
    create(base) {
        return GetIBCTransferTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetIBCTransferTxsRequest();
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.srcChannel = object.srcChannel ?? "";
        message.srcPort = object.srcPort ?? "";
        message.destChannel = object.destChannel ?? "";
        message.destPort = object.destPort ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        return message;
    },
};
function createBaseGetIBCTransferTxsResponse() {
    return { field: [] };
}
export const GetIBCTransferTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.field) {
            IBCTransferTx.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetIBCTransferTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.field.push(IBCTransferTx.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { field: Array.isArray(object?.field) ? object.field.map((e) => IBCTransferTx.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.field) {
            obj.field = message.field.map((e) => e ? IBCTransferTx.toJSON(e) : undefined);
        }
        else {
            obj.field = [];
        }
        return obj;
    },
    create(base) {
        return GetIBCTransferTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetIBCTransferTxsResponse();
        message.field = object.field?.map((e) => IBCTransferTx.fromPartial(e)) || [];
        return message;
    },
};
function createBaseIBCTransferTx() {
    return {
        sender: "",
        receiver: "",
        sourcePort: "",
        sourceChannel: "",
        destinationPort: "",
        destinationChannel: "",
        amount: "",
        denom: "",
        timeoutHeight: "",
        timeoutTimestamp: "0",
        packetSequence: "0",
        dataHex: new Uint8Array(),
        state: "",
        txHashes: [],
        createdAt: "",
        updatedAt: "",
    };
}
export const IBCTransferTx = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(18).string(message.receiver);
        }
        if (message.sourcePort !== "") {
            writer.uint32(26).string(message.sourcePort);
        }
        if (message.sourceChannel !== "") {
            writer.uint32(34).string(message.sourceChannel);
        }
        if (message.destinationPort !== "") {
            writer.uint32(42).string(message.destinationPort);
        }
        if (message.destinationChannel !== "") {
            writer.uint32(50).string(message.destinationChannel);
        }
        if (message.amount !== "") {
            writer.uint32(58).string(message.amount);
        }
        if (message.denom !== "") {
            writer.uint32(66).string(message.denom);
        }
        if (message.timeoutHeight !== "") {
            writer.uint32(74).string(message.timeoutHeight);
        }
        if (message.timeoutTimestamp !== "0") {
            writer.uint32(80).uint64(message.timeoutTimestamp);
        }
        if (message.packetSequence !== "0") {
            writer.uint32(88).uint64(message.packetSequence);
        }
        if (message.dataHex.length !== 0) {
            writer.uint32(98).bytes(message.dataHex);
        }
        if (message.state !== "") {
            writer.uint32(106).string(message.state);
        }
        for (const v of message.txHashes) {
            writer.uint32(114).string(v);
        }
        if (message.createdAt !== "") {
            writer.uint32(122).string(message.createdAt);
        }
        if (message.updatedAt !== "") {
            writer.uint32(130).string(message.updatedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIBCTransferTx();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.receiver = reader.string();
                    break;
                case 3:
                    message.sourcePort = reader.string();
                    break;
                case 4:
                    message.sourceChannel = reader.string();
                    break;
                case 5:
                    message.destinationPort = reader.string();
                    break;
                case 6:
                    message.destinationChannel = reader.string();
                    break;
                case 7:
                    message.amount = reader.string();
                    break;
                case 8:
                    message.denom = reader.string();
                    break;
                case 9:
                    message.timeoutHeight = reader.string();
                    break;
                case 10:
                    message.timeoutTimestamp = longToString(reader.uint64());
                    break;
                case 11:
                    message.packetSequence = longToString(reader.uint64());
                    break;
                case 12:
                    message.dataHex = reader.bytes();
                    break;
                case 13:
                    message.state = reader.string();
                    break;
                case 14:
                    message.txHashes.push(reader.string());
                    break;
                case 15:
                    message.createdAt = reader.string();
                    break;
                case 16:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            sourcePort: isSet(object.sourcePort) ? String(object.sourcePort) : "",
            sourceChannel: isSet(object.sourceChannel) ? String(object.sourceChannel) : "",
            destinationPort: isSet(object.destinationPort) ? String(object.destinationPort) : "",
            destinationChannel: isSet(object.destinationChannel) ? String(object.destinationChannel) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            timeoutHeight: isSet(object.timeoutHeight) ? String(object.timeoutHeight) : "",
            timeoutTimestamp: isSet(object.timeoutTimestamp) ? String(object.timeoutTimestamp) : "0",
            packetSequence: isSet(object.packetSequence) ? String(object.packetSequence) : "0",
            dataHex: isSet(object.dataHex) ? bytesFromBase64(object.dataHex) : new Uint8Array(),
            state: isSet(object.state) ? String(object.state) : "",
            txHashes: Array.isArray(object?.txHashes) ? object.txHashes.map((e) => String(e)) : [],
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.sourcePort !== undefined && (obj.sourcePort = message.sourcePort);
        message.sourceChannel !== undefined && (obj.sourceChannel = message.sourceChannel);
        message.destinationPort !== undefined && (obj.destinationPort = message.destinationPort);
        message.destinationChannel !== undefined && (obj.destinationChannel = message.destinationChannel);
        message.amount !== undefined && (obj.amount = message.amount);
        message.denom !== undefined && (obj.denom = message.denom);
        message.timeoutHeight !== undefined && (obj.timeoutHeight = message.timeoutHeight);
        message.timeoutTimestamp !== undefined && (obj.timeoutTimestamp = message.timeoutTimestamp);
        message.packetSequence !== undefined && (obj.packetSequence = message.packetSequence);
        message.dataHex !== undefined &&
            (obj.dataHex = base64FromBytes(message.dataHex !== undefined ? message.dataHex : new Uint8Array()));
        message.state !== undefined && (obj.state = message.state);
        if (message.txHashes) {
            obj.txHashes = message.txHashes.map((e) => e);
        }
        else {
            obj.txHashes = [];
        }
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        return obj;
    },
    create(base) {
        return IBCTransferTx.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseIBCTransferTx();
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.sourcePort = object.sourcePort ?? "";
        message.sourceChannel = object.sourceChannel ?? "";
        message.destinationPort = object.destinationPort ?? "";
        message.destinationChannel = object.destinationChannel ?? "";
        message.amount = object.amount ?? "";
        message.denom = object.denom ?? "";
        message.timeoutHeight = object.timeoutHeight ?? "";
        message.timeoutTimestamp = object.timeoutTimestamp ?? "0";
        message.packetSequence = object.packetSequence ?? "0";
        message.dataHex = object.dataHex ?? new Uint8Array();
        message.state = object.state ?? "";
        message.txHashes = object.txHashes?.map((e) => e) || [];
        message.createdAt = object.createdAt ?? "";
        message.updatedAt = object.updatedAt ?? "";
        return message;
    },
};
function createBaseGetWasmCodesRequest() {
    return { limit: 0, fromNumber: "0", toNumber: "0" };
}
export const GetWasmCodesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.limit !== 0) {
            writer.uint32(8).sint32(message.limit);
        }
        if (message.fromNumber !== "0") {
            writer.uint32(16).sint64(message.fromNumber);
        }
        if (message.toNumber !== "0") {
            writer.uint32(24).sint64(message.toNumber);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmCodesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.limit = reader.sint32();
                    break;
                case 2:
                    message.fromNumber = longToString(reader.sint64());
                    break;
                case 3:
                    message.toNumber = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            fromNumber: isSet(object.fromNumber) ? String(object.fromNumber) : "0",
            toNumber: isSet(object.toNumber) ? String(object.toNumber) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.fromNumber !== undefined && (obj.fromNumber = message.fromNumber);
        message.toNumber !== undefined && (obj.toNumber = message.toNumber);
        return obj;
    },
    create(base) {
        return GetWasmCodesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmCodesRequest();
        message.limit = object.limit ?? 0;
        message.fromNumber = object.fromNumber ?? "0";
        message.toNumber = object.toNumber ?? "0";
        return message;
    },
};
function createBaseGetWasmCodesResponse() {
    return { paging: undefined, data: [] };
}
export const GetWasmCodesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            WasmCode.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmCodesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(WasmCode.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => WasmCode.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? WasmCode.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetWasmCodesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmCodesResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => WasmCode.fromPartial(e)) || [];
        return message;
    },
};
function createBaseWasmCode() {
    return {
        codeId: "0",
        txHash: "",
        checksum: undefined,
        createdAt: "0",
        contractType: "",
        version: "",
        permission: undefined,
        codeSchema: "",
        codeView: "",
        instantiates: "0",
        creator: "",
        codeNumber: "0",
        proposalId: "0",
    };
}
export const WasmCode = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.codeId !== "0") {
            writer.uint32(8).uint64(message.codeId);
        }
        if (message.txHash !== "") {
            writer.uint32(18).string(message.txHash);
        }
        if (message.checksum !== undefined) {
            Checksum.encode(message.checksum, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== "0") {
            writer.uint32(32).uint64(message.createdAt);
        }
        if (message.contractType !== "") {
            writer.uint32(42).string(message.contractType);
        }
        if (message.version !== "") {
            writer.uint32(50).string(message.version);
        }
        if (message.permission !== undefined) {
            ContractPermission.encode(message.permission, writer.uint32(58).fork()).ldelim();
        }
        if (message.codeSchema !== "") {
            writer.uint32(66).string(message.codeSchema);
        }
        if (message.codeView !== "") {
            writer.uint32(74).string(message.codeView);
        }
        if (message.instantiates !== "0") {
            writer.uint32(80).uint64(message.instantiates);
        }
        if (message.creator !== "") {
            writer.uint32(90).string(message.creator);
        }
        if (message.codeNumber !== "0") {
            writer.uint32(96).sint64(message.codeNumber);
        }
        if (message.proposalId !== "0") {
            writer.uint32(104).sint64(message.proposalId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWasmCode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 2:
                    message.txHash = reader.string();
                    break;
                case 3:
                    message.checksum = Checksum.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createdAt = longToString(reader.uint64());
                    break;
                case 5:
                    message.contractType = reader.string();
                    break;
                case 6:
                    message.version = reader.string();
                    break;
                case 7:
                    message.permission = ContractPermission.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.codeSchema = reader.string();
                    break;
                case 9:
                    message.codeView = reader.string();
                    break;
                case 10:
                    message.instantiates = longToString(reader.uint64());
                    break;
                case 11:
                    message.creator = reader.string();
                    break;
                case 12:
                    message.codeNumber = longToString(reader.sint64());
                    break;
                case 13:
                    message.proposalId = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            checksum: isSet(object.checksum) ? Checksum.fromJSON(object.checksum) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            contractType: isSet(object.contractType) ? String(object.contractType) : "",
            version: isSet(object.version) ? String(object.version) : "",
            permission: isSet(object.permission) ? ContractPermission.fromJSON(object.permission) : undefined,
            codeSchema: isSet(object.codeSchema) ? String(object.codeSchema) : "",
            codeView: isSet(object.codeView) ? String(object.codeView) : "",
            instantiates: isSet(object.instantiates) ? String(object.instantiates) : "0",
            creator: isSet(object.creator) ? String(object.creator) : "",
            codeNumber: isSet(object.codeNumber) ? String(object.codeNumber) : "0",
            proposalId: isSet(object.proposalId) ? String(object.proposalId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.checksum !== undefined && (obj.checksum = message.checksum ? Checksum.toJSON(message.checksum) : undefined);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.contractType !== undefined && (obj.contractType = message.contractType);
        message.version !== undefined && (obj.version = message.version);
        message.permission !== undefined &&
            (obj.permission = message.permission ? ContractPermission.toJSON(message.permission) : undefined);
        message.codeSchema !== undefined && (obj.codeSchema = message.codeSchema);
        message.codeView !== undefined && (obj.codeView = message.codeView);
        message.instantiates !== undefined && (obj.instantiates = message.instantiates);
        message.creator !== undefined && (obj.creator = message.creator);
        message.codeNumber !== undefined && (obj.codeNumber = message.codeNumber);
        message.proposalId !== undefined && (obj.proposalId = message.proposalId);
        return obj;
    },
    create(base) {
        return WasmCode.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseWasmCode();
        message.codeId = object.codeId ?? "0";
        message.txHash = object.txHash ?? "";
        message.checksum = (object.checksum !== undefined && object.checksum !== null)
            ? Checksum.fromPartial(object.checksum)
            : undefined;
        message.createdAt = object.createdAt ?? "0";
        message.contractType = object.contractType ?? "";
        message.version = object.version ?? "";
        message.permission = (object.permission !== undefined && object.permission !== null)
            ? ContractPermission.fromPartial(object.permission)
            : undefined;
        message.codeSchema = object.codeSchema ?? "";
        message.codeView = object.codeView ?? "";
        message.instantiates = object.instantiates ?? "0";
        message.creator = object.creator ?? "";
        message.codeNumber = object.codeNumber ?? "0";
        message.proposalId = object.proposalId ?? "0";
        return message;
    },
};
function createBaseChecksum() {
    return { algorithm: "", hash: "" };
}
export const Checksum = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.algorithm !== "") {
            writer.uint32(10).string(message.algorithm);
        }
        if (message.hash !== "") {
            writer.uint32(18).string(message.hash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChecksum();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.algorithm = reader.string();
                    break;
                case 2:
                    message.hash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            algorithm: isSet(object.algorithm) ? String(object.algorithm) : "",
            hash: isSet(object.hash) ? String(object.hash) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.algorithm !== undefined && (obj.algorithm = message.algorithm);
        message.hash !== undefined && (obj.hash = message.hash);
        return obj;
    },
    create(base) {
        return Checksum.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseChecksum();
        message.algorithm = object.algorithm ?? "";
        message.hash = object.hash ?? "";
        return message;
    },
};
function createBaseContractPermission() {
    return { accessType: 0, address: "" };
}
export const ContractPermission = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accessType !== 0) {
            writer.uint32(8).sint32(message.accessType);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContractPermission();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accessType = reader.sint32();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accessType: isSet(object.accessType) ? Number(object.accessType) : 0,
            address: isSet(object.address) ? String(object.address) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.accessType !== undefined && (obj.accessType = Math.round(message.accessType));
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return ContractPermission.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseContractPermission();
        message.accessType = object.accessType ?? 0;
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseGetWasmCodeByIDRequest() {
    return { codeId: "0" };
}
export const GetWasmCodeByIDRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.codeId !== "0") {
            writer.uint32(8).sint64(message.codeId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmCodeByIDRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.codeId = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { codeId: isSet(object.codeId) ? String(object.codeId) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.codeId !== undefined && (obj.codeId = message.codeId);
        return obj;
    },
    create(base) {
        return GetWasmCodeByIDRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmCodeByIDRequest();
        message.codeId = object.codeId ?? "0";
        return message;
    },
};
function createBaseGetWasmCodeByIDResponse() {
    return {
        codeId: "0",
        txHash: "",
        checksum: undefined,
        createdAt: "0",
        contractType: "",
        version: "",
        permission: undefined,
        codeSchema: "",
        codeView: "",
        instantiates: "0",
        creator: "",
        codeNumber: "0",
        proposalId: "0",
    };
}
export const GetWasmCodeByIDResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.codeId !== "0") {
            writer.uint32(8).uint64(message.codeId);
        }
        if (message.txHash !== "") {
            writer.uint32(18).string(message.txHash);
        }
        if (message.checksum !== undefined) {
            Checksum.encode(message.checksum, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdAt !== "0") {
            writer.uint32(32).uint64(message.createdAt);
        }
        if (message.contractType !== "") {
            writer.uint32(42).string(message.contractType);
        }
        if (message.version !== "") {
            writer.uint32(50).string(message.version);
        }
        if (message.permission !== undefined) {
            ContractPermission.encode(message.permission, writer.uint32(58).fork()).ldelim();
        }
        if (message.codeSchema !== "") {
            writer.uint32(66).string(message.codeSchema);
        }
        if (message.codeView !== "") {
            writer.uint32(74).string(message.codeView);
        }
        if (message.instantiates !== "0") {
            writer.uint32(80).uint64(message.instantiates);
        }
        if (message.creator !== "") {
            writer.uint32(90).string(message.creator);
        }
        if (message.codeNumber !== "0") {
            writer.uint32(96).sint64(message.codeNumber);
        }
        if (message.proposalId !== "0") {
            writer.uint32(104).sint64(message.proposalId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmCodeByIDResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 2:
                    message.txHash = reader.string();
                    break;
                case 3:
                    message.checksum = Checksum.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createdAt = longToString(reader.uint64());
                    break;
                case 5:
                    message.contractType = reader.string();
                    break;
                case 6:
                    message.version = reader.string();
                    break;
                case 7:
                    message.permission = ContractPermission.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.codeSchema = reader.string();
                    break;
                case 9:
                    message.codeView = reader.string();
                    break;
                case 10:
                    message.instantiates = longToString(reader.uint64());
                    break;
                case 11:
                    message.creator = reader.string();
                    break;
                case 12:
                    message.codeNumber = longToString(reader.sint64());
                    break;
                case 13:
                    message.proposalId = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            checksum: isSet(object.checksum) ? Checksum.fromJSON(object.checksum) : undefined,
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            contractType: isSet(object.contractType) ? String(object.contractType) : "",
            version: isSet(object.version) ? String(object.version) : "",
            permission: isSet(object.permission) ? ContractPermission.fromJSON(object.permission) : undefined,
            codeSchema: isSet(object.codeSchema) ? String(object.codeSchema) : "",
            codeView: isSet(object.codeView) ? String(object.codeView) : "",
            instantiates: isSet(object.instantiates) ? String(object.instantiates) : "0",
            creator: isSet(object.creator) ? String(object.creator) : "",
            codeNumber: isSet(object.codeNumber) ? String(object.codeNumber) : "0",
            proposalId: isSet(object.proposalId) ? String(object.proposalId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.checksum !== undefined && (obj.checksum = message.checksum ? Checksum.toJSON(message.checksum) : undefined);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.contractType !== undefined && (obj.contractType = message.contractType);
        message.version !== undefined && (obj.version = message.version);
        message.permission !== undefined &&
            (obj.permission = message.permission ? ContractPermission.toJSON(message.permission) : undefined);
        message.codeSchema !== undefined && (obj.codeSchema = message.codeSchema);
        message.codeView !== undefined && (obj.codeView = message.codeView);
        message.instantiates !== undefined && (obj.instantiates = message.instantiates);
        message.creator !== undefined && (obj.creator = message.creator);
        message.codeNumber !== undefined && (obj.codeNumber = message.codeNumber);
        message.proposalId !== undefined && (obj.proposalId = message.proposalId);
        return obj;
    },
    create(base) {
        return GetWasmCodeByIDResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmCodeByIDResponse();
        message.codeId = object.codeId ?? "0";
        message.txHash = object.txHash ?? "";
        message.checksum = (object.checksum !== undefined && object.checksum !== null)
            ? Checksum.fromPartial(object.checksum)
            : undefined;
        message.createdAt = object.createdAt ?? "0";
        message.contractType = object.contractType ?? "";
        message.version = object.version ?? "";
        message.permission = (object.permission !== undefined && object.permission !== null)
            ? ContractPermission.fromPartial(object.permission)
            : undefined;
        message.codeSchema = object.codeSchema ?? "";
        message.codeView = object.codeView ?? "";
        message.instantiates = object.instantiates ?? "0";
        message.creator = object.creator ?? "";
        message.codeNumber = object.codeNumber ?? "0";
        message.proposalId = object.proposalId ?? "0";
        return message;
    },
};
function createBaseGetWasmContractsRequest() {
    return { limit: 0, codeId: "0", fromNumber: "0", toNumber: "0", assetsOnly: false, skip: "0", label: "" };
}
export const GetWasmContractsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.limit !== 0) {
            writer.uint32(8).sint32(message.limit);
        }
        if (message.codeId !== "0") {
            writer.uint32(16).sint64(message.codeId);
        }
        if (message.fromNumber !== "0") {
            writer.uint32(24).sint64(message.fromNumber);
        }
        if (message.toNumber !== "0") {
            writer.uint32(32).sint64(message.toNumber);
        }
        if (message.assetsOnly === true) {
            writer.uint32(40).bool(message.assetsOnly);
        }
        if (message.skip !== "0") {
            writer.uint32(48).sint64(message.skip);
        }
        if (message.label !== "") {
            writer.uint32(58).string(message.label);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmContractsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.limit = reader.sint32();
                    break;
                case 2:
                    message.codeId = longToString(reader.sint64());
                    break;
                case 3:
                    message.fromNumber = longToString(reader.sint64());
                    break;
                case 4:
                    message.toNumber = longToString(reader.sint64());
                    break;
                case 5:
                    message.assetsOnly = reader.bool();
                    break;
                case 6:
                    message.skip = longToString(reader.sint64());
                    break;
                case 7:
                    message.label = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            fromNumber: isSet(object.fromNumber) ? String(object.fromNumber) : "0",
            toNumber: isSet(object.toNumber) ? String(object.toNumber) : "0",
            assetsOnly: isSet(object.assetsOnly) ? Boolean(object.assetsOnly) : false,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            label: isSet(object.label) ? String(object.label) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.fromNumber !== undefined && (obj.fromNumber = message.fromNumber);
        message.toNumber !== undefined && (obj.toNumber = message.toNumber);
        message.assetsOnly !== undefined && (obj.assetsOnly = message.assetsOnly);
        message.skip !== undefined && (obj.skip = message.skip);
        message.label !== undefined && (obj.label = message.label);
        return obj;
    },
    create(base) {
        return GetWasmContractsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmContractsRequest();
        message.limit = object.limit ?? 0;
        message.codeId = object.codeId ?? "0";
        message.fromNumber = object.fromNumber ?? "0";
        message.toNumber = object.toNumber ?? "0";
        message.assetsOnly = object.assetsOnly ?? false;
        message.skip = object.skip ?? "0";
        message.label = object.label ?? "";
        return message;
    },
};
function createBaseGetWasmContractsResponse() {
    return { paging: undefined, data: [] };
}
export const GetWasmContractsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            WasmContract.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmContractsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(WasmContract.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => WasmContract.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? WasmContract.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetWasmContractsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmContractsResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => WasmContract.fromPartial(e)) || [];
        return message;
    },
};
function createBaseWasmContract() {
    return {
        label: "",
        address: "",
        txHash: "",
        creator: "",
        executes: "0",
        instantiatedAt: "0",
        initMessage: "",
        lastExecutedAt: "0",
        funds: [],
        codeId: "0",
        admin: "",
        currentMigrateMessage: "",
        contractNumber: "0",
        version: "",
        type: "",
        cw20Metadata: undefined,
        proposalId: "0",
    };
}
export const WasmContract = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.label !== "") {
            writer.uint32(10).string(message.label);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        if (message.txHash !== "") {
            writer.uint32(26).string(message.txHash);
        }
        if (message.creator !== "") {
            writer.uint32(34).string(message.creator);
        }
        if (message.executes !== "0") {
            writer.uint32(40).uint64(message.executes);
        }
        if (message.instantiatedAt !== "0") {
            writer.uint32(48).uint64(message.instantiatedAt);
        }
        if (message.initMessage !== "") {
            writer.uint32(58).string(message.initMessage);
        }
        if (message.lastExecutedAt !== "0") {
            writer.uint32(64).uint64(message.lastExecutedAt);
        }
        for (const v of message.funds) {
            ContractFund.encode(v, writer.uint32(74).fork()).ldelim();
        }
        if (message.codeId !== "0") {
            writer.uint32(80).uint64(message.codeId);
        }
        if (message.admin !== "") {
            writer.uint32(90).string(message.admin);
        }
        if (message.currentMigrateMessage !== "") {
            writer.uint32(98).string(message.currentMigrateMessage);
        }
        if (message.contractNumber !== "0") {
            writer.uint32(104).sint64(message.contractNumber);
        }
        if (message.version !== "") {
            writer.uint32(114).string(message.version);
        }
        if (message.type !== "") {
            writer.uint32(122).string(message.type);
        }
        if (message.cw20Metadata !== undefined) {
            Cw20Metadata.encode(message.cw20Metadata, writer.uint32(130).fork()).ldelim();
        }
        if (message.proposalId !== "0") {
            writer.uint32(136).sint64(message.proposalId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWasmContract();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                case 3:
                    message.txHash = reader.string();
                    break;
                case 4:
                    message.creator = reader.string();
                    break;
                case 5:
                    message.executes = longToString(reader.uint64());
                    break;
                case 6:
                    message.instantiatedAt = longToString(reader.uint64());
                    break;
                case 7:
                    message.initMessage = reader.string();
                    break;
                case 8:
                    message.lastExecutedAt = longToString(reader.uint64());
                    break;
                case 9:
                    message.funds.push(ContractFund.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 11:
                    message.admin = reader.string();
                    break;
                case 12:
                    message.currentMigrateMessage = reader.string();
                    break;
                case 13:
                    message.contractNumber = longToString(reader.sint64());
                    break;
                case 14:
                    message.version = reader.string();
                    break;
                case 15:
                    message.type = reader.string();
                    break;
                case 16:
                    message.cw20Metadata = Cw20Metadata.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.proposalId = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            label: isSet(object.label) ? String(object.label) : "",
            address: isSet(object.address) ? String(object.address) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            creator: isSet(object.creator) ? String(object.creator) : "",
            executes: isSet(object.executes) ? String(object.executes) : "0",
            instantiatedAt: isSet(object.instantiatedAt) ? String(object.instantiatedAt) : "0",
            initMessage: isSet(object.initMessage) ? String(object.initMessage) : "",
            lastExecutedAt: isSet(object.lastExecutedAt) ? String(object.lastExecutedAt) : "0",
            funds: Array.isArray(object?.funds) ? object.funds.map((e) => ContractFund.fromJSON(e)) : [],
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            admin: isSet(object.admin) ? String(object.admin) : "",
            currentMigrateMessage: isSet(object.currentMigrateMessage) ? String(object.currentMigrateMessage) : "",
            contractNumber: isSet(object.contractNumber) ? String(object.contractNumber) : "0",
            version: isSet(object.version) ? String(object.version) : "",
            type: isSet(object.type) ? String(object.type) : "",
            cw20Metadata: isSet(object.cw20Metadata) ? Cw20Metadata.fromJSON(object.cw20Metadata) : undefined,
            proposalId: isSet(object.proposalId) ? String(object.proposalId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.label !== undefined && (obj.label = message.label);
        message.address !== undefined && (obj.address = message.address);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.creator !== undefined && (obj.creator = message.creator);
        message.executes !== undefined && (obj.executes = message.executes);
        message.instantiatedAt !== undefined && (obj.instantiatedAt = message.instantiatedAt);
        message.initMessage !== undefined && (obj.initMessage = message.initMessage);
        message.lastExecutedAt !== undefined && (obj.lastExecutedAt = message.lastExecutedAt);
        if (message.funds) {
            obj.funds = message.funds.map((e) => e ? ContractFund.toJSON(e) : undefined);
        }
        else {
            obj.funds = [];
        }
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.admin !== undefined && (obj.admin = message.admin);
        message.currentMigrateMessage !== undefined && (obj.currentMigrateMessage = message.currentMigrateMessage);
        message.contractNumber !== undefined && (obj.contractNumber = message.contractNumber);
        message.version !== undefined && (obj.version = message.version);
        message.type !== undefined && (obj.type = message.type);
        message.cw20Metadata !== undefined &&
            (obj.cw20Metadata = message.cw20Metadata ? Cw20Metadata.toJSON(message.cw20Metadata) : undefined);
        message.proposalId !== undefined && (obj.proposalId = message.proposalId);
        return obj;
    },
    create(base) {
        return WasmContract.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseWasmContract();
        message.label = object.label ?? "";
        message.address = object.address ?? "";
        message.txHash = object.txHash ?? "";
        message.creator = object.creator ?? "";
        message.executes = object.executes ?? "0";
        message.instantiatedAt = object.instantiatedAt ?? "0";
        message.initMessage = object.initMessage ?? "";
        message.lastExecutedAt = object.lastExecutedAt ?? "0";
        message.funds = object.funds?.map((e) => ContractFund.fromPartial(e)) || [];
        message.codeId = object.codeId ?? "0";
        message.admin = object.admin ?? "";
        message.currentMigrateMessage = object.currentMigrateMessage ?? "";
        message.contractNumber = object.contractNumber ?? "0";
        message.version = object.version ?? "";
        message.type = object.type ?? "";
        message.cw20Metadata = (object.cw20Metadata !== undefined && object.cw20Metadata !== null)
            ? Cw20Metadata.fromPartial(object.cw20Metadata)
            : undefined;
        message.proposalId = object.proposalId ?? "0";
        return message;
    },
};
function createBaseContractFund() {
    return { denom: "", amount: "" };
}
export const ContractFund = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContractFund();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return ContractFund.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseContractFund();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseCw20Metadata() {
    return { tokenInfo: undefined, marketingInfo: undefined };
}
export const Cw20Metadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tokenInfo !== undefined) {
            Cw20TokenInfo.encode(message.tokenInfo, writer.uint32(10).fork()).ldelim();
        }
        if (message.marketingInfo !== undefined) {
            Cw20MarketingInfo.encode(message.marketingInfo, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCw20Metadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokenInfo = Cw20TokenInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.marketingInfo = Cw20MarketingInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tokenInfo: isSet(object.tokenInfo) ? Cw20TokenInfo.fromJSON(object.tokenInfo) : undefined,
            marketingInfo: isSet(object.marketingInfo) ? Cw20MarketingInfo.fromJSON(object.marketingInfo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.tokenInfo !== undefined &&
            (obj.tokenInfo = message.tokenInfo ? Cw20TokenInfo.toJSON(message.tokenInfo) : undefined);
        message.marketingInfo !== undefined &&
            (obj.marketingInfo = message.marketingInfo ? Cw20MarketingInfo.toJSON(message.marketingInfo) : undefined);
        return obj;
    },
    create(base) {
        return Cw20Metadata.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCw20Metadata();
        message.tokenInfo = (object.tokenInfo !== undefined && object.tokenInfo !== null)
            ? Cw20TokenInfo.fromPartial(object.tokenInfo)
            : undefined;
        message.marketingInfo = (object.marketingInfo !== undefined && object.marketingInfo !== null)
            ? Cw20MarketingInfo.fromPartial(object.marketingInfo)
            : undefined;
        return message;
    },
};
function createBaseCw20TokenInfo() {
    return { name: "", symbol: "", decimals: "0", totalSupply: "" };
}
export const Cw20TokenInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.symbol !== "") {
            writer.uint32(18).string(message.symbol);
        }
        if (message.decimals !== "0") {
            writer.uint32(24).sint64(message.decimals);
        }
        if (message.totalSupply !== "") {
            writer.uint32(34).string(message.totalSupply);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCw20TokenInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.symbol = reader.string();
                    break;
                case 3:
                    message.decimals = longToString(reader.sint64());
                    break;
                case 4:
                    message.totalSupply = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            decimals: isSet(object.decimals) ? String(object.decimals) : "0",
            totalSupply: isSet(object.totalSupply) ? String(object.totalSupply) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.decimals !== undefined && (obj.decimals = message.decimals);
        message.totalSupply !== undefined && (obj.totalSupply = message.totalSupply);
        return obj;
    },
    create(base) {
        return Cw20TokenInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCw20TokenInfo();
        message.name = object.name ?? "";
        message.symbol = object.symbol ?? "";
        message.decimals = object.decimals ?? "0";
        message.totalSupply = object.totalSupply ?? "";
        return message;
    },
};
function createBaseCw20MarketingInfo() {
    return { project: "", description: "", logo: "", marketing: new Uint8Array() };
}
export const Cw20MarketingInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.project !== "") {
            writer.uint32(10).string(message.project);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.logo !== "") {
            writer.uint32(26).string(message.logo);
        }
        if (message.marketing.length !== 0) {
            writer.uint32(34).bytes(message.marketing);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCw20MarketingInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.project = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.logo = reader.string();
                    break;
                case 4:
                    message.marketing = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            project: isSet(object.project) ? String(object.project) : "",
            description: isSet(object.description) ? String(object.description) : "",
            logo: isSet(object.logo) ? String(object.logo) : "",
            marketing: isSet(object.marketing) ? bytesFromBase64(object.marketing) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.project !== undefined && (obj.project = message.project);
        message.description !== undefined && (obj.description = message.description);
        message.logo !== undefined && (obj.logo = message.logo);
        message.marketing !== undefined &&
            (obj.marketing = base64FromBytes(message.marketing !== undefined ? message.marketing : new Uint8Array()));
        return obj;
    },
    create(base) {
        return Cw20MarketingInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCw20MarketingInfo();
        message.project = object.project ?? "";
        message.description = object.description ?? "";
        message.logo = object.logo ?? "";
        message.marketing = object.marketing ?? new Uint8Array();
        return message;
    },
};
function createBaseGetWasmContractByAddressRequest() {
    return { contractAddress: "" };
}
export const GetWasmContractByAddressRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.contractAddress !== "") {
            writer.uint32(10).string(message.contractAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmContractByAddressRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.contractAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        return obj;
    },
    create(base) {
        return GetWasmContractByAddressRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmContractByAddressRequest();
        message.contractAddress = object.contractAddress ?? "";
        return message;
    },
};
function createBaseGetWasmContractByAddressResponse() {
    return {
        label: "",
        address: "",
        txHash: "",
        creator: "",
        executes: "0",
        instantiatedAt: "0",
        initMessage: "",
        lastExecutedAt: "0",
        funds: [],
        codeId: "0",
        admin: "",
        currentMigrateMessage: "",
        contractNumber: "0",
        version: "",
        type: "",
        cw20Metadata: undefined,
        proposalId: "0",
    };
}
export const GetWasmContractByAddressResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.label !== "") {
            writer.uint32(10).string(message.label);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        if (message.txHash !== "") {
            writer.uint32(26).string(message.txHash);
        }
        if (message.creator !== "") {
            writer.uint32(34).string(message.creator);
        }
        if (message.executes !== "0") {
            writer.uint32(40).uint64(message.executes);
        }
        if (message.instantiatedAt !== "0") {
            writer.uint32(48).uint64(message.instantiatedAt);
        }
        if (message.initMessage !== "") {
            writer.uint32(58).string(message.initMessage);
        }
        if (message.lastExecutedAt !== "0") {
            writer.uint32(64).uint64(message.lastExecutedAt);
        }
        for (const v of message.funds) {
            ContractFund.encode(v, writer.uint32(74).fork()).ldelim();
        }
        if (message.codeId !== "0") {
            writer.uint32(80).uint64(message.codeId);
        }
        if (message.admin !== "") {
            writer.uint32(90).string(message.admin);
        }
        if (message.currentMigrateMessage !== "") {
            writer.uint32(98).string(message.currentMigrateMessage);
        }
        if (message.contractNumber !== "0") {
            writer.uint32(104).sint64(message.contractNumber);
        }
        if (message.version !== "") {
            writer.uint32(114).string(message.version);
        }
        if (message.type !== "") {
            writer.uint32(122).string(message.type);
        }
        if (message.cw20Metadata !== undefined) {
            Cw20Metadata.encode(message.cw20Metadata, writer.uint32(130).fork()).ldelim();
        }
        if (message.proposalId !== "0") {
            writer.uint32(136).sint64(message.proposalId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetWasmContractByAddressResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                case 3:
                    message.txHash = reader.string();
                    break;
                case 4:
                    message.creator = reader.string();
                    break;
                case 5:
                    message.executes = longToString(reader.uint64());
                    break;
                case 6:
                    message.instantiatedAt = longToString(reader.uint64());
                    break;
                case 7:
                    message.initMessage = reader.string();
                    break;
                case 8:
                    message.lastExecutedAt = longToString(reader.uint64());
                    break;
                case 9:
                    message.funds.push(ContractFund.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 11:
                    message.admin = reader.string();
                    break;
                case 12:
                    message.currentMigrateMessage = reader.string();
                    break;
                case 13:
                    message.contractNumber = longToString(reader.sint64());
                    break;
                case 14:
                    message.version = reader.string();
                    break;
                case 15:
                    message.type = reader.string();
                    break;
                case 16:
                    message.cw20Metadata = Cw20Metadata.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.proposalId = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            label: isSet(object.label) ? String(object.label) : "",
            address: isSet(object.address) ? String(object.address) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            creator: isSet(object.creator) ? String(object.creator) : "",
            executes: isSet(object.executes) ? String(object.executes) : "0",
            instantiatedAt: isSet(object.instantiatedAt) ? String(object.instantiatedAt) : "0",
            initMessage: isSet(object.initMessage) ? String(object.initMessage) : "",
            lastExecutedAt: isSet(object.lastExecutedAt) ? String(object.lastExecutedAt) : "0",
            funds: Array.isArray(object?.funds) ? object.funds.map((e) => ContractFund.fromJSON(e)) : [],
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            admin: isSet(object.admin) ? String(object.admin) : "",
            currentMigrateMessage: isSet(object.currentMigrateMessage) ? String(object.currentMigrateMessage) : "",
            contractNumber: isSet(object.contractNumber) ? String(object.contractNumber) : "0",
            version: isSet(object.version) ? String(object.version) : "",
            type: isSet(object.type) ? String(object.type) : "",
            cw20Metadata: isSet(object.cw20Metadata) ? Cw20Metadata.fromJSON(object.cw20Metadata) : undefined,
            proposalId: isSet(object.proposalId) ? String(object.proposalId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.label !== undefined && (obj.label = message.label);
        message.address !== undefined && (obj.address = message.address);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.creator !== undefined && (obj.creator = message.creator);
        message.executes !== undefined && (obj.executes = message.executes);
        message.instantiatedAt !== undefined && (obj.instantiatedAt = message.instantiatedAt);
        message.initMessage !== undefined && (obj.initMessage = message.initMessage);
        message.lastExecutedAt !== undefined && (obj.lastExecutedAt = message.lastExecutedAt);
        if (message.funds) {
            obj.funds = message.funds.map((e) => e ? ContractFund.toJSON(e) : undefined);
        }
        else {
            obj.funds = [];
        }
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.admin !== undefined && (obj.admin = message.admin);
        message.currentMigrateMessage !== undefined && (obj.currentMigrateMessage = message.currentMigrateMessage);
        message.contractNumber !== undefined && (obj.contractNumber = message.contractNumber);
        message.version !== undefined && (obj.version = message.version);
        message.type !== undefined && (obj.type = message.type);
        message.cw20Metadata !== undefined &&
            (obj.cw20Metadata = message.cw20Metadata ? Cw20Metadata.toJSON(message.cw20Metadata) : undefined);
        message.proposalId !== undefined && (obj.proposalId = message.proposalId);
        return obj;
    },
    create(base) {
        return GetWasmContractByAddressResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetWasmContractByAddressResponse();
        message.label = object.label ?? "";
        message.address = object.address ?? "";
        message.txHash = object.txHash ?? "";
        message.creator = object.creator ?? "";
        message.executes = object.executes ?? "0";
        message.instantiatedAt = object.instantiatedAt ?? "0";
        message.initMessage = object.initMessage ?? "";
        message.lastExecutedAt = object.lastExecutedAt ?? "0";
        message.funds = object.funds?.map((e) => ContractFund.fromPartial(e)) || [];
        message.codeId = object.codeId ?? "0";
        message.admin = object.admin ?? "";
        message.currentMigrateMessage = object.currentMigrateMessage ?? "";
        message.contractNumber = object.contractNumber ?? "0";
        message.version = object.version ?? "";
        message.type = object.type ?? "";
        message.cw20Metadata = (object.cw20Metadata !== undefined && object.cw20Metadata !== null)
            ? Cw20Metadata.fromPartial(object.cw20Metadata)
            : undefined;
        message.proposalId = object.proposalId ?? "0";
        return message;
    },
};
function createBaseGetCw20BalanceRequest() {
    return { address: "", limit: 0 };
}
export const GetCw20BalanceRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.limit !== 0) {
            writer.uint32(16).sint32(message.limit);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCw20BalanceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.limit = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        return obj;
    },
    create(base) {
        return GetCw20BalanceRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetCw20BalanceRequest();
        message.address = object.address ?? "";
        message.limit = object.limit ?? 0;
        return message;
    },
};
function createBaseGetCw20BalanceResponse() {
    return { field: [] };
}
export const GetCw20BalanceResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.field) {
            WasmCw20Balance.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCw20BalanceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.field.push(WasmCw20Balance.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { field: Array.isArray(object?.field) ? object.field.map((e) => WasmCw20Balance.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.field) {
            obj.field = message.field.map((e) => e ? WasmCw20Balance.toJSON(e) : undefined);
        }
        else {
            obj.field = [];
        }
        return obj;
    },
    create(base) {
        return GetCw20BalanceResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetCw20BalanceResponse();
        message.field = object.field?.map((e) => WasmCw20Balance.fromPartial(e)) || [];
        return message;
    },
};
function createBaseWasmCw20Balance() {
    return { contractAddress: "", account: "", balance: "", updatedAt: "0", cw20Metadata: undefined };
}
export const WasmCw20Balance = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.contractAddress !== "") {
            writer.uint32(10).string(message.contractAddress);
        }
        if (message.account !== "") {
            writer.uint32(18).string(message.account);
        }
        if (message.balance !== "") {
            writer.uint32(26).string(message.balance);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(32).sint64(message.updatedAt);
        }
        if (message.cw20Metadata !== undefined) {
            Cw20Metadata.encode(message.cw20Metadata, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWasmCw20Balance();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.contractAddress = reader.string();
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.balance = reader.string();
                    break;
                case 4:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 5:
                    message.cw20Metadata = Cw20Metadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            account: isSet(object.account) ? String(object.account) : "",
            balance: isSet(object.balance) ? String(object.balance) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            cw20Metadata: isSet(object.cw20Metadata) ? Cw20Metadata.fromJSON(object.cw20Metadata) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.account !== undefined && (obj.account = message.account);
        message.balance !== undefined && (obj.balance = message.balance);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.cw20Metadata !== undefined &&
            (obj.cw20Metadata = message.cw20Metadata ? Cw20Metadata.toJSON(message.cw20Metadata) : undefined);
        return obj;
    },
    create(base) {
        return WasmCw20Balance.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseWasmCw20Balance();
        message.contractAddress = object.contractAddress ?? "";
        message.account = object.account ?? "";
        message.balance = object.balance ?? "";
        message.updatedAt = object.updatedAt ?? "0";
        message.cw20Metadata = (object.cw20Metadata !== undefined && object.cw20Metadata !== null)
            ? Cw20Metadata.fromPartial(object.cw20Metadata)
            : undefined;
        return message;
    },
};
function createBaseRelayersRequest() {
    return { marketIDs: [] };
}
export const RelayersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIDs) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRelayersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIDs.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIDs: Array.isArray(object?.marketIDs) ? object.marketIDs.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIDs) {
            obj.marketIDs = message.marketIDs.map((e) => e);
        }
        else {
            obj.marketIDs = [];
        }
        return obj;
    },
    create(base) {
        return RelayersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRelayersRequest();
        message.marketIDs = object.marketIDs?.map((e) => e) || [];
        return message;
    },
};
function createBaseRelayersResponse() {
    return { field: [] };
}
export const RelayersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.field) {
            RelayerMarkets.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRelayersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.field.push(RelayerMarkets.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { field: Array.isArray(object?.field) ? object.field.map((e) => RelayerMarkets.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.field) {
            obj.field = message.field.map((e) => e ? RelayerMarkets.toJSON(e) : undefined);
        }
        else {
            obj.field = [];
        }
        return obj;
    },
    create(base) {
        return RelayersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRelayersResponse();
        message.field = object.field?.map((e) => RelayerMarkets.fromPartial(e)) || [];
        return message;
    },
};
function createBaseRelayerMarkets() {
    return { marketId: "", relayers: [] };
}
export const RelayerMarkets = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        for (const v of message.relayers) {
            Relayer.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRelayerMarkets();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.relayers.push(Relayer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => Relayer.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e ? Relayer.toJSON(e) : undefined);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return RelayerMarkets.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRelayerMarkets();
        message.marketId = object.marketId ?? "";
        message.relayers = object.relayers?.map((e) => Relayer.fromPartial(e)) || [];
        return message;
    },
};
function createBaseRelayer() {
    return { name: "", cta: "" };
}
export const Relayer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.cta !== "") {
            writer.uint32(18).string(message.cta);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRelayer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.cta = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { name: isSet(object.name) ? String(object.name) : "", cta: isSet(object.cta) ? String(object.cta) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.cta !== undefined && (obj.cta = message.cta);
        return obj;
    },
    create(base) {
        return Relayer.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRelayer();
        message.name = object.name ?? "";
        message.cta = object.cta ?? "";
        return message;
    },
};
function createBaseGetBankTransfersRequest() {
    return {
        senders: [],
        recipients: [],
        isCommunityPoolRelated: false,
        limit: 0,
        skip: "0",
        startTime: "0",
        endTime: "0",
        address: [],
        perPage: 0,
        token: "",
    };
}
export const GetBankTransfersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.senders) {
            writer.uint32(10).string(v);
        }
        for (const v of message.recipients) {
            writer.uint32(18).string(v);
        }
        if (message.isCommunityPoolRelated === true) {
            writer.uint32(24).bool(message.isCommunityPoolRelated);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.startTime !== "0") {
            writer.uint32(48).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(56).sint64(message.endTime);
        }
        for (const v of message.address) {
            writer.uint32(66).string(v);
        }
        if (message.perPage !== 0) {
            writer.uint32(72).sint32(message.perPage);
        }
        if (message.token !== "") {
            writer.uint32(82).string(message.token);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBankTransfersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.senders.push(reader.string());
                    break;
                case 2:
                    message.recipients.push(reader.string());
                    break;
                case 3:
                    message.isCommunityPoolRelated = reader.bool();
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 7:
                    message.endTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.address.push(reader.string());
                    break;
                case 9:
                    message.perPage = reader.sint32();
                    break;
                case 10:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            senders: Array.isArray(object?.senders) ? object.senders.map((e) => String(e)) : [],
            recipients: Array.isArray(object?.recipients) ? object.recipients.map((e) => String(e)) : [],
            isCommunityPoolRelated: isSet(object.isCommunityPoolRelated) ? Boolean(object.isCommunityPoolRelated) : false,
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
            address: Array.isArray(object?.address) ? object.address.map((e) => String(e)) : [],
            perPage: isSet(object.perPage) ? Number(object.perPage) : 0,
            token: isSet(object.token) ? String(object.token) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.senders) {
            obj.senders = message.senders.map((e) => e);
        }
        else {
            obj.senders = [];
        }
        if (message.recipients) {
            obj.recipients = message.recipients.map((e) => e);
        }
        else {
            obj.recipients = [];
        }
        message.isCommunityPoolRelated !== undefined && (obj.isCommunityPoolRelated = message.isCommunityPoolRelated);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        if (message.address) {
            obj.address = message.address.map((e) => e);
        }
        else {
            obj.address = [];
        }
        message.perPage !== undefined && (obj.perPage = Math.round(message.perPage));
        message.token !== undefined && (obj.token = message.token);
        return obj;
    },
    create(base) {
        return GetBankTransfersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBankTransfersRequest();
        message.senders = object.senders?.map((e) => e) || [];
        message.recipients = object.recipients?.map((e) => e) || [];
        message.isCommunityPoolRelated = object.isCommunityPoolRelated ?? false;
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        message.address = object.address?.map((e) => e) || [];
        message.perPage = object.perPage ?? 0;
        message.token = object.token ?? "";
        return message;
    },
};
function createBaseGetBankTransfersResponse() {
    return { paging: undefined, data: [] };
}
export const GetBankTransfersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.data) {
            BankTransfer.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBankTransfersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.data.push(BankTransfer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            data: Array.isArray(object?.data) ? object.data.map((e) => BankTransfer.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        if (message.data) {
            obj.data = message.data.map((e) => e ? BankTransfer.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return GetBankTransfersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBankTransfersResponse();
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.data = object.data?.map((e) => BankTransfer.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBankTransfer() {
    return { sender: "", recipient: "", amounts: [], blockNumber: "0", blockTimestamp: "" };
}
export const BankTransfer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.recipient !== "") {
            writer.uint32(18).string(message.recipient);
        }
        for (const v of message.amounts) {
            Coin.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.blockNumber !== "0") {
            writer.uint32(32).uint64(message.blockNumber);
        }
        if (message.blockTimestamp !== "") {
            writer.uint32(42).string(message.blockTimestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBankTransfer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.recipient = reader.string();
                    break;
                case 3:
                    message.amounts.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 5:
                    message.blockTimestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            recipient: isSet(object.recipient) ? String(object.recipient) : "",
            amounts: Array.isArray(object?.amounts) ? object.amounts.map((e) => Coin.fromJSON(e)) : [],
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.recipient !== undefined && (obj.recipient = message.recipient);
        if (message.amounts) {
            obj.amounts = message.amounts.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.amounts = [];
        }
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        return obj;
    },
    create(base) {
        return BankTransfer.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBankTransfer();
        message.sender = object.sender ?? "";
        message.recipient = object.recipient ?? "";
        message.amounts = object.amounts?.map((e) => Coin.fromPartial(e)) || [];
        message.blockNumber = object.blockNumber ?? "0";
        message.blockTimestamp = object.blockTimestamp ?? "";
        return message;
    },
};
function createBaseCoin() {
    return { denom: "", amount: "" };
}
export const Coin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return Coin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCoin();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseStreamTxsRequest() {
    return {};
}
export const StreamTxsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTxsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return StreamTxsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseStreamTxsRequest();
        return message;
    },
};
function createBaseStreamTxsResponse() {
    return {
        id: "",
        blockNumber: "0",
        blockTimestamp: "",
        hash: "",
        codespace: "",
        messages: "",
        txNumber: "0",
        errorLog: "",
        code: 0,
        claimIds: [],
    };
}
export const StreamTxsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.blockNumber !== "0") {
            writer.uint32(16).uint64(message.blockNumber);
        }
        if (message.blockTimestamp !== "") {
            writer.uint32(26).string(message.blockTimestamp);
        }
        if (message.hash !== "") {
            writer.uint32(34).string(message.hash);
        }
        if (message.codespace !== "") {
            writer.uint32(42).string(message.codespace);
        }
        if (message.messages !== "") {
            writer.uint32(50).string(message.messages);
        }
        if (message.txNumber !== "0") {
            writer.uint32(56).uint64(message.txNumber);
        }
        if (message.errorLog !== "") {
            writer.uint32(66).string(message.errorLog);
        }
        if (message.code !== 0) {
            writer.uint32(72).uint32(message.code);
        }
        writer.uint32(82).fork();
        for (const v of message.claimIds) {
            writer.sint64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamTxsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.blockNumber = longToString(reader.uint64());
                    break;
                case 3:
                    message.blockTimestamp = reader.string();
                    break;
                case 4:
                    message.hash = reader.string();
                    break;
                case 5:
                    message.codespace = reader.string();
                    break;
                case 6:
                    message.messages = reader.string();
                    break;
                case 7:
                    message.txNumber = longToString(reader.uint64());
                    break;
                case 8:
                    message.errorLog = reader.string();
                    break;
                case 9:
                    message.code = reader.uint32();
                    break;
                case 10:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.claimIds.push(longToString(reader.sint64()));
                        }
                    }
                    else {
                        message.claimIds.push(longToString(reader.sint64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            blockNumber: isSet(object.blockNumber) ? String(object.blockNumber) : "0",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "",
            hash: isSet(object.hash) ? String(object.hash) : "",
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            messages: isSet(object.messages) ? String(object.messages) : "",
            txNumber: isSet(object.txNumber) ? String(object.txNumber) : "0",
            errorLog: isSet(object.errorLog) ? String(object.errorLog) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            claimIds: Array.isArray(object?.claimIds) ? object.claimIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.blockNumber !== undefined && (obj.blockNumber = message.blockNumber);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        message.hash !== undefined && (obj.hash = message.hash);
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.messages !== undefined && (obj.messages = message.messages);
        message.txNumber !== undefined && (obj.txNumber = message.txNumber);
        message.errorLog !== undefined && (obj.errorLog = message.errorLog);
        message.code !== undefined && (obj.code = Math.round(message.code));
        if (message.claimIds) {
            obj.claimIds = message.claimIds.map((e) => e);
        }
        else {
            obj.claimIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamTxsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamTxsResponse();
        message.id = object.id ?? "";
        message.blockNumber = object.blockNumber ?? "0";
        message.blockTimestamp = object.blockTimestamp ?? "";
        message.hash = object.hash ?? "";
        message.codespace = object.codespace ?? "";
        message.messages = object.messages ?? "";
        message.txNumber = object.txNumber ?? "0";
        message.errorLog = object.errorLog ?? "";
        message.code = object.code ?? 0;
        message.claimIds = object.claimIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamBlocksRequest() {
    return {};
}
export const StreamBlocksRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamBlocksRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return StreamBlocksRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseStreamBlocksRequest();
        return message;
    },
};
function createBaseStreamBlocksResponse() {
    return {
        height: "0",
        proposer: "",
        moniker: "",
        blockHash: "",
        parentHash: "",
        numPreCommits: "0",
        numTxs: "0",
        txs: [],
        timestamp: "",
    };
}
export const StreamBlocksResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).uint64(message.height);
        }
        if (message.proposer !== "") {
            writer.uint32(18).string(message.proposer);
        }
        if (message.moniker !== "") {
            writer.uint32(26).string(message.moniker);
        }
        if (message.blockHash !== "") {
            writer.uint32(34).string(message.blockHash);
        }
        if (message.parentHash !== "") {
            writer.uint32(42).string(message.parentHash);
        }
        if (message.numPreCommits !== "0") {
            writer.uint32(48).sint64(message.numPreCommits);
        }
        if (message.numTxs !== "0") {
            writer.uint32(56).sint64(message.numTxs);
        }
        for (const v of message.txs) {
            TxDataRPC.encode(v, writer.uint32(66).fork()).ldelim();
        }
        if (message.timestamp !== "") {
            writer.uint32(74).string(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamBlocksResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.uint64());
                    break;
                case 2:
                    message.proposer = reader.string();
                    break;
                case 3:
                    message.moniker = reader.string();
                    break;
                case 4:
                    message.blockHash = reader.string();
                    break;
                case 5:
                    message.parentHash = reader.string();
                    break;
                case 6:
                    message.numPreCommits = longToString(reader.sint64());
                    break;
                case 7:
                    message.numTxs = longToString(reader.sint64());
                    break;
                case 8:
                    message.txs.push(TxDataRPC.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.timestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            proposer: isSet(object.proposer) ? String(object.proposer) : "",
            moniker: isSet(object.moniker) ? String(object.moniker) : "",
            blockHash: isSet(object.blockHash) ? String(object.blockHash) : "",
            parentHash: isSet(object.parentHash) ? String(object.parentHash) : "",
            numPreCommits: isSet(object.numPreCommits) ? String(object.numPreCommits) : "0",
            numTxs: isSet(object.numTxs) ? String(object.numTxs) : "0",
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => TxDataRPC.fromJSON(e)) : [],
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.proposer !== undefined && (obj.proposer = message.proposer);
        message.moniker !== undefined && (obj.moniker = message.moniker);
        message.blockHash !== undefined && (obj.blockHash = message.blockHash);
        message.parentHash !== undefined && (obj.parentHash = message.parentHash);
        message.numPreCommits !== undefined && (obj.numPreCommits = message.numPreCommits);
        message.numTxs !== undefined && (obj.numTxs = message.numTxs);
        if (message.txs) {
            obj.txs = message.txs.map((e) => e ? TxDataRPC.toJSON(e) : undefined);
        }
        else {
            obj.txs = [];
        }
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamBlocksResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamBlocksResponse();
        message.height = object.height ?? "0";
        message.proposer = object.proposer ?? "";
        message.moniker = object.moniker ?? "";
        message.blockHash = object.blockHash ?? "";
        message.parentHash = object.parentHash ?? "";
        message.numPreCommits = object.numPreCommits ?? "0";
        message.numTxs = object.numTxs ?? "0";
        message.txs = object.txs?.map((e) => TxDataRPC.fromPartial(e)) || [];
        message.timestamp = object.timestamp ?? "";
        return message;
    },
};
function createBaseGetStatsRequest() {
    return {};
}
export const GetStatsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetStatsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return GetStatsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseGetStatsRequest();
        return message;
    },
};
function createBaseGetStatsResponse() {
    return {
        addresses: "0",
        assets: "0",
        injSupply: "0",
        txsPs24H: "0",
        txsPs100B: "0",
        txsTotal: "0",
        txs24H: "0",
        txs30D: "0",
        blockCount24H: "0",
    };
}
export const GetStatsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.addresses !== "0") {
            writer.uint32(8).uint64(message.addresses);
        }
        if (message.assets !== "0") {
            writer.uint32(16).uint64(message.assets);
        }
        if (message.injSupply !== "0") {
            writer.uint32(24).uint64(message.injSupply);
        }
        if (message.txsPs24H !== "0") {
            writer.uint32(32).uint64(message.txsPs24H);
        }
        if (message.txsPs100B !== "0") {
            writer.uint32(40).uint64(message.txsPs100B);
        }
        if (message.txsTotal !== "0") {
            writer.uint32(48).uint64(message.txsTotal);
        }
        if (message.txs24H !== "0") {
            writer.uint32(56).uint64(message.txs24H);
        }
        if (message.txs30D !== "0") {
            writer.uint32(64).uint64(message.txs30D);
        }
        if (message.blockCount24H !== "0") {
            writer.uint32(72).uint64(message.blockCount24H);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetStatsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addresses = longToString(reader.uint64());
                    break;
                case 2:
                    message.assets = longToString(reader.uint64());
                    break;
                case 3:
                    message.injSupply = longToString(reader.uint64());
                    break;
                case 4:
                    message.txsPs24H = longToString(reader.uint64());
                    break;
                case 5:
                    message.txsPs100B = longToString(reader.uint64());
                    break;
                case 6:
                    message.txsTotal = longToString(reader.uint64());
                    break;
                case 7:
                    message.txs24H = longToString(reader.uint64());
                    break;
                case 8:
                    message.txs30D = longToString(reader.uint64());
                    break;
                case 9:
                    message.blockCount24H = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            addresses: isSet(object.addresses) ? String(object.addresses) : "0",
            assets: isSet(object.assets) ? String(object.assets) : "0",
            injSupply: isSet(object.injSupply) ? String(object.injSupply) : "0",
            txsPs24H: isSet(object.txsPs24H) ? String(object.txsPs24H) : "0",
            txsPs100B: isSet(object.txsPs100B) ? String(object.txsPs100B) : "0",
            txsTotal: isSet(object.txsTotal) ? String(object.txsTotal) : "0",
            txs24H: isSet(object.txs24H) ? String(object.txs24H) : "0",
            txs30D: isSet(object.txs30D) ? String(object.txs30D) : "0",
            blockCount24H: isSet(object.blockCount24H) ? String(object.blockCount24H) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.addresses !== undefined && (obj.addresses = message.addresses);
        message.assets !== undefined && (obj.assets = message.assets);
        message.injSupply !== undefined && (obj.injSupply = message.injSupply);
        message.txsPs24H !== undefined && (obj.txsPs24H = message.txsPs24H);
        message.txsPs100B !== undefined && (obj.txsPs100B = message.txsPs100B);
        message.txsTotal !== undefined && (obj.txsTotal = message.txsTotal);
        message.txs24H !== undefined && (obj.txs24H = message.txs24H);
        message.txs30D !== undefined && (obj.txs30D = message.txs30D);
        message.blockCount24H !== undefined && (obj.blockCount24H = message.blockCount24H);
        return obj;
    },
    create(base) {
        return GetStatsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetStatsResponse();
        message.addresses = object.addresses ?? "0";
        message.assets = object.assets ?? "0";
        message.injSupply = object.injSupply ?? "0";
        message.txsPs24H = object.txsPs24H ?? "0";
        message.txsPs100B = object.txsPs100B ?? "0";
        message.txsTotal = object.txsTotal ?? "0";
        message.txs24H = object.txs24H ?? "0";
        message.txs30D = object.txs30D ?? "0";
        message.blockCount24H = object.blockCount24H ?? "0";
        return message;
    },
};
export class InjectiveExplorerRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.GetAccountTxs = this.GetAccountTxs.bind(this);
        this.GetContractTxs = this.GetContractTxs.bind(this);
        this.GetContractTxsV2 = this.GetContractTxsV2.bind(this);
        this.GetBlocks = this.GetBlocks.bind(this);
        this.GetBlock = this.GetBlock.bind(this);
        this.GetValidators = this.GetValidators.bind(this);
        this.GetValidator = this.GetValidator.bind(this);
        this.GetValidatorUptime = this.GetValidatorUptime.bind(this);
        this.GetTxs = this.GetTxs.bind(this);
        this.GetTxByTxHash = this.GetTxByTxHash.bind(this);
        this.GetPeggyDepositTxs = this.GetPeggyDepositTxs.bind(this);
        this.GetPeggyWithdrawalTxs = this.GetPeggyWithdrawalTxs.bind(this);
        this.GetIBCTransferTxs = this.GetIBCTransferTxs.bind(this);
        this.GetWasmCodes = this.GetWasmCodes.bind(this);
        this.GetWasmCodeByID = this.GetWasmCodeByID.bind(this);
        this.GetWasmContracts = this.GetWasmContracts.bind(this);
        this.GetWasmContractByAddress = this.GetWasmContractByAddress.bind(this);
        this.GetCw20Balance = this.GetCw20Balance.bind(this);
        this.Relayers = this.Relayers.bind(this);
        this.GetBankTransfers = this.GetBankTransfers.bind(this);
        this.StreamTxs = this.StreamTxs.bind(this);
        this.StreamBlocks = this.StreamBlocks.bind(this);
        this.GetStats = this.GetStats.bind(this);
    }
    GetAccountTxs(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetAccountTxsDesc, GetAccountTxsRequest.fromPartial(request), metadata);
    }
    GetContractTxs(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetContractTxsDesc, GetContractTxsRequest.fromPartial(request), metadata);
    }
    GetContractTxsV2(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetContractTxsV2Desc, GetContractTxsV2Request.fromPartial(request), metadata);
    }
    GetBlocks(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetBlocksDesc, GetBlocksRequest.fromPartial(request), metadata);
    }
    GetBlock(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetBlockDesc, GetBlockRequest.fromPartial(request), metadata);
    }
    GetValidators(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetValidatorsDesc, GetValidatorsRequest.fromPartial(request), metadata);
    }
    GetValidator(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetValidatorDesc, GetValidatorRequest.fromPartial(request), metadata);
    }
    GetValidatorUptime(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetValidatorUptimeDesc, GetValidatorUptimeRequest.fromPartial(request), metadata);
    }
    GetTxs(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetTxsDesc, GetTxsRequest.fromPartial(request), metadata);
    }
    GetTxByTxHash(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetTxByTxHashDesc, GetTxByTxHashRequest.fromPartial(request), metadata);
    }
    GetPeggyDepositTxs(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetPeggyDepositTxsDesc, GetPeggyDepositTxsRequest.fromPartial(request), metadata);
    }
    GetPeggyWithdrawalTxs(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetPeggyWithdrawalTxsDesc, GetPeggyWithdrawalTxsRequest.fromPartial(request), metadata);
    }
    GetIBCTransferTxs(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetIBCTransferTxsDesc, GetIBCTransferTxsRequest.fromPartial(request), metadata);
    }
    GetWasmCodes(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetWasmCodesDesc, GetWasmCodesRequest.fromPartial(request), metadata);
    }
    GetWasmCodeByID(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetWasmCodeByIDDesc, GetWasmCodeByIDRequest.fromPartial(request), metadata);
    }
    GetWasmContracts(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetWasmContractsDesc, GetWasmContractsRequest.fromPartial(request), metadata);
    }
    GetWasmContractByAddress(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetWasmContractByAddressDesc, GetWasmContractByAddressRequest.fromPartial(request), metadata);
    }
    GetCw20Balance(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetCw20BalanceDesc, GetCw20BalanceRequest.fromPartial(request), metadata);
    }
    Relayers(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCRelayersDesc, RelayersRequest.fromPartial(request), metadata);
    }
    GetBankTransfers(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetBankTransfersDesc, GetBankTransfersRequest.fromPartial(request), metadata);
    }
    StreamTxs(request, metadata) {
        return this.rpc.invoke(InjectiveExplorerRPCStreamTxsDesc, StreamTxsRequest.fromPartial(request), metadata);
    }
    StreamBlocks(request, metadata) {
        return this.rpc.invoke(InjectiveExplorerRPCStreamBlocksDesc, StreamBlocksRequest.fromPartial(request), metadata);
    }
    GetStats(request, metadata) {
        return this.rpc.unary(InjectiveExplorerRPCGetStatsDesc, GetStatsRequest.fromPartial(request), metadata);
    }
}
export const InjectiveExplorerRPCDesc = { serviceName: "injective_explorer_rpc.InjectiveExplorerRPC" };
export const InjectiveExplorerRPCGetAccountTxsDesc = {
    methodName: "GetAccountTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetAccountTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetAccountTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetContractTxsDesc = {
    methodName: "GetContractTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetContractTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetContractTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetContractTxsV2Desc = {
    methodName: "GetContractTxsV2",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetContractTxsV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetContractTxsV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetBlocksDesc = {
    methodName: "GetBlocks",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetBlocksRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetBlocksResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetBlockDesc = {
    methodName: "GetBlock",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetBlockRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetBlockResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetValidatorsDesc = {
    methodName: "GetValidators",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetValidatorsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetValidatorsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetValidatorDesc = {
    methodName: "GetValidator",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetValidatorRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetValidatorResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetValidatorUptimeDesc = {
    methodName: "GetValidatorUptime",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetValidatorUptimeRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetValidatorUptimeResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetTxsDesc = {
    methodName: "GetTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetTxByTxHashDesc = {
    methodName: "GetTxByTxHash",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetTxByTxHashRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetTxByTxHashResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetPeggyDepositTxsDesc = {
    methodName: "GetPeggyDepositTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetPeggyDepositTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetPeggyDepositTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetPeggyWithdrawalTxsDesc = {
    methodName: "GetPeggyWithdrawalTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetPeggyWithdrawalTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetPeggyWithdrawalTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetIBCTransferTxsDesc = {
    methodName: "GetIBCTransferTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetIBCTransferTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetIBCTransferTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetWasmCodesDesc = {
    methodName: "GetWasmCodes",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetWasmCodesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetWasmCodesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetWasmCodeByIDDesc = {
    methodName: "GetWasmCodeByID",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetWasmCodeByIDRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetWasmCodeByIDResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetWasmContractsDesc = {
    methodName: "GetWasmContracts",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetWasmContractsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetWasmContractsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetWasmContractByAddressDesc = {
    methodName: "GetWasmContractByAddress",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetWasmContractByAddressRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetWasmContractByAddressResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetCw20BalanceDesc = {
    methodName: "GetCw20Balance",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetCw20BalanceRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetCw20BalanceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCRelayersDesc = {
    methodName: "Relayers",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return RelayersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = RelayersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetBankTransfersDesc = {
    methodName: "GetBankTransfers",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetBankTransfersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetBankTransfersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCStreamTxsDesc = {
    methodName: "StreamTxs",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamTxsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamTxsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCStreamBlocksDesc = {
    methodName: "StreamBlocks",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamBlocksRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamBlocksResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveExplorerRPCGetStatsDesc = {
    methodName: "GetStats",
    service: InjectiveExplorerRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetStatsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetStatsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => {
                    if (!observer.closed) {
                        return client.close();
                    }
                });
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
