/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { EpochAndRound, FeedConfig, Params, Transmission } from "./ocr.js";
export const protobufPackage = "injective.ocr.v1beta1";
function createBaseGenesisState() {
    return {
        params: undefined,
        feedConfigs: [],
        latestEpochAndRounds: [],
        feedTransmissions: [],
        latestAggregatorRoundIds: [],
        rewardPools: [],
        feedObservationCounts: [],
        feedTransmissionCounts: [],
        pendingPayeeships: [],
    };
}
export const GenesisState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.feedConfigs) {
            FeedConfig.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.latestEpochAndRounds) {
            FeedEpochAndRound.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.feedTransmissions) {
            FeedTransmission.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.latestAggregatorRoundIds) {
            FeedLatestAggregatorRoundIDs.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.rewardPools) {
            RewardPool.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.feedObservationCounts) {
            FeedCounts.encode(v, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.feedTransmissionCounts) {
            FeedCounts.encode(v, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.pendingPayeeships) {
            PendingPayeeship.encode(v, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenesisState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.feedConfigs.push(FeedConfig.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.latestEpochAndRounds.push(FeedEpochAndRound.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.feedTransmissions.push(FeedTransmission.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.latestAggregatorRoundIds.push(FeedLatestAggregatorRoundIDs.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.rewardPools.push(RewardPool.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.feedObservationCounts.push(FeedCounts.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.feedTransmissionCounts.push(FeedCounts.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.pendingPayeeships.push(PendingPayeeship.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
            feedConfigs: Array.isArray(object?.feedConfigs) ? object.feedConfigs.map((e) => FeedConfig.fromJSON(e)) : [],
            latestEpochAndRounds: Array.isArray(object?.latestEpochAndRounds)
                ? object.latestEpochAndRounds.map((e) => FeedEpochAndRound.fromJSON(e))
                : [],
            feedTransmissions: Array.isArray(object?.feedTransmissions)
                ? object.feedTransmissions.map((e) => FeedTransmission.fromJSON(e))
                : [],
            latestAggregatorRoundIds: Array.isArray(object?.latestAggregatorRoundIds)
                ? object.latestAggregatorRoundIds.map((e) => FeedLatestAggregatorRoundIDs.fromJSON(e))
                : [],
            rewardPools: Array.isArray(object?.rewardPools) ? object.rewardPools.map((e) => RewardPool.fromJSON(e)) : [],
            feedObservationCounts: Array.isArray(object?.feedObservationCounts)
                ? object.feedObservationCounts.map((e) => FeedCounts.fromJSON(e))
                : [],
            feedTransmissionCounts: Array.isArray(object?.feedTransmissionCounts)
                ? object.feedTransmissionCounts.map((e) => FeedCounts.fromJSON(e))
                : [],
            pendingPayeeships: Array.isArray(object?.pendingPayeeships)
                ? object.pendingPayeeships.map((e) => PendingPayeeship.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        if (message.feedConfigs) {
            obj.feedConfigs = message.feedConfigs.map((e) => e ? FeedConfig.toJSON(e) : undefined);
        }
        else {
            obj.feedConfigs = [];
        }
        if (message.latestEpochAndRounds) {
            obj.latestEpochAndRounds = message.latestEpochAndRounds.map((e) => e ? FeedEpochAndRound.toJSON(e) : undefined);
        }
        else {
            obj.latestEpochAndRounds = [];
        }
        if (message.feedTransmissions) {
            obj.feedTransmissions = message.feedTransmissions.map((e) => e ? FeedTransmission.toJSON(e) : undefined);
        }
        else {
            obj.feedTransmissions = [];
        }
        if (message.latestAggregatorRoundIds) {
            obj.latestAggregatorRoundIds = message.latestAggregatorRoundIds.map((e) => e ? FeedLatestAggregatorRoundIDs.toJSON(e) : undefined);
        }
        else {
            obj.latestAggregatorRoundIds = [];
        }
        if (message.rewardPools) {
            obj.rewardPools = message.rewardPools.map((e) => e ? RewardPool.toJSON(e) : undefined);
        }
        else {
            obj.rewardPools = [];
        }
        if (message.feedObservationCounts) {
            obj.feedObservationCounts = message.feedObservationCounts.map((e) => e ? FeedCounts.toJSON(e) : undefined);
        }
        else {
            obj.feedObservationCounts = [];
        }
        if (message.feedTransmissionCounts) {
            obj.feedTransmissionCounts = message.feedTransmissionCounts.map((e) => e ? FeedCounts.toJSON(e) : undefined);
        }
        else {
            obj.feedTransmissionCounts = [];
        }
        if (message.pendingPayeeships) {
            obj.pendingPayeeships = message.pendingPayeeships.map((e) => e ? PendingPayeeship.toJSON(e) : undefined);
        }
        else {
            obj.pendingPayeeships = [];
        }
        return obj;
    },
    create(base) {
        return GenesisState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGenesisState();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        message.feedConfigs = object.feedConfigs?.map((e) => FeedConfig.fromPartial(e)) || [];
        message.latestEpochAndRounds = object.latestEpochAndRounds?.map((e) => FeedEpochAndRound.fromPartial(e)) || [];
        message.feedTransmissions = object.feedTransmissions?.map((e) => FeedTransmission.fromPartial(e)) || [];
        message.latestAggregatorRoundIds =
            object.latestAggregatorRoundIds?.map((e) => FeedLatestAggregatorRoundIDs.fromPartial(e)) || [];
        message.rewardPools = object.rewardPools?.map((e) => RewardPool.fromPartial(e)) || [];
        message.feedObservationCounts = object.feedObservationCounts?.map((e) => FeedCounts.fromPartial(e)) || [];
        message.feedTransmissionCounts = object.feedTransmissionCounts?.map((e) => FeedCounts.fromPartial(e)) || [];
        message.pendingPayeeships = object.pendingPayeeships?.map((e) => PendingPayeeship.fromPartial(e)) || [];
        return message;
    },
};
function createBaseFeedTransmission() {
    return { feedId: "", transmission: undefined };
}
export const FeedTransmission = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.transmission !== undefined) {
            Transmission.encode(message.transmission, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeedTransmission();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.transmission = Transmission.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            transmission: isSet(object.transmission) ? Transmission.fromJSON(object.transmission) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.transmission !== undefined &&
            (obj.transmission = message.transmission ? Transmission.toJSON(message.transmission) : undefined);
        return obj;
    },
    create(base) {
        return FeedTransmission.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeedTransmission();
        message.feedId = object.feedId ?? "";
        message.transmission = (object.transmission !== undefined && object.transmission !== null)
            ? Transmission.fromPartial(object.transmission)
            : undefined;
        return message;
    },
};
function createBaseFeedEpochAndRound() {
    return { feedId: "", epochAndRound: undefined };
}
export const FeedEpochAndRound = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.epochAndRound !== undefined) {
            EpochAndRound.encode(message.epochAndRound, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeedEpochAndRound();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.epochAndRound = EpochAndRound.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            epochAndRound: isSet(object.epochAndRound) ? EpochAndRound.fromJSON(object.epochAndRound) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.epochAndRound !== undefined &&
            (obj.epochAndRound = message.epochAndRound ? EpochAndRound.toJSON(message.epochAndRound) : undefined);
        return obj;
    },
    create(base) {
        return FeedEpochAndRound.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeedEpochAndRound();
        message.feedId = object.feedId ?? "";
        message.epochAndRound = (object.epochAndRound !== undefined && object.epochAndRound !== null)
            ? EpochAndRound.fromPartial(object.epochAndRound)
            : undefined;
        return message;
    },
};
function createBaseFeedLatestAggregatorRoundIDs() {
    return { feedId: "", aggregatorRoundId: "0" };
}
export const FeedLatestAggregatorRoundIDs = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.aggregatorRoundId !== "0") {
            writer.uint32(16).uint64(message.aggregatorRoundId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeedLatestAggregatorRoundIDs();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.aggregatorRoundId = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            aggregatorRoundId: isSet(object.aggregatorRoundId) ? String(object.aggregatorRoundId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.aggregatorRoundId !== undefined && (obj.aggregatorRoundId = message.aggregatorRoundId);
        return obj;
    },
    create(base) {
        return FeedLatestAggregatorRoundIDs.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeedLatestAggregatorRoundIDs();
        message.feedId = object.feedId ?? "";
        message.aggregatorRoundId = object.aggregatorRoundId ?? "0";
        return message;
    },
};
function createBaseRewardPool() {
    return { feedId: "", amount: undefined };
}
export const RewardPool = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRewardPool();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return RewardPool.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRewardPool();
        message.feedId = object.feedId ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseFeedCounts() {
    return { feedId: "", counts: [] };
}
export const FeedCounts = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        for (const v of message.counts) {
            Count.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeedCounts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.counts.push(Count.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            counts: Array.isArray(object?.counts) ? object.counts.map((e) => Count.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        if (message.counts) {
            obj.counts = message.counts.map((e) => e ? Count.toJSON(e) : undefined);
        }
        else {
            obj.counts = [];
        }
        return obj;
    },
    create(base) {
        return FeedCounts.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeedCounts();
        message.feedId = object.feedId ?? "";
        message.counts = object.counts?.map((e) => Count.fromPartial(e)) || [];
        return message;
    },
};
function createBaseCount() {
    return { address: "", count: "0" };
}
export const Count = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.count !== "0") {
            writer.uint32(16).uint64(message.count);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.count = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            count: isSet(object.count) ? String(object.count) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.count !== undefined && (obj.count = message.count);
        return obj;
    },
    create(base) {
        return Count.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCount();
        message.address = object.address ?? "";
        message.count = object.count ?? "0";
        return message;
    },
};
function createBasePendingPayeeship() {
    return { feedId: "", transmitter: "", proposedPayee: "" };
}
export const PendingPayeeship = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.transmitter !== "") {
            writer.uint32(18).string(message.transmitter);
        }
        if (message.proposedPayee !== "") {
            writer.uint32(26).string(message.proposedPayee);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePendingPayeeship();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.transmitter = reader.string();
                    break;
                case 3:
                    message.proposedPayee = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            transmitter: isSet(object.transmitter) ? String(object.transmitter) : "",
            proposedPayee: isSet(object.proposedPayee) ? String(object.proposedPayee) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.transmitter !== undefined && (obj.transmitter = message.transmitter);
        message.proposedPayee !== undefined && (obj.proposedPayee = message.proposedPayee);
        return obj;
    },
    create(base) {
        return PendingPayeeship.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePendingPayeeship();
        message.feedId = object.feedId ?? "";
        message.transmitter = object.transmitter ?? "";
        message.proposedPayee = object.proposedPayee ?? "";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
