/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { AssetPair, Params, PriceAttestation } from "./oracle.js";
export const protobufPackage = "injective.oracle.v1beta1";
function createBaseMsgRelayProviderPrices() {
    return { sender: "", provider: "", symbols: [], prices: [] };
}
export const MsgRelayProviderPrices = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.provider !== "") {
            writer.uint32(18).string(message.provider);
        }
        for (const v of message.symbols) {
            writer.uint32(26).string(v);
        }
        for (const v of message.prices) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayProviderPrices();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.provider = reader.string();
                    break;
                case 3:
                    message.symbols.push(reader.string());
                    break;
                case 4:
                    message.prices.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            provider: isSet(object.provider) ? String(object.provider) : "",
            symbols: Array.isArray(object?.symbols) ? object.symbols.map((e) => String(e)) : [],
            prices: Array.isArray(object?.prices) ? object.prices.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.provider !== undefined && (obj.provider = message.provider);
        if (message.symbols) {
            obj.symbols = message.symbols.map((e) => e);
        }
        else {
            obj.symbols = [];
        }
        if (message.prices) {
            obj.prices = message.prices.map((e) => e);
        }
        else {
            obj.prices = [];
        }
        return obj;
    },
    create(base) {
        return MsgRelayProviderPrices.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRelayProviderPrices();
        message.sender = object.sender ?? "";
        message.provider = object.provider ?? "";
        message.symbols = object.symbols?.map((e) => e) || [];
        message.prices = object.prices?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgRelayProviderPricesResponse() {
    return {};
}
export const MsgRelayProviderPricesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayProviderPricesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRelayProviderPricesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRelayProviderPricesResponse();
        return message;
    },
};
function createBaseMsgRelayPriceFeedPrice() {
    return { sender: "", base: [], quote: [], price: [] };
}
export const MsgRelayPriceFeedPrice = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.base) {
            writer.uint32(18).string(v);
        }
        for (const v of message.quote) {
            writer.uint32(26).string(v);
        }
        for (const v of message.price) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayPriceFeedPrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.base.push(reader.string());
                    break;
                case 3:
                    message.quote.push(reader.string());
                    break;
                case 4:
                    message.price.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            base: Array.isArray(object?.base) ? object.base.map((e) => String(e)) : [],
            quote: Array.isArray(object?.quote) ? object.quote.map((e) => String(e)) : [],
            price: Array.isArray(object?.price) ? object.price.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.base) {
            obj.base = message.base.map((e) => e);
        }
        else {
            obj.base = [];
        }
        if (message.quote) {
            obj.quote = message.quote.map((e) => e);
        }
        else {
            obj.quote = [];
        }
        if (message.price) {
            obj.price = message.price.map((e) => e);
        }
        else {
            obj.price = [];
        }
        return obj;
    },
    create(base) {
        return MsgRelayPriceFeedPrice.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRelayPriceFeedPrice();
        message.sender = object.sender ?? "";
        message.base = object.base?.map((e) => e) || [];
        message.quote = object.quote?.map((e) => e) || [];
        message.price = object.price?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgRelayPriceFeedPriceResponse() {
    return {};
}
export const MsgRelayPriceFeedPriceResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayPriceFeedPriceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRelayPriceFeedPriceResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRelayPriceFeedPriceResponse();
        return message;
    },
};
function createBaseMsgRelayBandRates() {
    return { relayer: "", symbols: [], rates: [], resolveTimes: [], requestIDs: [] };
}
export const MsgRelayBandRates = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.relayer !== "") {
            writer.uint32(10).string(message.relayer);
        }
        for (const v of message.symbols) {
            writer.uint32(18).string(v);
        }
        writer.uint32(26).fork();
        for (const v of message.rates) {
            writer.uint64(v);
        }
        writer.ldelim();
        writer.uint32(34).fork();
        for (const v of message.resolveTimes) {
            writer.uint64(v);
        }
        writer.ldelim();
        writer.uint32(42).fork();
        for (const v of message.requestIDs) {
            writer.uint64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayBandRates();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.relayer = reader.string();
                    break;
                case 2:
                    message.symbols.push(reader.string());
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.rates.push(longToString(reader.uint64()));
                        }
                    }
                    else {
                        message.rates.push(longToString(reader.uint64()));
                    }
                    break;
                case 4:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.resolveTimes.push(longToString(reader.uint64()));
                        }
                    }
                    else {
                        message.resolveTimes.push(longToString(reader.uint64()));
                    }
                    break;
                case 5:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.requestIDs.push(longToString(reader.uint64()));
                        }
                    }
                    else {
                        message.requestIDs.push(longToString(reader.uint64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            relayer: isSet(object.relayer) ? String(object.relayer) : "",
            symbols: Array.isArray(object?.symbols) ? object.symbols.map((e) => String(e)) : [],
            rates: Array.isArray(object?.rates) ? object.rates.map((e) => String(e)) : [],
            resolveTimes: Array.isArray(object?.resolveTimes) ? object.resolveTimes.map((e) => String(e)) : [],
            requestIDs: Array.isArray(object?.requestIDs) ? object.requestIDs.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.relayer !== undefined && (obj.relayer = message.relayer);
        if (message.symbols) {
            obj.symbols = message.symbols.map((e) => e);
        }
        else {
            obj.symbols = [];
        }
        if (message.rates) {
            obj.rates = message.rates.map((e) => e);
        }
        else {
            obj.rates = [];
        }
        if (message.resolveTimes) {
            obj.resolveTimes = message.resolveTimes.map((e) => e);
        }
        else {
            obj.resolveTimes = [];
        }
        if (message.requestIDs) {
            obj.requestIDs = message.requestIDs.map((e) => e);
        }
        else {
            obj.requestIDs = [];
        }
        return obj;
    },
    create(base) {
        return MsgRelayBandRates.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRelayBandRates();
        message.relayer = object.relayer ?? "";
        message.symbols = object.symbols?.map((e) => e) || [];
        message.rates = object.rates?.map((e) => e) || [];
        message.resolveTimes = object.resolveTimes?.map((e) => e) || [];
        message.requestIDs = object.requestIDs?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgRelayBandRatesResponse() {
    return {};
}
export const MsgRelayBandRatesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayBandRatesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRelayBandRatesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRelayBandRatesResponse();
        return message;
    },
};
function createBaseMsgRelayCoinbaseMessages() {
    return { sender: "", messages: [], signatures: [] };
}
export const MsgRelayCoinbaseMessages = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.messages) {
            writer.uint32(18).bytes(v);
        }
        for (const v of message.signatures) {
            writer.uint32(26).bytes(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayCoinbaseMessages();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.messages.push(reader.bytes());
                    break;
                case 3:
                    message.signatures.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            messages: Array.isArray(object?.messages) ? object.messages.map((e) => bytesFromBase64(e)) : [],
            signatures: Array.isArray(object?.signatures) ? object.signatures.map((e) => bytesFromBase64(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.messages) {
            obj.messages = message.messages.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.messages = [];
        }
        if (message.signatures) {
            obj.signatures = message.signatures.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.signatures = [];
        }
        return obj;
    },
    create(base) {
        return MsgRelayCoinbaseMessages.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRelayCoinbaseMessages();
        message.sender = object.sender ?? "";
        message.messages = object.messages?.map((e) => e) || [];
        message.signatures = object.signatures?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgRelayCoinbaseMessagesResponse() {
    return {};
}
export const MsgRelayCoinbaseMessagesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayCoinbaseMessagesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRelayCoinbaseMessagesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRelayCoinbaseMessagesResponse();
        return message;
    },
};
function createBaseMsgRelayStorkPrices() {
    return { sender: "", assetPairs: [] };
}
export const MsgRelayStorkPrices = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.assetPairs) {
            AssetPair.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayStorkPrices();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.assetPairs.push(AssetPair.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            assetPairs: Array.isArray(object?.assetPairs) ? object.assetPairs.map((e) => AssetPair.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.assetPairs) {
            obj.assetPairs = message.assetPairs.map((e) => e ? AssetPair.toJSON(e) : undefined);
        }
        else {
            obj.assetPairs = [];
        }
        return obj;
    },
    create(base) {
        return MsgRelayStorkPrices.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRelayStorkPrices();
        message.sender = object.sender ?? "";
        message.assetPairs = object.assetPairs?.map((e) => AssetPair.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgRelayStorkPricesResponse() {
    return {};
}
export const MsgRelayStorkPricesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayStorkPricesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRelayStorkPricesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRelayStorkPricesResponse();
        return message;
    },
};
function createBaseMsgRequestBandIBCRates() {
    return { sender: "", requestId: "0" };
}
export const MsgRequestBandIBCRates = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.requestId !== "0") {
            writer.uint32(16).uint64(message.requestId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRequestBandIBCRates();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.requestId = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            requestId: isSet(object.requestId) ? String(object.requestId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.requestId !== undefined && (obj.requestId = message.requestId);
        return obj;
    },
    create(base) {
        return MsgRequestBandIBCRates.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRequestBandIBCRates();
        message.sender = object.sender ?? "";
        message.requestId = object.requestId ?? "0";
        return message;
    },
};
function createBaseMsgRequestBandIBCRatesResponse() {
    return {};
}
export const MsgRequestBandIBCRatesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRequestBandIBCRatesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRequestBandIBCRatesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRequestBandIBCRatesResponse();
        return message;
    },
};
function createBaseMsgRelayPythPrices() {
    return { sender: "", priceAttestations: [] };
}
export const MsgRelayPythPrices = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.priceAttestations) {
            PriceAttestation.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayPythPrices();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.priceAttestations.push(PriceAttestation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            priceAttestations: Array.isArray(object?.priceAttestations)
                ? object.priceAttestations.map((e) => PriceAttestation.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.priceAttestations) {
            obj.priceAttestations = message.priceAttestations.map((e) => e ? PriceAttestation.toJSON(e) : undefined);
        }
        else {
            obj.priceAttestations = [];
        }
        return obj;
    },
    create(base) {
        return MsgRelayPythPrices.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRelayPythPrices();
        message.sender = object.sender ?? "";
        message.priceAttestations = object.priceAttestations?.map((e) => PriceAttestation.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgRelayPythPricesResponse() {
    return {};
}
export const MsgRelayPythPricesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRelayPythPricesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRelayPythPricesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRelayPythPricesResponse();
        return message;
    },
};
function createBaseMsgUpdateParams() {
    return { authority: "", params: undefined };
}
export const MsgUpdateParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.authority !== "") {
            writer.uint32(10).string(message.authority);
        }
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.authority = reader.string();
                    break;
                case 2:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            authority: isSet(object.authority) ? String(object.authority) : "",
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.authority !== undefined && (obj.authority = message.authority);
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return MsgUpdateParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateParams();
        message.authority = object.authority ?? "";
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseMsgUpdateParamsResponse() {
    return {};
}
export const MsgUpdateParamsResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateParamsResponse();
        return message;
    },
};
export class MsgClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.RelayProviderPrices = this.RelayProviderPrices.bind(this);
        this.RelayPriceFeedPrice = this.RelayPriceFeedPrice.bind(this);
        this.RelayBandRates = this.RelayBandRates.bind(this);
        this.RequestBandIBCRates = this.RequestBandIBCRates.bind(this);
        this.RelayCoinbaseMessages = this.RelayCoinbaseMessages.bind(this);
        this.RelayStorkMessage = this.RelayStorkMessage.bind(this);
        this.RelayPythPrices = this.RelayPythPrices.bind(this);
        this.UpdateParams = this.UpdateParams.bind(this);
    }
    RelayProviderPrices(request, metadata) {
        return this.rpc.unary(MsgRelayProviderPricesDesc, MsgRelayProviderPrices.fromPartial(request), metadata);
    }
    RelayPriceFeedPrice(request, metadata) {
        return this.rpc.unary(MsgRelayPriceFeedPriceDesc, MsgRelayPriceFeedPrice.fromPartial(request), metadata);
    }
    RelayBandRates(request, metadata) {
        return this.rpc.unary(MsgRelayBandRatesDesc, MsgRelayBandRates.fromPartial(request), metadata);
    }
    RequestBandIBCRates(request, metadata) {
        return this.rpc.unary(MsgRequestBandIBCRatesDesc, MsgRequestBandIBCRates.fromPartial(request), metadata);
    }
    RelayCoinbaseMessages(request, metadata) {
        return this.rpc.unary(MsgRelayCoinbaseMessagesDesc, MsgRelayCoinbaseMessages.fromPartial(request), metadata);
    }
    RelayStorkMessage(request, metadata) {
        return this.rpc.unary(MsgRelayStorkMessageDesc, MsgRelayStorkPrices.fromPartial(request), metadata);
    }
    RelayPythPrices(request, metadata) {
        return this.rpc.unary(MsgRelayPythPricesDesc, MsgRelayPythPrices.fromPartial(request), metadata);
    }
    UpdateParams(request, metadata) {
        return this.rpc.unary(MsgUpdateParamsDesc, MsgUpdateParams.fromPartial(request), metadata);
    }
}
export const MsgDesc = { serviceName: "injective.oracle.v1beta1.Msg" };
export const MsgRelayProviderPricesDesc = {
    methodName: "RelayProviderPrices",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRelayProviderPrices.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRelayProviderPricesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRelayPriceFeedPriceDesc = {
    methodName: "RelayPriceFeedPrice",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRelayPriceFeedPrice.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRelayPriceFeedPriceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRelayBandRatesDesc = {
    methodName: "RelayBandRates",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRelayBandRates.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRelayBandRatesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRequestBandIBCRatesDesc = {
    methodName: "RequestBandIBCRates",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRequestBandIBCRates.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRequestBandIBCRatesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRelayCoinbaseMessagesDesc = {
    methodName: "RelayCoinbaseMessages",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRelayCoinbaseMessages.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRelayCoinbaseMessagesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRelayStorkMessageDesc = {
    methodName: "RelayStorkMessage",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRelayStorkPrices.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRelayStorkPricesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRelayPythPricesDesc = {
    methodName: "RelayPythPrices",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRelayPythPrices.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRelayPythPricesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateParamsDesc = {
    methodName: "UpdateParams",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateParams.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
