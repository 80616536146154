/* eslint-disable */
import _m0 from "protobufjs/minimal.js";
import { Metadata } from "../../../cosmos/bank/v1beta1/bank.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
export const protobufPackage = "injective.tokenfactory.v1beta1";
function createBaseEventCreateTFDenom() {
    return { account: "", denom: "" };
}
export const EventCreateTFDenom = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventCreateTFDenom();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.denom !== undefined && (obj.denom = message.denom);
        return obj;
    },
    create(base) {
        return EventCreateTFDenom.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventCreateTFDenom();
        message.account = object.account ?? "";
        message.denom = object.denom ?? "";
        return message;
    },
};
function createBaseEventMintTFDenom() {
    return { recipientAddress: "", amount: undefined };
}
export const EventMintTFDenom = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.recipientAddress !== "") {
            writer.uint32(10).string(message.recipientAddress);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventMintTFDenom();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.recipientAddress = reader.string();
                    break;
                case 2:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            recipientAddress: isSet(object.recipientAddress) ? String(object.recipientAddress) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.recipientAddress !== undefined && (obj.recipientAddress = message.recipientAddress);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return EventMintTFDenom.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventMintTFDenom();
        message.recipientAddress = object.recipientAddress ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseEventBurnDenom() {
    return { burnerAddress: "", amount: undefined };
}
export const EventBurnDenom = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.burnerAddress !== "") {
            writer.uint32(10).string(message.burnerAddress);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBurnDenom();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.burnerAddress = reader.string();
                    break;
                case 2:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            burnerAddress: isSet(object.burnerAddress) ? String(object.burnerAddress) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.burnerAddress !== undefined && (obj.burnerAddress = message.burnerAddress);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return EventBurnDenom.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBurnDenom();
        message.burnerAddress = object.burnerAddress ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseEventChangeTFAdmin() {
    return { denom: "", newAdminAddress: "" };
}
export const EventChangeTFAdmin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.newAdminAddress !== "") {
            writer.uint32(18).string(message.newAdminAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventChangeTFAdmin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.newAdminAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            newAdminAddress: isSet(object.newAdminAddress) ? String(object.newAdminAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.newAdminAddress !== undefined && (obj.newAdminAddress = message.newAdminAddress);
        return obj;
    },
    create(base) {
        return EventChangeTFAdmin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventChangeTFAdmin();
        message.denom = object.denom ?? "";
        message.newAdminAddress = object.newAdminAddress ?? "";
        return message;
    },
};
function createBaseEventSetTFDenomMetadata() {
    return { denom: "", metadata: undefined };
}
export const EventSetTFDenomMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.metadata !== undefined) {
            Metadata.encode(message.metadata, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSetTFDenomMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.metadata = Metadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.metadata !== undefined && (obj.metadata = message.metadata ? Metadata.toJSON(message.metadata) : undefined);
        return obj;
    },
    create(base) {
        return EventSetTFDenomMetadata.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSetTFDenomMetadata();
        message.denom = object.denom ?? "";
        message.metadata = (object.metadata !== undefined && object.metadata !== null)
            ? Metadata.fromPartial(object.metadata)
            : undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
