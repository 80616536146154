/* eslint-disable */
import _m0 from "protobufjs/minimal.js";
import { RequestFinalizeBlock, ResponseCommit, ResponseFinalizeBlock } from "../../../tendermint/abci/types.js";
export const protobufPackage = "cosmos.store.v1beta1";
function createBaseStoreKVPair() {
    return { storeKey: "", delete: false, key: new Uint8Array(), value: new Uint8Array() };
}
export const StoreKVPair = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.storeKey !== "") {
            writer.uint32(10).string(message.storeKey);
        }
        if (message.delete === true) {
            writer.uint32(16).bool(message.delete);
        }
        if (message.key.length !== 0) {
            writer.uint32(26).bytes(message.key);
        }
        if (message.value.length !== 0) {
            writer.uint32(34).bytes(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStoreKVPair();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.storeKey = reader.string();
                    break;
                case 2:
                    message.delete = reader.bool();
                    break;
                case 3:
                    message.key = reader.bytes();
                    break;
                case 4:
                    message.value = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            storeKey: isSet(object.storeKey) ? String(object.storeKey) : "",
            delete: isSet(object.delete) ? Boolean(object.delete) : false,
            key: isSet(object.key) ? bytesFromBase64(object.key) : new Uint8Array(),
            value: isSet(object.value) ? bytesFromBase64(object.value) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.storeKey !== undefined && (obj.storeKey = message.storeKey);
        message.delete !== undefined && (obj.delete = message.delete);
        message.key !== undefined &&
            (obj.key = base64FromBytes(message.key !== undefined ? message.key : new Uint8Array()));
        message.value !== undefined &&
            (obj.value = base64FromBytes(message.value !== undefined ? message.value : new Uint8Array()));
        return obj;
    },
    create(base) {
        return StoreKVPair.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStoreKVPair();
        message.storeKey = object.storeKey ?? "";
        message.delete = object.delete ?? false;
        message.key = object.key ?? new Uint8Array();
        message.value = object.value ?? new Uint8Array();
        return message;
    },
};
function createBaseBlockMetadata() {
    return { responseCommit: undefined, requestFinalizeBlock: undefined, responseFinalizeBlock: undefined };
}
export const BlockMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.responseCommit !== undefined) {
            ResponseCommit.encode(message.responseCommit, writer.uint32(50).fork()).ldelim();
        }
        if (message.requestFinalizeBlock !== undefined) {
            RequestFinalizeBlock.encode(message.requestFinalizeBlock, writer.uint32(58).fork()).ldelim();
        }
        if (message.responseFinalizeBlock !== undefined) {
            ResponseFinalizeBlock.encode(message.responseFinalizeBlock, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 6:
                    message.responseCommit = ResponseCommit.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.requestFinalizeBlock = RequestFinalizeBlock.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.responseFinalizeBlock = ResponseFinalizeBlock.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            responseCommit: isSet(object.responseCommit) ? ResponseCommit.fromJSON(object.responseCommit) : undefined,
            requestFinalizeBlock: isSet(object.requestFinalizeBlock)
                ? RequestFinalizeBlock.fromJSON(object.requestFinalizeBlock)
                : undefined,
            responseFinalizeBlock: isSet(object.responseFinalizeBlock)
                ? ResponseFinalizeBlock.fromJSON(object.responseFinalizeBlock)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.responseCommit !== undefined &&
            (obj.responseCommit = message.responseCommit ? ResponseCommit.toJSON(message.responseCommit) : undefined);
        message.requestFinalizeBlock !== undefined && (obj.requestFinalizeBlock = message.requestFinalizeBlock
            ? RequestFinalizeBlock.toJSON(message.requestFinalizeBlock)
            : undefined);
        message.responseFinalizeBlock !== undefined && (obj.responseFinalizeBlock = message.responseFinalizeBlock
            ? ResponseFinalizeBlock.toJSON(message.responseFinalizeBlock)
            : undefined);
        return obj;
    },
    create(base) {
        return BlockMetadata.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockMetadata();
        message.responseCommit = (object.responseCommit !== undefined && object.responseCommit !== null)
            ? ResponseCommit.fromPartial(object.responseCommit)
            : undefined;
        message.requestFinalizeBlock = (object.requestFinalizeBlock !== undefined && object.requestFinalizeBlock !== null)
            ? RequestFinalizeBlock.fromPartial(object.requestFinalizeBlock)
            : undefined;
        message.responseFinalizeBlock =
            (object.responseFinalizeBlock !== undefined && object.responseFinalizeBlock !== null)
                ? ResponseFinalizeBlock.fromPartial(object.responseFinalizeBlock)
                : undefined;
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
