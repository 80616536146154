/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { LastAuctionResult, Params } from "./auction.js";
import { GenesisState } from "./genesis.js";
export const protobufPackage = "injective.auction.v1beta1";
function createBaseQueryAuctionParamsRequest() {
    return {};
}
export const QueryAuctionParamsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAuctionParamsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryAuctionParamsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryAuctionParamsRequest();
        return message;
    },
};
function createBaseQueryAuctionParamsResponse() {
    return { params: undefined };
}
export const QueryAuctionParamsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAuctionParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { params: isSet(object.params) ? Params.fromJSON(object.params) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return QueryAuctionParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryAuctionParamsResponse();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseQueryCurrentAuctionBasketRequest() {
    return {};
}
export const QueryCurrentAuctionBasketRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryCurrentAuctionBasketRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryCurrentAuctionBasketRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryCurrentAuctionBasketRequest();
        return message;
    },
};
function createBaseQueryCurrentAuctionBasketResponse() {
    return { amount: [], auctionRound: "0", auctionClosingTime: "0", highestBidder: "", highestBidAmount: "" };
}
export const QueryCurrentAuctionBasketResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.amount) {
            Coin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.auctionRound !== "0") {
            writer.uint32(16).uint64(message.auctionRound);
        }
        if (message.auctionClosingTime !== "0") {
            writer.uint32(24).int64(message.auctionClosingTime);
        }
        if (message.highestBidder !== "") {
            writer.uint32(34).string(message.highestBidder);
        }
        if (message.highestBidAmount !== "") {
            writer.uint32(42).string(message.highestBidAmount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryCurrentAuctionBasketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.amount.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.auctionRound = longToString(reader.uint64());
                    break;
                case 3:
                    message.auctionClosingTime = longToString(reader.int64());
                    break;
                case 4:
                    message.highestBidder = reader.string();
                    break;
                case 5:
                    message.highestBidAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            amount: Array.isArray(object?.amount) ? object.amount.map((e) => Coin.fromJSON(e)) : [],
            auctionRound: isSet(object.auctionRound) ? String(object.auctionRound) : "0",
            auctionClosingTime: isSet(object.auctionClosingTime) ? String(object.auctionClosingTime) : "0",
            highestBidder: isSet(object.highestBidder) ? String(object.highestBidder) : "",
            highestBidAmount: isSet(object.highestBidAmount) ? String(object.highestBidAmount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.amount) {
            obj.amount = message.amount.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.amount = [];
        }
        message.auctionRound !== undefined && (obj.auctionRound = message.auctionRound);
        message.auctionClosingTime !== undefined && (obj.auctionClosingTime = message.auctionClosingTime);
        message.highestBidder !== undefined && (obj.highestBidder = message.highestBidder);
        message.highestBidAmount !== undefined && (obj.highestBidAmount = message.highestBidAmount);
        return obj;
    },
    create(base) {
        return QueryCurrentAuctionBasketResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryCurrentAuctionBasketResponse();
        message.amount = object.amount?.map((e) => Coin.fromPartial(e)) || [];
        message.auctionRound = object.auctionRound ?? "0";
        message.auctionClosingTime = object.auctionClosingTime ?? "0";
        message.highestBidder = object.highestBidder ?? "";
        message.highestBidAmount = object.highestBidAmount ?? "";
        return message;
    },
};
function createBaseQueryModuleStateRequest() {
    return {};
}
export const QueryModuleStateRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryModuleStateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryModuleStateRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryModuleStateRequest();
        return message;
    },
};
function createBaseQueryModuleStateResponse() {
    return { state: undefined };
}
export const QueryModuleStateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== undefined) {
            GenesisState.encode(message.state, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryModuleStateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = GenesisState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { state: isSet(object.state) ? GenesisState.fromJSON(object.state) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.state !== undefined && (obj.state = message.state ? GenesisState.toJSON(message.state) : undefined);
        return obj;
    },
    create(base) {
        return QueryModuleStateResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryModuleStateResponse();
        message.state = (object.state !== undefined && object.state !== null)
            ? GenesisState.fromPartial(object.state)
            : undefined;
        return message;
    },
};
function createBaseQueryLastAuctionResultRequest() {
    return {};
}
export const QueryLastAuctionResultRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryLastAuctionResultRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryLastAuctionResultRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryLastAuctionResultRequest();
        return message;
    },
};
function createBaseQueryLastAuctionResultResponse() {
    return { lastAuctionResult: undefined };
}
export const QueryLastAuctionResultResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.lastAuctionResult !== undefined) {
            LastAuctionResult.encode(message.lastAuctionResult, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryLastAuctionResultResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lastAuctionResult = LastAuctionResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            lastAuctionResult: isSet(object.lastAuctionResult)
                ? LastAuctionResult.fromJSON(object.lastAuctionResult)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.lastAuctionResult !== undefined && (obj.lastAuctionResult = message.lastAuctionResult
            ? LastAuctionResult.toJSON(message.lastAuctionResult)
            : undefined);
        return obj;
    },
    create(base) {
        return QueryLastAuctionResultResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryLastAuctionResultResponse();
        message.lastAuctionResult = (object.lastAuctionResult !== undefined && object.lastAuctionResult !== null)
            ? LastAuctionResult.fromPartial(object.lastAuctionResult)
            : undefined;
        return message;
    },
};
export class QueryClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.AuctionParams = this.AuctionParams.bind(this);
        this.CurrentAuctionBasket = this.CurrentAuctionBasket.bind(this);
        this.AuctionModuleState = this.AuctionModuleState.bind(this);
        this.LastAuctionResult = this.LastAuctionResult.bind(this);
    }
    AuctionParams(request, metadata) {
        return this.rpc.unary(QueryAuctionParamsDesc, QueryAuctionParamsRequest.fromPartial(request), metadata);
    }
    CurrentAuctionBasket(request, metadata) {
        return this.rpc.unary(QueryCurrentAuctionBasketDesc, QueryCurrentAuctionBasketRequest.fromPartial(request), metadata);
    }
    AuctionModuleState(request, metadata) {
        return this.rpc.unary(QueryAuctionModuleStateDesc, QueryModuleStateRequest.fromPartial(request), metadata);
    }
    LastAuctionResult(request, metadata) {
        return this.rpc.unary(QueryLastAuctionResultDesc, QueryLastAuctionResultRequest.fromPartial(request), metadata);
    }
}
export const QueryDesc = { serviceName: "injective.auction.v1beta1.Query" };
export const QueryAuctionParamsDesc = {
    methodName: "AuctionParams",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryAuctionParamsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryAuctionParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryCurrentAuctionBasketDesc = {
    methodName: "CurrentAuctionBasket",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryCurrentAuctionBasketRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryCurrentAuctionBasketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryAuctionModuleStateDesc = {
    methodName: "AuctionModuleState",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryModuleStateRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryModuleStateResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryLastAuctionResultDesc = {
    methodName: "LastAuctionResult",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryLastAuctionResultRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryLastAuctionResultResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
