/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { CommunityPoolSpendProposal } from "../../../cosmos/distribution/v1beta1/distribution.js";
import { oracleTypeFromJSON, oracleTypeToJSON } from "../../oracle/v1beta1/oracle.js";
import { CampaignRewardPool, DenomDecimals, FeeDiscountSchedule, MarketFeeMultiplier, marketStatusFromJSON, marketStatusToJSON, TradingRewardCampaignInfo, } from "./exchange.js";
export const protobufPackage = "injective.exchange.v1beta1";
export var ExchangeType;
(function (ExchangeType) {
    ExchangeType[ExchangeType["EXCHANGE_UNSPECIFIED"] = 0] = "EXCHANGE_UNSPECIFIED";
    ExchangeType[ExchangeType["SPOT"] = 1] = "SPOT";
    ExchangeType[ExchangeType["DERIVATIVES"] = 2] = "DERIVATIVES";
    ExchangeType[ExchangeType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ExchangeType || (ExchangeType = {}));
export function exchangeTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "EXCHANGE_UNSPECIFIED":
            return ExchangeType.EXCHANGE_UNSPECIFIED;
        case 1:
        case "SPOT":
            return ExchangeType.SPOT;
        case 2:
        case "DERIVATIVES":
            return ExchangeType.DERIVATIVES;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ExchangeType.UNRECOGNIZED;
    }
}
export function exchangeTypeToJSON(object) {
    switch (object) {
        case ExchangeType.EXCHANGE_UNSPECIFIED:
            return "EXCHANGE_UNSPECIFIED";
        case ExchangeType.SPOT:
            return "SPOT";
        case ExchangeType.DERIVATIVES:
            return "DERIVATIVES";
        case ExchangeType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseSpotMarketParamUpdateProposal() {
    return {
        title: "",
        description: "",
        marketId: "",
        makerFeeRate: "",
        takerFeeRate: "",
        relayerFeeShareRate: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        status: 0,
        ticker: "",
        minNotional: "",
        adminInfo: undefined,
        baseDecimals: 0,
        quoteDecimals: 0,
    };
}
export const SpotMarketParamUpdateProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(34).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(42).string(message.takerFeeRate);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(50).string(message.relayerFeeShareRate);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(58).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(66).string(message.minQuantityTickSize);
        }
        if (message.status !== 0) {
            writer.uint32(72).int32(message.status);
        }
        if (message.ticker !== "") {
            writer.uint32(82).string(message.ticker);
        }
        if (message.minNotional !== "") {
            writer.uint32(90).string(message.minNotional);
        }
        if (message.adminInfo !== undefined) {
            AdminInfo.encode(message.adminInfo, writer.uint32(98).fork()).ldelim();
        }
        if (message.baseDecimals !== 0) {
            writer.uint32(104).uint32(message.baseDecimals);
        }
        if (message.quoteDecimals !== 0) {
            writer.uint32(112).uint32(message.quoteDecimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotMarketParamUpdateProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.makerFeeRate = reader.string();
                    break;
                case 5:
                    message.takerFeeRate = reader.string();
                    break;
                case 6:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 7:
                    message.minPriceTickSize = reader.string();
                    break;
                case 8:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 9:
                    message.status = reader.int32();
                    break;
                case 10:
                    message.ticker = reader.string();
                    break;
                case 11:
                    message.minNotional = reader.string();
                    break;
                case 12:
                    message.adminInfo = AdminInfo.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.baseDecimals = reader.uint32();
                    break;
                case 14:
                    message.quoteDecimals = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminInfo: isSet(object.adminInfo) ? AdminInfo.fromJSON(object.adminInfo) : undefined,
            baseDecimals: isSet(object.baseDecimals) ? Number(object.baseDecimals) : 0,
            quoteDecimals: isSet(object.quoteDecimals) ? Number(object.quoteDecimals) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminInfo !== undefined &&
            (obj.adminInfo = message.adminInfo ? AdminInfo.toJSON(message.adminInfo) : undefined);
        message.baseDecimals !== undefined && (obj.baseDecimals = Math.round(message.baseDecimals));
        message.quoteDecimals !== undefined && (obj.quoteDecimals = Math.round(message.quoteDecimals));
        return obj;
    },
    create(base) {
        return SpotMarketParamUpdateProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotMarketParamUpdateProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.marketId = object.marketId ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.status = object.status ?? 0;
        message.ticker = object.ticker ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminInfo = (object.adminInfo !== undefined && object.adminInfo !== null)
            ? AdminInfo.fromPartial(object.adminInfo)
            : undefined;
        message.baseDecimals = object.baseDecimals ?? 0;
        message.quoteDecimals = object.quoteDecimals ?? 0;
        return message;
    },
};
function createBaseExchangeEnableProposal() {
    return { title: "", description: "", exchangeType: 0 };
}
export const ExchangeEnableProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.exchangeType !== 0) {
            writer.uint32(24).int32(message.exchangeType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExchangeEnableProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.exchangeType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            exchangeType: isSet(object.exchangeType) ? exchangeTypeFromJSON(object.exchangeType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.exchangeType !== undefined && (obj.exchangeType = exchangeTypeToJSON(message.exchangeType));
        return obj;
    },
    create(base) {
        return ExchangeEnableProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExchangeEnableProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.exchangeType = object.exchangeType ?? 0;
        return message;
    },
};
function createBaseBatchExchangeModificationProposal() {
    return {
        title: "",
        description: "",
        spotMarketParamUpdateProposals: [],
        derivativeMarketParamUpdateProposals: [],
        spotMarketLaunchProposals: [],
        perpetualMarketLaunchProposals: [],
        expiryFuturesMarketLaunchProposals: [],
        tradingRewardCampaignUpdateProposal: undefined,
        binaryOptionsMarketLaunchProposals: [],
        binaryOptionsParamUpdateProposals: [],
        denomDecimalsUpdateProposal: undefined,
        feeDiscountProposal: undefined,
        marketForcedSettlementProposals: [],
    };
}
export const BatchExchangeModificationProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.spotMarketParamUpdateProposals) {
            SpotMarketParamUpdateProposal.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.derivativeMarketParamUpdateProposals) {
            DerivativeMarketParamUpdateProposal.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.spotMarketLaunchProposals) {
            SpotMarketLaunchProposal.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.perpetualMarketLaunchProposals) {
            PerpetualMarketLaunchProposal.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.expiryFuturesMarketLaunchProposals) {
            ExpiryFuturesMarketLaunchProposal.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.tradingRewardCampaignUpdateProposal !== undefined) {
            TradingRewardCampaignUpdateProposal.encode(message.tradingRewardCampaignUpdateProposal, writer.uint32(66).fork())
                .ldelim();
        }
        for (const v of message.binaryOptionsMarketLaunchProposals) {
            BinaryOptionsMarketLaunchProposal.encode(v, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.binaryOptionsParamUpdateProposals) {
            BinaryOptionsMarketParamUpdateProposal.encode(v, writer.uint32(82).fork()).ldelim();
        }
        if (message.denomDecimalsUpdateProposal !== undefined) {
            UpdateDenomDecimalsProposal.encode(message.denomDecimalsUpdateProposal, writer.uint32(90).fork()).ldelim();
        }
        if (message.feeDiscountProposal !== undefined) {
            FeeDiscountProposal.encode(message.feeDiscountProposal, writer.uint32(98).fork()).ldelim();
        }
        for (const v of message.marketForcedSettlementProposals) {
            MarketForcedSettlementProposal.encode(v, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchExchangeModificationProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.spotMarketParamUpdateProposals.push(SpotMarketParamUpdateProposal.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.derivativeMarketParamUpdateProposals.push(DerivativeMarketParamUpdateProposal.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.spotMarketLaunchProposals.push(SpotMarketLaunchProposal.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.perpetualMarketLaunchProposals.push(PerpetualMarketLaunchProposal.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.expiryFuturesMarketLaunchProposals.push(ExpiryFuturesMarketLaunchProposal.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.tradingRewardCampaignUpdateProposal = TradingRewardCampaignUpdateProposal.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.binaryOptionsMarketLaunchProposals.push(BinaryOptionsMarketLaunchProposal.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.binaryOptionsParamUpdateProposals.push(BinaryOptionsMarketParamUpdateProposal.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.denomDecimalsUpdateProposal = UpdateDenomDecimalsProposal.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.feeDiscountProposal = FeeDiscountProposal.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.marketForcedSettlementProposals.push(MarketForcedSettlementProposal.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            spotMarketParamUpdateProposals: Array.isArray(object?.spotMarketParamUpdateProposals)
                ? object.spotMarketParamUpdateProposals.map((e) => SpotMarketParamUpdateProposal.fromJSON(e))
                : [],
            derivativeMarketParamUpdateProposals: Array.isArray(object?.derivativeMarketParamUpdateProposals)
                ? object.derivativeMarketParamUpdateProposals.map((e) => DerivativeMarketParamUpdateProposal.fromJSON(e))
                : [],
            spotMarketLaunchProposals: Array.isArray(object?.spotMarketLaunchProposals)
                ? object.spotMarketLaunchProposals.map((e) => SpotMarketLaunchProposal.fromJSON(e))
                : [],
            perpetualMarketLaunchProposals: Array.isArray(object?.perpetualMarketLaunchProposals)
                ? object.perpetualMarketLaunchProposals.map((e) => PerpetualMarketLaunchProposal.fromJSON(e))
                : [],
            expiryFuturesMarketLaunchProposals: Array.isArray(object?.expiryFuturesMarketLaunchProposals)
                ? object.expiryFuturesMarketLaunchProposals.map((e) => ExpiryFuturesMarketLaunchProposal.fromJSON(e))
                : [],
            tradingRewardCampaignUpdateProposal: isSet(object.tradingRewardCampaignUpdateProposal)
                ? TradingRewardCampaignUpdateProposal.fromJSON(object.tradingRewardCampaignUpdateProposal)
                : undefined,
            binaryOptionsMarketLaunchProposals: Array.isArray(object?.binaryOptionsMarketLaunchProposals)
                ? object.binaryOptionsMarketLaunchProposals.map((e) => BinaryOptionsMarketLaunchProposal.fromJSON(e))
                : [],
            binaryOptionsParamUpdateProposals: Array.isArray(object?.binaryOptionsParamUpdateProposals)
                ? object.binaryOptionsParamUpdateProposals.map((e) => BinaryOptionsMarketParamUpdateProposal.fromJSON(e))
                : [],
            denomDecimalsUpdateProposal: isSet(object.denomDecimalsUpdateProposal)
                ? UpdateDenomDecimalsProposal.fromJSON(object.denomDecimalsUpdateProposal)
                : undefined,
            feeDiscountProposal: isSet(object.feeDiscountProposal)
                ? FeeDiscountProposal.fromJSON(object.feeDiscountProposal)
                : undefined,
            marketForcedSettlementProposals: Array.isArray(object?.marketForcedSettlementProposals)
                ? object.marketForcedSettlementProposals.map((e) => MarketForcedSettlementProposal.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.spotMarketParamUpdateProposals) {
            obj.spotMarketParamUpdateProposals = message.spotMarketParamUpdateProposals.map((e) => e ? SpotMarketParamUpdateProposal.toJSON(e) : undefined);
        }
        else {
            obj.spotMarketParamUpdateProposals = [];
        }
        if (message.derivativeMarketParamUpdateProposals) {
            obj.derivativeMarketParamUpdateProposals = message.derivativeMarketParamUpdateProposals.map((e) => e ? DerivativeMarketParamUpdateProposal.toJSON(e) : undefined);
        }
        else {
            obj.derivativeMarketParamUpdateProposals = [];
        }
        if (message.spotMarketLaunchProposals) {
            obj.spotMarketLaunchProposals = message.spotMarketLaunchProposals.map((e) => e ? SpotMarketLaunchProposal.toJSON(e) : undefined);
        }
        else {
            obj.spotMarketLaunchProposals = [];
        }
        if (message.perpetualMarketLaunchProposals) {
            obj.perpetualMarketLaunchProposals = message.perpetualMarketLaunchProposals.map((e) => e ? PerpetualMarketLaunchProposal.toJSON(e) : undefined);
        }
        else {
            obj.perpetualMarketLaunchProposals = [];
        }
        if (message.expiryFuturesMarketLaunchProposals) {
            obj.expiryFuturesMarketLaunchProposals = message.expiryFuturesMarketLaunchProposals.map((e) => e ? ExpiryFuturesMarketLaunchProposal.toJSON(e) : undefined);
        }
        else {
            obj.expiryFuturesMarketLaunchProposals = [];
        }
        message.tradingRewardCampaignUpdateProposal !== undefined &&
            (obj.tradingRewardCampaignUpdateProposal = message.tradingRewardCampaignUpdateProposal
                ? TradingRewardCampaignUpdateProposal.toJSON(message.tradingRewardCampaignUpdateProposal)
                : undefined);
        if (message.binaryOptionsMarketLaunchProposals) {
            obj.binaryOptionsMarketLaunchProposals = message.binaryOptionsMarketLaunchProposals.map((e) => e ? BinaryOptionsMarketLaunchProposal.toJSON(e) : undefined);
        }
        else {
            obj.binaryOptionsMarketLaunchProposals = [];
        }
        if (message.binaryOptionsParamUpdateProposals) {
            obj.binaryOptionsParamUpdateProposals = message.binaryOptionsParamUpdateProposals.map((e) => e ? BinaryOptionsMarketParamUpdateProposal.toJSON(e) : undefined);
        }
        else {
            obj.binaryOptionsParamUpdateProposals = [];
        }
        message.denomDecimalsUpdateProposal !== undefined &&
            (obj.denomDecimalsUpdateProposal = message.denomDecimalsUpdateProposal
                ? UpdateDenomDecimalsProposal.toJSON(message.denomDecimalsUpdateProposal)
                : undefined);
        message.feeDiscountProposal !== undefined && (obj.feeDiscountProposal = message.feeDiscountProposal
            ? FeeDiscountProposal.toJSON(message.feeDiscountProposal)
            : undefined);
        if (message.marketForcedSettlementProposals) {
            obj.marketForcedSettlementProposals = message.marketForcedSettlementProposals.map((e) => e ? MarketForcedSettlementProposal.toJSON(e) : undefined);
        }
        else {
            obj.marketForcedSettlementProposals = [];
        }
        return obj;
    },
    create(base) {
        return BatchExchangeModificationProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchExchangeModificationProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.spotMarketParamUpdateProposals =
            object.spotMarketParamUpdateProposals?.map((e) => SpotMarketParamUpdateProposal.fromPartial(e)) || [];
        message.derivativeMarketParamUpdateProposals =
            object.derivativeMarketParamUpdateProposals?.map((e) => DerivativeMarketParamUpdateProposal.fromPartial(e)) || [];
        message.spotMarketLaunchProposals =
            object.spotMarketLaunchProposals?.map((e) => SpotMarketLaunchProposal.fromPartial(e)) || [];
        message.perpetualMarketLaunchProposals =
            object.perpetualMarketLaunchProposals?.map((e) => PerpetualMarketLaunchProposal.fromPartial(e)) || [];
        message.expiryFuturesMarketLaunchProposals =
            object.expiryFuturesMarketLaunchProposals?.map((e) => ExpiryFuturesMarketLaunchProposal.fromPartial(e)) || [];
        message.tradingRewardCampaignUpdateProposal =
            (object.tradingRewardCampaignUpdateProposal !== undefined && object.tradingRewardCampaignUpdateProposal !== null)
                ? TradingRewardCampaignUpdateProposal.fromPartial(object.tradingRewardCampaignUpdateProposal)
                : undefined;
        message.binaryOptionsMarketLaunchProposals =
            object.binaryOptionsMarketLaunchProposals?.map((e) => BinaryOptionsMarketLaunchProposal.fromPartial(e)) || [];
        message.binaryOptionsParamUpdateProposals =
            object.binaryOptionsParamUpdateProposals?.map((e) => BinaryOptionsMarketParamUpdateProposal.fromPartial(e)) || [];
        message.denomDecimalsUpdateProposal =
            (object.denomDecimalsUpdateProposal !== undefined && object.denomDecimalsUpdateProposal !== null)
                ? UpdateDenomDecimalsProposal.fromPartial(object.denomDecimalsUpdateProposal)
                : undefined;
        message.feeDiscountProposal = (object.feeDiscountProposal !== undefined && object.feeDiscountProposal !== null)
            ? FeeDiscountProposal.fromPartial(object.feeDiscountProposal)
            : undefined;
        message.marketForcedSettlementProposals =
            object.marketForcedSettlementProposals?.map((e) => MarketForcedSettlementProposal.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSpotMarketLaunchProposal() {
    return {
        title: "",
        description: "",
        ticker: "",
        baseDenom: "",
        quoteDenom: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        makerFeeRate: "",
        takerFeeRate: "",
        minNotional: "",
        adminInfo: undefined,
        baseDecimals: 0,
        quoteDecimals: 0,
    };
}
export const SpotMarketLaunchProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.baseDenom !== "") {
            writer.uint32(34).string(message.baseDenom);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(42).string(message.quoteDenom);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(50).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(58).string(message.minQuantityTickSize);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(66).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(74).string(message.takerFeeRate);
        }
        if (message.minNotional !== "") {
            writer.uint32(82).string(message.minNotional);
        }
        if (message.adminInfo !== undefined) {
            AdminInfo.encode(message.adminInfo, writer.uint32(90).fork()).ldelim();
        }
        if (message.baseDecimals !== 0) {
            writer.uint32(112).uint32(message.baseDecimals);
        }
        if (message.quoteDecimals !== 0) {
            writer.uint32(120).uint32(message.quoteDecimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotMarketLaunchProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.baseDenom = reader.string();
                    break;
                case 5:
                    message.quoteDenom = reader.string();
                    break;
                case 6:
                    message.minPriceTickSize = reader.string();
                    break;
                case 7:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 8:
                    message.makerFeeRate = reader.string();
                    break;
                case 9:
                    message.takerFeeRate = reader.string();
                    break;
                case 10:
                    message.minNotional = reader.string();
                    break;
                case 11:
                    message.adminInfo = AdminInfo.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.baseDecimals = reader.uint32();
                    break;
                case 15:
                    message.quoteDecimals = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            baseDenom: isSet(object.baseDenom) ? String(object.baseDenom) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminInfo: isSet(object.adminInfo) ? AdminInfo.fromJSON(object.adminInfo) : undefined,
            baseDecimals: isSet(object.baseDecimals) ? Number(object.baseDecimals) : 0,
            quoteDecimals: isSet(object.quoteDecimals) ? Number(object.quoteDecimals) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.baseDenom !== undefined && (obj.baseDenom = message.baseDenom);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminInfo !== undefined &&
            (obj.adminInfo = message.adminInfo ? AdminInfo.toJSON(message.adminInfo) : undefined);
        message.baseDecimals !== undefined && (obj.baseDecimals = Math.round(message.baseDecimals));
        message.quoteDecimals !== undefined && (obj.quoteDecimals = Math.round(message.quoteDecimals));
        return obj;
    },
    create(base) {
        return SpotMarketLaunchProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotMarketLaunchProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.ticker = object.ticker ?? "";
        message.baseDenom = object.baseDenom ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminInfo = (object.adminInfo !== undefined && object.adminInfo !== null)
            ? AdminInfo.fromPartial(object.adminInfo)
            : undefined;
        message.baseDecimals = object.baseDecimals ?? 0;
        message.quoteDecimals = object.quoteDecimals ?? 0;
        return message;
    },
};
function createBasePerpetualMarketLaunchProposal() {
    return {
        title: "",
        description: "",
        ticker: "",
        quoteDenom: "",
        oracleBase: "",
        oracleQuote: "",
        oracleScaleFactor: 0,
        oracleType: 0,
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        makerFeeRate: "",
        takerFeeRate: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
        adminInfo: undefined,
    };
}
export const PerpetualMarketLaunchProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(34).string(message.quoteDenom);
        }
        if (message.oracleBase !== "") {
            writer.uint32(42).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(50).string(message.oracleQuote);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(56).uint32(message.oracleScaleFactor);
        }
        if (message.oracleType !== 0) {
            writer.uint32(64).int32(message.oracleType);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(74).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(82).string(message.maintenanceMarginRatio);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(90).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(98).string(message.takerFeeRate);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(106).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(114).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(122).string(message.minNotional);
        }
        if (message.adminInfo !== undefined) {
            AdminInfo.encode(message.adminInfo, writer.uint32(130).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerpetualMarketLaunchProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.quoteDenom = reader.string();
                    break;
                case 5:
                    message.oracleBase = reader.string();
                    break;
                case 6:
                    message.oracleQuote = reader.string();
                    break;
                case 7:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 8:
                    message.oracleType = reader.int32();
                    break;
                case 9:
                    message.initialMarginRatio = reader.string();
                    break;
                case 10:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 11:
                    message.makerFeeRate = reader.string();
                    break;
                case 12:
                    message.takerFeeRate = reader.string();
                    break;
                case 13:
                    message.minPriceTickSize = reader.string();
                    break;
                case 14:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 15:
                    message.minNotional = reader.string();
                    break;
                case 16:
                    message.adminInfo = AdminInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminInfo: isSet(object.adminInfo) ? AdminInfo.fromJSON(object.adminInfo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminInfo !== undefined &&
            (obj.adminInfo = message.adminInfo ? AdminInfo.toJSON(message.adminInfo) : undefined);
        return obj;
    },
    create(base) {
        return PerpetualMarketLaunchProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePerpetualMarketLaunchProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.ticker = object.ticker ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.oracleType = object.oracleType ?? 0;
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminInfo = (object.adminInfo !== undefined && object.adminInfo !== null)
            ? AdminInfo.fromPartial(object.adminInfo)
            : undefined;
        return message;
    },
};
function createBaseBinaryOptionsMarketLaunchProposal() {
    return {
        title: "",
        description: "",
        ticker: "",
        oracleSymbol: "",
        oracleProvider: "",
        oracleType: 0,
        oracleScaleFactor: 0,
        expirationTimestamp: "0",
        settlementTimestamp: "0",
        admin: "",
        quoteDenom: "",
        makerFeeRate: "",
        takerFeeRate: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
        adminPermissions: 0,
    };
}
export const BinaryOptionsMarketLaunchProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.oracleSymbol !== "") {
            writer.uint32(34).string(message.oracleSymbol);
        }
        if (message.oracleProvider !== "") {
            writer.uint32(42).string(message.oracleProvider);
        }
        if (message.oracleType !== 0) {
            writer.uint32(48).int32(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(56).uint32(message.oracleScaleFactor);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(64).int64(message.expirationTimestamp);
        }
        if (message.settlementTimestamp !== "0") {
            writer.uint32(72).int64(message.settlementTimestamp);
        }
        if (message.admin !== "") {
            writer.uint32(82).string(message.admin);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(90).string(message.quoteDenom);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(98).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(106).string(message.takerFeeRate);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(114).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(122).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(130).string(message.minNotional);
        }
        if (message.adminPermissions !== 0) {
            writer.uint32(136).uint32(message.adminPermissions);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketLaunchProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.oracleSymbol = reader.string();
                    break;
                case 5:
                    message.oracleProvider = reader.string();
                    break;
                case 6:
                    message.oracleType = reader.int32();
                    break;
                case 7:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 8:
                    message.expirationTimestamp = longToString(reader.int64());
                    break;
                case 9:
                    message.settlementTimestamp = longToString(reader.int64());
                    break;
                case 10:
                    message.admin = reader.string();
                    break;
                case 11:
                    message.quoteDenom = reader.string();
                    break;
                case 12:
                    message.makerFeeRate = reader.string();
                    break;
                case 13:
                    message.takerFeeRate = reader.string();
                    break;
                case 14:
                    message.minPriceTickSize = reader.string();
                    break;
                case 15:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 16:
                    message.minNotional = reader.string();
                    break;
                case 17:
                    message.adminPermissions = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            oracleSymbol: isSet(object.oracleSymbol) ? String(object.oracleSymbol) : "",
            oracleProvider: isSet(object.oracleProvider) ? String(object.oracleProvider) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementTimestamp: isSet(object.settlementTimestamp) ? String(object.settlementTimestamp) : "0",
            admin: isSet(object.admin) ? String(object.admin) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminPermissions: isSet(object.adminPermissions) ? Number(object.adminPermissions) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.oracleSymbol !== undefined && (obj.oracleSymbol = message.oracleSymbol);
        message.oracleProvider !== undefined && (obj.oracleProvider = message.oracleProvider);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementTimestamp !== undefined && (obj.settlementTimestamp = message.settlementTimestamp);
        message.admin !== undefined && (obj.admin = message.admin);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminPermissions !== undefined && (obj.adminPermissions = Math.round(message.adminPermissions));
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketLaunchProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketLaunchProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.ticker = object.ticker ?? "";
        message.oracleSymbol = object.oracleSymbol ?? "";
        message.oracleProvider = object.oracleProvider ?? "";
        message.oracleType = object.oracleType ?? 0;
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementTimestamp = object.settlementTimestamp ?? "0";
        message.admin = object.admin ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminPermissions = object.adminPermissions ?? 0;
        return message;
    },
};
function createBaseExpiryFuturesMarketLaunchProposal() {
    return {
        title: "",
        description: "",
        ticker: "",
        quoteDenom: "",
        oracleBase: "",
        oracleQuote: "",
        oracleScaleFactor: 0,
        oracleType: 0,
        expiry: "0",
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        makerFeeRate: "",
        takerFeeRate: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
        adminInfo: undefined,
    };
}
export const ExpiryFuturesMarketLaunchProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.ticker !== "") {
            writer.uint32(26).string(message.ticker);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(34).string(message.quoteDenom);
        }
        if (message.oracleBase !== "") {
            writer.uint32(42).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(50).string(message.oracleQuote);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(56).uint32(message.oracleScaleFactor);
        }
        if (message.oracleType !== 0) {
            writer.uint32(64).int32(message.oracleType);
        }
        if (message.expiry !== "0") {
            writer.uint32(72).int64(message.expiry);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(82).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(90).string(message.maintenanceMarginRatio);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(98).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(106).string(message.takerFeeRate);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(114).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(122).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(130).string(message.minNotional);
        }
        if (message.adminInfo !== undefined) {
            AdminInfo.encode(message.adminInfo, writer.uint32(138).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExpiryFuturesMarketLaunchProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.ticker = reader.string();
                    break;
                case 4:
                    message.quoteDenom = reader.string();
                    break;
                case 5:
                    message.oracleBase = reader.string();
                    break;
                case 6:
                    message.oracleQuote = reader.string();
                    break;
                case 7:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 8:
                    message.oracleType = reader.int32();
                    break;
                case 9:
                    message.expiry = longToString(reader.int64());
                    break;
                case 10:
                    message.initialMarginRatio = reader.string();
                    break;
                case 11:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 12:
                    message.makerFeeRate = reader.string();
                    break;
                case 13:
                    message.takerFeeRate = reader.string();
                    break;
                case 14:
                    message.minPriceTickSize = reader.string();
                    break;
                case 15:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 16:
                    message.minNotional = reader.string();
                    break;
                case 17:
                    message.adminInfo = AdminInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            expiry: isSet(object.expiry) ? String(object.expiry) : "0",
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminInfo: isSet(object.adminInfo) ? AdminInfo.fromJSON(object.adminInfo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.expiry !== undefined && (obj.expiry = message.expiry);
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminInfo !== undefined &&
            (obj.adminInfo = message.adminInfo ? AdminInfo.toJSON(message.adminInfo) : undefined);
        return obj;
    },
    create(base) {
        return ExpiryFuturesMarketLaunchProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExpiryFuturesMarketLaunchProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.ticker = object.ticker ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.oracleType = object.oracleType ?? 0;
        message.expiry = object.expiry ?? "0";
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminInfo = (object.adminInfo !== undefined && object.adminInfo !== null)
            ? AdminInfo.fromPartial(object.adminInfo)
            : undefined;
        return message;
    },
};
function createBaseDerivativeMarketParamUpdateProposal() {
    return {
        title: "",
        description: "",
        marketId: "",
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        makerFeeRate: "",
        takerFeeRate: "",
        relayerFeeShareRate: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        HourlyInterestRate: "",
        HourlyFundingRateCap: "",
        status: 0,
        oracleParams: undefined,
        ticker: "",
        minNotional: "",
        adminInfo: undefined,
    };
}
export const DerivativeMarketParamUpdateProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(34).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(42).string(message.maintenanceMarginRatio);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(50).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(58).string(message.takerFeeRate);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(66).string(message.relayerFeeShareRate);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(74).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(82).string(message.minQuantityTickSize);
        }
        if (message.HourlyInterestRate !== "") {
            writer.uint32(90).string(message.HourlyInterestRate);
        }
        if (message.HourlyFundingRateCap !== "") {
            writer.uint32(98).string(message.HourlyFundingRateCap);
        }
        if (message.status !== 0) {
            writer.uint32(104).int32(message.status);
        }
        if (message.oracleParams !== undefined) {
            OracleParams.encode(message.oracleParams, writer.uint32(114).fork()).ldelim();
        }
        if (message.ticker !== "") {
            writer.uint32(122).string(message.ticker);
        }
        if (message.minNotional !== "") {
            writer.uint32(130).string(message.minNotional);
        }
        if (message.adminInfo !== undefined) {
            AdminInfo.encode(message.adminInfo, writer.uint32(138).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarketParamUpdateProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.initialMarginRatio = reader.string();
                    break;
                case 5:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 6:
                    message.makerFeeRate = reader.string();
                    break;
                case 7:
                    message.takerFeeRate = reader.string();
                    break;
                case 8:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 9:
                    message.minPriceTickSize = reader.string();
                    break;
                case 10:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 11:
                    message.HourlyInterestRate = reader.string();
                    break;
                case 12:
                    message.HourlyFundingRateCap = reader.string();
                    break;
                case 13:
                    message.status = reader.int32();
                    break;
                case 14:
                    message.oracleParams = OracleParams.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.ticker = reader.string();
                    break;
                case 16:
                    message.minNotional = reader.string();
                    break;
                case 17:
                    message.adminInfo = AdminInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            HourlyInterestRate: isSet(object.HourlyInterestRate) ? String(object.HourlyInterestRate) : "",
            HourlyFundingRateCap: isSet(object.HourlyFundingRateCap) ? String(object.HourlyFundingRateCap) : "",
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
            oracleParams: isSet(object.oracleParams) ? OracleParams.fromJSON(object.oracleParams) : undefined,
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            adminInfo: isSet(object.adminInfo) ? AdminInfo.fromJSON(object.adminInfo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.HourlyInterestRate !== undefined && (obj.HourlyInterestRate = message.HourlyInterestRate);
        message.HourlyFundingRateCap !== undefined && (obj.HourlyFundingRateCap = message.HourlyFundingRateCap);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        message.oracleParams !== undefined &&
            (obj.oracleParams = message.oracleParams ? OracleParams.toJSON(message.oracleParams) : undefined);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.adminInfo !== undefined &&
            (obj.adminInfo = message.adminInfo ? AdminInfo.toJSON(message.adminInfo) : undefined);
        return obj;
    },
    create(base) {
        return DerivativeMarketParamUpdateProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarketParamUpdateProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.marketId = object.marketId ?? "";
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.HourlyInterestRate = object.HourlyInterestRate ?? "";
        message.HourlyFundingRateCap = object.HourlyFundingRateCap ?? "";
        message.status = object.status ?? 0;
        message.oracleParams = (object.oracleParams !== undefined && object.oracleParams !== null)
            ? OracleParams.fromPartial(object.oracleParams)
            : undefined;
        message.ticker = object.ticker ?? "";
        message.minNotional = object.minNotional ?? "";
        message.adminInfo = (object.adminInfo !== undefined && object.adminInfo !== null)
            ? AdminInfo.fromPartial(object.adminInfo)
            : undefined;
        return message;
    },
};
function createBaseAdminInfo() {
    return { admin: "", adminPermissions: 0 };
}
export const AdminInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.admin !== "") {
            writer.uint32(10).string(message.admin);
        }
        if (message.adminPermissions !== 0) {
            writer.uint32(16).uint32(message.adminPermissions);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAdminInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.admin = reader.string();
                    break;
                case 2:
                    message.adminPermissions = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            admin: isSet(object.admin) ? String(object.admin) : "",
            adminPermissions: isSet(object.adminPermissions) ? Number(object.adminPermissions) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.admin !== undefined && (obj.admin = message.admin);
        message.adminPermissions !== undefined && (obj.adminPermissions = Math.round(message.adminPermissions));
        return obj;
    },
    create(base) {
        return AdminInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAdminInfo();
        message.admin = object.admin ?? "";
        message.adminPermissions = object.adminPermissions ?? 0;
        return message;
    },
};
function createBaseMarketForcedSettlementProposal() {
    return { title: "", description: "", marketId: "", settlementPrice: "" };
}
export const MarketForcedSettlementProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(34).string(message.settlementPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketForcedSettlementProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.settlementPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        return obj;
    },
    create(base) {
        return MarketForcedSettlementProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMarketForcedSettlementProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.marketId = object.marketId ?? "";
        message.settlementPrice = object.settlementPrice ?? "";
        return message;
    },
};
function createBaseUpdateDenomDecimalsProposal() {
    return { title: "", description: "", denomDecimals: [] };
}
export const UpdateDenomDecimalsProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.denomDecimals) {
            DenomDecimals.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateDenomDecimalsProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.denomDecimals.push(DenomDecimals.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            denomDecimals: Array.isArray(object?.denomDecimals)
                ? object.denomDecimals.map((e) => DenomDecimals.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.denomDecimals) {
            obj.denomDecimals = message.denomDecimals.map((e) => e ? DenomDecimals.toJSON(e) : undefined);
        }
        else {
            obj.denomDecimals = [];
        }
        return obj;
    },
    create(base) {
        return UpdateDenomDecimalsProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseUpdateDenomDecimalsProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.denomDecimals = object.denomDecimals?.map((e) => DenomDecimals.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBinaryOptionsMarketParamUpdateProposal() {
    return {
        title: "",
        description: "",
        marketId: "",
        makerFeeRate: "",
        takerFeeRate: "",
        relayerFeeShareRate: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        expirationTimestamp: "0",
        settlementTimestamp: "0",
        settlementPrice: "",
        admin: "",
        status: 0,
        oracleParams: undefined,
        ticker: "",
        minNotional: "",
    };
}
export const BinaryOptionsMarketParamUpdateProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(34).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(42).string(message.takerFeeRate);
        }
        if (message.relayerFeeShareRate !== "") {
            writer.uint32(50).string(message.relayerFeeShareRate);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(58).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(66).string(message.minQuantityTickSize);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(72).int64(message.expirationTimestamp);
        }
        if (message.settlementTimestamp !== "0") {
            writer.uint32(80).int64(message.settlementTimestamp);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(90).string(message.settlementPrice);
        }
        if (message.admin !== "") {
            writer.uint32(98).string(message.admin);
        }
        if (message.status !== 0) {
            writer.uint32(104).int32(message.status);
        }
        if (message.oracleParams !== undefined) {
            ProviderOracleParams.encode(message.oracleParams, writer.uint32(114).fork()).ldelim();
        }
        if (message.ticker !== "") {
            writer.uint32(122).string(message.ticker);
        }
        if (message.minNotional !== "") {
            writer.uint32(130).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBinaryOptionsMarketParamUpdateProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.makerFeeRate = reader.string();
                    break;
                case 5:
                    message.takerFeeRate = reader.string();
                    break;
                case 6:
                    message.relayerFeeShareRate = reader.string();
                    break;
                case 7:
                    message.minPriceTickSize = reader.string();
                    break;
                case 8:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 9:
                    message.expirationTimestamp = longToString(reader.int64());
                    break;
                case 10:
                    message.settlementTimestamp = longToString(reader.int64());
                    break;
                case 11:
                    message.settlementPrice = reader.string();
                    break;
                case 12:
                    message.admin = reader.string();
                    break;
                case 13:
                    message.status = reader.int32();
                    break;
                case 14:
                    message.oracleParams = ProviderOracleParams.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.ticker = reader.string();
                    break;
                case 16:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            relayerFeeShareRate: isSet(object.relayerFeeShareRate) ? String(object.relayerFeeShareRate) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementTimestamp: isSet(object.settlementTimestamp) ? String(object.settlementTimestamp) : "0",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
            admin: isSet(object.admin) ? String(object.admin) : "",
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
            oracleParams: isSet(object.oracleParams) ? ProviderOracleParams.fromJSON(object.oracleParams) : undefined,
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.relayerFeeShareRate !== undefined && (obj.relayerFeeShareRate = message.relayerFeeShareRate);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementTimestamp !== undefined && (obj.settlementTimestamp = message.settlementTimestamp);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        message.admin !== undefined && (obj.admin = message.admin);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        message.oracleParams !== undefined &&
            (obj.oracleParams = message.oracleParams ? ProviderOracleParams.toJSON(message.oracleParams) : undefined);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return BinaryOptionsMarketParamUpdateProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBinaryOptionsMarketParamUpdateProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.marketId = object.marketId ?? "";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.relayerFeeShareRate = object.relayerFeeShareRate ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementTimestamp = object.settlementTimestamp ?? "0";
        message.settlementPrice = object.settlementPrice ?? "";
        message.admin = object.admin ?? "";
        message.status = object.status ?? 0;
        message.oracleParams = (object.oracleParams !== undefined && object.oracleParams !== null)
            ? ProviderOracleParams.fromPartial(object.oracleParams)
            : undefined;
        message.ticker = object.ticker ?? "";
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBaseProviderOracleParams() {
    return { symbol: "", provider: "", oracleScaleFactor: 0, oracleType: 0 };
}
export const ProviderOracleParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.symbol !== "") {
            writer.uint32(10).string(message.symbol);
        }
        if (message.provider !== "") {
            writer.uint32(18).string(message.provider);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(24).uint32(message.oracleScaleFactor);
        }
        if (message.oracleType !== 0) {
            writer.uint32(32).int32(message.oracleType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProviderOracleParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.symbol = reader.string();
                    break;
                case 2:
                    message.provider = reader.string();
                    break;
                case 3:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 4:
                    message.oracleType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            provider: isSet(object.provider) ? String(object.provider) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.provider !== undefined && (obj.provider = message.provider);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        return obj;
    },
    create(base) {
        return ProviderOracleParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseProviderOracleParams();
        message.symbol = object.symbol ?? "";
        message.provider = object.provider ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.oracleType = object.oracleType ?? 0;
        return message;
    },
};
function createBaseOracleParams() {
    return { oracleBase: "", oracleQuote: "", oracleScaleFactor: 0, oracleType: 0 };
}
export const OracleParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.oracleBase !== "") {
            writer.uint32(10).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(18).string(message.oracleQuote);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(24).uint32(message.oracleScaleFactor);
        }
        if (message.oracleType !== 0) {
            writer.uint32(32).int32(message.oracleType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOracleParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.oracleBase = reader.string();
                    break;
                case 2:
                    message.oracleQuote = reader.string();
                    break;
                case 3:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 4:
                    message.oracleType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        return obj;
    },
    create(base) {
        return OracleParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOracleParams();
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.oracleType = object.oracleType ?? 0;
        return message;
    },
};
function createBaseTradingRewardCampaignLaunchProposal() {
    return { title: "", description: "", campaignInfo: undefined, campaignRewardPools: [] };
}
export const TradingRewardCampaignLaunchProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.campaignInfo !== undefined) {
            TradingRewardCampaignInfo.encode(message.campaignInfo, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.campaignRewardPools) {
            CampaignRewardPool.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardCampaignLaunchProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.campaignInfo = TradingRewardCampaignInfo.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.campaignRewardPools.push(CampaignRewardPool.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            campaignInfo: isSet(object.campaignInfo) ? TradingRewardCampaignInfo.fromJSON(object.campaignInfo) : undefined,
            campaignRewardPools: Array.isArray(object?.campaignRewardPools)
                ? object.campaignRewardPools.map((e) => CampaignRewardPool.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.campaignInfo !== undefined &&
            (obj.campaignInfo = message.campaignInfo ? TradingRewardCampaignInfo.toJSON(message.campaignInfo) : undefined);
        if (message.campaignRewardPools) {
            obj.campaignRewardPools = message.campaignRewardPools.map((e) => e ? CampaignRewardPool.toJSON(e) : undefined);
        }
        else {
            obj.campaignRewardPools = [];
        }
        return obj;
    },
    create(base) {
        return TradingRewardCampaignLaunchProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardCampaignLaunchProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.campaignInfo = (object.campaignInfo !== undefined && object.campaignInfo !== null)
            ? TradingRewardCampaignInfo.fromPartial(object.campaignInfo)
            : undefined;
        message.campaignRewardPools = object.campaignRewardPools?.map((e) => CampaignRewardPool.fromPartial(e)) || [];
        return message;
    },
};
function createBaseTradingRewardCampaignUpdateProposal() {
    return {
        title: "",
        description: "",
        campaignInfo: undefined,
        campaignRewardPoolsAdditions: [],
        campaignRewardPoolsUpdates: [],
    };
}
export const TradingRewardCampaignUpdateProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.campaignInfo !== undefined) {
            TradingRewardCampaignInfo.encode(message.campaignInfo, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.campaignRewardPoolsAdditions) {
            CampaignRewardPool.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.campaignRewardPoolsUpdates) {
            CampaignRewardPool.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardCampaignUpdateProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.campaignInfo = TradingRewardCampaignInfo.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.campaignRewardPoolsAdditions.push(CampaignRewardPool.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.campaignRewardPoolsUpdates.push(CampaignRewardPool.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            campaignInfo: isSet(object.campaignInfo) ? TradingRewardCampaignInfo.fromJSON(object.campaignInfo) : undefined,
            campaignRewardPoolsAdditions: Array.isArray(object?.campaignRewardPoolsAdditions)
                ? object.campaignRewardPoolsAdditions.map((e) => CampaignRewardPool.fromJSON(e))
                : [],
            campaignRewardPoolsUpdates: Array.isArray(object?.campaignRewardPoolsUpdates)
                ? object.campaignRewardPoolsUpdates.map((e) => CampaignRewardPool.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.campaignInfo !== undefined &&
            (obj.campaignInfo = message.campaignInfo ? TradingRewardCampaignInfo.toJSON(message.campaignInfo) : undefined);
        if (message.campaignRewardPoolsAdditions) {
            obj.campaignRewardPoolsAdditions = message.campaignRewardPoolsAdditions.map((e) => e ? CampaignRewardPool.toJSON(e) : undefined);
        }
        else {
            obj.campaignRewardPoolsAdditions = [];
        }
        if (message.campaignRewardPoolsUpdates) {
            obj.campaignRewardPoolsUpdates = message.campaignRewardPoolsUpdates.map((e) => e ? CampaignRewardPool.toJSON(e) : undefined);
        }
        else {
            obj.campaignRewardPoolsUpdates = [];
        }
        return obj;
    },
    create(base) {
        return TradingRewardCampaignUpdateProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardCampaignUpdateProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.campaignInfo = (object.campaignInfo !== undefined && object.campaignInfo !== null)
            ? TradingRewardCampaignInfo.fromPartial(object.campaignInfo)
            : undefined;
        message.campaignRewardPoolsAdditions =
            object.campaignRewardPoolsAdditions?.map((e) => CampaignRewardPool.fromPartial(e)) || [];
        message.campaignRewardPoolsUpdates =
            object.campaignRewardPoolsUpdates?.map((e) => CampaignRewardPool.fromPartial(e)) || [];
        return message;
    },
};
function createBaseRewardPointUpdate() {
    return { accountAddress: "", newPoints: "" };
}
export const RewardPointUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountAddress !== "") {
            writer.uint32(10).string(message.accountAddress);
        }
        if (message.newPoints !== "") {
            writer.uint32(98).string(message.newPoints);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRewardPointUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountAddress = reader.string();
                    break;
                case 12:
                    message.newPoints = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            newPoints: isSet(object.newPoints) ? String(object.newPoints) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.newPoints !== undefined && (obj.newPoints = message.newPoints);
        return obj;
    },
    create(base) {
        return RewardPointUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRewardPointUpdate();
        message.accountAddress = object.accountAddress ?? "";
        message.newPoints = object.newPoints ?? "";
        return message;
    },
};
function createBaseTradingRewardPendingPointsUpdateProposal() {
    return { title: "", description: "", pendingPoolTimestamp: "0", rewardPointUpdates: [] };
}
export const TradingRewardPendingPointsUpdateProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.pendingPoolTimestamp !== "0") {
            writer.uint32(24).int64(message.pendingPoolTimestamp);
        }
        for (const v of message.rewardPointUpdates) {
            RewardPointUpdate.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardPendingPointsUpdateProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.pendingPoolTimestamp = longToString(reader.int64());
                    break;
                case 4:
                    message.rewardPointUpdates.push(RewardPointUpdate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            pendingPoolTimestamp: isSet(object.pendingPoolTimestamp) ? String(object.pendingPoolTimestamp) : "0",
            rewardPointUpdates: Array.isArray(object?.rewardPointUpdates)
                ? object.rewardPointUpdates.map((e) => RewardPointUpdate.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.pendingPoolTimestamp !== undefined && (obj.pendingPoolTimestamp = message.pendingPoolTimestamp);
        if (message.rewardPointUpdates) {
            obj.rewardPointUpdates = message.rewardPointUpdates.map((e) => e ? RewardPointUpdate.toJSON(e) : undefined);
        }
        else {
            obj.rewardPointUpdates = [];
        }
        return obj;
    },
    create(base) {
        return TradingRewardPendingPointsUpdateProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardPendingPointsUpdateProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.pendingPoolTimestamp = object.pendingPoolTimestamp ?? "0";
        message.rewardPointUpdates = object.rewardPointUpdates?.map((e) => RewardPointUpdate.fromPartial(e)) || [];
        return message;
    },
};
function createBaseFeeDiscountProposal() {
    return { title: "", description: "", schedule: undefined };
}
export const FeeDiscountProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.schedule !== undefined) {
            FeeDiscountSchedule.encode(message.schedule, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeeDiscountProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.schedule = FeeDiscountSchedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            schedule: isSet(object.schedule) ? FeeDiscountSchedule.fromJSON(object.schedule) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.schedule !== undefined &&
            (obj.schedule = message.schedule ? FeeDiscountSchedule.toJSON(message.schedule) : undefined);
        return obj;
    },
    create(base) {
        return FeeDiscountProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeeDiscountProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.schedule = (object.schedule !== undefined && object.schedule !== null)
            ? FeeDiscountSchedule.fromPartial(object.schedule)
            : undefined;
        return message;
    },
};
function createBaseBatchCommunityPoolSpendProposal() {
    return { title: "", description: "", proposals: [] };
}
export const BatchCommunityPoolSpendProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.proposals) {
            CommunityPoolSpendProposal.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchCommunityPoolSpendProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.proposals.push(CommunityPoolSpendProposal.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            proposals: Array.isArray(object?.proposals)
                ? object.proposals.map((e) => CommunityPoolSpendProposal.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.proposals) {
            obj.proposals = message.proposals.map((e) => e ? CommunityPoolSpendProposal.toJSON(e) : undefined);
        }
        else {
            obj.proposals = [];
        }
        return obj;
    },
    create(base) {
        return BatchCommunityPoolSpendProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchCommunityPoolSpendProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.proposals = object.proposals?.map((e) => CommunityPoolSpendProposal.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAtomicMarketOrderFeeMultiplierScheduleProposal() {
    return { title: "", description: "", marketFeeMultipliers: [] };
}
export const AtomicMarketOrderFeeMultiplierScheduleProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.marketFeeMultipliers) {
            MarketFeeMultiplier.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAtomicMarketOrderFeeMultiplierScheduleProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.marketFeeMultipliers.push(MarketFeeMultiplier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            marketFeeMultipliers: Array.isArray(object?.marketFeeMultipliers)
                ? object.marketFeeMultipliers.map((e) => MarketFeeMultiplier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.marketFeeMultipliers) {
            obj.marketFeeMultipliers = message.marketFeeMultipliers.map((e) => e ? MarketFeeMultiplier.toJSON(e) : undefined);
        }
        else {
            obj.marketFeeMultipliers = [];
        }
        return obj;
    },
    create(base) {
        return AtomicMarketOrderFeeMultiplierScheduleProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAtomicMarketOrderFeeMultiplierScheduleProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.marketFeeMultipliers = object.marketFeeMultipliers?.map((e) => MarketFeeMultiplier.fromPartial(e)) || [];
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
