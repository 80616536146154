/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { AccessConfig } from "../../../cosmwasm/wasm/v1/types.js";
import { fundingModeFromJSON, fundingModeToJSON } from "./proposal.js";
export const protobufPackage = "injective.wasmx.v1";
function createBaseParams() {
    return {
        isExecutionEnabled: false,
        maxBeginBlockTotalGas: "0",
        maxContractGasLimit: "0",
        minGasPrice: "0",
        registerContractAccess: undefined,
    };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.isExecutionEnabled === true) {
            writer.uint32(8).bool(message.isExecutionEnabled);
        }
        if (message.maxBeginBlockTotalGas !== "0") {
            writer.uint32(16).uint64(message.maxBeginBlockTotalGas);
        }
        if (message.maxContractGasLimit !== "0") {
            writer.uint32(24).uint64(message.maxContractGasLimit);
        }
        if (message.minGasPrice !== "0") {
            writer.uint32(32).uint64(message.minGasPrice);
        }
        if (message.registerContractAccess !== undefined) {
            AccessConfig.encode(message.registerContractAccess, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isExecutionEnabled = reader.bool();
                    break;
                case 2:
                    message.maxBeginBlockTotalGas = longToString(reader.uint64());
                    break;
                case 3:
                    message.maxContractGasLimit = longToString(reader.uint64());
                    break;
                case 4:
                    message.minGasPrice = longToString(reader.uint64());
                    break;
                case 5:
                    message.registerContractAccess = AccessConfig.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            isExecutionEnabled: isSet(object.isExecutionEnabled) ? Boolean(object.isExecutionEnabled) : false,
            maxBeginBlockTotalGas: isSet(object.maxBeginBlockTotalGas) ? String(object.maxBeginBlockTotalGas) : "0",
            maxContractGasLimit: isSet(object.maxContractGasLimit) ? String(object.maxContractGasLimit) : "0",
            minGasPrice: isSet(object.minGasPrice) ? String(object.minGasPrice) : "0",
            registerContractAccess: isSet(object.registerContractAccess)
                ? AccessConfig.fromJSON(object.registerContractAccess)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.isExecutionEnabled !== undefined && (obj.isExecutionEnabled = message.isExecutionEnabled);
        message.maxBeginBlockTotalGas !== undefined && (obj.maxBeginBlockTotalGas = message.maxBeginBlockTotalGas);
        message.maxContractGasLimit !== undefined && (obj.maxContractGasLimit = message.maxContractGasLimit);
        message.minGasPrice !== undefined && (obj.minGasPrice = message.minGasPrice);
        message.registerContractAccess !== undefined && (obj.registerContractAccess = message.registerContractAccess
            ? AccessConfig.toJSON(message.registerContractAccess)
            : undefined);
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.isExecutionEnabled = object.isExecutionEnabled ?? false;
        message.maxBeginBlockTotalGas = object.maxBeginBlockTotalGas ?? "0";
        message.maxContractGasLimit = object.maxContractGasLimit ?? "0";
        message.minGasPrice = object.minGasPrice ?? "0";
        message.registerContractAccess =
            (object.registerContractAccess !== undefined && object.registerContractAccess !== null)
                ? AccessConfig.fromPartial(object.registerContractAccess)
                : undefined;
        return message;
    },
};
function createBaseRegisteredContract() {
    return {
        gasLimit: "0",
        gasPrice: "0",
        isExecutable: false,
        codeId: "0",
        adminAddress: "",
        granterAddress: "",
        fundMode: 0,
    };
}
export const RegisteredContract = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.gasLimit !== "0") {
            writer.uint32(8).uint64(message.gasLimit);
        }
        if (message.gasPrice !== "0") {
            writer.uint32(16).uint64(message.gasPrice);
        }
        if (message.isExecutable === true) {
            writer.uint32(24).bool(message.isExecutable);
        }
        if (message.codeId !== "0") {
            writer.uint32(32).uint64(message.codeId);
        }
        if (message.adminAddress !== "") {
            writer.uint32(42).string(message.adminAddress);
        }
        if (message.granterAddress !== "") {
            writer.uint32(50).string(message.granterAddress);
        }
        if (message.fundMode !== 0) {
            writer.uint32(56).int32(message.fundMode);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRegisteredContract();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.gasLimit = longToString(reader.uint64());
                    break;
                case 2:
                    message.gasPrice = longToString(reader.uint64());
                    break;
                case 3:
                    message.isExecutable = reader.bool();
                    break;
                case 4:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 5:
                    message.adminAddress = reader.string();
                    break;
                case 6:
                    message.granterAddress = reader.string();
                    break;
                case 7:
                    message.fundMode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            gasLimit: isSet(object.gasLimit) ? String(object.gasLimit) : "0",
            gasPrice: isSet(object.gasPrice) ? String(object.gasPrice) : "0",
            isExecutable: isSet(object.isExecutable) ? Boolean(object.isExecutable) : false,
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            adminAddress: isSet(object.adminAddress) ? String(object.adminAddress) : "",
            granterAddress: isSet(object.granterAddress) ? String(object.granterAddress) : "",
            fundMode: isSet(object.fundMode) ? fundingModeFromJSON(object.fundMode) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.gasLimit !== undefined && (obj.gasLimit = message.gasLimit);
        message.gasPrice !== undefined && (obj.gasPrice = message.gasPrice);
        message.isExecutable !== undefined && (obj.isExecutable = message.isExecutable);
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.adminAddress !== undefined && (obj.adminAddress = message.adminAddress);
        message.granterAddress !== undefined && (obj.granterAddress = message.granterAddress);
        message.fundMode !== undefined && (obj.fundMode = fundingModeToJSON(message.fundMode));
        return obj;
    },
    create(base) {
        return RegisteredContract.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRegisteredContract();
        message.gasLimit = object.gasLimit ?? "0";
        message.gasPrice = object.gasPrice ?? "0";
        message.isExecutable = object.isExecutable ?? false;
        message.codeId = object.codeId ?? "0";
        message.adminAddress = object.adminAddress ?? "";
        message.granterAddress = object.granterAddress ?? "";
        message.fundMode = object.fundMode ?? 0;
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
