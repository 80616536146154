/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { ActiveGrant, AggregateSubaccountVolumeRecord, BinaryOptionsMarket, CampaignRewardPool, DenomDecimals, Deposit, DerivativeLimitOrder, DerivativeMarket, DerivativeMarketOrder, DerivativeMarketSettlementInfo, ExpiryFuturesMarketInfo, FeeDiscountSchedule, FeeDiscountTierTTL, GrantAuthorization, MarketFeeMultiplier, MarketVolume, Params, PerpetualMarketFunding, PerpetualMarketInfo, Position, SpotLimitOrder, SpotMarket, SubaccountTradeNonce, TradeRecords, TradingRewardCampaignInfo, } from "./exchange.js";
export const protobufPackage = "injective.exchange.v1beta1";
function createBaseGenesisState() {
    return {
        params: undefined,
        spotMarkets: [],
        derivativeMarkets: [],
        spotOrderbook: [],
        derivativeOrderbook: [],
        balances: [],
        positions: [],
        subaccountTradeNonces: [],
        expiryFuturesMarketInfoState: [],
        perpetualMarketInfo: [],
        perpetualMarketFundingState: [],
        derivativeMarketSettlementScheduled: [],
        isSpotExchangeEnabled: false,
        isDerivativesExchangeEnabled: false,
        tradingRewardCampaignInfo: undefined,
        tradingRewardPoolCampaignSchedule: [],
        tradingRewardCampaignAccountPoints: [],
        feeDiscountSchedule: undefined,
        feeDiscountAccountTierTtl: [],
        feeDiscountBucketVolumeAccounts: [],
        isFirstFeeCycleFinished: false,
        pendingTradingRewardPoolCampaignSchedule: [],
        pendingTradingRewardCampaignAccountPoints: [],
        rewardsOptOutAddresses: [],
        historicalTradeRecords: [],
        binaryOptionsMarkets: [],
        binaryOptionsMarketIdsScheduledForSettlement: [],
        spotMarketIdsScheduledToForceClose: [],
        denomDecimals: [],
        conditionalDerivativeOrderbooks: [],
        marketFeeMultipliers: [],
        orderbookSequences: [],
        subaccountVolumes: [],
        marketVolumes: [],
        grantAuthorizations: [],
        activeGrants: [],
    };
}
export const GenesisState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.spotMarkets) {
            SpotMarket.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.derivativeMarkets) {
            DerivativeMarket.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.spotOrderbook) {
            SpotOrderBook.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.derivativeOrderbook) {
            DerivativeOrderBook.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.balances) {
            Balance.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.positions) {
            DerivativePosition.encode(v, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.subaccountTradeNonces) {
            SubaccountNonce.encode(v, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.expiryFuturesMarketInfoState) {
            ExpiryFuturesMarketInfoState.encode(v, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.perpetualMarketInfo) {
            PerpetualMarketInfo.encode(v, writer.uint32(82).fork()).ldelim();
        }
        for (const v of message.perpetualMarketFundingState) {
            PerpetualMarketFundingState.encode(v, writer.uint32(90).fork()).ldelim();
        }
        for (const v of message.derivativeMarketSettlementScheduled) {
            DerivativeMarketSettlementInfo.encode(v, writer.uint32(98).fork()).ldelim();
        }
        if (message.isSpotExchangeEnabled === true) {
            writer.uint32(104).bool(message.isSpotExchangeEnabled);
        }
        if (message.isDerivativesExchangeEnabled === true) {
            writer.uint32(112).bool(message.isDerivativesExchangeEnabled);
        }
        if (message.tradingRewardCampaignInfo !== undefined) {
            TradingRewardCampaignInfo.encode(message.tradingRewardCampaignInfo, writer.uint32(122).fork()).ldelim();
        }
        for (const v of message.tradingRewardPoolCampaignSchedule) {
            CampaignRewardPool.encode(v, writer.uint32(130).fork()).ldelim();
        }
        for (const v of message.tradingRewardCampaignAccountPoints) {
            TradingRewardCampaignAccountPoints.encode(v, writer.uint32(138).fork()).ldelim();
        }
        if (message.feeDiscountSchedule !== undefined) {
            FeeDiscountSchedule.encode(message.feeDiscountSchedule, writer.uint32(146).fork()).ldelim();
        }
        for (const v of message.feeDiscountAccountTierTtl) {
            FeeDiscountAccountTierTTL.encode(v, writer.uint32(154).fork()).ldelim();
        }
        for (const v of message.feeDiscountBucketVolumeAccounts) {
            FeeDiscountBucketVolumeAccounts.encode(v, writer.uint32(162).fork()).ldelim();
        }
        if (message.isFirstFeeCycleFinished === true) {
            writer.uint32(168).bool(message.isFirstFeeCycleFinished);
        }
        for (const v of message.pendingTradingRewardPoolCampaignSchedule) {
            CampaignRewardPool.encode(v, writer.uint32(178).fork()).ldelim();
        }
        for (const v of message.pendingTradingRewardCampaignAccountPoints) {
            TradingRewardCampaignAccountPendingPoints.encode(v, writer.uint32(186).fork()).ldelim();
        }
        for (const v of message.rewardsOptOutAddresses) {
            writer.uint32(194).string(v);
        }
        for (const v of message.historicalTradeRecords) {
            TradeRecords.encode(v, writer.uint32(202).fork()).ldelim();
        }
        for (const v of message.binaryOptionsMarkets) {
            BinaryOptionsMarket.encode(v, writer.uint32(210).fork()).ldelim();
        }
        for (const v of message.binaryOptionsMarketIdsScheduledForSettlement) {
            writer.uint32(218).string(v);
        }
        for (const v of message.spotMarketIdsScheduledToForceClose) {
            writer.uint32(226).string(v);
        }
        for (const v of message.denomDecimals) {
            DenomDecimals.encode(v, writer.uint32(234).fork()).ldelim();
        }
        for (const v of message.conditionalDerivativeOrderbooks) {
            ConditionalDerivativeOrderBook.encode(v, writer.uint32(242).fork()).ldelim();
        }
        for (const v of message.marketFeeMultipliers) {
            MarketFeeMultiplier.encode(v, writer.uint32(250).fork()).ldelim();
        }
        for (const v of message.orderbookSequences) {
            OrderbookSequence.encode(v, writer.uint32(258).fork()).ldelim();
        }
        for (const v of message.subaccountVolumes) {
            AggregateSubaccountVolumeRecord.encode(v, writer.uint32(266).fork()).ldelim();
        }
        for (const v of message.marketVolumes) {
            MarketVolume.encode(v, writer.uint32(274).fork()).ldelim();
        }
        for (const v of message.grantAuthorizations) {
            FullGrantAuthorizations.encode(v, writer.uint32(282).fork()).ldelim();
        }
        for (const v of message.activeGrants) {
            FullActiveGrant.encode(v, writer.uint32(290).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenesisState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.spotMarkets.push(SpotMarket.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.derivativeMarkets.push(DerivativeMarket.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.spotOrderbook.push(SpotOrderBook.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.derivativeOrderbook.push(DerivativeOrderBook.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.balances.push(Balance.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.positions.push(DerivativePosition.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.subaccountTradeNonces.push(SubaccountNonce.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.expiryFuturesMarketInfoState.push(ExpiryFuturesMarketInfoState.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.perpetualMarketInfo.push(PerpetualMarketInfo.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.perpetualMarketFundingState.push(PerpetualMarketFundingState.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.derivativeMarketSettlementScheduled.push(DerivativeMarketSettlementInfo.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.isSpotExchangeEnabled = reader.bool();
                    break;
                case 14:
                    message.isDerivativesExchangeEnabled = reader.bool();
                    break;
                case 15:
                    message.tradingRewardCampaignInfo = TradingRewardCampaignInfo.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.tradingRewardPoolCampaignSchedule.push(CampaignRewardPool.decode(reader, reader.uint32()));
                    break;
                case 17:
                    message.tradingRewardCampaignAccountPoints.push(TradingRewardCampaignAccountPoints.decode(reader, reader.uint32()));
                    break;
                case 18:
                    message.feeDiscountSchedule = FeeDiscountSchedule.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.feeDiscountAccountTierTtl.push(FeeDiscountAccountTierTTL.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.feeDiscountBucketVolumeAccounts.push(FeeDiscountBucketVolumeAccounts.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.isFirstFeeCycleFinished = reader.bool();
                    break;
                case 22:
                    message.pendingTradingRewardPoolCampaignSchedule.push(CampaignRewardPool.decode(reader, reader.uint32()));
                    break;
                case 23:
                    message.pendingTradingRewardCampaignAccountPoints.push(TradingRewardCampaignAccountPendingPoints.decode(reader, reader.uint32()));
                    break;
                case 24:
                    message.rewardsOptOutAddresses.push(reader.string());
                    break;
                case 25:
                    message.historicalTradeRecords.push(TradeRecords.decode(reader, reader.uint32()));
                    break;
                case 26:
                    message.binaryOptionsMarkets.push(BinaryOptionsMarket.decode(reader, reader.uint32()));
                    break;
                case 27:
                    message.binaryOptionsMarketIdsScheduledForSettlement.push(reader.string());
                    break;
                case 28:
                    message.spotMarketIdsScheduledToForceClose.push(reader.string());
                    break;
                case 29:
                    message.denomDecimals.push(DenomDecimals.decode(reader, reader.uint32()));
                    break;
                case 30:
                    message.conditionalDerivativeOrderbooks.push(ConditionalDerivativeOrderBook.decode(reader, reader.uint32()));
                    break;
                case 31:
                    message.marketFeeMultipliers.push(MarketFeeMultiplier.decode(reader, reader.uint32()));
                    break;
                case 32:
                    message.orderbookSequences.push(OrderbookSequence.decode(reader, reader.uint32()));
                    break;
                case 33:
                    message.subaccountVolumes.push(AggregateSubaccountVolumeRecord.decode(reader, reader.uint32()));
                    break;
                case 34:
                    message.marketVolumes.push(MarketVolume.decode(reader, reader.uint32()));
                    break;
                case 35:
                    message.grantAuthorizations.push(FullGrantAuthorizations.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.activeGrants.push(FullActiveGrant.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
            spotMarkets: Array.isArray(object?.spotMarkets) ? object.spotMarkets.map((e) => SpotMarket.fromJSON(e)) : [],
            derivativeMarkets: Array.isArray(object?.derivativeMarkets)
                ? object.derivativeMarkets.map((e) => DerivativeMarket.fromJSON(e))
                : [],
            spotOrderbook: Array.isArray(object?.spotOrderbook)
                ? object.spotOrderbook.map((e) => SpotOrderBook.fromJSON(e))
                : [],
            derivativeOrderbook: Array.isArray(object?.derivativeOrderbook)
                ? object.derivativeOrderbook.map((e) => DerivativeOrderBook.fromJSON(e))
                : [],
            balances: Array.isArray(object?.balances) ? object.balances.map((e) => Balance.fromJSON(e)) : [],
            positions: Array.isArray(object?.positions)
                ? object.positions.map((e) => DerivativePosition.fromJSON(e))
                : [],
            subaccountTradeNonces: Array.isArray(object?.subaccountTradeNonces)
                ? object.subaccountTradeNonces.map((e) => SubaccountNonce.fromJSON(e))
                : [],
            expiryFuturesMarketInfoState: Array.isArray(object?.expiryFuturesMarketInfoState)
                ? object.expiryFuturesMarketInfoState.map((e) => ExpiryFuturesMarketInfoState.fromJSON(e))
                : [],
            perpetualMarketInfo: Array.isArray(object?.perpetualMarketInfo)
                ? object.perpetualMarketInfo.map((e) => PerpetualMarketInfo.fromJSON(e))
                : [],
            perpetualMarketFundingState: Array.isArray(object?.perpetualMarketFundingState)
                ? object.perpetualMarketFundingState.map((e) => PerpetualMarketFundingState.fromJSON(e))
                : [],
            derivativeMarketSettlementScheduled: Array.isArray(object?.derivativeMarketSettlementScheduled)
                ? object.derivativeMarketSettlementScheduled.map((e) => DerivativeMarketSettlementInfo.fromJSON(e))
                : [],
            isSpotExchangeEnabled: isSet(object.isSpotExchangeEnabled) ? Boolean(object.isSpotExchangeEnabled) : false,
            isDerivativesExchangeEnabled: isSet(object.isDerivativesExchangeEnabled)
                ? Boolean(object.isDerivativesExchangeEnabled)
                : false,
            tradingRewardCampaignInfo: isSet(object.tradingRewardCampaignInfo)
                ? TradingRewardCampaignInfo.fromJSON(object.tradingRewardCampaignInfo)
                : undefined,
            tradingRewardPoolCampaignSchedule: Array.isArray(object?.tradingRewardPoolCampaignSchedule)
                ? object.tradingRewardPoolCampaignSchedule.map((e) => CampaignRewardPool.fromJSON(e))
                : [],
            tradingRewardCampaignAccountPoints: Array.isArray(object?.tradingRewardCampaignAccountPoints)
                ? object.tradingRewardCampaignAccountPoints.map((e) => TradingRewardCampaignAccountPoints.fromJSON(e))
                : [],
            feeDiscountSchedule: isSet(object.feeDiscountSchedule)
                ? FeeDiscountSchedule.fromJSON(object.feeDiscountSchedule)
                : undefined,
            feeDiscountAccountTierTtl: Array.isArray(object?.feeDiscountAccountTierTtl)
                ? object.feeDiscountAccountTierTtl.map((e) => FeeDiscountAccountTierTTL.fromJSON(e))
                : [],
            feeDiscountBucketVolumeAccounts: Array.isArray(object?.feeDiscountBucketVolumeAccounts)
                ? object.feeDiscountBucketVolumeAccounts.map((e) => FeeDiscountBucketVolumeAccounts.fromJSON(e))
                : [],
            isFirstFeeCycleFinished: isSet(object.isFirstFeeCycleFinished) ? Boolean(object.isFirstFeeCycleFinished) : false,
            pendingTradingRewardPoolCampaignSchedule: Array.isArray(object?.pendingTradingRewardPoolCampaignSchedule)
                ? object.pendingTradingRewardPoolCampaignSchedule.map((e) => CampaignRewardPool.fromJSON(e))
                : [],
            pendingTradingRewardCampaignAccountPoints: Array.isArray(object?.pendingTradingRewardCampaignAccountPoints)
                ? object.pendingTradingRewardCampaignAccountPoints.map((e) => TradingRewardCampaignAccountPendingPoints.fromJSON(e))
                : [],
            rewardsOptOutAddresses: Array.isArray(object?.rewardsOptOutAddresses)
                ? object.rewardsOptOutAddresses.map((e) => String(e))
                : [],
            historicalTradeRecords: Array.isArray(object?.historicalTradeRecords)
                ? object.historicalTradeRecords.map((e) => TradeRecords.fromJSON(e))
                : [],
            binaryOptionsMarkets: Array.isArray(object?.binaryOptionsMarkets)
                ? object.binaryOptionsMarkets.map((e) => BinaryOptionsMarket.fromJSON(e))
                : [],
            binaryOptionsMarketIdsScheduledForSettlement: Array.isArray(object?.binaryOptionsMarketIdsScheduledForSettlement)
                ? object.binaryOptionsMarketIdsScheduledForSettlement.map((e) => String(e))
                : [],
            spotMarketIdsScheduledToForceClose: Array.isArray(object?.spotMarketIdsScheduledToForceClose)
                ? object.spotMarketIdsScheduledToForceClose.map((e) => String(e))
                : [],
            denomDecimals: Array.isArray(object?.denomDecimals)
                ? object.denomDecimals.map((e) => DenomDecimals.fromJSON(e))
                : [],
            conditionalDerivativeOrderbooks: Array.isArray(object?.conditionalDerivativeOrderbooks)
                ? object.conditionalDerivativeOrderbooks.map((e) => ConditionalDerivativeOrderBook.fromJSON(e))
                : [],
            marketFeeMultipliers: Array.isArray(object?.marketFeeMultipliers)
                ? object.marketFeeMultipliers.map((e) => MarketFeeMultiplier.fromJSON(e))
                : [],
            orderbookSequences: Array.isArray(object?.orderbookSequences)
                ? object.orderbookSequences.map((e) => OrderbookSequence.fromJSON(e))
                : [],
            subaccountVolumes: Array.isArray(object?.subaccountVolumes)
                ? object.subaccountVolumes.map((e) => AggregateSubaccountVolumeRecord.fromJSON(e))
                : [],
            marketVolumes: Array.isArray(object?.marketVolumes)
                ? object.marketVolumes.map((e) => MarketVolume.fromJSON(e))
                : [],
            grantAuthorizations: Array.isArray(object?.grantAuthorizations)
                ? object.grantAuthorizations.map((e) => FullGrantAuthorizations.fromJSON(e))
                : [],
            activeGrants: Array.isArray(object?.activeGrants)
                ? object.activeGrants.map((e) => FullActiveGrant.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        if (message.spotMarkets) {
            obj.spotMarkets = message.spotMarkets.map((e) => e ? SpotMarket.toJSON(e) : undefined);
        }
        else {
            obj.spotMarkets = [];
        }
        if (message.derivativeMarkets) {
            obj.derivativeMarkets = message.derivativeMarkets.map((e) => e ? DerivativeMarket.toJSON(e) : undefined);
        }
        else {
            obj.derivativeMarkets = [];
        }
        if (message.spotOrderbook) {
            obj.spotOrderbook = message.spotOrderbook.map((e) => e ? SpotOrderBook.toJSON(e) : undefined);
        }
        else {
            obj.spotOrderbook = [];
        }
        if (message.derivativeOrderbook) {
            obj.derivativeOrderbook = message.derivativeOrderbook.map((e) => e ? DerivativeOrderBook.toJSON(e) : undefined);
        }
        else {
            obj.derivativeOrderbook = [];
        }
        if (message.balances) {
            obj.balances = message.balances.map((e) => e ? Balance.toJSON(e) : undefined);
        }
        else {
            obj.balances = [];
        }
        if (message.positions) {
            obj.positions = message.positions.map((e) => e ? DerivativePosition.toJSON(e) : undefined);
        }
        else {
            obj.positions = [];
        }
        if (message.subaccountTradeNonces) {
            obj.subaccountTradeNonces = message.subaccountTradeNonces.map((e) => e ? SubaccountNonce.toJSON(e) : undefined);
        }
        else {
            obj.subaccountTradeNonces = [];
        }
        if (message.expiryFuturesMarketInfoState) {
            obj.expiryFuturesMarketInfoState = message.expiryFuturesMarketInfoState.map((e) => e ? ExpiryFuturesMarketInfoState.toJSON(e) : undefined);
        }
        else {
            obj.expiryFuturesMarketInfoState = [];
        }
        if (message.perpetualMarketInfo) {
            obj.perpetualMarketInfo = message.perpetualMarketInfo.map((e) => e ? PerpetualMarketInfo.toJSON(e) : undefined);
        }
        else {
            obj.perpetualMarketInfo = [];
        }
        if (message.perpetualMarketFundingState) {
            obj.perpetualMarketFundingState = message.perpetualMarketFundingState.map((e) => e ? PerpetualMarketFundingState.toJSON(e) : undefined);
        }
        else {
            obj.perpetualMarketFundingState = [];
        }
        if (message.derivativeMarketSettlementScheduled) {
            obj.derivativeMarketSettlementScheduled = message.derivativeMarketSettlementScheduled.map((e) => e ? DerivativeMarketSettlementInfo.toJSON(e) : undefined);
        }
        else {
            obj.derivativeMarketSettlementScheduled = [];
        }
        message.isSpotExchangeEnabled !== undefined && (obj.isSpotExchangeEnabled = message.isSpotExchangeEnabled);
        message.isDerivativesExchangeEnabled !== undefined &&
            (obj.isDerivativesExchangeEnabled = message.isDerivativesExchangeEnabled);
        message.tradingRewardCampaignInfo !== undefined &&
            (obj.tradingRewardCampaignInfo = message.tradingRewardCampaignInfo
                ? TradingRewardCampaignInfo.toJSON(message.tradingRewardCampaignInfo)
                : undefined);
        if (message.tradingRewardPoolCampaignSchedule) {
            obj.tradingRewardPoolCampaignSchedule = message.tradingRewardPoolCampaignSchedule.map((e) => e ? CampaignRewardPool.toJSON(e) : undefined);
        }
        else {
            obj.tradingRewardPoolCampaignSchedule = [];
        }
        if (message.tradingRewardCampaignAccountPoints) {
            obj.tradingRewardCampaignAccountPoints = message.tradingRewardCampaignAccountPoints.map((e) => e ? TradingRewardCampaignAccountPoints.toJSON(e) : undefined);
        }
        else {
            obj.tradingRewardCampaignAccountPoints = [];
        }
        message.feeDiscountSchedule !== undefined && (obj.feeDiscountSchedule = message.feeDiscountSchedule
            ? FeeDiscountSchedule.toJSON(message.feeDiscountSchedule)
            : undefined);
        if (message.feeDiscountAccountTierTtl) {
            obj.feeDiscountAccountTierTtl = message.feeDiscountAccountTierTtl.map((e) => e ? FeeDiscountAccountTierTTL.toJSON(e) : undefined);
        }
        else {
            obj.feeDiscountAccountTierTtl = [];
        }
        if (message.feeDiscountBucketVolumeAccounts) {
            obj.feeDiscountBucketVolumeAccounts = message.feeDiscountBucketVolumeAccounts.map((e) => e ? FeeDiscountBucketVolumeAccounts.toJSON(e) : undefined);
        }
        else {
            obj.feeDiscountBucketVolumeAccounts = [];
        }
        message.isFirstFeeCycleFinished !== undefined && (obj.isFirstFeeCycleFinished = message.isFirstFeeCycleFinished);
        if (message.pendingTradingRewardPoolCampaignSchedule) {
            obj.pendingTradingRewardPoolCampaignSchedule = message.pendingTradingRewardPoolCampaignSchedule.map((e) => e ? CampaignRewardPool.toJSON(e) : undefined);
        }
        else {
            obj.pendingTradingRewardPoolCampaignSchedule = [];
        }
        if (message.pendingTradingRewardCampaignAccountPoints) {
            obj.pendingTradingRewardCampaignAccountPoints = message.pendingTradingRewardCampaignAccountPoints.map((e) => e ? TradingRewardCampaignAccountPendingPoints.toJSON(e) : undefined);
        }
        else {
            obj.pendingTradingRewardCampaignAccountPoints = [];
        }
        if (message.rewardsOptOutAddresses) {
            obj.rewardsOptOutAddresses = message.rewardsOptOutAddresses.map((e) => e);
        }
        else {
            obj.rewardsOptOutAddresses = [];
        }
        if (message.historicalTradeRecords) {
            obj.historicalTradeRecords = message.historicalTradeRecords.map((e) => e ? TradeRecords.toJSON(e) : undefined);
        }
        else {
            obj.historicalTradeRecords = [];
        }
        if (message.binaryOptionsMarkets) {
            obj.binaryOptionsMarkets = message.binaryOptionsMarkets.map((e) => e ? BinaryOptionsMarket.toJSON(e) : undefined);
        }
        else {
            obj.binaryOptionsMarkets = [];
        }
        if (message.binaryOptionsMarketIdsScheduledForSettlement) {
            obj.binaryOptionsMarketIdsScheduledForSettlement = message.binaryOptionsMarketIdsScheduledForSettlement.map((e) => e);
        }
        else {
            obj.binaryOptionsMarketIdsScheduledForSettlement = [];
        }
        if (message.spotMarketIdsScheduledToForceClose) {
            obj.spotMarketIdsScheduledToForceClose = message.spotMarketIdsScheduledToForceClose.map((e) => e);
        }
        else {
            obj.spotMarketIdsScheduledToForceClose = [];
        }
        if (message.denomDecimals) {
            obj.denomDecimals = message.denomDecimals.map((e) => e ? DenomDecimals.toJSON(e) : undefined);
        }
        else {
            obj.denomDecimals = [];
        }
        if (message.conditionalDerivativeOrderbooks) {
            obj.conditionalDerivativeOrderbooks = message.conditionalDerivativeOrderbooks.map((e) => e ? ConditionalDerivativeOrderBook.toJSON(e) : undefined);
        }
        else {
            obj.conditionalDerivativeOrderbooks = [];
        }
        if (message.marketFeeMultipliers) {
            obj.marketFeeMultipliers = message.marketFeeMultipliers.map((e) => e ? MarketFeeMultiplier.toJSON(e) : undefined);
        }
        else {
            obj.marketFeeMultipliers = [];
        }
        if (message.orderbookSequences) {
            obj.orderbookSequences = message.orderbookSequences.map((e) => e ? OrderbookSequence.toJSON(e) : undefined);
        }
        else {
            obj.orderbookSequences = [];
        }
        if (message.subaccountVolumes) {
            obj.subaccountVolumes = message.subaccountVolumes.map((e) => e ? AggregateSubaccountVolumeRecord.toJSON(e) : undefined);
        }
        else {
            obj.subaccountVolumes = [];
        }
        if (message.marketVolumes) {
            obj.marketVolumes = message.marketVolumes.map((e) => e ? MarketVolume.toJSON(e) : undefined);
        }
        else {
            obj.marketVolumes = [];
        }
        if (message.grantAuthorizations) {
            obj.grantAuthorizations = message.grantAuthorizations.map((e) => e ? FullGrantAuthorizations.toJSON(e) : undefined);
        }
        else {
            obj.grantAuthorizations = [];
        }
        if (message.activeGrants) {
            obj.activeGrants = message.activeGrants.map((e) => e ? FullActiveGrant.toJSON(e) : undefined);
        }
        else {
            obj.activeGrants = [];
        }
        return obj;
    },
    create(base) {
        return GenesisState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGenesisState();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        message.spotMarkets = object.spotMarkets?.map((e) => SpotMarket.fromPartial(e)) || [];
        message.derivativeMarkets = object.derivativeMarkets?.map((e) => DerivativeMarket.fromPartial(e)) || [];
        message.spotOrderbook = object.spotOrderbook?.map((e) => SpotOrderBook.fromPartial(e)) || [];
        message.derivativeOrderbook = object.derivativeOrderbook?.map((e) => DerivativeOrderBook.fromPartial(e)) || [];
        message.balances = object.balances?.map((e) => Balance.fromPartial(e)) || [];
        message.positions = object.positions?.map((e) => DerivativePosition.fromPartial(e)) || [];
        message.subaccountTradeNonces = object.subaccountTradeNonces?.map((e) => SubaccountNonce.fromPartial(e)) || [];
        message.expiryFuturesMarketInfoState =
            object.expiryFuturesMarketInfoState?.map((e) => ExpiryFuturesMarketInfoState.fromPartial(e)) || [];
        message.perpetualMarketInfo = object.perpetualMarketInfo?.map((e) => PerpetualMarketInfo.fromPartial(e)) || [];
        message.perpetualMarketFundingState =
            object.perpetualMarketFundingState?.map((e) => PerpetualMarketFundingState.fromPartial(e)) || [];
        message.derivativeMarketSettlementScheduled =
            object.derivativeMarketSettlementScheduled?.map((e) => DerivativeMarketSettlementInfo.fromPartial(e)) || [];
        message.isSpotExchangeEnabled = object.isSpotExchangeEnabled ?? false;
        message.isDerivativesExchangeEnabled = object.isDerivativesExchangeEnabled ?? false;
        message.tradingRewardCampaignInfo =
            (object.tradingRewardCampaignInfo !== undefined && object.tradingRewardCampaignInfo !== null)
                ? TradingRewardCampaignInfo.fromPartial(object.tradingRewardCampaignInfo)
                : undefined;
        message.tradingRewardPoolCampaignSchedule =
            object.tradingRewardPoolCampaignSchedule?.map((e) => CampaignRewardPool.fromPartial(e)) || [];
        message.tradingRewardCampaignAccountPoints =
            object.tradingRewardCampaignAccountPoints?.map((e) => TradingRewardCampaignAccountPoints.fromPartial(e)) || [];
        message.feeDiscountSchedule = (object.feeDiscountSchedule !== undefined && object.feeDiscountSchedule !== null)
            ? FeeDiscountSchedule.fromPartial(object.feeDiscountSchedule)
            : undefined;
        message.feeDiscountAccountTierTtl =
            object.feeDiscountAccountTierTtl?.map((e) => FeeDiscountAccountTierTTL.fromPartial(e)) || [];
        message.feeDiscountBucketVolumeAccounts =
            object.feeDiscountBucketVolumeAccounts?.map((e) => FeeDiscountBucketVolumeAccounts.fromPartial(e)) || [];
        message.isFirstFeeCycleFinished = object.isFirstFeeCycleFinished ?? false;
        message.pendingTradingRewardPoolCampaignSchedule =
            object.pendingTradingRewardPoolCampaignSchedule?.map((e) => CampaignRewardPool.fromPartial(e)) || [];
        message.pendingTradingRewardCampaignAccountPoints =
            object.pendingTradingRewardCampaignAccountPoints?.map((e) => TradingRewardCampaignAccountPendingPoints.fromPartial(e)) || [];
        message.rewardsOptOutAddresses = object.rewardsOptOutAddresses?.map((e) => e) || [];
        message.historicalTradeRecords = object.historicalTradeRecords?.map((e) => TradeRecords.fromPartial(e)) || [];
        message.binaryOptionsMarkets = object.binaryOptionsMarkets?.map((e) => BinaryOptionsMarket.fromPartial(e)) || [];
        message.binaryOptionsMarketIdsScheduledForSettlement =
            object.binaryOptionsMarketIdsScheduledForSettlement?.map((e) => e) || [];
        message.spotMarketIdsScheduledToForceClose = object.spotMarketIdsScheduledToForceClose?.map((e) => e) || [];
        message.denomDecimals = object.denomDecimals?.map((e) => DenomDecimals.fromPartial(e)) || [];
        message.conditionalDerivativeOrderbooks =
            object.conditionalDerivativeOrderbooks?.map((e) => ConditionalDerivativeOrderBook.fromPartial(e)) || [];
        message.marketFeeMultipliers = object.marketFeeMultipliers?.map((e) => MarketFeeMultiplier.fromPartial(e)) || [];
        message.orderbookSequences = object.orderbookSequences?.map((e) => OrderbookSequence.fromPartial(e)) || [];
        message.subaccountVolumes = object.subaccountVolumes?.map((e) => AggregateSubaccountVolumeRecord.fromPartial(e)) ||
            [];
        message.marketVolumes = object.marketVolumes?.map((e) => MarketVolume.fromPartial(e)) || [];
        message.grantAuthorizations = object.grantAuthorizations?.map((e) => FullGrantAuthorizations.fromPartial(e)) || [];
        message.activeGrants = object.activeGrants?.map((e) => FullActiveGrant.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOrderbookSequence() {
    return { sequence: "0", marketId: "" };
}
export const OrderbookSequence = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sequence !== "0") {
            writer.uint32(8).uint64(message.sequence);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookSequence();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return OrderbookSequence.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookSequence();
        message.sequence = object.sequence ?? "0";
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseFeeDiscountAccountTierTTL() {
    return { account: "", tierTtl: undefined };
}
export const FeeDiscountAccountTierTTL = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.tierTtl !== undefined) {
            FeeDiscountTierTTL.encode(message.tierTtl, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeeDiscountAccountTierTTL();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.tierTtl = FeeDiscountTierTTL.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            tierTtl: isSet(object.tierTtl) ? FeeDiscountTierTTL.fromJSON(object.tierTtl) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.tierTtl !== undefined &&
            (obj.tierTtl = message.tierTtl ? FeeDiscountTierTTL.toJSON(message.tierTtl) : undefined);
        return obj;
    },
    create(base) {
        return FeeDiscountAccountTierTTL.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeeDiscountAccountTierTTL();
        message.account = object.account ?? "";
        message.tierTtl = (object.tierTtl !== undefined && object.tierTtl !== null)
            ? FeeDiscountTierTTL.fromPartial(object.tierTtl)
            : undefined;
        return message;
    },
};
function createBaseFeeDiscountBucketVolumeAccounts() {
    return { bucketStartTimestamp: "0", accountVolume: [] };
}
export const FeeDiscountBucketVolumeAccounts = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bucketStartTimestamp !== "0") {
            writer.uint32(8).int64(message.bucketStartTimestamp);
        }
        for (const v of message.accountVolume) {
            AccountVolume.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFeeDiscountBucketVolumeAccounts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bucketStartTimestamp = longToString(reader.int64());
                    break;
                case 2:
                    message.accountVolume.push(AccountVolume.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bucketStartTimestamp: isSet(object.bucketStartTimestamp) ? String(object.bucketStartTimestamp) : "0",
            accountVolume: Array.isArray(object?.accountVolume)
                ? object.accountVolume.map((e) => AccountVolume.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.bucketStartTimestamp !== undefined && (obj.bucketStartTimestamp = message.bucketStartTimestamp);
        if (message.accountVolume) {
            obj.accountVolume = message.accountVolume.map((e) => e ? AccountVolume.toJSON(e) : undefined);
        }
        else {
            obj.accountVolume = [];
        }
        return obj;
    },
    create(base) {
        return FeeDiscountBucketVolumeAccounts.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFeeDiscountBucketVolumeAccounts();
        message.bucketStartTimestamp = object.bucketStartTimestamp ?? "0";
        message.accountVolume = object.accountVolume?.map((e) => AccountVolume.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAccountVolume() {
    return { account: "", volume: "" };
}
export const AccountVolume = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.volume !== "") {
            writer.uint32(18).string(message.volume);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccountVolume();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.volume = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            volume: isSet(object.volume) ? String(object.volume) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.volume !== undefined && (obj.volume = message.volume);
        return obj;
    },
    create(base) {
        return AccountVolume.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAccountVolume();
        message.account = object.account ?? "";
        message.volume = object.volume ?? "";
        return message;
    },
};
function createBaseTradingRewardCampaignAccountPoints() {
    return { account: "", points: "" };
}
export const TradingRewardCampaignAccountPoints = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account !== "") {
            writer.uint32(10).string(message.account);
        }
        if (message.points !== "") {
            writer.uint32(18).string(message.points);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardCampaignAccountPoints();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.points = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? String(object.account) : "",
            points: isSet(object.points) ? String(object.points) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined && (obj.account = message.account);
        message.points !== undefined && (obj.points = message.points);
        return obj;
    },
    create(base) {
        return TradingRewardCampaignAccountPoints.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardCampaignAccountPoints();
        message.account = object.account ?? "";
        message.points = object.points ?? "";
        return message;
    },
};
function createBaseTradingRewardCampaignAccountPendingPoints() {
    return { rewardPoolStartTimestamp: "0", accountPoints: [] };
}
export const TradingRewardCampaignAccountPendingPoints = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rewardPoolStartTimestamp !== "0") {
            writer.uint32(8).int64(message.rewardPoolStartTimestamp);
        }
        for (const v of message.accountPoints) {
            TradingRewardCampaignAccountPoints.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingRewardCampaignAccountPendingPoints();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rewardPoolStartTimestamp = longToString(reader.int64());
                    break;
                case 2:
                    message.accountPoints.push(TradingRewardCampaignAccountPoints.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            rewardPoolStartTimestamp: isSet(object.rewardPoolStartTimestamp) ? String(object.rewardPoolStartTimestamp) : "0",
            accountPoints: Array.isArray(object?.accountPoints)
                ? object.accountPoints.map((e) => TradingRewardCampaignAccountPoints.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.rewardPoolStartTimestamp !== undefined && (obj.rewardPoolStartTimestamp = message.rewardPoolStartTimestamp);
        if (message.accountPoints) {
            obj.accountPoints = message.accountPoints.map((e) => e ? TradingRewardCampaignAccountPoints.toJSON(e) : undefined);
        }
        else {
            obj.accountPoints = [];
        }
        return obj;
    },
    create(base) {
        return TradingRewardCampaignAccountPendingPoints.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradingRewardCampaignAccountPendingPoints();
        message.rewardPoolStartTimestamp = object.rewardPoolStartTimestamp ?? "0";
        message.accountPoints = object.accountPoints?.map((e) => TradingRewardCampaignAccountPoints.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSpotOrderBook() {
    return { marketId: "", isBuySide: false, orders: [] };
}
export const SpotOrderBook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isBuySide === true) {
            writer.uint32(16).bool(message.isBuySide);
        }
        for (const v of message.orders) {
            SpotLimitOrder.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotOrderBook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isBuySide = reader.bool();
                    break;
                case 3:
                    message.orders.push(SpotLimitOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isBuySide: isSet(object.isBuySide) ? Boolean(object.isBuySide) : false,
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => SpotLimitOrder.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isBuySide !== undefined && (obj.isBuySide = message.isBuySide);
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? SpotLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        return obj;
    },
    create(base) {
        return SpotOrderBook.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotOrderBook();
        message.marketId = object.marketId ?? "";
        message.isBuySide = object.isBuySide ?? false;
        message.orders = object.orders?.map((e) => SpotLimitOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseDerivativeOrderBook() {
    return { marketId: "", isBuySide: false, orders: [] };
}
export const DerivativeOrderBook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isBuySide === true) {
            writer.uint32(16).bool(message.isBuySide);
        }
        for (const v of message.orders) {
            DerivativeLimitOrder.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeOrderBook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isBuySide = reader.bool();
                    break;
                case 3:
                    message.orders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isBuySide: isSet(object.isBuySide) ? Boolean(object.isBuySide) : false,
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => DerivativeLimitOrder.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isBuySide !== undefined && (obj.isBuySide = message.isBuySide);
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        return obj;
    },
    create(base) {
        return DerivativeOrderBook.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeOrderBook();
        message.marketId = object.marketId ?? "";
        message.isBuySide = object.isBuySide ?? false;
        message.orders = object.orders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseConditionalDerivativeOrderBook() {
    return { marketId: "", limitBuyOrders: [], marketBuyOrders: [], limitSellOrders: [], marketSellOrders: [] };
}
export const ConditionalDerivativeOrderBook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        for (const v of message.limitBuyOrders) {
            DerivativeLimitOrder.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.marketBuyOrders) {
            DerivativeMarketOrder.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.limitSellOrders) {
            DerivativeLimitOrder.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.marketSellOrders) {
            DerivativeMarketOrder.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConditionalDerivativeOrderBook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.limitBuyOrders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.marketBuyOrders.push(DerivativeMarketOrder.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.limitSellOrders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.marketSellOrders.push(DerivativeMarketOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            limitBuyOrders: Array.isArray(object?.limitBuyOrders)
                ? object.limitBuyOrders.map((e) => DerivativeLimitOrder.fromJSON(e))
                : [],
            marketBuyOrders: Array.isArray(object?.marketBuyOrders)
                ? object.marketBuyOrders.map((e) => DerivativeMarketOrder.fromJSON(e))
                : [],
            limitSellOrders: Array.isArray(object?.limitSellOrders)
                ? object.limitSellOrders.map((e) => DerivativeLimitOrder.fromJSON(e))
                : [],
            marketSellOrders: Array.isArray(object?.marketSellOrders)
                ? object.marketSellOrders.map((e) => DerivativeMarketOrder.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.limitBuyOrders) {
            obj.limitBuyOrders = message.limitBuyOrders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.limitBuyOrders = [];
        }
        if (message.marketBuyOrders) {
            obj.marketBuyOrders = message.marketBuyOrders.map((e) => e ? DerivativeMarketOrder.toJSON(e) : undefined);
        }
        else {
            obj.marketBuyOrders = [];
        }
        if (message.limitSellOrders) {
            obj.limitSellOrders = message.limitSellOrders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.limitSellOrders = [];
        }
        if (message.marketSellOrders) {
            obj.marketSellOrders = message.marketSellOrders.map((e) => e ? DerivativeMarketOrder.toJSON(e) : undefined);
        }
        else {
            obj.marketSellOrders = [];
        }
        return obj;
    },
    create(base) {
        return ConditionalDerivativeOrderBook.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseConditionalDerivativeOrderBook();
        message.marketId = object.marketId ?? "";
        message.limitBuyOrders = object.limitBuyOrders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        message.marketBuyOrders = object.marketBuyOrders?.map((e) => DerivativeMarketOrder.fromPartial(e)) || [];
        message.limitSellOrders = object.limitSellOrders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        message.marketSellOrders = object.marketSellOrders?.map((e) => DerivativeMarketOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBalance() {
    return { subaccountId: "", denom: "", deposits: undefined };
}
export const Balance = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        if (message.deposits !== undefined) {
            Deposit.encode(message.deposits, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBalance();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                case 3:
                    message.deposits = Deposit.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            deposits: isSet(object.deposits) ? Deposit.fromJSON(object.deposits) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.denom !== undefined && (obj.denom = message.denom);
        message.deposits !== undefined && (obj.deposits = message.deposits ? Deposit.toJSON(message.deposits) : undefined);
        return obj;
    },
    create(base) {
        return Balance.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBalance();
        message.subaccountId = object.subaccountId ?? "";
        message.denom = object.denom ?? "";
        message.deposits = (object.deposits !== undefined && object.deposits !== null)
            ? Deposit.fromPartial(object.deposits)
            : undefined;
        return message;
    },
};
function createBaseDerivativePosition() {
    return { subaccountId: "", marketId: "", position: undefined };
}
export const DerivativePosition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.position !== undefined) {
            Position.encode(message.position, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativePosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.position = Position.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            position: isSet(object.position) ? Position.fromJSON(object.position) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.position !== undefined && (obj.position = message.position ? Position.toJSON(message.position) : undefined);
        return obj;
    },
    create(base) {
        return DerivativePosition.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativePosition();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.position = (object.position !== undefined && object.position !== null)
            ? Position.fromPartial(object.position)
            : undefined;
        return message;
    },
};
function createBaseSubaccountNonce() {
    return { subaccountId: "", subaccountTradeNonce: undefined };
}
export const SubaccountNonce = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.subaccountTradeNonce !== undefined) {
            SubaccountTradeNonce.encode(message.subaccountTradeNonce, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountNonce();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.subaccountTradeNonce = SubaccountTradeNonce.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            subaccountTradeNonce: isSet(object.subaccountTradeNonce)
                ? SubaccountTradeNonce.fromJSON(object.subaccountTradeNonce)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.subaccountTradeNonce !== undefined && (obj.subaccountTradeNonce = message.subaccountTradeNonce
            ? SubaccountTradeNonce.toJSON(message.subaccountTradeNonce)
            : undefined);
        return obj;
    },
    create(base) {
        return SubaccountNonce.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountNonce();
        message.subaccountId = object.subaccountId ?? "";
        message.subaccountTradeNonce = (object.subaccountTradeNonce !== undefined && object.subaccountTradeNonce !== null)
            ? SubaccountTradeNonce.fromPartial(object.subaccountTradeNonce)
            : undefined;
        return message;
    },
};
function createBaseExpiryFuturesMarketInfoState() {
    return { marketId: "", marketInfo: undefined };
}
export const ExpiryFuturesMarketInfoState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.marketInfo !== undefined) {
            ExpiryFuturesMarketInfo.encode(message.marketInfo, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExpiryFuturesMarketInfoState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.marketInfo = ExpiryFuturesMarketInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            marketInfo: isSet(object.marketInfo) ? ExpiryFuturesMarketInfo.fromJSON(object.marketInfo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.marketInfo !== undefined &&
            (obj.marketInfo = message.marketInfo ? ExpiryFuturesMarketInfo.toJSON(message.marketInfo) : undefined);
        return obj;
    },
    create(base) {
        return ExpiryFuturesMarketInfoState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExpiryFuturesMarketInfoState();
        message.marketId = object.marketId ?? "";
        message.marketInfo = (object.marketInfo !== undefined && object.marketInfo !== null)
            ? ExpiryFuturesMarketInfo.fromPartial(object.marketInfo)
            : undefined;
        return message;
    },
};
function createBasePerpetualMarketFundingState() {
    return { marketId: "", funding: undefined };
}
export const PerpetualMarketFundingState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.funding !== undefined) {
            PerpetualMarketFunding.encode(message.funding, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerpetualMarketFundingState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.funding = PerpetualMarketFunding.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            funding: isSet(object.funding) ? PerpetualMarketFunding.fromJSON(object.funding) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.funding !== undefined &&
            (obj.funding = message.funding ? PerpetualMarketFunding.toJSON(message.funding) : undefined);
        return obj;
    },
    create(base) {
        return PerpetualMarketFundingState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePerpetualMarketFundingState();
        message.marketId = object.marketId ?? "";
        message.funding = (object.funding !== undefined && object.funding !== null)
            ? PerpetualMarketFunding.fromPartial(object.funding)
            : undefined;
        return message;
    },
};
function createBaseFullGrantAuthorizations() {
    return { granter: "", totalGrantAmount: "", lastDelegationsCheckedTime: "0", grants: [] };
}
export const FullGrantAuthorizations = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.granter !== "") {
            writer.uint32(10).string(message.granter);
        }
        if (message.totalGrantAmount !== "") {
            writer.uint32(18).string(message.totalGrantAmount);
        }
        if (message.lastDelegationsCheckedTime !== "0") {
            writer.uint32(24).int64(message.lastDelegationsCheckedTime);
        }
        for (const v of message.grants) {
            GrantAuthorization.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFullGrantAuthorizations();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.granter = reader.string();
                    break;
                case 2:
                    message.totalGrantAmount = reader.string();
                    break;
                case 3:
                    message.lastDelegationsCheckedTime = longToString(reader.int64());
                    break;
                case 4:
                    message.grants.push(GrantAuthorization.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            granter: isSet(object.granter) ? String(object.granter) : "",
            totalGrantAmount: isSet(object.totalGrantAmount) ? String(object.totalGrantAmount) : "",
            lastDelegationsCheckedTime: isSet(object.lastDelegationsCheckedTime)
                ? String(object.lastDelegationsCheckedTime)
                : "0",
            grants: Array.isArray(object?.grants) ? object.grants.map((e) => GrantAuthorization.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.granter !== undefined && (obj.granter = message.granter);
        message.totalGrantAmount !== undefined && (obj.totalGrantAmount = message.totalGrantAmount);
        message.lastDelegationsCheckedTime !== undefined &&
            (obj.lastDelegationsCheckedTime = message.lastDelegationsCheckedTime);
        if (message.grants) {
            obj.grants = message.grants.map((e) => e ? GrantAuthorization.toJSON(e) : undefined);
        }
        else {
            obj.grants = [];
        }
        return obj;
    },
    create(base) {
        return FullGrantAuthorizations.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFullGrantAuthorizations();
        message.granter = object.granter ?? "";
        message.totalGrantAmount = object.totalGrantAmount ?? "";
        message.lastDelegationsCheckedTime = object.lastDelegationsCheckedTime ?? "0";
        message.grants = object.grants?.map((e) => GrantAuthorization.fromPartial(e)) || [];
        return message;
    },
};
function createBaseFullActiveGrant() {
    return { grantee: "", activeGrant: undefined };
}
export const FullActiveGrant = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.grantee !== "") {
            writer.uint32(10).string(message.grantee);
        }
        if (message.activeGrant !== undefined) {
            ActiveGrant.encode(message.activeGrant, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFullActiveGrant();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.grantee = reader.string();
                    break;
                case 2:
                    message.activeGrant = ActiveGrant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            grantee: isSet(object.grantee) ? String(object.grantee) : "",
            activeGrant: isSet(object.activeGrant) ? ActiveGrant.fromJSON(object.activeGrant) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.grantee !== undefined && (obj.grantee = message.grantee);
        message.activeGrant !== undefined &&
            (obj.activeGrant = message.activeGrant ? ActiveGrant.toJSON(message.activeGrant) : undefined);
        return obj;
    },
    create(base) {
        return FullActiveGrant.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFullActiveGrant();
        message.grantee = object.grantee ?? "";
        message.activeGrant = (object.activeGrant !== undefined && object.activeGrant !== null)
            ? ActiveGrant.fromPartial(object.activeGrant)
            : undefined;
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
