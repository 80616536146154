/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { BandIBCParams, BandOracleRequest, BandPriceState, ChainlinkPriceState, CoinbasePriceState, Params, PriceFeedState, PriceRecords, ProviderState, PythPriceState, StorkPriceState, } from "./oracle.js";
export const protobufPackage = "injective.oracle.v1beta1";
function createBaseGenesisState() {
    return {
        params: undefined,
        bandRelayers: [],
        bandPriceStates: [],
        priceFeedPriceStates: [],
        coinbasePriceStates: [],
        bandIbcPriceStates: [],
        bandIbcOracleRequests: [],
        bandIbcParams: undefined,
        bandIbcLatestClientId: "0",
        calldataRecords: [],
        bandIbcLatestRequestId: "0",
        chainlinkPriceStates: [],
        historicalPriceRecords: [],
        providerStates: [],
        pythPriceStates: [],
        storkPriceStates: [],
        storkPublishers: [],
    };
}
export const GenesisState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.bandRelayers) {
            writer.uint32(18).string(v);
        }
        for (const v of message.bandPriceStates) {
            BandPriceState.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.priceFeedPriceStates) {
            PriceFeedState.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.coinbasePriceStates) {
            CoinbasePriceState.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.bandIbcPriceStates) {
            BandPriceState.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.bandIbcOracleRequests) {
            BandOracleRequest.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.bandIbcParams !== undefined) {
            BandIBCParams.encode(message.bandIbcParams, writer.uint32(66).fork()).ldelim();
        }
        if (message.bandIbcLatestClientId !== "0") {
            writer.uint32(72).uint64(message.bandIbcLatestClientId);
        }
        for (const v of message.calldataRecords) {
            CalldataRecord.encode(v, writer.uint32(82).fork()).ldelim();
        }
        if (message.bandIbcLatestRequestId !== "0") {
            writer.uint32(88).uint64(message.bandIbcLatestRequestId);
        }
        for (const v of message.chainlinkPriceStates) {
            ChainlinkPriceState.encode(v, writer.uint32(98).fork()).ldelim();
        }
        for (const v of message.historicalPriceRecords) {
            PriceRecords.encode(v, writer.uint32(106).fork()).ldelim();
        }
        for (const v of message.providerStates) {
            ProviderState.encode(v, writer.uint32(114).fork()).ldelim();
        }
        for (const v of message.pythPriceStates) {
            PythPriceState.encode(v, writer.uint32(122).fork()).ldelim();
        }
        for (const v of message.storkPriceStates) {
            StorkPriceState.encode(v, writer.uint32(130).fork()).ldelim();
        }
        for (const v of message.storkPublishers) {
            writer.uint32(138).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenesisState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.bandRelayers.push(reader.string());
                    break;
                case 3:
                    message.bandPriceStates.push(BandPriceState.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.priceFeedPriceStates.push(PriceFeedState.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.coinbasePriceStates.push(CoinbasePriceState.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.bandIbcPriceStates.push(BandPriceState.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.bandIbcOracleRequests.push(BandOracleRequest.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.bandIbcParams = BandIBCParams.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.bandIbcLatestClientId = longToString(reader.uint64());
                    break;
                case 10:
                    message.calldataRecords.push(CalldataRecord.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.bandIbcLatestRequestId = longToString(reader.uint64());
                    break;
                case 12:
                    message.chainlinkPriceStates.push(ChainlinkPriceState.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.historicalPriceRecords.push(PriceRecords.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.providerStates.push(ProviderState.decode(reader, reader.uint32()));
                    break;
                case 15:
                    message.pythPriceStates.push(PythPriceState.decode(reader, reader.uint32()));
                    break;
                case 16:
                    message.storkPriceStates.push(StorkPriceState.decode(reader, reader.uint32()));
                    break;
                case 17:
                    message.storkPublishers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
            bandRelayers: Array.isArray(object?.bandRelayers) ? object.bandRelayers.map((e) => String(e)) : [],
            bandPriceStates: Array.isArray(object?.bandPriceStates)
                ? object.bandPriceStates.map((e) => BandPriceState.fromJSON(e))
                : [],
            priceFeedPriceStates: Array.isArray(object?.priceFeedPriceStates)
                ? object.priceFeedPriceStates.map((e) => PriceFeedState.fromJSON(e))
                : [],
            coinbasePriceStates: Array.isArray(object?.coinbasePriceStates)
                ? object.coinbasePriceStates.map((e) => CoinbasePriceState.fromJSON(e))
                : [],
            bandIbcPriceStates: Array.isArray(object?.bandIbcPriceStates)
                ? object.bandIbcPriceStates.map((e) => BandPriceState.fromJSON(e))
                : [],
            bandIbcOracleRequests: Array.isArray(object?.bandIbcOracleRequests)
                ? object.bandIbcOracleRequests.map((e) => BandOracleRequest.fromJSON(e))
                : [],
            bandIbcParams: isSet(object.bandIbcParams) ? BandIBCParams.fromJSON(object.bandIbcParams) : undefined,
            bandIbcLatestClientId: isSet(object.bandIbcLatestClientId) ? String(object.bandIbcLatestClientId) : "0",
            calldataRecords: Array.isArray(object?.calldataRecords)
                ? object.calldataRecords.map((e) => CalldataRecord.fromJSON(e))
                : [],
            bandIbcLatestRequestId: isSet(object.bandIbcLatestRequestId) ? String(object.bandIbcLatestRequestId) : "0",
            chainlinkPriceStates: Array.isArray(object?.chainlinkPriceStates)
                ? object.chainlinkPriceStates.map((e) => ChainlinkPriceState.fromJSON(e))
                : [],
            historicalPriceRecords: Array.isArray(object?.historicalPriceRecords)
                ? object.historicalPriceRecords.map((e) => PriceRecords.fromJSON(e))
                : [],
            providerStates: Array.isArray(object?.providerStates)
                ? object.providerStates.map((e) => ProviderState.fromJSON(e))
                : [],
            pythPriceStates: Array.isArray(object?.pythPriceStates)
                ? object.pythPriceStates.map((e) => PythPriceState.fromJSON(e))
                : [],
            storkPriceStates: Array.isArray(object?.storkPriceStates)
                ? object.storkPriceStates.map((e) => StorkPriceState.fromJSON(e))
                : [],
            storkPublishers: Array.isArray(object?.storkPublishers) ? object.storkPublishers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        if (message.bandRelayers) {
            obj.bandRelayers = message.bandRelayers.map((e) => e);
        }
        else {
            obj.bandRelayers = [];
        }
        if (message.bandPriceStates) {
            obj.bandPriceStates = message.bandPriceStates.map((e) => e ? BandPriceState.toJSON(e) : undefined);
        }
        else {
            obj.bandPriceStates = [];
        }
        if (message.priceFeedPriceStates) {
            obj.priceFeedPriceStates = message.priceFeedPriceStates.map((e) => e ? PriceFeedState.toJSON(e) : undefined);
        }
        else {
            obj.priceFeedPriceStates = [];
        }
        if (message.coinbasePriceStates) {
            obj.coinbasePriceStates = message.coinbasePriceStates.map((e) => e ? CoinbasePriceState.toJSON(e) : undefined);
        }
        else {
            obj.coinbasePriceStates = [];
        }
        if (message.bandIbcPriceStates) {
            obj.bandIbcPriceStates = message.bandIbcPriceStates.map((e) => e ? BandPriceState.toJSON(e) : undefined);
        }
        else {
            obj.bandIbcPriceStates = [];
        }
        if (message.bandIbcOracleRequests) {
            obj.bandIbcOracleRequests = message.bandIbcOracleRequests.map((e) => e ? BandOracleRequest.toJSON(e) : undefined);
        }
        else {
            obj.bandIbcOracleRequests = [];
        }
        message.bandIbcParams !== undefined &&
            (obj.bandIbcParams = message.bandIbcParams ? BandIBCParams.toJSON(message.bandIbcParams) : undefined);
        message.bandIbcLatestClientId !== undefined && (obj.bandIbcLatestClientId = message.bandIbcLatestClientId);
        if (message.calldataRecords) {
            obj.calldataRecords = message.calldataRecords.map((e) => e ? CalldataRecord.toJSON(e) : undefined);
        }
        else {
            obj.calldataRecords = [];
        }
        message.bandIbcLatestRequestId !== undefined && (obj.bandIbcLatestRequestId = message.bandIbcLatestRequestId);
        if (message.chainlinkPriceStates) {
            obj.chainlinkPriceStates = message.chainlinkPriceStates.map((e) => e ? ChainlinkPriceState.toJSON(e) : undefined);
        }
        else {
            obj.chainlinkPriceStates = [];
        }
        if (message.historicalPriceRecords) {
            obj.historicalPriceRecords = message.historicalPriceRecords.map((e) => e ? PriceRecords.toJSON(e) : undefined);
        }
        else {
            obj.historicalPriceRecords = [];
        }
        if (message.providerStates) {
            obj.providerStates = message.providerStates.map((e) => e ? ProviderState.toJSON(e) : undefined);
        }
        else {
            obj.providerStates = [];
        }
        if (message.pythPriceStates) {
            obj.pythPriceStates = message.pythPriceStates.map((e) => e ? PythPriceState.toJSON(e) : undefined);
        }
        else {
            obj.pythPriceStates = [];
        }
        if (message.storkPriceStates) {
            obj.storkPriceStates = message.storkPriceStates.map((e) => e ? StorkPriceState.toJSON(e) : undefined);
        }
        else {
            obj.storkPriceStates = [];
        }
        if (message.storkPublishers) {
            obj.storkPublishers = message.storkPublishers.map((e) => e);
        }
        else {
            obj.storkPublishers = [];
        }
        return obj;
    },
    create(base) {
        return GenesisState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGenesisState();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        message.bandRelayers = object.bandRelayers?.map((e) => e) || [];
        message.bandPriceStates = object.bandPriceStates?.map((e) => BandPriceState.fromPartial(e)) || [];
        message.priceFeedPriceStates = object.priceFeedPriceStates?.map((e) => PriceFeedState.fromPartial(e)) || [];
        message.coinbasePriceStates = object.coinbasePriceStates?.map((e) => CoinbasePriceState.fromPartial(e)) || [];
        message.bandIbcPriceStates = object.bandIbcPriceStates?.map((e) => BandPriceState.fromPartial(e)) || [];
        message.bandIbcOracleRequests = object.bandIbcOracleRequests?.map((e) => BandOracleRequest.fromPartial(e)) || [];
        message.bandIbcParams = (object.bandIbcParams !== undefined && object.bandIbcParams !== null)
            ? BandIBCParams.fromPartial(object.bandIbcParams)
            : undefined;
        message.bandIbcLatestClientId = object.bandIbcLatestClientId ?? "0";
        message.calldataRecords = object.calldataRecords?.map((e) => CalldataRecord.fromPartial(e)) || [];
        message.bandIbcLatestRequestId = object.bandIbcLatestRequestId ?? "0";
        message.chainlinkPriceStates = object.chainlinkPriceStates?.map((e) => ChainlinkPriceState.fromPartial(e)) || [];
        message.historicalPriceRecords = object.historicalPriceRecords?.map((e) => PriceRecords.fromPartial(e)) || [];
        message.providerStates = object.providerStates?.map((e) => ProviderState.fromPartial(e)) || [];
        message.pythPriceStates = object.pythPriceStates?.map((e) => PythPriceState.fromPartial(e)) || [];
        message.storkPriceStates = object.storkPriceStates?.map((e) => StorkPriceState.fromPartial(e)) || [];
        message.storkPublishers = object.storkPublishers?.map((e) => e) || [];
        return message;
    },
};
function createBaseCalldataRecord() {
    return { clientId: "0", calldata: new Uint8Array() };
}
export const CalldataRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.clientId !== "0") {
            writer.uint32(8).uint64(message.clientId);
        }
        if (message.calldata.length !== 0) {
            writer.uint32(18).bytes(message.calldata);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCalldataRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientId = longToString(reader.uint64());
                    break;
                case 2:
                    message.calldata = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            clientId: isSet(object.clientId) ? String(object.clientId) : "0",
            calldata: isSet(object.calldata) ? bytesFromBase64(object.calldata) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.clientId !== undefined && (obj.clientId = message.clientId);
        message.calldata !== undefined &&
            (obj.calldata = base64FromBytes(message.calldata !== undefined ? message.calldata : new Uint8Array()));
        return obj;
    },
    create(base) {
        return CalldataRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCalldataRecord();
        message.clientId = object.clientId ?? "0";
        message.calldata = object.calldata ?? new Uint8Array();
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
