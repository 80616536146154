/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { BandIBCParams, BandOracleRequest } from "./oracle.js";
export const protobufPackage = "injective.oracle.v1beta1";
function createBaseGrantBandOraclePrivilegeProposal() {
    return { title: "", description: "", relayers: [] };
}
export const GrantBandOraclePrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.relayers) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGrantBandOraclePrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return GrantBandOraclePrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGrantBandOraclePrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseRevokeBandOraclePrivilegeProposal() {
    return { title: "", description: "", relayers: [] };
}
export const RevokeBandOraclePrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.relayers) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRevokeBandOraclePrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return RevokeBandOraclePrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRevokeBandOraclePrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseGrantPriceFeederPrivilegeProposal() {
    return { title: "", description: "", base: "", quote: "", relayers: [] };
}
export const GrantPriceFeederPrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.base !== "") {
            writer.uint32(26).string(message.base);
        }
        if (message.quote !== "") {
            writer.uint32(34).string(message.quote);
        }
        for (const v of message.relayers) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGrantPriceFeederPrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.base = reader.string();
                    break;
                case 4:
                    message.quote = reader.string();
                    break;
                case 5:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            base: isSet(object.base) ? String(object.base) : "",
            quote: isSet(object.quote) ? String(object.quote) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.base !== undefined && (obj.base = message.base);
        message.quote !== undefined && (obj.quote = message.quote);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return GrantPriceFeederPrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGrantPriceFeederPrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.base = object.base ?? "";
        message.quote = object.quote ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseGrantProviderPrivilegeProposal() {
    return { title: "", description: "", provider: "", relayers: [] };
}
export const GrantProviderPrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.provider !== "") {
            writer.uint32(26).string(message.provider);
        }
        for (const v of message.relayers) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGrantProviderPrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.provider = reader.string();
                    break;
                case 4:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            provider: isSet(object.provider) ? String(object.provider) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.provider !== undefined && (obj.provider = message.provider);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return GrantProviderPrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGrantProviderPrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.provider = object.provider ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseRevokeProviderPrivilegeProposal() {
    return { title: "", description: "", provider: "", relayers: [] };
}
export const RevokeProviderPrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.provider !== "") {
            writer.uint32(26).string(message.provider);
        }
        for (const v of message.relayers) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRevokeProviderPrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.provider = reader.string();
                    break;
                case 5:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            provider: isSet(object.provider) ? String(object.provider) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.provider !== undefined && (obj.provider = message.provider);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return RevokeProviderPrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRevokeProviderPrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.provider = object.provider ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseRevokePriceFeederPrivilegeProposal() {
    return { title: "", description: "", base: "", quote: "", relayers: [] };
}
export const RevokePriceFeederPrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.base !== "") {
            writer.uint32(26).string(message.base);
        }
        if (message.quote !== "") {
            writer.uint32(34).string(message.quote);
        }
        for (const v of message.relayers) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRevokePriceFeederPrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.base = reader.string();
                    break;
                case 4:
                    message.quote = reader.string();
                    break;
                case 5:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            base: isSet(object.base) ? String(object.base) : "",
            quote: isSet(object.quote) ? String(object.quote) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.base !== undefined && (obj.base = message.base);
        message.quote !== undefined && (obj.quote = message.quote);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return RevokePriceFeederPrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRevokePriceFeederPrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.base = object.base ?? "";
        message.quote = object.quote ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseAuthorizeBandOracleRequestProposal() {
    return { title: "", description: "", request: undefined };
}
export const AuthorizeBandOracleRequestProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.request !== undefined) {
            BandOracleRequest.encode(message.request, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAuthorizeBandOracleRequestProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.request = BandOracleRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            request: isSet(object.request) ? BandOracleRequest.fromJSON(object.request) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.request !== undefined &&
            (obj.request = message.request ? BandOracleRequest.toJSON(message.request) : undefined);
        return obj;
    },
    create(base) {
        return AuthorizeBandOracleRequestProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAuthorizeBandOracleRequestProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.request = (object.request !== undefined && object.request !== null)
            ? BandOracleRequest.fromPartial(object.request)
            : undefined;
        return message;
    },
};
function createBaseUpdateBandOracleRequestProposal() {
    return { title: "", description: "", deleteRequestIds: [], updateOracleRequest: undefined };
}
export const UpdateBandOracleRequestProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        writer.uint32(26).fork();
        for (const v of message.deleteRequestIds) {
            writer.uint64(v);
        }
        writer.ldelim();
        if (message.updateOracleRequest !== undefined) {
            BandOracleRequest.encode(message.updateOracleRequest, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateBandOracleRequestProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.deleteRequestIds.push(longToString(reader.uint64()));
                        }
                    }
                    else {
                        message.deleteRequestIds.push(longToString(reader.uint64()));
                    }
                    break;
                case 4:
                    message.updateOracleRequest = BandOracleRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            deleteRequestIds: Array.isArray(object?.deleteRequestIds)
                ? object.deleteRequestIds.map((e) => String(e))
                : [],
            updateOracleRequest: isSet(object.updateOracleRequest)
                ? BandOracleRequest.fromJSON(object.updateOracleRequest)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.deleteRequestIds) {
            obj.deleteRequestIds = message.deleteRequestIds.map((e) => e);
        }
        else {
            obj.deleteRequestIds = [];
        }
        message.updateOracleRequest !== undefined && (obj.updateOracleRequest = message.updateOracleRequest
            ? BandOracleRequest.toJSON(message.updateOracleRequest)
            : undefined);
        return obj;
    },
    create(base) {
        return UpdateBandOracleRequestProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseUpdateBandOracleRequestProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.deleteRequestIds = object.deleteRequestIds?.map((e) => e) || [];
        message.updateOracleRequest = (object.updateOracleRequest !== undefined && object.updateOracleRequest !== null)
            ? BandOracleRequest.fromPartial(object.updateOracleRequest)
            : undefined;
        return message;
    },
};
function createBaseEnableBandIBCProposal() {
    return { title: "", description: "", bandIbcParams: undefined };
}
export const EnableBandIBCProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.bandIbcParams !== undefined) {
            BandIBCParams.encode(message.bandIbcParams, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEnableBandIBCProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.bandIbcParams = BandIBCParams.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            bandIbcParams: isSet(object.bandIbcParams) ? BandIBCParams.fromJSON(object.bandIbcParams) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.bandIbcParams !== undefined &&
            (obj.bandIbcParams = message.bandIbcParams ? BandIBCParams.toJSON(message.bandIbcParams) : undefined);
        return obj;
    },
    create(base) {
        return EnableBandIBCProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEnableBandIBCProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.bandIbcParams = (object.bandIbcParams !== undefined && object.bandIbcParams !== null)
            ? BandIBCParams.fromPartial(object.bandIbcParams)
            : undefined;
        return message;
    },
};
function createBaseGrantStorkPublisherPrivilegeProposal() {
    return { title: "", description: "", storkPublishers: [] };
}
export const GrantStorkPublisherPrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.storkPublishers) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGrantStorkPublisherPrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.storkPublishers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            storkPublishers: Array.isArray(object?.storkPublishers) ? object.storkPublishers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.storkPublishers) {
            obj.storkPublishers = message.storkPublishers.map((e) => e);
        }
        else {
            obj.storkPublishers = [];
        }
        return obj;
    },
    create(base) {
        return GrantStorkPublisherPrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGrantStorkPublisherPrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.storkPublishers = object.storkPublishers?.map((e) => e) || [];
        return message;
    },
};
function createBaseRevokeStorkPublisherPrivilegeProposal() {
    return { title: "", description: "", storkPublishers: [] };
}
export const RevokeStorkPublisherPrivilegeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.storkPublishers) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRevokeStorkPublisherPrivilegeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.storkPublishers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            storkPublishers: Array.isArray(object?.storkPublishers) ? object.storkPublishers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.storkPublishers) {
            obj.storkPublishers = message.storkPublishers.map((e) => e);
        }
        else {
            obj.storkPublishers = [];
        }
        return obj;
    },
    create(base) {
        return RevokeStorkPublisherPrivilegeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRevokeStorkPublisherPrivilegeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.storkPublishers = object.storkPublishers?.map((e) => e) || [];
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
