/* eslint-disable */
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective.tokenfactory.v1beta1";
function createBaseDenomAuthorityMetadata() {
    return { admin: "" };
}
export const DenomAuthorityMetadata = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.admin !== "") {
            writer.uint32(10).string(message.admin);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDenomAuthorityMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.admin = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { admin: isSet(object.admin) ? String(object.admin) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.admin !== undefined && (obj.admin = message.admin);
        return obj;
    },
    create(base) {
        return DenomAuthorityMetadata.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDenomAuthorityMetadata();
        message.admin = object.admin ?? "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
