/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "injective_accounts_rpc";
function createBasePortfolioRequest() {
    return { accountAddress: "" };
}
export const PortfolioRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountAddress !== "") {
            writer.uint32(10).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePortfolioRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return PortfolioRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePortfolioRequest();
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBasePortfolioResponse() {
    return { portfolio: undefined };
}
export const PortfolioResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.portfolio !== undefined) {
            AccountPortfolio.encode(message.portfolio, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePortfolioResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.portfolio = AccountPortfolio.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { portfolio: isSet(object.portfolio) ? AccountPortfolio.fromJSON(object.portfolio) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.portfolio !== undefined &&
            (obj.portfolio = message.portfolio ? AccountPortfolio.toJSON(message.portfolio) : undefined);
        return obj;
    },
    create(base) {
        return PortfolioResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePortfolioResponse();
        message.portfolio = (object.portfolio !== undefined && object.portfolio !== null)
            ? AccountPortfolio.fromPartial(object.portfolio)
            : undefined;
        return message;
    },
};
function createBaseAccountPortfolio() {
    return { portfolioValue: "", availableBalance: "", lockedBalance: "", unrealizedPnl: "", subaccounts: [] };
}
export const AccountPortfolio = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.portfolioValue !== "") {
            writer.uint32(10).string(message.portfolioValue);
        }
        if (message.availableBalance !== "") {
            writer.uint32(18).string(message.availableBalance);
        }
        if (message.lockedBalance !== "") {
            writer.uint32(26).string(message.lockedBalance);
        }
        if (message.unrealizedPnl !== "") {
            writer.uint32(34).string(message.unrealizedPnl);
        }
        for (const v of message.subaccounts) {
            SubaccountPortfolio.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccountPortfolio();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.portfolioValue = reader.string();
                    break;
                case 2:
                    message.availableBalance = reader.string();
                    break;
                case 3:
                    message.lockedBalance = reader.string();
                    break;
                case 4:
                    message.unrealizedPnl = reader.string();
                    break;
                case 5:
                    message.subaccounts.push(SubaccountPortfolio.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            portfolioValue: isSet(object.portfolioValue) ? String(object.portfolioValue) : "",
            availableBalance: isSet(object.availableBalance) ? String(object.availableBalance) : "",
            lockedBalance: isSet(object.lockedBalance) ? String(object.lockedBalance) : "",
            unrealizedPnl: isSet(object.unrealizedPnl) ? String(object.unrealizedPnl) : "",
            subaccounts: Array.isArray(object?.subaccounts)
                ? object.subaccounts.map((e) => SubaccountPortfolio.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.portfolioValue !== undefined && (obj.portfolioValue = message.portfolioValue);
        message.availableBalance !== undefined && (obj.availableBalance = message.availableBalance);
        message.lockedBalance !== undefined && (obj.lockedBalance = message.lockedBalance);
        message.unrealizedPnl !== undefined && (obj.unrealizedPnl = message.unrealizedPnl);
        if (message.subaccounts) {
            obj.subaccounts = message.subaccounts.map((e) => e ? SubaccountPortfolio.toJSON(e) : undefined);
        }
        else {
            obj.subaccounts = [];
        }
        return obj;
    },
    create(base) {
        return AccountPortfolio.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAccountPortfolio();
        message.portfolioValue = object.portfolioValue ?? "";
        message.availableBalance = object.availableBalance ?? "";
        message.lockedBalance = object.lockedBalance ?? "";
        message.unrealizedPnl = object.unrealizedPnl ?? "";
        message.subaccounts = object.subaccounts?.map((e) => SubaccountPortfolio.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSubaccountPortfolio() {
    return { subaccountId: "", availableBalance: "", lockedBalance: "", unrealizedPnl: "" };
}
export const SubaccountPortfolio = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.availableBalance !== "") {
            writer.uint32(18).string(message.availableBalance);
        }
        if (message.lockedBalance !== "") {
            writer.uint32(26).string(message.lockedBalance);
        }
        if (message.unrealizedPnl !== "") {
            writer.uint32(34).string(message.unrealizedPnl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountPortfolio();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.availableBalance = reader.string();
                    break;
                case 3:
                    message.lockedBalance = reader.string();
                    break;
                case 4:
                    message.unrealizedPnl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            availableBalance: isSet(object.availableBalance) ? String(object.availableBalance) : "",
            lockedBalance: isSet(object.lockedBalance) ? String(object.lockedBalance) : "",
            unrealizedPnl: isSet(object.unrealizedPnl) ? String(object.unrealizedPnl) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.availableBalance !== undefined && (obj.availableBalance = message.availableBalance);
        message.lockedBalance !== undefined && (obj.lockedBalance = message.lockedBalance);
        message.unrealizedPnl !== undefined && (obj.unrealizedPnl = message.unrealizedPnl);
        return obj;
    },
    create(base) {
        return SubaccountPortfolio.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountPortfolio();
        message.subaccountId = object.subaccountId ?? "";
        message.availableBalance = object.availableBalance ?? "";
        message.lockedBalance = object.lockedBalance ?? "";
        message.unrealizedPnl = object.unrealizedPnl ?? "";
        return message;
    },
};
function createBaseOrderStatesRequest() {
    return { spotOrderHashes: [], derivativeOrderHashes: [] };
}
export const OrderStatesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.spotOrderHashes) {
            writer.uint32(10).string(v);
        }
        for (const v of message.derivativeOrderHashes) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderStatesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotOrderHashes.push(reader.string());
                    break;
                case 2:
                    message.derivativeOrderHashes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotOrderHashes: Array.isArray(object?.spotOrderHashes) ? object.spotOrderHashes.map((e) => String(e)) : [],
            derivativeOrderHashes: Array.isArray(object?.derivativeOrderHashes)
                ? object.derivativeOrderHashes.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.spotOrderHashes) {
            obj.spotOrderHashes = message.spotOrderHashes.map((e) => e);
        }
        else {
            obj.spotOrderHashes = [];
        }
        if (message.derivativeOrderHashes) {
            obj.derivativeOrderHashes = message.derivativeOrderHashes.map((e) => e);
        }
        else {
            obj.derivativeOrderHashes = [];
        }
        return obj;
    },
    create(base) {
        return OrderStatesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderStatesRequest();
        message.spotOrderHashes = object.spotOrderHashes?.map((e) => e) || [];
        message.derivativeOrderHashes = object.derivativeOrderHashes?.map((e) => e) || [];
        return message;
    },
};
function createBaseOrderStatesResponse() {
    return { spotOrderStates: [], derivativeOrderStates: [] };
}
export const OrderStatesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.spotOrderStates) {
            OrderStateRecord.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.derivativeOrderStates) {
            OrderStateRecord.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderStatesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotOrderStates.push(OrderStateRecord.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.derivativeOrderStates.push(OrderStateRecord.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotOrderStates: Array.isArray(object?.spotOrderStates)
                ? object.spotOrderStates.map((e) => OrderStateRecord.fromJSON(e))
                : [],
            derivativeOrderStates: Array.isArray(object?.derivativeOrderStates)
                ? object.derivativeOrderStates.map((e) => OrderStateRecord.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.spotOrderStates) {
            obj.spotOrderStates = message.spotOrderStates.map((e) => e ? OrderStateRecord.toJSON(e) : undefined);
        }
        else {
            obj.spotOrderStates = [];
        }
        if (message.derivativeOrderStates) {
            obj.derivativeOrderStates = message.derivativeOrderStates.map((e) => e ? OrderStateRecord.toJSON(e) : undefined);
        }
        else {
            obj.derivativeOrderStates = [];
        }
        return obj;
    },
    create(base) {
        return OrderStatesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderStatesResponse();
        message.spotOrderStates = object.spotOrderStates?.map((e) => OrderStateRecord.fromPartial(e)) || [];
        message.derivativeOrderStates = object.derivativeOrderStates?.map((e) => OrderStateRecord.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOrderStateRecord() {
    return {
        orderHash: "",
        subaccountId: "",
        marketId: "",
        orderType: "",
        orderSide: "",
        state: "",
        quantityFilled: "",
        quantityRemaining: "",
        createdAt: "0",
        updatedAt: "0",
        price: "",
        margin: "",
    };
}
export const OrderStateRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.orderType !== "") {
            writer.uint32(34).string(message.orderType);
        }
        if (message.orderSide !== "") {
            writer.uint32(42).string(message.orderSide);
        }
        if (message.state !== "") {
            writer.uint32(50).string(message.state);
        }
        if (message.quantityFilled !== "") {
            writer.uint32(58).string(message.quantityFilled);
        }
        if (message.quantityRemaining !== "") {
            writer.uint32(66).string(message.quantityRemaining);
        }
        if (message.createdAt !== "0") {
            writer.uint32(72).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(80).sint64(message.updatedAt);
        }
        if (message.price !== "") {
            writer.uint32(90).string(message.price);
        }
        if (message.margin !== "") {
            writer.uint32(98).string(message.margin);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderStateRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.orderType = reader.string();
                    break;
                case 5:
                    message.orderSide = reader.string();
                    break;
                case 6:
                    message.state = reader.string();
                    break;
                case 7:
                    message.quantityFilled = reader.string();
                    break;
                case 8:
                    message.quantityRemaining = reader.string();
                    break;
                case 9:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 10:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 11:
                    message.price = reader.string();
                    break;
                case 12:
                    message.margin = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            state: isSet(object.state) ? String(object.state) : "",
            quantityFilled: isSet(object.quantityFilled) ? String(object.quantityFilled) : "",
            quantityRemaining: isSet(object.quantityRemaining) ? String(object.quantityRemaining) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            price: isSet(object.price) ? String(object.price) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.state !== undefined && (obj.state = message.state);
        message.quantityFilled !== undefined && (obj.quantityFilled = message.quantityFilled);
        message.quantityRemaining !== undefined && (obj.quantityRemaining = message.quantityRemaining);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.price !== undefined && (obj.price = message.price);
        message.margin !== undefined && (obj.margin = message.margin);
        return obj;
    },
    create(base) {
        return OrderStateRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderStateRecord();
        message.orderHash = object.orderHash ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.orderType = object.orderType ?? "";
        message.orderSide = object.orderSide ?? "";
        message.state = object.state ?? "";
        message.quantityFilled = object.quantityFilled ?? "";
        message.quantityRemaining = object.quantityRemaining ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.price = object.price ?? "";
        message.margin = object.margin ?? "";
        return message;
    },
};
function createBaseSubaccountsListRequest() {
    return { accountAddress: "" };
}
export const SubaccountsListRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountAddress !== "") {
            writer.uint32(10).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountsListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return SubaccountsListRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountsListRequest();
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBaseSubaccountsListResponse() {
    return { subaccounts: [] };
}
export const SubaccountsListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.subaccounts) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountsListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccounts.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { subaccounts: Array.isArray(object?.subaccounts) ? object.subaccounts.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.subaccounts) {
            obj.subaccounts = message.subaccounts.map((e) => e);
        }
        else {
            obj.subaccounts = [];
        }
        return obj;
    },
    create(base) {
        return SubaccountsListResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountsListResponse();
        message.subaccounts = object.subaccounts?.map((e) => e) || [];
        return message;
    },
};
function createBaseSubaccountBalancesListRequest() {
    return { subaccountId: "", denoms: [] };
}
export const SubaccountBalancesListRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.denoms) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalancesListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.denoms.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            denoms: Array.isArray(object?.denoms) ? object.denoms.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.denoms) {
            obj.denoms = message.denoms.map((e) => e);
        }
        else {
            obj.denoms = [];
        }
        return obj;
    },
    create(base) {
        return SubaccountBalancesListRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalancesListRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.denoms = object.denoms?.map((e) => e) || [];
        return message;
    },
};
function createBaseSubaccountBalancesListResponse() {
    return { balances: [] };
}
export const SubaccountBalancesListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.balances) {
            SubaccountBalance.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalancesListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.balances.push(SubaccountBalance.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            balances: Array.isArray(object?.balances) ? object.balances.map((e) => SubaccountBalance.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.balances) {
            obj.balances = message.balances.map((e) => e ? SubaccountBalance.toJSON(e) : undefined);
        }
        else {
            obj.balances = [];
        }
        return obj;
    },
    create(base) {
        return SubaccountBalancesListResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalancesListResponse();
        message.balances = object.balances?.map((e) => SubaccountBalance.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSubaccountBalance() {
    return { subaccountId: "", accountAddress: "", denom: "", deposit: undefined };
}
export const SubaccountBalance = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(18).string(message.accountAddress);
        }
        if (message.denom !== "") {
            writer.uint32(26).string(message.denom);
        }
        if (message.deposit !== undefined) {
            SubaccountDeposit.encode(message.deposit, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalance();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.accountAddress = reader.string();
                    break;
                case 3:
                    message.denom = reader.string();
                    break;
                case 4:
                    message.deposit = SubaccountDeposit.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            deposit: isSet(object.deposit) ? SubaccountDeposit.fromJSON(object.deposit) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.denom !== undefined && (obj.denom = message.denom);
        message.deposit !== undefined &&
            (obj.deposit = message.deposit ? SubaccountDeposit.toJSON(message.deposit) : undefined);
        return obj;
    },
    create(base) {
        return SubaccountBalance.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalance();
        message.subaccountId = object.subaccountId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.denom = object.denom ?? "";
        message.deposit = (object.deposit !== undefined && object.deposit !== null)
            ? SubaccountDeposit.fromPartial(object.deposit)
            : undefined;
        return message;
    },
};
function createBaseSubaccountDeposit() {
    return { totalBalance: "", availableBalance: "" };
}
export const SubaccountDeposit = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.totalBalance !== "") {
            writer.uint32(10).string(message.totalBalance);
        }
        if (message.availableBalance !== "") {
            writer.uint32(18).string(message.availableBalance);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountDeposit();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.totalBalance = reader.string();
                    break;
                case 2:
                    message.availableBalance = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            totalBalance: isSet(object.totalBalance) ? String(object.totalBalance) : "",
            availableBalance: isSet(object.availableBalance) ? String(object.availableBalance) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.totalBalance !== undefined && (obj.totalBalance = message.totalBalance);
        message.availableBalance !== undefined && (obj.availableBalance = message.availableBalance);
        return obj;
    },
    create(base) {
        return SubaccountDeposit.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountDeposit();
        message.totalBalance = object.totalBalance ?? "";
        message.availableBalance = object.availableBalance ?? "";
        return message;
    },
};
function createBaseSubaccountBalanceEndpointRequest() {
    return { subaccountId: "", denom: "" };
}
export const SubaccountBalanceEndpointRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalanceEndpointRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.denom !== undefined && (obj.denom = message.denom);
        return obj;
    },
    create(base) {
        return SubaccountBalanceEndpointRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalanceEndpointRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.denom = object.denom ?? "";
        return message;
    },
};
function createBaseSubaccountBalanceEndpointResponse() {
    return { balance: undefined };
}
export const SubaccountBalanceEndpointResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.balance !== undefined) {
            SubaccountBalance.encode(message.balance, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalanceEndpointResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.balance = SubaccountBalance.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { balance: isSet(object.balance) ? SubaccountBalance.fromJSON(object.balance) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.balance !== undefined &&
            (obj.balance = message.balance ? SubaccountBalance.toJSON(message.balance) : undefined);
        return obj;
    },
    create(base) {
        return SubaccountBalanceEndpointResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalanceEndpointResponse();
        message.balance = (object.balance !== undefined && object.balance !== null)
            ? SubaccountBalance.fromPartial(object.balance)
            : undefined;
        return message;
    },
};
function createBaseStreamSubaccountBalanceRequest() {
    return { subaccountId: "", denoms: [] };
}
export const StreamSubaccountBalanceRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        for (const v of message.denoms) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamSubaccountBalanceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.denoms.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            denoms: Array.isArray(object?.denoms) ? object.denoms.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.denoms) {
            obj.denoms = message.denoms.map((e) => e);
        }
        else {
            obj.denoms = [];
        }
        return obj;
    },
    create(base) {
        return StreamSubaccountBalanceRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamSubaccountBalanceRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.denoms = object.denoms?.map((e) => e) || [];
        return message;
    },
};
function createBaseStreamSubaccountBalanceResponse() {
    return { balance: undefined, timestamp: "0" };
}
export const StreamSubaccountBalanceResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.balance !== undefined) {
            SubaccountBalance.encode(message.balance, writer.uint32(10).fork()).ldelim();
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamSubaccountBalanceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.balance = SubaccountBalance.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            balance: isSet(object.balance) ? SubaccountBalance.fromJSON(object.balance) : undefined,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.balance !== undefined &&
            (obj.balance = message.balance ? SubaccountBalance.toJSON(message.balance) : undefined);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamSubaccountBalanceResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamSubaccountBalanceResponse();
        message.balance = (object.balance !== undefined && object.balance !== null)
            ? SubaccountBalance.fromPartial(object.balance)
            : undefined;
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSubaccountHistoryRequest() {
    return { subaccountId: "", denom: "", transferTypes: [], skip: "0", limit: 0, endTime: "0" };
}
export const SubaccountHistoryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        for (const v of message.transferTypes) {
            writer.uint32(26).string(v);
        }
        if (message.skip !== "0") {
            writer.uint32(32).uint64(message.skip);
        }
        if (message.limit !== 0) {
            writer.uint32(40).sint32(message.limit);
        }
        if (message.endTime !== "0") {
            writer.uint32(48).sint64(message.endTime);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountHistoryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                case 3:
                    message.transferTypes.push(reader.string());
                    break;
                case 4:
                    message.skip = longToString(reader.uint64());
                    break;
                case 5:
                    message.limit = reader.sint32();
                    break;
                case 6:
                    message.endTime = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            transferTypes: Array.isArray(object?.transferTypes) ? object.transferTypes.map((e) => String(e)) : [],
            skip: isSet(object.skip) ? String(object.skip) : "0",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.denom !== undefined && (obj.denom = message.denom);
        if (message.transferTypes) {
            obj.transferTypes = message.transferTypes.map((e) => e);
        }
        else {
            obj.transferTypes = [];
        }
        message.skip !== undefined && (obj.skip = message.skip);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.endTime !== undefined && (obj.endTime = message.endTime);
        return obj;
    },
    create(base) {
        return SubaccountHistoryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountHistoryRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.denom = object.denom ?? "";
        message.transferTypes = object.transferTypes?.map((e) => e) || [];
        message.skip = object.skip ?? "0";
        message.limit = object.limit ?? 0;
        message.endTime = object.endTime ?? "0";
        return message;
    },
};
function createBaseSubaccountHistoryResponse() {
    return { transfers: [], paging: undefined };
}
export const SubaccountHistoryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.transfers) {
            SubaccountBalanceTransfer.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountHistoryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.transfers.push(SubaccountBalanceTransfer.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            transfers: Array.isArray(object?.transfers)
                ? object.transfers.map((e) => SubaccountBalanceTransfer.fromJSON(e))
                : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.transfers) {
            obj.transfers = message.transfers.map((e) => e ? SubaccountBalanceTransfer.toJSON(e) : undefined);
        }
        else {
            obj.transfers = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return SubaccountHistoryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountHistoryResponse();
        message.transfers = object.transfers?.map((e) => SubaccountBalanceTransfer.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseSubaccountBalanceTransfer() {
    return {
        transferType: "",
        srcSubaccountId: "",
        srcAccountAddress: "",
        dstSubaccountId: "",
        dstAccountAddress: "",
        amount: undefined,
        executedAt: "0",
    };
}
export const SubaccountBalanceTransfer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.transferType !== "") {
            writer.uint32(10).string(message.transferType);
        }
        if (message.srcSubaccountId !== "") {
            writer.uint32(18).string(message.srcSubaccountId);
        }
        if (message.srcAccountAddress !== "") {
            writer.uint32(26).string(message.srcAccountAddress);
        }
        if (message.dstSubaccountId !== "") {
            writer.uint32(34).string(message.dstSubaccountId);
        }
        if (message.dstAccountAddress !== "") {
            writer.uint32(42).string(message.dstAccountAddress);
        }
        if (message.amount !== undefined) {
            CosmosCoin.encode(message.amount, writer.uint32(50).fork()).ldelim();
        }
        if (message.executedAt !== "0") {
            writer.uint32(56).sint64(message.executedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalanceTransfer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.transferType = reader.string();
                    break;
                case 2:
                    message.srcSubaccountId = reader.string();
                    break;
                case 3:
                    message.srcAccountAddress = reader.string();
                    break;
                case 4:
                    message.dstSubaccountId = reader.string();
                    break;
                case 5:
                    message.dstAccountAddress = reader.string();
                    break;
                case 6:
                    message.amount = CosmosCoin.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.executedAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            transferType: isSet(object.transferType) ? String(object.transferType) : "",
            srcSubaccountId: isSet(object.srcSubaccountId) ? String(object.srcSubaccountId) : "",
            srcAccountAddress: isSet(object.srcAccountAddress) ? String(object.srcAccountAddress) : "",
            dstSubaccountId: isSet(object.dstSubaccountId) ? String(object.dstSubaccountId) : "",
            dstAccountAddress: isSet(object.dstAccountAddress) ? String(object.dstAccountAddress) : "",
            amount: isSet(object.amount) ? CosmosCoin.fromJSON(object.amount) : undefined,
            executedAt: isSet(object.executedAt) ? String(object.executedAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.transferType !== undefined && (obj.transferType = message.transferType);
        message.srcSubaccountId !== undefined && (obj.srcSubaccountId = message.srcSubaccountId);
        message.srcAccountAddress !== undefined && (obj.srcAccountAddress = message.srcAccountAddress);
        message.dstSubaccountId !== undefined && (obj.dstSubaccountId = message.dstSubaccountId);
        message.dstAccountAddress !== undefined && (obj.dstAccountAddress = message.dstAccountAddress);
        message.amount !== undefined && (obj.amount = message.amount ? CosmosCoin.toJSON(message.amount) : undefined);
        message.executedAt !== undefined && (obj.executedAt = message.executedAt);
        return obj;
    },
    create(base) {
        return SubaccountBalanceTransfer.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalanceTransfer();
        message.transferType = object.transferType ?? "";
        message.srcSubaccountId = object.srcSubaccountId ?? "";
        message.srcAccountAddress = object.srcAccountAddress ?? "";
        message.dstSubaccountId = object.dstSubaccountId ?? "";
        message.dstAccountAddress = object.dstAccountAddress ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? CosmosCoin.fromPartial(object.amount)
            : undefined;
        message.executedAt = object.executedAt ?? "0";
        return message;
    },
};
function createBaseCosmosCoin() {
    return { denom: "", amount: "" };
}
export const CosmosCoin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCosmosCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return CosmosCoin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCosmosCoin();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBasePaging() {
    return { total: "0", from: 0, to: 0, countBySubaccount: "0", next: [] };
}
export const Paging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== "0") {
            writer.uint32(8).sint64(message.total);
        }
        if (message.from !== 0) {
            writer.uint32(16).sint32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(24).sint32(message.to);
        }
        if (message.countBySubaccount !== "0") {
            writer.uint32(32).sint64(message.countBySubaccount);
        }
        for (const v of message.next) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = longToString(reader.sint64());
                    break;
                case 2:
                    message.from = reader.sint32();
                    break;
                case 3:
                    message.to = reader.sint32();
                    break;
                case 4:
                    message.countBySubaccount = longToString(reader.sint64());
                    break;
                case 5:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? String(object.total) : "0",
            from: isSet(object.from) ? Number(object.from) : 0,
            to: isSet(object.to) ? Number(object.to) : 0,
            countBySubaccount: isSet(object.countBySubaccount) ? String(object.countBySubaccount) : "0",
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = message.total);
        message.from !== undefined && (obj.from = Math.round(message.from));
        message.to !== undefined && (obj.to = Math.round(message.to));
        message.countBySubaccount !== undefined && (obj.countBySubaccount = message.countBySubaccount);
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return Paging.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePaging();
        message.total = object.total ?? "0";
        message.from = object.from ?? 0;
        message.to = object.to ?? 0;
        message.countBySubaccount = object.countBySubaccount ?? "0";
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseSubaccountOrderSummaryRequest() {
    return { subaccountId: "", marketId: "", orderDirection: "" };
}
export const SubaccountOrderSummaryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId !== "") {
            writer.uint32(10).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.orderDirection !== "") {
            writer.uint32(26).string(message.orderDirection);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrderSummaryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.orderDirection = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            orderDirection: isSet(object.orderDirection) ? String(object.orderDirection) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.orderDirection !== undefined && (obj.orderDirection = message.orderDirection);
        return obj;
    },
    create(base) {
        return SubaccountOrderSummaryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrderSummaryRequest();
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.orderDirection = object.orderDirection ?? "";
        return message;
    },
};
function createBaseSubaccountOrderSummaryResponse() {
    return { spotOrdersTotal: "0", derivativeOrdersTotal: "0" };
}
export const SubaccountOrderSummaryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.spotOrdersTotal !== "0") {
            writer.uint32(8).sint64(message.spotOrdersTotal);
        }
        if (message.derivativeOrdersTotal !== "0") {
            writer.uint32(16).sint64(message.derivativeOrdersTotal);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountOrderSummaryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotOrdersTotal = longToString(reader.sint64());
                    break;
                case 2:
                    message.derivativeOrdersTotal = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotOrdersTotal: isSet(object.spotOrdersTotal) ? String(object.spotOrdersTotal) : "0",
            derivativeOrdersTotal: isSet(object.derivativeOrdersTotal) ? String(object.derivativeOrdersTotal) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.spotOrdersTotal !== undefined && (obj.spotOrdersTotal = message.spotOrdersTotal);
        message.derivativeOrdersTotal !== undefined && (obj.derivativeOrdersTotal = message.derivativeOrdersTotal);
        return obj;
    },
    create(base) {
        return SubaccountOrderSummaryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountOrderSummaryResponse();
        message.spotOrdersTotal = object.spotOrdersTotal ?? "0";
        message.derivativeOrdersTotal = object.derivativeOrdersTotal ?? "0";
        return message;
    },
};
function createBaseRewardsRequest() {
    return { epoch: "0", accountAddress: "" };
}
export const RewardsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.epoch !== "0") {
            writer.uint32(8).sint64(message.epoch);
        }
        if (message.accountAddress !== "") {
            writer.uint32(18).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRewardsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.epoch = longToString(reader.sint64());
                    break;
                case 2:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            epoch: isSet(object.epoch) ? String(object.epoch) : "0",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.epoch !== undefined && (obj.epoch = message.epoch);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return RewardsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRewardsRequest();
        message.epoch = object.epoch ?? "0";
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBaseRewardsResponse() {
    return { rewards: [] };
}
export const RewardsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.rewards) {
            Reward.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRewardsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.rewards.push(Reward.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { rewards: Array.isArray(object?.rewards) ? object.rewards.map((e) => Reward.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.rewards) {
            obj.rewards = message.rewards.map((e) => e ? Reward.toJSON(e) : undefined);
        }
        else {
            obj.rewards = [];
        }
        return obj;
    },
    create(base) {
        return RewardsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRewardsResponse();
        message.rewards = object.rewards?.map((e) => Reward.fromPartial(e)) || [];
        return message;
    },
};
function createBaseReward() {
    return { accountAddress: "", rewards: [], distributedAt: "0" };
}
export const Reward = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountAddress !== "") {
            writer.uint32(10).string(message.accountAddress);
        }
        for (const v of message.rewards) {
            Coin.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.distributedAt !== "0") {
            writer.uint32(24).sint64(message.distributedAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReward();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountAddress = reader.string();
                    break;
                case 2:
                    message.rewards.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.distributedAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            rewards: Array.isArray(object?.rewards) ? object.rewards.map((e) => Coin.fromJSON(e)) : [],
            distributedAt: isSet(object.distributedAt) ? String(object.distributedAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        if (message.rewards) {
            obj.rewards = message.rewards.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.rewards = [];
        }
        message.distributedAt !== undefined && (obj.distributedAt = message.distributedAt);
        return obj;
    },
    create(base) {
        return Reward.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseReward();
        message.accountAddress = object.accountAddress ?? "";
        message.rewards = object.rewards?.map((e) => Coin.fromPartial(e)) || [];
        message.distributedAt = object.distributedAt ?? "0";
        return message;
    },
};
function createBaseCoin() {
    return { denom: "", amount: "" };
}
export const Coin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return Coin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCoin();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseStreamAccountDataRequest() {
    return { accountAddress: "" };
}
export const StreamAccountDataRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.accountAddress !== "") {
            writer.uint32(10).string(message.accountAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamAccountDataRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        return obj;
    },
    create(base) {
        return StreamAccountDataRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamAccountDataRequest();
        message.accountAddress = object.accountAddress ?? "";
        return message;
    },
};
function createBaseStreamAccountDataResponse() {
    return {
        subaccountBalance: undefined,
        position: undefined,
        trade: undefined,
        order: undefined,
        orderHistory: undefined,
        fundingPayment: undefined,
    };
}
export const StreamAccountDataResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountBalance !== undefined) {
            SubaccountBalanceResult.encode(message.subaccountBalance, writer.uint32(10).fork()).ldelim();
        }
        if (message.position !== undefined) {
            PositionsResult.encode(message.position, writer.uint32(18).fork()).ldelim();
        }
        if (message.trade !== undefined) {
            TradeResult.encode(message.trade, writer.uint32(26).fork()).ldelim();
        }
        if (message.order !== undefined) {
            OrderResult.encode(message.order, writer.uint32(34).fork()).ldelim();
        }
        if (message.orderHistory !== undefined) {
            OrderHistoryResult.encode(message.orderHistory, writer.uint32(42).fork()).ldelim();
        }
        if (message.fundingPayment !== undefined) {
            FundingPaymentResult.encode(message.fundingPayment, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamAccountDataResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountBalance = SubaccountBalanceResult.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.position = PositionsResult.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.trade = TradeResult.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.order = OrderResult.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.orderHistory = OrderHistoryResult.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.fundingPayment = FundingPaymentResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountBalance: isSet(object.subaccountBalance)
                ? SubaccountBalanceResult.fromJSON(object.subaccountBalance)
                : undefined,
            position: isSet(object.position) ? PositionsResult.fromJSON(object.position) : undefined,
            trade: isSet(object.trade) ? TradeResult.fromJSON(object.trade) : undefined,
            order: isSet(object.order) ? OrderResult.fromJSON(object.order) : undefined,
            orderHistory: isSet(object.orderHistory) ? OrderHistoryResult.fromJSON(object.orderHistory) : undefined,
            fundingPayment: isSet(object.fundingPayment) ? FundingPaymentResult.fromJSON(object.fundingPayment) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountBalance !== undefined && (obj.subaccountBalance = message.subaccountBalance
            ? SubaccountBalanceResult.toJSON(message.subaccountBalance)
            : undefined);
        message.position !== undefined &&
            (obj.position = message.position ? PositionsResult.toJSON(message.position) : undefined);
        message.trade !== undefined && (obj.trade = message.trade ? TradeResult.toJSON(message.trade) : undefined);
        message.order !== undefined && (obj.order = message.order ? OrderResult.toJSON(message.order) : undefined);
        message.orderHistory !== undefined &&
            (obj.orderHistory = message.orderHistory ? OrderHistoryResult.toJSON(message.orderHistory) : undefined);
        message.fundingPayment !== undefined &&
            (obj.fundingPayment = message.fundingPayment ? FundingPaymentResult.toJSON(message.fundingPayment) : undefined);
        return obj;
    },
    create(base) {
        return StreamAccountDataResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamAccountDataResponse();
        message.subaccountBalance = (object.subaccountBalance !== undefined && object.subaccountBalance !== null)
            ? SubaccountBalanceResult.fromPartial(object.subaccountBalance)
            : undefined;
        message.position = (object.position !== undefined && object.position !== null)
            ? PositionsResult.fromPartial(object.position)
            : undefined;
        message.trade = (object.trade !== undefined && object.trade !== null)
            ? TradeResult.fromPartial(object.trade)
            : undefined;
        message.order = (object.order !== undefined && object.order !== null)
            ? OrderResult.fromPartial(object.order)
            : undefined;
        message.orderHistory = (object.orderHistory !== undefined && object.orderHistory !== null)
            ? OrderHistoryResult.fromPartial(object.orderHistory)
            : undefined;
        message.fundingPayment = (object.fundingPayment !== undefined && object.fundingPayment !== null)
            ? FundingPaymentResult.fromPartial(object.fundingPayment)
            : undefined;
        return message;
    },
};
function createBaseSubaccountBalanceResult() {
    return { balance: undefined, timestamp: "0" };
}
export const SubaccountBalanceResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.balance !== undefined) {
            SubaccountBalance.encode(message.balance, writer.uint32(10).fork()).ldelim();
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubaccountBalanceResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.balance = SubaccountBalance.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            balance: isSet(object.balance) ? SubaccountBalance.fromJSON(object.balance) : undefined,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.balance !== undefined &&
            (obj.balance = message.balance ? SubaccountBalance.toJSON(message.balance) : undefined);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return SubaccountBalanceResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSubaccountBalanceResult();
        message.balance = (object.balance !== undefined && object.balance !== null)
            ? SubaccountBalance.fromPartial(object.balance)
            : undefined;
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBasePositionsResult() {
    return { position: undefined, timestamp: "0" };
}
export const PositionsResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.position !== undefined) {
            Position.encode(message.position, writer.uint32(10).fork()).ldelim();
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionsResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.position = Position.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            position: isSet(object.position) ? Position.fromJSON(object.position) : undefined,
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.position !== undefined && (obj.position = message.position ? Position.toJSON(message.position) : undefined);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PositionsResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionsResult();
        message.position = (object.position !== undefined && object.position !== null)
            ? Position.fromPartial(object.position)
            : undefined;
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBasePosition() {
    return {
        ticker: "",
        marketId: "",
        subaccountId: "",
        direction: "",
        quantity: "",
        entryPrice: "",
        margin: "",
        liquidationPrice: "",
        markPrice: "",
        updatedAt: "0",
        createdAt: "0",
    };
}
export const Position = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ticker !== "") {
            writer.uint32(10).string(message.ticker);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.direction !== "") {
            writer.uint32(34).string(message.direction);
        }
        if (message.quantity !== "") {
            writer.uint32(42).string(message.quantity);
        }
        if (message.entryPrice !== "") {
            writer.uint32(50).string(message.entryPrice);
        }
        if (message.margin !== "") {
            writer.uint32(58).string(message.margin);
        }
        if (message.liquidationPrice !== "") {
            writer.uint32(66).string(message.liquidationPrice);
        }
        if (message.markPrice !== "") {
            writer.uint32(74).string(message.markPrice);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(80).sint64(message.updatedAt);
        }
        if (message.createdAt !== "0") {
            writer.uint32(88).sint64(message.createdAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ticker = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.direction = reader.string();
                    break;
                case 5:
                    message.quantity = reader.string();
                    break;
                case 6:
                    message.entryPrice = reader.string();
                    break;
                case 7:
                    message.margin = reader.string();
                    break;
                case 8:
                    message.liquidationPrice = reader.string();
                    break;
                case 9:
                    message.markPrice = reader.string();
                    break;
                case 10:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 11:
                    message.createdAt = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            direction: isSet(object.direction) ? String(object.direction) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            entryPrice: isSet(object.entryPrice) ? String(object.entryPrice) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
            liquidationPrice: isSet(object.liquidationPrice) ? String(object.liquidationPrice) : "",
            markPrice: isSet(object.markPrice) ? String(object.markPrice) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.direction !== undefined && (obj.direction = message.direction);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.entryPrice !== undefined && (obj.entryPrice = message.entryPrice);
        message.margin !== undefined && (obj.margin = message.margin);
        message.liquidationPrice !== undefined && (obj.liquidationPrice = message.liquidationPrice);
        message.markPrice !== undefined && (obj.markPrice = message.markPrice);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        return obj;
    },
    create(base) {
        return Position.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePosition();
        message.ticker = object.ticker ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.direction = object.direction ?? "";
        message.quantity = object.quantity ?? "";
        message.entryPrice = object.entryPrice ?? "";
        message.margin = object.margin ?? "";
        message.liquidationPrice = object.liquidationPrice ?? "";
        message.markPrice = object.markPrice ?? "";
        message.updatedAt = object.updatedAt ?? "0";
        message.createdAt = object.createdAt ?? "0";
        return message;
    },
};
function createBaseTradeResult() {
    return { spotTrade: undefined, derivativeTrade: undefined, operationType: "", timestamp: "0" };
}
export const TradeResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.spotTrade !== undefined) {
            SpotTrade.encode(message.spotTrade, writer.uint32(10).fork()).ldelim();
        }
        if (message.derivativeTrade !== undefined) {
            DerivativeTrade.encode(message.derivativeTrade, writer.uint32(18).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(26).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradeResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotTrade = SpotTrade.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.derivativeTrade = DerivativeTrade.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.operationType = reader.string();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotTrade: isSet(object.spotTrade) ? SpotTrade.fromJSON(object.spotTrade) : undefined,
            derivativeTrade: isSet(object.derivativeTrade) ? DerivativeTrade.fromJSON(object.derivativeTrade) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.spotTrade !== undefined &&
            (obj.spotTrade = message.spotTrade ? SpotTrade.toJSON(message.spotTrade) : undefined);
        message.derivativeTrade !== undefined &&
            (obj.derivativeTrade = message.derivativeTrade ? DerivativeTrade.toJSON(message.derivativeTrade) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return TradeResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTradeResult();
        message.spotTrade = (object.spotTrade !== undefined && object.spotTrade !== null)
            ? SpotTrade.fromPartial(object.spotTrade)
            : undefined;
        message.derivativeTrade = (object.derivativeTrade !== undefined && object.derivativeTrade !== null)
            ? DerivativeTrade.fromPartial(object.derivativeTrade)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSpotTrade() {
    return {
        orderHash: "",
        subaccountId: "",
        marketId: "",
        tradeExecutionType: "",
        tradeDirection: "",
        price: undefined,
        fee: "",
        executedAt: "0",
        feeRecipient: "",
        tradeId: "",
        executionSide: "",
        cid: "",
    };
}
export const SpotTrade = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.tradeExecutionType !== "") {
            writer.uint32(34).string(message.tradeExecutionType);
        }
        if (message.tradeDirection !== "") {
            writer.uint32(42).string(message.tradeDirection);
        }
        if (message.price !== undefined) {
            PriceLevel.encode(message.price, writer.uint32(50).fork()).ldelim();
        }
        if (message.fee !== "") {
            writer.uint32(58).string(message.fee);
        }
        if (message.executedAt !== "0") {
            writer.uint32(64).sint64(message.executedAt);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(74).string(message.feeRecipient);
        }
        if (message.tradeId !== "") {
            writer.uint32(82).string(message.tradeId);
        }
        if (message.executionSide !== "") {
            writer.uint32(90).string(message.executionSide);
        }
        if (message.cid !== "") {
            writer.uint32(98).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotTrade();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.tradeExecutionType = reader.string();
                    break;
                case 5:
                    message.tradeDirection = reader.string();
                    break;
                case 6:
                    message.price = PriceLevel.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.fee = reader.string();
                    break;
                case 8:
                    message.executedAt = longToString(reader.sint64());
                    break;
                case 9:
                    message.feeRecipient = reader.string();
                    break;
                case 10:
                    message.tradeId = reader.string();
                    break;
                case 11:
                    message.executionSide = reader.string();
                    break;
                case 12:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            tradeExecutionType: isSet(object.tradeExecutionType) ? String(object.tradeExecutionType) : "",
            tradeDirection: isSet(object.tradeDirection) ? String(object.tradeDirection) : "",
            price: isSet(object.price) ? PriceLevel.fromJSON(object.price) : undefined,
            fee: isSet(object.fee) ? String(object.fee) : "",
            executedAt: isSet(object.executedAt) ? String(object.executedAt) : "0",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.tradeExecutionType !== undefined && (obj.tradeExecutionType = message.tradeExecutionType);
        message.tradeDirection !== undefined && (obj.tradeDirection = message.tradeDirection);
        message.price !== undefined && (obj.price = message.price ? PriceLevel.toJSON(message.price) : undefined);
        message.fee !== undefined && (obj.fee = message.fee);
        message.executedAt !== undefined && (obj.executedAt = message.executedAt);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SpotTrade.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotTrade();
        message.orderHash = object.orderHash ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.tradeExecutionType = object.tradeExecutionType ?? "";
        message.tradeDirection = object.tradeDirection ?? "";
        message.price = (object.price !== undefined && object.price !== null)
            ? PriceLevel.fromPartial(object.price)
            : undefined;
        message.fee = object.fee ?? "";
        message.executedAt = object.executedAt ?? "0";
        message.feeRecipient = object.feeRecipient ?? "";
        message.tradeId = object.tradeId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBasePriceLevel() {
    return { price: "", quantity: "", timestamp: "0" };
}
export const PriceLevel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(18).string(message.quantity);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceLevel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.quantity = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PriceLevel.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceLevel();
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseDerivativeTrade() {
    return {
        orderHash: "",
        subaccountId: "",
        marketId: "",
        tradeExecutionType: "",
        isLiquidation: false,
        positionDelta: undefined,
        payout: "",
        fee: "",
        executedAt: "0",
        feeRecipient: "",
        tradeId: "",
        executionSide: "",
        cid: "",
    };
}
export const DerivativeTrade = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.tradeExecutionType !== "") {
            writer.uint32(34).string(message.tradeExecutionType);
        }
        if (message.isLiquidation === true) {
            writer.uint32(40).bool(message.isLiquidation);
        }
        if (message.positionDelta !== undefined) {
            PositionDelta.encode(message.positionDelta, writer.uint32(50).fork()).ldelim();
        }
        if (message.payout !== "") {
            writer.uint32(58).string(message.payout);
        }
        if (message.fee !== "") {
            writer.uint32(66).string(message.fee);
        }
        if (message.executedAt !== "0") {
            writer.uint32(72).sint64(message.executedAt);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(82).string(message.feeRecipient);
        }
        if (message.tradeId !== "") {
            writer.uint32(90).string(message.tradeId);
        }
        if (message.executionSide !== "") {
            writer.uint32(98).string(message.executionSide);
        }
        if (message.cid !== "") {
            writer.uint32(106).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeTrade();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.tradeExecutionType = reader.string();
                    break;
                case 5:
                    message.isLiquidation = reader.bool();
                    break;
                case 6:
                    message.positionDelta = PositionDelta.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.payout = reader.string();
                    break;
                case 8:
                    message.fee = reader.string();
                    break;
                case 9:
                    message.executedAt = longToString(reader.sint64());
                    break;
                case 10:
                    message.feeRecipient = reader.string();
                    break;
                case 11:
                    message.tradeId = reader.string();
                    break;
                case 12:
                    message.executionSide = reader.string();
                    break;
                case 13:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            tradeExecutionType: isSet(object.tradeExecutionType) ? String(object.tradeExecutionType) : "",
            isLiquidation: isSet(object.isLiquidation) ? Boolean(object.isLiquidation) : false,
            positionDelta: isSet(object.positionDelta) ? PositionDelta.fromJSON(object.positionDelta) : undefined,
            payout: isSet(object.payout) ? String(object.payout) : "",
            fee: isSet(object.fee) ? String(object.fee) : "",
            executedAt: isSet(object.executedAt) ? String(object.executedAt) : "0",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            tradeId: isSet(object.tradeId) ? String(object.tradeId) : "",
            executionSide: isSet(object.executionSide) ? String(object.executionSide) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.tradeExecutionType !== undefined && (obj.tradeExecutionType = message.tradeExecutionType);
        message.isLiquidation !== undefined && (obj.isLiquidation = message.isLiquidation);
        message.positionDelta !== undefined &&
            (obj.positionDelta = message.positionDelta ? PositionDelta.toJSON(message.positionDelta) : undefined);
        message.payout !== undefined && (obj.payout = message.payout);
        message.fee !== undefined && (obj.fee = message.fee);
        message.executedAt !== undefined && (obj.executedAt = message.executedAt);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.tradeId !== undefined && (obj.tradeId = message.tradeId);
        message.executionSide !== undefined && (obj.executionSide = message.executionSide);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return DerivativeTrade.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeTrade();
        message.orderHash = object.orderHash ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.tradeExecutionType = object.tradeExecutionType ?? "";
        message.isLiquidation = object.isLiquidation ?? false;
        message.positionDelta = (object.positionDelta !== undefined && object.positionDelta !== null)
            ? PositionDelta.fromPartial(object.positionDelta)
            : undefined;
        message.payout = object.payout ?? "";
        message.fee = object.fee ?? "";
        message.executedAt = object.executedAt ?? "0";
        message.feeRecipient = object.feeRecipient ?? "";
        message.tradeId = object.tradeId ?? "";
        message.executionSide = object.executionSide ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBasePositionDelta() {
    return { tradeDirection: "", executionPrice: "", executionQuantity: "", executionMargin: "" };
}
export const PositionDelta = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tradeDirection !== "") {
            writer.uint32(10).string(message.tradeDirection);
        }
        if (message.executionPrice !== "") {
            writer.uint32(18).string(message.executionPrice);
        }
        if (message.executionQuantity !== "") {
            writer.uint32(26).string(message.executionQuantity);
        }
        if (message.executionMargin !== "") {
            writer.uint32(34).string(message.executionMargin);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionDelta();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tradeDirection = reader.string();
                    break;
                case 2:
                    message.executionPrice = reader.string();
                    break;
                case 3:
                    message.executionQuantity = reader.string();
                    break;
                case 4:
                    message.executionMargin = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tradeDirection: isSet(object.tradeDirection) ? String(object.tradeDirection) : "",
            executionPrice: isSet(object.executionPrice) ? String(object.executionPrice) : "",
            executionQuantity: isSet(object.executionQuantity) ? String(object.executionQuantity) : "",
            executionMargin: isSet(object.executionMargin) ? String(object.executionMargin) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.tradeDirection !== undefined && (obj.tradeDirection = message.tradeDirection);
        message.executionPrice !== undefined && (obj.executionPrice = message.executionPrice);
        message.executionQuantity !== undefined && (obj.executionQuantity = message.executionQuantity);
        message.executionMargin !== undefined && (obj.executionMargin = message.executionMargin);
        return obj;
    },
    create(base) {
        return PositionDelta.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePositionDelta();
        message.tradeDirection = object.tradeDirection ?? "";
        message.executionPrice = object.executionPrice ?? "";
        message.executionQuantity = object.executionQuantity ?? "";
        message.executionMargin = object.executionMargin ?? "";
        return message;
    },
};
function createBaseOrderResult() {
    return { spotOrder: undefined, derivativeOrder: undefined, operationType: "", timestamp: "0" };
}
export const OrderResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.spotOrder !== undefined) {
            SpotLimitOrder.encode(message.spotOrder, writer.uint32(10).fork()).ldelim();
        }
        if (message.derivativeOrder !== undefined) {
            DerivativeLimitOrder.encode(message.derivativeOrder, writer.uint32(18).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(26).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotOrder = SpotLimitOrder.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.derivativeOrder = DerivativeLimitOrder.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.operationType = reader.string();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotOrder: isSet(object.spotOrder) ? SpotLimitOrder.fromJSON(object.spotOrder) : undefined,
            derivativeOrder: isSet(object.derivativeOrder)
                ? DerivativeLimitOrder.fromJSON(object.derivativeOrder)
                : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.spotOrder !== undefined &&
            (obj.spotOrder = message.spotOrder ? SpotLimitOrder.toJSON(message.spotOrder) : undefined);
        message.derivativeOrder !== undefined &&
            (obj.derivativeOrder = message.derivativeOrder
                ? DerivativeLimitOrder.toJSON(message.derivativeOrder)
                : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return OrderResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderResult();
        message.spotOrder = (object.spotOrder !== undefined && object.spotOrder !== null)
            ? SpotLimitOrder.fromPartial(object.spotOrder)
            : undefined;
        message.derivativeOrder = (object.derivativeOrder !== undefined && object.derivativeOrder !== null)
            ? DerivativeLimitOrder.fromPartial(object.derivativeOrder)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSpotLimitOrder() {
    return {
        orderHash: "",
        orderSide: "",
        marketId: "",
        subaccountId: "",
        price: "",
        quantity: "",
        unfilledQuantity: "",
        triggerPrice: "",
        feeRecipient: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        txHash: "",
        cid: "",
    };
}
export const SpotLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.price !== "") {
            writer.uint32(42).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(50).string(message.quantity);
        }
        if (message.unfilledQuantity !== "") {
            writer.uint32(58).string(message.unfilledQuantity);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(66).string(message.triggerPrice);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(74).string(message.feeRecipient);
        }
        if (message.state !== "") {
            writer.uint32(82).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(88).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(96).sint64(message.updatedAt);
        }
        if (message.txHash !== "") {
            writer.uint32(106).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(114).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.price = reader.string();
                    break;
                case 6:
                    message.quantity = reader.string();
                    break;
                case 7:
                    message.unfilledQuantity = reader.string();
                    break;
                case 8:
                    message.triggerPrice = reader.string();
                    break;
                case 9:
                    message.feeRecipient = reader.string();
                    break;
                case 10:
                    message.state = reader.string();
                    break;
                case 11:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 12:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.txHash = reader.string();
                    break;
                case 14:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            unfilledQuantity: isSet(object.unfilledQuantity) ? String(object.unfilledQuantity) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.unfilledQuantity !== undefined && (obj.unfilledQuantity = message.unfilledQuantity);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SpotLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotLimitOrder();
        message.orderHash = object.orderHash ?? "";
        message.orderSide = object.orderSide ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.unfilledQuantity = object.unfilledQuantity ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseDerivativeLimitOrder() {
    return {
        orderHash: "",
        orderSide: "",
        marketId: "",
        subaccountId: "",
        isReduceOnly: false,
        margin: "",
        price: "",
        quantity: "",
        unfilledQuantity: "",
        triggerPrice: "",
        feeRecipient: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        orderNumber: "0",
        orderType: "",
        isConditional: false,
        triggerAt: "0",
        placedOrderHash: "",
        executionType: "",
        txHash: "",
        cid: "",
    };
}
export const DerivativeLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.orderSide !== "") {
            writer.uint32(18).string(message.orderSide);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.isReduceOnly === true) {
            writer.uint32(40).bool(message.isReduceOnly);
        }
        if (message.margin !== "") {
            writer.uint32(50).string(message.margin);
        }
        if (message.price !== "") {
            writer.uint32(58).string(message.price);
        }
        if (message.quantity !== "") {
            writer.uint32(66).string(message.quantity);
        }
        if (message.unfilledQuantity !== "") {
            writer.uint32(74).string(message.unfilledQuantity);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(82).string(message.triggerPrice);
        }
        if (message.feeRecipient !== "") {
            writer.uint32(90).string(message.feeRecipient);
        }
        if (message.state !== "") {
            writer.uint32(98).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(104).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(112).sint64(message.updatedAt);
        }
        if (message.orderNumber !== "0") {
            writer.uint32(120).sint64(message.orderNumber);
        }
        if (message.orderType !== "") {
            writer.uint32(130).string(message.orderType);
        }
        if (message.isConditional === true) {
            writer.uint32(136).bool(message.isConditional);
        }
        if (message.triggerAt !== "0") {
            writer.uint32(144).uint64(message.triggerAt);
        }
        if (message.placedOrderHash !== "") {
            writer.uint32(154).string(message.placedOrderHash);
        }
        if (message.executionType !== "") {
            writer.uint32(162).string(message.executionType);
        }
        if (message.txHash !== "") {
            writer.uint32(170).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(178).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.orderSide = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.isReduceOnly = reader.bool();
                    break;
                case 6:
                    message.margin = reader.string();
                    break;
                case 7:
                    message.price = reader.string();
                    break;
                case 8:
                    message.quantity = reader.string();
                    break;
                case 9:
                    message.unfilledQuantity = reader.string();
                    break;
                case 10:
                    message.triggerPrice = reader.string();
                    break;
                case 11:
                    message.feeRecipient = reader.string();
                    break;
                case 12:
                    message.state = reader.string();
                    break;
                case 13:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 15:
                    message.orderNumber = longToString(reader.sint64());
                    break;
                case 16:
                    message.orderType = reader.string();
                    break;
                case 17:
                    message.isConditional = reader.bool();
                    break;
                case 18:
                    message.triggerAt = longToString(reader.uint64());
                    break;
                case 19:
                    message.placedOrderHash = reader.string();
                    break;
                case 20:
                    message.executionType = reader.string();
                    break;
                case 21:
                    message.txHash = reader.string();
                    break;
                case 22:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderSide: isSet(object.orderSide) ? String(object.orderSide) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            isReduceOnly: isSet(object.isReduceOnly) ? Boolean(object.isReduceOnly) : false,
            margin: isSet(object.margin) ? String(object.margin) : "",
            price: isSet(object.price) ? String(object.price) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            unfilledQuantity: isSet(object.unfilledQuantity) ? String(object.unfilledQuantity) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            feeRecipient: isSet(object.feeRecipient) ? String(object.feeRecipient) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            orderNumber: isSet(object.orderNumber) ? String(object.orderNumber) : "0",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            isConditional: isSet(object.isConditional) ? Boolean(object.isConditional) : false,
            triggerAt: isSet(object.triggerAt) ? String(object.triggerAt) : "0",
            placedOrderHash: isSet(object.placedOrderHash) ? String(object.placedOrderHash) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderSide !== undefined && (obj.orderSide = message.orderSide);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.isReduceOnly !== undefined && (obj.isReduceOnly = message.isReduceOnly);
        message.margin !== undefined && (obj.margin = message.margin);
        message.price !== undefined && (obj.price = message.price);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.unfilledQuantity !== undefined && (obj.unfilledQuantity = message.unfilledQuantity);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.feeRecipient !== undefined && (obj.feeRecipient = message.feeRecipient);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.orderNumber !== undefined && (obj.orderNumber = message.orderNumber);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.triggerAt !== undefined && (obj.triggerAt = message.triggerAt);
        message.placedOrderHash !== undefined && (obj.placedOrderHash = message.placedOrderHash);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return DerivativeLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeLimitOrder();
        message.orderHash = object.orderHash ?? "";
        message.orderSide = object.orderSide ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.isReduceOnly = object.isReduceOnly ?? false;
        message.margin = object.margin ?? "";
        message.price = object.price ?? "";
        message.quantity = object.quantity ?? "";
        message.unfilledQuantity = object.unfilledQuantity ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.feeRecipient = object.feeRecipient ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.orderNumber = object.orderNumber ?? "0";
        message.orderType = object.orderType ?? "";
        message.isConditional = object.isConditional ?? false;
        message.triggerAt = object.triggerAt ?? "0";
        message.placedOrderHash = object.placedOrderHash ?? "";
        message.executionType = object.executionType ?? "";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseOrderHistoryResult() {
    return { spotOrderHistory: undefined, derivativeOrderHistory: undefined, operationType: "", timestamp: "0" };
}
export const OrderHistoryResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.spotOrderHistory !== undefined) {
            SpotOrderHistory.encode(message.spotOrderHistory, writer.uint32(10).fork()).ldelim();
        }
        if (message.derivativeOrderHistory !== undefined) {
            DerivativeOrderHistory.encode(message.derivativeOrderHistory, writer.uint32(18).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(26).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderHistoryResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotOrderHistory = SpotOrderHistory.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.derivativeOrderHistory = DerivativeOrderHistory.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.operationType = reader.string();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotOrderHistory: isSet(object.spotOrderHistory) ? SpotOrderHistory.fromJSON(object.spotOrderHistory) : undefined,
            derivativeOrderHistory: isSet(object.derivativeOrderHistory)
                ? DerivativeOrderHistory.fromJSON(object.derivativeOrderHistory)
                : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.spotOrderHistory !== undefined &&
            (obj.spotOrderHistory = message.spotOrderHistory ? SpotOrderHistory.toJSON(message.spotOrderHistory) : undefined);
        message.derivativeOrderHistory !== undefined && (obj.derivativeOrderHistory = message.derivativeOrderHistory
            ? DerivativeOrderHistory.toJSON(message.derivativeOrderHistory)
            : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return OrderHistoryResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderHistoryResult();
        message.spotOrderHistory = (object.spotOrderHistory !== undefined && object.spotOrderHistory !== null)
            ? SpotOrderHistory.fromPartial(object.spotOrderHistory)
            : undefined;
        message.derivativeOrderHistory =
            (object.derivativeOrderHistory !== undefined && object.derivativeOrderHistory !== null)
                ? DerivativeOrderHistory.fromPartial(object.derivativeOrderHistory)
                : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseSpotOrderHistory() {
    return {
        orderHash: "",
        marketId: "",
        isActive: false,
        subaccountId: "",
        executionType: "",
        orderType: "",
        price: "",
        triggerPrice: "",
        quantity: "",
        filledQuantity: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        direction: "",
        txHash: "",
        cid: "",
    };
}
export const SpotOrderHistory = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.isActive === true) {
            writer.uint32(24).bool(message.isActive);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.executionType !== "") {
            writer.uint32(42).string(message.executionType);
        }
        if (message.orderType !== "") {
            writer.uint32(50).string(message.orderType);
        }
        if (message.price !== "") {
            writer.uint32(58).string(message.price);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(66).string(message.triggerPrice);
        }
        if (message.quantity !== "") {
            writer.uint32(74).string(message.quantity);
        }
        if (message.filledQuantity !== "") {
            writer.uint32(82).string(message.filledQuantity);
        }
        if (message.state !== "") {
            writer.uint32(90).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(96).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(104).sint64(message.updatedAt);
        }
        if (message.direction !== "") {
            writer.uint32(114).string(message.direction);
        }
        if (message.txHash !== "") {
            writer.uint32(122).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(130).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotOrderHistory();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.isActive = reader.bool();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.executionType = reader.string();
                    break;
                case 6:
                    message.orderType = reader.string();
                    break;
                case 7:
                    message.price = reader.string();
                    break;
                case 8:
                    message.triggerPrice = reader.string();
                    break;
                case 9:
                    message.quantity = reader.string();
                    break;
                case 10:
                    message.filledQuantity = reader.string();
                    break;
                case 11:
                    message.state = reader.string();
                    break;
                case 12:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.direction = reader.string();
                    break;
                case 15:
                    message.txHash = reader.string();
                    break;
                case 16:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            price: isSet(object.price) ? String(object.price) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            filledQuantity: isSet(object.filledQuantity) ? String(object.filledQuantity) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            direction: isSet(object.direction) ? String(object.direction) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.price !== undefined && (obj.price = message.price);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.filledQuantity !== undefined && (obj.filledQuantity = message.filledQuantity);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.direction !== undefined && (obj.direction = message.direction);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return SpotOrderHistory.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotOrderHistory();
        message.orderHash = object.orderHash ?? "";
        message.marketId = object.marketId ?? "";
        message.isActive = object.isActive ?? false;
        message.subaccountId = object.subaccountId ?? "";
        message.executionType = object.executionType ?? "";
        message.orderType = object.orderType ?? "";
        message.price = object.price ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.quantity = object.quantity ?? "";
        message.filledQuantity = object.filledQuantity ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.direction = object.direction ?? "";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseDerivativeOrderHistory() {
    return {
        orderHash: "",
        marketId: "",
        isActive: false,
        subaccountId: "",
        executionType: "",
        orderType: "",
        price: "",
        triggerPrice: "",
        quantity: "",
        filledQuantity: "",
        state: "",
        createdAt: "0",
        updatedAt: "0",
        isReduceOnly: false,
        direction: "",
        isConditional: false,
        triggerAt: "0",
        placedOrderHash: "",
        margin: "",
        txHash: "",
        cid: "",
    };
}
export const DerivativeOrderHistory = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.isActive === true) {
            writer.uint32(24).bool(message.isActive);
        }
        if (message.subaccountId !== "") {
            writer.uint32(34).string(message.subaccountId);
        }
        if (message.executionType !== "") {
            writer.uint32(42).string(message.executionType);
        }
        if (message.orderType !== "") {
            writer.uint32(50).string(message.orderType);
        }
        if (message.price !== "") {
            writer.uint32(58).string(message.price);
        }
        if (message.triggerPrice !== "") {
            writer.uint32(66).string(message.triggerPrice);
        }
        if (message.quantity !== "") {
            writer.uint32(74).string(message.quantity);
        }
        if (message.filledQuantity !== "") {
            writer.uint32(82).string(message.filledQuantity);
        }
        if (message.state !== "") {
            writer.uint32(90).string(message.state);
        }
        if (message.createdAt !== "0") {
            writer.uint32(96).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(104).sint64(message.updatedAt);
        }
        if (message.isReduceOnly === true) {
            writer.uint32(112).bool(message.isReduceOnly);
        }
        if (message.direction !== "") {
            writer.uint32(122).string(message.direction);
        }
        if (message.isConditional === true) {
            writer.uint32(128).bool(message.isConditional);
        }
        if (message.triggerAt !== "0") {
            writer.uint32(136).uint64(message.triggerAt);
        }
        if (message.placedOrderHash !== "") {
            writer.uint32(146).string(message.placedOrderHash);
        }
        if (message.margin !== "") {
            writer.uint32(154).string(message.margin);
        }
        if (message.txHash !== "") {
            writer.uint32(162).string(message.txHash);
        }
        if (message.cid !== "") {
            writer.uint32(170).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeOrderHistory();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.isActive = reader.bool();
                    break;
                case 4:
                    message.subaccountId = reader.string();
                    break;
                case 5:
                    message.executionType = reader.string();
                    break;
                case 6:
                    message.orderType = reader.string();
                    break;
                case 7:
                    message.price = reader.string();
                    break;
                case 8:
                    message.triggerPrice = reader.string();
                    break;
                case 9:
                    message.quantity = reader.string();
                    break;
                case 10:
                    message.filledQuantity = reader.string();
                    break;
                case 11:
                    message.state = reader.string();
                    break;
                case 12:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 13:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.isReduceOnly = reader.bool();
                    break;
                case 15:
                    message.direction = reader.string();
                    break;
                case 16:
                    message.isConditional = reader.bool();
                    break;
                case 17:
                    message.triggerAt = longToString(reader.uint64());
                    break;
                case 18:
                    message.placedOrderHash = reader.string();
                    break;
                case 19:
                    message.margin = reader.string();
                    break;
                case 20:
                    message.txHash = reader.string();
                    break;
                case 21:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            executionType: isSet(object.executionType) ? String(object.executionType) : "",
            orderType: isSet(object.orderType) ? String(object.orderType) : "",
            price: isSet(object.price) ? String(object.price) : "",
            triggerPrice: isSet(object.triggerPrice) ? String(object.triggerPrice) : "",
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            filledQuantity: isSet(object.filledQuantity) ? String(object.filledQuantity) : "",
            state: isSet(object.state) ? String(object.state) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            isReduceOnly: isSet(object.isReduceOnly) ? Boolean(object.isReduceOnly) : false,
            direction: isSet(object.direction) ? String(object.direction) : "",
            isConditional: isSet(object.isConditional) ? Boolean(object.isConditional) : false,
            triggerAt: isSet(object.triggerAt) ? String(object.triggerAt) : "0",
            placedOrderHash: isSet(object.placedOrderHash) ? String(object.placedOrderHash) : "",
            margin: isSet(object.margin) ? String(object.margin) : "",
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.executionType !== undefined && (obj.executionType = message.executionType);
        message.orderType !== undefined && (obj.orderType = message.orderType);
        message.price !== undefined && (obj.price = message.price);
        message.triggerPrice !== undefined && (obj.triggerPrice = message.triggerPrice);
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.filledQuantity !== undefined && (obj.filledQuantity = message.filledQuantity);
        message.state !== undefined && (obj.state = message.state);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.isReduceOnly !== undefined && (obj.isReduceOnly = message.isReduceOnly);
        message.direction !== undefined && (obj.direction = message.direction);
        message.isConditional !== undefined && (obj.isConditional = message.isConditional);
        message.triggerAt !== undefined && (obj.triggerAt = message.triggerAt);
        message.placedOrderHash !== undefined && (obj.placedOrderHash = message.placedOrderHash);
        message.margin !== undefined && (obj.margin = message.margin);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return DerivativeOrderHistory.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeOrderHistory();
        message.orderHash = object.orderHash ?? "";
        message.marketId = object.marketId ?? "";
        message.isActive = object.isActive ?? false;
        message.subaccountId = object.subaccountId ?? "";
        message.executionType = object.executionType ?? "";
        message.orderType = object.orderType ?? "";
        message.price = object.price ?? "";
        message.triggerPrice = object.triggerPrice ?? "";
        message.quantity = object.quantity ?? "";
        message.filledQuantity = object.filledQuantity ?? "";
        message.state = object.state ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.isReduceOnly = object.isReduceOnly ?? false;
        message.direction = object.direction ?? "";
        message.isConditional = object.isConditional ?? false;
        message.triggerAt = object.triggerAt ?? "0";
        message.placedOrderHash = object.placedOrderHash ?? "";
        message.margin = object.margin ?? "";
        message.txHash = object.txHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseFundingPaymentResult() {
    return { fundingPayments: undefined, operationType: "", timestamp: "0" };
}
export const FundingPaymentResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.fundingPayments !== undefined) {
            FundingPayment.encode(message.fundingPayments, writer.uint32(10).fork()).ldelim();
        }
        if (message.operationType !== "") {
            writer.uint32(18).string(message.operationType);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingPaymentResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fundingPayments = FundingPayment.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.operationType = reader.string();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            fundingPayments: isSet(object.fundingPayments) ? FundingPayment.fromJSON(object.fundingPayments) : undefined,
            operationType: isSet(object.operationType) ? String(object.operationType) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.fundingPayments !== undefined &&
            (obj.fundingPayments = message.fundingPayments ? FundingPayment.toJSON(message.fundingPayments) : undefined);
        message.operationType !== undefined && (obj.operationType = message.operationType);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return FundingPaymentResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingPaymentResult();
        message.fundingPayments = (object.fundingPayments !== undefined && object.fundingPayments !== null)
            ? FundingPayment.fromPartial(object.fundingPayments)
            : undefined;
        message.operationType = object.operationType ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseFundingPayment() {
    return { marketId: "", subaccountId: "", amount: "", timestamp: "0" };
}
export const FundingPayment = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.amount !== "") {
            writer.uint32(26).string(message.amount);
        }
        if (message.timestamp !== "0") {
            writer.uint32(32).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFundingPayment();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.amount = reader.string();
                    break;
                case 4:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.amount !== undefined && (obj.amount = message.amount);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return FundingPayment.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFundingPayment();
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.amount = object.amount ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
export class InjectiveAccountsRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Portfolio = this.Portfolio.bind(this);
        this.OrderStates = this.OrderStates.bind(this);
        this.SubaccountsList = this.SubaccountsList.bind(this);
        this.SubaccountBalancesList = this.SubaccountBalancesList.bind(this);
        this.SubaccountBalanceEndpoint = this.SubaccountBalanceEndpoint.bind(this);
        this.StreamSubaccountBalance = this.StreamSubaccountBalance.bind(this);
        this.SubaccountHistory = this.SubaccountHistory.bind(this);
        this.SubaccountOrderSummary = this.SubaccountOrderSummary.bind(this);
        this.Rewards = this.Rewards.bind(this);
        this.StreamAccountData = this.StreamAccountData.bind(this);
    }
    Portfolio(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCPortfolioDesc, PortfolioRequest.fromPartial(request), metadata);
    }
    OrderStates(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCOrderStatesDesc, OrderStatesRequest.fromPartial(request), metadata);
    }
    SubaccountsList(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCSubaccountsListDesc, SubaccountsListRequest.fromPartial(request), metadata);
    }
    SubaccountBalancesList(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCSubaccountBalancesListDesc, SubaccountBalancesListRequest.fromPartial(request), metadata);
    }
    SubaccountBalanceEndpoint(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCSubaccountBalanceEndpointDesc, SubaccountBalanceEndpointRequest.fromPartial(request), metadata);
    }
    StreamSubaccountBalance(request, metadata) {
        return this.rpc.invoke(InjectiveAccountsRPCStreamSubaccountBalanceDesc, StreamSubaccountBalanceRequest.fromPartial(request), metadata);
    }
    SubaccountHistory(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCSubaccountHistoryDesc, SubaccountHistoryRequest.fromPartial(request), metadata);
    }
    SubaccountOrderSummary(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCSubaccountOrderSummaryDesc, SubaccountOrderSummaryRequest.fromPartial(request), metadata);
    }
    Rewards(request, metadata) {
        return this.rpc.unary(InjectiveAccountsRPCRewardsDesc, RewardsRequest.fromPartial(request), metadata);
    }
    StreamAccountData(request, metadata) {
        return this.rpc.invoke(InjectiveAccountsRPCStreamAccountDataDesc, StreamAccountDataRequest.fromPartial(request), metadata);
    }
}
export const InjectiveAccountsRPCDesc = { serviceName: "injective_accounts_rpc.InjectiveAccountsRPC" };
export const InjectiveAccountsRPCPortfolioDesc = {
    methodName: "Portfolio",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PortfolioRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PortfolioResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCOrderStatesDesc = {
    methodName: "OrderStates",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OrderStatesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OrderStatesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCSubaccountsListDesc = {
    methodName: "SubaccountsList",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountsListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountsListResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCSubaccountBalancesListDesc = {
    methodName: "SubaccountBalancesList",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountBalancesListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountBalancesListResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCSubaccountBalanceEndpointDesc = {
    methodName: "SubaccountBalanceEndpoint",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountBalanceEndpointRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountBalanceEndpointResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCStreamSubaccountBalanceDesc = {
    methodName: "StreamSubaccountBalance",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamSubaccountBalanceRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamSubaccountBalanceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCSubaccountHistoryDesc = {
    methodName: "SubaccountHistory",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountHistoryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountHistoryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCSubaccountOrderSummaryDesc = {
    methodName: "SubaccountOrderSummary",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return SubaccountOrderSummaryRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = SubaccountOrderSummaryResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCRewardsDesc = {
    methodName: "Rewards",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return RewardsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = RewardsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveAccountsRPCStreamAccountDataDesc = {
    methodName: "StreamAccountData",
    service: InjectiveAccountsRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamAccountDataRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamAccountDataResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => {
                    if (!observer.closed) {
                        return client.close();
                    }
                });
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
