/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Bid, LastAuctionResult, Params } from "./auction.js";
export const protobufPackage = "injective.auction.v1beta1";
function createBaseGenesisState() {
    return {
        params: undefined,
        auctionRound: "0",
        highestBid: undefined,
        auctionEndingTimestamp: "0",
        lastAuctionResult: undefined,
    };
}
export const GenesisState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        if (message.auctionRound !== "0") {
            writer.uint32(16).uint64(message.auctionRound);
        }
        if (message.highestBid !== undefined) {
            Bid.encode(message.highestBid, writer.uint32(26).fork()).ldelim();
        }
        if (message.auctionEndingTimestamp !== "0") {
            writer.uint32(32).int64(message.auctionEndingTimestamp);
        }
        if (message.lastAuctionResult !== undefined) {
            LastAuctionResult.encode(message.lastAuctionResult, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenesisState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.auctionRound = longToString(reader.uint64());
                    break;
                case 3:
                    message.highestBid = Bid.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.auctionEndingTimestamp = longToString(reader.int64());
                    break;
                case 5:
                    message.lastAuctionResult = LastAuctionResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
            auctionRound: isSet(object.auctionRound) ? String(object.auctionRound) : "0",
            highestBid: isSet(object.highestBid) ? Bid.fromJSON(object.highestBid) : undefined,
            auctionEndingTimestamp: isSet(object.auctionEndingTimestamp) ? String(object.auctionEndingTimestamp) : "0",
            lastAuctionResult: isSet(object.lastAuctionResult)
                ? LastAuctionResult.fromJSON(object.lastAuctionResult)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        message.auctionRound !== undefined && (obj.auctionRound = message.auctionRound);
        message.highestBid !== undefined &&
            (obj.highestBid = message.highestBid ? Bid.toJSON(message.highestBid) : undefined);
        message.auctionEndingTimestamp !== undefined && (obj.auctionEndingTimestamp = message.auctionEndingTimestamp);
        message.lastAuctionResult !== undefined && (obj.lastAuctionResult = message.lastAuctionResult
            ? LastAuctionResult.toJSON(message.lastAuctionResult)
            : undefined);
        return obj;
    },
    create(base) {
        return GenesisState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGenesisState();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        message.auctionRound = object.auctionRound ?? "0";
        message.highestBid = (object.highestBid !== undefined && object.highestBid !== null)
            ? Bid.fromPartial(object.highestBid)
            : undefined;
        message.auctionEndingTimestamp = object.auctionEndingTimestamp ?? "0";
        message.lastAuctionResult = (object.lastAuctionResult !== undefined && object.lastAuctionResult !== null)
            ? LastAuctionResult.fromPartial(object.lastAuctionResult)
            : undefined;
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
