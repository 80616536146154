/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective.permissions.v1beta1";
function createBaseParams() {
    return { wasmHookQueryMaxGas: "0" };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.wasmHookQueryMaxGas !== "0") {
            writer.uint32(8).uint64(message.wasmHookQueryMaxGas);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.wasmHookQueryMaxGas = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { wasmHookQueryMaxGas: isSet(object.wasmHookQueryMaxGas) ? String(object.wasmHookQueryMaxGas) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.wasmHookQueryMaxGas !== undefined && (obj.wasmHookQueryMaxGas = message.wasmHookQueryMaxGas);
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.wasmHookQueryMaxGas = object.wasmHookQueryMaxGas ?? "0";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
