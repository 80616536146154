/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
export const protobufPackage = "injective.peggy.v1";
function createBaseParams() {
    return {
        peggyId: "",
        contractSourceHash: "",
        bridgeEthereumAddress: "",
        bridgeChainId: "0",
        signedValsetsWindow: "0",
        signedBatchesWindow: "0",
        signedClaimsWindow: "0",
        targetBatchTimeout: "0",
        averageBlockTime: "0",
        averageEthereumBlockTime: "0",
        slashFractionValset: new Uint8Array(),
        slashFractionBatch: new Uint8Array(),
        slashFractionClaim: new Uint8Array(),
        slashFractionConflictingClaim: new Uint8Array(),
        unbondSlashingValsetsWindow: "0",
        slashFractionBadEthSignature: new Uint8Array(),
        cosmosCoinDenom: "",
        cosmosCoinErc20Contract: "",
        claimSlashingEnabled: false,
        bridgeContractStartHeight: "0",
        valsetReward: undefined,
        admins: [],
    };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.peggyId !== "") {
            writer.uint32(10).string(message.peggyId);
        }
        if (message.contractSourceHash !== "") {
            writer.uint32(18).string(message.contractSourceHash);
        }
        if (message.bridgeEthereumAddress !== "") {
            writer.uint32(26).string(message.bridgeEthereumAddress);
        }
        if (message.bridgeChainId !== "0") {
            writer.uint32(32).uint64(message.bridgeChainId);
        }
        if (message.signedValsetsWindow !== "0") {
            writer.uint32(40).uint64(message.signedValsetsWindow);
        }
        if (message.signedBatchesWindow !== "0") {
            writer.uint32(48).uint64(message.signedBatchesWindow);
        }
        if (message.signedClaimsWindow !== "0") {
            writer.uint32(56).uint64(message.signedClaimsWindow);
        }
        if (message.targetBatchTimeout !== "0") {
            writer.uint32(64).uint64(message.targetBatchTimeout);
        }
        if (message.averageBlockTime !== "0") {
            writer.uint32(72).uint64(message.averageBlockTime);
        }
        if (message.averageEthereumBlockTime !== "0") {
            writer.uint32(80).uint64(message.averageEthereumBlockTime);
        }
        if (message.slashFractionValset.length !== 0) {
            writer.uint32(90).bytes(message.slashFractionValset);
        }
        if (message.slashFractionBatch.length !== 0) {
            writer.uint32(98).bytes(message.slashFractionBatch);
        }
        if (message.slashFractionClaim.length !== 0) {
            writer.uint32(106).bytes(message.slashFractionClaim);
        }
        if (message.slashFractionConflictingClaim.length !== 0) {
            writer.uint32(114).bytes(message.slashFractionConflictingClaim);
        }
        if (message.unbondSlashingValsetsWindow !== "0") {
            writer.uint32(120).uint64(message.unbondSlashingValsetsWindow);
        }
        if (message.slashFractionBadEthSignature.length !== 0) {
            writer.uint32(130).bytes(message.slashFractionBadEthSignature);
        }
        if (message.cosmosCoinDenom !== "") {
            writer.uint32(138).string(message.cosmosCoinDenom);
        }
        if (message.cosmosCoinErc20Contract !== "") {
            writer.uint32(146).string(message.cosmosCoinErc20Contract);
        }
        if (message.claimSlashingEnabled === true) {
            writer.uint32(152).bool(message.claimSlashingEnabled);
        }
        if (message.bridgeContractStartHeight !== "0") {
            writer.uint32(160).uint64(message.bridgeContractStartHeight);
        }
        if (message.valsetReward !== undefined) {
            Coin.encode(message.valsetReward, writer.uint32(170).fork()).ldelim();
        }
        for (const v of message.admins) {
            writer.uint32(178).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.peggyId = reader.string();
                    break;
                case 2:
                    message.contractSourceHash = reader.string();
                    break;
                case 3:
                    message.bridgeEthereumAddress = reader.string();
                    break;
                case 4:
                    message.bridgeChainId = longToString(reader.uint64());
                    break;
                case 5:
                    message.signedValsetsWindow = longToString(reader.uint64());
                    break;
                case 6:
                    message.signedBatchesWindow = longToString(reader.uint64());
                    break;
                case 7:
                    message.signedClaimsWindow = longToString(reader.uint64());
                    break;
                case 8:
                    message.targetBatchTimeout = longToString(reader.uint64());
                    break;
                case 9:
                    message.averageBlockTime = longToString(reader.uint64());
                    break;
                case 10:
                    message.averageEthereumBlockTime = longToString(reader.uint64());
                    break;
                case 11:
                    message.slashFractionValset = reader.bytes();
                    break;
                case 12:
                    message.slashFractionBatch = reader.bytes();
                    break;
                case 13:
                    message.slashFractionClaim = reader.bytes();
                    break;
                case 14:
                    message.slashFractionConflictingClaim = reader.bytes();
                    break;
                case 15:
                    message.unbondSlashingValsetsWindow = longToString(reader.uint64());
                    break;
                case 16:
                    message.slashFractionBadEthSignature = reader.bytes();
                    break;
                case 17:
                    message.cosmosCoinDenom = reader.string();
                    break;
                case 18:
                    message.cosmosCoinErc20Contract = reader.string();
                    break;
                case 19:
                    message.claimSlashingEnabled = reader.bool();
                    break;
                case 20:
                    message.bridgeContractStartHeight = longToString(reader.uint64());
                    break;
                case 21:
                    message.valsetReward = Coin.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.admins.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            peggyId: isSet(object.peggyId) ? String(object.peggyId) : "",
            contractSourceHash: isSet(object.contractSourceHash) ? String(object.contractSourceHash) : "",
            bridgeEthereumAddress: isSet(object.bridgeEthereumAddress) ? String(object.bridgeEthereumAddress) : "",
            bridgeChainId: isSet(object.bridgeChainId) ? String(object.bridgeChainId) : "0",
            signedValsetsWindow: isSet(object.signedValsetsWindow) ? String(object.signedValsetsWindow) : "0",
            signedBatchesWindow: isSet(object.signedBatchesWindow) ? String(object.signedBatchesWindow) : "0",
            signedClaimsWindow: isSet(object.signedClaimsWindow) ? String(object.signedClaimsWindow) : "0",
            targetBatchTimeout: isSet(object.targetBatchTimeout) ? String(object.targetBatchTimeout) : "0",
            averageBlockTime: isSet(object.averageBlockTime) ? String(object.averageBlockTime) : "0",
            averageEthereumBlockTime: isSet(object.averageEthereumBlockTime) ? String(object.averageEthereumBlockTime) : "0",
            slashFractionValset: isSet(object.slashFractionValset)
                ? bytesFromBase64(object.slashFractionValset)
                : new Uint8Array(),
            slashFractionBatch: isSet(object.slashFractionBatch)
                ? bytesFromBase64(object.slashFractionBatch)
                : new Uint8Array(),
            slashFractionClaim: isSet(object.slashFractionClaim)
                ? bytesFromBase64(object.slashFractionClaim)
                : new Uint8Array(),
            slashFractionConflictingClaim: isSet(object.slashFractionConflictingClaim)
                ? bytesFromBase64(object.slashFractionConflictingClaim)
                : new Uint8Array(),
            unbondSlashingValsetsWindow: isSet(object.unbondSlashingValsetsWindow)
                ? String(object.unbondSlashingValsetsWindow)
                : "0",
            slashFractionBadEthSignature: isSet(object.slashFractionBadEthSignature)
                ? bytesFromBase64(object.slashFractionBadEthSignature)
                : new Uint8Array(),
            cosmosCoinDenom: isSet(object.cosmosCoinDenom) ? String(object.cosmosCoinDenom) : "",
            cosmosCoinErc20Contract: isSet(object.cosmosCoinErc20Contract) ? String(object.cosmosCoinErc20Contract) : "",
            claimSlashingEnabled: isSet(object.claimSlashingEnabled) ? Boolean(object.claimSlashingEnabled) : false,
            bridgeContractStartHeight: isSet(object.bridgeContractStartHeight)
                ? String(object.bridgeContractStartHeight)
                : "0",
            valsetReward: isSet(object.valsetReward) ? Coin.fromJSON(object.valsetReward) : undefined,
            admins: Array.isArray(object?.admins)
                ? object.admins.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.peggyId !== undefined && (obj.peggyId = message.peggyId);
        message.contractSourceHash !== undefined && (obj.contractSourceHash = message.contractSourceHash);
        message.bridgeEthereumAddress !== undefined && (obj.bridgeEthereumAddress = message.bridgeEthereumAddress);
        message.bridgeChainId !== undefined && (obj.bridgeChainId = message.bridgeChainId);
        message.signedValsetsWindow !== undefined && (obj.signedValsetsWindow = message.signedValsetsWindow);
        message.signedBatchesWindow !== undefined && (obj.signedBatchesWindow = message.signedBatchesWindow);
        message.signedClaimsWindow !== undefined && (obj.signedClaimsWindow = message.signedClaimsWindow);
        message.targetBatchTimeout !== undefined && (obj.targetBatchTimeout = message.targetBatchTimeout);
        message.averageBlockTime !== undefined && (obj.averageBlockTime = message.averageBlockTime);
        message.averageEthereumBlockTime !== undefined && (obj.averageEthereumBlockTime = message.averageEthereumBlockTime);
        message.slashFractionValset !== undefined &&
            (obj.slashFractionValset = base64FromBytes(message.slashFractionValset !== undefined ? message.slashFractionValset : new Uint8Array()));
        message.slashFractionBatch !== undefined &&
            (obj.slashFractionBatch = base64FromBytes(message.slashFractionBatch !== undefined ? message.slashFractionBatch : new Uint8Array()));
        message.slashFractionClaim !== undefined &&
            (obj.slashFractionClaim = base64FromBytes(message.slashFractionClaim !== undefined ? message.slashFractionClaim : new Uint8Array()));
        message.slashFractionConflictingClaim !== undefined &&
            (obj.slashFractionConflictingClaim = base64FromBytes(message.slashFractionConflictingClaim !== undefined ? message.slashFractionConflictingClaim : new Uint8Array()));
        message.unbondSlashingValsetsWindow !== undefined &&
            (obj.unbondSlashingValsetsWindow = message.unbondSlashingValsetsWindow);
        message.slashFractionBadEthSignature !== undefined &&
            (obj.slashFractionBadEthSignature = base64FromBytes(message.slashFractionBadEthSignature !== undefined ? message.slashFractionBadEthSignature : new Uint8Array()));
        message.cosmosCoinDenom !== undefined && (obj.cosmosCoinDenom = message.cosmosCoinDenom);
        message.cosmosCoinErc20Contract !== undefined && (obj.cosmosCoinErc20Contract = message.cosmosCoinErc20Contract);
        message.claimSlashingEnabled !== undefined && (obj.claimSlashingEnabled = message.claimSlashingEnabled);
        message.bridgeContractStartHeight !== undefined &&
            (obj.bridgeContractStartHeight = message.bridgeContractStartHeight);
        message.valsetReward !== undefined &&
            (obj.valsetReward = message.valsetReward ? Coin.toJSON(message.valsetReward) : undefined);
        if (message.admins) {
            obj.admins = message.admins.map((e) => e);
        }
        else {
            obj.admins = [];
        }
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.peggyId = object.peggyId ?? "";
        message.contractSourceHash = object.contractSourceHash ?? "";
        message.bridgeEthereumAddress = object.bridgeEthereumAddress ?? "";
        message.bridgeChainId = object.bridgeChainId ?? "0";
        message.signedValsetsWindow = object.signedValsetsWindow ?? "0";
        message.signedBatchesWindow = object.signedBatchesWindow ?? "0";
        message.signedClaimsWindow = object.signedClaimsWindow ?? "0";
        message.targetBatchTimeout = object.targetBatchTimeout ?? "0";
        message.averageBlockTime = object.averageBlockTime ?? "0";
        message.averageEthereumBlockTime = object.averageEthereumBlockTime ?? "0";
        message.slashFractionValset = object.slashFractionValset ?? new Uint8Array();
        message.slashFractionBatch = object.slashFractionBatch ?? new Uint8Array();
        message.slashFractionClaim = object.slashFractionClaim ?? new Uint8Array();
        message.slashFractionConflictingClaim = object.slashFractionConflictingClaim ?? new Uint8Array();
        message.unbondSlashingValsetsWindow = object.unbondSlashingValsetsWindow ?? "0";
        message.slashFractionBadEthSignature = object.slashFractionBadEthSignature ?? new Uint8Array();
        message.cosmosCoinDenom = object.cosmosCoinDenom ?? "";
        message.cosmosCoinErc20Contract = object.cosmosCoinErc20Contract ?? "";
        message.claimSlashingEnabled = object.claimSlashingEnabled ?? false;
        message.bridgeContractStartHeight = object.bridgeContractStartHeight ?? "0";
        message.valsetReward = (object.valsetReward !== undefined && object.valsetReward !== null)
            ? Coin.fromPartial(object.valsetReward)
            : undefined;
        message.admins = object.admins?.map((e) => e) || [];
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
