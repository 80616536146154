/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { ERC20Token } from "./attestation.js";
export const protobufPackage = "injective.peggy.v1";
function createBaseOutgoingTxBatch() {
    return { batchNonce: "0", batchTimeout: "0", transactions: [], tokenContract: "", block: "0" };
}
export const OutgoingTxBatch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.batchNonce !== "0") {
            writer.uint32(8).uint64(message.batchNonce);
        }
        if (message.batchTimeout !== "0") {
            writer.uint32(16).uint64(message.batchTimeout);
        }
        for (const v of message.transactions) {
            OutgoingTransferTx.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.tokenContract !== "") {
            writer.uint32(34).string(message.tokenContract);
        }
        if (message.block !== "0") {
            writer.uint32(40).uint64(message.block);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutgoingTxBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.batchNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.batchTimeout = longToString(reader.uint64());
                    break;
                case 3:
                    message.transactions.push(OutgoingTransferTx.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.tokenContract = reader.string();
                    break;
                case 5:
                    message.block = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            batchNonce: isSet(object.batchNonce) ? String(object.batchNonce) : "0",
            batchTimeout: isSet(object.batchTimeout) ? String(object.batchTimeout) : "0",
            transactions: Array.isArray(object?.transactions)
                ? object.transactions.map((e) => OutgoingTransferTx.fromJSON(e))
                : [],
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            block: isSet(object.block) ? String(object.block) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.batchNonce !== undefined && (obj.batchNonce = message.batchNonce);
        message.batchTimeout !== undefined && (obj.batchTimeout = message.batchTimeout);
        if (message.transactions) {
            obj.transactions = message.transactions.map((e) => e ? OutgoingTransferTx.toJSON(e) : undefined);
        }
        else {
            obj.transactions = [];
        }
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.block !== undefined && (obj.block = message.block);
        return obj;
    },
    create(base) {
        return OutgoingTxBatch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutgoingTxBatch();
        message.batchNonce = object.batchNonce ?? "0";
        message.batchTimeout = object.batchTimeout ?? "0";
        message.transactions = object.transactions?.map((e) => OutgoingTransferTx.fromPartial(e)) || [];
        message.tokenContract = object.tokenContract ?? "";
        message.block = object.block ?? "0";
        return message;
    },
};
function createBaseOutgoingTransferTx() {
    return { id: "0", sender: "", destAddress: "", erc20Token: undefined, erc20Fee: undefined };
}
export const OutgoingTransferTx = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "0") {
            writer.uint32(8).uint64(message.id);
        }
        if (message.sender !== "") {
            writer.uint32(18).string(message.sender);
        }
        if (message.destAddress !== "") {
            writer.uint32(26).string(message.destAddress);
        }
        if (message.erc20Token !== undefined) {
            ERC20Token.encode(message.erc20Token, writer.uint32(34).fork()).ldelim();
        }
        if (message.erc20Fee !== undefined) {
            ERC20Token.encode(message.erc20Fee, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutgoingTransferTx();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.uint64());
                    break;
                case 2:
                    message.sender = reader.string();
                    break;
                case 3:
                    message.destAddress = reader.string();
                    break;
                case 4:
                    message.erc20Token = ERC20Token.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.erc20Fee = ERC20Token.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "0",
            sender: isSet(object.sender) ? String(object.sender) : "",
            destAddress: isSet(object.destAddress) ? String(object.destAddress) : "",
            erc20Token: isSet(object.erc20Token) ? ERC20Token.fromJSON(object.erc20Token) : undefined,
            erc20Fee: isSet(object.erc20Fee) ? ERC20Token.fromJSON(object.erc20Fee) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.sender !== undefined && (obj.sender = message.sender);
        message.destAddress !== undefined && (obj.destAddress = message.destAddress);
        message.erc20Token !== undefined &&
            (obj.erc20Token = message.erc20Token ? ERC20Token.toJSON(message.erc20Token) : undefined);
        message.erc20Fee !== undefined &&
            (obj.erc20Fee = message.erc20Fee ? ERC20Token.toJSON(message.erc20Fee) : undefined);
        return obj;
    },
    create(base) {
        return OutgoingTransferTx.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutgoingTransferTx();
        message.id = object.id ?? "0";
        message.sender = object.sender ?? "";
        message.destAddress = object.destAddress ?? "";
        message.erc20Token = (object.erc20Token !== undefined && object.erc20Token !== null)
            ? ERC20Token.fromPartial(object.erc20Token)
            : undefined;
        message.erc20Fee = (object.erc20Fee !== undefined && object.erc20Fee !== null)
            ? ERC20Token.fromPartial(object.erc20Fee)
            : undefined;
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
