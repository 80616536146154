/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "event_provider_api";
function createBaseGetLatestHeightRequest() {
    return {};
}
export const GetLatestHeightRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetLatestHeightRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return GetLatestHeightRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseGetLatestHeightRequest();
        return message;
    },
};
function createBaseGetLatestHeightResponse() {
    return { v: "", s: "", e: "", data: undefined };
}
export const GetLatestHeightResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.v !== "") {
            writer.uint32(10).string(message.v);
        }
        if (message.s !== "") {
            writer.uint32(18).string(message.s);
        }
        if (message.e !== "") {
            writer.uint32(26).string(message.e);
        }
        if (message.data !== undefined) {
            LatestBlockHeight.encode(message.data, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetLatestHeightResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.v = reader.string();
                    break;
                case 2:
                    message.s = reader.string();
                    break;
                case 3:
                    message.e = reader.string();
                    break;
                case 4:
                    message.data = LatestBlockHeight.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            v: isSet(object.v) ? String(object.v) : "",
            s: isSet(object.s) ? String(object.s) : "",
            e: isSet(object.e) ? String(object.e) : "",
            data: isSet(object.data) ? LatestBlockHeight.fromJSON(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.v !== undefined && (obj.v = message.v);
        message.s !== undefined && (obj.s = message.s);
        message.e !== undefined && (obj.e = message.e);
        message.data !== undefined && (obj.data = message.data ? LatestBlockHeight.toJSON(message.data) : undefined);
        return obj;
    },
    create(base) {
        return GetLatestHeightResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetLatestHeightResponse();
        message.v = object.v ?? "";
        message.s = object.s ?? "";
        message.e = object.e ?? "";
        message.data = (object.data !== undefined && object.data !== null)
            ? LatestBlockHeight.fromPartial(object.data)
            : undefined;
        return message;
    },
};
function createBaseLatestBlockHeight() {
    return { height: "0" };
}
export const LatestBlockHeight = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).uint64(message.height);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLatestBlockHeight();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { height: isSet(object.height) ? String(object.height) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        return obj;
    },
    create(base) {
        return LatestBlockHeight.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLatestBlockHeight();
        message.height = object.height ?? "0";
        return message;
    },
};
function createBaseStreamBlockEventsRequest() {
    return { backend: "", height: 0 };
}
export const StreamBlockEventsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.backend !== "") {
            writer.uint32(10).string(message.backend);
        }
        if (message.height !== 0) {
            writer.uint32(16).sint32(message.height);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamBlockEventsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.backend = reader.string();
                    break;
                case 2:
                    message.height = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            backend: isSet(object.backend) ? String(object.backend) : "",
            height: isSet(object.height) ? Number(object.height) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.backend !== undefined && (obj.backend = message.backend);
        message.height !== undefined && (obj.height = Math.round(message.height));
        return obj;
    },
    create(base) {
        return StreamBlockEventsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamBlockEventsRequest();
        message.backend = object.backend ?? "";
        message.height = object.height ?? 0;
        return message;
    },
};
function createBaseStreamBlockEventsResponse() {
    return { blocks: [] };
}
export const StreamBlockEventsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.blocks) {
            Block.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamBlockEventsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.blocks.push(Block.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { blocks: Array.isArray(object?.blocks) ? object.blocks.map((e) => Block.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.blocks) {
            obj.blocks = message.blocks.map((e) => e ? Block.toJSON(e) : undefined);
        }
        else {
            obj.blocks = [];
        }
        return obj;
    },
    create(base) {
        return StreamBlockEventsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamBlockEventsResponse();
        message.blocks = object.blocks?.map((e) => Block.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBlock() {
    return { height: "0", version: "", events: [], inSync: false };
}
export const Block = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).sint64(message.height);
        }
        if (message.version !== "") {
            writer.uint32(18).string(message.version);
        }
        for (const v of message.events) {
            BlockEvent.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.inSync === true) {
            writer.uint32(32).bool(message.inSync);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlock();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.sint64());
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                case 3:
                    message.events.push(BlockEvent.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.inSync = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            version: isSet(object.version) ? String(object.version) : "",
            events: Array.isArray(object?.events) ? object.events.map((e) => BlockEvent.fromJSON(e)) : [],
            inSync: isSet(object.inSync) ? Boolean(object.inSync) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.version !== undefined && (obj.version = message.version);
        if (message.events) {
            obj.events = message.events.map((e) => e ? BlockEvent.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        message.inSync !== undefined && (obj.inSync = message.inSync);
        return obj;
    },
    create(base) {
        return Block.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlock();
        message.height = object.height ?? "0";
        message.version = object.version ?? "";
        message.events = object.events?.map((e) => BlockEvent.fromPartial(e)) || [];
        message.inSync = object.inSync ?? false;
        return message;
    },
};
function createBaseBlockEvent() {
    return { typeUrl: "", value: new Uint8Array(), txHash: new Uint8Array(), mode: "" };
}
export const BlockEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.typeUrl !== "") {
            writer.uint32(10).string(message.typeUrl);
        }
        if (message.value.length !== 0) {
            writer.uint32(18).bytes(message.value);
        }
        if (message.txHash.length !== 0) {
            writer.uint32(26).bytes(message.txHash);
        }
        if (message.mode !== "") {
            writer.uint32(34).string(message.mode);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.typeUrl = reader.string();
                    break;
                case 2:
                    message.value = reader.bytes();
                    break;
                case 3:
                    message.txHash = reader.bytes();
                    break;
                case 4:
                    message.mode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            typeUrl: isSet(object.typeUrl) ? String(object.typeUrl) : "",
            value: isSet(object.value) ? bytesFromBase64(object.value) : new Uint8Array(),
            txHash: isSet(object.txHash) ? bytesFromBase64(object.txHash) : new Uint8Array(),
            mode: isSet(object.mode) ? String(object.mode) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.typeUrl !== undefined && (obj.typeUrl = message.typeUrl);
        message.value !== undefined &&
            (obj.value = base64FromBytes(message.value !== undefined ? message.value : new Uint8Array()));
        message.txHash !== undefined &&
            (obj.txHash = base64FromBytes(message.txHash !== undefined ? message.txHash : new Uint8Array()));
        message.mode !== undefined && (obj.mode = message.mode);
        return obj;
    },
    create(base) {
        return BlockEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockEvent();
        message.typeUrl = object.typeUrl ?? "";
        message.value = object.value ?? new Uint8Array();
        message.txHash = object.txHash ?? new Uint8Array();
        message.mode = object.mode ?? "";
        return message;
    },
};
function createBaseGetBlockEventsRPCRequest() {
    return { backend: "", height: 0, humanReadable: false };
}
export const GetBlockEventsRPCRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.backend !== "") {
            writer.uint32(10).string(message.backend);
        }
        if (message.height !== 0) {
            writer.uint32(16).sint32(message.height);
        }
        if (message.humanReadable === true) {
            writer.uint32(24).bool(message.humanReadable);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBlockEventsRPCRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.backend = reader.string();
                    break;
                case 2:
                    message.height = reader.sint32();
                    break;
                case 3:
                    message.humanReadable = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            backend: isSet(object.backend) ? String(object.backend) : "",
            height: isSet(object.height) ? Number(object.height) : 0,
            humanReadable: isSet(object.humanReadable) ? Boolean(object.humanReadable) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.backend !== undefined && (obj.backend = message.backend);
        message.height !== undefined && (obj.height = Math.round(message.height));
        message.humanReadable !== undefined && (obj.humanReadable = message.humanReadable);
        return obj;
    },
    create(base) {
        return GetBlockEventsRPCRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBlockEventsRPCRequest();
        message.backend = object.backend ?? "";
        message.height = object.height ?? 0;
        message.humanReadable = object.humanReadable ?? false;
        return message;
    },
};
function createBaseGetBlockEventsRPCResponse() {
    return { v: "", s: "", e: "", data: undefined };
}
export const GetBlockEventsRPCResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.v !== "") {
            writer.uint32(10).string(message.v);
        }
        if (message.s !== "") {
            writer.uint32(18).string(message.s);
        }
        if (message.e !== "") {
            writer.uint32(26).string(message.e);
        }
        if (message.data !== undefined) {
            BlockEventsRPC.encode(message.data, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBlockEventsRPCResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.v = reader.string();
                    break;
                case 2:
                    message.s = reader.string();
                    break;
                case 3:
                    message.e = reader.string();
                    break;
                case 4:
                    message.data = BlockEventsRPC.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            v: isSet(object.v) ? String(object.v) : "",
            s: isSet(object.s) ? String(object.s) : "",
            e: isSet(object.e) ? String(object.e) : "",
            data: isSet(object.data) ? BlockEventsRPC.fromJSON(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.v !== undefined && (obj.v = message.v);
        message.s !== undefined && (obj.s = message.s);
        message.e !== undefined && (obj.e = message.e);
        message.data !== undefined && (obj.data = message.data ? BlockEventsRPC.toJSON(message.data) : undefined);
        return obj;
    },
    create(base) {
        return GetBlockEventsRPCResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetBlockEventsRPCResponse();
        message.v = object.v ?? "";
        message.s = object.s ?? "";
        message.e = object.e ?? "";
        message.data = (object.data !== undefined && object.data !== null)
            ? BlockEventsRPC.fromPartial(object.data)
            : undefined;
        return message;
    },
};
function createBaseBlockEventsRPC() {
    return { types: [], events: [], txHashes: {} };
}
export const BlockEventsRPC = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.types) {
            writer.uint32(10).string(v);
        }
        for (const v of message.events) {
            writer.uint32(18).bytes(v);
        }
        Object.entries(message.txHashes).forEach(([key, value]) => {
            BlockEventsRPC_TxHashesEntry.encode({ key: key, value }, writer.uint32(26).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockEventsRPC();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.types.push(reader.string());
                    break;
                case 2:
                    message.events.push(reader.bytes());
                    break;
                case 3:
                    const entry3 = BlockEventsRPC_TxHashesEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.txHashes[entry3.key] = entry3.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            types: Array.isArray(object?.types) ? object.types.map((e) => String(e)) : [],
            events: Array.isArray(object?.events) ? object.events.map((e) => bytesFromBase64(e)) : [],
            txHashes: isObject(object.txHashes)
                ? Object.entries(object.txHashes).reduce((acc, [key, value]) => {
                    acc[Number(key)] = bytesFromBase64(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.types) {
            obj.types = message.types.map((e) => e);
        }
        else {
            obj.types = [];
        }
        if (message.events) {
            obj.events = message.events.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.events = [];
        }
        obj.txHashes = {};
        if (message.txHashes) {
            Object.entries(message.txHashes).forEach(([k, v]) => {
                obj.txHashes[k] = base64FromBytes(v);
            });
        }
        return obj;
    },
    create(base) {
        return BlockEventsRPC.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockEventsRPC();
        message.types = object.types?.map((e) => e) || [];
        message.events = object.events?.map((e) => e) || [];
        message.txHashes = Object.entries(object.txHashes ?? {}).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[Number(key)] = value;
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseBlockEventsRPC_TxHashesEntry() {
    return { key: 0, value: new Uint8Array() };
}
export const BlockEventsRPC_TxHashesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== 0) {
            writer.uint32(8).sint32(message.key);
        }
        if (message.value.length !== 0) {
            writer.uint32(18).bytes(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockEventsRPC_TxHashesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.sint32();
                    break;
                case 2:
                    message.value = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? Number(object.key) : 0,
            value: isSet(object.value) ? bytesFromBase64(object.value) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = Math.round(message.key));
        message.value !== undefined &&
            (obj.value = base64FromBytes(message.value !== undefined ? message.value : new Uint8Array()));
        return obj;
    },
    create(base) {
        return BlockEventsRPC_TxHashesEntry.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockEventsRPC_TxHashesEntry();
        message.key = object.key ?? 0;
        message.value = object.value ?? new Uint8Array();
        return message;
    },
};
function createBaseGetCustomEventsRPCRequest() {
    return { backend: "", height: 0, events: "" };
}
export const GetCustomEventsRPCRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.backend !== "") {
            writer.uint32(10).string(message.backend);
        }
        if (message.height !== 0) {
            writer.uint32(16).sint32(message.height);
        }
        if (message.events !== "") {
            writer.uint32(26).string(message.events);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCustomEventsRPCRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.backend = reader.string();
                    break;
                case 2:
                    message.height = reader.sint32();
                    break;
                case 3:
                    message.events = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            backend: isSet(object.backend) ? String(object.backend) : "",
            height: isSet(object.height) ? Number(object.height) : 0,
            events: isSet(object.events) ? String(object.events) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.backend !== undefined && (obj.backend = message.backend);
        message.height !== undefined && (obj.height = Math.round(message.height));
        message.events !== undefined && (obj.events = message.events);
        return obj;
    },
    create(base) {
        return GetCustomEventsRPCRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetCustomEventsRPCRequest();
        message.backend = object.backend ?? "";
        message.height = object.height ?? 0;
        message.events = object.events ?? "";
        return message;
    },
};
function createBaseGetCustomEventsRPCResponse() {
    return { v: "", s: "", e: "", data: undefined };
}
export const GetCustomEventsRPCResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.v !== "") {
            writer.uint32(10).string(message.v);
        }
        if (message.s !== "") {
            writer.uint32(18).string(message.s);
        }
        if (message.e !== "") {
            writer.uint32(26).string(message.e);
        }
        if (message.data !== undefined) {
            BlockEventsRPC.encode(message.data, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCustomEventsRPCResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.v = reader.string();
                    break;
                case 2:
                    message.s = reader.string();
                    break;
                case 3:
                    message.e = reader.string();
                    break;
                case 4:
                    message.data = BlockEventsRPC.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            v: isSet(object.v) ? String(object.v) : "",
            s: isSet(object.s) ? String(object.s) : "",
            e: isSet(object.e) ? String(object.e) : "",
            data: isSet(object.data) ? BlockEventsRPC.fromJSON(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.v !== undefined && (obj.v = message.v);
        message.s !== undefined && (obj.s = message.s);
        message.e !== undefined && (obj.e = message.e);
        message.data !== undefined && (obj.data = message.data ? BlockEventsRPC.toJSON(message.data) : undefined);
        return obj;
    },
    create(base) {
        return GetCustomEventsRPCResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetCustomEventsRPCResponse();
        message.v = object.v ?? "";
        message.s = object.s ?? "";
        message.e = object.e ?? "";
        message.data = (object.data !== undefined && object.data !== null)
            ? BlockEventsRPC.fromPartial(object.data)
            : undefined;
        return message;
    },
};
function createBaseGetABCIBlockEventsRequest() {
    return { height: 0, eventTypes: [] };
}
export const GetABCIBlockEventsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== 0) {
            writer.uint32(8).sint32(message.height);
        }
        for (const v of message.eventTypes) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetABCIBlockEventsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = reader.sint32();
                    break;
                case 2:
                    message.eventTypes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? Number(object.height) : 0,
            eventTypes: Array.isArray(object?.eventTypes) ? object.eventTypes.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = Math.round(message.height));
        if (message.eventTypes) {
            obj.eventTypes = message.eventTypes.map((e) => e);
        }
        else {
            obj.eventTypes = [];
        }
        return obj;
    },
    create(base) {
        return GetABCIBlockEventsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetABCIBlockEventsRequest();
        message.height = object.height ?? 0;
        message.eventTypes = object.eventTypes?.map((e) => e) || [];
        return message;
    },
};
function createBaseGetABCIBlockEventsResponse() {
    return { v: "", s: "", e: "", rawBlock: undefined };
}
export const GetABCIBlockEventsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.v !== "") {
            writer.uint32(10).string(message.v);
        }
        if (message.s !== "") {
            writer.uint32(18).string(message.s);
        }
        if (message.e !== "") {
            writer.uint32(26).string(message.e);
        }
        if (message.rawBlock !== undefined) {
            RawBlock.encode(message.rawBlock, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetABCIBlockEventsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.v = reader.string();
                    break;
                case 2:
                    message.s = reader.string();
                    break;
                case 3:
                    message.e = reader.string();
                    break;
                case 4:
                    message.rawBlock = RawBlock.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            v: isSet(object.v) ? String(object.v) : "",
            s: isSet(object.s) ? String(object.s) : "",
            e: isSet(object.e) ? String(object.e) : "",
            rawBlock: isSet(object.rawBlock) ? RawBlock.fromJSON(object.rawBlock) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.v !== undefined && (obj.v = message.v);
        message.s !== undefined && (obj.s = message.s);
        message.e !== undefined && (obj.e = message.e);
        message.rawBlock !== undefined && (obj.rawBlock = message.rawBlock ? RawBlock.toJSON(message.rawBlock) : undefined);
        return obj;
    },
    create(base) {
        return GetABCIBlockEventsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetABCIBlockEventsResponse();
        message.v = object.v ?? "";
        message.s = object.s ?? "";
        message.e = object.e ?? "";
        message.rawBlock = (object.rawBlock !== undefined && object.rawBlock !== null)
            ? RawBlock.fromPartial(object.rawBlock)
            : undefined;
        return message;
    },
};
function createBaseRawBlock() {
    return { height: "0", blockTime: "", blockTimestamp: "0", txsResults: [], beginBlockEvents: [], endBlockEvents: [] };
}
export const RawBlock = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).sint64(message.height);
        }
        if (message.blockTime !== "") {
            writer.uint32(42).string(message.blockTime);
        }
        if (message.blockTimestamp !== "0") {
            writer.uint32(48).sint64(message.blockTimestamp);
        }
        for (const v of message.txsResults) {
            ABCIResponseDeliverTx.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.beginBlockEvents) {
            ABCIEvent.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.endBlockEvents) {
            ABCIEvent.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRawBlock();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.sint64());
                    break;
                case 5:
                    message.blockTime = reader.string();
                    break;
                case 6:
                    message.blockTimestamp = longToString(reader.sint64());
                    break;
                case 2:
                    message.txsResults.push(ABCIResponseDeliverTx.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.beginBlockEvents.push(ABCIEvent.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endBlockEvents.push(ABCIEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            blockTime: isSet(object.blockTime) ? String(object.blockTime) : "",
            blockTimestamp: isSet(object.blockTimestamp) ? String(object.blockTimestamp) : "0",
            txsResults: Array.isArray(object?.txsResults)
                ? object.txsResults.map((e) => ABCIResponseDeliverTx.fromJSON(e))
                : [],
            beginBlockEvents: Array.isArray(object?.beginBlockEvents)
                ? object.beginBlockEvents.map((e) => ABCIEvent.fromJSON(e))
                : [],
            endBlockEvents: Array.isArray(object?.endBlockEvents)
                ? object.endBlockEvents.map((e) => ABCIEvent.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.blockTime !== undefined && (obj.blockTime = message.blockTime);
        message.blockTimestamp !== undefined && (obj.blockTimestamp = message.blockTimestamp);
        if (message.txsResults) {
            obj.txsResults = message.txsResults.map((e) => e ? ABCIResponseDeliverTx.toJSON(e) : undefined);
        }
        else {
            obj.txsResults = [];
        }
        if (message.beginBlockEvents) {
            obj.beginBlockEvents = message.beginBlockEvents.map((e) => e ? ABCIEvent.toJSON(e) : undefined);
        }
        else {
            obj.beginBlockEvents = [];
        }
        if (message.endBlockEvents) {
            obj.endBlockEvents = message.endBlockEvents.map((e) => e ? ABCIEvent.toJSON(e) : undefined);
        }
        else {
            obj.endBlockEvents = [];
        }
        return obj;
    },
    create(base) {
        return RawBlock.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRawBlock();
        message.height = object.height ?? "0";
        message.blockTime = object.blockTime ?? "";
        message.blockTimestamp = object.blockTimestamp ?? "0";
        message.txsResults = object.txsResults?.map((e) => ABCIResponseDeliverTx.fromPartial(e)) || [];
        message.beginBlockEvents = object.beginBlockEvents?.map((e) => ABCIEvent.fromPartial(e)) || [];
        message.endBlockEvents = object.endBlockEvents?.map((e) => ABCIEvent.fromPartial(e)) || [];
        return message;
    },
};
function createBaseABCIResponseDeliverTx() {
    return {
        code: 0,
        log: "",
        info: "",
        gasWanted: "0",
        gasUsed: "0",
        events: [],
        codespace: "",
        txHash: new Uint8Array(),
    };
}
export const ABCIResponseDeliverTx = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).sint32(message.code);
        }
        if (message.log !== "") {
            writer.uint32(18).string(message.log);
        }
        if (message.info !== "") {
            writer.uint32(26).string(message.info);
        }
        if (message.gasWanted !== "0") {
            writer.uint32(32).sint64(message.gasWanted);
        }
        if (message.gasUsed !== "0") {
            writer.uint32(40).sint64(message.gasUsed);
        }
        for (const v of message.events) {
            ABCIEvent.encode(v, writer.uint32(50).fork()).ldelim();
        }
        if (message.codespace !== "") {
            writer.uint32(58).string(message.codespace);
        }
        if (message.txHash.length !== 0) {
            writer.uint32(66).bytes(message.txHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseABCIResponseDeliverTx();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.sint32();
                    break;
                case 2:
                    message.log = reader.string();
                    break;
                case 3:
                    message.info = reader.string();
                    break;
                case 4:
                    message.gasWanted = longToString(reader.sint64());
                    break;
                case 5:
                    message.gasUsed = longToString(reader.sint64());
                    break;
                case 6:
                    message.events.push(ABCIEvent.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.codespace = reader.string();
                    break;
                case 8:
                    message.txHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? Number(object.code) : 0,
            log: isSet(object.log) ? String(object.log) : "",
            info: isSet(object.info) ? String(object.info) : "",
            gasWanted: isSet(object.gasWanted) ? String(object.gasWanted) : "0",
            gasUsed: isSet(object.gasUsed) ? String(object.gasUsed) : "0",
            events: Array.isArray(object?.events) ? object.events.map((e) => ABCIEvent.fromJSON(e)) : [],
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            txHash: isSet(object.txHash) ? bytesFromBase64(object.txHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.log !== undefined && (obj.log = message.log);
        message.info !== undefined && (obj.info = message.info);
        message.gasWanted !== undefined && (obj.gasWanted = message.gasWanted);
        message.gasUsed !== undefined && (obj.gasUsed = message.gasUsed);
        if (message.events) {
            obj.events = message.events.map((e) => e ? ABCIEvent.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.txHash !== undefined &&
            (obj.txHash = base64FromBytes(message.txHash !== undefined ? message.txHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return ABCIResponseDeliverTx.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseABCIResponseDeliverTx();
        message.code = object.code ?? 0;
        message.log = object.log ?? "";
        message.info = object.info ?? "";
        message.gasWanted = object.gasWanted ?? "0";
        message.gasUsed = object.gasUsed ?? "0";
        message.events = object.events?.map((e) => ABCIEvent.fromPartial(e)) || [];
        message.codespace = object.codespace ?? "";
        message.txHash = object.txHash ?? new Uint8Array();
        return message;
    },
};
function createBaseABCIEvent() {
    return { type: "", attributes: [] };
}
export const ABCIEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        for (const v of message.attributes) {
            ABCIAttribute.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseABCIEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.attributes.push(ABCIAttribute.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
            attributes: Array.isArray(object?.attributes) ? object.attributes.map((e) => ABCIAttribute.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        if (message.attributes) {
            obj.attributes = message.attributes.map((e) => e ? ABCIAttribute.toJSON(e) : undefined);
        }
        else {
            obj.attributes = [];
        }
        return obj;
    },
    create(base) {
        return ABCIEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseABCIEvent();
        message.type = object.type ?? "";
        message.attributes = object.attributes?.map((e) => ABCIAttribute.fromPartial(e)) || [];
        return message;
    },
};
function createBaseABCIAttribute() {
    return { key: "", value: "" };
}
export const ABCIAttribute = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseABCIAttribute();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    create(base) {
        return ABCIAttribute.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseABCIAttribute();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseGetABCIBlockEventsAtHeightRequest() {
    return { height: 0 };
}
export const GetABCIBlockEventsAtHeightRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== 0) {
            writer.uint32(8).sint32(message.height);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetABCIBlockEventsAtHeightRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { height: isSet(object.height) ? Number(object.height) : 0 };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = Math.round(message.height));
        return obj;
    },
    create(base) {
        return GetABCIBlockEventsAtHeightRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetABCIBlockEventsAtHeightRequest();
        message.height = object.height ?? 0;
        return message;
    },
};
function createBaseGetABCIBlockEventsAtHeightResponse() {
    return { v: "", s: "", e: "", rawBlock: undefined };
}
export const GetABCIBlockEventsAtHeightResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.v !== "") {
            writer.uint32(10).string(message.v);
        }
        if (message.s !== "") {
            writer.uint32(18).string(message.s);
        }
        if (message.e !== "") {
            writer.uint32(26).string(message.e);
        }
        if (message.rawBlock !== undefined) {
            RawBlock.encode(message.rawBlock, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetABCIBlockEventsAtHeightResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.v = reader.string();
                    break;
                case 2:
                    message.s = reader.string();
                    break;
                case 3:
                    message.e = reader.string();
                    break;
                case 4:
                    message.rawBlock = RawBlock.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            v: isSet(object.v) ? String(object.v) : "",
            s: isSet(object.s) ? String(object.s) : "",
            e: isSet(object.e) ? String(object.e) : "",
            rawBlock: isSet(object.rawBlock) ? RawBlock.fromJSON(object.rawBlock) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.v !== undefined && (obj.v = message.v);
        message.s !== undefined && (obj.s = message.s);
        message.e !== undefined && (obj.e = message.e);
        message.rawBlock !== undefined && (obj.rawBlock = message.rawBlock ? RawBlock.toJSON(message.rawBlock) : undefined);
        return obj;
    },
    create(base) {
        return GetABCIBlockEventsAtHeightResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetABCIBlockEventsAtHeightResponse();
        message.v = object.v ?? "";
        message.s = object.s ?? "";
        message.e = object.e ?? "";
        message.rawBlock = (object.rawBlock !== undefined && object.rawBlock !== null)
            ? RawBlock.fromPartial(object.rawBlock)
            : undefined;
        return message;
    },
};
export class EventProviderAPIClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.GetLatestHeight = this.GetLatestHeight.bind(this);
        this.StreamBlockEvents = this.StreamBlockEvents.bind(this);
        this.GetBlockEventsRPC = this.GetBlockEventsRPC.bind(this);
        this.GetCustomEventsRPC = this.GetCustomEventsRPC.bind(this);
        this.GetABCIBlockEvents = this.GetABCIBlockEvents.bind(this);
        this.GetABCIBlockEventsAtHeight = this.GetABCIBlockEventsAtHeight.bind(this);
    }
    GetLatestHeight(request, metadata) {
        return this.rpc.unary(EventProviderAPIGetLatestHeightDesc, GetLatestHeightRequest.fromPartial(request), metadata);
    }
    StreamBlockEvents(request, metadata) {
        return this.rpc.invoke(EventProviderAPIStreamBlockEventsDesc, StreamBlockEventsRequest.fromPartial(request), metadata);
    }
    GetBlockEventsRPC(request, metadata) {
        return this.rpc.unary(EventProviderAPIGetBlockEventsRPCDesc, GetBlockEventsRPCRequest.fromPartial(request), metadata);
    }
    GetCustomEventsRPC(request, metadata) {
        return this.rpc.unary(EventProviderAPIGetCustomEventsRPCDesc, GetCustomEventsRPCRequest.fromPartial(request), metadata);
    }
    GetABCIBlockEvents(request, metadata) {
        return this.rpc.unary(EventProviderAPIGetABCIBlockEventsDesc, GetABCIBlockEventsRequest.fromPartial(request), metadata);
    }
    GetABCIBlockEventsAtHeight(request, metadata) {
        return this.rpc.unary(EventProviderAPIGetABCIBlockEventsAtHeightDesc, GetABCIBlockEventsAtHeightRequest.fromPartial(request), metadata);
    }
}
export const EventProviderAPIDesc = { serviceName: "event_provider_api.EventProviderAPI" };
export const EventProviderAPIGetLatestHeightDesc = {
    methodName: "GetLatestHeight",
    service: EventProviderAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetLatestHeightRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetLatestHeightResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const EventProviderAPIStreamBlockEventsDesc = {
    methodName: "StreamBlockEvents",
    service: EventProviderAPIDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamBlockEventsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamBlockEventsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const EventProviderAPIGetBlockEventsRPCDesc = {
    methodName: "GetBlockEventsRPC",
    service: EventProviderAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetBlockEventsRPCRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetBlockEventsRPCResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const EventProviderAPIGetCustomEventsRPCDesc = {
    methodName: "GetCustomEventsRPC",
    service: EventProviderAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetCustomEventsRPCRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetCustomEventsRPCResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const EventProviderAPIGetABCIBlockEventsDesc = {
    methodName: "GetABCIBlockEvents",
    service: EventProviderAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetABCIBlockEventsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetABCIBlockEventsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const EventProviderAPIGetABCIBlockEventsAtHeightDesc = {
    methodName: "GetABCIBlockEventsAtHeight",
    service: EventProviderAPIDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetABCIBlockEventsAtHeightRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetABCIBlockEventsAtHeightResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => {
                    if (!observer.closed) {
                        return client.close();
                    }
                });
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
