/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { GenesisState } from "./genesis.js";
import { InsuranceFund, Params } from "./insurance.js";
export const protobufPackage = "injective.insurance.v1beta1";
function createBaseQueryInsuranceParamsRequest() {
    return {};
}
export const QueryInsuranceParamsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryInsuranceParamsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryInsuranceParamsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryInsuranceParamsRequest();
        return message;
    },
};
function createBaseQueryInsuranceParamsResponse() {
    return { params: undefined };
}
export const QueryInsuranceParamsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryInsuranceParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { params: isSet(object.params) ? Params.fromJSON(object.params) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return QueryInsuranceParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryInsuranceParamsResponse();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseQueryInsuranceFundRequest() {
    return { marketId: "" };
}
export const QueryInsuranceFundRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryInsuranceFundRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketId: isSet(object.marketId) ? String(object.marketId) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return QueryInsuranceFundRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryInsuranceFundRequest();
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseQueryInsuranceFundResponse() {
    return { fund: undefined };
}
export const QueryInsuranceFundResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.fund !== undefined) {
            InsuranceFund.encode(message.fund, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryInsuranceFundResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fund = InsuranceFund.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { fund: isSet(object.fund) ? InsuranceFund.fromJSON(object.fund) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.fund !== undefined && (obj.fund = message.fund ? InsuranceFund.toJSON(message.fund) : undefined);
        return obj;
    },
    create(base) {
        return QueryInsuranceFundResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryInsuranceFundResponse();
        message.fund = (object.fund !== undefined && object.fund !== null)
            ? InsuranceFund.fromPartial(object.fund)
            : undefined;
        return message;
    },
};
function createBaseQueryInsuranceFundsRequest() {
    return {};
}
export const QueryInsuranceFundsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryInsuranceFundsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryInsuranceFundsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryInsuranceFundsRequest();
        return message;
    },
};
function createBaseQueryInsuranceFundsResponse() {
    return { funds: [] };
}
export const QueryInsuranceFundsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.funds) {
            InsuranceFund.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryInsuranceFundsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.funds.push(InsuranceFund.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { funds: Array.isArray(object?.funds) ? object.funds.map((e) => InsuranceFund.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.funds) {
            obj.funds = message.funds.map((e) => e ? InsuranceFund.toJSON(e) : undefined);
        }
        else {
            obj.funds = [];
        }
        return obj;
    },
    create(base) {
        return QueryInsuranceFundsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryInsuranceFundsResponse();
        message.funds = object.funds?.map((e) => InsuranceFund.fromPartial(e)) || [];
        return message;
    },
};
function createBaseQueryEstimatedRedemptionsRequest() {
    return { marketId: "", address: "" };
}
export const QueryEstimatedRedemptionsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryEstimatedRedemptionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            address: isSet(object.address) ? String(object.address) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return QueryEstimatedRedemptionsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryEstimatedRedemptionsRequest();
        message.marketId = object.marketId ?? "";
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseQueryEstimatedRedemptionsResponse() {
    return { amount: [] };
}
export const QueryEstimatedRedemptionsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.amount) {
            Coin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryEstimatedRedemptionsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.amount.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { amount: Array.isArray(object?.amount) ? object.amount.map((e) => Coin.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.amount) {
            obj.amount = message.amount.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.amount = [];
        }
        return obj;
    },
    create(base) {
        return QueryEstimatedRedemptionsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryEstimatedRedemptionsResponse();
        message.amount = object.amount?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseQueryPendingRedemptionsRequest() {
    return { marketId: "", address: "" };
}
export const QueryPendingRedemptionsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryPendingRedemptionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            address: isSet(object.address) ? String(object.address) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return QueryPendingRedemptionsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryPendingRedemptionsRequest();
        message.marketId = object.marketId ?? "";
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseQueryPendingRedemptionsResponse() {
    return { amount: [] };
}
export const QueryPendingRedemptionsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.amount) {
            Coin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryPendingRedemptionsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.amount.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { amount: Array.isArray(object?.amount) ? object.amount.map((e) => Coin.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.amount) {
            obj.amount = message.amount.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.amount = [];
        }
        return obj;
    },
    create(base) {
        return QueryPendingRedemptionsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryPendingRedemptionsResponse();
        message.amount = object.amount?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseQueryModuleStateRequest() {
    return {};
}
export const QueryModuleStateRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryModuleStateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryModuleStateRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryModuleStateRequest();
        return message;
    },
};
function createBaseQueryModuleStateResponse() {
    return { state: undefined };
}
export const QueryModuleStateResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== undefined) {
            GenesisState.encode(message.state, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryModuleStateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = GenesisState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { state: isSet(object.state) ? GenesisState.fromJSON(object.state) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.state !== undefined && (obj.state = message.state ? GenesisState.toJSON(message.state) : undefined);
        return obj;
    },
    create(base) {
        return QueryModuleStateResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryModuleStateResponse();
        message.state = (object.state !== undefined && object.state !== null)
            ? GenesisState.fromPartial(object.state)
            : undefined;
        return message;
    },
};
export class QueryClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.InsuranceParams = this.InsuranceParams.bind(this);
        this.InsuranceFund = this.InsuranceFund.bind(this);
        this.InsuranceFunds = this.InsuranceFunds.bind(this);
        this.EstimatedRedemptions = this.EstimatedRedemptions.bind(this);
        this.PendingRedemptions = this.PendingRedemptions.bind(this);
        this.InsuranceModuleState = this.InsuranceModuleState.bind(this);
    }
    InsuranceParams(request, metadata) {
        return this.rpc.unary(QueryInsuranceParamsDesc, QueryInsuranceParamsRequest.fromPartial(request), metadata);
    }
    InsuranceFund(request, metadata) {
        return this.rpc.unary(QueryInsuranceFundDesc, QueryInsuranceFundRequest.fromPartial(request), metadata);
    }
    InsuranceFunds(request, metadata) {
        return this.rpc.unary(QueryInsuranceFundsDesc, QueryInsuranceFundsRequest.fromPartial(request), metadata);
    }
    EstimatedRedemptions(request, metadata) {
        return this.rpc.unary(QueryEstimatedRedemptionsDesc, QueryEstimatedRedemptionsRequest.fromPartial(request), metadata);
    }
    PendingRedemptions(request, metadata) {
        return this.rpc.unary(QueryPendingRedemptionsDesc, QueryPendingRedemptionsRequest.fromPartial(request), metadata);
    }
    InsuranceModuleState(request, metadata) {
        return this.rpc.unary(QueryInsuranceModuleStateDesc, QueryModuleStateRequest.fromPartial(request), metadata);
    }
}
export const QueryDesc = { serviceName: "injective.insurance.v1beta1.Query" };
export const QueryInsuranceParamsDesc = {
    methodName: "InsuranceParams",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryInsuranceParamsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryInsuranceParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryInsuranceFundDesc = {
    methodName: "InsuranceFund",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryInsuranceFundRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryInsuranceFundResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryInsuranceFundsDesc = {
    methodName: "InsuranceFunds",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryInsuranceFundsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryInsuranceFundsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryEstimatedRedemptionsDesc = {
    methodName: "EstimatedRedemptions",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryEstimatedRedemptionsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryEstimatedRedemptionsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryPendingRedemptionsDesc = {
    methodName: "PendingRedemptions",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryPendingRedemptionsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryPendingRedemptionsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryInsuranceModuleStateDesc = {
    methodName: "InsuranceModuleState",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryModuleStateRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryModuleStateResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
