/* eslint-disable */
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
export const protobufPackage = "injective.permissions.v1beta1";
/** each Action enum value should be a power of two */
export var Action;
(function (Action) {
    Action[Action["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    Action[Action["MINT"] = 1] = "MINT";
    Action[Action["RECEIVE"] = 2] = "RECEIVE";
    Action[Action["BURN"] = 4] = "BURN";
    Action[Action["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Action || (Action = {}));
export function actionFromJSON(object) {
    switch (object) {
        case 0:
        case "UNSPECIFIED":
            return Action.UNSPECIFIED;
        case 1:
        case "MINT":
            return Action.MINT;
        case 2:
        case "RECEIVE":
            return Action.RECEIVE;
        case 4:
        case "BURN":
            return Action.BURN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Action.UNRECOGNIZED;
    }
}
export function actionToJSON(object) {
    switch (object) {
        case Action.UNSPECIFIED:
            return "UNSPECIFIED";
        case Action.MINT:
            return "MINT";
        case Action.RECEIVE:
            return "RECEIVE";
        case Action.BURN:
            return "BURN";
        case Action.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseNamespace() {
    return {
        denom: "",
        wasmHook: "",
        mintsPaused: false,
        sendsPaused: false,
        burnsPaused: false,
        rolePermissions: [],
        addressRoles: [],
    };
}
export const Namespace = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.wasmHook !== "") {
            writer.uint32(18).string(message.wasmHook);
        }
        if (message.mintsPaused === true) {
            writer.uint32(24).bool(message.mintsPaused);
        }
        if (message.sendsPaused === true) {
            writer.uint32(32).bool(message.sendsPaused);
        }
        if (message.burnsPaused === true) {
            writer.uint32(40).bool(message.burnsPaused);
        }
        for (const v of message.rolePermissions) {
            Role.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.addressRoles) {
            AddressRoles.encode(v, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNamespace();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.wasmHook = reader.string();
                    break;
                case 3:
                    message.mintsPaused = reader.bool();
                    break;
                case 4:
                    message.sendsPaused = reader.bool();
                    break;
                case 5:
                    message.burnsPaused = reader.bool();
                    break;
                case 6:
                    message.rolePermissions.push(Role.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.addressRoles.push(AddressRoles.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            wasmHook: isSet(object.wasmHook) ? String(object.wasmHook) : "",
            mintsPaused: isSet(object.mintsPaused) ? Boolean(object.mintsPaused) : false,
            sendsPaused: isSet(object.sendsPaused) ? Boolean(object.sendsPaused) : false,
            burnsPaused: isSet(object.burnsPaused) ? Boolean(object.burnsPaused) : false,
            rolePermissions: Array.isArray(object?.rolePermissions)
                ? object.rolePermissions.map((e) => Role.fromJSON(e))
                : [],
            addressRoles: Array.isArray(object?.addressRoles)
                ? object.addressRoles.map((e) => AddressRoles.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.wasmHook !== undefined && (obj.wasmHook = message.wasmHook);
        message.mintsPaused !== undefined && (obj.mintsPaused = message.mintsPaused);
        message.sendsPaused !== undefined && (obj.sendsPaused = message.sendsPaused);
        message.burnsPaused !== undefined && (obj.burnsPaused = message.burnsPaused);
        if (message.rolePermissions) {
            obj.rolePermissions = message.rolePermissions.map((e) => e ? Role.toJSON(e) : undefined);
        }
        else {
            obj.rolePermissions = [];
        }
        if (message.addressRoles) {
            obj.addressRoles = message.addressRoles.map((e) => e ? AddressRoles.toJSON(e) : undefined);
        }
        else {
            obj.addressRoles = [];
        }
        return obj;
    },
    create(base) {
        return Namespace.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseNamespace();
        message.denom = object.denom ?? "";
        message.wasmHook = object.wasmHook ?? "";
        message.mintsPaused = object.mintsPaused ?? false;
        message.sendsPaused = object.sendsPaused ?? false;
        message.burnsPaused = object.burnsPaused ?? false;
        message.rolePermissions = object.rolePermissions?.map((e) => Role.fromPartial(e)) || [];
        message.addressRoles = object.addressRoles?.map((e) => AddressRoles.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAddressRoles() {
    return { address: "", roles: [] };
}
export const AddressRoles = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        for (const v of message.roles) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddressRoles();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.roles.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            roles: Array.isArray(object?.roles) ? object.roles.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        if (message.roles) {
            obj.roles = message.roles.map((e) => e);
        }
        else {
            obj.roles = [];
        }
        return obj;
    },
    create(base) {
        return AddressRoles.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAddressRoles();
        message.address = object.address ?? "";
        message.roles = object.roles?.map((e) => e) || [];
        return message;
    },
};
function createBaseRole() {
    return { role: "", permissions: 0 };
}
export const Role = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.role !== "") {
            writer.uint32(10).string(message.role);
        }
        if (message.permissions !== 0) {
            writer.uint32(16).uint32(message.permissions);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRole();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.role = reader.string();
                    break;
                case 2:
                    message.permissions = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            role: isSet(object.role) ? String(object.role) : "",
            permissions: isSet(object.permissions) ? Number(object.permissions) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.role !== undefined && (obj.role = message.role);
        message.permissions !== undefined && (obj.permissions = Math.round(message.permissions));
        return obj;
    },
    create(base) {
        return Role.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRole();
        message.role = object.role ?? "";
        message.permissions = object.permissions ?? 0;
        return message;
    },
};
function createBaseRoleIDs() {
    return { roleIds: [] };
}
export const RoleIDs = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.roleIds) {
            writer.uint32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRoleIDs();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.roleIds.push(reader.uint32());
                        }
                    }
                    else {
                        message.roleIds.push(reader.uint32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { roleIds: Array.isArray(object?.roleIds) ? object.roleIds.map((e) => Number(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.roleIds) {
            obj.roleIds = message.roleIds.map((e) => Math.round(e));
        }
        else {
            obj.roleIds = [];
        }
        return obj;
    },
    create(base) {
        return RoleIDs.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRoleIDs();
        message.roleIds = object.roleIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseVoucher() {
    return { coins: [] };
}
export const Voucher = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.coins) {
            Coin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVoucher();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.coins.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { coins: Array.isArray(object?.coins) ? object.coins.map((e) => Coin.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.coins) {
            obj.coins = message.coins.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.coins = [];
        }
        return obj;
    },
    create(base) {
        return Voucher.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVoucher();
        message.coins = object.coins?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAddressVoucher() {
    return { address: "", voucher: undefined };
}
export const AddressVoucher = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.voucher !== undefined) {
            Voucher.encode(message.voucher, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddressVoucher();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.voucher = Voucher.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? String(object.address) : "",
            voucher: isSet(object.voucher) ? Voucher.fromJSON(object.voucher) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.voucher !== undefined && (obj.voucher = message.voucher ? Voucher.toJSON(message.voucher) : undefined);
        return obj;
    },
    create(base) {
        return AddressVoucher.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAddressVoucher();
        message.address = object.address ?? "";
        message.voucher = (object.voucher !== undefined && object.voucher !== null)
            ? Voucher.fromPartial(object.voucher)
            : undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
