/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { claimTypeFromJSON, claimTypeToJSON } from "./attestation.js";
import { BridgeValidator } from "./types.js";
export const protobufPackage = "injective.peggy.v1";
function createBaseEventAttestationObserved() {
    return { attestationType: 0, bridgeContract: "", bridgeChainId: "0", attestationId: new Uint8Array(), nonce: "0" };
}
export const EventAttestationObserved = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.attestationType !== 0) {
            writer.uint32(8).int32(message.attestationType);
        }
        if (message.bridgeContract !== "") {
            writer.uint32(18).string(message.bridgeContract);
        }
        if (message.bridgeChainId !== "0") {
            writer.uint32(24).uint64(message.bridgeChainId);
        }
        if (message.attestationId.length !== 0) {
            writer.uint32(34).bytes(message.attestationId);
        }
        if (message.nonce !== "0") {
            writer.uint32(40).uint64(message.nonce);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAttestationObserved();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.attestationType = reader.int32();
                    break;
                case 2:
                    message.bridgeContract = reader.string();
                    break;
                case 3:
                    message.bridgeChainId = longToString(reader.uint64());
                    break;
                case 4:
                    message.attestationId = reader.bytes();
                    break;
                case 5:
                    message.nonce = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            attestationType: isSet(object.attestationType) ? claimTypeFromJSON(object.attestationType) : 0,
            bridgeContract: isSet(object.bridgeContract) ? String(object.bridgeContract) : "",
            bridgeChainId: isSet(object.bridgeChainId) ? String(object.bridgeChainId) : "0",
            attestationId: isSet(object.attestationId) ? bytesFromBase64(object.attestationId) : new Uint8Array(),
            nonce: isSet(object.nonce) ? String(object.nonce) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.attestationType !== undefined && (obj.attestationType = claimTypeToJSON(message.attestationType));
        message.bridgeContract !== undefined && (obj.bridgeContract = message.bridgeContract);
        message.bridgeChainId !== undefined && (obj.bridgeChainId = message.bridgeChainId);
        message.attestationId !== undefined &&
            (obj.attestationId = base64FromBytes(message.attestationId !== undefined ? message.attestationId : new Uint8Array()));
        message.nonce !== undefined && (obj.nonce = message.nonce);
        return obj;
    },
    create(base) {
        return EventAttestationObserved.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAttestationObserved();
        message.attestationType = object.attestationType ?? 0;
        message.bridgeContract = object.bridgeContract ?? "";
        message.bridgeChainId = object.bridgeChainId ?? "0";
        message.attestationId = object.attestationId ?? new Uint8Array();
        message.nonce = object.nonce ?? "0";
        return message;
    },
};
function createBaseEventBridgeWithdrawCanceled() {
    return { bridgeContract: "", bridgeChainId: "0" };
}
export const EventBridgeWithdrawCanceled = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bridgeContract !== "") {
            writer.uint32(10).string(message.bridgeContract);
        }
        if (message.bridgeChainId !== "0") {
            writer.uint32(16).uint64(message.bridgeChainId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBridgeWithdrawCanceled();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bridgeContract = reader.string();
                    break;
                case 2:
                    message.bridgeChainId = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bridgeContract: isSet(object.bridgeContract) ? String(object.bridgeContract) : "",
            bridgeChainId: isSet(object.bridgeChainId) ? String(object.bridgeChainId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.bridgeContract !== undefined && (obj.bridgeContract = message.bridgeContract);
        message.bridgeChainId !== undefined && (obj.bridgeChainId = message.bridgeChainId);
        return obj;
    },
    create(base) {
        return EventBridgeWithdrawCanceled.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBridgeWithdrawCanceled();
        message.bridgeContract = object.bridgeContract ?? "";
        message.bridgeChainId = object.bridgeChainId ?? "0";
        return message;
    },
};
function createBaseEventOutgoingBatch() {
    return { denom: "", orchestratorAddress: "", batchNonce: "0", batchTimeout: "0", batchTxIds: [] };
}
export const EventOutgoingBatch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(18).string(message.orchestratorAddress);
        }
        if (message.batchNonce !== "0") {
            writer.uint32(24).uint64(message.batchNonce);
        }
        if (message.batchTimeout !== "0") {
            writer.uint32(32).uint64(message.batchTimeout);
        }
        writer.uint32(42).fork();
        for (const v of message.batchTxIds) {
            writer.uint64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventOutgoingBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.orchestratorAddress = reader.string();
                    break;
                case 3:
                    message.batchNonce = longToString(reader.uint64());
                    break;
                case 4:
                    message.batchTimeout = longToString(reader.uint64());
                    break;
                case 5:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.batchTxIds.push(longToString(reader.uint64()));
                        }
                    }
                    else {
                        message.batchTxIds.push(longToString(reader.uint64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
            batchNonce: isSet(object.batchNonce) ? String(object.batchNonce) : "0",
            batchTimeout: isSet(object.batchTimeout) ? String(object.batchTimeout) : "0",
            batchTxIds: Array.isArray(object?.batchTxIds) ? object.batchTxIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        message.batchNonce !== undefined && (obj.batchNonce = message.batchNonce);
        message.batchTimeout !== undefined && (obj.batchTimeout = message.batchTimeout);
        if (message.batchTxIds) {
            obj.batchTxIds = message.batchTxIds.map((e) => e);
        }
        else {
            obj.batchTxIds = [];
        }
        return obj;
    },
    create(base) {
        return EventOutgoingBatch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventOutgoingBatch();
        message.denom = object.denom ?? "";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        message.batchNonce = object.batchNonce ?? "0";
        message.batchTimeout = object.batchTimeout ?? "0";
        message.batchTxIds = object.batchTxIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseEventOutgoingBatchCanceled() {
    return { bridgeContract: "", bridgeChainId: "0", batchId: "0", nonce: "0" };
}
export const EventOutgoingBatchCanceled = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bridgeContract !== "") {
            writer.uint32(10).string(message.bridgeContract);
        }
        if (message.bridgeChainId !== "0") {
            writer.uint32(16).uint64(message.bridgeChainId);
        }
        if (message.batchId !== "0") {
            writer.uint32(24).uint64(message.batchId);
        }
        if (message.nonce !== "0") {
            writer.uint32(32).uint64(message.nonce);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventOutgoingBatchCanceled();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bridgeContract = reader.string();
                    break;
                case 2:
                    message.bridgeChainId = longToString(reader.uint64());
                    break;
                case 3:
                    message.batchId = longToString(reader.uint64());
                    break;
                case 4:
                    message.nonce = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bridgeContract: isSet(object.bridgeContract) ? String(object.bridgeContract) : "",
            bridgeChainId: isSet(object.bridgeChainId) ? String(object.bridgeChainId) : "0",
            batchId: isSet(object.batchId) ? String(object.batchId) : "0",
            nonce: isSet(object.nonce) ? String(object.nonce) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.bridgeContract !== undefined && (obj.bridgeContract = message.bridgeContract);
        message.bridgeChainId !== undefined && (obj.bridgeChainId = message.bridgeChainId);
        message.batchId !== undefined && (obj.batchId = message.batchId);
        message.nonce !== undefined && (obj.nonce = message.nonce);
        return obj;
    },
    create(base) {
        return EventOutgoingBatchCanceled.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventOutgoingBatchCanceled();
        message.bridgeContract = object.bridgeContract ?? "";
        message.bridgeChainId = object.bridgeChainId ?? "0";
        message.batchId = object.batchId ?? "0";
        message.nonce = object.nonce ?? "0";
        return message;
    },
};
function createBaseEventValsetUpdateRequest() {
    return { valsetNonce: "0", valsetHeight: "0", valsetMembers: [], rewardAmount: "", rewardToken: "" };
}
export const EventValsetUpdateRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.valsetNonce !== "0") {
            writer.uint32(8).uint64(message.valsetNonce);
        }
        if (message.valsetHeight !== "0") {
            writer.uint32(16).uint64(message.valsetHeight);
        }
        for (const v of message.valsetMembers) {
            BridgeValidator.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.rewardAmount !== "") {
            writer.uint32(34).string(message.rewardAmount);
        }
        if (message.rewardToken !== "") {
            writer.uint32(42).string(message.rewardToken);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventValsetUpdateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.valsetNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.valsetHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.valsetMembers.push(BridgeValidator.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.rewardAmount = reader.string();
                    break;
                case 5:
                    message.rewardToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            valsetNonce: isSet(object.valsetNonce) ? String(object.valsetNonce) : "0",
            valsetHeight: isSet(object.valsetHeight) ? String(object.valsetHeight) : "0",
            valsetMembers: Array.isArray(object?.valsetMembers)
                ? object.valsetMembers.map((e) => BridgeValidator.fromJSON(e))
                : [],
            rewardAmount: isSet(object.rewardAmount) ? String(object.rewardAmount) : "",
            rewardToken: isSet(object.rewardToken) ? String(object.rewardToken) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.valsetNonce !== undefined && (obj.valsetNonce = message.valsetNonce);
        message.valsetHeight !== undefined && (obj.valsetHeight = message.valsetHeight);
        if (message.valsetMembers) {
            obj.valsetMembers = message.valsetMembers.map((e) => e ? BridgeValidator.toJSON(e) : undefined);
        }
        else {
            obj.valsetMembers = [];
        }
        message.rewardAmount !== undefined && (obj.rewardAmount = message.rewardAmount);
        message.rewardToken !== undefined && (obj.rewardToken = message.rewardToken);
        return obj;
    },
    create(base) {
        return EventValsetUpdateRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventValsetUpdateRequest();
        message.valsetNonce = object.valsetNonce ?? "0";
        message.valsetHeight = object.valsetHeight ?? "0";
        message.valsetMembers = object.valsetMembers?.map((e) => BridgeValidator.fromPartial(e)) || [];
        message.rewardAmount = object.rewardAmount ?? "";
        message.rewardToken = object.rewardToken ?? "";
        return message;
    },
};
function createBaseEventSetOrchestratorAddresses() {
    return { validatorAddress: "", orchestratorAddress: "", operatorEthAddress: "" };
}
export const EventSetOrchestratorAddresses = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.validatorAddress !== "") {
            writer.uint32(10).string(message.validatorAddress);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(18).string(message.orchestratorAddress);
        }
        if (message.operatorEthAddress !== "") {
            writer.uint32(26).string(message.operatorEthAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSetOrchestratorAddresses();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.validatorAddress = reader.string();
                    break;
                case 2:
                    message.orchestratorAddress = reader.string();
                    break;
                case 3:
                    message.operatorEthAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            validatorAddress: isSet(object.validatorAddress) ? String(object.validatorAddress) : "",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
            operatorEthAddress: isSet(object.operatorEthAddress) ? String(object.operatorEthAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.validatorAddress !== undefined && (obj.validatorAddress = message.validatorAddress);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        message.operatorEthAddress !== undefined && (obj.operatorEthAddress = message.operatorEthAddress);
        return obj;
    },
    create(base) {
        return EventSetOrchestratorAddresses.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSetOrchestratorAddresses();
        message.validatorAddress = object.validatorAddress ?? "";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        message.operatorEthAddress = object.operatorEthAddress ?? "";
        return message;
    },
};
function createBaseEventValsetConfirm() {
    return { valsetNonce: "0", orchestratorAddress: "" };
}
export const EventValsetConfirm = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.valsetNonce !== "0") {
            writer.uint32(8).uint64(message.valsetNonce);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(18).string(message.orchestratorAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventValsetConfirm();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.valsetNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.orchestratorAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            valsetNonce: isSet(object.valsetNonce) ? String(object.valsetNonce) : "0",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.valsetNonce !== undefined && (obj.valsetNonce = message.valsetNonce);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        return obj;
    },
    create(base) {
        return EventValsetConfirm.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventValsetConfirm();
        message.valsetNonce = object.valsetNonce ?? "0";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        return message;
    },
};
function createBaseEventSendToEth() {
    return { outgoingTxId: "0", sender: "", receiver: "", amount: "", bridgeFee: "" };
}
export const EventSendToEth = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.outgoingTxId !== "0") {
            writer.uint32(8).uint64(message.outgoingTxId);
        }
        if (message.sender !== "") {
            writer.uint32(18).string(message.sender);
        }
        if (message.receiver !== "") {
            writer.uint32(26).string(message.receiver);
        }
        if (message.amount !== "") {
            writer.uint32(34).string(message.amount);
        }
        if (message.bridgeFee !== "") {
            writer.uint32(42).string(message.bridgeFee);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSendToEth();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.outgoingTxId = longToString(reader.uint64());
                    break;
                case 2:
                    message.sender = reader.string();
                    break;
                case 3:
                    message.receiver = reader.string();
                    break;
                case 4:
                    message.amount = reader.string();
                    break;
                case 5:
                    message.bridgeFee = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            outgoingTxId: isSet(object.outgoingTxId) ? String(object.outgoingTxId) : "0",
            sender: isSet(object.sender) ? String(object.sender) : "",
            receiver: isSet(object.receiver) ? String(object.receiver) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            bridgeFee: isSet(object.bridgeFee) ? String(object.bridgeFee) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.outgoingTxId !== undefined && (obj.outgoingTxId = message.outgoingTxId);
        message.sender !== undefined && (obj.sender = message.sender);
        message.receiver !== undefined && (obj.receiver = message.receiver);
        message.amount !== undefined && (obj.amount = message.amount);
        message.bridgeFee !== undefined && (obj.bridgeFee = message.bridgeFee);
        return obj;
    },
    create(base) {
        return EventSendToEth.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSendToEth();
        message.outgoingTxId = object.outgoingTxId ?? "0";
        message.sender = object.sender ?? "";
        message.receiver = object.receiver ?? "";
        message.amount = object.amount ?? "";
        message.bridgeFee = object.bridgeFee ?? "";
        return message;
    },
};
function createBaseEventConfirmBatch() {
    return { batchNonce: "0", orchestratorAddress: "" };
}
export const EventConfirmBatch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.batchNonce !== "0") {
            writer.uint32(8).uint64(message.batchNonce);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(18).string(message.orchestratorAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventConfirmBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.batchNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.orchestratorAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            batchNonce: isSet(object.batchNonce) ? String(object.batchNonce) : "0",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.batchNonce !== undefined && (obj.batchNonce = message.batchNonce);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        return obj;
    },
    create(base) {
        return EventConfirmBatch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventConfirmBatch();
        message.batchNonce = object.batchNonce ?? "0";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        return message;
    },
};
function createBaseEventAttestationVote() {
    return { eventNonce: "0", attestationId: new Uint8Array(), voter: "" };
}
export const EventAttestationVote = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.attestationId.length !== 0) {
            writer.uint32(18).bytes(message.attestationId);
        }
        if (message.voter !== "") {
            writer.uint32(26).string(message.voter);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAttestationVote();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.attestationId = reader.bytes();
                    break;
                case 3:
                    message.voter = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            attestationId: isSet(object.attestationId) ? bytesFromBase64(object.attestationId) : new Uint8Array(),
            voter: isSet(object.voter) ? String(object.voter) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.attestationId !== undefined &&
            (obj.attestationId = base64FromBytes(message.attestationId !== undefined ? message.attestationId : new Uint8Array()));
        message.voter !== undefined && (obj.voter = message.voter);
        return obj;
    },
    create(base) {
        return EventAttestationVote.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAttestationVote();
        message.eventNonce = object.eventNonce ?? "0";
        message.attestationId = object.attestationId ?? new Uint8Array();
        message.voter = object.voter ?? "";
        return message;
    },
};
function createBaseEventDepositClaim() {
    return {
        eventNonce: "0",
        eventHeight: "0",
        attestationId: new Uint8Array(),
        ethereumSender: "",
        cosmosReceiver: "",
        tokenContract: "",
        amount: "",
        orchestratorAddress: "",
        data: "",
    };
}
export const EventDepositClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.eventHeight !== "0") {
            writer.uint32(16).uint64(message.eventHeight);
        }
        if (message.attestationId.length !== 0) {
            writer.uint32(26).bytes(message.attestationId);
        }
        if (message.ethereumSender !== "") {
            writer.uint32(34).string(message.ethereumSender);
        }
        if (message.cosmosReceiver !== "") {
            writer.uint32(42).string(message.cosmosReceiver);
        }
        if (message.tokenContract !== "") {
            writer.uint32(50).string(message.tokenContract);
        }
        if (message.amount !== "") {
            writer.uint32(58).string(message.amount);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(66).string(message.orchestratorAddress);
        }
        if (message.data !== "") {
            writer.uint32(74).string(message.data);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventDepositClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.eventHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.attestationId = reader.bytes();
                    break;
                case 4:
                    message.ethereumSender = reader.string();
                    break;
                case 5:
                    message.cosmosReceiver = reader.string();
                    break;
                case 6:
                    message.tokenContract = reader.string();
                    break;
                case 7:
                    message.amount = reader.string();
                    break;
                case 8:
                    message.orchestratorAddress = reader.string();
                    break;
                case 9:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            eventHeight: isSet(object.eventHeight) ? String(object.eventHeight) : "0",
            attestationId: isSet(object.attestationId) ? bytesFromBase64(object.attestationId) : new Uint8Array(),
            ethereumSender: isSet(object.ethereumSender) ? String(object.ethereumSender) : "",
            cosmosReceiver: isSet(object.cosmosReceiver) ? String(object.cosmosReceiver) : "",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
            data: isSet(object.data) ? String(object.data) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.eventHeight !== undefined && (obj.eventHeight = message.eventHeight);
        message.attestationId !== undefined &&
            (obj.attestationId = base64FromBytes(message.attestationId !== undefined ? message.attestationId : new Uint8Array()));
        message.ethereumSender !== undefined && (obj.ethereumSender = message.ethereumSender);
        message.cosmosReceiver !== undefined && (obj.cosmosReceiver = message.cosmosReceiver);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.amount !== undefined && (obj.amount = message.amount);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        message.data !== undefined && (obj.data = message.data);
        return obj;
    },
    create(base) {
        return EventDepositClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventDepositClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.eventHeight = object.eventHeight ?? "0";
        message.attestationId = object.attestationId ?? new Uint8Array();
        message.ethereumSender = object.ethereumSender ?? "";
        message.cosmosReceiver = object.cosmosReceiver ?? "";
        message.tokenContract = object.tokenContract ?? "";
        message.amount = object.amount ?? "";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        message.data = object.data ?? "";
        return message;
    },
};
function createBaseEventWithdrawClaim() {
    return {
        eventNonce: "0",
        eventHeight: "0",
        attestationId: new Uint8Array(),
        batchNonce: "0",
        tokenContract: "",
        orchestratorAddress: "",
    };
}
export const EventWithdrawClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.eventHeight !== "0") {
            writer.uint32(16).uint64(message.eventHeight);
        }
        if (message.attestationId.length !== 0) {
            writer.uint32(26).bytes(message.attestationId);
        }
        if (message.batchNonce !== "0") {
            writer.uint32(32).uint64(message.batchNonce);
        }
        if (message.tokenContract !== "") {
            writer.uint32(42).string(message.tokenContract);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(50).string(message.orchestratorAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventWithdrawClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.eventHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.attestationId = reader.bytes();
                    break;
                case 4:
                    message.batchNonce = longToString(reader.uint64());
                    break;
                case 5:
                    message.tokenContract = reader.string();
                    break;
                case 6:
                    message.orchestratorAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            eventHeight: isSet(object.eventHeight) ? String(object.eventHeight) : "0",
            attestationId: isSet(object.attestationId) ? bytesFromBase64(object.attestationId) : new Uint8Array(),
            batchNonce: isSet(object.batchNonce) ? String(object.batchNonce) : "0",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.eventHeight !== undefined && (obj.eventHeight = message.eventHeight);
        message.attestationId !== undefined &&
            (obj.attestationId = base64FromBytes(message.attestationId !== undefined ? message.attestationId : new Uint8Array()));
        message.batchNonce !== undefined && (obj.batchNonce = message.batchNonce);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        return obj;
    },
    create(base) {
        return EventWithdrawClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventWithdrawClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.eventHeight = object.eventHeight ?? "0";
        message.attestationId = object.attestationId ?? new Uint8Array();
        message.batchNonce = object.batchNonce ?? "0";
        message.tokenContract = object.tokenContract ?? "";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        return message;
    },
};
function createBaseEventERC20DeployedClaim() {
    return {
        eventNonce: "0",
        eventHeight: "0",
        attestationId: new Uint8Array(),
        cosmosDenom: "",
        tokenContract: "",
        name: "",
        symbol: "",
        decimals: "0",
        orchestratorAddress: "",
    };
}
export const EventERC20DeployedClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.eventHeight !== "0") {
            writer.uint32(16).uint64(message.eventHeight);
        }
        if (message.attestationId.length !== 0) {
            writer.uint32(26).bytes(message.attestationId);
        }
        if (message.cosmosDenom !== "") {
            writer.uint32(34).string(message.cosmosDenom);
        }
        if (message.tokenContract !== "") {
            writer.uint32(42).string(message.tokenContract);
        }
        if (message.name !== "") {
            writer.uint32(50).string(message.name);
        }
        if (message.symbol !== "") {
            writer.uint32(58).string(message.symbol);
        }
        if (message.decimals !== "0") {
            writer.uint32(64).uint64(message.decimals);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(74).string(message.orchestratorAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventERC20DeployedClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.eventHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.attestationId = reader.bytes();
                    break;
                case 4:
                    message.cosmosDenom = reader.string();
                    break;
                case 5:
                    message.tokenContract = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.symbol = reader.string();
                    break;
                case 8:
                    message.decimals = longToString(reader.uint64());
                    break;
                case 9:
                    message.orchestratorAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            eventHeight: isSet(object.eventHeight) ? String(object.eventHeight) : "0",
            attestationId: isSet(object.attestationId) ? bytesFromBase64(object.attestationId) : new Uint8Array(),
            cosmosDenom: isSet(object.cosmosDenom) ? String(object.cosmosDenom) : "",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            name: isSet(object.name) ? String(object.name) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            decimals: isSet(object.decimals) ? String(object.decimals) : "0",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.eventHeight !== undefined && (obj.eventHeight = message.eventHeight);
        message.attestationId !== undefined &&
            (obj.attestationId = base64FromBytes(message.attestationId !== undefined ? message.attestationId : new Uint8Array()));
        message.cosmosDenom !== undefined && (obj.cosmosDenom = message.cosmosDenom);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.name !== undefined && (obj.name = message.name);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.decimals !== undefined && (obj.decimals = message.decimals);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        return obj;
    },
    create(base) {
        return EventERC20DeployedClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventERC20DeployedClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.eventHeight = object.eventHeight ?? "0";
        message.attestationId = object.attestationId ?? new Uint8Array();
        message.cosmosDenom = object.cosmosDenom ?? "";
        message.tokenContract = object.tokenContract ?? "";
        message.name = object.name ?? "";
        message.symbol = object.symbol ?? "";
        message.decimals = object.decimals ?? "0";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        return message;
    },
};
function createBaseEventValsetUpdateClaim() {
    return {
        eventNonce: "0",
        eventHeight: "0",
        attestationId: new Uint8Array(),
        valsetNonce: "0",
        valsetMembers: [],
        rewardAmount: "",
        rewardToken: "",
        orchestratorAddress: "",
    };
}
export const EventValsetUpdateClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.eventHeight !== "0") {
            writer.uint32(16).uint64(message.eventHeight);
        }
        if (message.attestationId.length !== 0) {
            writer.uint32(26).bytes(message.attestationId);
        }
        if (message.valsetNonce !== "0") {
            writer.uint32(32).uint64(message.valsetNonce);
        }
        for (const v of message.valsetMembers) {
            BridgeValidator.encode(v, writer.uint32(42).fork()).ldelim();
        }
        if (message.rewardAmount !== "") {
            writer.uint32(50).string(message.rewardAmount);
        }
        if (message.rewardToken !== "") {
            writer.uint32(58).string(message.rewardToken);
        }
        if (message.orchestratorAddress !== "") {
            writer.uint32(66).string(message.orchestratorAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventValsetUpdateClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.eventHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.attestationId = reader.bytes();
                    break;
                case 4:
                    message.valsetNonce = longToString(reader.uint64());
                    break;
                case 5:
                    message.valsetMembers.push(BridgeValidator.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.rewardAmount = reader.string();
                    break;
                case 7:
                    message.rewardToken = reader.string();
                    break;
                case 8:
                    message.orchestratorAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            eventHeight: isSet(object.eventHeight) ? String(object.eventHeight) : "0",
            attestationId: isSet(object.attestationId) ? bytesFromBase64(object.attestationId) : new Uint8Array(),
            valsetNonce: isSet(object.valsetNonce) ? String(object.valsetNonce) : "0",
            valsetMembers: Array.isArray(object?.valsetMembers)
                ? object.valsetMembers.map((e) => BridgeValidator.fromJSON(e))
                : [],
            rewardAmount: isSet(object.rewardAmount) ? String(object.rewardAmount) : "",
            rewardToken: isSet(object.rewardToken) ? String(object.rewardToken) : "",
            orchestratorAddress: isSet(object.orchestratorAddress) ? String(object.orchestratorAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.eventHeight !== undefined && (obj.eventHeight = message.eventHeight);
        message.attestationId !== undefined &&
            (obj.attestationId = base64FromBytes(message.attestationId !== undefined ? message.attestationId : new Uint8Array()));
        message.valsetNonce !== undefined && (obj.valsetNonce = message.valsetNonce);
        if (message.valsetMembers) {
            obj.valsetMembers = message.valsetMembers.map((e) => e ? BridgeValidator.toJSON(e) : undefined);
        }
        else {
            obj.valsetMembers = [];
        }
        message.rewardAmount !== undefined && (obj.rewardAmount = message.rewardAmount);
        message.rewardToken !== undefined && (obj.rewardToken = message.rewardToken);
        message.orchestratorAddress !== undefined && (obj.orchestratorAddress = message.orchestratorAddress);
        return obj;
    },
    create(base) {
        return EventValsetUpdateClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventValsetUpdateClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.eventHeight = object.eventHeight ?? "0";
        message.attestationId = object.attestationId ?? new Uint8Array();
        message.valsetNonce = object.valsetNonce ?? "0";
        message.valsetMembers = object.valsetMembers?.map((e) => BridgeValidator.fromPartial(e)) || [];
        message.rewardAmount = object.rewardAmount ?? "";
        message.rewardToken = object.rewardToken ?? "";
        message.orchestratorAddress = object.orchestratorAddress ?? "";
        return message;
    },
};
function createBaseEventCancelSendToEth() {
    return { outgoingTxId: "0" };
}
export const EventCancelSendToEth = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.outgoingTxId !== "0") {
            writer.uint32(8).uint64(message.outgoingTxId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventCancelSendToEth();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.outgoingTxId = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { outgoingTxId: isSet(object.outgoingTxId) ? String(object.outgoingTxId) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.outgoingTxId !== undefined && (obj.outgoingTxId = message.outgoingTxId);
        return obj;
    },
    create(base) {
        return EventCancelSendToEth.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventCancelSendToEth();
        message.outgoingTxId = object.outgoingTxId ?? "0";
        return message;
    },
};
function createBaseEventSubmitBadSignatureEvidence() {
    return { badEthSignature: "", badEthSignatureSubject: "" };
}
export const EventSubmitBadSignatureEvidence = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.badEthSignature !== "") {
            writer.uint32(10).string(message.badEthSignature);
        }
        if (message.badEthSignatureSubject !== "") {
            writer.uint32(18).string(message.badEthSignatureSubject);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSubmitBadSignatureEvidence();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.badEthSignature = reader.string();
                    break;
                case 2:
                    message.badEthSignatureSubject = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            badEthSignature: isSet(object.badEthSignature) ? String(object.badEthSignature) : "",
            badEthSignatureSubject: isSet(object.badEthSignatureSubject) ? String(object.badEthSignatureSubject) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.badEthSignature !== undefined && (obj.badEthSignature = message.badEthSignature);
        message.badEthSignatureSubject !== undefined && (obj.badEthSignatureSubject = message.badEthSignatureSubject);
        return obj;
    },
    create(base) {
        return EventSubmitBadSignatureEvidence.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSubmitBadSignatureEvidence();
        message.badEthSignature = object.badEthSignature ?? "";
        message.badEthSignatureSubject = object.badEthSignatureSubject ?? "";
        return message;
    },
};
function createBaseEventValidatorSlash() {
    return { power: "0", reason: "", consensusAddress: "", operatorAddress: "", moniker: "" };
}
export const EventValidatorSlash = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.power !== "0") {
            writer.uint32(8).int64(message.power);
        }
        if (message.reason !== "") {
            writer.uint32(18).string(message.reason);
        }
        if (message.consensusAddress !== "") {
            writer.uint32(26).string(message.consensusAddress);
        }
        if (message.operatorAddress !== "") {
            writer.uint32(34).string(message.operatorAddress);
        }
        if (message.moniker !== "") {
            writer.uint32(42).string(message.moniker);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventValidatorSlash();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.power = longToString(reader.int64());
                    break;
                case 2:
                    message.reason = reader.string();
                    break;
                case 3:
                    message.consensusAddress = reader.string();
                    break;
                case 4:
                    message.operatorAddress = reader.string();
                    break;
                case 5:
                    message.moniker = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            power: isSet(object.power) ? String(object.power) : "0",
            reason: isSet(object.reason) ? String(object.reason) : "",
            consensusAddress: isSet(object.consensusAddress) ? String(object.consensusAddress) : "",
            operatorAddress: isSet(object.operatorAddress) ? String(object.operatorAddress) : "",
            moniker: isSet(object.moniker) ? String(object.moniker) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.power !== undefined && (obj.power = message.power);
        message.reason !== undefined && (obj.reason = message.reason);
        message.consensusAddress !== undefined && (obj.consensusAddress = message.consensusAddress);
        message.operatorAddress !== undefined && (obj.operatorAddress = message.operatorAddress);
        message.moniker !== undefined && (obj.moniker = message.moniker);
        return obj;
    },
    create(base) {
        return EventValidatorSlash.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventValidatorSlash();
        message.power = object.power ?? "0";
        message.reason = object.reason ?? "";
        message.consensusAddress = object.consensusAddress ?? "";
        message.operatorAddress = object.operatorAddress ?? "";
        message.moniker = object.moniker ?? "";
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
