/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective_campaign_rpc";
function createBaseRankingRequest() {
    return { campaignId: "", marketId: "", accountAddress: "", limit: 0, skip: "0", contractAddress: "" };
}
export const RankingRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignId !== "") {
            writer.uint32(10).string(message.campaignId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(26).string(message.accountAddress);
        }
        if (message.limit !== 0) {
            writer.uint32(32).sint32(message.limit);
        }
        if (message.skip !== "0") {
            writer.uint32(40).uint64(message.skip);
        }
        if (message.contractAddress !== "") {
            writer.uint32(50).string(message.contractAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRankingRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.accountAddress = reader.string();
                    break;
                case 4:
                    message.limit = reader.sint32();
                    break;
                case 5:
                    message.skip = longToString(reader.uint64());
                    break;
                case 6:
                    message.contractAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? String(object.skip) : "0",
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = message.skip);
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        return obj;
    },
    create(base) {
        return RankingRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRankingRequest();
        message.campaignId = object.campaignId ?? "";
        message.marketId = object.marketId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? "0";
        message.contractAddress = object.contractAddress ?? "";
        return message;
    },
};
function createBaseRankingResponse() {
    return { campaign: undefined, users: [], paging: undefined };
}
export const RankingResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaign !== undefined) {
            Campaign.encode(message.campaign, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.users) {
            CampaignUser.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRankingResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaign = Campaign.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.users.push(CampaignUser.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaign: isSet(object.campaign) ? Campaign.fromJSON(object.campaign) : undefined,
            users: Array.isArray(object?.users) ? object.users.map((e) => CampaignUser.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaign !== undefined && (obj.campaign = message.campaign ? Campaign.toJSON(message.campaign) : undefined);
        if (message.users) {
            obj.users = message.users.map((e) => e ? CampaignUser.toJSON(e) : undefined);
        }
        else {
            obj.users = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        return obj;
    },
    create(base) {
        return RankingResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRankingResponse();
        message.campaign = (object.campaign !== undefined && object.campaign !== null)
            ? Campaign.fromPartial(object.campaign)
            : undefined;
        message.users = object.users?.map((e) => CampaignUser.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        return message;
    },
};
function createBaseCampaign() {
    return {
        campaignId: "",
        marketId: "",
        totalScore: "",
        lastUpdated: "0",
        startDate: "0",
        endDate: "0",
        isClaimable: false,
        roundId: 0,
        managerContract: "",
        rewards: [],
        userScore: "",
        userClaimed: false,
        subaccountIdSuffix: "",
        rewardContract: "",
        version: "",
        type: "",
    };
}
export const Campaign = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignId !== "") {
            writer.uint32(10).string(message.campaignId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.totalScore !== "") {
            writer.uint32(34).string(message.totalScore);
        }
        if (message.lastUpdated !== "0") {
            writer.uint32(40).sint64(message.lastUpdated);
        }
        if (message.startDate !== "0") {
            writer.uint32(48).sint64(message.startDate);
        }
        if (message.endDate !== "0") {
            writer.uint32(56).sint64(message.endDate);
        }
        if (message.isClaimable === true) {
            writer.uint32(64).bool(message.isClaimable);
        }
        if (message.roundId !== 0) {
            writer.uint32(72).sint32(message.roundId);
        }
        if (message.managerContract !== "") {
            writer.uint32(82).string(message.managerContract);
        }
        for (const v of message.rewards) {
            Coin.encode(v, writer.uint32(90).fork()).ldelim();
        }
        if (message.userScore !== "") {
            writer.uint32(98).string(message.userScore);
        }
        if (message.userClaimed === true) {
            writer.uint32(104).bool(message.userClaimed);
        }
        if (message.subaccountIdSuffix !== "") {
            writer.uint32(114).string(message.subaccountIdSuffix);
        }
        if (message.rewardContract !== "") {
            writer.uint32(122).string(message.rewardContract);
        }
        if (message.version !== "") {
            writer.uint32(130).string(message.version);
        }
        if (message.type !== "") {
            writer.uint32(138).string(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.totalScore = reader.string();
                    break;
                case 5:
                    message.lastUpdated = longToString(reader.sint64());
                    break;
                case 6:
                    message.startDate = longToString(reader.sint64());
                    break;
                case 7:
                    message.endDate = longToString(reader.sint64());
                    break;
                case 8:
                    message.isClaimable = reader.bool();
                    break;
                case 9:
                    message.roundId = reader.sint32();
                    break;
                case 10:
                    message.managerContract = reader.string();
                    break;
                case 11:
                    message.rewards.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.userScore = reader.string();
                    break;
                case 13:
                    message.userClaimed = reader.bool();
                    break;
                case 14:
                    message.subaccountIdSuffix = reader.string();
                    break;
                case 15:
                    message.rewardContract = reader.string();
                    break;
                case 16:
                    message.version = reader.string();
                    break;
                case 17:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            totalScore: isSet(object.totalScore) ? String(object.totalScore) : "",
            lastUpdated: isSet(object.lastUpdated) ? String(object.lastUpdated) : "0",
            startDate: isSet(object.startDate) ? String(object.startDate) : "0",
            endDate: isSet(object.endDate) ? String(object.endDate) : "0",
            isClaimable: isSet(object.isClaimable) ? Boolean(object.isClaimable) : false,
            roundId: isSet(object.roundId) ? Number(object.roundId) : 0,
            managerContract: isSet(object.managerContract) ? String(object.managerContract) : "",
            rewards: Array.isArray(object?.rewards) ? object.rewards.map((e) => Coin.fromJSON(e)) : [],
            userScore: isSet(object.userScore) ? String(object.userScore) : "",
            userClaimed: isSet(object.userClaimed) ? Boolean(object.userClaimed) : false,
            subaccountIdSuffix: isSet(object.subaccountIdSuffix) ? String(object.subaccountIdSuffix) : "",
            rewardContract: isSet(object.rewardContract) ? String(object.rewardContract) : "",
            version: isSet(object.version) ? String(object.version) : "",
            type: isSet(object.type) ? String(object.type) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.totalScore !== undefined && (obj.totalScore = message.totalScore);
        message.lastUpdated !== undefined && (obj.lastUpdated = message.lastUpdated);
        message.startDate !== undefined && (obj.startDate = message.startDate);
        message.endDate !== undefined && (obj.endDate = message.endDate);
        message.isClaimable !== undefined && (obj.isClaimable = message.isClaimable);
        message.roundId !== undefined && (obj.roundId = Math.round(message.roundId));
        message.managerContract !== undefined && (obj.managerContract = message.managerContract);
        if (message.rewards) {
            obj.rewards = message.rewards.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.rewards = [];
        }
        message.userScore !== undefined && (obj.userScore = message.userScore);
        message.userClaimed !== undefined && (obj.userClaimed = message.userClaimed);
        message.subaccountIdSuffix !== undefined && (obj.subaccountIdSuffix = message.subaccountIdSuffix);
        message.rewardContract !== undefined && (obj.rewardContract = message.rewardContract);
        message.version !== undefined && (obj.version = message.version);
        message.type !== undefined && (obj.type = message.type);
        return obj;
    },
    create(base) {
        return Campaign.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaign();
        message.campaignId = object.campaignId ?? "";
        message.marketId = object.marketId ?? "";
        message.totalScore = object.totalScore ?? "";
        message.lastUpdated = object.lastUpdated ?? "0";
        message.startDate = object.startDate ?? "0";
        message.endDate = object.endDate ?? "0";
        message.isClaimable = object.isClaimable ?? false;
        message.roundId = object.roundId ?? 0;
        message.managerContract = object.managerContract ?? "";
        message.rewards = object.rewards?.map((e) => Coin.fromPartial(e)) || [];
        message.userScore = object.userScore ?? "";
        message.userClaimed = object.userClaimed ?? false;
        message.subaccountIdSuffix = object.subaccountIdSuffix ?? "";
        message.rewardContract = object.rewardContract ?? "";
        message.version = object.version ?? "";
        message.type = object.type ?? "";
        return message;
    },
};
function createBaseCoin() {
    return { denom: "", amount: "" };
}
export const Coin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return Coin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCoin();
        message.denom = object.denom ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseCampaignUser() {
    return {
        campaignId: "",
        marketId: "",
        accountAddress: "",
        score: "",
        contractUpdated: false,
        blockHeight: "0",
        blockTime: "0",
        purchasedAmount: "",
        galxeUpdated: false,
        rewardClaimed: false,
    };
}
export const CampaignUser = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignId !== "") {
            writer.uint32(10).string(message.campaignId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(26).string(message.accountAddress);
        }
        if (message.score !== "") {
            writer.uint32(34).string(message.score);
        }
        if (message.contractUpdated === true) {
            writer.uint32(40).bool(message.contractUpdated);
        }
        if (message.blockHeight !== "0") {
            writer.uint32(48).sint64(message.blockHeight);
        }
        if (message.blockTime !== "0") {
            writer.uint32(56).sint64(message.blockTime);
        }
        if (message.purchasedAmount !== "") {
            writer.uint32(66).string(message.purchasedAmount);
        }
        if (message.galxeUpdated === true) {
            writer.uint32(72).bool(message.galxeUpdated);
        }
        if (message.rewardClaimed === true) {
            writer.uint32(80).bool(message.rewardClaimed);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.accountAddress = reader.string();
                    break;
                case 4:
                    message.score = reader.string();
                    break;
                case 5:
                    message.contractUpdated = reader.bool();
                    break;
                case 6:
                    message.blockHeight = longToString(reader.sint64());
                    break;
                case 7:
                    message.blockTime = longToString(reader.sint64());
                    break;
                case 8:
                    message.purchasedAmount = reader.string();
                    break;
                case 9:
                    message.galxeUpdated = reader.bool();
                    break;
                case 10:
                    message.rewardClaimed = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            score: isSet(object.score) ? String(object.score) : "",
            contractUpdated: isSet(object.contractUpdated) ? Boolean(object.contractUpdated) : false,
            blockHeight: isSet(object.blockHeight) ? String(object.blockHeight) : "0",
            blockTime: isSet(object.blockTime) ? String(object.blockTime) : "0",
            purchasedAmount: isSet(object.purchasedAmount) ? String(object.purchasedAmount) : "",
            galxeUpdated: isSet(object.galxeUpdated) ? Boolean(object.galxeUpdated) : false,
            rewardClaimed: isSet(object.rewardClaimed) ? Boolean(object.rewardClaimed) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.score !== undefined && (obj.score = message.score);
        message.contractUpdated !== undefined && (obj.contractUpdated = message.contractUpdated);
        message.blockHeight !== undefined && (obj.blockHeight = message.blockHeight);
        message.blockTime !== undefined && (obj.blockTime = message.blockTime);
        message.purchasedAmount !== undefined && (obj.purchasedAmount = message.purchasedAmount);
        message.galxeUpdated !== undefined && (obj.galxeUpdated = message.galxeUpdated);
        message.rewardClaimed !== undefined && (obj.rewardClaimed = message.rewardClaimed);
        return obj;
    },
    create(base) {
        return CampaignUser.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignUser();
        message.campaignId = object.campaignId ?? "";
        message.marketId = object.marketId ?? "";
        message.accountAddress = object.accountAddress ?? "";
        message.score = object.score ?? "";
        message.contractUpdated = object.contractUpdated ?? false;
        message.blockHeight = object.blockHeight ?? "0";
        message.blockTime = object.blockTime ?? "0";
        message.purchasedAmount = object.purchasedAmount ?? "";
        message.galxeUpdated = object.galxeUpdated ?? false;
        message.rewardClaimed = object.rewardClaimed ?? false;
        return message;
    },
};
function createBasePaging() {
    return { total: "0", from: 0, to: 0, countBySubaccount: "0", next: [] };
}
export const Paging = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== "0") {
            writer.uint32(8).sint64(message.total);
        }
        if (message.from !== 0) {
            writer.uint32(16).sint32(message.from);
        }
        if (message.to !== 0) {
            writer.uint32(24).sint32(message.to);
        }
        if (message.countBySubaccount !== "0") {
            writer.uint32(32).sint64(message.countBySubaccount);
        }
        for (const v of message.next) {
            writer.uint32(42).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePaging();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = longToString(reader.sint64());
                    break;
                case 2:
                    message.from = reader.sint32();
                    break;
                case 3:
                    message.to = reader.sint32();
                    break;
                case 4:
                    message.countBySubaccount = longToString(reader.sint64());
                    break;
                case 5:
                    message.next.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? String(object.total) : "0",
            from: isSet(object.from) ? Number(object.from) : 0,
            to: isSet(object.to) ? Number(object.to) : 0,
            countBySubaccount: isSet(object.countBySubaccount) ? String(object.countBySubaccount) : "0",
            next: Array.isArray(object?.next) ? object.next.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = message.total);
        message.from !== undefined && (obj.from = Math.round(message.from));
        message.to !== undefined && (obj.to = Math.round(message.to));
        message.countBySubaccount !== undefined && (obj.countBySubaccount = message.countBySubaccount);
        if (message.next) {
            obj.next = message.next.map((e) => e);
        }
        else {
            obj.next = [];
        }
        return obj;
    },
    create(base) {
        return Paging.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePaging();
        message.total = object.total ?? "0";
        message.from = object.from ?? 0;
        message.to = object.to ?? 0;
        message.countBySubaccount = object.countBySubaccount ?? "0";
        message.next = object.next?.map((e) => e) || [];
        return message;
    },
};
function createBaseCampaignsRequest() {
    return { roundId: "0", accountAddress: "", toRoundId: 0, contractAddress: "", type: "" };
}
export const CampaignsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.roundId !== "0") {
            writer.uint32(8).sint64(message.roundId);
        }
        if (message.accountAddress !== "") {
            writer.uint32(18).string(message.accountAddress);
        }
        if (message.toRoundId !== 0) {
            writer.uint32(24).sint32(message.toRoundId);
        }
        if (message.contractAddress !== "") {
            writer.uint32(34).string(message.contractAddress);
        }
        if (message.type !== "") {
            writer.uint32(42).string(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roundId = longToString(reader.sint64());
                    break;
                case 2:
                    message.accountAddress = reader.string();
                    break;
                case 3:
                    message.toRoundId = reader.sint32();
                    break;
                case 4:
                    message.contractAddress = reader.string();
                    break;
                case 5:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            roundId: isSet(object.roundId) ? String(object.roundId) : "0",
            accountAddress: isSet(object.accountAddress) ? String(object.accountAddress) : "",
            toRoundId: isSet(object.toRoundId) ? Number(object.toRoundId) : 0,
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            type: isSet(object.type) ? String(object.type) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.roundId !== undefined && (obj.roundId = message.roundId);
        message.accountAddress !== undefined && (obj.accountAddress = message.accountAddress);
        message.toRoundId !== undefined && (obj.toRoundId = Math.round(message.toRoundId));
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.type !== undefined && (obj.type = message.type);
        return obj;
    },
    create(base) {
        return CampaignsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignsRequest();
        message.roundId = object.roundId ?? "0";
        message.accountAddress = object.accountAddress ?? "";
        message.toRoundId = object.toRoundId ?? 0;
        message.contractAddress = object.contractAddress ?? "";
        message.type = object.type ?? "";
        return message;
    },
};
function createBaseCampaignsResponse() {
    return { campaigns: [], accumulatedRewards: [], rewardCount: 0 };
}
export const CampaignsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.campaigns) {
            Campaign.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.accumulatedRewards) {
            Coin.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.rewardCount !== 0) {
            writer.uint32(24).sint32(message.rewardCount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaigns.push(Campaign.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.accumulatedRewards.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.rewardCount = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaigns: Array.isArray(object?.campaigns) ? object.campaigns.map((e) => Campaign.fromJSON(e)) : [],
            accumulatedRewards: Array.isArray(object?.accumulatedRewards)
                ? object.accumulatedRewards.map((e) => Coin.fromJSON(e))
                : [],
            rewardCount: isSet(object.rewardCount) ? Number(object.rewardCount) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.campaigns) {
            obj.campaigns = message.campaigns.map((e) => e ? Campaign.toJSON(e) : undefined);
        }
        else {
            obj.campaigns = [];
        }
        if (message.accumulatedRewards) {
            obj.accumulatedRewards = message.accumulatedRewards.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.accumulatedRewards = [];
        }
        message.rewardCount !== undefined && (obj.rewardCount = Math.round(message.rewardCount));
        return obj;
    },
    create(base) {
        return CampaignsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignsResponse();
        message.campaigns = object.campaigns?.map((e) => Campaign.fromPartial(e)) || [];
        message.accumulatedRewards = object.accumulatedRewards?.map((e) => Coin.fromPartial(e)) || [];
        message.rewardCount = object.rewardCount ?? 0;
        return message;
    },
};
function createBaseCampaignsV2Request() {
    return {
        type: "",
        active: false,
        limit: 0,
        cursor: "",
        fromStartDate: "0",
        toStartDate: "0",
        fromEndDate: "0",
        toEndDate: "0",
        status: "",
    };
}
export const CampaignsV2Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        if (message.active === true) {
            writer.uint32(16).bool(message.active);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.cursor !== "") {
            writer.uint32(34).string(message.cursor);
        }
        if (message.fromStartDate !== "0") {
            writer.uint32(40).sint64(message.fromStartDate);
        }
        if (message.toStartDate !== "0") {
            writer.uint32(48).sint64(message.toStartDate);
        }
        if (message.fromEndDate !== "0") {
            writer.uint32(56).sint64(message.fromEndDate);
        }
        if (message.toEndDate !== "0") {
            writer.uint32(64).sint64(message.toEndDate);
        }
        if (message.status !== "") {
            writer.uint32(74).string(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignsV2Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.active = reader.bool();
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.cursor = reader.string();
                    break;
                case 5:
                    message.fromStartDate = longToString(reader.sint64());
                    break;
                case 6:
                    message.toStartDate = longToString(reader.sint64());
                    break;
                case 7:
                    message.fromEndDate = longToString(reader.sint64());
                    break;
                case 8:
                    message.toEndDate = longToString(reader.sint64());
                    break;
                case 9:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
            active: isSet(object.active) ? Boolean(object.active) : false,
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            cursor: isSet(object.cursor) ? String(object.cursor) : "",
            fromStartDate: isSet(object.fromStartDate) ? String(object.fromStartDate) : "0",
            toStartDate: isSet(object.toStartDate) ? String(object.toStartDate) : "0",
            fromEndDate: isSet(object.fromEndDate) ? String(object.fromEndDate) : "0",
            toEndDate: isSet(object.toEndDate) ? String(object.toEndDate) : "0",
            status: isSet(object.status) ? String(object.status) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        message.active !== undefined && (obj.active = message.active);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.cursor !== undefined && (obj.cursor = message.cursor);
        message.fromStartDate !== undefined && (obj.fromStartDate = message.fromStartDate);
        message.toStartDate !== undefined && (obj.toStartDate = message.toStartDate);
        message.fromEndDate !== undefined && (obj.fromEndDate = message.fromEndDate);
        message.toEndDate !== undefined && (obj.toEndDate = message.toEndDate);
        message.status !== undefined && (obj.status = message.status);
        return obj;
    },
    create(base) {
        return CampaignsV2Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignsV2Request();
        message.type = object.type ?? "";
        message.active = object.active ?? false;
        message.limit = object.limit ?? 0;
        message.cursor = object.cursor ?? "";
        message.fromStartDate = object.fromStartDate ?? "0";
        message.toStartDate = object.toStartDate ?? "0";
        message.fromEndDate = object.fromEndDate ?? "0";
        message.toEndDate = object.toEndDate ?? "0";
        message.status = object.status ?? "";
        return message;
    },
};
function createBaseCampaignsV2Response() {
    return { campaigns: [], cursor: "" };
}
export const CampaignsV2Response = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.campaigns) {
            CampaignV2.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.cursor !== "") {
            writer.uint32(18).string(message.cursor);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignsV2Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaigns.push(CampaignV2.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.cursor = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaigns: Array.isArray(object?.campaigns) ? object.campaigns.map((e) => CampaignV2.fromJSON(e)) : [],
            cursor: isSet(object.cursor) ? String(object.cursor) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.campaigns) {
            obj.campaigns = message.campaigns.map((e) => e ? CampaignV2.toJSON(e) : undefined);
        }
        else {
            obj.campaigns = [];
        }
        message.cursor !== undefined && (obj.cursor = message.cursor);
        return obj;
    },
    create(base) {
        return CampaignsV2Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignsV2Response();
        message.campaigns = object.campaigns?.map((e) => CampaignV2.fromPartial(e)) || [];
        message.cursor = object.cursor ?? "";
        return message;
    },
};
function createBaseCampaignV2() {
    return {
        campaignId: "",
        marketId: "",
        totalScore: "",
        createdAt: "0",
        updatedAt: "0",
        startDate: "0",
        endDate: "0",
        isClaimable: false,
        roundId: 0,
        managerContract: "",
        rewards: [],
        subaccountIdSuffix: "",
        rewardContract: "",
        type: "",
        version: "",
        name: "",
        description: "",
    };
}
export const CampaignV2 = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignId !== "") {
            writer.uint32(10).string(message.campaignId);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.totalScore !== "") {
            writer.uint32(34).string(message.totalScore);
        }
        if (message.createdAt !== "0") {
            writer.uint32(40).sint64(message.createdAt);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(48).sint64(message.updatedAt);
        }
        if (message.startDate !== "0") {
            writer.uint32(56).sint64(message.startDate);
        }
        if (message.endDate !== "0") {
            writer.uint32(64).sint64(message.endDate);
        }
        if (message.isClaimable === true) {
            writer.uint32(72).bool(message.isClaimable);
        }
        if (message.roundId !== 0) {
            writer.uint32(80).sint32(message.roundId);
        }
        if (message.managerContract !== "") {
            writer.uint32(90).string(message.managerContract);
        }
        for (const v of message.rewards) {
            Coin.encode(v, writer.uint32(98).fork()).ldelim();
        }
        if (message.subaccountIdSuffix !== "") {
            writer.uint32(106).string(message.subaccountIdSuffix);
        }
        if (message.rewardContract !== "") {
            writer.uint32(114).string(message.rewardContract);
        }
        if (message.type !== "") {
            writer.uint32(122).string(message.type);
        }
        if (message.version !== "") {
            writer.uint32(130).string(message.version);
        }
        if (message.name !== "") {
            writer.uint32(138).string(message.name);
        }
        if (message.description !== "") {
            writer.uint32(146).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignV2();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignId = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.totalScore = reader.string();
                    break;
                case 5:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 6:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 7:
                    message.startDate = longToString(reader.sint64());
                    break;
                case 8:
                    message.endDate = longToString(reader.sint64());
                    break;
                case 9:
                    message.isClaimable = reader.bool();
                    break;
                case 10:
                    message.roundId = reader.sint32();
                    break;
                case 11:
                    message.managerContract = reader.string();
                    break;
                case 12:
                    message.rewards.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.subaccountIdSuffix = reader.string();
                    break;
                case 14:
                    message.rewardContract = reader.string();
                    break;
                case 15:
                    message.type = reader.string();
                    break;
                case 16:
                    message.version = reader.string();
                    break;
                case 17:
                    message.name = reader.string();
                    break;
                case 18:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            totalScore: isSet(object.totalScore) ? String(object.totalScore) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            startDate: isSet(object.startDate) ? String(object.startDate) : "0",
            endDate: isSet(object.endDate) ? String(object.endDate) : "0",
            isClaimable: isSet(object.isClaimable) ? Boolean(object.isClaimable) : false,
            roundId: isSet(object.roundId) ? Number(object.roundId) : 0,
            managerContract: isSet(object.managerContract) ? String(object.managerContract) : "",
            rewards: Array.isArray(object?.rewards) ? object.rewards.map((e) => Coin.fromJSON(e)) : [],
            subaccountIdSuffix: isSet(object.subaccountIdSuffix) ? String(object.subaccountIdSuffix) : "",
            rewardContract: isSet(object.rewardContract) ? String(object.rewardContract) : "",
            type: isSet(object.type) ? String(object.type) : "",
            version: isSet(object.version) ? String(object.version) : "",
            name: isSet(object.name) ? String(object.name) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.totalScore !== undefined && (obj.totalScore = message.totalScore);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.startDate !== undefined && (obj.startDate = message.startDate);
        message.endDate !== undefined && (obj.endDate = message.endDate);
        message.isClaimable !== undefined && (obj.isClaimable = message.isClaimable);
        message.roundId !== undefined && (obj.roundId = Math.round(message.roundId));
        message.managerContract !== undefined && (obj.managerContract = message.managerContract);
        if (message.rewards) {
            obj.rewards = message.rewards.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.rewards = [];
        }
        message.subaccountIdSuffix !== undefined && (obj.subaccountIdSuffix = message.subaccountIdSuffix);
        message.rewardContract !== undefined && (obj.rewardContract = message.rewardContract);
        message.type !== undefined && (obj.type = message.type);
        message.version !== undefined && (obj.version = message.version);
        message.name !== undefined && (obj.name = message.name);
        message.description !== undefined && (obj.description = message.description);
        return obj;
    },
    create(base) {
        return CampaignV2.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignV2();
        message.campaignId = object.campaignId ?? "";
        message.marketId = object.marketId ?? "";
        message.totalScore = object.totalScore ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.updatedAt = object.updatedAt ?? "0";
        message.startDate = object.startDate ?? "0";
        message.endDate = object.endDate ?? "0";
        message.isClaimable = object.isClaimable ?? false;
        message.roundId = object.roundId ?? 0;
        message.managerContract = object.managerContract ?? "";
        message.rewards = object.rewards?.map((e) => Coin.fromPartial(e)) || [];
        message.subaccountIdSuffix = object.subaccountIdSuffix ?? "";
        message.rewardContract = object.rewardContract ?? "";
        message.type = object.type ?? "";
        message.version = object.version ?? "";
        message.name = object.name ?? "";
        message.description = object.description ?? "";
        return message;
    },
};
function createBaseListGuildsRequest() {
    return { campaignContract: "", limit: 0, skip: 0, sortBy: "" };
}
export const ListGuildsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignContract !== "") {
            writer.uint32(10).string(message.campaignContract);
        }
        if (message.limit !== 0) {
            writer.uint32(16).sint32(message.limit);
        }
        if (message.skip !== 0) {
            writer.uint32(24).sint32(message.skip);
        }
        if (message.sortBy !== "") {
            writer.uint32(34).string(message.sortBy);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListGuildsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignContract = reader.string();
                    break;
                case 2:
                    message.limit = reader.sint32();
                    break;
                case 3:
                    message.skip = reader.sint32();
                    break;
                case 4:
                    message.sortBy = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignContract: isSet(object.campaignContract) ? String(object.campaignContract) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? Number(object.skip) : 0,
            sortBy: isSet(object.sortBy) ? String(object.sortBy) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignContract !== undefined && (obj.campaignContract = message.campaignContract);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = Math.round(message.skip));
        message.sortBy !== undefined && (obj.sortBy = message.sortBy);
        return obj;
    },
    create(base) {
        return ListGuildsRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListGuildsRequest();
        message.campaignContract = object.campaignContract ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? 0;
        message.sortBy = object.sortBy ?? "";
        return message;
    },
};
function createBaseListGuildsResponse() {
    return { guilds: [], paging: undefined, updatedAt: "0", campaignSummary: undefined };
}
export const ListGuildsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.guilds) {
            Guild.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        if (message.updatedAt !== "0") {
            writer.uint32(24).sint64(message.updatedAt);
        }
        if (message.campaignSummary !== undefined) {
            CampaignSummary.encode(message.campaignSummary, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListGuildsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.guilds.push(Guild.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 4:
                    message.campaignSummary = CampaignSummary.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            guilds: Array.isArray(object?.guilds) ? object.guilds.map((e) => Guild.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            campaignSummary: isSet(object.campaignSummary) ? CampaignSummary.fromJSON(object.campaignSummary) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.guilds) {
            obj.guilds = message.guilds.map((e) => e ? Guild.toJSON(e) : undefined);
        }
        else {
            obj.guilds = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.campaignSummary !== undefined &&
            (obj.campaignSummary = message.campaignSummary ? CampaignSummary.toJSON(message.campaignSummary) : undefined);
        return obj;
    },
    create(base) {
        return ListGuildsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListGuildsResponse();
        message.guilds = object.guilds?.map((e) => Guild.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.updatedAt = object.updatedAt ?? "0";
        message.campaignSummary = (object.campaignSummary !== undefined && object.campaignSummary !== null)
            ? CampaignSummary.fromPartial(object.campaignSummary)
            : undefined;
        return message;
    },
};
function createBaseGuild() {
    return {
        campaignContract: "",
        guildId: "",
        masterAddress: "",
        createdAt: "0",
        tvlScore: "",
        volumeScore: "",
        rankByVolume: 0,
        rankByTvl: 0,
        logo: "",
        totalTvl: "",
        updatedAt: "0",
        name: "",
        isActive: false,
        masterBalance: "",
        description: "",
    };
}
export const Guild = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignContract !== "") {
            writer.uint32(10).string(message.campaignContract);
        }
        if (message.guildId !== "") {
            writer.uint32(18).string(message.guildId);
        }
        if (message.masterAddress !== "") {
            writer.uint32(26).string(message.masterAddress);
        }
        if (message.createdAt !== "0") {
            writer.uint32(32).sint64(message.createdAt);
        }
        if (message.tvlScore !== "") {
            writer.uint32(42).string(message.tvlScore);
        }
        if (message.volumeScore !== "") {
            writer.uint32(50).string(message.volumeScore);
        }
        if (message.rankByVolume !== 0) {
            writer.uint32(56).sint32(message.rankByVolume);
        }
        if (message.rankByTvl !== 0) {
            writer.uint32(64).sint32(message.rankByTvl);
        }
        if (message.logo !== "") {
            writer.uint32(74).string(message.logo);
        }
        if (message.totalTvl !== "") {
            writer.uint32(82).string(message.totalTvl);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(88).sint64(message.updatedAt);
        }
        if (message.name !== "") {
            writer.uint32(114).string(message.name);
        }
        if (message.isActive === true) {
            writer.uint32(104).bool(message.isActive);
        }
        if (message.masterBalance !== "") {
            writer.uint32(122).string(message.masterBalance);
        }
        if (message.description !== "") {
            writer.uint32(130).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGuild();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignContract = reader.string();
                    break;
                case 2:
                    message.guildId = reader.string();
                    break;
                case 3:
                    message.masterAddress = reader.string();
                    break;
                case 4:
                    message.createdAt = longToString(reader.sint64());
                    break;
                case 5:
                    message.tvlScore = reader.string();
                    break;
                case 6:
                    message.volumeScore = reader.string();
                    break;
                case 7:
                    message.rankByVolume = reader.sint32();
                    break;
                case 8:
                    message.rankByTvl = reader.sint32();
                    break;
                case 9:
                    message.logo = reader.string();
                    break;
                case 10:
                    message.totalTvl = reader.string();
                    break;
                case 11:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 14:
                    message.name = reader.string();
                    break;
                case 13:
                    message.isActive = reader.bool();
                    break;
                case 15:
                    message.masterBalance = reader.string();
                    break;
                case 16:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignContract: isSet(object.campaignContract) ? String(object.campaignContract) : "",
            guildId: isSet(object.guildId) ? String(object.guildId) : "",
            masterAddress: isSet(object.masterAddress) ? String(object.masterAddress) : "",
            createdAt: isSet(object.createdAt) ? String(object.createdAt) : "0",
            tvlScore: isSet(object.tvlScore) ? String(object.tvlScore) : "",
            volumeScore: isSet(object.volumeScore) ? String(object.volumeScore) : "",
            rankByVolume: isSet(object.rankByVolume) ? Number(object.rankByVolume) : 0,
            rankByTvl: isSet(object.rankByTvl) ? Number(object.rankByTvl) : 0,
            logo: isSet(object.logo) ? String(object.logo) : "",
            totalTvl: isSet(object.totalTvl) ? String(object.totalTvl) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            name: isSet(object.name) ? String(object.name) : "",
            isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
            masterBalance: isSet(object.masterBalance) ? String(object.masterBalance) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignContract !== undefined && (obj.campaignContract = message.campaignContract);
        message.guildId !== undefined && (obj.guildId = message.guildId);
        message.masterAddress !== undefined && (obj.masterAddress = message.masterAddress);
        message.createdAt !== undefined && (obj.createdAt = message.createdAt);
        message.tvlScore !== undefined && (obj.tvlScore = message.tvlScore);
        message.volumeScore !== undefined && (obj.volumeScore = message.volumeScore);
        message.rankByVolume !== undefined && (obj.rankByVolume = Math.round(message.rankByVolume));
        message.rankByTvl !== undefined && (obj.rankByTvl = Math.round(message.rankByTvl));
        message.logo !== undefined && (obj.logo = message.logo);
        message.totalTvl !== undefined && (obj.totalTvl = message.totalTvl);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.name !== undefined && (obj.name = message.name);
        message.isActive !== undefined && (obj.isActive = message.isActive);
        message.masterBalance !== undefined && (obj.masterBalance = message.masterBalance);
        message.description !== undefined && (obj.description = message.description);
        return obj;
    },
    create(base) {
        return Guild.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGuild();
        message.campaignContract = object.campaignContract ?? "";
        message.guildId = object.guildId ?? "";
        message.masterAddress = object.masterAddress ?? "";
        message.createdAt = object.createdAt ?? "0";
        message.tvlScore = object.tvlScore ?? "";
        message.volumeScore = object.volumeScore ?? "";
        message.rankByVolume = object.rankByVolume ?? 0;
        message.rankByTvl = object.rankByTvl ?? 0;
        message.logo = object.logo ?? "";
        message.totalTvl = object.totalTvl ?? "";
        message.updatedAt = object.updatedAt ?? "0";
        message.name = object.name ?? "";
        message.isActive = object.isActive ?? false;
        message.masterBalance = object.masterBalance ?? "";
        message.description = object.description ?? "";
        return message;
    },
};
function createBaseCampaignSummary() {
    return {
        campaignId: "",
        campaignContract: "",
        totalGuildsCount: 0,
        totalTvl: "",
        totalAverageTvl: "",
        totalVolume: "",
        updatedAt: "0",
        totalMembersCount: 0,
        startTime: "0",
        endTime: "0",
    };
}
export const CampaignSummary = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignId !== "") {
            writer.uint32(10).string(message.campaignId);
        }
        if (message.campaignContract !== "") {
            writer.uint32(18).string(message.campaignContract);
        }
        if (message.totalGuildsCount !== 0) {
            writer.uint32(24).sint32(message.totalGuildsCount);
        }
        if (message.totalTvl !== "") {
            writer.uint32(34).string(message.totalTvl);
        }
        if (message.totalAverageTvl !== "") {
            writer.uint32(42).string(message.totalAverageTvl);
        }
        if (message.totalVolume !== "") {
            writer.uint32(50).string(message.totalVolume);
        }
        if (message.updatedAt !== "0") {
            writer.uint32(56).sint64(message.updatedAt);
        }
        if (message.totalMembersCount !== 0) {
            writer.uint32(64).sint32(message.totalMembersCount);
        }
        if (message.startTime !== "0") {
            writer.uint32(72).sint64(message.startTime);
        }
        if (message.endTime !== "0") {
            writer.uint32(80).sint64(message.endTime);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaignSummary();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignId = reader.string();
                    break;
                case 2:
                    message.campaignContract = reader.string();
                    break;
                case 3:
                    message.totalGuildsCount = reader.sint32();
                    break;
                case 4:
                    message.totalTvl = reader.string();
                    break;
                case 5:
                    message.totalAverageTvl = reader.string();
                    break;
                case 6:
                    message.totalVolume = reader.string();
                    break;
                case 7:
                    message.updatedAt = longToString(reader.sint64());
                    break;
                case 8:
                    message.totalMembersCount = reader.sint32();
                    break;
                case 9:
                    message.startTime = longToString(reader.sint64());
                    break;
                case 10:
                    message.endTime = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignId: isSet(object.campaignId) ? String(object.campaignId) : "",
            campaignContract: isSet(object.campaignContract) ? String(object.campaignContract) : "",
            totalGuildsCount: isSet(object.totalGuildsCount) ? Number(object.totalGuildsCount) : 0,
            totalTvl: isSet(object.totalTvl) ? String(object.totalTvl) : "",
            totalAverageTvl: isSet(object.totalAverageTvl) ? String(object.totalAverageTvl) : "",
            totalVolume: isSet(object.totalVolume) ? String(object.totalVolume) : "",
            updatedAt: isSet(object.updatedAt) ? String(object.updatedAt) : "0",
            totalMembersCount: isSet(object.totalMembersCount) ? Number(object.totalMembersCount) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "0",
            endTime: isSet(object.endTime) ? String(object.endTime) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignId !== undefined && (obj.campaignId = message.campaignId);
        message.campaignContract !== undefined && (obj.campaignContract = message.campaignContract);
        message.totalGuildsCount !== undefined && (obj.totalGuildsCount = Math.round(message.totalGuildsCount));
        message.totalTvl !== undefined && (obj.totalTvl = message.totalTvl);
        message.totalAverageTvl !== undefined && (obj.totalAverageTvl = message.totalAverageTvl);
        message.totalVolume !== undefined && (obj.totalVolume = message.totalVolume);
        message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt);
        message.totalMembersCount !== undefined && (obj.totalMembersCount = Math.round(message.totalMembersCount));
        message.startTime !== undefined && (obj.startTime = message.startTime);
        message.endTime !== undefined && (obj.endTime = message.endTime);
        return obj;
    },
    create(base) {
        return CampaignSummary.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCampaignSummary();
        message.campaignId = object.campaignId ?? "";
        message.campaignContract = object.campaignContract ?? "";
        message.totalGuildsCount = object.totalGuildsCount ?? 0;
        message.totalTvl = object.totalTvl ?? "";
        message.totalAverageTvl = object.totalAverageTvl ?? "";
        message.totalVolume = object.totalVolume ?? "";
        message.updatedAt = object.updatedAt ?? "0";
        message.totalMembersCount = object.totalMembersCount ?? 0;
        message.startTime = object.startTime ?? "0";
        message.endTime = object.endTime ?? "0";
        return message;
    },
};
function createBaseListGuildMembersRequest() {
    return { campaignContract: "", guildId: "", limit: 0, skip: 0, includeGuildInfo: false, sortBy: "" };
}
export const ListGuildMembersRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignContract !== "") {
            writer.uint32(10).string(message.campaignContract);
        }
        if (message.guildId !== "") {
            writer.uint32(18).string(message.guildId);
        }
        if (message.limit !== 0) {
            writer.uint32(24).sint32(message.limit);
        }
        if (message.skip !== 0) {
            writer.uint32(32).sint32(message.skip);
        }
        if (message.includeGuildInfo === true) {
            writer.uint32(40).bool(message.includeGuildInfo);
        }
        if (message.sortBy !== "") {
            writer.uint32(50).string(message.sortBy);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListGuildMembersRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignContract = reader.string();
                    break;
                case 2:
                    message.guildId = reader.string();
                    break;
                case 3:
                    message.limit = reader.sint32();
                    break;
                case 4:
                    message.skip = reader.sint32();
                    break;
                case 5:
                    message.includeGuildInfo = reader.bool();
                    break;
                case 6:
                    message.sortBy = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignContract: isSet(object.campaignContract) ? String(object.campaignContract) : "",
            guildId: isSet(object.guildId) ? String(object.guildId) : "",
            limit: isSet(object.limit) ? Number(object.limit) : 0,
            skip: isSet(object.skip) ? Number(object.skip) : 0,
            includeGuildInfo: isSet(object.includeGuildInfo) ? Boolean(object.includeGuildInfo) : false,
            sortBy: isSet(object.sortBy) ? String(object.sortBy) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignContract !== undefined && (obj.campaignContract = message.campaignContract);
        message.guildId !== undefined && (obj.guildId = message.guildId);
        message.limit !== undefined && (obj.limit = Math.round(message.limit));
        message.skip !== undefined && (obj.skip = Math.round(message.skip));
        message.includeGuildInfo !== undefined && (obj.includeGuildInfo = message.includeGuildInfo);
        message.sortBy !== undefined && (obj.sortBy = message.sortBy);
        return obj;
    },
    create(base) {
        return ListGuildMembersRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListGuildMembersRequest();
        message.campaignContract = object.campaignContract ?? "";
        message.guildId = object.guildId ?? "";
        message.limit = object.limit ?? 0;
        message.skip = object.skip ?? 0;
        message.includeGuildInfo = object.includeGuildInfo ?? false;
        message.sortBy = object.sortBy ?? "";
        return message;
    },
};
function createBaseListGuildMembersResponse() {
    return { members: [], paging: undefined, guildInfo: undefined };
}
export const ListGuildMembersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.members) {
            GuildMember.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.paging !== undefined) {
            Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
        }
        if (message.guildInfo !== undefined) {
            Guild.encode(message.guildInfo, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListGuildMembersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.members.push(GuildMember.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.paging = Paging.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.guildInfo = Guild.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            members: Array.isArray(object?.members) ? object.members.map((e) => GuildMember.fromJSON(e)) : [],
            paging: isSet(object.paging) ? Paging.fromJSON(object.paging) : undefined,
            guildInfo: isSet(object.guildInfo) ? Guild.fromJSON(object.guildInfo) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.members) {
            obj.members = message.members.map((e) => e ? GuildMember.toJSON(e) : undefined);
        }
        else {
            obj.members = [];
        }
        message.paging !== undefined && (obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined);
        message.guildInfo !== undefined &&
            (obj.guildInfo = message.guildInfo ? Guild.toJSON(message.guildInfo) : undefined);
        return obj;
    },
    create(base) {
        return ListGuildMembersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListGuildMembersResponse();
        message.members = object.members?.map((e) => GuildMember.fromPartial(e)) || [];
        message.paging = (object.paging !== undefined && object.paging !== null)
            ? Paging.fromPartial(object.paging)
            : undefined;
        message.guildInfo = (object.guildInfo !== undefined && object.guildInfo !== null)
            ? Guild.fromPartial(object.guildInfo)
            : undefined;
        return message;
    },
};
function createBaseGuildMember() {
    return {
        campaignContract: "",
        guildId: "",
        address: "",
        joinedAt: "0",
        tvlScore: "",
        volumeScore: "",
        totalTvl: "",
        volumeScorePercentage: 0,
        tvlScorePercentage: 0,
        tvlReward: [],
        volumeReward: [],
    };
}
export const GuildMember = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignContract !== "") {
            writer.uint32(10).string(message.campaignContract);
        }
        if (message.guildId !== "") {
            writer.uint32(18).string(message.guildId);
        }
        if (message.address !== "") {
            writer.uint32(26).string(message.address);
        }
        if (message.joinedAt !== "0") {
            writer.uint32(32).sint64(message.joinedAt);
        }
        if (message.tvlScore !== "") {
            writer.uint32(42).string(message.tvlScore);
        }
        if (message.volumeScore !== "") {
            writer.uint32(50).string(message.volumeScore);
        }
        if (message.totalTvl !== "") {
            writer.uint32(58).string(message.totalTvl);
        }
        if (message.volumeScorePercentage !== 0) {
            writer.uint32(65).double(message.volumeScorePercentage);
        }
        if (message.tvlScorePercentage !== 0) {
            writer.uint32(73).double(message.tvlScorePercentage);
        }
        for (const v of message.tvlReward) {
            Coin.encode(v, writer.uint32(82).fork()).ldelim();
        }
        for (const v of message.volumeReward) {
            Coin.encode(v, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGuildMember();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignContract = reader.string();
                    break;
                case 2:
                    message.guildId = reader.string();
                    break;
                case 3:
                    message.address = reader.string();
                    break;
                case 4:
                    message.joinedAt = longToString(reader.sint64());
                    break;
                case 5:
                    message.tvlScore = reader.string();
                    break;
                case 6:
                    message.volumeScore = reader.string();
                    break;
                case 7:
                    message.totalTvl = reader.string();
                    break;
                case 8:
                    message.volumeScorePercentage = reader.double();
                    break;
                case 9:
                    message.tvlScorePercentage = reader.double();
                    break;
                case 10:
                    message.tvlReward.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.volumeReward.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignContract: isSet(object.campaignContract) ? String(object.campaignContract) : "",
            guildId: isSet(object.guildId) ? String(object.guildId) : "",
            address: isSet(object.address) ? String(object.address) : "",
            joinedAt: isSet(object.joinedAt) ? String(object.joinedAt) : "0",
            tvlScore: isSet(object.tvlScore) ? String(object.tvlScore) : "",
            volumeScore: isSet(object.volumeScore) ? String(object.volumeScore) : "",
            totalTvl: isSet(object.totalTvl) ? String(object.totalTvl) : "",
            volumeScorePercentage: isSet(object.volumeScorePercentage) ? Number(object.volumeScorePercentage) : 0,
            tvlScorePercentage: isSet(object.tvlScorePercentage) ? Number(object.tvlScorePercentage) : 0,
            tvlReward: Array.isArray(object?.tvlReward) ? object.tvlReward.map((e) => Coin.fromJSON(e)) : [],
            volumeReward: Array.isArray(object?.volumeReward) ? object.volumeReward.map((e) => Coin.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignContract !== undefined && (obj.campaignContract = message.campaignContract);
        message.guildId !== undefined && (obj.guildId = message.guildId);
        message.address !== undefined && (obj.address = message.address);
        message.joinedAt !== undefined && (obj.joinedAt = message.joinedAt);
        message.tvlScore !== undefined && (obj.tvlScore = message.tvlScore);
        message.volumeScore !== undefined && (obj.volumeScore = message.volumeScore);
        message.totalTvl !== undefined && (obj.totalTvl = message.totalTvl);
        message.volumeScorePercentage !== undefined && (obj.volumeScorePercentage = message.volumeScorePercentage);
        message.tvlScorePercentage !== undefined && (obj.tvlScorePercentage = message.tvlScorePercentage);
        if (message.tvlReward) {
            obj.tvlReward = message.tvlReward.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.tvlReward = [];
        }
        if (message.volumeReward) {
            obj.volumeReward = message.volumeReward.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.volumeReward = [];
        }
        return obj;
    },
    create(base) {
        return GuildMember.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGuildMember();
        message.campaignContract = object.campaignContract ?? "";
        message.guildId = object.guildId ?? "";
        message.address = object.address ?? "";
        message.joinedAt = object.joinedAt ?? "0";
        message.tvlScore = object.tvlScore ?? "";
        message.volumeScore = object.volumeScore ?? "";
        message.totalTvl = object.totalTvl ?? "";
        message.volumeScorePercentage = object.volumeScorePercentage ?? 0;
        message.tvlScorePercentage = object.tvlScorePercentage ?? 0;
        message.tvlReward = object.tvlReward?.map((e) => Coin.fromPartial(e)) || [];
        message.volumeReward = object.volumeReward?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetGuildMemberRequest() {
    return { campaignContract: "", address: "" };
}
export const GetGuildMemberRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignContract !== "") {
            writer.uint32(10).string(message.campaignContract);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGuildMemberRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignContract = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignContract: isSet(object.campaignContract) ? String(object.campaignContract) : "",
            address: isSet(object.address) ? String(object.address) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignContract !== undefined && (obj.campaignContract = message.campaignContract);
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return GetGuildMemberRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetGuildMemberRequest();
        message.campaignContract = object.campaignContract ?? "";
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseGetGuildMemberResponse() {
    return { info: undefined };
}
export const GetGuildMemberResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.info !== undefined) {
            GuildMember.encode(message.info, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGuildMemberResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.info = GuildMember.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { info: isSet(object.info) ? GuildMember.fromJSON(object.info) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.info !== undefined && (obj.info = message.info ? GuildMember.toJSON(message.info) : undefined);
        return obj;
    },
    create(base) {
        return GetGuildMemberResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetGuildMemberResponse();
        message.info = (object.info !== undefined && object.info !== null)
            ? GuildMember.fromPartial(object.info)
            : undefined;
        return message;
    },
};
export class InjectiveCampaignRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Ranking = this.Ranking.bind(this);
        this.Campaigns = this.Campaigns.bind(this);
        this.CampaignsV2 = this.CampaignsV2.bind(this);
        this.ListGuilds = this.ListGuilds.bind(this);
        this.ListGuildMembers = this.ListGuildMembers.bind(this);
        this.GetGuildMember = this.GetGuildMember.bind(this);
    }
    Ranking(request, metadata) {
        return this.rpc.unary(InjectiveCampaignRPCRankingDesc, RankingRequest.fromPartial(request), metadata);
    }
    Campaigns(request, metadata) {
        return this.rpc.unary(InjectiveCampaignRPCCampaignsDesc, CampaignsRequest.fromPartial(request), metadata);
    }
    CampaignsV2(request, metadata) {
        return this.rpc.unary(InjectiveCampaignRPCCampaignsV2Desc, CampaignsV2Request.fromPartial(request), metadata);
    }
    ListGuilds(request, metadata) {
        return this.rpc.unary(InjectiveCampaignRPCListGuildsDesc, ListGuildsRequest.fromPartial(request), metadata);
    }
    ListGuildMembers(request, metadata) {
        return this.rpc.unary(InjectiveCampaignRPCListGuildMembersDesc, ListGuildMembersRequest.fromPartial(request), metadata);
    }
    GetGuildMember(request, metadata) {
        return this.rpc.unary(InjectiveCampaignRPCGetGuildMemberDesc, GetGuildMemberRequest.fromPartial(request), metadata);
    }
}
export const InjectiveCampaignRPCDesc = { serviceName: "injective_campaign_rpc.InjectiveCampaignRPC" };
export const InjectiveCampaignRPCRankingDesc = {
    methodName: "Ranking",
    service: InjectiveCampaignRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return RankingRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = RankingResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveCampaignRPCCampaignsDesc = {
    methodName: "Campaigns",
    service: InjectiveCampaignRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return CampaignsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = CampaignsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveCampaignRPCCampaignsV2Desc = {
    methodName: "CampaignsV2",
    service: InjectiveCampaignRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return CampaignsV2Request.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = CampaignsV2Response.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveCampaignRPCListGuildsDesc = {
    methodName: "ListGuilds",
    service: InjectiveCampaignRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return ListGuildsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = ListGuildsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveCampaignRPCListGuildMembersDesc = {
    methodName: "ListGuildMembers",
    service: InjectiveCampaignRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return ListGuildMembersRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = ListGuildMembersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveCampaignRPCGetGuildMemberDesc = {
    methodName: "GetGuildMember",
    service: InjectiveCampaignRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetGuildMemberRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetGuildMemberResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
