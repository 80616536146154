/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "cometbft.store.v1";
function createBaseBlockStoreState() {
    return { base: "0", height: "0" };
}
export const BlockStoreState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.base !== "0") {
            writer.uint32(8).int64(message.base);
        }
        if (message.height !== "0") {
            writer.uint32(16).int64(message.height);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBlockStoreState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.base = longToString(reader.int64());
                    break;
                case 2:
                    message.height = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            base: isSet(object.base) ? String(object.base) : "0",
            height: isSet(object.height) ? String(object.height) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.base !== undefined && (obj.base = message.base);
        message.height !== undefined && (obj.height = message.height);
        return obj;
    },
    create(base) {
        return BlockStoreState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBlockStoreState();
        message.base = object.base ?? "0";
        message.height = object.height ?? "0";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
