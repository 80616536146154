/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { AccountRewards, BinaryOptionsMarket, CampaignRewardPool, DepositUpdate, DerivativeLimitOrder, DerivativeMarket, DerivativeMarketOrder, DerivativeOrder, DerivativeTradeLog, executionTypeFromJSON, executionTypeToJSON, ExpiryFuturesMarketInfo, FeeDiscountSchedule, GrantAuthorization, Level, MarketFeeMultiplier, PerpetualMarketFunding, PerpetualMarketInfo, SpotLimitOrder, SpotMarket, SubaccountPosition, TradeLog, TradingRewardCampaignInfo, } from "./exchange.js";
export const protobufPackage = "injective.exchange.v1beta1";
function createBaseEventBatchSpotExecution() {
    return { marketId: "", isBuy: false, executionType: 0, trades: [] };
}
export const EventBatchSpotExecution = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isBuy === true) {
            writer.uint32(16).bool(message.isBuy);
        }
        if (message.executionType !== 0) {
            writer.uint32(24).int32(message.executionType);
        }
        for (const v of message.trades) {
            TradeLog.encode(v, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBatchSpotExecution();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isBuy = reader.bool();
                    break;
                case 3:
                    message.executionType = reader.int32();
                    break;
                case 4:
                    message.trades.push(TradeLog.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isBuy: isSet(object.isBuy) ? Boolean(object.isBuy) : false,
            executionType: isSet(object.executionType) ? executionTypeFromJSON(object.executionType) : 0,
            trades: Array.isArray(object?.trades) ? object.trades.map((e) => TradeLog.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isBuy !== undefined && (obj.isBuy = message.isBuy);
        message.executionType !== undefined && (obj.executionType = executionTypeToJSON(message.executionType));
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? TradeLog.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        return obj;
    },
    create(base) {
        return EventBatchSpotExecution.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBatchSpotExecution();
        message.marketId = object.marketId ?? "";
        message.isBuy = object.isBuy ?? false;
        message.executionType = object.executionType ?? 0;
        message.trades = object.trades?.map((e) => TradeLog.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventBatchDerivativeExecution() {
    return { marketId: "", isBuy: false, isLiquidation: false, cumulativeFunding: "", executionType: 0, trades: [] };
}
export const EventBatchDerivativeExecution = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isBuy === true) {
            writer.uint32(16).bool(message.isBuy);
        }
        if (message.isLiquidation === true) {
            writer.uint32(24).bool(message.isLiquidation);
        }
        if (message.cumulativeFunding !== "") {
            writer.uint32(34).string(message.cumulativeFunding);
        }
        if (message.executionType !== 0) {
            writer.uint32(40).int32(message.executionType);
        }
        for (const v of message.trades) {
            DerivativeTradeLog.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBatchDerivativeExecution();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isBuy = reader.bool();
                    break;
                case 3:
                    message.isLiquidation = reader.bool();
                    break;
                case 4:
                    message.cumulativeFunding = reader.string();
                    break;
                case 5:
                    message.executionType = reader.int32();
                    break;
                case 6:
                    message.trades.push(DerivativeTradeLog.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isBuy: isSet(object.isBuy) ? Boolean(object.isBuy) : false,
            isLiquidation: isSet(object.isLiquidation) ? Boolean(object.isLiquidation) : false,
            cumulativeFunding: isSet(object.cumulativeFunding) ? String(object.cumulativeFunding) : "",
            executionType: isSet(object.executionType) ? executionTypeFromJSON(object.executionType) : 0,
            trades: Array.isArray(object?.trades) ? object.trades.map((e) => DerivativeTradeLog.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isBuy !== undefined && (obj.isBuy = message.isBuy);
        message.isLiquidation !== undefined && (obj.isLiquidation = message.isLiquidation);
        message.cumulativeFunding !== undefined && (obj.cumulativeFunding = message.cumulativeFunding);
        message.executionType !== undefined && (obj.executionType = executionTypeToJSON(message.executionType));
        if (message.trades) {
            obj.trades = message.trades.map((e) => e ? DerivativeTradeLog.toJSON(e) : undefined);
        }
        else {
            obj.trades = [];
        }
        return obj;
    },
    create(base) {
        return EventBatchDerivativeExecution.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBatchDerivativeExecution();
        message.marketId = object.marketId ?? "";
        message.isBuy = object.isBuy ?? false;
        message.isLiquidation = object.isLiquidation ?? false;
        message.cumulativeFunding = object.cumulativeFunding ?? "";
        message.executionType = object.executionType ?? 0;
        message.trades = object.trades?.map((e) => DerivativeTradeLog.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventLostFundsFromLiquidation() {
    return {
        marketId: "",
        subaccountId: new Uint8Array(),
        lostFundsFromAvailableDuringPayout: "",
        lostFundsFromOrderCancels: "",
    };
}
export const EventLostFundsFromLiquidation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.subaccountId.length !== 0) {
            writer.uint32(18).bytes(message.subaccountId);
        }
        if (message.lostFundsFromAvailableDuringPayout !== "") {
            writer.uint32(26).string(message.lostFundsFromAvailableDuringPayout);
        }
        if (message.lostFundsFromOrderCancels !== "") {
            writer.uint32(34).string(message.lostFundsFromOrderCancels);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventLostFundsFromLiquidation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.bytes();
                    break;
                case 3:
                    message.lostFundsFromAvailableDuringPayout = reader.string();
                    break;
                case 4:
                    message.lostFundsFromOrderCancels = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
            lostFundsFromAvailableDuringPayout: isSet(object.lostFundsFromAvailableDuringPayout)
                ? String(object.lostFundsFromAvailableDuringPayout)
                : "",
            lostFundsFromOrderCancels: isSet(object.lostFundsFromOrderCancels)
                ? String(object.lostFundsFromOrderCancels)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        message.lostFundsFromAvailableDuringPayout !== undefined &&
            (obj.lostFundsFromAvailableDuringPayout = message.lostFundsFromAvailableDuringPayout);
        message.lostFundsFromOrderCancels !== undefined &&
            (obj.lostFundsFromOrderCancels = message.lostFundsFromOrderCancels);
        return obj;
    },
    create(base) {
        return EventLostFundsFromLiquidation.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventLostFundsFromLiquidation();
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        message.lostFundsFromAvailableDuringPayout = object.lostFundsFromAvailableDuringPayout ?? "";
        message.lostFundsFromOrderCancels = object.lostFundsFromOrderCancels ?? "";
        return message;
    },
};
function createBaseEventBatchDerivativePosition() {
    return { marketId: "", positions: [] };
}
export const EventBatchDerivativePosition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        for (const v of message.positions) {
            SubaccountPosition.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBatchDerivativePosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.positions.push(SubaccountPosition.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            positions: Array.isArray(object?.positions)
                ? object.positions.map((e) => SubaccountPosition.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.positions) {
            obj.positions = message.positions.map((e) => e ? SubaccountPosition.toJSON(e) : undefined);
        }
        else {
            obj.positions = [];
        }
        return obj;
    },
    create(base) {
        return EventBatchDerivativePosition.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBatchDerivativePosition();
        message.marketId = object.marketId ?? "";
        message.positions = object.positions?.map((e) => SubaccountPosition.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventDerivativeMarketPaused() {
    return { marketId: "", settlePrice: "", totalMissingFunds: "", missingFundsRate: "" };
}
export const EventDerivativeMarketPaused = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.settlePrice !== "") {
            writer.uint32(18).string(message.settlePrice);
        }
        if (message.totalMissingFunds !== "") {
            writer.uint32(26).string(message.totalMissingFunds);
        }
        if (message.missingFundsRate !== "") {
            writer.uint32(34).string(message.missingFundsRate);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventDerivativeMarketPaused();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.settlePrice = reader.string();
                    break;
                case 3:
                    message.totalMissingFunds = reader.string();
                    break;
                case 4:
                    message.missingFundsRate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            settlePrice: isSet(object.settlePrice) ? String(object.settlePrice) : "",
            totalMissingFunds: isSet(object.totalMissingFunds) ? String(object.totalMissingFunds) : "",
            missingFundsRate: isSet(object.missingFundsRate) ? String(object.missingFundsRate) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.settlePrice !== undefined && (obj.settlePrice = message.settlePrice);
        message.totalMissingFunds !== undefined && (obj.totalMissingFunds = message.totalMissingFunds);
        message.missingFundsRate !== undefined && (obj.missingFundsRate = message.missingFundsRate);
        return obj;
    },
    create(base) {
        return EventDerivativeMarketPaused.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventDerivativeMarketPaused();
        message.marketId = object.marketId ?? "";
        message.settlePrice = object.settlePrice ?? "";
        message.totalMissingFunds = object.totalMissingFunds ?? "";
        message.missingFundsRate = object.missingFundsRate ?? "";
        return message;
    },
};
function createBaseEventMarketBeyondBankruptcy() {
    return { marketId: "", settlePrice: "", missingMarketFunds: "" };
}
export const EventMarketBeyondBankruptcy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.settlePrice !== "") {
            writer.uint32(18).string(message.settlePrice);
        }
        if (message.missingMarketFunds !== "") {
            writer.uint32(26).string(message.missingMarketFunds);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventMarketBeyondBankruptcy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.settlePrice = reader.string();
                    break;
                case 3:
                    message.missingMarketFunds = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            settlePrice: isSet(object.settlePrice) ? String(object.settlePrice) : "",
            missingMarketFunds: isSet(object.missingMarketFunds) ? String(object.missingMarketFunds) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.settlePrice !== undefined && (obj.settlePrice = message.settlePrice);
        message.missingMarketFunds !== undefined && (obj.missingMarketFunds = message.missingMarketFunds);
        return obj;
    },
    create(base) {
        return EventMarketBeyondBankruptcy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventMarketBeyondBankruptcy();
        message.marketId = object.marketId ?? "";
        message.settlePrice = object.settlePrice ?? "";
        message.missingMarketFunds = object.missingMarketFunds ?? "";
        return message;
    },
};
function createBaseEventAllPositionsHaircut() {
    return { marketId: "", settlePrice: "", missingFundsRate: "" };
}
export const EventAllPositionsHaircut = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.settlePrice !== "") {
            writer.uint32(18).string(message.settlePrice);
        }
        if (message.missingFundsRate !== "") {
            writer.uint32(26).string(message.missingFundsRate);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAllPositionsHaircut();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.settlePrice = reader.string();
                    break;
                case 3:
                    message.missingFundsRate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            settlePrice: isSet(object.settlePrice) ? String(object.settlePrice) : "",
            missingFundsRate: isSet(object.missingFundsRate) ? String(object.missingFundsRate) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.settlePrice !== undefined && (obj.settlePrice = message.settlePrice);
        message.missingFundsRate !== undefined && (obj.missingFundsRate = message.missingFundsRate);
        return obj;
    },
    create(base) {
        return EventAllPositionsHaircut.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAllPositionsHaircut();
        message.marketId = object.marketId ?? "";
        message.settlePrice = object.settlePrice ?? "";
        message.missingFundsRate = object.missingFundsRate ?? "";
        return message;
    },
};
function createBaseEventBinaryOptionsMarketUpdate() {
    return { market: undefined };
}
export const EventBinaryOptionsMarketUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            BinaryOptionsMarket.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBinaryOptionsMarketUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = BinaryOptionsMarket.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { market: isSet(object.market) ? BinaryOptionsMarket.fromJSON(object.market) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined &&
            (obj.market = message.market ? BinaryOptionsMarket.toJSON(message.market) : undefined);
        return obj;
    },
    create(base) {
        return EventBinaryOptionsMarketUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBinaryOptionsMarketUpdate();
        message.market = (object.market !== undefined && object.market !== null)
            ? BinaryOptionsMarket.fromPartial(object.market)
            : undefined;
        return message;
    },
};
function createBaseEventNewSpotOrders() {
    return { marketId: "", buyOrders: [], sellOrders: [] };
}
export const EventNewSpotOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        for (const v of message.buyOrders) {
            SpotLimitOrder.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.sellOrders) {
            SpotLimitOrder.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventNewSpotOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.buyOrders.push(SpotLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sellOrders.push(SpotLimitOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            buyOrders: Array.isArray(object?.buyOrders) ? object.buyOrders.map((e) => SpotLimitOrder.fromJSON(e)) : [],
            sellOrders: Array.isArray(object?.sellOrders)
                ? object.sellOrders.map((e) => SpotLimitOrder.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.buyOrders) {
            obj.buyOrders = message.buyOrders.map((e) => e ? SpotLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.buyOrders = [];
        }
        if (message.sellOrders) {
            obj.sellOrders = message.sellOrders.map((e) => e ? SpotLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.sellOrders = [];
        }
        return obj;
    },
    create(base) {
        return EventNewSpotOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventNewSpotOrders();
        message.marketId = object.marketId ?? "";
        message.buyOrders = object.buyOrders?.map((e) => SpotLimitOrder.fromPartial(e)) || [];
        message.sellOrders = object.sellOrders?.map((e) => SpotLimitOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventNewDerivativeOrders() {
    return { marketId: "", buyOrders: [], sellOrders: [] };
}
export const EventNewDerivativeOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        for (const v of message.buyOrders) {
            DerivativeLimitOrder.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.sellOrders) {
            DerivativeLimitOrder.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventNewDerivativeOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.buyOrders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sellOrders.push(DerivativeLimitOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            buyOrders: Array.isArray(object?.buyOrders)
                ? object.buyOrders.map((e) => DerivativeLimitOrder.fromJSON(e))
                : [],
            sellOrders: Array.isArray(object?.sellOrders)
                ? object.sellOrders.map((e) => DerivativeLimitOrder.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        if (message.buyOrders) {
            obj.buyOrders = message.buyOrders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.buyOrders = [];
        }
        if (message.sellOrders) {
            obj.sellOrders = message.sellOrders.map((e) => e ? DerivativeLimitOrder.toJSON(e) : undefined);
        }
        else {
            obj.sellOrders = [];
        }
        return obj;
    },
    create(base) {
        return EventNewDerivativeOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventNewDerivativeOrders();
        message.marketId = object.marketId ?? "";
        message.buyOrders = object.buyOrders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        message.sellOrders = object.sellOrders?.map((e) => DerivativeLimitOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventCancelSpotOrder() {
    return { marketId: "", order: undefined };
}
export const EventCancelSpotOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.order !== undefined) {
            SpotLimitOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventCancelSpotOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.order = SpotLimitOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            order: isSet(object.order) ? SpotLimitOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.order !== undefined && (obj.order = message.order ? SpotLimitOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return EventCancelSpotOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventCancelSpotOrder();
        message.marketId = object.marketId ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? SpotLimitOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseEventSpotMarketUpdate() {
    return { market: undefined };
}
export const EventSpotMarketUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            SpotMarket.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSpotMarketUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = SpotMarket.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { market: isSet(object.market) ? SpotMarket.fromJSON(object.market) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined && (obj.market = message.market ? SpotMarket.toJSON(message.market) : undefined);
        return obj;
    },
    create(base) {
        return EventSpotMarketUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSpotMarketUpdate();
        message.market = (object.market !== undefined && object.market !== null)
            ? SpotMarket.fromPartial(object.market)
            : undefined;
        return message;
    },
};
function createBaseEventPerpetualMarketUpdate() {
    return { market: undefined, perpetualMarketInfo: undefined, funding: undefined };
}
export const EventPerpetualMarketUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            DerivativeMarket.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        if (message.perpetualMarketInfo !== undefined) {
            PerpetualMarketInfo.encode(message.perpetualMarketInfo, writer.uint32(18).fork()).ldelim();
        }
        if (message.funding !== undefined) {
            PerpetualMarketFunding.encode(message.funding, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventPerpetualMarketUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = DerivativeMarket.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.perpetualMarketInfo = PerpetualMarketInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.funding = PerpetualMarketFunding.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            market: isSet(object.market) ? DerivativeMarket.fromJSON(object.market) : undefined,
            perpetualMarketInfo: isSet(object.perpetualMarketInfo)
                ? PerpetualMarketInfo.fromJSON(object.perpetualMarketInfo)
                : undefined,
            funding: isSet(object.funding) ? PerpetualMarketFunding.fromJSON(object.funding) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined && (obj.market = message.market ? DerivativeMarket.toJSON(message.market) : undefined);
        message.perpetualMarketInfo !== undefined && (obj.perpetualMarketInfo = message.perpetualMarketInfo
            ? PerpetualMarketInfo.toJSON(message.perpetualMarketInfo)
            : undefined);
        message.funding !== undefined &&
            (obj.funding = message.funding ? PerpetualMarketFunding.toJSON(message.funding) : undefined);
        return obj;
    },
    create(base) {
        return EventPerpetualMarketUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventPerpetualMarketUpdate();
        message.market = (object.market !== undefined && object.market !== null)
            ? DerivativeMarket.fromPartial(object.market)
            : undefined;
        message.perpetualMarketInfo = (object.perpetualMarketInfo !== undefined && object.perpetualMarketInfo !== null)
            ? PerpetualMarketInfo.fromPartial(object.perpetualMarketInfo)
            : undefined;
        message.funding = (object.funding !== undefined && object.funding !== null)
            ? PerpetualMarketFunding.fromPartial(object.funding)
            : undefined;
        return message;
    },
};
function createBaseEventExpiryFuturesMarketUpdate() {
    return { market: undefined, expiryFuturesMarketInfo: undefined };
}
export const EventExpiryFuturesMarketUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.market !== undefined) {
            DerivativeMarket.encode(message.market, writer.uint32(10).fork()).ldelim();
        }
        if (message.expiryFuturesMarketInfo !== undefined) {
            ExpiryFuturesMarketInfo.encode(message.expiryFuturesMarketInfo, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventExpiryFuturesMarketUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.market = DerivativeMarket.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.expiryFuturesMarketInfo = ExpiryFuturesMarketInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            market: isSet(object.market) ? DerivativeMarket.fromJSON(object.market) : undefined,
            expiryFuturesMarketInfo: isSet(object.expiryFuturesMarketInfo)
                ? ExpiryFuturesMarketInfo.fromJSON(object.expiryFuturesMarketInfo)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.market !== undefined && (obj.market = message.market ? DerivativeMarket.toJSON(message.market) : undefined);
        message.expiryFuturesMarketInfo !== undefined && (obj.expiryFuturesMarketInfo = message.expiryFuturesMarketInfo
            ? ExpiryFuturesMarketInfo.toJSON(message.expiryFuturesMarketInfo)
            : undefined);
        return obj;
    },
    create(base) {
        return EventExpiryFuturesMarketUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventExpiryFuturesMarketUpdate();
        message.market = (object.market !== undefined && object.market !== null)
            ? DerivativeMarket.fromPartial(object.market)
            : undefined;
        message.expiryFuturesMarketInfo =
            (object.expiryFuturesMarketInfo !== undefined && object.expiryFuturesMarketInfo !== null)
                ? ExpiryFuturesMarketInfo.fromPartial(object.expiryFuturesMarketInfo)
                : undefined;
        return message;
    },
};
function createBaseEventPerpetualMarketFundingUpdate() {
    return { marketId: "", funding: undefined, isHourlyFunding: false, fundingRate: "", markPrice: "" };
}
export const EventPerpetualMarketFundingUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.funding !== undefined) {
            PerpetualMarketFunding.encode(message.funding, writer.uint32(18).fork()).ldelim();
        }
        if (message.isHourlyFunding === true) {
            writer.uint32(24).bool(message.isHourlyFunding);
        }
        if (message.fundingRate !== "") {
            writer.uint32(34).string(message.fundingRate);
        }
        if (message.markPrice !== "") {
            writer.uint32(42).string(message.markPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventPerpetualMarketFundingUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.funding = PerpetualMarketFunding.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.isHourlyFunding = reader.bool();
                    break;
                case 4:
                    message.fundingRate = reader.string();
                    break;
                case 5:
                    message.markPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            funding: isSet(object.funding) ? PerpetualMarketFunding.fromJSON(object.funding) : undefined,
            isHourlyFunding: isSet(object.isHourlyFunding) ? Boolean(object.isHourlyFunding) : false,
            fundingRate: isSet(object.fundingRate) ? String(object.fundingRate) : "",
            markPrice: isSet(object.markPrice) ? String(object.markPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.funding !== undefined &&
            (obj.funding = message.funding ? PerpetualMarketFunding.toJSON(message.funding) : undefined);
        message.isHourlyFunding !== undefined && (obj.isHourlyFunding = message.isHourlyFunding);
        message.fundingRate !== undefined && (obj.fundingRate = message.fundingRate);
        message.markPrice !== undefined && (obj.markPrice = message.markPrice);
        return obj;
    },
    create(base) {
        return EventPerpetualMarketFundingUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventPerpetualMarketFundingUpdate();
        message.marketId = object.marketId ?? "";
        message.funding = (object.funding !== undefined && object.funding !== null)
            ? PerpetualMarketFunding.fromPartial(object.funding)
            : undefined;
        message.isHourlyFunding = object.isHourlyFunding ?? false;
        message.fundingRate = object.fundingRate ?? "";
        message.markPrice = object.markPrice ?? "";
        return message;
    },
};
function createBaseEventSubaccountDeposit() {
    return { srcAddress: "", subaccountId: new Uint8Array(), amount: undefined };
}
export const EventSubaccountDeposit = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.srcAddress !== "") {
            writer.uint32(10).string(message.srcAddress);
        }
        if (message.subaccountId.length !== 0) {
            writer.uint32(18).bytes(message.subaccountId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSubaccountDeposit();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.srcAddress = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.bytes();
                    break;
                case 3:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            srcAddress: isSet(object.srcAddress) ? String(object.srcAddress) : "",
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.srcAddress !== undefined && (obj.srcAddress = message.srcAddress);
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return EventSubaccountDeposit.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSubaccountDeposit();
        message.srcAddress = object.srcAddress ?? "";
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseEventSubaccountWithdraw() {
    return { subaccountId: new Uint8Array(), dstAddress: "", amount: undefined };
}
export const EventSubaccountWithdraw = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subaccountId.length !== 0) {
            writer.uint32(10).bytes(message.subaccountId);
        }
        if (message.dstAddress !== "") {
            writer.uint32(18).string(message.dstAddress);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSubaccountWithdraw();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subaccountId = reader.bytes();
                    break;
                case 2:
                    message.dstAddress = reader.string();
                    break;
                case 3:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subaccountId: isSet(object.subaccountId) ? bytesFromBase64(object.subaccountId) : new Uint8Array(),
            dstAddress: isSet(object.dstAddress) ? String(object.dstAddress) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.subaccountId !== undefined &&
            (obj.subaccountId = base64FromBytes(message.subaccountId !== undefined ? message.subaccountId : new Uint8Array()));
        message.dstAddress !== undefined && (obj.dstAddress = message.dstAddress);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return EventSubaccountWithdraw.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSubaccountWithdraw();
        message.subaccountId = object.subaccountId ?? new Uint8Array();
        message.dstAddress = object.dstAddress ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseEventSubaccountBalanceTransfer() {
    return { srcSubaccountId: "", dstSubaccountId: "", amount: undefined };
}
export const EventSubaccountBalanceTransfer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.srcSubaccountId !== "") {
            writer.uint32(10).string(message.srcSubaccountId);
        }
        if (message.dstSubaccountId !== "") {
            writer.uint32(18).string(message.dstSubaccountId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSubaccountBalanceTransfer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.srcSubaccountId = reader.string();
                    break;
                case 2:
                    message.dstSubaccountId = reader.string();
                    break;
                case 3:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            srcSubaccountId: isSet(object.srcSubaccountId) ? String(object.srcSubaccountId) : "",
            dstSubaccountId: isSet(object.dstSubaccountId) ? String(object.dstSubaccountId) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.srcSubaccountId !== undefined && (obj.srcSubaccountId = message.srcSubaccountId);
        message.dstSubaccountId !== undefined && (obj.dstSubaccountId = message.dstSubaccountId);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return EventSubaccountBalanceTransfer.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSubaccountBalanceTransfer();
        message.srcSubaccountId = object.srcSubaccountId ?? "";
        message.dstSubaccountId = object.dstSubaccountId ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseEventBatchDepositUpdate() {
    return { depositUpdates: [] };
}
export const EventBatchDepositUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.depositUpdates) {
            DepositUpdate.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBatchDepositUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.depositUpdates.push(DepositUpdate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            depositUpdates: Array.isArray(object?.depositUpdates)
                ? object.depositUpdates.map((e) => DepositUpdate.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.depositUpdates) {
            obj.depositUpdates = message.depositUpdates.map((e) => e ? DepositUpdate.toJSON(e) : undefined);
        }
        else {
            obj.depositUpdates = [];
        }
        return obj;
    },
    create(base) {
        return EventBatchDepositUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBatchDepositUpdate();
        message.depositUpdates = object.depositUpdates?.map((e) => DepositUpdate.fromPartial(e)) || [];
        return message;
    },
};
function createBaseDerivativeMarketOrderCancel() {
    return { marketOrder: undefined, cancelQuantity: "" };
}
export const DerivativeMarketOrderCancel = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketOrder !== undefined) {
            DerivativeMarketOrder.encode(message.marketOrder, writer.uint32(10).fork()).ldelim();
        }
        if (message.cancelQuantity !== "") {
            writer.uint32(18).string(message.cancelQuantity);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarketOrderCancel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketOrder = DerivativeMarketOrder.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.cancelQuantity = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketOrder: isSet(object.marketOrder) ? DerivativeMarketOrder.fromJSON(object.marketOrder) : undefined,
            cancelQuantity: isSet(object.cancelQuantity) ? String(object.cancelQuantity) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketOrder !== undefined &&
            (obj.marketOrder = message.marketOrder ? DerivativeMarketOrder.toJSON(message.marketOrder) : undefined);
        message.cancelQuantity !== undefined && (obj.cancelQuantity = message.cancelQuantity);
        return obj;
    },
    create(base) {
        return DerivativeMarketOrderCancel.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarketOrderCancel();
        message.marketOrder = (object.marketOrder !== undefined && object.marketOrder !== null)
            ? DerivativeMarketOrder.fromPartial(object.marketOrder)
            : undefined;
        message.cancelQuantity = object.cancelQuantity ?? "";
        return message;
    },
};
function createBaseEventCancelDerivativeOrder() {
    return { marketId: "", isLimitCancel: false, limitOrder: undefined, marketOrderCancel: undefined };
}
export const EventCancelDerivativeOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isLimitCancel === true) {
            writer.uint32(16).bool(message.isLimitCancel);
        }
        if (message.limitOrder !== undefined) {
            DerivativeLimitOrder.encode(message.limitOrder, writer.uint32(26).fork()).ldelim();
        }
        if (message.marketOrderCancel !== undefined) {
            DerivativeMarketOrderCancel.encode(message.marketOrderCancel, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventCancelDerivativeOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isLimitCancel = reader.bool();
                    break;
                case 3:
                    message.limitOrder = DerivativeLimitOrder.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.marketOrderCancel = DerivativeMarketOrderCancel.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isLimitCancel: isSet(object.isLimitCancel) ? Boolean(object.isLimitCancel) : false,
            limitOrder: isSet(object.limitOrder) ? DerivativeLimitOrder.fromJSON(object.limitOrder) : undefined,
            marketOrderCancel: isSet(object.marketOrderCancel)
                ? DerivativeMarketOrderCancel.fromJSON(object.marketOrderCancel)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isLimitCancel !== undefined && (obj.isLimitCancel = message.isLimitCancel);
        message.limitOrder !== undefined &&
            (obj.limitOrder = message.limitOrder ? DerivativeLimitOrder.toJSON(message.limitOrder) : undefined);
        message.marketOrderCancel !== undefined && (obj.marketOrderCancel = message.marketOrderCancel
            ? DerivativeMarketOrderCancel.toJSON(message.marketOrderCancel)
            : undefined);
        return obj;
    },
    create(base) {
        return EventCancelDerivativeOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventCancelDerivativeOrder();
        message.marketId = object.marketId ?? "";
        message.isLimitCancel = object.isLimitCancel ?? false;
        message.limitOrder = (object.limitOrder !== undefined && object.limitOrder !== null)
            ? DerivativeLimitOrder.fromPartial(object.limitOrder)
            : undefined;
        message.marketOrderCancel = (object.marketOrderCancel !== undefined && object.marketOrderCancel !== null)
            ? DerivativeMarketOrderCancel.fromPartial(object.marketOrderCancel)
            : undefined;
        return message;
    },
};
function createBaseEventFeeDiscountSchedule() {
    return { schedule: undefined };
}
export const EventFeeDiscountSchedule = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.schedule !== undefined) {
            FeeDiscountSchedule.encode(message.schedule, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventFeeDiscountSchedule();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.schedule = FeeDiscountSchedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { schedule: isSet(object.schedule) ? FeeDiscountSchedule.fromJSON(object.schedule) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.schedule !== undefined &&
            (obj.schedule = message.schedule ? FeeDiscountSchedule.toJSON(message.schedule) : undefined);
        return obj;
    },
    create(base) {
        return EventFeeDiscountSchedule.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventFeeDiscountSchedule();
        message.schedule = (object.schedule !== undefined && object.schedule !== null)
            ? FeeDiscountSchedule.fromPartial(object.schedule)
            : undefined;
        return message;
    },
};
function createBaseEventTradingRewardCampaignUpdate() {
    return { campaignInfo: undefined, campaignRewardPools: [] };
}
export const EventTradingRewardCampaignUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.campaignInfo !== undefined) {
            TradingRewardCampaignInfo.encode(message.campaignInfo, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.campaignRewardPools) {
            CampaignRewardPool.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventTradingRewardCampaignUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.campaignInfo = TradingRewardCampaignInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.campaignRewardPools.push(CampaignRewardPool.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            campaignInfo: isSet(object.campaignInfo) ? TradingRewardCampaignInfo.fromJSON(object.campaignInfo) : undefined,
            campaignRewardPools: Array.isArray(object?.campaignRewardPools)
                ? object.campaignRewardPools.map((e) => CampaignRewardPool.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.campaignInfo !== undefined &&
            (obj.campaignInfo = message.campaignInfo ? TradingRewardCampaignInfo.toJSON(message.campaignInfo) : undefined);
        if (message.campaignRewardPools) {
            obj.campaignRewardPools = message.campaignRewardPools.map((e) => e ? CampaignRewardPool.toJSON(e) : undefined);
        }
        else {
            obj.campaignRewardPools = [];
        }
        return obj;
    },
    create(base) {
        return EventTradingRewardCampaignUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventTradingRewardCampaignUpdate();
        message.campaignInfo = (object.campaignInfo !== undefined && object.campaignInfo !== null)
            ? TradingRewardCampaignInfo.fromPartial(object.campaignInfo)
            : undefined;
        message.campaignRewardPools = object.campaignRewardPools?.map((e) => CampaignRewardPool.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventTradingRewardDistribution() {
    return { accountRewards: [] };
}
export const EventTradingRewardDistribution = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.accountRewards) {
            AccountRewards.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventTradingRewardDistribution();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountRewards.push(AccountRewards.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            accountRewards: Array.isArray(object?.accountRewards)
                ? object.accountRewards.map((e) => AccountRewards.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.accountRewards) {
            obj.accountRewards = message.accountRewards.map((e) => e ? AccountRewards.toJSON(e) : undefined);
        }
        else {
            obj.accountRewards = [];
        }
        return obj;
    },
    create(base) {
        return EventTradingRewardDistribution.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventTradingRewardDistribution();
        message.accountRewards = object.accountRewards?.map((e) => AccountRewards.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventNewConditionalDerivativeOrder() {
    return { marketId: "", order: undefined, hash: new Uint8Array(), isMarket: false };
}
export const EventNewConditionalDerivativeOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.order !== undefined) {
            DerivativeOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        if (message.hash.length !== 0) {
            writer.uint32(26).bytes(message.hash);
        }
        if (message.isMarket === true) {
            writer.uint32(32).bool(message.isMarket);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventNewConditionalDerivativeOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.order = DerivativeOrder.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.hash = reader.bytes();
                    break;
                case 4:
                    message.isMarket = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            order: isSet(object.order) ? DerivativeOrder.fromJSON(object.order) : undefined,
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            isMarket: isSet(object.isMarket) ? Boolean(object.isMarket) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.order !== undefined && (obj.order = message.order ? DerivativeOrder.toJSON(message.order) : undefined);
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.isMarket !== undefined && (obj.isMarket = message.isMarket);
        return obj;
    },
    create(base) {
        return EventNewConditionalDerivativeOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventNewConditionalDerivativeOrder();
        message.marketId = object.marketId ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrder.fromPartial(object.order)
            : undefined;
        message.hash = object.hash ?? new Uint8Array();
        message.isMarket = object.isMarket ?? false;
        return message;
    },
};
function createBaseEventCancelConditionalDerivativeOrder() {
    return { marketId: "", isLimitCancel: false, limitOrder: undefined, marketOrder: undefined };
}
export const EventCancelConditionalDerivativeOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.isLimitCancel === true) {
            writer.uint32(16).bool(message.isLimitCancel);
        }
        if (message.limitOrder !== undefined) {
            DerivativeLimitOrder.encode(message.limitOrder, writer.uint32(26).fork()).ldelim();
        }
        if (message.marketOrder !== undefined) {
            DerivativeMarketOrder.encode(message.marketOrder, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventCancelConditionalDerivativeOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.isLimitCancel = reader.bool();
                    break;
                case 3:
                    message.limitOrder = DerivativeLimitOrder.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.marketOrder = DerivativeMarketOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            isLimitCancel: isSet(object.isLimitCancel) ? Boolean(object.isLimitCancel) : false,
            limitOrder: isSet(object.limitOrder) ? DerivativeLimitOrder.fromJSON(object.limitOrder) : undefined,
            marketOrder: isSet(object.marketOrder) ? DerivativeMarketOrder.fromJSON(object.marketOrder) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.isLimitCancel !== undefined && (obj.isLimitCancel = message.isLimitCancel);
        message.limitOrder !== undefined &&
            (obj.limitOrder = message.limitOrder ? DerivativeLimitOrder.toJSON(message.limitOrder) : undefined);
        message.marketOrder !== undefined &&
            (obj.marketOrder = message.marketOrder ? DerivativeMarketOrder.toJSON(message.marketOrder) : undefined);
        return obj;
    },
    create(base) {
        return EventCancelConditionalDerivativeOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventCancelConditionalDerivativeOrder();
        message.marketId = object.marketId ?? "";
        message.isLimitCancel = object.isLimitCancel ?? false;
        message.limitOrder = (object.limitOrder !== undefined && object.limitOrder !== null)
            ? DerivativeLimitOrder.fromPartial(object.limitOrder)
            : undefined;
        message.marketOrder = (object.marketOrder !== undefined && object.marketOrder !== null)
            ? DerivativeMarketOrder.fromPartial(object.marketOrder)
            : undefined;
        return message;
    },
};
function createBaseEventConditionalDerivativeOrderTrigger() {
    return {
        marketId: new Uint8Array(),
        isLimitTrigger: false,
        triggeredOrderHash: new Uint8Array(),
        placedOrderHash: new Uint8Array(),
        triggeredOrderCid: "",
    };
}
export const EventConditionalDerivativeOrderTrigger = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId.length !== 0) {
            writer.uint32(10).bytes(message.marketId);
        }
        if (message.isLimitTrigger === true) {
            writer.uint32(16).bool(message.isLimitTrigger);
        }
        if (message.triggeredOrderHash.length !== 0) {
            writer.uint32(26).bytes(message.triggeredOrderHash);
        }
        if (message.placedOrderHash.length !== 0) {
            writer.uint32(34).bytes(message.placedOrderHash);
        }
        if (message.triggeredOrderCid !== "") {
            writer.uint32(42).string(message.triggeredOrderCid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventConditionalDerivativeOrderTrigger();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.bytes();
                    break;
                case 2:
                    message.isLimitTrigger = reader.bool();
                    break;
                case 3:
                    message.triggeredOrderHash = reader.bytes();
                    break;
                case 4:
                    message.placedOrderHash = reader.bytes();
                    break;
                case 5:
                    message.triggeredOrderCid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? bytesFromBase64(object.marketId) : new Uint8Array(),
            isLimitTrigger: isSet(object.isLimitTrigger) ? Boolean(object.isLimitTrigger) : false,
            triggeredOrderHash: isSet(object.triggeredOrderHash)
                ? bytesFromBase64(object.triggeredOrderHash)
                : new Uint8Array(),
            placedOrderHash: isSet(object.placedOrderHash) ? bytesFromBase64(object.placedOrderHash) : new Uint8Array(),
            triggeredOrderCid: isSet(object.triggeredOrderCid) ? String(object.triggeredOrderCid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined &&
            (obj.marketId = base64FromBytes(message.marketId !== undefined ? message.marketId : new Uint8Array()));
        message.isLimitTrigger !== undefined && (obj.isLimitTrigger = message.isLimitTrigger);
        message.triggeredOrderHash !== undefined &&
            (obj.triggeredOrderHash = base64FromBytes(message.triggeredOrderHash !== undefined ? message.triggeredOrderHash : new Uint8Array()));
        message.placedOrderHash !== undefined &&
            (obj.placedOrderHash = base64FromBytes(message.placedOrderHash !== undefined ? message.placedOrderHash : new Uint8Array()));
        message.triggeredOrderCid !== undefined && (obj.triggeredOrderCid = message.triggeredOrderCid);
        return obj;
    },
    create(base) {
        return EventConditionalDerivativeOrderTrigger.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventConditionalDerivativeOrderTrigger();
        message.marketId = object.marketId ?? new Uint8Array();
        message.isLimitTrigger = object.isLimitTrigger ?? false;
        message.triggeredOrderHash = object.triggeredOrderHash ?? new Uint8Array();
        message.placedOrderHash = object.placedOrderHash ?? new Uint8Array();
        message.triggeredOrderCid = object.triggeredOrderCid ?? "";
        return message;
    },
};
function createBaseEventOrderFail() {
    return { account: new Uint8Array(), hashes: [], flags: [], cids: [] };
}
export const EventOrderFail = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.account.length !== 0) {
            writer.uint32(10).bytes(message.account);
        }
        for (const v of message.hashes) {
            writer.uint32(18).bytes(v);
        }
        writer.uint32(26).fork();
        for (const v of message.flags) {
            writer.uint32(v);
        }
        writer.ldelim();
        for (const v of message.cids) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventOrderFail();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.bytes();
                    break;
                case 2:
                    message.hashes.push(reader.bytes());
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.flags.push(reader.uint32());
                        }
                    }
                    else {
                        message.flags.push(reader.uint32());
                    }
                    break;
                case 4:
                    message.cids.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            account: isSet(object.account) ? bytesFromBase64(object.account) : new Uint8Array(),
            hashes: Array.isArray(object?.hashes) ? object.hashes.map((e) => bytesFromBase64(e)) : [],
            flags: Array.isArray(object?.flags) ? object.flags.map((e) => Number(e)) : [],
            cids: Array.isArray(object?.cids) ? object.cids.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.account !== undefined &&
            (obj.account = base64FromBytes(message.account !== undefined ? message.account : new Uint8Array()));
        if (message.hashes) {
            obj.hashes = message.hashes.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.hashes = [];
        }
        if (message.flags) {
            obj.flags = message.flags.map((e) => Math.round(e));
        }
        else {
            obj.flags = [];
        }
        if (message.cids) {
            obj.cids = message.cids.map((e) => e);
        }
        else {
            obj.cids = [];
        }
        return obj;
    },
    create(base) {
        return EventOrderFail.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventOrderFail();
        message.account = object.account ?? new Uint8Array();
        message.hashes = object.hashes?.map((e) => e) || [];
        message.flags = object.flags?.map((e) => e) || [];
        message.cids = object.cids?.map((e) => e) || [];
        return message;
    },
};
function createBaseEventAtomicMarketOrderFeeMultipliersUpdated() {
    return { marketFeeMultipliers: [] };
}
export const EventAtomicMarketOrderFeeMultipliersUpdated = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketFeeMultipliers) {
            MarketFeeMultiplier.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAtomicMarketOrderFeeMultipliersUpdated();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketFeeMultipliers.push(MarketFeeMultiplier.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketFeeMultipliers: Array.isArray(object?.marketFeeMultipliers)
                ? object.marketFeeMultipliers.map((e) => MarketFeeMultiplier.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketFeeMultipliers) {
            obj.marketFeeMultipliers = message.marketFeeMultipliers.map((e) => e ? MarketFeeMultiplier.toJSON(e) : undefined);
        }
        else {
            obj.marketFeeMultipliers = [];
        }
        return obj;
    },
    create(base) {
        return EventAtomicMarketOrderFeeMultipliersUpdated.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAtomicMarketOrderFeeMultipliersUpdated();
        message.marketFeeMultipliers = object.marketFeeMultipliers?.map((e) => MarketFeeMultiplier.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventOrderbookUpdate() {
    return { spotUpdates: [], derivativeUpdates: [] };
}
export const EventOrderbookUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.spotUpdates) {
            OrderbookUpdate.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.derivativeUpdates) {
            OrderbookUpdate.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventOrderbookUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.spotUpdates.push(OrderbookUpdate.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.derivativeUpdates.push(OrderbookUpdate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotUpdates: Array.isArray(object?.spotUpdates)
                ? object.spotUpdates.map((e) => OrderbookUpdate.fromJSON(e))
                : [],
            derivativeUpdates: Array.isArray(object?.derivativeUpdates)
                ? object.derivativeUpdates.map((e) => OrderbookUpdate.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.spotUpdates) {
            obj.spotUpdates = message.spotUpdates.map((e) => e ? OrderbookUpdate.toJSON(e) : undefined);
        }
        else {
            obj.spotUpdates = [];
        }
        if (message.derivativeUpdates) {
            obj.derivativeUpdates = message.derivativeUpdates.map((e) => e ? OrderbookUpdate.toJSON(e) : undefined);
        }
        else {
            obj.derivativeUpdates = [];
        }
        return obj;
    },
    create(base) {
        return EventOrderbookUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventOrderbookUpdate();
        message.spotUpdates = object.spotUpdates?.map((e) => OrderbookUpdate.fromPartial(e)) || [];
        message.derivativeUpdates = object.derivativeUpdates?.map((e) => OrderbookUpdate.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOrderbookUpdate() {
    return { seq: "0", orderbook: undefined };
}
export const OrderbookUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.seq !== "0") {
            writer.uint32(8).uint64(message.seq);
        }
        if (message.orderbook !== undefined) {
            Orderbook.encode(message.orderbook, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbookUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.seq = longToString(reader.uint64());
                    break;
                case 2:
                    message.orderbook = Orderbook.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            seq: isSet(object.seq) ? String(object.seq) : "0",
            orderbook: isSet(object.orderbook) ? Orderbook.fromJSON(object.orderbook) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.seq !== undefined && (obj.seq = message.seq);
        message.orderbook !== undefined &&
            (obj.orderbook = message.orderbook ? Orderbook.toJSON(message.orderbook) : undefined);
        return obj;
    },
    create(base) {
        return OrderbookUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbookUpdate();
        message.seq = object.seq ?? "0";
        message.orderbook = (object.orderbook !== undefined && object.orderbook !== null)
            ? Orderbook.fromPartial(object.orderbook)
            : undefined;
        return message;
    },
};
function createBaseOrderbook() {
    return { marketId: new Uint8Array(), buyLevels: [], sellLevels: [] };
}
export const Orderbook = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId.length !== 0) {
            writer.uint32(10).bytes(message.marketId);
        }
        for (const v of message.buyLevels) {
            Level.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.sellLevels) {
            Level.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderbook();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.bytes();
                    break;
                case 2:
                    message.buyLevels.push(Level.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.sellLevels.push(Level.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? bytesFromBase64(object.marketId) : new Uint8Array(),
            buyLevels: Array.isArray(object?.buyLevels) ? object.buyLevels.map((e) => Level.fromJSON(e)) : [],
            sellLevels: Array.isArray(object?.sellLevels) ? object.sellLevels.map((e) => Level.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined &&
            (obj.marketId = base64FromBytes(message.marketId !== undefined ? message.marketId : new Uint8Array()));
        if (message.buyLevels) {
            obj.buyLevels = message.buyLevels.map((e) => e ? Level.toJSON(e) : undefined);
        }
        else {
            obj.buyLevels = [];
        }
        if (message.sellLevels) {
            obj.sellLevels = message.sellLevels.map((e) => e ? Level.toJSON(e) : undefined);
        }
        else {
            obj.sellLevels = [];
        }
        return obj;
    },
    create(base) {
        return Orderbook.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderbook();
        message.marketId = object.marketId ?? new Uint8Array();
        message.buyLevels = object.buyLevels?.map((e) => Level.fromPartial(e)) || [];
        message.sellLevels = object.sellLevels?.map((e) => Level.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventGrantAuthorizations() {
    return { granter: "", grants: [] };
}
export const EventGrantAuthorizations = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.granter !== "") {
            writer.uint32(10).string(message.granter);
        }
        for (const v of message.grants) {
            GrantAuthorization.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventGrantAuthorizations();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.granter = reader.string();
                    break;
                case 2:
                    message.grants.push(GrantAuthorization.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            granter: isSet(object.granter) ? String(object.granter) : "",
            grants: Array.isArray(object?.grants) ? object.grants.map((e) => GrantAuthorization.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.granter !== undefined && (obj.granter = message.granter);
        if (message.grants) {
            obj.grants = message.grants.map((e) => e ? GrantAuthorization.toJSON(e) : undefined);
        }
        else {
            obj.grants = [];
        }
        return obj;
    },
    create(base) {
        return EventGrantAuthorizations.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventGrantAuthorizations();
        message.granter = object.granter ?? "";
        message.grants = object.grants?.map((e) => GrantAuthorization.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventGrantActivation() {
    return { grantee: "", granter: "", amount: "" };
}
export const EventGrantActivation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.grantee !== "") {
            writer.uint32(10).string(message.grantee);
        }
        if (message.granter !== "") {
            writer.uint32(18).string(message.granter);
        }
        if (message.amount !== "") {
            writer.uint32(26).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventGrantActivation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.grantee = reader.string();
                    break;
                case 2:
                    message.granter = reader.string();
                    break;
                case 3:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            grantee: isSet(object.grantee) ? String(object.grantee) : "",
            granter: isSet(object.granter) ? String(object.granter) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.grantee !== undefined && (obj.grantee = message.grantee);
        message.granter !== undefined && (obj.granter = message.granter);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return EventGrantActivation.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventGrantActivation();
        message.grantee = object.grantee ?? "";
        message.granter = object.granter ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseEventInvalidGrant() {
    return { grantee: "", granter: "" };
}
export const EventInvalidGrant = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.grantee !== "") {
            writer.uint32(10).string(message.grantee);
        }
        if (message.granter !== "") {
            writer.uint32(18).string(message.granter);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventInvalidGrant();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.grantee = reader.string();
                    break;
                case 2:
                    message.granter = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            grantee: isSet(object.grantee) ? String(object.grantee) : "",
            granter: isSet(object.granter) ? String(object.granter) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.grantee !== undefined && (obj.grantee = message.grantee);
        message.granter !== undefined && (obj.granter = message.granter);
        return obj;
    },
    create(base) {
        return EventInvalidGrant.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventInvalidGrant();
        message.grantee = object.grantee ?? "";
        message.granter = object.granter ?? "";
        return message;
    },
};
function createBaseEventOrderCancelFail() {
    return { marketId: "", subaccountId: "", orderHash: "", cid: "", description: "" };
}
export const EventOrderCancelFail = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.orderHash !== "") {
            writer.uint32(26).string(message.orderHash);
        }
        if (message.cid !== "") {
            writer.uint32(34).string(message.cid);
        }
        if (message.description !== "") {
            writer.uint32(42).string(message.description);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventOrderCancelFail();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.orderHash = reader.string();
                    break;
                case 4:
                    message.cid = reader.string();
                    break;
                case 5:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.cid !== undefined && (obj.cid = message.cid);
        message.description !== undefined && (obj.description = message.description);
        return obj;
    },
    create(base) {
        return EventOrderCancelFail.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventOrderCancelFail();
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.orderHash = object.orderHash ?? "";
        message.cid = object.cid ?? "";
        message.description = object.description ?? "";
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
