/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
export const protobufPackage = "injective.auction.v1beta1";
function createBaseParams() {
    return { auctionPeriod: "0", minNextBidIncrementRate: "", injBasketMaxCap: "" };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.auctionPeriod !== "0") {
            writer.uint32(8).int64(message.auctionPeriod);
        }
        if (message.minNextBidIncrementRate !== "") {
            writer.uint32(18).string(message.minNextBidIncrementRate);
        }
        if (message.injBasketMaxCap !== "") {
            writer.uint32(26).string(message.injBasketMaxCap);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.auctionPeriod = longToString(reader.int64());
                    break;
                case 2:
                    message.minNextBidIncrementRate = reader.string();
                    break;
                case 3:
                    message.injBasketMaxCap = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            auctionPeriod: isSet(object.auctionPeriod) ? String(object.auctionPeriod) : "0",
            minNextBidIncrementRate: isSet(object.minNextBidIncrementRate) ? String(object.minNextBidIncrementRate) : "",
            injBasketMaxCap: isSet(object.injBasketMaxCap) ? String(object.injBasketMaxCap) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.auctionPeriod !== undefined && (obj.auctionPeriod = message.auctionPeriod);
        message.minNextBidIncrementRate !== undefined && (obj.minNextBidIncrementRate = message.minNextBidIncrementRate);
        message.injBasketMaxCap !== undefined && (obj.injBasketMaxCap = message.injBasketMaxCap);
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.auctionPeriod = object.auctionPeriod ?? "0";
        message.minNextBidIncrementRate = object.minNextBidIncrementRate ?? "";
        message.injBasketMaxCap = object.injBasketMaxCap ?? "";
        return message;
    },
};
function createBaseBid() {
    return { bidder: "", amount: "" };
}
export const Bid = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bidder !== "") {
            writer.uint32(10).string(message.bidder);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBid();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bidder = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bidder: isSet(object.bidder) ? String(object.bidder) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.bidder !== undefined && (obj.bidder = message.bidder);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return Bid.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBid();
        message.bidder = object.bidder ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseLastAuctionResult() {
    return { winner: "", amount: "", round: "0" };
}
export const LastAuctionResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.winner !== "") {
            writer.uint32(10).string(message.winner);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        if (message.round !== "0") {
            writer.uint32(24).uint64(message.round);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLastAuctionResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.winner = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                case 3:
                    message.round = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            winner: isSet(object.winner) ? String(object.winner) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            round: isSet(object.round) ? String(object.round) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.winner !== undefined && (obj.winner = message.winner);
        message.amount !== undefined && (obj.amount = message.amount);
        message.round !== undefined && (obj.round = message.round);
        return obj;
    },
    create(base) {
        return LastAuctionResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLastAuctionResult();
        message.winner = object.winner ?? "";
        message.amount = object.amount ?? "";
        message.round = object.round ?? "0";
        return message;
    },
};
function createBaseEventBid() {
    return { bidder: "", amount: "", round: "0" };
}
export const EventBid = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bidder !== "") {
            writer.uint32(10).string(message.bidder);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        if (message.round !== "0") {
            writer.uint32(24).uint64(message.round);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventBid();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bidder = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                case 3:
                    message.round = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bidder: isSet(object.bidder) ? String(object.bidder) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            round: isSet(object.round) ? String(object.round) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.bidder !== undefined && (obj.bidder = message.bidder);
        message.amount !== undefined && (obj.amount = message.amount);
        message.round !== undefined && (obj.round = message.round);
        return obj;
    },
    create(base) {
        return EventBid.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventBid();
        message.bidder = object.bidder ?? "";
        message.amount = object.amount ?? "";
        message.round = object.round ?? "0";
        return message;
    },
};
function createBaseEventAuctionResult() {
    return { winner: "", amount: "", round: "0" };
}
export const EventAuctionResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.winner !== "") {
            writer.uint32(10).string(message.winner);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        if (message.round !== "0") {
            writer.uint32(24).uint64(message.round);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAuctionResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.winner = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                case 3:
                    message.round = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            winner: isSet(object.winner) ? String(object.winner) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            round: isSet(object.round) ? String(object.round) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.winner !== undefined && (obj.winner = message.winner);
        message.amount !== undefined && (obj.amount = message.amount);
        message.round !== undefined && (obj.round = message.round);
        return obj;
    },
    create(base) {
        return EventAuctionResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAuctionResult();
        message.winner = object.winner ?? "";
        message.amount = object.amount ?? "";
        message.round = object.round ?? "0";
        return message;
    },
};
function createBaseEventAuctionStart() {
    return { round: "0", endingTimestamp: "0", newBasket: [] };
}
export const EventAuctionStart = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.round !== "0") {
            writer.uint32(8).uint64(message.round);
        }
        if (message.endingTimestamp !== "0") {
            writer.uint32(16).int64(message.endingTimestamp);
        }
        for (const v of message.newBasket) {
            Coin.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAuctionStart();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.round = longToString(reader.uint64());
                    break;
                case 2:
                    message.endingTimestamp = longToString(reader.int64());
                    break;
                case 3:
                    message.newBasket.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            round: isSet(object.round) ? String(object.round) : "0",
            endingTimestamp: isSet(object.endingTimestamp) ? String(object.endingTimestamp) : "0",
            newBasket: Array.isArray(object?.newBasket) ? object.newBasket.map((e) => Coin.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.round !== undefined && (obj.round = message.round);
        message.endingTimestamp !== undefined && (obj.endingTimestamp = message.endingTimestamp);
        if (message.newBasket) {
            obj.newBasket = message.newBasket.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.newBasket = [];
        }
        return obj;
    },
    create(base) {
        return EventAuctionStart.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAuctionStart();
        message.round = object.round ?? "0";
        message.endingTimestamp = object.endingTimestamp ?? "0";
        message.newBasket = object.newBasket?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
