/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
export const protobufPackage = "injective.oracle.v1beta1";
export var OracleType;
(function (OracleType) {
    OracleType[OracleType["Unspecified"] = 0] = "Unspecified";
    OracleType[OracleType["Band"] = 1] = "Band";
    OracleType[OracleType["PriceFeed"] = 2] = "PriceFeed";
    OracleType[OracleType["Coinbase"] = 3] = "Coinbase";
    OracleType[OracleType["Chainlink"] = 4] = "Chainlink";
    OracleType[OracleType["Razor"] = 5] = "Razor";
    OracleType[OracleType["Dia"] = 6] = "Dia";
    OracleType[OracleType["API3"] = 7] = "API3";
    OracleType[OracleType["Uma"] = 8] = "Uma";
    OracleType[OracleType["Pyth"] = 9] = "Pyth";
    OracleType[OracleType["BandIBC"] = 10] = "BandIBC";
    OracleType[OracleType["Provider"] = 11] = "Provider";
    OracleType[OracleType["Stork"] = 12] = "Stork";
    OracleType[OracleType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(OracleType || (OracleType = {}));
export function oracleTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "Unspecified":
            return OracleType.Unspecified;
        case 1:
        case "Band":
            return OracleType.Band;
        case 2:
        case "PriceFeed":
            return OracleType.PriceFeed;
        case 3:
        case "Coinbase":
            return OracleType.Coinbase;
        case 4:
        case "Chainlink":
            return OracleType.Chainlink;
        case 5:
        case "Razor":
            return OracleType.Razor;
        case 6:
        case "Dia":
            return OracleType.Dia;
        case 7:
        case "API3":
            return OracleType.API3;
        case 8:
        case "Uma":
            return OracleType.Uma;
        case 9:
        case "Pyth":
            return OracleType.Pyth;
        case 10:
        case "BandIBC":
            return OracleType.BandIBC;
        case 11:
        case "Provider":
            return OracleType.Provider;
        case 12:
        case "Stork":
            return OracleType.Stork;
        case -1:
        case "UNRECOGNIZED":
        default:
            return OracleType.UNRECOGNIZED;
    }
}
export function oracleTypeToJSON(object) {
    switch (object) {
        case OracleType.Unspecified:
            return "Unspecified";
        case OracleType.Band:
            return "Band";
        case OracleType.PriceFeed:
            return "PriceFeed";
        case OracleType.Coinbase:
            return "Coinbase";
        case OracleType.Chainlink:
            return "Chainlink";
        case OracleType.Razor:
            return "Razor";
        case OracleType.Dia:
            return "Dia";
        case OracleType.API3:
            return "API3";
        case OracleType.Uma:
            return "Uma";
        case OracleType.Pyth:
            return "Pyth";
        case OracleType.BandIBC:
            return "BandIBC";
        case OracleType.Provider:
            return "Provider";
        case OracleType.Stork:
            return "Stork";
        case OracleType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseParams() {
    return { pythContract: "" };
}
export const Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.pythContract !== "") {
            writer.uint32(10).string(message.pythContract);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pythContract = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { pythContract: isSet(object.pythContract) ? String(object.pythContract) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.pythContract !== undefined && (obj.pythContract = message.pythContract);
        return obj;
    },
    create(base) {
        return Params.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.pythContract = object.pythContract ?? "";
        return message;
    },
};
function createBaseOracleInfo() {
    return { symbol: "", oracleType: 0 };
}
export const OracleInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.symbol !== "") {
            writer.uint32(10).string(message.symbol);
        }
        if (message.oracleType !== 0) {
            writer.uint32(16).int32(message.oracleType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOracleInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.symbol = reader.string();
                    break;
                case 2:
                    message.oracleType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        return obj;
    },
    create(base) {
        return OracleInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOracleInfo();
        message.symbol = object.symbol ?? "";
        message.oracleType = object.oracleType ?? 0;
        return message;
    },
};
function createBaseChainlinkPriceState() {
    return { feedId: "", answer: "", timestamp: "0", priceState: undefined };
}
export const ChainlinkPriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feedId !== "") {
            writer.uint32(10).string(message.feedId);
        }
        if (message.answer !== "") {
            writer.uint32(18).string(message.answer);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).uint64(message.timestamp);
        }
        if (message.priceState !== undefined) {
            PriceState.encode(message.priceState, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChainlinkPriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feedId = reader.string();
                    break;
                case 2:
                    message.answer = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.uint64());
                    break;
                case 4:
                    message.priceState = PriceState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feedId: isSet(object.feedId) ? String(object.feedId) : "",
            answer: isSet(object.answer) ? String(object.answer) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            priceState: isSet(object.priceState) ? PriceState.fromJSON(object.priceState) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.feedId !== undefined && (obj.feedId = message.feedId);
        message.answer !== undefined && (obj.answer = message.answer);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.priceState !== undefined &&
            (obj.priceState = message.priceState ? PriceState.toJSON(message.priceState) : undefined);
        return obj;
    },
    create(base) {
        return ChainlinkPriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseChainlinkPriceState();
        message.feedId = object.feedId ?? "";
        message.answer = object.answer ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.priceState = (object.priceState !== undefined && object.priceState !== null)
            ? PriceState.fromPartial(object.priceState)
            : undefined;
        return message;
    },
};
function createBaseBandPriceState() {
    return { symbol: "", rate: "", resolveTime: "0", requestID: "0", priceState: undefined };
}
export const BandPriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.symbol !== "") {
            writer.uint32(10).string(message.symbol);
        }
        if (message.rate !== "") {
            writer.uint32(18).string(message.rate);
        }
        if (message.resolveTime !== "0") {
            writer.uint32(24).uint64(message.resolveTime);
        }
        if (message.requestID !== "0") {
            writer.uint32(32).uint64(message.requestID);
        }
        if (message.priceState !== undefined) {
            PriceState.encode(message.priceState, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBandPriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.symbol = reader.string();
                    break;
                case 2:
                    message.rate = reader.string();
                    break;
                case 3:
                    message.resolveTime = longToString(reader.uint64());
                    break;
                case 4:
                    message.requestID = longToString(reader.uint64());
                    break;
                case 5:
                    message.priceState = PriceState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            rate: isSet(object.rate) ? String(object.rate) : "",
            resolveTime: isSet(object.resolveTime) ? String(object.resolveTime) : "0",
            requestID: isSet(object.requestID) ? String(object.requestID) : "0",
            priceState: isSet(object.priceState) ? PriceState.fromJSON(object.priceState) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.rate !== undefined && (obj.rate = message.rate);
        message.resolveTime !== undefined && (obj.resolveTime = message.resolveTime);
        message.requestID !== undefined && (obj.requestID = message.requestID);
        message.priceState !== undefined &&
            (obj.priceState = message.priceState ? PriceState.toJSON(message.priceState) : undefined);
        return obj;
    },
    create(base) {
        return BandPriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBandPriceState();
        message.symbol = object.symbol ?? "";
        message.rate = object.rate ?? "";
        message.resolveTime = object.resolveTime ?? "0";
        message.requestID = object.requestID ?? "0";
        message.priceState = (object.priceState !== undefined && object.priceState !== null)
            ? PriceState.fromPartial(object.priceState)
            : undefined;
        return message;
    },
};
function createBasePriceFeedState() {
    return { base: "", quote: "", priceState: undefined, relayers: [] };
}
export const PriceFeedState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.base !== "") {
            writer.uint32(10).string(message.base);
        }
        if (message.quote !== "") {
            writer.uint32(18).string(message.quote);
        }
        if (message.priceState !== undefined) {
            PriceState.encode(message.priceState, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.relayers) {
            writer.uint32(34).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceFeedState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.base = reader.string();
                    break;
                case 2:
                    message.quote = reader.string();
                    break;
                case 3:
                    message.priceState = PriceState.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            base: isSet(object.base) ? String(object.base) : "",
            quote: isSet(object.quote) ? String(object.quote) : "",
            priceState: isSet(object.priceState) ? PriceState.fromJSON(object.priceState) : undefined,
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.base !== undefined && (obj.base = message.base);
        message.quote !== undefined && (obj.quote = message.quote);
        message.priceState !== undefined &&
            (obj.priceState = message.priceState ? PriceState.toJSON(message.priceState) : undefined);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return PriceFeedState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceFeedState();
        message.base = object.base ?? "";
        message.quote = object.quote ?? "";
        message.priceState = (object.priceState !== undefined && object.priceState !== null)
            ? PriceState.fromPartial(object.priceState)
            : undefined;
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseProviderInfo() {
    return { provider: "", relayers: [] };
}
export const ProviderInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.provider !== "") {
            writer.uint32(10).string(message.provider);
        }
        for (const v of message.relayers) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProviderInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.provider = reader.string();
                    break;
                case 2:
                    message.relayers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            provider: isSet(object.provider) ? String(object.provider) : "",
            relayers: Array.isArray(object?.relayers) ? object.relayers.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.provider !== undefined && (obj.provider = message.provider);
        if (message.relayers) {
            obj.relayers = message.relayers.map((e) => e);
        }
        else {
            obj.relayers = [];
        }
        return obj;
    },
    create(base) {
        return ProviderInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseProviderInfo();
        message.provider = object.provider ?? "";
        message.relayers = object.relayers?.map((e) => e) || [];
        return message;
    },
};
function createBaseProviderState() {
    return { providerInfo: undefined, providerPriceStates: [] };
}
export const ProviderState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.providerInfo !== undefined) {
            ProviderInfo.encode(message.providerInfo, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.providerPriceStates) {
            ProviderPriceState.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProviderState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.providerInfo = ProviderInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.providerPriceStates.push(ProviderPriceState.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            providerInfo: isSet(object.providerInfo) ? ProviderInfo.fromJSON(object.providerInfo) : undefined,
            providerPriceStates: Array.isArray(object?.providerPriceStates)
                ? object.providerPriceStates.map((e) => ProviderPriceState.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.providerInfo !== undefined &&
            (obj.providerInfo = message.providerInfo ? ProviderInfo.toJSON(message.providerInfo) : undefined);
        if (message.providerPriceStates) {
            obj.providerPriceStates = message.providerPriceStates.map((e) => e ? ProviderPriceState.toJSON(e) : undefined);
        }
        else {
            obj.providerPriceStates = [];
        }
        return obj;
    },
    create(base) {
        return ProviderState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseProviderState();
        message.providerInfo = (object.providerInfo !== undefined && object.providerInfo !== null)
            ? ProviderInfo.fromPartial(object.providerInfo)
            : undefined;
        message.providerPriceStates = object.providerPriceStates?.map((e) => ProviderPriceState.fromPartial(e)) || [];
        return message;
    },
};
function createBaseProviderPriceState() {
    return { symbol: "", state: undefined };
}
export const ProviderPriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.symbol !== "") {
            writer.uint32(10).string(message.symbol);
        }
        if (message.state !== undefined) {
            PriceState.encode(message.state, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProviderPriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.symbol = reader.string();
                    break;
                case 2:
                    message.state = PriceState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            state: isSet(object.state) ? PriceState.fromJSON(object.state) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.state !== undefined && (obj.state = message.state ? PriceState.toJSON(message.state) : undefined);
        return obj;
    },
    create(base) {
        return ProviderPriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseProviderPriceState();
        message.symbol = object.symbol ?? "";
        message.state = (object.state !== undefined && object.state !== null)
            ? PriceState.fromPartial(object.state)
            : undefined;
        return message;
    },
};
function createBasePriceFeedInfo() {
    return { base: "", quote: "" };
}
export const PriceFeedInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.base !== "") {
            writer.uint32(10).string(message.base);
        }
        if (message.quote !== "") {
            writer.uint32(18).string(message.quote);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceFeedInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.base = reader.string();
                    break;
                case 2:
                    message.quote = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            base: isSet(object.base) ? String(object.base) : "",
            quote: isSet(object.quote) ? String(object.quote) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.base !== undefined && (obj.base = message.base);
        message.quote !== undefined && (obj.quote = message.quote);
        return obj;
    },
    create(base) {
        return PriceFeedInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceFeedInfo();
        message.base = object.base ?? "";
        message.quote = object.quote ?? "";
        return message;
    },
};
function createBasePriceFeedPrice() {
    return { price: "" };
}
export const PriceFeedPrice = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceFeedPrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { price: isSet(object.price) ? String(object.price) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    create(base) {
        return PriceFeedPrice.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceFeedPrice();
        message.price = object.price ?? "";
        return message;
    },
};
function createBaseCoinbasePriceState() {
    return { kind: "", timestamp: "0", key: "", value: "0", priceState: undefined };
}
export const CoinbasePriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.kind !== "") {
            writer.uint32(10).string(message.kind);
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).uint64(message.timestamp);
        }
        if (message.key !== "") {
            writer.uint32(26).string(message.key);
        }
        if (message.value !== "0") {
            writer.uint32(32).uint64(message.value);
        }
        if (message.priceState !== undefined) {
            PriceState.encode(message.priceState, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCoinbasePriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.kind = reader.string();
                    break;
                case 2:
                    message.timestamp = longToString(reader.uint64());
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.value = longToString(reader.uint64());
                    break;
                case 5:
                    message.priceState = PriceState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            kind: isSet(object.kind) ? String(object.kind) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "0",
            priceState: isSet(object.priceState) ? PriceState.fromJSON(object.priceState) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.kind !== undefined && (obj.kind = message.kind);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        message.priceState !== undefined &&
            (obj.priceState = message.priceState ? PriceState.toJSON(message.priceState) : undefined);
        return obj;
    },
    create(base) {
        return CoinbasePriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCoinbasePriceState();
        message.kind = object.kind ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.key = object.key ?? "";
        message.value = object.value ?? "0";
        message.priceState = (object.priceState !== undefined && object.priceState !== null)
            ? PriceState.fromPartial(object.priceState)
            : undefined;
        return message;
    },
};
function createBaseStorkPriceState() {
    return { timestamp: "0", symbol: "", value: "", priceState: undefined };
}
export const StorkPriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.timestamp !== "0") {
            writer.uint32(8).uint64(message.timestamp);
        }
        if (message.symbol !== "") {
            writer.uint32(18).string(message.symbol);
        }
        if (message.value !== "") {
            writer.uint32(26).string(message.value);
        }
        if (message.priceState !== undefined) {
            PriceState.encode(message.priceState, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStorkPriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.timestamp = longToString(reader.uint64());
                    break;
                case 2:
                    message.symbol = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 5:
                    message.priceState = PriceState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            value: isSet(object.value) ? String(object.value) : "",
            priceState: isSet(object.priceState) ? PriceState.fromJSON(object.priceState) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.value !== undefined && (obj.value = message.value);
        message.priceState !== undefined &&
            (obj.priceState = message.priceState ? PriceState.toJSON(message.priceState) : undefined);
        return obj;
    },
    create(base) {
        return StorkPriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStorkPriceState();
        message.timestamp = object.timestamp ?? "0";
        message.symbol = object.symbol ?? "";
        message.value = object.value ?? "";
        message.priceState = (object.priceState !== undefined && object.priceState !== null)
            ? PriceState.fromPartial(object.priceState)
            : undefined;
        return message;
    },
};
function createBasePriceState() {
    return { price: "", cumulativePrice: "", timestamp: "0" };
}
export const PriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.cumulativePrice !== "") {
            writer.uint32(18).string(message.cumulativePrice);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).int64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.cumulativePrice = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            cumulativePrice: isSet(object.cumulativePrice) ? String(object.cumulativePrice) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.cumulativePrice !== undefined && (obj.cumulativePrice = message.cumulativePrice);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return PriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceState();
        message.price = object.price ?? "";
        message.cumulativePrice = object.cumulativePrice ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBasePythPriceState() {
    return { priceId: "", emaPrice: "", emaConf: "", conf: "", publishTime: "0", priceState: undefined };
}
export const PythPriceState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.priceId !== "") {
            writer.uint32(10).string(message.priceId);
        }
        if (message.emaPrice !== "") {
            writer.uint32(18).string(message.emaPrice);
        }
        if (message.emaConf !== "") {
            writer.uint32(26).string(message.emaConf);
        }
        if (message.conf !== "") {
            writer.uint32(34).string(message.conf);
        }
        if (message.publishTime !== "0") {
            writer.uint32(40).uint64(message.publishTime);
        }
        if (message.priceState !== undefined) {
            PriceState.encode(message.priceState, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePythPriceState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.priceId = reader.string();
                    break;
                case 2:
                    message.emaPrice = reader.string();
                    break;
                case 3:
                    message.emaConf = reader.string();
                    break;
                case 4:
                    message.conf = reader.string();
                    break;
                case 5:
                    message.publishTime = longToString(reader.uint64());
                    break;
                case 6:
                    message.priceState = PriceState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            priceId: isSet(object.priceId) ? String(object.priceId) : "",
            emaPrice: isSet(object.emaPrice) ? String(object.emaPrice) : "",
            emaConf: isSet(object.emaConf) ? String(object.emaConf) : "",
            conf: isSet(object.conf) ? String(object.conf) : "",
            publishTime: isSet(object.publishTime) ? String(object.publishTime) : "0",
            priceState: isSet(object.priceState) ? PriceState.fromJSON(object.priceState) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.priceId !== undefined && (obj.priceId = message.priceId);
        message.emaPrice !== undefined && (obj.emaPrice = message.emaPrice);
        message.emaConf !== undefined && (obj.emaConf = message.emaConf);
        message.conf !== undefined && (obj.conf = message.conf);
        message.publishTime !== undefined && (obj.publishTime = message.publishTime);
        message.priceState !== undefined &&
            (obj.priceState = message.priceState ? PriceState.toJSON(message.priceState) : undefined);
        return obj;
    },
    create(base) {
        return PythPriceState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePythPriceState();
        message.priceId = object.priceId ?? "";
        message.emaPrice = object.emaPrice ?? "";
        message.emaConf = object.emaConf ?? "";
        message.conf = object.conf ?? "";
        message.publishTime = object.publishTime ?? "0";
        message.priceState = (object.priceState !== undefined && object.priceState !== null)
            ? PriceState.fromPartial(object.priceState)
            : undefined;
        return message;
    },
};
function createBaseBandOracleRequest() {
    return {
        requestId: "0",
        oracleScriptId: "0",
        symbols: [],
        askCount: "0",
        minCount: "0",
        feeLimit: [],
        prepareGas: "0",
        executeGas: "0",
        minSourceCount: "0",
    };
}
export const BandOracleRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.requestId !== "0") {
            writer.uint32(8).uint64(message.requestId);
        }
        if (message.oracleScriptId !== "0") {
            writer.uint32(16).int64(message.oracleScriptId);
        }
        for (const v of message.symbols) {
            writer.uint32(26).string(v);
        }
        if (message.askCount !== "0") {
            writer.uint32(32).uint64(message.askCount);
        }
        if (message.minCount !== "0") {
            writer.uint32(40).uint64(message.minCount);
        }
        for (const v of message.feeLimit) {
            Coin.encode(v, writer.uint32(50).fork()).ldelim();
        }
        if (message.prepareGas !== "0") {
            writer.uint32(56).uint64(message.prepareGas);
        }
        if (message.executeGas !== "0") {
            writer.uint32(64).uint64(message.executeGas);
        }
        if (message.minSourceCount !== "0") {
            writer.uint32(72).uint64(message.minSourceCount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBandOracleRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.requestId = longToString(reader.uint64());
                    break;
                case 2:
                    message.oracleScriptId = longToString(reader.int64());
                    break;
                case 3:
                    message.symbols.push(reader.string());
                    break;
                case 4:
                    message.askCount = longToString(reader.uint64());
                    break;
                case 5:
                    message.minCount = longToString(reader.uint64());
                    break;
                case 6:
                    message.feeLimit.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.prepareGas = longToString(reader.uint64());
                    break;
                case 8:
                    message.executeGas = longToString(reader.uint64());
                    break;
                case 9:
                    message.minSourceCount = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            requestId: isSet(object.requestId) ? String(object.requestId) : "0",
            oracleScriptId: isSet(object.oracleScriptId) ? String(object.oracleScriptId) : "0",
            symbols: Array.isArray(object?.symbols) ? object.symbols.map((e) => String(e)) : [],
            askCount: isSet(object.askCount) ? String(object.askCount) : "0",
            minCount: isSet(object.minCount) ? String(object.minCount) : "0",
            feeLimit: Array.isArray(object?.feeLimit) ? object.feeLimit.map((e) => Coin.fromJSON(e)) : [],
            prepareGas: isSet(object.prepareGas) ? String(object.prepareGas) : "0",
            executeGas: isSet(object.executeGas) ? String(object.executeGas) : "0",
            minSourceCount: isSet(object.minSourceCount) ? String(object.minSourceCount) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.requestId !== undefined && (obj.requestId = message.requestId);
        message.oracleScriptId !== undefined && (obj.oracleScriptId = message.oracleScriptId);
        if (message.symbols) {
            obj.symbols = message.symbols.map((e) => e);
        }
        else {
            obj.symbols = [];
        }
        message.askCount !== undefined && (obj.askCount = message.askCount);
        message.minCount !== undefined && (obj.minCount = message.minCount);
        if (message.feeLimit) {
            obj.feeLimit = message.feeLimit.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.feeLimit = [];
        }
        message.prepareGas !== undefined && (obj.prepareGas = message.prepareGas);
        message.executeGas !== undefined && (obj.executeGas = message.executeGas);
        message.minSourceCount !== undefined && (obj.minSourceCount = message.minSourceCount);
        return obj;
    },
    create(base) {
        return BandOracleRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBandOracleRequest();
        message.requestId = object.requestId ?? "0";
        message.oracleScriptId = object.oracleScriptId ?? "0";
        message.symbols = object.symbols?.map((e) => e) || [];
        message.askCount = object.askCount ?? "0";
        message.minCount = object.minCount ?? "0";
        message.feeLimit = object.feeLimit?.map((e) => Coin.fromPartial(e)) || [];
        message.prepareGas = object.prepareGas ?? "0";
        message.executeGas = object.executeGas ?? "0";
        message.minSourceCount = object.minSourceCount ?? "0";
        return message;
    },
};
function createBaseBandIBCParams() {
    return {
        bandIbcEnabled: false,
        ibcRequestInterval: "0",
        ibcSourceChannel: "",
        ibcVersion: "",
        ibcPortId: "",
        legacyOracleIds: [],
    };
}
export const BandIBCParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bandIbcEnabled === true) {
            writer.uint32(8).bool(message.bandIbcEnabled);
        }
        if (message.ibcRequestInterval !== "0") {
            writer.uint32(16).int64(message.ibcRequestInterval);
        }
        if (message.ibcSourceChannel !== "") {
            writer.uint32(26).string(message.ibcSourceChannel);
        }
        if (message.ibcVersion !== "") {
            writer.uint32(34).string(message.ibcVersion);
        }
        if (message.ibcPortId !== "") {
            writer.uint32(42).string(message.ibcPortId);
        }
        writer.uint32(50).fork();
        for (const v of message.legacyOracleIds) {
            writer.int64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBandIBCParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bandIbcEnabled = reader.bool();
                    break;
                case 2:
                    message.ibcRequestInterval = longToString(reader.int64());
                    break;
                case 3:
                    message.ibcSourceChannel = reader.string();
                    break;
                case 4:
                    message.ibcVersion = reader.string();
                    break;
                case 5:
                    message.ibcPortId = reader.string();
                    break;
                case 6:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.legacyOracleIds.push(longToString(reader.int64()));
                        }
                    }
                    else {
                        message.legacyOracleIds.push(longToString(reader.int64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bandIbcEnabled: isSet(object.bandIbcEnabled) ? Boolean(object.bandIbcEnabled) : false,
            ibcRequestInterval: isSet(object.ibcRequestInterval) ? String(object.ibcRequestInterval) : "0",
            ibcSourceChannel: isSet(object.ibcSourceChannel) ? String(object.ibcSourceChannel) : "",
            ibcVersion: isSet(object.ibcVersion) ? String(object.ibcVersion) : "",
            ibcPortId: isSet(object.ibcPortId) ? String(object.ibcPortId) : "",
            legacyOracleIds: Array.isArray(object?.legacyOracleIds) ? object.legacyOracleIds.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.bandIbcEnabled !== undefined && (obj.bandIbcEnabled = message.bandIbcEnabled);
        message.ibcRequestInterval !== undefined && (obj.ibcRequestInterval = message.ibcRequestInterval);
        message.ibcSourceChannel !== undefined && (obj.ibcSourceChannel = message.ibcSourceChannel);
        message.ibcVersion !== undefined && (obj.ibcVersion = message.ibcVersion);
        message.ibcPortId !== undefined && (obj.ibcPortId = message.ibcPortId);
        if (message.legacyOracleIds) {
            obj.legacyOracleIds = message.legacyOracleIds.map((e) => e);
        }
        else {
            obj.legacyOracleIds = [];
        }
        return obj;
    },
    create(base) {
        return BandIBCParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBandIBCParams();
        message.bandIbcEnabled = object.bandIbcEnabled ?? false;
        message.ibcRequestInterval = object.ibcRequestInterval ?? "0";
        message.ibcSourceChannel = object.ibcSourceChannel ?? "";
        message.ibcVersion = object.ibcVersion ?? "";
        message.ibcPortId = object.ibcPortId ?? "";
        message.legacyOracleIds = object.legacyOracleIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseSymbolPriceTimestamp() {
    return { oracle: 0, symbolId: "", timestamp: "0" };
}
export const SymbolPriceTimestamp = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.oracle !== 0) {
            writer.uint32(8).int32(message.oracle);
        }
        if (message.symbolId !== "") {
            writer.uint32(18).string(message.symbolId);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).int64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSymbolPriceTimestamp();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.oracle = reader.int32();
                    break;
                case 2:
                    message.symbolId = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            oracle: isSet(object.oracle) ? oracleTypeFromJSON(object.oracle) : 0,
            symbolId: isSet(object.symbolId) ? String(object.symbolId) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.oracle !== undefined && (obj.oracle = oracleTypeToJSON(message.oracle));
        message.symbolId !== undefined && (obj.symbolId = message.symbolId);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return SymbolPriceTimestamp.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSymbolPriceTimestamp();
        message.oracle = object.oracle ?? 0;
        message.symbolId = object.symbolId ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseLastPriceTimestamps() {
    return { lastPriceTimestamps: [] };
}
export const LastPriceTimestamps = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.lastPriceTimestamps) {
            SymbolPriceTimestamp.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLastPriceTimestamps();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lastPriceTimestamps.push(SymbolPriceTimestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            lastPriceTimestamps: Array.isArray(object?.lastPriceTimestamps)
                ? object.lastPriceTimestamps.map((e) => SymbolPriceTimestamp.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.lastPriceTimestamps) {
            obj.lastPriceTimestamps = message.lastPriceTimestamps.map((e) => e ? SymbolPriceTimestamp.toJSON(e) : undefined);
        }
        else {
            obj.lastPriceTimestamps = [];
        }
        return obj;
    },
    create(base) {
        return LastPriceTimestamps.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLastPriceTimestamps();
        message.lastPriceTimestamps = object.lastPriceTimestamps?.map((e) => SymbolPriceTimestamp.fromPartial(e)) || [];
        return message;
    },
};
function createBasePriceRecords() {
    return { oracle: 0, symbolId: "", latestPriceRecords: [] };
}
export const PriceRecords = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.oracle !== 0) {
            writer.uint32(8).int32(message.oracle);
        }
        if (message.symbolId !== "") {
            writer.uint32(18).string(message.symbolId);
        }
        for (const v of message.latestPriceRecords) {
            PriceRecord.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceRecords();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.oracle = reader.int32();
                    break;
                case 2:
                    message.symbolId = reader.string();
                    break;
                case 3:
                    message.latestPriceRecords.push(PriceRecord.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            oracle: isSet(object.oracle) ? oracleTypeFromJSON(object.oracle) : 0,
            symbolId: isSet(object.symbolId) ? String(object.symbolId) : "",
            latestPriceRecords: Array.isArray(object?.latestPriceRecords)
                ? object.latestPriceRecords.map((e) => PriceRecord.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.oracle !== undefined && (obj.oracle = oracleTypeToJSON(message.oracle));
        message.symbolId !== undefined && (obj.symbolId = message.symbolId);
        if (message.latestPriceRecords) {
            obj.latestPriceRecords = message.latestPriceRecords.map((e) => e ? PriceRecord.toJSON(e) : undefined);
        }
        else {
            obj.latestPriceRecords = [];
        }
        return obj;
    },
    create(base) {
        return PriceRecords.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceRecords();
        message.oracle = object.oracle ?? 0;
        message.symbolId = object.symbolId ?? "";
        message.latestPriceRecords = object.latestPriceRecords?.map((e) => PriceRecord.fromPartial(e)) || [];
        return message;
    },
};
function createBasePriceRecord() {
    return { timestamp: "0", price: "" };
}
export const PriceRecord = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.timestamp !== "0") {
            writer.uint32(8).int64(message.timestamp);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.timestamp = longToString(reader.int64());
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            price: isSet(object.price) ? String(object.price) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    create(base) {
        return PriceRecord.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceRecord();
        message.timestamp = object.timestamp ?? "0";
        message.price = object.price ?? "";
        return message;
    },
};
function createBaseMetadataStatistics() {
    return {
        groupCount: 0,
        recordsSampleSize: 0,
        mean: "",
        twap: "",
        firstTimestamp: "0",
        lastTimestamp: "0",
        minPrice: "",
        maxPrice: "",
        medianPrice: "",
    };
}
export const MetadataStatistics = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.groupCount !== 0) {
            writer.uint32(8).uint32(message.groupCount);
        }
        if (message.recordsSampleSize !== 0) {
            writer.uint32(16).uint32(message.recordsSampleSize);
        }
        if (message.mean !== "") {
            writer.uint32(26).string(message.mean);
        }
        if (message.twap !== "") {
            writer.uint32(34).string(message.twap);
        }
        if (message.firstTimestamp !== "0") {
            writer.uint32(40).int64(message.firstTimestamp);
        }
        if (message.lastTimestamp !== "0") {
            writer.uint32(48).int64(message.lastTimestamp);
        }
        if (message.minPrice !== "") {
            writer.uint32(58).string(message.minPrice);
        }
        if (message.maxPrice !== "") {
            writer.uint32(66).string(message.maxPrice);
        }
        if (message.medianPrice !== "") {
            writer.uint32(74).string(message.medianPrice);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetadataStatistics();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupCount = reader.uint32();
                    break;
                case 2:
                    message.recordsSampleSize = reader.uint32();
                    break;
                case 3:
                    message.mean = reader.string();
                    break;
                case 4:
                    message.twap = reader.string();
                    break;
                case 5:
                    message.firstTimestamp = longToString(reader.int64());
                    break;
                case 6:
                    message.lastTimestamp = longToString(reader.int64());
                    break;
                case 7:
                    message.minPrice = reader.string();
                    break;
                case 8:
                    message.maxPrice = reader.string();
                    break;
                case 9:
                    message.medianPrice = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            groupCount: isSet(object.groupCount) ? Number(object.groupCount) : 0,
            recordsSampleSize: isSet(object.recordsSampleSize) ? Number(object.recordsSampleSize) : 0,
            mean: isSet(object.mean) ? String(object.mean) : "",
            twap: isSet(object.twap) ? String(object.twap) : "",
            firstTimestamp: isSet(object.firstTimestamp) ? String(object.firstTimestamp) : "0",
            lastTimestamp: isSet(object.lastTimestamp) ? String(object.lastTimestamp) : "0",
            minPrice: isSet(object.minPrice) ? String(object.minPrice) : "",
            maxPrice: isSet(object.maxPrice) ? String(object.maxPrice) : "",
            medianPrice: isSet(object.medianPrice) ? String(object.medianPrice) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.groupCount !== undefined && (obj.groupCount = Math.round(message.groupCount));
        message.recordsSampleSize !== undefined && (obj.recordsSampleSize = Math.round(message.recordsSampleSize));
        message.mean !== undefined && (obj.mean = message.mean);
        message.twap !== undefined && (obj.twap = message.twap);
        message.firstTimestamp !== undefined && (obj.firstTimestamp = message.firstTimestamp);
        message.lastTimestamp !== undefined && (obj.lastTimestamp = message.lastTimestamp);
        message.minPrice !== undefined && (obj.minPrice = message.minPrice);
        message.maxPrice !== undefined && (obj.maxPrice = message.maxPrice);
        message.medianPrice !== undefined && (obj.medianPrice = message.medianPrice);
        return obj;
    },
    create(base) {
        return MetadataStatistics.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMetadataStatistics();
        message.groupCount = object.groupCount ?? 0;
        message.recordsSampleSize = object.recordsSampleSize ?? 0;
        message.mean = object.mean ?? "";
        message.twap = object.twap ?? "";
        message.firstTimestamp = object.firstTimestamp ?? "0";
        message.lastTimestamp = object.lastTimestamp ?? "0";
        message.minPrice = object.minPrice ?? "";
        message.maxPrice = object.maxPrice ?? "";
        message.medianPrice = object.medianPrice ?? "";
        return message;
    },
};
function createBasePriceAttestation() {
    return { priceId: "", price: "0", conf: "0", expo: 0, emaPrice: "0", emaConf: "0", emaExpo: 0, publishTime: "0" };
}
export const PriceAttestation = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.priceId !== "") {
            writer.uint32(10).string(message.priceId);
        }
        if (message.price !== "0") {
            writer.uint32(16).int64(message.price);
        }
        if (message.conf !== "0") {
            writer.uint32(24).uint64(message.conf);
        }
        if (message.expo !== 0) {
            writer.uint32(32).int32(message.expo);
        }
        if (message.emaPrice !== "0") {
            writer.uint32(40).int64(message.emaPrice);
        }
        if (message.emaConf !== "0") {
            writer.uint32(48).uint64(message.emaConf);
        }
        if (message.emaExpo !== 0) {
            writer.uint32(56).int32(message.emaExpo);
        }
        if (message.publishTime !== "0") {
            writer.uint32(64).int64(message.publishTime);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceAttestation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.priceId = reader.string();
                    break;
                case 2:
                    message.price = longToString(reader.int64());
                    break;
                case 3:
                    message.conf = longToString(reader.uint64());
                    break;
                case 4:
                    message.expo = reader.int32();
                    break;
                case 5:
                    message.emaPrice = longToString(reader.int64());
                    break;
                case 6:
                    message.emaConf = longToString(reader.uint64());
                    break;
                case 7:
                    message.emaExpo = reader.int32();
                    break;
                case 8:
                    message.publishTime = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            priceId: isSet(object.priceId) ? String(object.priceId) : "",
            price: isSet(object.price) ? String(object.price) : "0",
            conf: isSet(object.conf) ? String(object.conf) : "0",
            expo: isSet(object.expo) ? Number(object.expo) : 0,
            emaPrice: isSet(object.emaPrice) ? String(object.emaPrice) : "0",
            emaConf: isSet(object.emaConf) ? String(object.emaConf) : "0",
            emaExpo: isSet(object.emaExpo) ? Number(object.emaExpo) : 0,
            publishTime: isSet(object.publishTime) ? String(object.publishTime) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.priceId !== undefined && (obj.priceId = message.priceId);
        message.price !== undefined && (obj.price = message.price);
        message.conf !== undefined && (obj.conf = message.conf);
        message.expo !== undefined && (obj.expo = Math.round(message.expo));
        message.emaPrice !== undefined && (obj.emaPrice = message.emaPrice);
        message.emaConf !== undefined && (obj.emaConf = message.emaConf);
        message.emaExpo !== undefined && (obj.emaExpo = Math.round(message.emaExpo));
        message.publishTime !== undefined && (obj.publishTime = message.publishTime);
        return obj;
    },
    create(base) {
        return PriceAttestation.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePriceAttestation();
        message.priceId = object.priceId ?? "";
        message.price = object.price ?? "0";
        message.conf = object.conf ?? "0";
        message.expo = object.expo ?? 0;
        message.emaPrice = object.emaPrice ?? "0";
        message.emaConf = object.emaConf ?? "0";
        message.emaExpo = object.emaExpo ?? 0;
        message.publishTime = object.publishTime ?? "0";
        return message;
    },
};
function createBaseAssetPair() {
    return { assetId: "", signedPrices: [] };
}
export const AssetPair = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.assetId !== "") {
            writer.uint32(10).string(message.assetId);
        }
        for (const v of message.signedPrices) {
            SignedPriceOfAssetPair.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAssetPair();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assetId = reader.string();
                    break;
                case 2:
                    message.signedPrices.push(SignedPriceOfAssetPair.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            assetId: isSet(object.assetId) ? String(object.assetId) : "",
            signedPrices: Array.isArray(object?.signedPrices)
                ? object.signedPrices.map((e) => SignedPriceOfAssetPair.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.assetId !== undefined && (obj.assetId = message.assetId);
        if (message.signedPrices) {
            obj.signedPrices = message.signedPrices.map((e) => e ? SignedPriceOfAssetPair.toJSON(e) : undefined);
        }
        else {
            obj.signedPrices = [];
        }
        return obj;
    },
    create(base) {
        return AssetPair.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAssetPair();
        message.assetId = object.assetId ?? "";
        message.signedPrices = object.signedPrices?.map((e) => SignedPriceOfAssetPair.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSignedPriceOfAssetPair() {
    return { publisherKey: "", timestamp: "0", price: "", signature: new Uint8Array() };
}
export const SignedPriceOfAssetPair = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.publisherKey !== "") {
            writer.uint32(10).string(message.publisherKey);
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).uint64(message.timestamp);
        }
        if (message.price !== "") {
            writer.uint32(26).string(message.price);
        }
        if (message.signature.length !== 0) {
            writer.uint32(34).bytes(message.signature);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSignedPriceOfAssetPair();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.publisherKey = reader.string();
                    break;
                case 2:
                    message.timestamp = longToString(reader.uint64());
                    break;
                case 3:
                    message.price = reader.string();
                    break;
                case 4:
                    message.signature = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            publisherKey: isSet(object.publisherKey) ? String(object.publisherKey) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            price: isSet(object.price) ? String(object.price) : "",
            signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.publisherKey !== undefined && (obj.publisherKey = message.publisherKey);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.price !== undefined && (obj.price = message.price);
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        return obj;
    },
    create(base) {
        return SignedPriceOfAssetPair.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSignedPriceOfAssetPair();
        message.publisherKey = object.publisherKey ?? "";
        message.timestamp = object.timestamp ?? "0";
        message.price = object.price ?? "";
        message.signature = object.signature ?? new Uint8Array();
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
