/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { Any } from "../../../google/protobuf/any.js";
import { Params } from "./params.js";
import { BridgeValidator } from "./types.js";
export const protobufPackage = "injective.peggy.v1";
function createBaseMsgSetOrchestratorAddresses() {
    return { sender: "", orchestrator: "", ethAddress: "" };
}
export const MsgSetOrchestratorAddresses = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.orchestrator !== "") {
            writer.uint32(18).string(message.orchestrator);
        }
        if (message.ethAddress !== "") {
            writer.uint32(26).string(message.ethAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSetOrchestratorAddresses();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.orchestrator = reader.string();
                    break;
                case 3:
                    message.ethAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
            ethAddress: isSet(object.ethAddress) ? String(object.ethAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        message.ethAddress !== undefined && (obj.ethAddress = message.ethAddress);
        return obj;
    },
    create(base) {
        return MsgSetOrchestratorAddresses.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgSetOrchestratorAddresses();
        message.sender = object.sender ?? "";
        message.orchestrator = object.orchestrator ?? "";
        message.ethAddress = object.ethAddress ?? "";
        return message;
    },
};
function createBaseMsgSetOrchestratorAddressesResponse() {
    return {};
}
export const MsgSetOrchestratorAddressesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSetOrchestratorAddressesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgSetOrchestratorAddressesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgSetOrchestratorAddressesResponse();
        return message;
    },
};
function createBaseMsgValsetConfirm() {
    return { nonce: "0", orchestrator: "", ethAddress: "", signature: "" };
}
export const MsgValsetConfirm = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nonce !== "0") {
            writer.uint32(8).uint64(message.nonce);
        }
        if (message.orchestrator !== "") {
            writer.uint32(18).string(message.orchestrator);
        }
        if (message.ethAddress !== "") {
            writer.uint32(26).string(message.ethAddress);
        }
        if (message.signature !== "") {
            writer.uint32(34).string(message.signature);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgValsetConfirm();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.orchestrator = reader.string();
                    break;
                case 3:
                    message.ethAddress = reader.string();
                    break;
                case 4:
                    message.signature = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nonce: isSet(object.nonce) ? String(object.nonce) : "0",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
            ethAddress: isSet(object.ethAddress) ? String(object.ethAddress) : "",
            signature: isSet(object.signature) ? String(object.signature) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.nonce !== undefined && (obj.nonce = message.nonce);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        message.ethAddress !== undefined && (obj.ethAddress = message.ethAddress);
        message.signature !== undefined && (obj.signature = message.signature);
        return obj;
    },
    create(base) {
        return MsgValsetConfirm.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgValsetConfirm();
        message.nonce = object.nonce ?? "0";
        message.orchestrator = object.orchestrator ?? "";
        message.ethAddress = object.ethAddress ?? "";
        message.signature = object.signature ?? "";
        return message;
    },
};
function createBaseMsgValsetConfirmResponse() {
    return {};
}
export const MsgValsetConfirmResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgValsetConfirmResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgValsetConfirmResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgValsetConfirmResponse();
        return message;
    },
};
function createBaseMsgSendToEth() {
    return { sender: "", ethDest: "", amount: undefined, bridgeFee: undefined };
}
export const MsgSendToEth = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.ethDest !== "") {
            writer.uint32(18).string(message.ethDest);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
        }
        if (message.bridgeFee !== undefined) {
            Coin.encode(message.bridgeFee, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSendToEth();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.ethDest = reader.string();
                    break;
                case 3:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.bridgeFee = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            ethDest: isSet(object.ethDest) ? String(object.ethDest) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
            bridgeFee: isSet(object.bridgeFee) ? Coin.fromJSON(object.bridgeFee) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.ethDest !== undefined && (obj.ethDest = message.ethDest);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        message.bridgeFee !== undefined && (obj.bridgeFee = message.bridgeFee ? Coin.toJSON(message.bridgeFee) : undefined);
        return obj;
    },
    create(base) {
        return MsgSendToEth.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgSendToEth();
        message.sender = object.sender ?? "";
        message.ethDest = object.ethDest ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        message.bridgeFee = (object.bridgeFee !== undefined && object.bridgeFee !== null)
            ? Coin.fromPartial(object.bridgeFee)
            : undefined;
        return message;
    },
};
function createBaseMsgSendToEthResponse() {
    return {};
}
export const MsgSendToEthResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSendToEthResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgSendToEthResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgSendToEthResponse();
        return message;
    },
};
function createBaseMsgRequestBatch() {
    return { orchestrator: "", denom: "" };
}
export const MsgRequestBatch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orchestrator !== "") {
            writer.uint32(10).string(message.orchestrator);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRequestBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orchestrator = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        message.denom !== undefined && (obj.denom = message.denom);
        return obj;
    },
    create(base) {
        return MsgRequestBatch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRequestBatch();
        message.orchestrator = object.orchestrator ?? "";
        message.denom = object.denom ?? "";
        return message;
    },
};
function createBaseMsgRequestBatchResponse() {
    return {};
}
export const MsgRequestBatchResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRequestBatchResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRequestBatchResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRequestBatchResponse();
        return message;
    },
};
function createBaseMsgConfirmBatch() {
    return { nonce: "0", tokenContract: "", ethSigner: "", orchestrator: "", signature: "" };
}
export const MsgConfirmBatch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nonce !== "0") {
            writer.uint32(8).uint64(message.nonce);
        }
        if (message.tokenContract !== "") {
            writer.uint32(18).string(message.tokenContract);
        }
        if (message.ethSigner !== "") {
            writer.uint32(26).string(message.ethSigner);
        }
        if (message.orchestrator !== "") {
            writer.uint32(34).string(message.orchestrator);
        }
        if (message.signature !== "") {
            writer.uint32(42).string(message.signature);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgConfirmBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.tokenContract = reader.string();
                    break;
                case 3:
                    message.ethSigner = reader.string();
                    break;
                case 4:
                    message.orchestrator = reader.string();
                    break;
                case 5:
                    message.signature = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nonce: isSet(object.nonce) ? String(object.nonce) : "0",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            ethSigner: isSet(object.ethSigner) ? String(object.ethSigner) : "",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
            signature: isSet(object.signature) ? String(object.signature) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.nonce !== undefined && (obj.nonce = message.nonce);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.ethSigner !== undefined && (obj.ethSigner = message.ethSigner);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        message.signature !== undefined && (obj.signature = message.signature);
        return obj;
    },
    create(base) {
        return MsgConfirmBatch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgConfirmBatch();
        message.nonce = object.nonce ?? "0";
        message.tokenContract = object.tokenContract ?? "";
        message.ethSigner = object.ethSigner ?? "";
        message.orchestrator = object.orchestrator ?? "";
        message.signature = object.signature ?? "";
        return message;
    },
};
function createBaseMsgConfirmBatchResponse() {
    return {};
}
export const MsgConfirmBatchResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgConfirmBatchResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgConfirmBatchResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgConfirmBatchResponse();
        return message;
    },
};
function createBaseMsgDepositClaim() {
    return {
        eventNonce: "0",
        blockHeight: "0",
        tokenContract: "",
        amount: "",
        ethereumSender: "",
        cosmosReceiver: "",
        orchestrator: "",
        data: "",
    };
}
export const MsgDepositClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.blockHeight !== "0") {
            writer.uint32(16).uint64(message.blockHeight);
        }
        if (message.tokenContract !== "") {
            writer.uint32(26).string(message.tokenContract);
        }
        if (message.amount !== "") {
            writer.uint32(34).string(message.amount);
        }
        if (message.ethereumSender !== "") {
            writer.uint32(42).string(message.ethereumSender);
        }
        if (message.cosmosReceiver !== "") {
            writer.uint32(50).string(message.cosmosReceiver);
        }
        if (message.orchestrator !== "") {
            writer.uint32(58).string(message.orchestrator);
        }
        if (message.data !== "") {
            writer.uint32(66).string(message.data);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDepositClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.blockHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.tokenContract = reader.string();
                    break;
                case 4:
                    message.amount = reader.string();
                    break;
                case 5:
                    message.ethereumSender = reader.string();
                    break;
                case 6:
                    message.cosmosReceiver = reader.string();
                    break;
                case 7:
                    message.orchestrator = reader.string();
                    break;
                case 8:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            blockHeight: isSet(object.blockHeight) ? String(object.blockHeight) : "0",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
            ethereumSender: isSet(object.ethereumSender) ? String(object.ethereumSender) : "",
            cosmosReceiver: isSet(object.cosmosReceiver) ? String(object.cosmosReceiver) : "",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
            data: isSet(object.data) ? String(object.data) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.blockHeight !== undefined && (obj.blockHeight = message.blockHeight);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.amount !== undefined && (obj.amount = message.amount);
        message.ethereumSender !== undefined && (obj.ethereumSender = message.ethereumSender);
        message.cosmosReceiver !== undefined && (obj.cosmosReceiver = message.cosmosReceiver);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        message.data !== undefined && (obj.data = message.data);
        return obj;
    },
    create(base) {
        return MsgDepositClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgDepositClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.blockHeight = object.blockHeight ?? "0";
        message.tokenContract = object.tokenContract ?? "";
        message.amount = object.amount ?? "";
        message.ethereumSender = object.ethereumSender ?? "";
        message.cosmosReceiver = object.cosmosReceiver ?? "";
        message.orchestrator = object.orchestrator ?? "";
        message.data = object.data ?? "";
        return message;
    },
};
function createBaseMsgDepositClaimResponse() {
    return {};
}
export const MsgDepositClaimResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDepositClaimResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgDepositClaimResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgDepositClaimResponse();
        return message;
    },
};
function createBaseMsgWithdrawClaim() {
    return { eventNonce: "0", blockHeight: "0", batchNonce: "0", tokenContract: "", orchestrator: "" };
}
export const MsgWithdrawClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.blockHeight !== "0") {
            writer.uint32(16).uint64(message.blockHeight);
        }
        if (message.batchNonce !== "0") {
            writer.uint32(24).uint64(message.batchNonce);
        }
        if (message.tokenContract !== "") {
            writer.uint32(34).string(message.tokenContract);
        }
        if (message.orchestrator !== "") {
            writer.uint32(42).string(message.orchestrator);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgWithdrawClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.blockHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.batchNonce = longToString(reader.uint64());
                    break;
                case 4:
                    message.tokenContract = reader.string();
                    break;
                case 5:
                    message.orchestrator = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            blockHeight: isSet(object.blockHeight) ? String(object.blockHeight) : "0",
            batchNonce: isSet(object.batchNonce) ? String(object.batchNonce) : "0",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.blockHeight !== undefined && (obj.blockHeight = message.blockHeight);
        message.batchNonce !== undefined && (obj.batchNonce = message.batchNonce);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        return obj;
    },
    create(base) {
        return MsgWithdrawClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgWithdrawClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.blockHeight = object.blockHeight ?? "0";
        message.batchNonce = object.batchNonce ?? "0";
        message.tokenContract = object.tokenContract ?? "";
        message.orchestrator = object.orchestrator ?? "";
        return message;
    },
};
function createBaseMsgWithdrawClaimResponse() {
    return {};
}
export const MsgWithdrawClaimResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgWithdrawClaimResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgWithdrawClaimResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgWithdrawClaimResponse();
        return message;
    },
};
function createBaseMsgERC20DeployedClaim() {
    return {
        eventNonce: "0",
        blockHeight: "0",
        cosmosDenom: "",
        tokenContract: "",
        name: "",
        symbol: "",
        decimals: "0",
        orchestrator: "",
    };
}
export const MsgERC20DeployedClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.blockHeight !== "0") {
            writer.uint32(16).uint64(message.blockHeight);
        }
        if (message.cosmosDenom !== "") {
            writer.uint32(26).string(message.cosmosDenom);
        }
        if (message.tokenContract !== "") {
            writer.uint32(34).string(message.tokenContract);
        }
        if (message.name !== "") {
            writer.uint32(42).string(message.name);
        }
        if (message.symbol !== "") {
            writer.uint32(50).string(message.symbol);
        }
        if (message.decimals !== "0") {
            writer.uint32(56).uint64(message.decimals);
        }
        if (message.orchestrator !== "") {
            writer.uint32(66).string(message.orchestrator);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgERC20DeployedClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.blockHeight = longToString(reader.uint64());
                    break;
                case 3:
                    message.cosmosDenom = reader.string();
                    break;
                case 4:
                    message.tokenContract = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.symbol = reader.string();
                    break;
                case 7:
                    message.decimals = longToString(reader.uint64());
                    break;
                case 8:
                    message.orchestrator = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            blockHeight: isSet(object.blockHeight) ? String(object.blockHeight) : "0",
            cosmosDenom: isSet(object.cosmosDenom) ? String(object.cosmosDenom) : "",
            tokenContract: isSet(object.tokenContract) ? String(object.tokenContract) : "",
            name: isSet(object.name) ? String(object.name) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            decimals: isSet(object.decimals) ? String(object.decimals) : "0",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.blockHeight !== undefined && (obj.blockHeight = message.blockHeight);
        message.cosmosDenom !== undefined && (obj.cosmosDenom = message.cosmosDenom);
        message.tokenContract !== undefined && (obj.tokenContract = message.tokenContract);
        message.name !== undefined && (obj.name = message.name);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.decimals !== undefined && (obj.decimals = message.decimals);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        return obj;
    },
    create(base) {
        return MsgERC20DeployedClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgERC20DeployedClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.blockHeight = object.blockHeight ?? "0";
        message.cosmosDenom = object.cosmosDenom ?? "";
        message.tokenContract = object.tokenContract ?? "";
        message.name = object.name ?? "";
        message.symbol = object.symbol ?? "";
        message.decimals = object.decimals ?? "0";
        message.orchestrator = object.orchestrator ?? "";
        return message;
    },
};
function createBaseMsgERC20DeployedClaimResponse() {
    return {};
}
export const MsgERC20DeployedClaimResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgERC20DeployedClaimResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgERC20DeployedClaimResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgERC20DeployedClaimResponse();
        return message;
    },
};
function createBaseMsgCancelSendToEth() {
    return { transactionId: "0", sender: "" };
}
export const MsgCancelSendToEth = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.transactionId !== "0") {
            writer.uint32(8).uint64(message.transactionId);
        }
        if (message.sender !== "") {
            writer.uint32(18).string(message.sender);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelSendToEth();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.transactionId = longToString(reader.uint64());
                    break;
                case 2:
                    message.sender = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            transactionId: isSet(object.transactionId) ? String(object.transactionId) : "0",
            sender: isSet(object.sender) ? String(object.sender) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.transactionId !== undefined && (obj.transactionId = message.transactionId);
        message.sender !== undefined && (obj.sender = message.sender);
        return obj;
    },
    create(base) {
        return MsgCancelSendToEth.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCancelSendToEth();
        message.transactionId = object.transactionId ?? "0";
        message.sender = object.sender ?? "";
        return message;
    },
};
function createBaseMsgCancelSendToEthResponse() {
    return {};
}
export const MsgCancelSendToEthResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelSendToEthResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgCancelSendToEthResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgCancelSendToEthResponse();
        return message;
    },
};
function createBaseMsgSubmitBadSignatureEvidence() {
    return { subject: undefined, signature: "", sender: "" };
}
export const MsgSubmitBadSignatureEvidence = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.subject !== undefined) {
            Any.encode(message.subject, writer.uint32(10).fork()).ldelim();
        }
        if (message.signature !== "") {
            writer.uint32(18).string(message.signature);
        }
        if (message.sender !== "") {
            writer.uint32(26).string(message.sender);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSubmitBadSignatureEvidence();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subject = Any.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.signature = reader.string();
                    break;
                case 3:
                    message.sender = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            subject: isSet(object.subject) ? Any.fromJSON(object.subject) : undefined,
            signature: isSet(object.signature) ? String(object.signature) : "",
            sender: isSet(object.sender) ? String(object.sender) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.subject !== undefined && (obj.subject = message.subject ? Any.toJSON(message.subject) : undefined);
        message.signature !== undefined && (obj.signature = message.signature);
        message.sender !== undefined && (obj.sender = message.sender);
        return obj;
    },
    create(base) {
        return MsgSubmitBadSignatureEvidence.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgSubmitBadSignatureEvidence();
        message.subject = (object.subject !== undefined && object.subject !== null)
            ? Any.fromPartial(object.subject)
            : undefined;
        message.signature = object.signature ?? "";
        message.sender = object.sender ?? "";
        return message;
    },
};
function createBaseMsgSubmitBadSignatureEvidenceResponse() {
    return {};
}
export const MsgSubmitBadSignatureEvidenceResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSubmitBadSignatureEvidenceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgSubmitBadSignatureEvidenceResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgSubmitBadSignatureEvidenceResponse();
        return message;
    },
};
function createBaseMsgValsetUpdatedClaim() {
    return {
        eventNonce: "0",
        valsetNonce: "0",
        blockHeight: "0",
        members: [],
        rewardAmount: "",
        rewardToken: "",
        orchestrator: "",
    };
}
export const MsgValsetUpdatedClaim = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventNonce !== "0") {
            writer.uint32(8).uint64(message.eventNonce);
        }
        if (message.valsetNonce !== "0") {
            writer.uint32(16).uint64(message.valsetNonce);
        }
        if (message.blockHeight !== "0") {
            writer.uint32(24).uint64(message.blockHeight);
        }
        for (const v of message.members) {
            BridgeValidator.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.rewardAmount !== "") {
            writer.uint32(42).string(message.rewardAmount);
        }
        if (message.rewardToken !== "") {
            writer.uint32(50).string(message.rewardToken);
        }
        if (message.orchestrator !== "") {
            writer.uint32(58).string(message.orchestrator);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgValsetUpdatedClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.valsetNonce = longToString(reader.uint64());
                    break;
                case 3:
                    message.blockHeight = longToString(reader.uint64());
                    break;
                case 4:
                    message.members.push(BridgeValidator.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.rewardAmount = reader.string();
                    break;
                case 6:
                    message.rewardToken = reader.string();
                    break;
                case 7:
                    message.orchestrator = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventNonce: isSet(object.eventNonce) ? String(object.eventNonce) : "0",
            valsetNonce: isSet(object.valsetNonce) ? String(object.valsetNonce) : "0",
            blockHeight: isSet(object.blockHeight) ? String(object.blockHeight) : "0",
            members: Array.isArray(object?.members) ? object.members.map((e) => BridgeValidator.fromJSON(e)) : [],
            rewardAmount: isSet(object.rewardAmount) ? String(object.rewardAmount) : "",
            rewardToken: isSet(object.rewardToken) ? String(object.rewardToken) : "",
            orchestrator: isSet(object.orchestrator) ? String(object.orchestrator) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.eventNonce !== undefined && (obj.eventNonce = message.eventNonce);
        message.valsetNonce !== undefined && (obj.valsetNonce = message.valsetNonce);
        message.blockHeight !== undefined && (obj.blockHeight = message.blockHeight);
        if (message.members) {
            obj.members = message.members.map((e) => e ? BridgeValidator.toJSON(e) : undefined);
        }
        else {
            obj.members = [];
        }
        message.rewardAmount !== undefined && (obj.rewardAmount = message.rewardAmount);
        message.rewardToken !== undefined && (obj.rewardToken = message.rewardToken);
        message.orchestrator !== undefined && (obj.orchestrator = message.orchestrator);
        return obj;
    },
    create(base) {
        return MsgValsetUpdatedClaim.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgValsetUpdatedClaim();
        message.eventNonce = object.eventNonce ?? "0";
        message.valsetNonce = object.valsetNonce ?? "0";
        message.blockHeight = object.blockHeight ?? "0";
        message.members = object.members?.map((e) => BridgeValidator.fromPartial(e)) || [];
        message.rewardAmount = object.rewardAmount ?? "";
        message.rewardToken = object.rewardToken ?? "";
        message.orchestrator = object.orchestrator ?? "";
        return message;
    },
};
function createBaseMsgValsetUpdatedClaimResponse() {
    return {};
}
export const MsgValsetUpdatedClaimResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgValsetUpdatedClaimResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgValsetUpdatedClaimResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgValsetUpdatedClaimResponse();
        return message;
    },
};
function createBaseMsgUpdateParams() {
    return { authority: "", params: undefined };
}
export const MsgUpdateParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.authority !== "") {
            writer.uint32(10).string(message.authority);
        }
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.authority = reader.string();
                    break;
                case 2:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            authority: isSet(object.authority) ? String(object.authority) : "",
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.authority !== undefined && (obj.authority = message.authority);
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return MsgUpdateParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateParams();
        message.authority = object.authority ?? "";
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseMsgUpdateParamsResponse() {
    return {};
}
export const MsgUpdateParamsResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateParamsResponse();
        return message;
    },
};
function createBaseMsgBlacklistEthereumAddresses() {
    return { signer: "", blacklistAddresses: [] };
}
export const MsgBlacklistEthereumAddresses = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.signer !== "") {
            writer.uint32(10).string(message.signer);
        }
        for (const v of message.blacklistAddresses) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBlacklistEthereumAddresses();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.signer = reader.string();
                    break;
                case 2:
                    message.blacklistAddresses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            signer: isSet(object.signer) ? String(object.signer) : "",
            blacklistAddresses: Array.isArray(object?.blacklistAddresses)
                ? object.blacklistAddresses.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.signer !== undefined && (obj.signer = message.signer);
        if (message.blacklistAddresses) {
            obj.blacklistAddresses = message.blacklistAddresses.map((e) => e);
        }
        else {
            obj.blacklistAddresses = [];
        }
        return obj;
    },
    create(base) {
        return MsgBlacklistEthereumAddresses.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBlacklistEthereumAddresses();
        message.signer = object.signer ?? "";
        message.blacklistAddresses = object.blacklistAddresses?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgBlacklistEthereumAddressesResponse() {
    return {};
}
export const MsgBlacklistEthereumAddressesResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBlacklistEthereumAddressesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgBlacklistEthereumAddressesResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgBlacklistEthereumAddressesResponse();
        return message;
    },
};
function createBaseMsgRevokeEthereumBlacklist() {
    return { signer: "", blacklistAddresses: [] };
}
export const MsgRevokeEthereumBlacklist = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.signer !== "") {
            writer.uint32(10).string(message.signer);
        }
        for (const v of message.blacklistAddresses) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRevokeEthereumBlacklist();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.signer = reader.string();
                    break;
                case 2:
                    message.blacklistAddresses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            signer: isSet(object.signer) ? String(object.signer) : "",
            blacklistAddresses: Array.isArray(object?.blacklistAddresses)
                ? object.blacklistAddresses.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.signer !== undefined && (obj.signer = message.signer);
        if (message.blacklistAddresses) {
            obj.blacklistAddresses = message.blacklistAddresses.map((e) => e);
        }
        else {
            obj.blacklistAddresses = [];
        }
        return obj;
    },
    create(base) {
        return MsgRevokeEthereumBlacklist.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRevokeEthereumBlacklist();
        message.signer = object.signer ?? "";
        message.blacklistAddresses = object.blacklistAddresses?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgRevokeEthereumBlacklistResponse() {
    return {};
}
export const MsgRevokeEthereumBlacklistResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRevokeEthereumBlacklistResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRevokeEthereumBlacklistResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRevokeEthereumBlacklistResponse();
        return message;
    },
};
export class MsgClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.ValsetConfirm = this.ValsetConfirm.bind(this);
        this.SendToEth = this.SendToEth.bind(this);
        this.RequestBatch = this.RequestBatch.bind(this);
        this.ConfirmBatch = this.ConfirmBatch.bind(this);
        this.DepositClaim = this.DepositClaim.bind(this);
        this.WithdrawClaim = this.WithdrawClaim.bind(this);
        this.ValsetUpdateClaim = this.ValsetUpdateClaim.bind(this);
        this.ERC20DeployedClaim = this.ERC20DeployedClaim.bind(this);
        this.SetOrchestratorAddresses = this.SetOrchestratorAddresses.bind(this);
        this.CancelSendToEth = this.CancelSendToEth.bind(this);
        this.SubmitBadSignatureEvidence = this.SubmitBadSignatureEvidence.bind(this);
        this.UpdateParams = this.UpdateParams.bind(this);
        this.BlacklistEthereumAddresses = this.BlacklistEthereumAddresses.bind(this);
        this.RevokeEthereumBlacklist = this.RevokeEthereumBlacklist.bind(this);
    }
    ValsetConfirm(request, metadata) {
        return this.rpc.unary(MsgValsetConfirmDesc, MsgValsetConfirm.fromPartial(request), metadata);
    }
    SendToEth(request, metadata) {
        return this.rpc.unary(MsgSendToEthDesc, MsgSendToEth.fromPartial(request), metadata);
    }
    RequestBatch(request, metadata) {
        return this.rpc.unary(MsgRequestBatchDesc, MsgRequestBatch.fromPartial(request), metadata);
    }
    ConfirmBatch(request, metadata) {
        return this.rpc.unary(MsgConfirmBatchDesc, MsgConfirmBatch.fromPartial(request), metadata);
    }
    DepositClaim(request, metadata) {
        return this.rpc.unary(MsgDepositClaimDesc, MsgDepositClaim.fromPartial(request), metadata);
    }
    WithdrawClaim(request, metadata) {
        return this.rpc.unary(MsgWithdrawClaimDesc, MsgWithdrawClaim.fromPartial(request), metadata);
    }
    ValsetUpdateClaim(request, metadata) {
        return this.rpc.unary(MsgValsetUpdateClaimDesc, MsgValsetUpdatedClaim.fromPartial(request), metadata);
    }
    ERC20DeployedClaim(request, metadata) {
        return this.rpc.unary(MsgERC20DeployedClaimDesc, MsgERC20DeployedClaim.fromPartial(request), metadata);
    }
    SetOrchestratorAddresses(request, metadata) {
        return this.rpc.unary(MsgSetOrchestratorAddressesDesc, MsgSetOrchestratorAddresses.fromPartial(request), metadata);
    }
    CancelSendToEth(request, metadata) {
        return this.rpc.unary(MsgCancelSendToEthDesc, MsgCancelSendToEth.fromPartial(request), metadata);
    }
    SubmitBadSignatureEvidence(request, metadata) {
        return this.rpc.unary(MsgSubmitBadSignatureEvidenceDesc, MsgSubmitBadSignatureEvidence.fromPartial(request), metadata);
    }
    UpdateParams(request, metadata) {
        return this.rpc.unary(MsgUpdateParamsDesc, MsgUpdateParams.fromPartial(request), metadata);
    }
    BlacklistEthereumAddresses(request, metadata) {
        return this.rpc.unary(MsgBlacklistEthereumAddressesDesc, MsgBlacklistEthereumAddresses.fromPartial(request), metadata);
    }
    RevokeEthereumBlacklist(request, metadata) {
        return this.rpc.unary(MsgRevokeEthereumBlacklistDesc, MsgRevokeEthereumBlacklist.fromPartial(request), metadata);
    }
}
export const MsgDesc = { serviceName: "injective.peggy.v1.Msg" };
export const MsgValsetConfirmDesc = {
    methodName: "ValsetConfirm",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgValsetConfirm.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgValsetConfirmResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgSendToEthDesc = {
    methodName: "SendToEth",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgSendToEth.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgSendToEthResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRequestBatchDesc = {
    methodName: "RequestBatch",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRequestBatch.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRequestBatchResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgConfirmBatchDesc = {
    methodName: "ConfirmBatch",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgConfirmBatch.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgConfirmBatchResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgDepositClaimDesc = {
    methodName: "DepositClaim",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgDepositClaim.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgDepositClaimResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgWithdrawClaimDesc = {
    methodName: "WithdrawClaim",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgWithdrawClaim.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgWithdrawClaimResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgValsetUpdateClaimDesc = {
    methodName: "ValsetUpdateClaim",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgValsetUpdatedClaim.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgValsetUpdatedClaimResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgERC20DeployedClaimDesc = {
    methodName: "ERC20DeployedClaim",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgERC20DeployedClaim.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgERC20DeployedClaimResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgSetOrchestratorAddressesDesc = {
    methodName: "SetOrchestratorAddresses",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgSetOrchestratorAddresses.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgSetOrchestratorAddressesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCancelSendToEthDesc = {
    methodName: "CancelSendToEth",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCancelSendToEth.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCancelSendToEthResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgSubmitBadSignatureEvidenceDesc = {
    methodName: "SubmitBadSignatureEvidence",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgSubmitBadSignatureEvidence.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgSubmitBadSignatureEvidenceResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateParamsDesc = {
    methodName: "UpdateParams",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateParams.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBlacklistEthereumAddressesDesc = {
    methodName: "BlacklistEthereumAddresses",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBlacklistEthereumAddresses.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBlacklistEthereumAddressesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRevokeEthereumBlacklistDesc = {
    methodName: "RevokeEthereumBlacklist",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRevokeEthereumBlacklist.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRevokeEthereumBlacklistResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
