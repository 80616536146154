/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "injective_meta_rpc";
function createBasePingRequest() {
    return {};
}
export const PingRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePingRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return PingRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePingRequest();
        return message;
    },
};
function createBasePingResponse() {
    return {};
}
export const PingResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePingResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return PingResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePingResponse();
        return message;
    },
};
function createBaseVersionRequest() {
    return {};
}
export const VersionRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVersionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return VersionRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseVersionRequest();
        return message;
    },
};
function createBaseVersionResponse() {
    return { version: "", build: {} };
}
export const VersionResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.version !== "") {
            writer.uint32(10).string(message.version);
        }
        Object.entries(message.build).forEach(([key, value]) => {
            VersionResponse_BuildEntry.encode({ key: key, value }, writer.uint32(18).fork()).ldelim();
        });
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVersionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.version = reader.string();
                    break;
                case 2:
                    const entry2 = VersionResponse_BuildEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.build[entry2.key] = entry2.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            version: isSet(object.version) ? String(object.version) : "",
            build: isObject(object.build)
                ? Object.entries(object.build).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message) {
        const obj = {};
        message.version !== undefined && (obj.version = message.version);
        obj.build = {};
        if (message.build) {
            Object.entries(message.build).forEach(([k, v]) => {
                obj.build[k] = v;
            });
        }
        return obj;
    },
    create(base) {
        return VersionResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVersionResponse();
        message.version = object.version ?? "";
        message.build = Object.entries(object.build ?? {}).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseVersionResponse_BuildEntry() {
    return { key: "", value: "" };
}
export const VersionResponse_BuildEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVersionResponse_BuildEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    create(base) {
        return VersionResponse_BuildEntry.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVersionResponse_BuildEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseInfoRequest() {
    return { timestamp: "0" };
}
export const InfoRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.timestamp !== "0") {
            writer.uint32(8).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInfoRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return InfoRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInfoRequest();
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseInfoResponse() {
    return { timestamp: "0", serverTime: "0", version: "", build: {}, region: "" };
}
export const InfoResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.timestamp !== "0") {
            writer.uint32(8).sint64(message.timestamp);
        }
        if (message.serverTime !== "0") {
            writer.uint32(16).sint64(message.serverTime);
        }
        if (message.version !== "") {
            writer.uint32(26).string(message.version);
        }
        Object.entries(message.build).forEach(([key, value]) => {
            InfoResponse_BuildEntry.encode({ key: key, value }, writer.uint32(34).fork()).ldelim();
        });
        if (message.region !== "") {
            writer.uint32(42).string(message.region);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInfoResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.timestamp = longToString(reader.sint64());
                    break;
                case 2:
                    message.serverTime = longToString(reader.sint64());
                    break;
                case 3:
                    message.version = reader.string();
                    break;
                case 4:
                    const entry4 = InfoResponse_BuildEntry.decode(reader, reader.uint32());
                    if (entry4.value !== undefined) {
                        message.build[entry4.key] = entry4.value;
                    }
                    break;
                case 5:
                    message.region = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            serverTime: isSet(object.serverTime) ? String(object.serverTime) : "0",
            version: isSet(object.version) ? String(object.version) : "",
            build: isObject(object.build)
                ? Object.entries(object.build).reduce((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
            region: isSet(object.region) ? String(object.region) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.serverTime !== undefined && (obj.serverTime = message.serverTime);
        message.version !== undefined && (obj.version = message.version);
        obj.build = {};
        if (message.build) {
            Object.entries(message.build).forEach(([k, v]) => {
                obj.build[k] = v;
            });
        }
        message.region !== undefined && (obj.region = message.region);
        return obj;
    },
    create(base) {
        return InfoResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInfoResponse();
        message.timestamp = object.timestamp ?? "0";
        message.serverTime = object.serverTime ?? "0";
        message.version = object.version ?? "";
        message.build = Object.entries(object.build ?? {}).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.region = object.region ?? "";
        return message;
    },
};
function createBaseInfoResponse_BuildEntry() {
    return { key: "", value: "" };
}
export const InfoResponse_BuildEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInfoResponse_BuildEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    create(base) {
        return InfoResponse_BuildEntry.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInfoResponse_BuildEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseStreamKeepaliveRequest() {
    return {};
}
export const StreamKeepaliveRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamKeepaliveRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return StreamKeepaliveRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseStreamKeepaliveRequest();
        return message;
    },
};
function createBaseStreamKeepaliveResponse() {
    return { event: "", newEndpoint: "", timestamp: "0" };
}
export const StreamKeepaliveResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.event !== "") {
            writer.uint32(10).string(message.event);
        }
        if (message.newEndpoint !== "") {
            writer.uint32(18).string(message.newEndpoint);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamKeepaliveResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.event = reader.string();
                    break;
                case 2:
                    message.newEndpoint = reader.string();
                    break;
                case 3:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            event: isSet(object.event) ? String(object.event) : "",
            newEndpoint: isSet(object.newEndpoint) ? String(object.newEndpoint) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.event !== undefined && (obj.event = message.event);
        message.newEndpoint !== undefined && (obj.newEndpoint = message.newEndpoint);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamKeepaliveResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseStreamKeepaliveResponse();
        message.event = object.event ?? "";
        message.newEndpoint = object.newEndpoint ?? "";
        message.timestamp = object.timestamp ?? "0";
        return message;
    },
};
function createBaseTokenMetadataRequest() {
    return { denoms: [] };
}
export const TokenMetadataRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.denoms) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenMetadataRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denoms.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { denoms: Array.isArray(object?.denoms) ? object.denoms.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.denoms) {
            obj.denoms = message.denoms.map((e) => e);
        }
        else {
            obj.denoms = [];
        }
        return obj;
    },
    create(base) {
        return TokenMetadataRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTokenMetadataRequest();
        message.denoms = object.denoms?.map((e) => e) || [];
        return message;
    },
};
function createBaseTokenMetadataResponse() {
    return { tokens: [] };
}
export const TokenMetadataResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tokens) {
            TokenMetadataElement.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenMetadataResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokens.push(TokenMetadataElement.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tokens: Array.isArray(object?.tokens) ? object.tokens.map((e) => TokenMetadataElement.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tokens) {
            obj.tokens = message.tokens.map((e) => e ? TokenMetadataElement.toJSON(e) : undefined);
        }
        else {
            obj.tokens = [];
        }
        return obj;
    },
    create(base) {
        return TokenMetadataResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTokenMetadataResponse();
        message.tokens = object.tokens?.map((e) => TokenMetadataElement.fromPartial(e)) || [];
        return message;
    },
};
function createBaseTokenMetadataElement() {
    return { ethereumAddress: "", coingeckoId: "", denom: "", name: "", symbol: "", decimals: 0, logo: "" };
}
export const TokenMetadataElement = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ethereumAddress !== "") {
            writer.uint32(10).string(message.ethereumAddress);
        }
        if (message.coingeckoId !== "") {
            writer.uint32(18).string(message.coingeckoId);
        }
        if (message.denom !== "") {
            writer.uint32(26).string(message.denom);
        }
        if (message.name !== "") {
            writer.uint32(34).string(message.name);
        }
        if (message.symbol !== "") {
            writer.uint32(42).string(message.symbol);
        }
        if (message.decimals !== 0) {
            writer.uint32(48).sint32(message.decimals);
        }
        if (message.logo !== "") {
            writer.uint32(58).string(message.logo);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenMetadataElement();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ethereumAddress = reader.string();
                    break;
                case 2:
                    message.coingeckoId = reader.string();
                    break;
                case 3:
                    message.denom = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                case 5:
                    message.symbol = reader.string();
                    break;
                case 6:
                    message.decimals = reader.sint32();
                    break;
                case 7:
                    message.logo = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ethereumAddress: isSet(object.ethereumAddress) ? String(object.ethereumAddress) : "",
            coingeckoId: isSet(object.coingeckoId) ? String(object.coingeckoId) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
            name: isSet(object.name) ? String(object.name) : "",
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            decimals: isSet(object.decimals) ? Number(object.decimals) : 0,
            logo: isSet(object.logo) ? String(object.logo) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ethereumAddress !== undefined && (obj.ethereumAddress = message.ethereumAddress);
        message.coingeckoId !== undefined && (obj.coingeckoId = message.coingeckoId);
        message.denom !== undefined && (obj.denom = message.denom);
        message.name !== undefined && (obj.name = message.name);
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.decimals !== undefined && (obj.decimals = Math.round(message.decimals));
        message.logo !== undefined && (obj.logo = message.logo);
        return obj;
    },
    create(base) {
        return TokenMetadataElement.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTokenMetadataElement();
        message.ethereumAddress = object.ethereumAddress ?? "";
        message.coingeckoId = object.coingeckoId ?? "";
        message.denom = object.denom ?? "";
        message.name = object.name ?? "";
        message.symbol = object.symbol ?? "";
        message.decimals = object.decimals ?? 0;
        message.logo = object.logo ?? "";
        return message;
    },
};
export class InjectiveMetaRPCClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Ping = this.Ping.bind(this);
        this.Version = this.Version.bind(this);
        this.Info = this.Info.bind(this);
        this.StreamKeepalive = this.StreamKeepalive.bind(this);
        this.TokenMetadata = this.TokenMetadata.bind(this);
    }
    Ping(request, metadata) {
        return this.rpc.unary(InjectiveMetaRPCPingDesc, PingRequest.fromPartial(request), metadata);
    }
    Version(request, metadata) {
        return this.rpc.unary(InjectiveMetaRPCVersionDesc, VersionRequest.fromPartial(request), metadata);
    }
    Info(request, metadata) {
        return this.rpc.unary(InjectiveMetaRPCInfoDesc, InfoRequest.fromPartial(request), metadata);
    }
    StreamKeepalive(request, metadata) {
        return this.rpc.invoke(InjectiveMetaRPCStreamKeepaliveDesc, StreamKeepaliveRequest.fromPartial(request), metadata);
    }
    TokenMetadata(request, metadata) {
        return this.rpc.unary(InjectiveMetaRPCTokenMetadataDesc, TokenMetadataRequest.fromPartial(request), metadata);
    }
}
export const InjectiveMetaRPCDesc = { serviceName: "injective_meta_rpc.InjectiveMetaRPC" };
export const InjectiveMetaRPCPingDesc = {
    methodName: "Ping",
    service: InjectiveMetaRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PingRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PingResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveMetaRPCVersionDesc = {
    methodName: "Version",
    service: InjectiveMetaRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return VersionRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = VersionResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveMetaRPCInfoDesc = {
    methodName: "Info",
    service: InjectiveMetaRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return InfoRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = InfoResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveMetaRPCStreamKeepaliveDesc = {
    methodName: "StreamKeepalive",
    service: InjectiveMetaRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamKeepaliveRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamKeepaliveResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const InjectiveMetaRPCTokenMetadataDesc = {
    methodName: "TokenMetadata",
    service: InjectiveMetaRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return TokenMetadataRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = TokenMetadataResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => {
                    if (!observer.closed) {
                        return client.close();
                    }
                });
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
