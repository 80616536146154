/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { StoreCodeProposal } from "../../../cosmwasm/wasm/v1/proposal_legacy.js";
export const protobufPackage = "injective.wasmx.v1";
export var FundingMode;
(function (FundingMode) {
    FundingMode[FundingMode["Unspecified"] = 0] = "Unspecified";
    FundingMode[FundingMode["SelfFunded"] = 1] = "SelfFunded";
    FundingMode[FundingMode["GrantOnly"] = 2] = "GrantOnly";
    FundingMode[FundingMode["Dual"] = 3] = "Dual";
    FundingMode[FundingMode["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(FundingMode || (FundingMode = {}));
export function fundingModeFromJSON(object) {
    switch (object) {
        case 0:
        case "Unspecified":
            return FundingMode.Unspecified;
        case 1:
        case "SelfFunded":
            return FundingMode.SelfFunded;
        case 2:
        case "GrantOnly":
            return FundingMode.GrantOnly;
        case 3:
        case "Dual":
            return FundingMode.Dual;
        case -1:
        case "UNRECOGNIZED":
        default:
            return FundingMode.UNRECOGNIZED;
    }
}
export function fundingModeToJSON(object) {
    switch (object) {
        case FundingMode.Unspecified:
            return "Unspecified";
        case FundingMode.SelfFunded:
            return "SelfFunded";
        case FundingMode.GrantOnly:
            return "GrantOnly";
        case FundingMode.Dual:
            return "Dual";
        case FundingMode.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseContractRegistrationRequestProposal() {
    return { title: "", description: "", contractRegistrationRequest: undefined };
}
export const ContractRegistrationRequestProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.contractRegistrationRequest !== undefined) {
            ContractRegistrationRequest.encode(message.contractRegistrationRequest, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContractRegistrationRequestProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.contractRegistrationRequest = ContractRegistrationRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            contractRegistrationRequest: isSet(object.contractRegistrationRequest)
                ? ContractRegistrationRequest.fromJSON(object.contractRegistrationRequest)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        message.contractRegistrationRequest !== undefined &&
            (obj.contractRegistrationRequest = message.contractRegistrationRequest
                ? ContractRegistrationRequest.toJSON(message.contractRegistrationRequest)
                : undefined);
        return obj;
    },
    create(base) {
        return ContractRegistrationRequestProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseContractRegistrationRequestProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.contractRegistrationRequest =
            (object.contractRegistrationRequest !== undefined && object.contractRegistrationRequest !== null)
                ? ContractRegistrationRequest.fromPartial(object.contractRegistrationRequest)
                : undefined;
        return message;
    },
};
function createBaseBatchContractRegistrationRequestProposal() {
    return { title: "", description: "", contractRegistrationRequests: [] };
}
export const BatchContractRegistrationRequestProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.contractRegistrationRequests) {
            ContractRegistrationRequest.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchContractRegistrationRequestProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.contractRegistrationRequests.push(ContractRegistrationRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            contractRegistrationRequests: Array.isArray(object?.contractRegistrationRequests)
                ? object.contractRegistrationRequests.map((e) => ContractRegistrationRequest.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.contractRegistrationRequests) {
            obj.contractRegistrationRequests = message.contractRegistrationRequests.map((e) => e ? ContractRegistrationRequest.toJSON(e) : undefined);
        }
        else {
            obj.contractRegistrationRequests = [];
        }
        return obj;
    },
    create(base) {
        return BatchContractRegistrationRequestProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchContractRegistrationRequestProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.contractRegistrationRequests =
            object.contractRegistrationRequests?.map((e) => ContractRegistrationRequest.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBatchContractDeregistrationProposal() {
    return { title: "", description: "", contracts: [] };
}
export const BatchContractDeregistrationProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.contracts) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchContractDeregistrationProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.contracts.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            contracts: Array.isArray(object?.contracts) ? object.contracts.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.contracts) {
            obj.contracts = message.contracts.map((e) => e);
        }
        else {
            obj.contracts = [];
        }
        return obj;
    },
    create(base) {
        return BatchContractDeregistrationProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchContractDeregistrationProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.contracts = object.contracts?.map((e) => e) || [];
        return message;
    },
};
function createBaseContractRegistrationRequest() {
    return {
        contractAddress: "",
        gasLimit: "0",
        gasPrice: "0",
        shouldPinContract: false,
        isMigrationAllowed: false,
        codeId: "0",
        adminAddress: "",
        granterAddress: "",
        fundingMode: 0,
    };
}
export const ContractRegistrationRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.contractAddress !== "") {
            writer.uint32(10).string(message.contractAddress);
        }
        if (message.gasLimit !== "0") {
            writer.uint32(16).uint64(message.gasLimit);
        }
        if (message.gasPrice !== "0") {
            writer.uint32(24).uint64(message.gasPrice);
        }
        if (message.shouldPinContract === true) {
            writer.uint32(32).bool(message.shouldPinContract);
        }
        if (message.isMigrationAllowed === true) {
            writer.uint32(40).bool(message.isMigrationAllowed);
        }
        if (message.codeId !== "0") {
            writer.uint32(48).uint64(message.codeId);
        }
        if (message.adminAddress !== "") {
            writer.uint32(58).string(message.adminAddress);
        }
        if (message.granterAddress !== "") {
            writer.uint32(66).string(message.granterAddress);
        }
        if (message.fundingMode !== 0) {
            writer.uint32(72).int32(message.fundingMode);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContractRegistrationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.contractAddress = reader.string();
                    break;
                case 2:
                    message.gasLimit = longToString(reader.uint64());
                    break;
                case 3:
                    message.gasPrice = longToString(reader.uint64());
                    break;
                case 4:
                    message.shouldPinContract = reader.bool();
                    break;
                case 5:
                    message.isMigrationAllowed = reader.bool();
                    break;
                case 6:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 7:
                    message.adminAddress = reader.string();
                    break;
                case 8:
                    message.granterAddress = reader.string();
                    break;
                case 9:
                    message.fundingMode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            gasLimit: isSet(object.gasLimit) ? String(object.gasLimit) : "0",
            gasPrice: isSet(object.gasPrice) ? String(object.gasPrice) : "0",
            shouldPinContract: isSet(object.shouldPinContract) ? Boolean(object.shouldPinContract) : false,
            isMigrationAllowed: isSet(object.isMigrationAllowed) ? Boolean(object.isMigrationAllowed) : false,
            codeId: isSet(object.codeId) ? String(object.codeId) : "0",
            adminAddress: isSet(object.adminAddress) ? String(object.adminAddress) : "",
            granterAddress: isSet(object.granterAddress) ? String(object.granterAddress) : "",
            fundingMode: isSet(object.fundingMode) ? fundingModeFromJSON(object.fundingMode) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.gasLimit !== undefined && (obj.gasLimit = message.gasLimit);
        message.gasPrice !== undefined && (obj.gasPrice = message.gasPrice);
        message.shouldPinContract !== undefined && (obj.shouldPinContract = message.shouldPinContract);
        message.isMigrationAllowed !== undefined && (obj.isMigrationAllowed = message.isMigrationAllowed);
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.adminAddress !== undefined && (obj.adminAddress = message.adminAddress);
        message.granterAddress !== undefined && (obj.granterAddress = message.granterAddress);
        message.fundingMode !== undefined && (obj.fundingMode = fundingModeToJSON(message.fundingMode));
        return obj;
    },
    create(base) {
        return ContractRegistrationRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseContractRegistrationRequest();
        message.contractAddress = object.contractAddress ?? "";
        message.gasLimit = object.gasLimit ?? "0";
        message.gasPrice = object.gasPrice ?? "0";
        message.shouldPinContract = object.shouldPinContract ?? false;
        message.isMigrationAllowed = object.isMigrationAllowed ?? false;
        message.codeId = object.codeId ?? "0";
        message.adminAddress = object.adminAddress ?? "";
        message.granterAddress = object.granterAddress ?? "";
        message.fundingMode = object.fundingMode ?? 0;
        return message;
    },
};
function createBaseBatchStoreCodeProposal() {
    return { title: "", description: "", proposals: [] };
}
export const BatchStoreCodeProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.title !== "") {
            writer.uint32(10).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        for (const v of message.proposals) {
            StoreCodeProposal.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchStoreCodeProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.proposals.push(StoreCodeProposal.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            proposals: Array.isArray(object?.proposals)
                ? object.proposals.map((e) => StoreCodeProposal.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.title !== undefined && (obj.title = message.title);
        message.description !== undefined && (obj.description = message.description);
        if (message.proposals) {
            obj.proposals = message.proposals.map((e) => e ? StoreCodeProposal.toJSON(e) : undefined);
        }
        else {
            obj.proposals = [];
        }
        return obj;
    },
    create(base) {
        return BatchStoreCodeProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBatchStoreCodeProposal();
        message.title = object.title ?? "";
        message.description = object.description ?? "";
        message.proposals = object.proposals?.map((e) => StoreCodeProposal.fromPartial(e)) || [];
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
