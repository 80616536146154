/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { Params } from "./params.js";
import { Namespace } from "./permissions.js";
export const protobufPackage = "injective.permissions.v1beta1";
function createBaseQueryParamsRequest() {
    return {};
}
export const QueryParamsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryParamsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryParamsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryParamsRequest();
        return message;
    },
};
function createBaseQueryParamsResponse() {
    return { params: undefined };
}
export const QueryParamsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { params: isSet(object.params) ? Params.fromJSON(object.params) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return QueryParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryParamsResponse();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseQueryAllNamespacesRequest() {
    return {};
}
export const QueryAllNamespacesRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAllNamespacesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return QueryAllNamespacesRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseQueryAllNamespacesRequest();
        return message;
    },
};
function createBaseQueryAllNamespacesResponse() {
    return { namespaces: [] };
}
export const QueryAllNamespacesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.namespaces) {
            Namespace.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAllNamespacesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.namespaces.push(Namespace.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            namespaces: Array.isArray(object?.namespaces) ? object.namespaces.map((e) => Namespace.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.namespaces) {
            obj.namespaces = message.namespaces.map((e) => e ? Namespace.toJSON(e) : undefined);
        }
        else {
            obj.namespaces = [];
        }
        return obj;
    },
    create(base) {
        return QueryAllNamespacesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryAllNamespacesResponse();
        message.namespaces = object.namespaces?.map((e) => Namespace.fromPartial(e)) || [];
        return message;
    },
};
function createBaseQueryNamespaceByDenomRequest() {
    return { denom: "", includeRoles: false };
}
export const QueryNamespaceByDenomRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.includeRoles === true) {
            writer.uint32(16).bool(message.includeRoles);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryNamespaceByDenomRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.includeRoles = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            includeRoles: isSet(object.includeRoles) ? Boolean(object.includeRoles) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.includeRoles !== undefined && (obj.includeRoles = message.includeRoles);
        return obj;
    },
    create(base) {
        return QueryNamespaceByDenomRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryNamespaceByDenomRequest();
        message.denom = object.denom ?? "";
        message.includeRoles = object.includeRoles ?? false;
        return message;
    },
};
function createBaseQueryNamespaceByDenomResponse() {
    return { namespace: undefined };
}
export const QueryNamespaceByDenomResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.namespace !== undefined) {
            Namespace.encode(message.namespace, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryNamespaceByDenomResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.namespace = Namespace.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { namespace: isSet(object.namespace) ? Namespace.fromJSON(object.namespace) : undefined };
    },
    toJSON(message) {
        const obj = {};
        message.namespace !== undefined &&
            (obj.namespace = message.namespace ? Namespace.toJSON(message.namespace) : undefined);
        return obj;
    },
    create(base) {
        return QueryNamespaceByDenomResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryNamespaceByDenomResponse();
        message.namespace = (object.namespace !== undefined && object.namespace !== null)
            ? Namespace.fromPartial(object.namespace)
            : undefined;
        return message;
    },
};
function createBaseQueryAddressesByRoleRequest() {
    return { denom: "", role: "" };
}
export const QueryAddressesByRoleRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.role !== "") {
            writer.uint32(18).string(message.role);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAddressesByRoleRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.role = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            role: isSet(object.role) ? String(object.role) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.role !== undefined && (obj.role = message.role);
        return obj;
    },
    create(base) {
        return QueryAddressesByRoleRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryAddressesByRoleRequest();
        message.denom = object.denom ?? "";
        message.role = object.role ?? "";
        return message;
    },
};
function createBaseQueryAddressesByRoleResponse() {
    return { addresses: [] };
}
export const QueryAddressesByRoleResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.addresses) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAddressesByRoleResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addresses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { addresses: Array.isArray(object?.addresses) ? object.addresses.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.addresses) {
            obj.addresses = message.addresses.map((e) => e);
        }
        else {
            obj.addresses = [];
        }
        return obj;
    },
    create(base) {
        return QueryAddressesByRoleResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryAddressesByRoleResponse();
        message.addresses = object.addresses?.map((e) => e) || [];
        return message;
    },
};
function createBaseQueryAddressRolesRequest() {
    return { denom: "", address: "" };
}
export const QueryAddressRolesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAddressRolesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            address: isSet(object.address) ? String(object.address) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return QueryAddressRolesRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryAddressRolesRequest();
        message.denom = object.denom ?? "";
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseQueryAddressRolesResponse() {
    return { roles: [] };
}
export const QueryAddressRolesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.roles) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryAddressRolesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.roles.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { roles: Array.isArray(object?.roles) ? object.roles.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.roles) {
            obj.roles = message.roles.map((e) => e);
        }
        else {
            obj.roles = [];
        }
        return obj;
    },
    create(base) {
        return QueryAddressRolesResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryAddressRolesResponse();
        message.roles = object.roles?.map((e) => e) || [];
        return message;
    },
};
function createBaseQueryVouchersForAddressRequest() {
    return { address: "" };
}
export const QueryVouchersForAddressRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryVouchersForAddressRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { address: isSet(object.address) ? String(object.address) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        return obj;
    },
    create(base) {
        return QueryVouchersForAddressRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryVouchersForAddressRequest();
        message.address = object.address ?? "";
        return message;
    },
};
function createBaseQueryVouchersForAddressResponse() {
    return { vouchers: [] };
}
export const QueryVouchersForAddressResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.vouchers) {
            Coin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryVouchersForAddressResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vouchers.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { vouchers: Array.isArray(object?.vouchers) ? object.vouchers.map((e) => Coin.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.vouchers) {
            obj.vouchers = message.vouchers.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.vouchers = [];
        }
        return obj;
    },
    create(base) {
        return QueryVouchersForAddressResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryVouchersForAddressResponse();
        message.vouchers = object.vouchers?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
export class QueryClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Params = this.Params.bind(this);
        this.AllNamespaces = this.AllNamespaces.bind(this);
        this.NamespaceByDenom = this.NamespaceByDenom.bind(this);
        this.AddressRoles = this.AddressRoles.bind(this);
        this.AddressesByRole = this.AddressesByRole.bind(this);
        this.VouchersForAddress = this.VouchersForAddress.bind(this);
    }
    Params(request, metadata) {
        return this.rpc.unary(QueryParamsDesc, QueryParamsRequest.fromPartial(request), metadata);
    }
    AllNamespaces(request, metadata) {
        return this.rpc.unary(QueryAllNamespacesDesc, QueryAllNamespacesRequest.fromPartial(request), metadata);
    }
    NamespaceByDenom(request, metadata) {
        return this.rpc.unary(QueryNamespaceByDenomDesc, QueryNamespaceByDenomRequest.fromPartial(request), metadata);
    }
    AddressRoles(request, metadata) {
        return this.rpc.unary(QueryAddressRolesDesc, QueryAddressRolesRequest.fromPartial(request), metadata);
    }
    AddressesByRole(request, metadata) {
        return this.rpc.unary(QueryAddressesByRoleDesc, QueryAddressesByRoleRequest.fromPartial(request), metadata);
    }
    VouchersForAddress(request, metadata) {
        return this.rpc.unary(QueryVouchersForAddressDesc, QueryVouchersForAddressRequest.fromPartial(request), metadata);
    }
}
export const QueryDesc = { serviceName: "injective.permissions.v1beta1.Query" };
export const QueryParamsDesc = {
    methodName: "Params",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryParamsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryAllNamespacesDesc = {
    methodName: "AllNamespaces",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryAllNamespacesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryAllNamespacesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryNamespaceByDenomDesc = {
    methodName: "NamespaceByDenom",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryNamespaceByDenomRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryNamespaceByDenomResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryAddressRolesDesc = {
    methodName: "AddressRoles",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryAddressRolesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryAddressRolesResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryAddressesByRoleDesc = {
    methodName: "AddressesByRole",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryAddressesByRoleRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryAddressesByRoleResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const QueryVouchersForAddressDesc = {
    methodName: "VouchersForAddress",
    service: QueryDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return QueryVouchersForAddressRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = QueryVouchersForAddressResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
