/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "injective.peggy.v1";
function createBaseBridgeValidator() {
    return { power: "0", ethereumAddress: "" };
}
export const BridgeValidator = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.power !== "0") {
            writer.uint32(8).uint64(message.power);
        }
        if (message.ethereumAddress !== "") {
            writer.uint32(18).string(message.ethereumAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBridgeValidator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.power = longToString(reader.uint64());
                    break;
                case 2:
                    message.ethereumAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            power: isSet(object.power) ? String(object.power) : "0",
            ethereumAddress: isSet(object.ethereumAddress) ? String(object.ethereumAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.power !== undefined && (obj.power = message.power);
        message.ethereumAddress !== undefined && (obj.ethereumAddress = message.ethereumAddress);
        return obj;
    },
    create(base) {
        return BridgeValidator.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBridgeValidator();
        message.power = object.power ?? "0";
        message.ethereumAddress = object.ethereumAddress ?? "";
        return message;
    },
};
function createBaseValset() {
    return { nonce: "0", members: [], height: "0", rewardAmount: "", rewardToken: "" };
}
export const Valset = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.nonce !== "0") {
            writer.uint32(8).uint64(message.nonce);
        }
        for (const v of message.members) {
            BridgeValidator.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.height !== "0") {
            writer.uint32(24).uint64(message.height);
        }
        if (message.rewardAmount !== "") {
            writer.uint32(34).string(message.rewardAmount);
        }
        if (message.rewardToken !== "") {
            writer.uint32(42).string(message.rewardToken);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValset();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.members.push(BridgeValidator.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.height = longToString(reader.uint64());
                    break;
                case 4:
                    message.rewardAmount = reader.string();
                    break;
                case 5:
                    message.rewardToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            nonce: isSet(object.nonce) ? String(object.nonce) : "0",
            members: Array.isArray(object?.members) ? object.members.map((e) => BridgeValidator.fromJSON(e)) : [],
            height: isSet(object.height) ? String(object.height) : "0",
            rewardAmount: isSet(object.rewardAmount) ? String(object.rewardAmount) : "",
            rewardToken: isSet(object.rewardToken) ? String(object.rewardToken) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.nonce !== undefined && (obj.nonce = message.nonce);
        if (message.members) {
            obj.members = message.members.map((e) => e ? BridgeValidator.toJSON(e) : undefined);
        }
        else {
            obj.members = [];
        }
        message.height !== undefined && (obj.height = message.height);
        message.rewardAmount !== undefined && (obj.rewardAmount = message.rewardAmount);
        message.rewardToken !== undefined && (obj.rewardToken = message.rewardToken);
        return obj;
    },
    create(base) {
        return Valset.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValset();
        message.nonce = object.nonce ?? "0";
        message.members = object.members?.map((e) => BridgeValidator.fromPartial(e)) || [];
        message.height = object.height ?? "0";
        message.rewardAmount = object.rewardAmount ?? "";
        message.rewardToken = object.rewardToken ?? "";
        return message;
    },
};
function createBaseLastObservedEthereumBlockHeight() {
    return { cosmosBlockHeight: "0", ethereumBlockHeight: "0" };
}
export const LastObservedEthereumBlockHeight = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cosmosBlockHeight !== "0") {
            writer.uint32(8).uint64(message.cosmosBlockHeight);
        }
        if (message.ethereumBlockHeight !== "0") {
            writer.uint32(16).uint64(message.ethereumBlockHeight);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLastObservedEthereumBlockHeight();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cosmosBlockHeight = longToString(reader.uint64());
                    break;
                case 2:
                    message.ethereumBlockHeight = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            cosmosBlockHeight: isSet(object.cosmosBlockHeight) ? String(object.cosmosBlockHeight) : "0",
            ethereumBlockHeight: isSet(object.ethereumBlockHeight) ? String(object.ethereumBlockHeight) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.cosmosBlockHeight !== undefined && (obj.cosmosBlockHeight = message.cosmosBlockHeight);
        message.ethereumBlockHeight !== undefined && (obj.ethereumBlockHeight = message.ethereumBlockHeight);
        return obj;
    },
    create(base) {
        return LastObservedEthereumBlockHeight.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLastObservedEthereumBlockHeight();
        message.cosmosBlockHeight = object.cosmosBlockHeight ?? "0";
        message.ethereumBlockHeight = object.ethereumBlockHeight ?? "0";
        return message;
    },
};
function createBaseLastClaimEvent() {
    return { ethereumEventNonce: "0", ethereumEventHeight: "0" };
}
export const LastClaimEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ethereumEventNonce !== "0") {
            writer.uint32(8).uint64(message.ethereumEventNonce);
        }
        if (message.ethereumEventHeight !== "0") {
            writer.uint32(16).uint64(message.ethereumEventHeight);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLastClaimEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ethereumEventNonce = longToString(reader.uint64());
                    break;
                case 2:
                    message.ethereumEventHeight = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ethereumEventNonce: isSet(object.ethereumEventNonce) ? String(object.ethereumEventNonce) : "0",
            ethereumEventHeight: isSet(object.ethereumEventHeight) ? String(object.ethereumEventHeight) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ethereumEventNonce !== undefined && (obj.ethereumEventNonce = message.ethereumEventNonce);
        message.ethereumEventHeight !== undefined && (obj.ethereumEventHeight = message.ethereumEventHeight);
        return obj;
    },
    create(base) {
        return LastClaimEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLastClaimEvent();
        message.ethereumEventNonce = object.ethereumEventNonce ?? "0";
        message.ethereumEventHeight = object.ethereumEventHeight ?? "0";
        return message;
    },
};
function createBaseERC20ToDenom() {
    return { erc20: "", denom: "" };
}
export const ERC20ToDenom = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.erc20 !== "") {
            writer.uint32(10).string(message.erc20);
        }
        if (message.denom !== "") {
            writer.uint32(18).string(message.denom);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseERC20ToDenom();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.erc20 = reader.string();
                    break;
                case 2:
                    message.denom = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            erc20: isSet(object.erc20) ? String(object.erc20) : "",
            denom: isSet(object.denom) ? String(object.denom) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.erc20 !== undefined && (obj.erc20 = message.erc20);
        message.denom !== undefined && (obj.denom = message.denom);
        return obj;
    },
    create(base) {
        return ERC20ToDenom.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseERC20ToDenom();
        message.erc20 = object.erc20 ?? "";
        message.denom = object.denom ?? "";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
