/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
import { oracleTypeFromJSON, oracleTypeToJSON } from "../../oracle/v1beta1/oracle.js";
import { DerivativeOrder, GrantAuthorization, marketStatusFromJSON, marketStatusToJSON, Params, PositionDelta, SpotOrder, } from "./exchange.js";
export const protobufPackage = "injective.exchange.v1beta1";
function createBaseMsgUpdateSpotMarket() {
    return {
        admin: "",
        marketId: "",
        newTicker: "",
        newMinPriceTickSize: "",
        newMinQuantityTickSize: "",
        newMinNotional: "",
    };
}
export const MsgUpdateSpotMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.admin !== "") {
            writer.uint32(10).string(message.admin);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.newTicker !== "") {
            writer.uint32(26).string(message.newTicker);
        }
        if (message.newMinPriceTickSize !== "") {
            writer.uint32(34).string(message.newMinPriceTickSize);
        }
        if (message.newMinQuantityTickSize !== "") {
            writer.uint32(42).string(message.newMinQuantityTickSize);
        }
        if (message.newMinNotional !== "") {
            writer.uint32(50).string(message.newMinNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateSpotMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.admin = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.newTicker = reader.string();
                    break;
                case 4:
                    message.newMinPriceTickSize = reader.string();
                    break;
                case 5:
                    message.newMinQuantityTickSize = reader.string();
                    break;
                case 6:
                    message.newMinNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            admin: isSet(object.admin) ? String(object.admin) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            newTicker: isSet(object.newTicker) ? String(object.newTicker) : "",
            newMinPriceTickSize: isSet(object.newMinPriceTickSize) ? String(object.newMinPriceTickSize) : "",
            newMinQuantityTickSize: isSet(object.newMinQuantityTickSize) ? String(object.newMinQuantityTickSize) : "",
            newMinNotional: isSet(object.newMinNotional) ? String(object.newMinNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.admin !== undefined && (obj.admin = message.admin);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.newTicker !== undefined && (obj.newTicker = message.newTicker);
        message.newMinPriceTickSize !== undefined && (obj.newMinPriceTickSize = message.newMinPriceTickSize);
        message.newMinQuantityTickSize !== undefined && (obj.newMinQuantityTickSize = message.newMinQuantityTickSize);
        message.newMinNotional !== undefined && (obj.newMinNotional = message.newMinNotional);
        return obj;
    },
    create(base) {
        return MsgUpdateSpotMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateSpotMarket();
        message.admin = object.admin ?? "";
        message.marketId = object.marketId ?? "";
        message.newTicker = object.newTicker ?? "";
        message.newMinPriceTickSize = object.newMinPriceTickSize ?? "";
        message.newMinQuantityTickSize = object.newMinQuantityTickSize ?? "";
        message.newMinNotional = object.newMinNotional ?? "";
        return message;
    },
};
function createBaseMsgUpdateSpotMarketResponse() {
    return {};
}
export const MsgUpdateSpotMarketResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateSpotMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateSpotMarketResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateSpotMarketResponse();
        return message;
    },
};
function createBaseMsgUpdateDerivativeMarket() {
    return {
        admin: "",
        marketId: "",
        newTicker: "",
        newMinPriceTickSize: "",
        newMinQuantityTickSize: "",
        newMinNotional: "",
        newInitialMarginRatio: "",
        newMaintenanceMarginRatio: "",
    };
}
export const MsgUpdateDerivativeMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.admin !== "") {
            writer.uint32(10).string(message.admin);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.newTicker !== "") {
            writer.uint32(26).string(message.newTicker);
        }
        if (message.newMinPriceTickSize !== "") {
            writer.uint32(34).string(message.newMinPriceTickSize);
        }
        if (message.newMinQuantityTickSize !== "") {
            writer.uint32(42).string(message.newMinQuantityTickSize);
        }
        if (message.newMinNotional !== "") {
            writer.uint32(50).string(message.newMinNotional);
        }
        if (message.newInitialMarginRatio !== "") {
            writer.uint32(58).string(message.newInitialMarginRatio);
        }
        if (message.newMaintenanceMarginRatio !== "") {
            writer.uint32(66).string(message.newMaintenanceMarginRatio);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateDerivativeMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.admin = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.newTicker = reader.string();
                    break;
                case 4:
                    message.newMinPriceTickSize = reader.string();
                    break;
                case 5:
                    message.newMinQuantityTickSize = reader.string();
                    break;
                case 6:
                    message.newMinNotional = reader.string();
                    break;
                case 7:
                    message.newInitialMarginRatio = reader.string();
                    break;
                case 8:
                    message.newMaintenanceMarginRatio = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            admin: isSet(object.admin) ? String(object.admin) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            newTicker: isSet(object.newTicker) ? String(object.newTicker) : "",
            newMinPriceTickSize: isSet(object.newMinPriceTickSize) ? String(object.newMinPriceTickSize) : "",
            newMinQuantityTickSize: isSet(object.newMinQuantityTickSize) ? String(object.newMinQuantityTickSize) : "",
            newMinNotional: isSet(object.newMinNotional) ? String(object.newMinNotional) : "",
            newInitialMarginRatio: isSet(object.newInitialMarginRatio) ? String(object.newInitialMarginRatio) : "",
            newMaintenanceMarginRatio: isSet(object.newMaintenanceMarginRatio)
                ? String(object.newMaintenanceMarginRatio)
                : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.admin !== undefined && (obj.admin = message.admin);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.newTicker !== undefined && (obj.newTicker = message.newTicker);
        message.newMinPriceTickSize !== undefined && (obj.newMinPriceTickSize = message.newMinPriceTickSize);
        message.newMinQuantityTickSize !== undefined && (obj.newMinQuantityTickSize = message.newMinQuantityTickSize);
        message.newMinNotional !== undefined && (obj.newMinNotional = message.newMinNotional);
        message.newInitialMarginRatio !== undefined && (obj.newInitialMarginRatio = message.newInitialMarginRatio);
        message.newMaintenanceMarginRatio !== undefined &&
            (obj.newMaintenanceMarginRatio = message.newMaintenanceMarginRatio);
        return obj;
    },
    create(base) {
        return MsgUpdateDerivativeMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateDerivativeMarket();
        message.admin = object.admin ?? "";
        message.marketId = object.marketId ?? "";
        message.newTicker = object.newTicker ?? "";
        message.newMinPriceTickSize = object.newMinPriceTickSize ?? "";
        message.newMinQuantityTickSize = object.newMinQuantityTickSize ?? "";
        message.newMinNotional = object.newMinNotional ?? "";
        message.newInitialMarginRatio = object.newInitialMarginRatio ?? "";
        message.newMaintenanceMarginRatio = object.newMaintenanceMarginRatio ?? "";
        return message;
    },
};
function createBaseMsgUpdateDerivativeMarketResponse() {
    return {};
}
export const MsgUpdateDerivativeMarketResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateDerivativeMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateDerivativeMarketResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateDerivativeMarketResponse();
        return message;
    },
};
function createBaseMsgUpdateParams() {
    return { authority: "", params: undefined };
}
export const MsgUpdateParams = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.authority !== "") {
            writer.uint32(10).string(message.authority);
        }
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.authority = reader.string();
                    break;
                case 2:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            authority: isSet(object.authority) ? String(object.authority) : "",
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.authority !== undefined && (obj.authority = message.authority);
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },
    create(base) {
        return MsgUpdateParams.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgUpdateParams();
        message.authority = object.authority ?? "";
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};
function createBaseMsgUpdateParamsResponse() {
    return {};
}
export const MsgUpdateParamsResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgUpdateParamsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgUpdateParamsResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgUpdateParamsResponse();
        return message;
    },
};
function createBaseMsgDeposit() {
    return { sender: "", subaccountId: "", amount: undefined };
}
export const MsgDeposit = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDeposit();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return MsgDeposit.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgDeposit();
        message.sender = object.sender ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseMsgDepositResponse() {
    return {};
}
export const MsgDepositResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDepositResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgDepositResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgDepositResponse();
        return message;
    },
};
function createBaseMsgWithdraw() {
    return { sender: "", subaccountId: "", amount: undefined };
}
export const MsgWithdraw = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgWithdraw();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return MsgWithdraw.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgWithdraw();
        message.sender = object.sender ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseMsgWithdrawResponse() {
    return {};
}
export const MsgWithdrawResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgWithdrawResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgWithdrawResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgWithdrawResponse();
        return message;
    },
};
function createBaseMsgCreateSpotLimitOrder() {
    return { sender: "", order: undefined };
}
export const MsgCreateSpotLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.order !== undefined) {
            SpotOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateSpotLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.order = SpotOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            order: isSet(object.order) ? SpotOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.order !== undefined && (obj.order = message.order ? SpotOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateSpotLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateSpotLimitOrder();
        message.sender = object.sender ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? SpotOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateSpotLimitOrderResponse() {
    return { orderHash: "", cid: "" };
}
export const MsgCreateSpotLimitOrderResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.cid !== "") {
            writer.uint32(18).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateSpotLimitOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCreateSpotLimitOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateSpotLimitOrderResponse();
        message.orderHash = object.orderHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgBatchCreateSpotLimitOrders() {
    return { sender: "", orders: [] };
}
export const MsgBatchCreateSpotLimitOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.orders) {
            SpotOrder.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCreateSpotLimitOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.orders.push(SpotOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => SpotOrder.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? SpotOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCreateSpotLimitOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCreateSpotLimitOrders();
        message.sender = object.sender ?? "";
        message.orders = object.orders?.map((e) => SpotOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgBatchCreateSpotLimitOrdersResponse() {
    return { orderHashes: [], createdOrdersCids: [], failedOrdersCids: [] };
}
export const MsgBatchCreateSpotLimitOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orderHashes) {
            writer.uint32(10).string(v);
        }
        for (const v of message.createdOrdersCids) {
            writer.uint32(18).string(v);
        }
        for (const v of message.failedOrdersCids) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCreateSpotLimitOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHashes.push(reader.string());
                    break;
                case 2:
                    message.createdOrdersCids.push(reader.string());
                    break;
                case 3:
                    message.failedOrdersCids.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHashes: Array.isArray(object?.orderHashes) ? object.orderHashes.map((e) => String(e)) : [],
            createdOrdersCids: Array.isArray(object?.createdOrdersCids)
                ? object.createdOrdersCids.map((e) => String(e))
                : [],
            failedOrdersCids: Array.isArray(object?.failedOrdersCids)
                ? object.failedOrdersCids.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orderHashes) {
            obj.orderHashes = message.orderHashes.map((e) => e);
        }
        else {
            obj.orderHashes = [];
        }
        if (message.createdOrdersCids) {
            obj.createdOrdersCids = message.createdOrdersCids.map((e) => e);
        }
        else {
            obj.createdOrdersCids = [];
        }
        if (message.failedOrdersCids) {
            obj.failedOrdersCids = message.failedOrdersCids.map((e) => e);
        }
        else {
            obj.failedOrdersCids = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCreateSpotLimitOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCreateSpotLimitOrdersResponse();
        message.orderHashes = object.orderHashes?.map((e) => e) || [];
        message.createdOrdersCids = object.createdOrdersCids?.map((e) => e) || [];
        message.failedOrdersCids = object.failedOrdersCids?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgInstantSpotMarketLaunch() {
    return {
        sender: "",
        ticker: "",
        baseDenom: "",
        quoteDenom: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
        baseDecimals: 0,
        quoteDecimals: 0,
    };
}
export const MsgInstantSpotMarketLaunch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.ticker !== "") {
            writer.uint32(18).string(message.ticker);
        }
        if (message.baseDenom !== "") {
            writer.uint32(26).string(message.baseDenom);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(34).string(message.quoteDenom);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(42).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(50).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(58).string(message.minNotional);
        }
        if (message.baseDecimals !== 0) {
            writer.uint32(64).uint32(message.baseDecimals);
        }
        if (message.quoteDecimals !== 0) {
            writer.uint32(72).uint32(message.quoteDecimals);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantSpotMarketLaunch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.ticker = reader.string();
                    break;
                case 3:
                    message.baseDenom = reader.string();
                    break;
                case 4:
                    message.quoteDenom = reader.string();
                    break;
                case 5:
                    message.minPriceTickSize = reader.string();
                    break;
                case 6:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 7:
                    message.minNotional = reader.string();
                    break;
                case 8:
                    message.baseDecimals = reader.uint32();
                    break;
                case 9:
                    message.quoteDecimals = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            baseDenom: isSet(object.baseDenom) ? String(object.baseDenom) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
            baseDecimals: isSet(object.baseDecimals) ? Number(object.baseDecimals) : 0,
            quoteDecimals: isSet(object.quoteDecimals) ? Number(object.quoteDecimals) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.baseDenom !== undefined && (obj.baseDenom = message.baseDenom);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        message.baseDecimals !== undefined && (obj.baseDecimals = Math.round(message.baseDecimals));
        message.quoteDecimals !== undefined && (obj.quoteDecimals = Math.round(message.quoteDecimals));
        return obj;
    },
    create(base) {
        return MsgInstantSpotMarketLaunch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgInstantSpotMarketLaunch();
        message.sender = object.sender ?? "";
        message.ticker = object.ticker ?? "";
        message.baseDenom = object.baseDenom ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        message.baseDecimals = object.baseDecimals ?? 0;
        message.quoteDecimals = object.quoteDecimals ?? 0;
        return message;
    },
};
function createBaseMsgInstantSpotMarketLaunchResponse() {
    return {};
}
export const MsgInstantSpotMarketLaunchResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantSpotMarketLaunchResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgInstantSpotMarketLaunchResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgInstantSpotMarketLaunchResponse();
        return message;
    },
};
function createBaseMsgInstantPerpetualMarketLaunch() {
    return {
        sender: "",
        ticker: "",
        quoteDenom: "",
        oracleBase: "",
        oracleQuote: "",
        oracleScaleFactor: 0,
        oracleType: 0,
        makerFeeRate: "",
        takerFeeRate: "",
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
    };
}
export const MsgInstantPerpetualMarketLaunch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.ticker !== "") {
            writer.uint32(18).string(message.ticker);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(26).string(message.quoteDenom);
        }
        if (message.oracleBase !== "") {
            writer.uint32(34).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(42).string(message.oracleQuote);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(48).uint32(message.oracleScaleFactor);
        }
        if (message.oracleType !== 0) {
            writer.uint32(56).int32(message.oracleType);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(66).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(74).string(message.takerFeeRate);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(82).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(90).string(message.maintenanceMarginRatio);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(98).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(106).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(114).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantPerpetualMarketLaunch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.ticker = reader.string();
                    break;
                case 3:
                    message.quoteDenom = reader.string();
                    break;
                case 4:
                    message.oracleBase = reader.string();
                    break;
                case 5:
                    message.oracleQuote = reader.string();
                    break;
                case 6:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 7:
                    message.oracleType = reader.int32();
                    break;
                case 8:
                    message.makerFeeRate = reader.string();
                    break;
                case 9:
                    message.takerFeeRate = reader.string();
                    break;
                case 10:
                    message.initialMarginRatio = reader.string();
                    break;
                case 11:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 12:
                    message.minPriceTickSize = reader.string();
                    break;
                case 13:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 14:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return MsgInstantPerpetualMarketLaunch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgInstantPerpetualMarketLaunch();
        message.sender = object.sender ?? "";
        message.ticker = object.ticker ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.oracleType = object.oracleType ?? 0;
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBaseMsgInstantPerpetualMarketLaunchResponse() {
    return {};
}
export const MsgInstantPerpetualMarketLaunchResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantPerpetualMarketLaunchResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgInstantPerpetualMarketLaunchResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgInstantPerpetualMarketLaunchResponse();
        return message;
    },
};
function createBaseMsgInstantBinaryOptionsMarketLaunch() {
    return {
        sender: "",
        ticker: "",
        oracleSymbol: "",
        oracleProvider: "",
        oracleType: 0,
        oracleScaleFactor: 0,
        makerFeeRate: "",
        takerFeeRate: "",
        expirationTimestamp: "0",
        settlementTimestamp: "0",
        admin: "",
        quoteDenom: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
    };
}
export const MsgInstantBinaryOptionsMarketLaunch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.ticker !== "") {
            writer.uint32(18).string(message.ticker);
        }
        if (message.oracleSymbol !== "") {
            writer.uint32(26).string(message.oracleSymbol);
        }
        if (message.oracleProvider !== "") {
            writer.uint32(34).string(message.oracleProvider);
        }
        if (message.oracleType !== 0) {
            writer.uint32(40).int32(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(48).uint32(message.oracleScaleFactor);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(58).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(66).string(message.takerFeeRate);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(72).int64(message.expirationTimestamp);
        }
        if (message.settlementTimestamp !== "0") {
            writer.uint32(80).int64(message.settlementTimestamp);
        }
        if (message.admin !== "") {
            writer.uint32(90).string(message.admin);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(98).string(message.quoteDenom);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(106).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(114).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(122).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantBinaryOptionsMarketLaunch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.ticker = reader.string();
                    break;
                case 3:
                    message.oracleSymbol = reader.string();
                    break;
                case 4:
                    message.oracleProvider = reader.string();
                    break;
                case 5:
                    message.oracleType = reader.int32();
                    break;
                case 6:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 7:
                    message.makerFeeRate = reader.string();
                    break;
                case 8:
                    message.takerFeeRate = reader.string();
                    break;
                case 9:
                    message.expirationTimestamp = longToString(reader.int64());
                    break;
                case 10:
                    message.settlementTimestamp = longToString(reader.int64());
                    break;
                case 11:
                    message.admin = reader.string();
                    break;
                case 12:
                    message.quoteDenom = reader.string();
                    break;
                case 13:
                    message.minPriceTickSize = reader.string();
                    break;
                case 14:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 15:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            oracleSymbol: isSet(object.oracleSymbol) ? String(object.oracleSymbol) : "",
            oracleProvider: isSet(object.oracleProvider) ? String(object.oracleProvider) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementTimestamp: isSet(object.settlementTimestamp) ? String(object.settlementTimestamp) : "0",
            admin: isSet(object.admin) ? String(object.admin) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.oracleSymbol !== undefined && (obj.oracleSymbol = message.oracleSymbol);
        message.oracleProvider !== undefined && (obj.oracleProvider = message.oracleProvider);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementTimestamp !== undefined && (obj.settlementTimestamp = message.settlementTimestamp);
        message.admin !== undefined && (obj.admin = message.admin);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return MsgInstantBinaryOptionsMarketLaunch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgInstantBinaryOptionsMarketLaunch();
        message.sender = object.sender ?? "";
        message.ticker = object.ticker ?? "";
        message.oracleSymbol = object.oracleSymbol ?? "";
        message.oracleProvider = object.oracleProvider ?? "";
        message.oracleType = object.oracleType ?? 0;
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementTimestamp = object.settlementTimestamp ?? "0";
        message.admin = object.admin ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBaseMsgInstantBinaryOptionsMarketLaunchResponse() {
    return {};
}
export const MsgInstantBinaryOptionsMarketLaunchResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantBinaryOptionsMarketLaunchResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgInstantBinaryOptionsMarketLaunchResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgInstantBinaryOptionsMarketLaunchResponse();
        return message;
    },
};
function createBaseMsgInstantExpiryFuturesMarketLaunch() {
    return {
        sender: "",
        ticker: "",
        quoteDenom: "",
        oracleBase: "",
        oracleQuote: "",
        oracleType: 0,
        oracleScaleFactor: 0,
        expiry: "0",
        makerFeeRate: "",
        takerFeeRate: "",
        initialMarginRatio: "",
        maintenanceMarginRatio: "",
        minPriceTickSize: "",
        minQuantityTickSize: "",
        minNotional: "",
    };
}
export const MsgInstantExpiryFuturesMarketLaunch = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.ticker !== "") {
            writer.uint32(18).string(message.ticker);
        }
        if (message.quoteDenom !== "") {
            writer.uint32(26).string(message.quoteDenom);
        }
        if (message.oracleBase !== "") {
            writer.uint32(34).string(message.oracleBase);
        }
        if (message.oracleQuote !== "") {
            writer.uint32(42).string(message.oracleQuote);
        }
        if (message.oracleType !== 0) {
            writer.uint32(48).int32(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(56).uint32(message.oracleScaleFactor);
        }
        if (message.expiry !== "0") {
            writer.uint32(64).int64(message.expiry);
        }
        if (message.makerFeeRate !== "") {
            writer.uint32(74).string(message.makerFeeRate);
        }
        if (message.takerFeeRate !== "") {
            writer.uint32(82).string(message.takerFeeRate);
        }
        if (message.initialMarginRatio !== "") {
            writer.uint32(90).string(message.initialMarginRatio);
        }
        if (message.maintenanceMarginRatio !== "") {
            writer.uint32(98).string(message.maintenanceMarginRatio);
        }
        if (message.minPriceTickSize !== "") {
            writer.uint32(106).string(message.minPriceTickSize);
        }
        if (message.minQuantityTickSize !== "") {
            writer.uint32(114).string(message.minQuantityTickSize);
        }
        if (message.minNotional !== "") {
            writer.uint32(122).string(message.minNotional);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantExpiryFuturesMarketLaunch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.ticker = reader.string();
                    break;
                case 3:
                    message.quoteDenom = reader.string();
                    break;
                case 4:
                    message.oracleBase = reader.string();
                    break;
                case 5:
                    message.oracleQuote = reader.string();
                    break;
                case 6:
                    message.oracleType = reader.int32();
                    break;
                case 7:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                case 8:
                    message.expiry = longToString(reader.int64());
                    break;
                case 9:
                    message.makerFeeRate = reader.string();
                    break;
                case 10:
                    message.takerFeeRate = reader.string();
                    break;
                case 11:
                    message.initialMarginRatio = reader.string();
                    break;
                case 12:
                    message.maintenanceMarginRatio = reader.string();
                    break;
                case 13:
                    message.minPriceTickSize = reader.string();
                    break;
                case 14:
                    message.minQuantityTickSize = reader.string();
                    break;
                case 15:
                    message.minNotional = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            ticker: isSet(object.ticker) ? String(object.ticker) : "",
            quoteDenom: isSet(object.quoteDenom) ? String(object.quoteDenom) : "",
            oracleBase: isSet(object.oracleBase) ? String(object.oracleBase) : "",
            oracleQuote: isSet(object.oracleQuote) ? String(object.oracleQuote) : "",
            oracleType: isSet(object.oracleType) ? oracleTypeFromJSON(object.oracleType) : 0,
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
            expiry: isSet(object.expiry) ? String(object.expiry) : "0",
            makerFeeRate: isSet(object.makerFeeRate) ? String(object.makerFeeRate) : "",
            takerFeeRate: isSet(object.takerFeeRate) ? String(object.takerFeeRate) : "",
            initialMarginRatio: isSet(object.initialMarginRatio) ? String(object.initialMarginRatio) : "",
            maintenanceMarginRatio: isSet(object.maintenanceMarginRatio) ? String(object.maintenanceMarginRatio) : "",
            minPriceTickSize: isSet(object.minPriceTickSize) ? String(object.minPriceTickSize) : "",
            minQuantityTickSize: isSet(object.minQuantityTickSize) ? String(object.minQuantityTickSize) : "",
            minNotional: isSet(object.minNotional) ? String(object.minNotional) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.ticker !== undefined && (obj.ticker = message.ticker);
        message.quoteDenom !== undefined && (obj.quoteDenom = message.quoteDenom);
        message.oracleBase !== undefined && (obj.oracleBase = message.oracleBase);
        message.oracleQuote !== undefined && (obj.oracleQuote = message.oracleQuote);
        message.oracleType !== undefined && (obj.oracleType = oracleTypeToJSON(message.oracleType));
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        message.expiry !== undefined && (obj.expiry = message.expiry);
        message.makerFeeRate !== undefined && (obj.makerFeeRate = message.makerFeeRate);
        message.takerFeeRate !== undefined && (obj.takerFeeRate = message.takerFeeRate);
        message.initialMarginRatio !== undefined && (obj.initialMarginRatio = message.initialMarginRatio);
        message.maintenanceMarginRatio !== undefined && (obj.maintenanceMarginRatio = message.maintenanceMarginRatio);
        message.minPriceTickSize !== undefined && (obj.minPriceTickSize = message.minPriceTickSize);
        message.minQuantityTickSize !== undefined && (obj.minQuantityTickSize = message.minQuantityTickSize);
        message.minNotional !== undefined && (obj.minNotional = message.minNotional);
        return obj;
    },
    create(base) {
        return MsgInstantExpiryFuturesMarketLaunch.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgInstantExpiryFuturesMarketLaunch();
        message.sender = object.sender ?? "";
        message.ticker = object.ticker ?? "";
        message.quoteDenom = object.quoteDenom ?? "";
        message.oracleBase = object.oracleBase ?? "";
        message.oracleQuote = object.oracleQuote ?? "";
        message.oracleType = object.oracleType ?? 0;
        message.oracleScaleFactor = object.oracleScaleFactor ?? 0;
        message.expiry = object.expiry ?? "0";
        message.makerFeeRate = object.makerFeeRate ?? "";
        message.takerFeeRate = object.takerFeeRate ?? "";
        message.initialMarginRatio = object.initialMarginRatio ?? "";
        message.maintenanceMarginRatio = object.maintenanceMarginRatio ?? "";
        message.minPriceTickSize = object.minPriceTickSize ?? "";
        message.minQuantityTickSize = object.minQuantityTickSize ?? "";
        message.minNotional = object.minNotional ?? "";
        return message;
    },
};
function createBaseMsgInstantExpiryFuturesMarketLaunchResponse() {
    return {};
}
export const MsgInstantExpiryFuturesMarketLaunchResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgInstantExpiryFuturesMarketLaunchResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgInstantExpiryFuturesMarketLaunchResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgInstantExpiryFuturesMarketLaunchResponse();
        return message;
    },
};
function createBaseMsgCreateSpotMarketOrder() {
    return { sender: "", order: undefined };
}
export const MsgCreateSpotMarketOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.order !== undefined) {
            SpotOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateSpotMarketOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.order = SpotOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            order: isSet(object.order) ? SpotOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.order !== undefined && (obj.order = message.order ? SpotOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateSpotMarketOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateSpotMarketOrder();
        message.sender = object.sender ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? SpotOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateSpotMarketOrderResponse() {
    return { orderHash: "", results: undefined, cid: "" };
}
export const MsgCreateSpotMarketOrderResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.results !== undefined) {
            SpotMarketOrderResults.encode(message.results, writer.uint32(18).fork()).ldelim();
        }
        if (message.cid !== "") {
            writer.uint32(26).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateSpotMarketOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.results = SpotMarketOrderResults.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            results: isSet(object.results) ? SpotMarketOrderResults.fromJSON(object.results) : undefined,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.results !== undefined &&
            (obj.results = message.results ? SpotMarketOrderResults.toJSON(message.results) : undefined);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCreateSpotMarketOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateSpotMarketOrderResponse();
        message.orderHash = object.orderHash ?? "";
        message.results = (object.results !== undefined && object.results !== null)
            ? SpotMarketOrderResults.fromPartial(object.results)
            : undefined;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseSpotMarketOrderResults() {
    return { quantity: "", price: "", fee: "" };
}
export const SpotMarketOrderResults = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.quantity !== "") {
            writer.uint32(10).string(message.quantity);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        if (message.fee !== "") {
            writer.uint32(26).string(message.fee);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSpotMarketOrderResults();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quantity = reader.string();
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                case 3:
                    message.fee = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            price: isSet(object.price) ? String(object.price) : "",
            fee: isSet(object.fee) ? String(object.fee) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.price !== undefined && (obj.price = message.price);
        message.fee !== undefined && (obj.fee = message.fee);
        return obj;
    },
    create(base) {
        return SpotMarketOrderResults.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSpotMarketOrderResults();
        message.quantity = object.quantity ?? "";
        message.price = object.price ?? "";
        message.fee = object.fee ?? "";
        return message;
    },
};
function createBaseMsgCreateDerivativeLimitOrder() {
    return { sender: "", order: undefined };
}
export const MsgCreateDerivativeLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.order !== undefined) {
            DerivativeOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateDerivativeLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.order = DerivativeOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            order: isSet(object.order) ? DerivativeOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.order !== undefined && (obj.order = message.order ? DerivativeOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateDerivativeLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateDerivativeLimitOrder();
        message.sender = object.sender ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateDerivativeLimitOrderResponse() {
    return { orderHash: "", cid: "" };
}
export const MsgCreateDerivativeLimitOrderResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.cid !== "") {
            writer.uint32(18).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateDerivativeLimitOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCreateDerivativeLimitOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateDerivativeLimitOrderResponse();
        message.orderHash = object.orderHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgCreateBinaryOptionsLimitOrder() {
    return { sender: "", order: undefined };
}
export const MsgCreateBinaryOptionsLimitOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.order !== undefined) {
            DerivativeOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateBinaryOptionsLimitOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.order = DerivativeOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            order: isSet(object.order) ? DerivativeOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.order !== undefined && (obj.order = message.order ? DerivativeOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateBinaryOptionsLimitOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateBinaryOptionsLimitOrder();
        message.sender = object.sender ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateBinaryOptionsLimitOrderResponse() {
    return { orderHash: "", cid: "" };
}
export const MsgCreateBinaryOptionsLimitOrderResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.cid !== "") {
            writer.uint32(18).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateBinaryOptionsLimitOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCreateBinaryOptionsLimitOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateBinaryOptionsLimitOrderResponse();
        message.orderHash = object.orderHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgBatchCreateDerivativeLimitOrders() {
    return { sender: "", orders: [] };
}
export const MsgBatchCreateDerivativeLimitOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.orders) {
            DerivativeOrder.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCreateDerivativeLimitOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.orders.push(DerivativeOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            orders: Array.isArray(object?.orders) ? object.orders.map((e) => DerivativeOrder.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.orders) {
            obj.orders = message.orders.map((e) => e ? DerivativeOrder.toJSON(e) : undefined);
        }
        else {
            obj.orders = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCreateDerivativeLimitOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCreateDerivativeLimitOrders();
        message.sender = object.sender ?? "";
        message.orders = object.orders?.map((e) => DerivativeOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgBatchCreateDerivativeLimitOrdersResponse() {
    return { orderHashes: [], createdOrdersCids: [], failedOrdersCids: [] };
}
export const MsgBatchCreateDerivativeLimitOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.orderHashes) {
            writer.uint32(10).string(v);
        }
        for (const v of message.createdOrdersCids) {
            writer.uint32(18).string(v);
        }
        for (const v of message.failedOrdersCids) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCreateDerivativeLimitOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHashes.push(reader.string());
                    break;
                case 2:
                    message.createdOrdersCids.push(reader.string());
                    break;
                case 3:
                    message.failedOrdersCids.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHashes: Array.isArray(object?.orderHashes) ? object.orderHashes.map((e) => String(e)) : [],
            createdOrdersCids: Array.isArray(object?.createdOrdersCids)
                ? object.createdOrdersCids.map((e) => String(e))
                : [],
            failedOrdersCids: Array.isArray(object?.failedOrdersCids)
                ? object.failedOrdersCids.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.orderHashes) {
            obj.orderHashes = message.orderHashes.map((e) => e);
        }
        else {
            obj.orderHashes = [];
        }
        if (message.createdOrdersCids) {
            obj.createdOrdersCids = message.createdOrdersCids.map((e) => e);
        }
        else {
            obj.createdOrdersCids = [];
        }
        if (message.failedOrdersCids) {
            obj.failedOrdersCids = message.failedOrdersCids.map((e) => e);
        }
        else {
            obj.failedOrdersCids = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCreateDerivativeLimitOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCreateDerivativeLimitOrdersResponse();
        message.orderHashes = object.orderHashes?.map((e) => e) || [];
        message.createdOrdersCids = object.createdOrdersCids?.map((e) => e) || [];
        message.failedOrdersCids = object.failedOrdersCids?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgCancelSpotOrder() {
    return { sender: "", marketId: "", subaccountId: "", orderHash: "", cid: "" };
}
export const MsgCancelSpotOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.orderHash !== "") {
            writer.uint32(34).string(message.orderHash);
        }
        if (message.cid !== "") {
            writer.uint32(42).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelSpotOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.orderHash = reader.string();
                    break;
                case 5:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCancelSpotOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCancelSpotOrder();
        message.sender = object.sender ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.orderHash = object.orderHash ?? "";
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgCancelSpotOrderResponse() {
    return {};
}
export const MsgCancelSpotOrderResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelSpotOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgCancelSpotOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgCancelSpotOrderResponse();
        return message;
    },
};
function createBaseMsgBatchCancelSpotOrders() {
    return { sender: "", data: [] };
}
export const MsgBatchCancelSpotOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.data) {
            OrderData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCancelSpotOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.data.push(OrderData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            data: Array.isArray(object?.data) ? object.data.map((e) => OrderData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.data) {
            obj.data = message.data.map((e) => e ? OrderData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCancelSpotOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCancelSpotOrders();
        message.sender = object.sender ?? "";
        message.data = object.data?.map((e) => OrderData.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgBatchCancelSpotOrdersResponse() {
    return { success: [] };
}
export const MsgBatchCancelSpotOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.success) {
            writer.bool(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCancelSpotOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.success.push(reader.bool());
                        }
                    }
                    else {
                        message.success.push(reader.bool());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { success: Array.isArray(object?.success) ? object.success.map((e) => Boolean(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.success) {
            obj.success = message.success.map((e) => e);
        }
        else {
            obj.success = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCancelSpotOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCancelSpotOrdersResponse();
        message.success = object.success?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgBatchCancelBinaryOptionsOrders() {
    return { sender: "", data: [] };
}
export const MsgBatchCancelBinaryOptionsOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.data) {
            OrderData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCancelBinaryOptionsOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.data.push(OrderData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            data: Array.isArray(object?.data) ? object.data.map((e) => OrderData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.data) {
            obj.data = message.data.map((e) => e ? OrderData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCancelBinaryOptionsOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCancelBinaryOptionsOrders();
        message.sender = object.sender ?? "";
        message.data = object.data?.map((e) => OrderData.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgBatchCancelBinaryOptionsOrdersResponse() {
    return { success: [] };
}
export const MsgBatchCancelBinaryOptionsOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.success) {
            writer.bool(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCancelBinaryOptionsOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.success.push(reader.bool());
                        }
                    }
                    else {
                        message.success.push(reader.bool());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { success: Array.isArray(object?.success) ? object.success.map((e) => Boolean(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.success) {
            obj.success = message.success.map((e) => e);
        }
        else {
            obj.success = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCancelBinaryOptionsOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCancelBinaryOptionsOrdersResponse();
        message.success = object.success?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgBatchUpdateOrders() {
    return {
        sender: "",
        subaccountId: "",
        spotMarketIdsToCancelAll: [],
        derivativeMarketIdsToCancelAll: [],
        spotOrdersToCancel: [],
        derivativeOrdersToCancel: [],
        spotOrdersToCreate: [],
        derivativeOrdersToCreate: [],
        binaryOptionsOrdersToCancel: [],
        binaryOptionsMarketIdsToCancelAll: [],
        binaryOptionsOrdersToCreate: [],
    };
}
export const MsgBatchUpdateOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        for (const v of message.spotMarketIdsToCancelAll) {
            writer.uint32(26).string(v);
        }
        for (const v of message.derivativeMarketIdsToCancelAll) {
            writer.uint32(34).string(v);
        }
        for (const v of message.spotOrdersToCancel) {
            OrderData.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.derivativeOrdersToCancel) {
            OrderData.encode(v, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.spotOrdersToCreate) {
            SpotOrder.encode(v, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.derivativeOrdersToCreate) {
            DerivativeOrder.encode(v, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.binaryOptionsOrdersToCancel) {
            OrderData.encode(v, writer.uint32(74).fork()).ldelim();
        }
        for (const v of message.binaryOptionsMarketIdsToCancelAll) {
            writer.uint32(82).string(v);
        }
        for (const v of message.binaryOptionsOrdersToCreate) {
            DerivativeOrder.encode(v, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchUpdateOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.spotMarketIdsToCancelAll.push(reader.string());
                    break;
                case 4:
                    message.derivativeMarketIdsToCancelAll.push(reader.string());
                    break;
                case 5:
                    message.spotOrdersToCancel.push(OrderData.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.derivativeOrdersToCancel.push(OrderData.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.spotOrdersToCreate.push(SpotOrder.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.derivativeOrdersToCreate.push(DerivativeOrder.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.binaryOptionsOrdersToCancel.push(OrderData.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.binaryOptionsMarketIdsToCancelAll.push(reader.string());
                    break;
                case 11:
                    message.binaryOptionsOrdersToCreate.push(DerivativeOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            spotMarketIdsToCancelAll: Array.isArray(object?.spotMarketIdsToCancelAll)
                ? object.spotMarketIdsToCancelAll.map((e) => String(e))
                : [],
            derivativeMarketIdsToCancelAll: Array.isArray(object?.derivativeMarketIdsToCancelAll)
                ? object.derivativeMarketIdsToCancelAll.map((e) => String(e))
                : [],
            spotOrdersToCancel: Array.isArray(object?.spotOrdersToCancel)
                ? object.spotOrdersToCancel.map((e) => OrderData.fromJSON(e))
                : [],
            derivativeOrdersToCancel: Array.isArray(object?.derivativeOrdersToCancel)
                ? object.derivativeOrdersToCancel.map((e) => OrderData.fromJSON(e))
                : [],
            spotOrdersToCreate: Array.isArray(object?.spotOrdersToCreate)
                ? object.spotOrdersToCreate.map((e) => SpotOrder.fromJSON(e))
                : [],
            derivativeOrdersToCreate: Array.isArray(object?.derivativeOrdersToCreate)
                ? object.derivativeOrdersToCreate.map((e) => DerivativeOrder.fromJSON(e))
                : [],
            binaryOptionsOrdersToCancel: Array.isArray(object?.binaryOptionsOrdersToCancel)
                ? object.binaryOptionsOrdersToCancel.map((e) => OrderData.fromJSON(e))
                : [],
            binaryOptionsMarketIdsToCancelAll: Array.isArray(object?.binaryOptionsMarketIdsToCancelAll)
                ? object.binaryOptionsMarketIdsToCancelAll.map((e) => String(e))
                : [],
            binaryOptionsOrdersToCreate: Array.isArray(object?.binaryOptionsOrdersToCreate)
                ? object.binaryOptionsOrdersToCreate.map((e) => DerivativeOrder.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        if (message.spotMarketIdsToCancelAll) {
            obj.spotMarketIdsToCancelAll = message.spotMarketIdsToCancelAll.map((e) => e);
        }
        else {
            obj.spotMarketIdsToCancelAll = [];
        }
        if (message.derivativeMarketIdsToCancelAll) {
            obj.derivativeMarketIdsToCancelAll = message.derivativeMarketIdsToCancelAll.map((e) => e);
        }
        else {
            obj.derivativeMarketIdsToCancelAll = [];
        }
        if (message.spotOrdersToCancel) {
            obj.spotOrdersToCancel = message.spotOrdersToCancel.map((e) => e ? OrderData.toJSON(e) : undefined);
        }
        else {
            obj.spotOrdersToCancel = [];
        }
        if (message.derivativeOrdersToCancel) {
            obj.derivativeOrdersToCancel = message.derivativeOrdersToCancel.map((e) => e ? OrderData.toJSON(e) : undefined);
        }
        else {
            obj.derivativeOrdersToCancel = [];
        }
        if (message.spotOrdersToCreate) {
            obj.spotOrdersToCreate = message.spotOrdersToCreate.map((e) => e ? SpotOrder.toJSON(e) : undefined);
        }
        else {
            obj.spotOrdersToCreate = [];
        }
        if (message.derivativeOrdersToCreate) {
            obj.derivativeOrdersToCreate = message.derivativeOrdersToCreate.map((e) => e ? DerivativeOrder.toJSON(e) : undefined);
        }
        else {
            obj.derivativeOrdersToCreate = [];
        }
        if (message.binaryOptionsOrdersToCancel) {
            obj.binaryOptionsOrdersToCancel = message.binaryOptionsOrdersToCancel.map((e) => e ? OrderData.toJSON(e) : undefined);
        }
        else {
            obj.binaryOptionsOrdersToCancel = [];
        }
        if (message.binaryOptionsMarketIdsToCancelAll) {
            obj.binaryOptionsMarketIdsToCancelAll = message.binaryOptionsMarketIdsToCancelAll.map((e) => e);
        }
        else {
            obj.binaryOptionsMarketIdsToCancelAll = [];
        }
        if (message.binaryOptionsOrdersToCreate) {
            obj.binaryOptionsOrdersToCreate = message.binaryOptionsOrdersToCreate.map((e) => e ? DerivativeOrder.toJSON(e) : undefined);
        }
        else {
            obj.binaryOptionsOrdersToCreate = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchUpdateOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchUpdateOrders();
        message.sender = object.sender ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.spotMarketIdsToCancelAll = object.spotMarketIdsToCancelAll?.map((e) => e) || [];
        message.derivativeMarketIdsToCancelAll = object.derivativeMarketIdsToCancelAll?.map((e) => e) || [];
        message.spotOrdersToCancel = object.spotOrdersToCancel?.map((e) => OrderData.fromPartial(e)) || [];
        message.derivativeOrdersToCancel = object.derivativeOrdersToCancel?.map((e) => OrderData.fromPartial(e)) || [];
        message.spotOrdersToCreate = object.spotOrdersToCreate?.map((e) => SpotOrder.fromPartial(e)) || [];
        message.derivativeOrdersToCreate = object.derivativeOrdersToCreate?.map((e) => DerivativeOrder.fromPartial(e)) ||
            [];
        message.binaryOptionsOrdersToCancel = object.binaryOptionsOrdersToCancel?.map((e) => OrderData.fromPartial(e)) ||
            [];
        message.binaryOptionsMarketIdsToCancelAll = object.binaryOptionsMarketIdsToCancelAll?.map((e) => e) || [];
        message.binaryOptionsOrdersToCreate =
            object.binaryOptionsOrdersToCreate?.map((e) => DerivativeOrder.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgBatchUpdateOrdersResponse() {
    return {
        spotCancelSuccess: [],
        derivativeCancelSuccess: [],
        spotOrderHashes: [],
        derivativeOrderHashes: [],
        binaryOptionsCancelSuccess: [],
        binaryOptionsOrderHashes: [],
        createdSpotOrdersCids: [],
        failedSpotOrdersCids: [],
        createdDerivativeOrdersCids: [],
        failedDerivativeOrdersCids: [],
        createdBinaryOptionsOrdersCids: [],
        failedBinaryOptionsOrdersCids: [],
    };
}
export const MsgBatchUpdateOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.spotCancelSuccess) {
            writer.bool(v);
        }
        writer.ldelim();
        writer.uint32(18).fork();
        for (const v of message.derivativeCancelSuccess) {
            writer.bool(v);
        }
        writer.ldelim();
        for (const v of message.spotOrderHashes) {
            writer.uint32(26).string(v);
        }
        for (const v of message.derivativeOrderHashes) {
            writer.uint32(34).string(v);
        }
        writer.uint32(42).fork();
        for (const v of message.binaryOptionsCancelSuccess) {
            writer.bool(v);
        }
        writer.ldelim();
        for (const v of message.binaryOptionsOrderHashes) {
            writer.uint32(50).string(v);
        }
        for (const v of message.createdSpotOrdersCids) {
            writer.uint32(58).string(v);
        }
        for (const v of message.failedSpotOrdersCids) {
            writer.uint32(66).string(v);
        }
        for (const v of message.createdDerivativeOrdersCids) {
            writer.uint32(74).string(v);
        }
        for (const v of message.failedDerivativeOrdersCids) {
            writer.uint32(82).string(v);
        }
        for (const v of message.createdBinaryOptionsOrdersCids) {
            writer.uint32(90).string(v);
        }
        for (const v of message.failedBinaryOptionsOrdersCids) {
            writer.uint32(98).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchUpdateOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.spotCancelSuccess.push(reader.bool());
                        }
                    }
                    else {
                        message.spotCancelSuccess.push(reader.bool());
                    }
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.derivativeCancelSuccess.push(reader.bool());
                        }
                    }
                    else {
                        message.derivativeCancelSuccess.push(reader.bool());
                    }
                    break;
                case 3:
                    message.spotOrderHashes.push(reader.string());
                    break;
                case 4:
                    message.derivativeOrderHashes.push(reader.string());
                    break;
                case 5:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.binaryOptionsCancelSuccess.push(reader.bool());
                        }
                    }
                    else {
                        message.binaryOptionsCancelSuccess.push(reader.bool());
                    }
                    break;
                case 6:
                    message.binaryOptionsOrderHashes.push(reader.string());
                    break;
                case 7:
                    message.createdSpotOrdersCids.push(reader.string());
                    break;
                case 8:
                    message.failedSpotOrdersCids.push(reader.string());
                    break;
                case 9:
                    message.createdDerivativeOrdersCids.push(reader.string());
                    break;
                case 10:
                    message.failedDerivativeOrdersCids.push(reader.string());
                    break;
                case 11:
                    message.createdBinaryOptionsOrdersCids.push(reader.string());
                    break;
                case 12:
                    message.failedBinaryOptionsOrdersCids.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            spotCancelSuccess: Array.isArray(object?.spotCancelSuccess)
                ? object.spotCancelSuccess.map((e) => Boolean(e))
                : [],
            derivativeCancelSuccess: Array.isArray(object?.derivativeCancelSuccess)
                ? object.derivativeCancelSuccess.map((e) => Boolean(e))
                : [],
            spotOrderHashes: Array.isArray(object?.spotOrderHashes) ? object.spotOrderHashes.map((e) => String(e)) : [],
            derivativeOrderHashes: Array.isArray(object?.derivativeOrderHashes)
                ? object.derivativeOrderHashes.map((e) => String(e))
                : [],
            binaryOptionsCancelSuccess: Array.isArray(object?.binaryOptionsCancelSuccess)
                ? object.binaryOptionsCancelSuccess.map((e) => Boolean(e))
                : [],
            binaryOptionsOrderHashes: Array.isArray(object?.binaryOptionsOrderHashes)
                ? object.binaryOptionsOrderHashes.map((e) => String(e))
                : [],
            createdSpotOrdersCids: Array.isArray(object?.createdSpotOrdersCids)
                ? object.createdSpotOrdersCids.map((e) => String(e))
                : [],
            failedSpotOrdersCids: Array.isArray(object?.failedSpotOrdersCids)
                ? object.failedSpotOrdersCids.map((e) => String(e))
                : [],
            createdDerivativeOrdersCids: Array.isArray(object?.createdDerivativeOrdersCids)
                ? object.createdDerivativeOrdersCids.map((e) => String(e))
                : [],
            failedDerivativeOrdersCids: Array.isArray(object?.failedDerivativeOrdersCids)
                ? object.failedDerivativeOrdersCids.map((e) => String(e))
                : [],
            createdBinaryOptionsOrdersCids: Array.isArray(object?.createdBinaryOptionsOrdersCids)
                ? object.createdBinaryOptionsOrdersCids.map((e) => String(e))
                : [],
            failedBinaryOptionsOrdersCids: Array.isArray(object?.failedBinaryOptionsOrdersCids)
                ? object.failedBinaryOptionsOrdersCids.map((e) => String(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.spotCancelSuccess) {
            obj.spotCancelSuccess = message.spotCancelSuccess.map((e) => e);
        }
        else {
            obj.spotCancelSuccess = [];
        }
        if (message.derivativeCancelSuccess) {
            obj.derivativeCancelSuccess = message.derivativeCancelSuccess.map((e) => e);
        }
        else {
            obj.derivativeCancelSuccess = [];
        }
        if (message.spotOrderHashes) {
            obj.spotOrderHashes = message.spotOrderHashes.map((e) => e);
        }
        else {
            obj.spotOrderHashes = [];
        }
        if (message.derivativeOrderHashes) {
            obj.derivativeOrderHashes = message.derivativeOrderHashes.map((e) => e);
        }
        else {
            obj.derivativeOrderHashes = [];
        }
        if (message.binaryOptionsCancelSuccess) {
            obj.binaryOptionsCancelSuccess = message.binaryOptionsCancelSuccess.map((e) => e);
        }
        else {
            obj.binaryOptionsCancelSuccess = [];
        }
        if (message.binaryOptionsOrderHashes) {
            obj.binaryOptionsOrderHashes = message.binaryOptionsOrderHashes.map((e) => e);
        }
        else {
            obj.binaryOptionsOrderHashes = [];
        }
        if (message.createdSpotOrdersCids) {
            obj.createdSpotOrdersCids = message.createdSpotOrdersCids.map((e) => e);
        }
        else {
            obj.createdSpotOrdersCids = [];
        }
        if (message.failedSpotOrdersCids) {
            obj.failedSpotOrdersCids = message.failedSpotOrdersCids.map((e) => e);
        }
        else {
            obj.failedSpotOrdersCids = [];
        }
        if (message.createdDerivativeOrdersCids) {
            obj.createdDerivativeOrdersCids = message.createdDerivativeOrdersCids.map((e) => e);
        }
        else {
            obj.createdDerivativeOrdersCids = [];
        }
        if (message.failedDerivativeOrdersCids) {
            obj.failedDerivativeOrdersCids = message.failedDerivativeOrdersCids.map((e) => e);
        }
        else {
            obj.failedDerivativeOrdersCids = [];
        }
        if (message.createdBinaryOptionsOrdersCids) {
            obj.createdBinaryOptionsOrdersCids = message.createdBinaryOptionsOrdersCids.map((e) => e);
        }
        else {
            obj.createdBinaryOptionsOrdersCids = [];
        }
        if (message.failedBinaryOptionsOrdersCids) {
            obj.failedBinaryOptionsOrdersCids = message.failedBinaryOptionsOrdersCids.map((e) => e);
        }
        else {
            obj.failedBinaryOptionsOrdersCids = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchUpdateOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchUpdateOrdersResponse();
        message.spotCancelSuccess = object.spotCancelSuccess?.map((e) => e) || [];
        message.derivativeCancelSuccess = object.derivativeCancelSuccess?.map((e) => e) || [];
        message.spotOrderHashes = object.spotOrderHashes?.map((e) => e) || [];
        message.derivativeOrderHashes = object.derivativeOrderHashes?.map((e) => e) || [];
        message.binaryOptionsCancelSuccess = object.binaryOptionsCancelSuccess?.map((e) => e) || [];
        message.binaryOptionsOrderHashes = object.binaryOptionsOrderHashes?.map((e) => e) || [];
        message.createdSpotOrdersCids = object.createdSpotOrdersCids?.map((e) => e) || [];
        message.failedSpotOrdersCids = object.failedSpotOrdersCids?.map((e) => e) || [];
        message.createdDerivativeOrdersCids = object.createdDerivativeOrdersCids?.map((e) => e) || [];
        message.failedDerivativeOrdersCids = object.failedDerivativeOrdersCids?.map((e) => e) || [];
        message.createdBinaryOptionsOrdersCids = object.createdBinaryOptionsOrdersCids?.map((e) => e) || [];
        message.failedBinaryOptionsOrdersCids = object.failedBinaryOptionsOrdersCids?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgCreateDerivativeMarketOrder() {
    return { sender: "", order: undefined };
}
export const MsgCreateDerivativeMarketOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.order !== undefined) {
            DerivativeOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateDerivativeMarketOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.order = DerivativeOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            order: isSet(object.order) ? DerivativeOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.order !== undefined && (obj.order = message.order ? DerivativeOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateDerivativeMarketOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateDerivativeMarketOrder();
        message.sender = object.sender ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateDerivativeMarketOrderResponse() {
    return { orderHash: "", results: undefined, cid: "" };
}
export const MsgCreateDerivativeMarketOrderResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.results !== undefined) {
            DerivativeMarketOrderResults.encode(message.results, writer.uint32(18).fork()).ldelim();
        }
        if (message.cid !== "") {
            writer.uint32(26).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateDerivativeMarketOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.results = DerivativeMarketOrderResults.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            results: isSet(object.results) ? DerivativeMarketOrderResults.fromJSON(object.results) : undefined,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.results !== undefined &&
            (obj.results = message.results ? DerivativeMarketOrderResults.toJSON(message.results) : undefined);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCreateDerivativeMarketOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateDerivativeMarketOrderResponse();
        message.orderHash = object.orderHash ?? "";
        message.results = (object.results !== undefined && object.results !== null)
            ? DerivativeMarketOrderResults.fromPartial(object.results)
            : undefined;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseDerivativeMarketOrderResults() {
    return { quantity: "", price: "", fee: "", positionDelta: undefined, payout: "" };
}
export const DerivativeMarketOrderResults = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.quantity !== "") {
            writer.uint32(10).string(message.quantity);
        }
        if (message.price !== "") {
            writer.uint32(18).string(message.price);
        }
        if (message.fee !== "") {
            writer.uint32(26).string(message.fee);
        }
        if (message.positionDelta !== undefined) {
            PositionDelta.encode(message.positionDelta, writer.uint32(34).fork()).ldelim();
        }
        if (message.payout !== "") {
            writer.uint32(42).string(message.payout);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDerivativeMarketOrderResults();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quantity = reader.string();
                    break;
                case 2:
                    message.price = reader.string();
                    break;
                case 3:
                    message.fee = reader.string();
                    break;
                case 4:
                    message.positionDelta = PositionDelta.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.payout = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            quantity: isSet(object.quantity) ? String(object.quantity) : "",
            price: isSet(object.price) ? String(object.price) : "",
            fee: isSet(object.fee) ? String(object.fee) : "",
            positionDelta: isSet(object.positionDelta) ? PositionDelta.fromJSON(object.positionDelta) : undefined,
            payout: isSet(object.payout) ? String(object.payout) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.quantity !== undefined && (obj.quantity = message.quantity);
        message.price !== undefined && (obj.price = message.price);
        message.fee !== undefined && (obj.fee = message.fee);
        message.positionDelta !== undefined &&
            (obj.positionDelta = message.positionDelta ? PositionDelta.toJSON(message.positionDelta) : undefined);
        message.payout !== undefined && (obj.payout = message.payout);
        return obj;
    },
    create(base) {
        return DerivativeMarketOrderResults.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDerivativeMarketOrderResults();
        message.quantity = object.quantity ?? "";
        message.price = object.price ?? "";
        message.fee = object.fee ?? "";
        message.positionDelta = (object.positionDelta !== undefined && object.positionDelta !== null)
            ? PositionDelta.fromPartial(object.positionDelta)
            : undefined;
        message.payout = object.payout ?? "";
        return message;
    },
};
function createBaseMsgCreateBinaryOptionsMarketOrder() {
    return { sender: "", order: undefined };
}
export const MsgCreateBinaryOptionsMarketOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.order !== undefined) {
            DerivativeOrder.encode(message.order, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateBinaryOptionsMarketOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.order = DerivativeOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            order: isSet(object.order) ? DerivativeOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.order !== undefined && (obj.order = message.order ? DerivativeOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgCreateBinaryOptionsMarketOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateBinaryOptionsMarketOrder();
        message.sender = object.sender ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgCreateBinaryOptionsMarketOrderResponse() {
    return { orderHash: "", results: undefined, cid: "" };
}
export const MsgCreateBinaryOptionsMarketOrderResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.orderHash !== "") {
            writer.uint32(10).string(message.orderHash);
        }
        if (message.results !== undefined) {
            DerivativeMarketOrderResults.encode(message.results, writer.uint32(18).fork()).ldelim();
        }
        if (message.cid !== "") {
            writer.uint32(26).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateBinaryOptionsMarketOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderHash = reader.string();
                    break;
                case 2:
                    message.results = DerivativeMarketOrderResults.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            results: isSet(object.results) ? DerivativeMarketOrderResults.fromJSON(object.results) : undefined,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.results !== undefined &&
            (obj.results = message.results ? DerivativeMarketOrderResults.toJSON(message.results) : undefined);
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCreateBinaryOptionsMarketOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCreateBinaryOptionsMarketOrderResponse();
        message.orderHash = object.orderHash ?? "";
        message.results = (object.results !== undefined && object.results !== null)
            ? DerivativeMarketOrderResults.fromPartial(object.results)
            : undefined;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgCancelDerivativeOrder() {
    return { sender: "", marketId: "", subaccountId: "", orderHash: "", orderMask: 0, cid: "" };
}
export const MsgCancelDerivativeOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.orderHash !== "") {
            writer.uint32(34).string(message.orderHash);
        }
        if (message.orderMask !== 0) {
            writer.uint32(40).int32(message.orderMask);
        }
        if (message.cid !== "") {
            writer.uint32(50).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelDerivativeOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.orderHash = reader.string();
                    break;
                case 5:
                    message.orderMask = reader.int32();
                    break;
                case 6:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderMask: isSet(object.orderMask) ? Number(object.orderMask) : 0,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderMask !== undefined && (obj.orderMask = Math.round(message.orderMask));
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCancelDerivativeOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCancelDerivativeOrder();
        message.sender = object.sender ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.orderHash = object.orderHash ?? "";
        message.orderMask = object.orderMask ?? 0;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgCancelDerivativeOrderResponse() {
    return {};
}
export const MsgCancelDerivativeOrderResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelDerivativeOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgCancelDerivativeOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgCancelDerivativeOrderResponse();
        return message;
    },
};
function createBaseMsgCancelBinaryOptionsOrder() {
    return { sender: "", marketId: "", subaccountId: "", orderHash: "", orderMask: 0, cid: "" };
}
export const MsgCancelBinaryOptionsOrder = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(26).string(message.subaccountId);
        }
        if (message.orderHash !== "") {
            writer.uint32(34).string(message.orderHash);
        }
        if (message.orderMask !== 0) {
            writer.uint32(40).int32(message.orderMask);
        }
        if (message.cid !== "") {
            writer.uint32(50).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelBinaryOptionsOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.subaccountId = reader.string();
                    break;
                case 4:
                    message.orderHash = reader.string();
                    break;
                case 5:
                    message.orderMask = reader.int32();
                    break;
                case 6:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderMask: isSet(object.orderMask) ? Number(object.orderMask) : 0,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderMask !== undefined && (obj.orderMask = Math.round(message.orderMask));
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return MsgCancelBinaryOptionsOrder.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgCancelBinaryOptionsOrder();
        message.sender = object.sender ?? "";
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.orderHash = object.orderHash ?? "";
        message.orderMask = object.orderMask ?? 0;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgCancelBinaryOptionsOrderResponse() {
    return {};
}
export const MsgCancelBinaryOptionsOrderResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelBinaryOptionsOrderResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgCancelBinaryOptionsOrderResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgCancelBinaryOptionsOrderResponse();
        return message;
    },
};
function createBaseOrderData() {
    return { marketId: "", subaccountId: "", orderHash: "", orderMask: 0, cid: "" };
}
export const OrderData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.marketId !== "") {
            writer.uint32(10).string(message.marketId);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.orderHash !== "") {
            writer.uint32(26).string(message.orderHash);
        }
        if (message.orderMask !== 0) {
            writer.uint32(32).int32(message.orderMask);
        }
        if (message.cid !== "") {
            writer.uint32(42).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketId = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.orderHash = reader.string();
                    break;
                case 4:
                    message.orderMask = reader.int32();
                    break;
                case 5:
                    message.cid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            orderHash: isSet(object.orderHash) ? String(object.orderHash) : "",
            orderMask: isSet(object.orderMask) ? Number(object.orderMask) : 0,
            cid: isSet(object.cid) ? String(object.cid) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.orderHash !== undefined && (obj.orderHash = message.orderHash);
        message.orderMask !== undefined && (obj.orderMask = Math.round(message.orderMask));
        message.cid !== undefined && (obj.cid = message.cid);
        return obj;
    },
    create(base) {
        return OrderData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOrderData();
        message.marketId = object.marketId ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.orderHash = object.orderHash ?? "";
        message.orderMask = object.orderMask ?? 0;
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseMsgBatchCancelDerivativeOrders() {
    return { sender: "", data: [] };
}
export const MsgBatchCancelDerivativeOrders = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.data) {
            OrderData.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCancelDerivativeOrders();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.data.push(OrderData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            data: Array.isArray(object?.data) ? object.data.map((e) => OrderData.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.data) {
            obj.data = message.data.map((e) => e ? OrderData.toJSON(e) : undefined);
        }
        else {
            obj.data = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCancelDerivativeOrders.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCancelDerivativeOrders();
        message.sender = object.sender ?? "";
        message.data = object.data?.map((e) => OrderData.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgBatchCancelDerivativeOrdersResponse() {
    return { success: [] };
}
export const MsgBatchCancelDerivativeOrdersResponse = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.success) {
            writer.bool(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBatchCancelDerivativeOrdersResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.success.push(reader.bool());
                        }
                    }
                    else {
                        message.success.push(reader.bool());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { success: Array.isArray(object?.success) ? object.success.map((e) => Boolean(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.success) {
            obj.success = message.success.map((e) => e);
        }
        else {
            obj.success = [];
        }
        return obj;
    },
    create(base) {
        return MsgBatchCancelDerivativeOrdersResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgBatchCancelDerivativeOrdersResponse();
        message.success = object.success?.map((e) => e) || [];
        return message;
    },
};
function createBaseMsgSubaccountTransfer() {
    return { sender: "", sourceSubaccountId: "", destinationSubaccountId: "", amount: undefined };
}
export const MsgSubaccountTransfer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.sourceSubaccountId !== "") {
            writer.uint32(18).string(message.sourceSubaccountId);
        }
        if (message.destinationSubaccountId !== "") {
            writer.uint32(26).string(message.destinationSubaccountId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSubaccountTransfer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.sourceSubaccountId = reader.string();
                    break;
                case 3:
                    message.destinationSubaccountId = reader.string();
                    break;
                case 4:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            sourceSubaccountId: isSet(object.sourceSubaccountId) ? String(object.sourceSubaccountId) : "",
            destinationSubaccountId: isSet(object.destinationSubaccountId) ? String(object.destinationSubaccountId) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.sourceSubaccountId !== undefined && (obj.sourceSubaccountId = message.sourceSubaccountId);
        message.destinationSubaccountId !== undefined && (obj.destinationSubaccountId = message.destinationSubaccountId);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return MsgSubaccountTransfer.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgSubaccountTransfer();
        message.sender = object.sender ?? "";
        message.sourceSubaccountId = object.sourceSubaccountId ?? "";
        message.destinationSubaccountId = object.destinationSubaccountId ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseMsgSubaccountTransferResponse() {
    return {};
}
export const MsgSubaccountTransferResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSubaccountTransferResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgSubaccountTransferResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgSubaccountTransferResponse();
        return message;
    },
};
function createBaseMsgExternalTransfer() {
    return { sender: "", sourceSubaccountId: "", destinationSubaccountId: "", amount: undefined };
}
export const MsgExternalTransfer = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.sourceSubaccountId !== "") {
            writer.uint32(18).string(message.sourceSubaccountId);
        }
        if (message.destinationSubaccountId !== "") {
            writer.uint32(26).string(message.destinationSubaccountId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExternalTransfer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.sourceSubaccountId = reader.string();
                    break;
                case 3:
                    message.destinationSubaccountId = reader.string();
                    break;
                case 4:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            sourceSubaccountId: isSet(object.sourceSubaccountId) ? String(object.sourceSubaccountId) : "",
            destinationSubaccountId: isSet(object.destinationSubaccountId) ? String(object.destinationSubaccountId) : "",
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.sourceSubaccountId !== undefined && (obj.sourceSubaccountId = message.sourceSubaccountId);
        message.destinationSubaccountId !== undefined && (obj.destinationSubaccountId = message.destinationSubaccountId);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    create(base) {
        return MsgExternalTransfer.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgExternalTransfer();
        message.sender = object.sender ?? "";
        message.sourceSubaccountId = object.sourceSubaccountId ?? "";
        message.destinationSubaccountId = object.destinationSubaccountId ?? "";
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        return message;
    },
};
function createBaseMsgExternalTransferResponse() {
    return {};
}
export const MsgExternalTransferResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExternalTransferResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgExternalTransferResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgExternalTransferResponse();
        return message;
    },
};
function createBaseMsgLiquidatePosition() {
    return { sender: "", subaccountId: "", marketId: "", order: undefined };
}
export const MsgLiquidatePosition = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        if (message.order !== undefined) {
            DerivativeOrder.encode(message.order, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgLiquidatePosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                case 4:
                    message.order = DerivativeOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            order: isSet(object.order) ? DerivativeOrder.fromJSON(object.order) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.order !== undefined && (obj.order = message.order ? DerivativeOrder.toJSON(message.order) : undefined);
        return obj;
    },
    create(base) {
        return MsgLiquidatePosition.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgLiquidatePosition();
        message.sender = object.sender ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.order = (object.order !== undefined && object.order !== null)
            ? DerivativeOrder.fromPartial(object.order)
            : undefined;
        return message;
    },
};
function createBaseMsgLiquidatePositionResponse() {
    return {};
}
export const MsgLiquidatePositionResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgLiquidatePositionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgLiquidatePositionResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgLiquidatePositionResponse();
        return message;
    },
};
function createBaseMsgEmergencySettleMarket() {
    return { sender: "", subaccountId: "", marketId: "" };
}
export const MsgEmergencySettleMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.subaccountId !== "") {
            writer.uint32(18).string(message.subaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgEmergencySettleMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.subaccountId = reader.string();
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            subaccountId: isSet(object.subaccountId) ? String(object.subaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.subaccountId !== undefined && (obj.subaccountId = message.subaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return MsgEmergencySettleMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgEmergencySettleMarket();
        message.sender = object.sender ?? "";
        message.subaccountId = object.subaccountId ?? "";
        message.marketId = object.marketId ?? "";
        return message;
    },
};
function createBaseMsgEmergencySettleMarketResponse() {
    return {};
}
export const MsgEmergencySettleMarketResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgEmergencySettleMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgEmergencySettleMarketResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgEmergencySettleMarketResponse();
        return message;
    },
};
function createBaseMsgIncreasePositionMargin() {
    return { sender: "", sourceSubaccountId: "", destinationSubaccountId: "", marketId: "", amount: "" };
}
export const MsgIncreasePositionMargin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.sourceSubaccountId !== "") {
            writer.uint32(18).string(message.sourceSubaccountId);
        }
        if (message.destinationSubaccountId !== "") {
            writer.uint32(26).string(message.destinationSubaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(34).string(message.marketId);
        }
        if (message.amount !== "") {
            writer.uint32(42).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgIncreasePositionMargin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.sourceSubaccountId = reader.string();
                    break;
                case 3:
                    message.destinationSubaccountId = reader.string();
                    break;
                case 4:
                    message.marketId = reader.string();
                    break;
                case 5:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            sourceSubaccountId: isSet(object.sourceSubaccountId) ? String(object.sourceSubaccountId) : "",
            destinationSubaccountId: isSet(object.destinationSubaccountId) ? String(object.destinationSubaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.sourceSubaccountId !== undefined && (obj.sourceSubaccountId = message.sourceSubaccountId);
        message.destinationSubaccountId !== undefined && (obj.destinationSubaccountId = message.destinationSubaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return MsgIncreasePositionMargin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgIncreasePositionMargin();
        message.sender = object.sender ?? "";
        message.sourceSubaccountId = object.sourceSubaccountId ?? "";
        message.destinationSubaccountId = object.destinationSubaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseMsgIncreasePositionMarginResponse() {
    return {};
}
export const MsgIncreasePositionMarginResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgIncreasePositionMarginResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgIncreasePositionMarginResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgIncreasePositionMarginResponse();
        return message;
    },
};
function createBaseMsgDecreasePositionMargin() {
    return { sender: "", sourceSubaccountId: "", destinationSubaccountId: "", marketId: "", amount: "" };
}
export const MsgDecreasePositionMargin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.sourceSubaccountId !== "") {
            writer.uint32(18).string(message.sourceSubaccountId);
        }
        if (message.destinationSubaccountId !== "") {
            writer.uint32(26).string(message.destinationSubaccountId);
        }
        if (message.marketId !== "") {
            writer.uint32(34).string(message.marketId);
        }
        if (message.amount !== "") {
            writer.uint32(42).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDecreasePositionMargin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.sourceSubaccountId = reader.string();
                    break;
                case 3:
                    message.destinationSubaccountId = reader.string();
                    break;
                case 4:
                    message.marketId = reader.string();
                    break;
                case 5:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            sourceSubaccountId: isSet(object.sourceSubaccountId) ? String(object.sourceSubaccountId) : "",
            destinationSubaccountId: isSet(object.destinationSubaccountId) ? String(object.destinationSubaccountId) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.sourceSubaccountId !== undefined && (obj.sourceSubaccountId = message.sourceSubaccountId);
        message.destinationSubaccountId !== undefined && (obj.destinationSubaccountId = message.destinationSubaccountId);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return MsgDecreasePositionMargin.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgDecreasePositionMargin();
        message.sender = object.sender ?? "";
        message.sourceSubaccountId = object.sourceSubaccountId ?? "";
        message.destinationSubaccountId = object.destinationSubaccountId ?? "";
        message.marketId = object.marketId ?? "";
        message.amount = object.amount ?? "";
        return message;
    },
};
function createBaseMsgDecreasePositionMarginResponse() {
    return {};
}
export const MsgDecreasePositionMarginResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDecreasePositionMarginResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgDecreasePositionMarginResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgDecreasePositionMarginResponse();
        return message;
    },
};
function createBaseMsgPrivilegedExecuteContract() {
    return { sender: "", funds: "", contractAddress: "", data: "" };
}
export const MsgPrivilegedExecuteContract = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.funds !== "") {
            writer.uint32(18).string(message.funds);
        }
        if (message.contractAddress !== "") {
            writer.uint32(26).string(message.contractAddress);
        }
        if (message.data !== "") {
            writer.uint32(34).string(message.data);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgPrivilegedExecuteContract();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.funds = reader.string();
                    break;
                case 3:
                    message.contractAddress = reader.string();
                    break;
                case 4:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            funds: isSet(object.funds) ? String(object.funds) : "",
            contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
            data: isSet(object.data) ? String(object.data) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.funds !== undefined && (obj.funds = message.funds);
        message.contractAddress !== undefined && (obj.contractAddress = message.contractAddress);
        message.data !== undefined && (obj.data = message.data);
        return obj;
    },
    create(base) {
        return MsgPrivilegedExecuteContract.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgPrivilegedExecuteContract();
        message.sender = object.sender ?? "";
        message.funds = object.funds ?? "";
        message.contractAddress = object.contractAddress ?? "";
        message.data = object.data ?? "";
        return message;
    },
};
function createBaseMsgPrivilegedExecuteContractResponse() {
    return { fundsDiff: [] };
}
export const MsgPrivilegedExecuteContractResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.fundsDiff) {
            Coin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgPrivilegedExecuteContractResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fundsDiff.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { fundsDiff: Array.isArray(object?.fundsDiff) ? object.fundsDiff.map((e) => Coin.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.fundsDiff) {
            obj.fundsDiff = message.fundsDiff.map((e) => e ? Coin.toJSON(e) : undefined);
        }
        else {
            obj.fundsDiff = [];
        }
        return obj;
    },
    create(base) {
        return MsgPrivilegedExecuteContractResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgPrivilegedExecuteContractResponse();
        message.fundsDiff = object.fundsDiff?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgRewardsOptOut() {
    return { sender: "" };
}
export const MsgRewardsOptOut = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRewardsOptOut();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { sender: isSet(object.sender) ? String(object.sender) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        return obj;
    },
    create(base) {
        return MsgRewardsOptOut.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgRewardsOptOut();
        message.sender = object.sender ?? "";
        return message;
    },
};
function createBaseMsgRewardsOptOutResponse() {
    return {};
}
export const MsgRewardsOptOutResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgRewardsOptOutResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgRewardsOptOutResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgRewardsOptOutResponse();
        return message;
    },
};
function createBaseMsgReclaimLockedFunds() {
    return { sender: "", lockedAccountPubKey: new Uint8Array(), signature: new Uint8Array() };
}
export const MsgReclaimLockedFunds = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.lockedAccountPubKey.length !== 0) {
            writer.uint32(18).bytes(message.lockedAccountPubKey);
        }
        if (message.signature.length !== 0) {
            writer.uint32(26).bytes(message.signature);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgReclaimLockedFunds();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.lockedAccountPubKey = reader.bytes();
                    break;
                case 3:
                    message.signature = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            lockedAccountPubKey: isSet(object.lockedAccountPubKey)
                ? bytesFromBase64(object.lockedAccountPubKey)
                : new Uint8Array(),
            signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.lockedAccountPubKey !== undefined &&
            (obj.lockedAccountPubKey = base64FromBytes(message.lockedAccountPubKey !== undefined ? message.lockedAccountPubKey : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        return obj;
    },
    create(base) {
        return MsgReclaimLockedFunds.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgReclaimLockedFunds();
        message.sender = object.sender ?? "";
        message.lockedAccountPubKey = object.lockedAccountPubKey ?? new Uint8Array();
        message.signature = object.signature ?? new Uint8Array();
        return message;
    },
};
function createBaseMsgReclaimLockedFundsResponse() {
    return {};
}
export const MsgReclaimLockedFundsResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgReclaimLockedFundsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgReclaimLockedFundsResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgReclaimLockedFundsResponse();
        return message;
    },
};
function createBaseMsgSignData() {
    return { Signer: new Uint8Array(), Data: new Uint8Array() };
}
export const MsgSignData = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.Signer.length !== 0) {
            writer.uint32(10).bytes(message.Signer);
        }
        if (message.Data.length !== 0) {
            writer.uint32(18).bytes(message.Data);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSignData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.Signer = reader.bytes();
                    break;
                case 2:
                    message.Data = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            Signer: isSet(object.Signer) ? bytesFromBase64(object.Signer) : new Uint8Array(),
            Data: isSet(object.Data) ? bytesFromBase64(object.Data) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.Signer !== undefined &&
            (obj.Signer = base64FromBytes(message.Signer !== undefined ? message.Signer : new Uint8Array()));
        message.Data !== undefined &&
            (obj.Data = base64FromBytes(message.Data !== undefined ? message.Data : new Uint8Array()));
        return obj;
    },
    create(base) {
        return MsgSignData.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgSignData();
        message.Signer = object.Signer ?? new Uint8Array();
        message.Data = object.Data ?? new Uint8Array();
        return message;
    },
};
function createBaseMsgSignDoc() {
    return { signType: "", value: undefined };
}
export const MsgSignDoc = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.signType !== "") {
            writer.uint32(10).string(message.signType);
        }
        if (message.value !== undefined) {
            MsgSignData.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSignDoc();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.signType = reader.string();
                    break;
                case 2:
                    message.value = MsgSignData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            signType: isSet(object.signType) ? String(object.signType) : "",
            value: isSet(object.value) ? MsgSignData.fromJSON(object.value) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.signType !== undefined && (obj.signType = message.signType);
        message.value !== undefined && (obj.value = message.value ? MsgSignData.toJSON(message.value) : undefined);
        return obj;
    },
    create(base) {
        return MsgSignDoc.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgSignDoc();
        message.signType = object.signType ?? "";
        message.value = (object.value !== undefined && object.value !== null)
            ? MsgSignData.fromPartial(object.value)
            : undefined;
        return message;
    },
};
function createBaseMsgAdminUpdateBinaryOptionsMarket() {
    return {
        sender: "",
        marketId: "",
        settlementPrice: "",
        expirationTimestamp: "0",
        settlementTimestamp: "0",
        status: 0,
    };
}
export const MsgAdminUpdateBinaryOptionsMarket = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.marketId !== "") {
            writer.uint32(18).string(message.marketId);
        }
        if (message.settlementPrice !== "") {
            writer.uint32(26).string(message.settlementPrice);
        }
        if (message.expirationTimestamp !== "0") {
            writer.uint32(32).int64(message.expirationTimestamp);
        }
        if (message.settlementTimestamp !== "0") {
            writer.uint32(40).int64(message.settlementTimestamp);
        }
        if (message.status !== 0) {
            writer.uint32(48).int32(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgAdminUpdateBinaryOptionsMarket();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.marketId = reader.string();
                    break;
                case 3:
                    message.settlementPrice = reader.string();
                    break;
                case 4:
                    message.expirationTimestamp = longToString(reader.int64());
                    break;
                case 5:
                    message.settlementTimestamp = longToString(reader.int64());
                    break;
                case 6:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
            settlementPrice: isSet(object.settlementPrice) ? String(object.settlementPrice) : "",
            expirationTimestamp: isSet(object.expirationTimestamp) ? String(object.expirationTimestamp) : "0",
            settlementTimestamp: isSet(object.settlementTimestamp) ? String(object.settlementTimestamp) : "0",
            status: isSet(object.status) ? marketStatusFromJSON(object.status) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        message.settlementPrice !== undefined && (obj.settlementPrice = message.settlementPrice);
        message.expirationTimestamp !== undefined && (obj.expirationTimestamp = message.expirationTimestamp);
        message.settlementTimestamp !== undefined && (obj.settlementTimestamp = message.settlementTimestamp);
        message.status !== undefined && (obj.status = marketStatusToJSON(message.status));
        return obj;
    },
    create(base) {
        return MsgAdminUpdateBinaryOptionsMarket.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgAdminUpdateBinaryOptionsMarket();
        message.sender = object.sender ?? "";
        message.marketId = object.marketId ?? "";
        message.settlementPrice = object.settlementPrice ?? "";
        message.expirationTimestamp = object.expirationTimestamp ?? "0";
        message.settlementTimestamp = object.settlementTimestamp ?? "0";
        message.status = object.status ?? 0;
        return message;
    },
};
function createBaseMsgAdminUpdateBinaryOptionsMarketResponse() {
    return {};
}
export const MsgAdminUpdateBinaryOptionsMarketResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgAdminUpdateBinaryOptionsMarketResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgAdminUpdateBinaryOptionsMarketResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgAdminUpdateBinaryOptionsMarketResponse();
        return message;
    },
};
function createBaseMsgAuthorizeStakeGrants() {
    return { sender: "", grants: [] };
}
export const MsgAuthorizeStakeGrants = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.grants) {
            GrantAuthorization.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgAuthorizeStakeGrants();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.grants.push(GrantAuthorization.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            grants: Array.isArray(object?.grants) ? object.grants.map((e) => GrantAuthorization.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.grants) {
            obj.grants = message.grants.map((e) => e ? GrantAuthorization.toJSON(e) : undefined);
        }
        else {
            obj.grants = [];
        }
        return obj;
    },
    create(base) {
        return MsgAuthorizeStakeGrants.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgAuthorizeStakeGrants();
        message.sender = object.sender ?? "";
        message.grants = object.grants?.map((e) => GrantAuthorization.fromPartial(e)) || [];
        return message;
    },
};
function createBaseMsgAuthorizeStakeGrantsResponse() {
    return {};
}
export const MsgAuthorizeStakeGrantsResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgAuthorizeStakeGrantsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgAuthorizeStakeGrantsResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgAuthorizeStakeGrantsResponse();
        return message;
    },
};
function createBaseMsgActivateStakeGrant() {
    return { sender: "", granter: "" };
}
export const MsgActivateStakeGrant = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sender !== "") {
            writer.uint32(10).string(message.sender);
        }
        if (message.granter !== "") {
            writer.uint32(18).string(message.granter);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgActivateStakeGrant();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.granter = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sender: isSet(object.sender) ? String(object.sender) : "",
            granter: isSet(object.granter) ? String(object.granter) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.granter !== undefined && (obj.granter = message.granter);
        return obj;
    },
    create(base) {
        return MsgActivateStakeGrant.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMsgActivateStakeGrant();
        message.sender = object.sender ?? "";
        message.granter = object.granter ?? "";
        return message;
    },
};
function createBaseMsgActivateStakeGrantResponse() {
    return {};
}
export const MsgActivateStakeGrantResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgActivateStakeGrantResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return MsgActivateStakeGrantResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseMsgActivateStakeGrantResponse();
        return message;
    },
};
export class MsgClientImpl {
    rpc;
    constructor(rpc) {
        this.rpc = rpc;
        this.Deposit = this.Deposit.bind(this);
        this.Withdraw = this.Withdraw.bind(this);
        this.InstantSpotMarketLaunch = this.InstantSpotMarketLaunch.bind(this);
        this.InstantPerpetualMarketLaunch = this.InstantPerpetualMarketLaunch.bind(this);
        this.InstantExpiryFuturesMarketLaunch = this.InstantExpiryFuturesMarketLaunch.bind(this);
        this.CreateSpotLimitOrder = this.CreateSpotLimitOrder.bind(this);
        this.BatchCreateSpotLimitOrders = this.BatchCreateSpotLimitOrders.bind(this);
        this.CreateSpotMarketOrder = this.CreateSpotMarketOrder.bind(this);
        this.CancelSpotOrder = this.CancelSpotOrder.bind(this);
        this.BatchCancelSpotOrders = this.BatchCancelSpotOrders.bind(this);
        this.BatchUpdateOrders = this.BatchUpdateOrders.bind(this);
        this.PrivilegedExecuteContract = this.PrivilegedExecuteContract.bind(this);
        this.CreateDerivativeLimitOrder = this.CreateDerivativeLimitOrder.bind(this);
        this.BatchCreateDerivativeLimitOrders = this.BatchCreateDerivativeLimitOrders.bind(this);
        this.CreateDerivativeMarketOrder = this.CreateDerivativeMarketOrder.bind(this);
        this.CancelDerivativeOrder = this.CancelDerivativeOrder.bind(this);
        this.BatchCancelDerivativeOrders = this.BatchCancelDerivativeOrders.bind(this);
        this.InstantBinaryOptionsMarketLaunch = this.InstantBinaryOptionsMarketLaunch.bind(this);
        this.CreateBinaryOptionsLimitOrder = this.CreateBinaryOptionsLimitOrder.bind(this);
        this.CreateBinaryOptionsMarketOrder = this.CreateBinaryOptionsMarketOrder.bind(this);
        this.CancelBinaryOptionsOrder = this.CancelBinaryOptionsOrder.bind(this);
        this.BatchCancelBinaryOptionsOrders = this.BatchCancelBinaryOptionsOrders.bind(this);
        this.SubaccountTransfer = this.SubaccountTransfer.bind(this);
        this.ExternalTransfer = this.ExternalTransfer.bind(this);
        this.LiquidatePosition = this.LiquidatePosition.bind(this);
        this.EmergencySettleMarket = this.EmergencySettleMarket.bind(this);
        this.IncreasePositionMargin = this.IncreasePositionMargin.bind(this);
        this.DecreasePositionMargin = this.DecreasePositionMargin.bind(this);
        this.RewardsOptOut = this.RewardsOptOut.bind(this);
        this.AdminUpdateBinaryOptionsMarket = this.AdminUpdateBinaryOptionsMarket.bind(this);
        this.UpdateParams = this.UpdateParams.bind(this);
        this.UpdateSpotMarket = this.UpdateSpotMarket.bind(this);
        this.UpdateDerivativeMarket = this.UpdateDerivativeMarket.bind(this);
        this.AuthorizeStakeGrants = this.AuthorizeStakeGrants.bind(this);
        this.ActivateStakeGrant = this.ActivateStakeGrant.bind(this);
    }
    Deposit(request, metadata) {
        return this.rpc.unary(MsgDepositDesc, MsgDeposit.fromPartial(request), metadata);
    }
    Withdraw(request, metadata) {
        return this.rpc.unary(MsgWithdrawDesc, MsgWithdraw.fromPartial(request), metadata);
    }
    InstantSpotMarketLaunch(request, metadata) {
        return this.rpc.unary(MsgInstantSpotMarketLaunchDesc, MsgInstantSpotMarketLaunch.fromPartial(request), metadata);
    }
    InstantPerpetualMarketLaunch(request, metadata) {
        return this.rpc.unary(MsgInstantPerpetualMarketLaunchDesc, MsgInstantPerpetualMarketLaunch.fromPartial(request), metadata);
    }
    InstantExpiryFuturesMarketLaunch(request, metadata) {
        return this.rpc.unary(MsgInstantExpiryFuturesMarketLaunchDesc, MsgInstantExpiryFuturesMarketLaunch.fromPartial(request), metadata);
    }
    CreateSpotLimitOrder(request, metadata) {
        return this.rpc.unary(MsgCreateSpotLimitOrderDesc, MsgCreateSpotLimitOrder.fromPartial(request), metadata);
    }
    BatchCreateSpotLimitOrders(request, metadata) {
        return this.rpc.unary(MsgBatchCreateSpotLimitOrdersDesc, MsgBatchCreateSpotLimitOrders.fromPartial(request), metadata);
    }
    CreateSpotMarketOrder(request, metadata) {
        return this.rpc.unary(MsgCreateSpotMarketOrderDesc, MsgCreateSpotMarketOrder.fromPartial(request), metadata);
    }
    CancelSpotOrder(request, metadata) {
        return this.rpc.unary(MsgCancelSpotOrderDesc, MsgCancelSpotOrder.fromPartial(request), metadata);
    }
    BatchCancelSpotOrders(request, metadata) {
        return this.rpc.unary(MsgBatchCancelSpotOrdersDesc, MsgBatchCancelSpotOrders.fromPartial(request), metadata);
    }
    BatchUpdateOrders(request, metadata) {
        return this.rpc.unary(MsgBatchUpdateOrdersDesc, MsgBatchUpdateOrders.fromPartial(request), metadata);
    }
    PrivilegedExecuteContract(request, metadata) {
        return this.rpc.unary(MsgPrivilegedExecuteContractDesc, MsgPrivilegedExecuteContract.fromPartial(request), metadata);
    }
    CreateDerivativeLimitOrder(request, metadata) {
        return this.rpc.unary(MsgCreateDerivativeLimitOrderDesc, MsgCreateDerivativeLimitOrder.fromPartial(request), metadata);
    }
    BatchCreateDerivativeLimitOrders(request, metadata) {
        return this.rpc.unary(MsgBatchCreateDerivativeLimitOrdersDesc, MsgBatchCreateDerivativeLimitOrders.fromPartial(request), metadata);
    }
    CreateDerivativeMarketOrder(request, metadata) {
        return this.rpc.unary(MsgCreateDerivativeMarketOrderDesc, MsgCreateDerivativeMarketOrder.fromPartial(request), metadata);
    }
    CancelDerivativeOrder(request, metadata) {
        return this.rpc.unary(MsgCancelDerivativeOrderDesc, MsgCancelDerivativeOrder.fromPartial(request), metadata);
    }
    BatchCancelDerivativeOrders(request, metadata) {
        return this.rpc.unary(MsgBatchCancelDerivativeOrdersDesc, MsgBatchCancelDerivativeOrders.fromPartial(request), metadata);
    }
    InstantBinaryOptionsMarketLaunch(request, metadata) {
        return this.rpc.unary(MsgInstantBinaryOptionsMarketLaunchDesc, MsgInstantBinaryOptionsMarketLaunch.fromPartial(request), metadata);
    }
    CreateBinaryOptionsLimitOrder(request, metadata) {
        return this.rpc.unary(MsgCreateBinaryOptionsLimitOrderDesc, MsgCreateBinaryOptionsLimitOrder.fromPartial(request), metadata);
    }
    CreateBinaryOptionsMarketOrder(request, metadata) {
        return this.rpc.unary(MsgCreateBinaryOptionsMarketOrderDesc, MsgCreateBinaryOptionsMarketOrder.fromPartial(request), metadata);
    }
    CancelBinaryOptionsOrder(request, metadata) {
        return this.rpc.unary(MsgCancelBinaryOptionsOrderDesc, MsgCancelBinaryOptionsOrder.fromPartial(request), metadata);
    }
    BatchCancelBinaryOptionsOrders(request, metadata) {
        return this.rpc.unary(MsgBatchCancelBinaryOptionsOrdersDesc, MsgBatchCancelBinaryOptionsOrders.fromPartial(request), metadata);
    }
    SubaccountTransfer(request, metadata) {
        return this.rpc.unary(MsgSubaccountTransferDesc, MsgSubaccountTransfer.fromPartial(request), metadata);
    }
    ExternalTransfer(request, metadata) {
        return this.rpc.unary(MsgExternalTransferDesc, MsgExternalTransfer.fromPartial(request), metadata);
    }
    LiquidatePosition(request, metadata) {
        return this.rpc.unary(MsgLiquidatePositionDesc, MsgLiquidatePosition.fromPartial(request), metadata);
    }
    EmergencySettleMarket(request, metadata) {
        return this.rpc.unary(MsgEmergencySettleMarketDesc, MsgEmergencySettleMarket.fromPartial(request), metadata);
    }
    IncreasePositionMargin(request, metadata) {
        return this.rpc.unary(MsgIncreasePositionMarginDesc, MsgIncreasePositionMargin.fromPartial(request), metadata);
    }
    DecreasePositionMargin(request, metadata) {
        return this.rpc.unary(MsgDecreasePositionMarginDesc, MsgDecreasePositionMargin.fromPartial(request), metadata);
    }
    RewardsOptOut(request, metadata) {
        return this.rpc.unary(MsgRewardsOptOutDesc, MsgRewardsOptOut.fromPartial(request), metadata);
    }
    AdminUpdateBinaryOptionsMarket(request, metadata) {
        return this.rpc.unary(MsgAdminUpdateBinaryOptionsMarketDesc, MsgAdminUpdateBinaryOptionsMarket.fromPartial(request), metadata);
    }
    UpdateParams(request, metadata) {
        return this.rpc.unary(MsgUpdateParamsDesc, MsgUpdateParams.fromPartial(request), metadata);
    }
    UpdateSpotMarket(request, metadata) {
        return this.rpc.unary(MsgUpdateSpotMarketDesc, MsgUpdateSpotMarket.fromPartial(request), metadata);
    }
    UpdateDerivativeMarket(request, metadata) {
        return this.rpc.unary(MsgUpdateDerivativeMarketDesc, MsgUpdateDerivativeMarket.fromPartial(request), metadata);
    }
    AuthorizeStakeGrants(request, metadata) {
        return this.rpc.unary(MsgAuthorizeStakeGrantsDesc, MsgAuthorizeStakeGrants.fromPartial(request), metadata);
    }
    ActivateStakeGrant(request, metadata) {
        return this.rpc.unary(MsgActivateStakeGrantDesc, MsgActivateStakeGrant.fromPartial(request), metadata);
    }
}
export const MsgDesc = { serviceName: "injective.exchange.v1beta1.Msg" };
export const MsgDepositDesc = {
    methodName: "Deposit",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgDeposit.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgDepositResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgWithdrawDesc = {
    methodName: "Withdraw",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgWithdraw.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgWithdrawResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgInstantSpotMarketLaunchDesc = {
    methodName: "InstantSpotMarketLaunch",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgInstantSpotMarketLaunch.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgInstantSpotMarketLaunchResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgInstantPerpetualMarketLaunchDesc = {
    methodName: "InstantPerpetualMarketLaunch",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgInstantPerpetualMarketLaunch.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgInstantPerpetualMarketLaunchResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgInstantExpiryFuturesMarketLaunchDesc = {
    methodName: "InstantExpiryFuturesMarketLaunch",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgInstantExpiryFuturesMarketLaunch.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgInstantExpiryFuturesMarketLaunchResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateSpotLimitOrderDesc = {
    methodName: "CreateSpotLimitOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateSpotLimitOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateSpotLimitOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBatchCreateSpotLimitOrdersDesc = {
    methodName: "BatchCreateSpotLimitOrders",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBatchCreateSpotLimitOrders.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBatchCreateSpotLimitOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateSpotMarketOrderDesc = {
    methodName: "CreateSpotMarketOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateSpotMarketOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateSpotMarketOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCancelSpotOrderDesc = {
    methodName: "CancelSpotOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCancelSpotOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCancelSpotOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBatchCancelSpotOrdersDesc = {
    methodName: "BatchCancelSpotOrders",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBatchCancelSpotOrders.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBatchCancelSpotOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBatchUpdateOrdersDesc = {
    methodName: "BatchUpdateOrders",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBatchUpdateOrders.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBatchUpdateOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgPrivilegedExecuteContractDesc = {
    methodName: "PrivilegedExecuteContract",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgPrivilegedExecuteContract.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgPrivilegedExecuteContractResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateDerivativeLimitOrderDesc = {
    methodName: "CreateDerivativeLimitOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateDerivativeLimitOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateDerivativeLimitOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBatchCreateDerivativeLimitOrdersDesc = {
    methodName: "BatchCreateDerivativeLimitOrders",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBatchCreateDerivativeLimitOrders.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBatchCreateDerivativeLimitOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateDerivativeMarketOrderDesc = {
    methodName: "CreateDerivativeMarketOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateDerivativeMarketOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateDerivativeMarketOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCancelDerivativeOrderDesc = {
    methodName: "CancelDerivativeOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCancelDerivativeOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCancelDerivativeOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBatchCancelDerivativeOrdersDesc = {
    methodName: "BatchCancelDerivativeOrders",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBatchCancelDerivativeOrders.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBatchCancelDerivativeOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgInstantBinaryOptionsMarketLaunchDesc = {
    methodName: "InstantBinaryOptionsMarketLaunch",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgInstantBinaryOptionsMarketLaunch.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgInstantBinaryOptionsMarketLaunchResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateBinaryOptionsLimitOrderDesc = {
    methodName: "CreateBinaryOptionsLimitOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateBinaryOptionsLimitOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateBinaryOptionsLimitOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCreateBinaryOptionsMarketOrderDesc = {
    methodName: "CreateBinaryOptionsMarketOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCreateBinaryOptionsMarketOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCreateBinaryOptionsMarketOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgCancelBinaryOptionsOrderDesc = {
    methodName: "CancelBinaryOptionsOrder",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgCancelBinaryOptionsOrder.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgCancelBinaryOptionsOrderResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgBatchCancelBinaryOptionsOrdersDesc = {
    methodName: "BatchCancelBinaryOptionsOrders",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgBatchCancelBinaryOptionsOrders.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgBatchCancelBinaryOptionsOrdersResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgSubaccountTransferDesc = {
    methodName: "SubaccountTransfer",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgSubaccountTransfer.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgSubaccountTransferResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgExternalTransferDesc = {
    methodName: "ExternalTransfer",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgExternalTransfer.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgExternalTransferResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgLiquidatePositionDesc = {
    methodName: "LiquidatePosition",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgLiquidatePosition.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgLiquidatePositionResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgEmergencySettleMarketDesc = {
    methodName: "EmergencySettleMarket",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgEmergencySettleMarket.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgEmergencySettleMarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgIncreasePositionMarginDesc = {
    methodName: "IncreasePositionMargin",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgIncreasePositionMargin.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgIncreasePositionMarginResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgDecreasePositionMarginDesc = {
    methodName: "DecreasePositionMargin",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgDecreasePositionMargin.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgDecreasePositionMarginResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgRewardsOptOutDesc = {
    methodName: "RewardsOptOut",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgRewardsOptOut.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgRewardsOptOutResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgAdminUpdateBinaryOptionsMarketDesc = {
    methodName: "AdminUpdateBinaryOptionsMarket",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgAdminUpdateBinaryOptionsMarket.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgAdminUpdateBinaryOptionsMarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateParamsDesc = {
    methodName: "UpdateParams",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateParams.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateParamsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateSpotMarketDesc = {
    methodName: "UpdateSpotMarket",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateSpotMarket.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateSpotMarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgUpdateDerivativeMarketDesc = {
    methodName: "UpdateDerivativeMarket",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgUpdateDerivativeMarket.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgUpdateDerivativeMarketResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgAuthorizeStakeGrantsDesc = {
    methodName: "AuthorizeStakeGrants",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgAuthorizeStakeGrants.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgAuthorizeStakeGrantsResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export const MsgActivateStakeGrantDesc = {
    methodName: "ActivateStakeGrant",
    service: MsgDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return MsgActivateStakeGrant.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = MsgActivateStakeGrantResponse.decode(data);
            return {
                ...value,
                toObject() {
                    return value;
                },
            };
        },
    },
};
export class GrpcWebImpl {
    host;
    options;
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    code;
    metadata;
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
