/* eslint-disable */
import _m0 from "protobufjs/minimal.js";
import { Coin } from "../../../cosmos/base/v1beta1/coin.js";
export const protobufPackage = "injective.permissions.v1beta1";
function createBaseEventSetVoucher() {
    return { addr: "", voucher: undefined };
}
export const EventSetVoucher = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.addr !== "") {
            writer.uint32(10).string(message.addr);
        }
        if (message.voucher !== undefined) {
            Coin.encode(message.voucher, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventSetVoucher();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addr = reader.string();
                    break;
                case 2:
                    message.voucher = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            addr: isSet(object.addr) ? String(object.addr) : "",
            voucher: isSet(object.voucher) ? Coin.fromJSON(object.voucher) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.addr !== undefined && (obj.addr = message.addr);
        message.voucher !== undefined && (obj.voucher = message.voucher ? Coin.toJSON(message.voucher) : undefined);
        return obj;
    },
    create(base) {
        return EventSetVoucher.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventSetVoucher();
        message.addr = object.addr ?? "";
        message.voucher = (object.voucher !== undefined && object.voucher !== null)
            ? Coin.fromPartial(object.voucher)
            : undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
