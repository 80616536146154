/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Duration } from "../../../google/protobuf/duration.js";
import { Timestamp } from "../../../google/protobuf/timestamp.js";
import { ProofOps } from "../../crypto/v1/proof.js";
import { ConsensusParams } from "../../types/v1/params.js";
import { blockIDFlagFromJSON, blockIDFlagToJSON } from "../../types/v1/validator.js";
export const protobufPackage = "cometbft.abci.v1";
/**
 * Type of the transaction check request.
 *
 * This enumeration is incompatible with the CheckTxType definition in
 * cometbft.abci.v1beta1 and therefore shall not be used in encoding with the same
 * field number.
 */
export var CheckTxType;
(function (CheckTxType) {
    /** CHECK_TX_TYPE_UNKNOWN - Unknown */
    CheckTxType[CheckTxType["CHECK_TX_TYPE_UNKNOWN"] = 0] = "CHECK_TX_TYPE_UNKNOWN";
    /** CHECK_TX_TYPE_RECHECK - Recheck (2nd, 3rd, etc.) */
    CheckTxType[CheckTxType["CHECK_TX_TYPE_RECHECK"] = 1] = "CHECK_TX_TYPE_RECHECK";
    /** CHECK_TX_TYPE_CHECK - Check (1st time) */
    CheckTxType[CheckTxType["CHECK_TX_TYPE_CHECK"] = 2] = "CHECK_TX_TYPE_CHECK";
    CheckTxType[CheckTxType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CheckTxType || (CheckTxType = {}));
export function checkTxTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "CHECK_TX_TYPE_UNKNOWN":
            return CheckTxType.CHECK_TX_TYPE_UNKNOWN;
        case 1:
        case "CHECK_TX_TYPE_RECHECK":
            return CheckTxType.CHECK_TX_TYPE_RECHECK;
        case 2:
        case "CHECK_TX_TYPE_CHECK":
            return CheckTxType.CHECK_TX_TYPE_CHECK;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CheckTxType.UNRECOGNIZED;
    }
}
export function checkTxTypeToJSON(object) {
    switch (object) {
        case CheckTxType.CHECK_TX_TYPE_UNKNOWN:
            return "CHECK_TX_TYPE_UNKNOWN";
        case CheckTxType.CHECK_TX_TYPE_RECHECK:
            return "CHECK_TX_TYPE_RECHECK";
        case CheckTxType.CHECK_TX_TYPE_CHECK:
            return "CHECK_TX_TYPE_CHECK";
        case CheckTxType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** The result of offering a snapshot. */
export var OfferSnapshotResult;
(function (OfferSnapshotResult) {
    /** OFFER_SNAPSHOT_RESULT_UNKNOWN - Unknown result, abort all snapshot restoration */
    OfferSnapshotResult[OfferSnapshotResult["OFFER_SNAPSHOT_RESULT_UNKNOWN"] = 0] = "OFFER_SNAPSHOT_RESULT_UNKNOWN";
    /** OFFER_SNAPSHOT_RESULT_ACCEPT - Snapshot accepted, apply chunks */
    OfferSnapshotResult[OfferSnapshotResult["OFFER_SNAPSHOT_RESULT_ACCEPT"] = 1] = "OFFER_SNAPSHOT_RESULT_ACCEPT";
    /** OFFER_SNAPSHOT_RESULT_ABORT - Abort all snapshot restoration */
    OfferSnapshotResult[OfferSnapshotResult["OFFER_SNAPSHOT_RESULT_ABORT"] = 2] = "OFFER_SNAPSHOT_RESULT_ABORT";
    /** OFFER_SNAPSHOT_RESULT_REJECT - Reject this specific snapshot, try others */
    OfferSnapshotResult[OfferSnapshotResult["OFFER_SNAPSHOT_RESULT_REJECT"] = 3] = "OFFER_SNAPSHOT_RESULT_REJECT";
    /** OFFER_SNAPSHOT_RESULT_REJECT_FORMAT - Reject all snapshots of this format, try others */
    OfferSnapshotResult[OfferSnapshotResult["OFFER_SNAPSHOT_RESULT_REJECT_FORMAT"] = 4] = "OFFER_SNAPSHOT_RESULT_REJECT_FORMAT";
    /** OFFER_SNAPSHOT_RESULT_REJECT_SENDER - Reject all snapshots from the sender(s), try others */
    OfferSnapshotResult[OfferSnapshotResult["OFFER_SNAPSHOT_RESULT_REJECT_SENDER"] = 5] = "OFFER_SNAPSHOT_RESULT_REJECT_SENDER";
    OfferSnapshotResult[OfferSnapshotResult["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(OfferSnapshotResult || (OfferSnapshotResult = {}));
export function offerSnapshotResultFromJSON(object) {
    switch (object) {
        case 0:
        case "OFFER_SNAPSHOT_RESULT_UNKNOWN":
            return OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_UNKNOWN;
        case 1:
        case "OFFER_SNAPSHOT_RESULT_ACCEPT":
            return OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_ACCEPT;
        case 2:
        case "OFFER_SNAPSHOT_RESULT_ABORT":
            return OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_ABORT;
        case 3:
        case "OFFER_SNAPSHOT_RESULT_REJECT":
            return OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_REJECT;
        case 4:
        case "OFFER_SNAPSHOT_RESULT_REJECT_FORMAT":
            return OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_REJECT_FORMAT;
        case 5:
        case "OFFER_SNAPSHOT_RESULT_REJECT_SENDER":
            return OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_REJECT_SENDER;
        case -1:
        case "UNRECOGNIZED":
        default:
            return OfferSnapshotResult.UNRECOGNIZED;
    }
}
export function offerSnapshotResultToJSON(object) {
    switch (object) {
        case OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_UNKNOWN:
            return "OFFER_SNAPSHOT_RESULT_UNKNOWN";
        case OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_ACCEPT:
            return "OFFER_SNAPSHOT_RESULT_ACCEPT";
        case OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_ABORT:
            return "OFFER_SNAPSHOT_RESULT_ABORT";
        case OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_REJECT:
            return "OFFER_SNAPSHOT_RESULT_REJECT";
        case OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_REJECT_FORMAT:
            return "OFFER_SNAPSHOT_RESULT_REJECT_FORMAT";
        case OfferSnapshotResult.OFFER_SNAPSHOT_RESULT_REJECT_SENDER:
            return "OFFER_SNAPSHOT_RESULT_REJECT_SENDER";
        case OfferSnapshotResult.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** The result of applying a snapshot chunk. */
export var ApplySnapshotChunkResult;
(function (ApplySnapshotChunkResult) {
    /** APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN - Unknown result, abort all snapshot restoration */
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN"] = 0] = "APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN";
    /** APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT - Chunk successfully accepted */
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT"] = 1] = "APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT";
    /** APPLY_SNAPSHOT_CHUNK_RESULT_ABORT - Abort all snapshot restoration */
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["APPLY_SNAPSHOT_CHUNK_RESULT_ABORT"] = 2] = "APPLY_SNAPSHOT_CHUNK_RESULT_ABORT";
    /** APPLY_SNAPSHOT_CHUNK_RESULT_RETRY - Retry chunk (combine with refetch and reject) */
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["APPLY_SNAPSHOT_CHUNK_RESULT_RETRY"] = 3] = "APPLY_SNAPSHOT_CHUNK_RESULT_RETRY";
    /** APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT - Retry snapshot (combine with refetch and reject) */
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT"] = 4] = "APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT";
    /** APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT - Reject this snapshot, try others */
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT"] = 5] = "APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT";
    ApplySnapshotChunkResult[ApplySnapshotChunkResult["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ApplySnapshotChunkResult || (ApplySnapshotChunkResult = {}));
export function applySnapshotChunkResultFromJSON(object) {
    switch (object) {
        case 0:
        case "APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN":
            return ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN;
        case 1:
        case "APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT":
            return ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT;
        case 2:
        case "APPLY_SNAPSHOT_CHUNK_RESULT_ABORT":
            return ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_ABORT;
        case 3:
        case "APPLY_SNAPSHOT_CHUNK_RESULT_RETRY":
            return ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_RETRY;
        case 4:
        case "APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT":
            return ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT;
        case 5:
        case "APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT":
            return ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ApplySnapshotChunkResult.UNRECOGNIZED;
    }
}
export function applySnapshotChunkResultToJSON(object) {
    switch (object) {
        case ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN:
            return "APPLY_SNAPSHOT_CHUNK_RESULT_UNKNOWN";
        case ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT:
            return "APPLY_SNAPSHOT_CHUNK_RESULT_ACCEPT";
        case ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_ABORT:
            return "APPLY_SNAPSHOT_CHUNK_RESULT_ABORT";
        case ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_RETRY:
            return "APPLY_SNAPSHOT_CHUNK_RESULT_RETRY";
        case ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT:
            return "APPLY_SNAPSHOT_CHUNK_RESULT_RETRY_SNAPSHOT";
        case ApplySnapshotChunkResult.APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT:
            return "APPLY_SNAPSHOT_CHUNK_RESULT_REJECT_SNAPSHOT";
        case ApplySnapshotChunkResult.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** ProcessProposalStatus is the status of the proposal processing. */
export var ProcessProposalStatus;
(function (ProcessProposalStatus) {
    /** PROCESS_PROPOSAL_STATUS_UNKNOWN - Unknown */
    ProcessProposalStatus[ProcessProposalStatus["PROCESS_PROPOSAL_STATUS_UNKNOWN"] = 0] = "PROCESS_PROPOSAL_STATUS_UNKNOWN";
    /** PROCESS_PROPOSAL_STATUS_ACCEPT - Accepted */
    ProcessProposalStatus[ProcessProposalStatus["PROCESS_PROPOSAL_STATUS_ACCEPT"] = 1] = "PROCESS_PROPOSAL_STATUS_ACCEPT";
    /** PROCESS_PROPOSAL_STATUS_REJECT - Rejected */
    ProcessProposalStatus[ProcessProposalStatus["PROCESS_PROPOSAL_STATUS_REJECT"] = 2] = "PROCESS_PROPOSAL_STATUS_REJECT";
    ProcessProposalStatus[ProcessProposalStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ProcessProposalStatus || (ProcessProposalStatus = {}));
export function processProposalStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "PROCESS_PROPOSAL_STATUS_UNKNOWN":
            return ProcessProposalStatus.PROCESS_PROPOSAL_STATUS_UNKNOWN;
        case 1:
        case "PROCESS_PROPOSAL_STATUS_ACCEPT":
            return ProcessProposalStatus.PROCESS_PROPOSAL_STATUS_ACCEPT;
        case 2:
        case "PROCESS_PROPOSAL_STATUS_REJECT":
            return ProcessProposalStatus.PROCESS_PROPOSAL_STATUS_REJECT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ProcessProposalStatus.UNRECOGNIZED;
    }
}
export function processProposalStatusToJSON(object) {
    switch (object) {
        case ProcessProposalStatus.PROCESS_PROPOSAL_STATUS_UNKNOWN:
            return "PROCESS_PROPOSAL_STATUS_UNKNOWN";
        case ProcessProposalStatus.PROCESS_PROPOSAL_STATUS_ACCEPT:
            return "PROCESS_PROPOSAL_STATUS_ACCEPT";
        case ProcessProposalStatus.PROCESS_PROPOSAL_STATUS_REJECT:
            return "PROCESS_PROPOSAL_STATUS_REJECT";
        case ProcessProposalStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** VerifyVoteExtensionStatus is the status of the vote extension verification. */
export var VerifyVoteExtensionStatus;
(function (VerifyVoteExtensionStatus) {
    /** VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN - Unknown */
    VerifyVoteExtensionStatus[VerifyVoteExtensionStatus["VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN"] = 0] = "VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN";
    /** VERIFY_VOTE_EXTENSION_STATUS_ACCEPT - Accepted */
    VerifyVoteExtensionStatus[VerifyVoteExtensionStatus["VERIFY_VOTE_EXTENSION_STATUS_ACCEPT"] = 1] = "VERIFY_VOTE_EXTENSION_STATUS_ACCEPT";
    /**
     * VERIFY_VOTE_EXTENSION_STATUS_REJECT - Rejecting the vote extension will reject the entire precommit by the sender.
     * Incorrectly implementing this thus has liveness implications as it may affect
     * CometBFT's ability to receive 2/3+ valid votes to finalize the block.
     * Honest nodes should never be rejected.
     */
    VerifyVoteExtensionStatus[VerifyVoteExtensionStatus["VERIFY_VOTE_EXTENSION_STATUS_REJECT"] = 2] = "VERIFY_VOTE_EXTENSION_STATUS_REJECT";
    VerifyVoteExtensionStatus[VerifyVoteExtensionStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VerifyVoteExtensionStatus || (VerifyVoteExtensionStatus = {}));
export function verifyVoteExtensionStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN":
            return VerifyVoteExtensionStatus.VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN;
        case 1:
        case "VERIFY_VOTE_EXTENSION_STATUS_ACCEPT":
            return VerifyVoteExtensionStatus.VERIFY_VOTE_EXTENSION_STATUS_ACCEPT;
        case 2:
        case "VERIFY_VOTE_EXTENSION_STATUS_REJECT":
            return VerifyVoteExtensionStatus.VERIFY_VOTE_EXTENSION_STATUS_REJECT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VerifyVoteExtensionStatus.UNRECOGNIZED;
    }
}
export function verifyVoteExtensionStatusToJSON(object) {
    switch (object) {
        case VerifyVoteExtensionStatus.VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN:
            return "VERIFY_VOTE_EXTENSION_STATUS_UNKNOWN";
        case VerifyVoteExtensionStatus.VERIFY_VOTE_EXTENSION_STATUS_ACCEPT:
            return "VERIFY_VOTE_EXTENSION_STATUS_ACCEPT";
        case VerifyVoteExtensionStatus.VERIFY_VOTE_EXTENSION_STATUS_REJECT:
            return "VERIFY_VOTE_EXTENSION_STATUS_REJECT";
        case VerifyVoteExtensionStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** The type of misbehavior committed by a validator. */
export var MisbehaviorType;
(function (MisbehaviorType) {
    /** MISBEHAVIOR_TYPE_UNKNOWN - Unknown */
    MisbehaviorType[MisbehaviorType["MISBEHAVIOR_TYPE_UNKNOWN"] = 0] = "MISBEHAVIOR_TYPE_UNKNOWN";
    /** MISBEHAVIOR_TYPE_DUPLICATE_VOTE - Duplicate vote */
    MisbehaviorType[MisbehaviorType["MISBEHAVIOR_TYPE_DUPLICATE_VOTE"] = 1] = "MISBEHAVIOR_TYPE_DUPLICATE_VOTE";
    /** MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK - Light client attack */
    MisbehaviorType[MisbehaviorType["MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK"] = 2] = "MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK";
    MisbehaviorType[MisbehaviorType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(MisbehaviorType || (MisbehaviorType = {}));
export function misbehaviorTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "MISBEHAVIOR_TYPE_UNKNOWN":
            return MisbehaviorType.MISBEHAVIOR_TYPE_UNKNOWN;
        case 1:
        case "MISBEHAVIOR_TYPE_DUPLICATE_VOTE":
            return MisbehaviorType.MISBEHAVIOR_TYPE_DUPLICATE_VOTE;
        case 2:
        case "MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK":
            return MisbehaviorType.MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK;
        case -1:
        case "UNRECOGNIZED":
        default:
            return MisbehaviorType.UNRECOGNIZED;
    }
}
export function misbehaviorTypeToJSON(object) {
    switch (object) {
        case MisbehaviorType.MISBEHAVIOR_TYPE_UNKNOWN:
            return "MISBEHAVIOR_TYPE_UNKNOWN";
        case MisbehaviorType.MISBEHAVIOR_TYPE_DUPLICATE_VOTE:
            return "MISBEHAVIOR_TYPE_DUPLICATE_VOTE";
        case MisbehaviorType.MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK:
            return "MISBEHAVIOR_TYPE_LIGHT_CLIENT_ATTACK";
        case MisbehaviorType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseRequest() {
    return {
        echo: undefined,
        flush: undefined,
        info: undefined,
        initChain: undefined,
        query: undefined,
        checkTx: undefined,
        commit: undefined,
        listSnapshots: undefined,
        offerSnapshot: undefined,
        loadSnapshotChunk: undefined,
        applySnapshotChunk: undefined,
        prepareProposal: undefined,
        processProposal: undefined,
        extendVote: undefined,
        verifyVoteExtension: undefined,
        finalizeBlock: undefined,
    };
}
export const Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.echo !== undefined) {
            EchoRequest.encode(message.echo, writer.uint32(10).fork()).ldelim();
        }
        if (message.flush !== undefined) {
            FlushRequest.encode(message.flush, writer.uint32(18).fork()).ldelim();
        }
        if (message.info !== undefined) {
            InfoRequest.encode(message.info, writer.uint32(26).fork()).ldelim();
        }
        if (message.initChain !== undefined) {
            InitChainRequest.encode(message.initChain, writer.uint32(42).fork()).ldelim();
        }
        if (message.query !== undefined) {
            QueryRequest.encode(message.query, writer.uint32(50).fork()).ldelim();
        }
        if (message.checkTx !== undefined) {
            CheckTxRequest.encode(message.checkTx, writer.uint32(66).fork()).ldelim();
        }
        if (message.commit !== undefined) {
            CommitRequest.encode(message.commit, writer.uint32(90).fork()).ldelim();
        }
        if (message.listSnapshots !== undefined) {
            ListSnapshotsRequest.encode(message.listSnapshots, writer.uint32(98).fork()).ldelim();
        }
        if (message.offerSnapshot !== undefined) {
            OfferSnapshotRequest.encode(message.offerSnapshot, writer.uint32(106).fork()).ldelim();
        }
        if (message.loadSnapshotChunk !== undefined) {
            LoadSnapshotChunkRequest.encode(message.loadSnapshotChunk, writer.uint32(114).fork()).ldelim();
        }
        if (message.applySnapshotChunk !== undefined) {
            ApplySnapshotChunkRequest.encode(message.applySnapshotChunk, writer.uint32(122).fork()).ldelim();
        }
        if (message.prepareProposal !== undefined) {
            PrepareProposalRequest.encode(message.prepareProposal, writer.uint32(130).fork()).ldelim();
        }
        if (message.processProposal !== undefined) {
            ProcessProposalRequest.encode(message.processProposal, writer.uint32(138).fork()).ldelim();
        }
        if (message.extendVote !== undefined) {
            ExtendVoteRequest.encode(message.extendVote, writer.uint32(146).fork()).ldelim();
        }
        if (message.verifyVoteExtension !== undefined) {
            VerifyVoteExtensionRequest.encode(message.verifyVoteExtension, writer.uint32(154).fork()).ldelim();
        }
        if (message.finalizeBlock !== undefined) {
            FinalizeBlockRequest.encode(message.finalizeBlock, writer.uint32(162).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.echo = EchoRequest.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.flush = FlushRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.info = InfoRequest.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.initChain = InitChainRequest.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.query = QueryRequest.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.checkTx = CheckTxRequest.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.commit = CommitRequest.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.listSnapshots = ListSnapshotsRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.offerSnapshot = OfferSnapshotRequest.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.loadSnapshotChunk = LoadSnapshotChunkRequest.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.applySnapshotChunk = ApplySnapshotChunkRequest.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.prepareProposal = PrepareProposalRequest.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.processProposal = ProcessProposalRequest.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.extendVote = ExtendVoteRequest.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.verifyVoteExtension = VerifyVoteExtensionRequest.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.finalizeBlock = FinalizeBlockRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            echo: isSet(object.echo) ? EchoRequest.fromJSON(object.echo) : undefined,
            flush: isSet(object.flush) ? FlushRequest.fromJSON(object.flush) : undefined,
            info: isSet(object.info) ? InfoRequest.fromJSON(object.info) : undefined,
            initChain: isSet(object.initChain) ? InitChainRequest.fromJSON(object.initChain) : undefined,
            query: isSet(object.query) ? QueryRequest.fromJSON(object.query) : undefined,
            checkTx: isSet(object.checkTx) ? CheckTxRequest.fromJSON(object.checkTx) : undefined,
            commit: isSet(object.commit) ? CommitRequest.fromJSON(object.commit) : undefined,
            listSnapshots: isSet(object.listSnapshots) ? ListSnapshotsRequest.fromJSON(object.listSnapshots) : undefined,
            offerSnapshot: isSet(object.offerSnapshot) ? OfferSnapshotRequest.fromJSON(object.offerSnapshot) : undefined,
            loadSnapshotChunk: isSet(object.loadSnapshotChunk)
                ? LoadSnapshotChunkRequest.fromJSON(object.loadSnapshotChunk)
                : undefined,
            applySnapshotChunk: isSet(object.applySnapshotChunk)
                ? ApplySnapshotChunkRequest.fromJSON(object.applySnapshotChunk)
                : undefined,
            prepareProposal: isSet(object.prepareProposal)
                ? PrepareProposalRequest.fromJSON(object.prepareProposal)
                : undefined,
            processProposal: isSet(object.processProposal)
                ? ProcessProposalRequest.fromJSON(object.processProposal)
                : undefined,
            extendVote: isSet(object.extendVote) ? ExtendVoteRequest.fromJSON(object.extendVote) : undefined,
            verifyVoteExtension: isSet(object.verifyVoteExtension)
                ? VerifyVoteExtensionRequest.fromJSON(object.verifyVoteExtension)
                : undefined,
            finalizeBlock: isSet(object.finalizeBlock) ? FinalizeBlockRequest.fromJSON(object.finalizeBlock) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.echo !== undefined && (obj.echo = message.echo ? EchoRequest.toJSON(message.echo) : undefined);
        message.flush !== undefined && (obj.flush = message.flush ? FlushRequest.toJSON(message.flush) : undefined);
        message.info !== undefined && (obj.info = message.info ? InfoRequest.toJSON(message.info) : undefined);
        message.initChain !== undefined &&
            (obj.initChain = message.initChain ? InitChainRequest.toJSON(message.initChain) : undefined);
        message.query !== undefined && (obj.query = message.query ? QueryRequest.toJSON(message.query) : undefined);
        message.checkTx !== undefined &&
            (obj.checkTx = message.checkTx ? CheckTxRequest.toJSON(message.checkTx) : undefined);
        message.commit !== undefined && (obj.commit = message.commit ? CommitRequest.toJSON(message.commit) : undefined);
        message.listSnapshots !== undefined &&
            (obj.listSnapshots = message.listSnapshots ? ListSnapshotsRequest.toJSON(message.listSnapshots) : undefined);
        message.offerSnapshot !== undefined &&
            (obj.offerSnapshot = message.offerSnapshot ? OfferSnapshotRequest.toJSON(message.offerSnapshot) : undefined);
        message.loadSnapshotChunk !== undefined && (obj.loadSnapshotChunk = message.loadSnapshotChunk
            ? LoadSnapshotChunkRequest.toJSON(message.loadSnapshotChunk)
            : undefined);
        message.applySnapshotChunk !== undefined && (obj.applySnapshotChunk = message.applySnapshotChunk
            ? ApplySnapshotChunkRequest.toJSON(message.applySnapshotChunk)
            : undefined);
        message.prepareProposal !== undefined && (obj.prepareProposal = message.prepareProposal
            ? PrepareProposalRequest.toJSON(message.prepareProposal)
            : undefined);
        message.processProposal !== undefined && (obj.processProposal = message.processProposal
            ? ProcessProposalRequest.toJSON(message.processProposal)
            : undefined);
        message.extendVote !== undefined &&
            (obj.extendVote = message.extendVote ? ExtendVoteRequest.toJSON(message.extendVote) : undefined);
        message.verifyVoteExtension !== undefined && (obj.verifyVoteExtension = message.verifyVoteExtension
            ? VerifyVoteExtensionRequest.toJSON(message.verifyVoteExtension)
            : undefined);
        message.finalizeBlock !== undefined &&
            (obj.finalizeBlock = message.finalizeBlock ? FinalizeBlockRequest.toJSON(message.finalizeBlock) : undefined);
        return obj;
    },
    create(base) {
        return Request.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRequest();
        message.echo = (object.echo !== undefined && object.echo !== null)
            ? EchoRequest.fromPartial(object.echo)
            : undefined;
        message.flush = (object.flush !== undefined && object.flush !== null)
            ? FlushRequest.fromPartial(object.flush)
            : undefined;
        message.info = (object.info !== undefined && object.info !== null)
            ? InfoRequest.fromPartial(object.info)
            : undefined;
        message.initChain = (object.initChain !== undefined && object.initChain !== null)
            ? InitChainRequest.fromPartial(object.initChain)
            : undefined;
        message.query = (object.query !== undefined && object.query !== null)
            ? QueryRequest.fromPartial(object.query)
            : undefined;
        message.checkTx = (object.checkTx !== undefined && object.checkTx !== null)
            ? CheckTxRequest.fromPartial(object.checkTx)
            : undefined;
        message.commit = (object.commit !== undefined && object.commit !== null)
            ? CommitRequest.fromPartial(object.commit)
            : undefined;
        message.listSnapshots = (object.listSnapshots !== undefined && object.listSnapshots !== null)
            ? ListSnapshotsRequest.fromPartial(object.listSnapshots)
            : undefined;
        message.offerSnapshot = (object.offerSnapshot !== undefined && object.offerSnapshot !== null)
            ? OfferSnapshotRequest.fromPartial(object.offerSnapshot)
            : undefined;
        message.loadSnapshotChunk = (object.loadSnapshotChunk !== undefined && object.loadSnapshotChunk !== null)
            ? LoadSnapshotChunkRequest.fromPartial(object.loadSnapshotChunk)
            : undefined;
        message.applySnapshotChunk = (object.applySnapshotChunk !== undefined && object.applySnapshotChunk !== null)
            ? ApplySnapshotChunkRequest.fromPartial(object.applySnapshotChunk)
            : undefined;
        message.prepareProposal = (object.prepareProposal !== undefined && object.prepareProposal !== null)
            ? PrepareProposalRequest.fromPartial(object.prepareProposal)
            : undefined;
        message.processProposal = (object.processProposal !== undefined && object.processProposal !== null)
            ? ProcessProposalRequest.fromPartial(object.processProposal)
            : undefined;
        message.extendVote = (object.extendVote !== undefined && object.extendVote !== null)
            ? ExtendVoteRequest.fromPartial(object.extendVote)
            : undefined;
        message.verifyVoteExtension = (object.verifyVoteExtension !== undefined && object.verifyVoteExtension !== null)
            ? VerifyVoteExtensionRequest.fromPartial(object.verifyVoteExtension)
            : undefined;
        message.finalizeBlock = (object.finalizeBlock !== undefined && object.finalizeBlock !== null)
            ? FinalizeBlockRequest.fromPartial(object.finalizeBlock)
            : undefined;
        return message;
    },
};
function createBaseEchoRequest() {
    return { message: "" };
}
export const EchoRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.message !== "") {
            writer.uint32(10).string(message.message);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEchoRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { message: isSet(object.message) ? String(object.message) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.message !== undefined && (obj.message = message.message);
        return obj;
    },
    create(base) {
        return EchoRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEchoRequest();
        message.message = object.message ?? "";
        return message;
    },
};
function createBaseFlushRequest() {
    return {};
}
export const FlushRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlushRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return FlushRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseFlushRequest();
        return message;
    },
};
function createBaseInfoRequest() {
    return { version: "", blockVersion: "0", p2pVersion: "0", abciVersion: "" };
}
export const InfoRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.version !== "") {
            writer.uint32(10).string(message.version);
        }
        if (message.blockVersion !== "0") {
            writer.uint32(16).uint64(message.blockVersion);
        }
        if (message.p2pVersion !== "0") {
            writer.uint32(24).uint64(message.p2pVersion);
        }
        if (message.abciVersion !== "") {
            writer.uint32(34).string(message.abciVersion);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInfoRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.version = reader.string();
                    break;
                case 2:
                    message.blockVersion = longToString(reader.uint64());
                    break;
                case 3:
                    message.p2pVersion = longToString(reader.uint64());
                    break;
                case 4:
                    message.abciVersion = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            version: isSet(object.version) ? String(object.version) : "",
            blockVersion: isSet(object.blockVersion) ? String(object.blockVersion) : "0",
            p2pVersion: isSet(object.p2pVersion) ? String(object.p2pVersion) : "0",
            abciVersion: isSet(object.abciVersion) ? String(object.abciVersion) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.version !== undefined && (obj.version = message.version);
        message.blockVersion !== undefined && (obj.blockVersion = message.blockVersion);
        message.p2pVersion !== undefined && (obj.p2pVersion = message.p2pVersion);
        message.abciVersion !== undefined && (obj.abciVersion = message.abciVersion);
        return obj;
    },
    create(base) {
        return InfoRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInfoRequest();
        message.version = object.version ?? "";
        message.blockVersion = object.blockVersion ?? "0";
        message.p2pVersion = object.p2pVersion ?? "0";
        message.abciVersion = object.abciVersion ?? "";
        return message;
    },
};
function createBaseInitChainRequest() {
    return {
        time: undefined,
        chainId: "",
        consensusParams: undefined,
        validators: [],
        appStateBytes: new Uint8Array(),
        initialHeight: "0",
    };
}
export const InitChainRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.time !== undefined) {
            Timestamp.encode(toTimestamp(message.time), writer.uint32(10).fork()).ldelim();
        }
        if (message.chainId !== "") {
            writer.uint32(18).string(message.chainId);
        }
        if (message.consensusParams !== undefined) {
            ConsensusParams.encode(message.consensusParams, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.validators) {
            ValidatorUpdate.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.appStateBytes.length !== 0) {
            writer.uint32(42).bytes(message.appStateBytes);
        }
        if (message.initialHeight !== "0") {
            writer.uint32(48).int64(message.initialHeight);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInitChainRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.chainId = reader.string();
                    break;
                case 3:
                    message.consensusParams = ConsensusParams.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.validators.push(ValidatorUpdate.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.appStateBytes = reader.bytes();
                    break;
                case 6:
                    message.initialHeight = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
            chainId: isSet(object.chainId) ? String(object.chainId) : "",
            consensusParams: isSet(object.consensusParams) ? ConsensusParams.fromJSON(object.consensusParams) : undefined,
            validators: Array.isArray(object?.validators)
                ? object.validators.map((e) => ValidatorUpdate.fromJSON(e))
                : [],
            appStateBytes: isSet(object.appStateBytes) ? bytesFromBase64(object.appStateBytes) : new Uint8Array(),
            initialHeight: isSet(object.initialHeight) ? String(object.initialHeight) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.time !== undefined && (obj.time = message.time.toISOString());
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.consensusParams !== undefined &&
            (obj.consensusParams = message.consensusParams ? ConsensusParams.toJSON(message.consensusParams) : undefined);
        if (message.validators) {
            obj.validators = message.validators.map((e) => e ? ValidatorUpdate.toJSON(e) : undefined);
        }
        else {
            obj.validators = [];
        }
        message.appStateBytes !== undefined &&
            (obj.appStateBytes = base64FromBytes(message.appStateBytes !== undefined ? message.appStateBytes : new Uint8Array()));
        message.initialHeight !== undefined && (obj.initialHeight = message.initialHeight);
        return obj;
    },
    create(base) {
        return InitChainRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInitChainRequest();
        message.time = object.time ?? undefined;
        message.chainId = object.chainId ?? "";
        message.consensusParams = (object.consensusParams !== undefined && object.consensusParams !== null)
            ? ConsensusParams.fromPartial(object.consensusParams)
            : undefined;
        message.validators = object.validators?.map((e) => ValidatorUpdate.fromPartial(e)) || [];
        message.appStateBytes = object.appStateBytes ?? new Uint8Array();
        message.initialHeight = object.initialHeight ?? "0";
        return message;
    },
};
function createBaseQueryRequest() {
    return { data: new Uint8Array(), path: "", height: "0", prove: false };
}
export const QueryRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.data.length !== 0) {
            writer.uint32(10).bytes(message.data);
        }
        if (message.path !== "") {
            writer.uint32(18).string(message.path);
        }
        if (message.height !== "0") {
            writer.uint32(24).int64(message.height);
        }
        if (message.prove === true) {
            writer.uint32(32).bool(message.prove);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data = reader.bytes();
                    break;
                case 2:
                    message.path = reader.string();
                    break;
                case 3:
                    message.height = longToString(reader.int64());
                    break;
                case 4:
                    message.prove = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            path: isSet(object.path) ? String(object.path) : "",
            height: isSet(object.height) ? String(object.height) : "0",
            prove: isSet(object.prove) ? Boolean(object.prove) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.path !== undefined && (obj.path = message.path);
        message.height !== undefined && (obj.height = message.height);
        message.prove !== undefined && (obj.prove = message.prove);
        return obj;
    },
    create(base) {
        return QueryRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryRequest();
        message.data = object.data ?? new Uint8Array();
        message.path = object.path ?? "";
        message.height = object.height ?? "0";
        message.prove = object.prove ?? false;
        return message;
    },
};
function createBaseCheckTxRequest() {
    return { tx: new Uint8Array(), type: 0 };
}
export const CheckTxRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tx.length !== 0) {
            writer.uint32(10).bytes(message.tx);
        }
        if (message.type !== 0) {
            writer.uint32(24).int32(message.type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCheckTxRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tx = reader.bytes();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tx: isSet(object.tx) ? bytesFromBase64(object.tx) : new Uint8Array(),
            type: isSet(object.type) ? checkTxTypeFromJSON(object.type) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.tx !== undefined && (obj.tx = base64FromBytes(message.tx !== undefined ? message.tx : new Uint8Array()));
        message.type !== undefined && (obj.type = checkTxTypeToJSON(message.type));
        return obj;
    },
    create(base) {
        return CheckTxRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCheckTxRequest();
        message.tx = object.tx ?? new Uint8Array();
        message.type = object.type ?? 0;
        return message;
    },
};
function createBaseCommitRequest() {
    return {};
}
export const CommitRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCommitRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return CommitRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseCommitRequest();
        return message;
    },
};
function createBaseListSnapshotsRequest() {
    return {};
}
export const ListSnapshotsRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListSnapshotsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return ListSnapshotsRequest.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseListSnapshotsRequest();
        return message;
    },
};
function createBaseOfferSnapshotRequest() {
    return { snapshot: undefined, appHash: new Uint8Array() };
}
export const OfferSnapshotRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.snapshot !== undefined) {
            Snapshot.encode(message.snapshot, writer.uint32(10).fork()).ldelim();
        }
        if (message.appHash.length !== 0) {
            writer.uint32(18).bytes(message.appHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOfferSnapshotRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snapshot = Snapshot.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.appHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            snapshot: isSet(object.snapshot) ? Snapshot.fromJSON(object.snapshot) : undefined,
            appHash: isSet(object.appHash) ? bytesFromBase64(object.appHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.snapshot !== undefined && (obj.snapshot = message.snapshot ? Snapshot.toJSON(message.snapshot) : undefined);
        message.appHash !== undefined &&
            (obj.appHash = base64FromBytes(message.appHash !== undefined ? message.appHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return OfferSnapshotRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOfferSnapshotRequest();
        message.snapshot = (object.snapshot !== undefined && object.snapshot !== null)
            ? Snapshot.fromPartial(object.snapshot)
            : undefined;
        message.appHash = object.appHash ?? new Uint8Array();
        return message;
    },
};
function createBaseLoadSnapshotChunkRequest() {
    return { height: "0", format: 0, chunk: 0 };
}
export const LoadSnapshotChunkRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).uint64(message.height);
        }
        if (message.format !== 0) {
            writer.uint32(16).uint32(message.format);
        }
        if (message.chunk !== 0) {
            writer.uint32(24).uint32(message.chunk);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLoadSnapshotChunkRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.uint64());
                    break;
                case 2:
                    message.format = reader.uint32();
                    break;
                case 3:
                    message.chunk = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            format: isSet(object.format) ? Number(object.format) : 0,
            chunk: isSet(object.chunk) ? Number(object.chunk) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.format !== undefined && (obj.format = Math.round(message.format));
        message.chunk !== undefined && (obj.chunk = Math.round(message.chunk));
        return obj;
    },
    create(base) {
        return LoadSnapshotChunkRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLoadSnapshotChunkRequest();
        message.height = object.height ?? "0";
        message.format = object.format ?? 0;
        message.chunk = object.chunk ?? 0;
        return message;
    },
};
function createBaseApplySnapshotChunkRequest() {
    return { index: 0, chunk: new Uint8Array(), sender: "" };
}
export const ApplySnapshotChunkRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.index !== 0) {
            writer.uint32(8).uint32(message.index);
        }
        if (message.chunk.length !== 0) {
            writer.uint32(18).bytes(message.chunk);
        }
        if (message.sender !== "") {
            writer.uint32(26).string(message.sender);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseApplySnapshotChunkRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.index = reader.uint32();
                    break;
                case 2:
                    message.chunk = reader.bytes();
                    break;
                case 3:
                    message.sender = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            index: isSet(object.index) ? Number(object.index) : 0,
            chunk: isSet(object.chunk) ? bytesFromBase64(object.chunk) : new Uint8Array(),
            sender: isSet(object.sender) ? String(object.sender) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.index !== undefined && (obj.index = Math.round(message.index));
        message.chunk !== undefined &&
            (obj.chunk = base64FromBytes(message.chunk !== undefined ? message.chunk : new Uint8Array()));
        message.sender !== undefined && (obj.sender = message.sender);
        return obj;
    },
    create(base) {
        return ApplySnapshotChunkRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseApplySnapshotChunkRequest();
        message.index = object.index ?? 0;
        message.chunk = object.chunk ?? new Uint8Array();
        message.sender = object.sender ?? "";
        return message;
    },
};
function createBasePrepareProposalRequest() {
    return {
        maxTxBytes: "0",
        txs: [],
        localLastCommit: undefined,
        misbehavior: [],
        height: "0",
        time: undefined,
        nextValidatorsHash: new Uint8Array(),
        proposerAddress: new Uint8Array(),
    };
}
export const PrepareProposalRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.maxTxBytes !== "0") {
            writer.uint32(8).int64(message.maxTxBytes);
        }
        for (const v of message.txs) {
            writer.uint32(18).bytes(v);
        }
        if (message.localLastCommit !== undefined) {
            ExtendedCommitInfo.encode(message.localLastCommit, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.misbehavior) {
            Misbehavior.encode(v, writer.uint32(34).fork()).ldelim();
        }
        if (message.height !== "0") {
            writer.uint32(40).int64(message.height);
        }
        if (message.time !== undefined) {
            Timestamp.encode(toTimestamp(message.time), writer.uint32(50).fork()).ldelim();
        }
        if (message.nextValidatorsHash.length !== 0) {
            writer.uint32(58).bytes(message.nextValidatorsHash);
        }
        if (message.proposerAddress.length !== 0) {
            writer.uint32(66).bytes(message.proposerAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrepareProposalRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.maxTxBytes = longToString(reader.int64());
                    break;
                case 2:
                    message.txs.push(reader.bytes());
                    break;
                case 3:
                    message.localLastCommit = ExtendedCommitInfo.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.misbehavior.push(Misbehavior.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.height = longToString(reader.int64());
                    break;
                case 6:
                    message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.nextValidatorsHash = reader.bytes();
                    break;
                case 8:
                    message.proposerAddress = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            maxTxBytes: isSet(object.maxTxBytes) ? String(object.maxTxBytes) : "0",
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => bytesFromBase64(e)) : [],
            localLastCommit: isSet(object.localLastCommit) ? ExtendedCommitInfo.fromJSON(object.localLastCommit) : undefined,
            misbehavior: Array.isArray(object?.misbehavior)
                ? object.misbehavior.map((e) => Misbehavior.fromJSON(e))
                : [],
            height: isSet(object.height) ? String(object.height) : "0",
            time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
            nextValidatorsHash: isSet(object.nextValidatorsHash)
                ? bytesFromBase64(object.nextValidatorsHash)
                : new Uint8Array(),
            proposerAddress: isSet(object.proposerAddress) ? bytesFromBase64(object.proposerAddress) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.maxTxBytes !== undefined && (obj.maxTxBytes = message.maxTxBytes);
        if (message.txs) {
            obj.txs = message.txs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.txs = [];
        }
        message.localLastCommit !== undefined &&
            (obj.localLastCommit = message.localLastCommit ? ExtendedCommitInfo.toJSON(message.localLastCommit) : undefined);
        if (message.misbehavior) {
            obj.misbehavior = message.misbehavior.map((e) => e ? Misbehavior.toJSON(e) : undefined);
        }
        else {
            obj.misbehavior = [];
        }
        message.height !== undefined && (obj.height = message.height);
        message.time !== undefined && (obj.time = message.time.toISOString());
        message.nextValidatorsHash !== undefined &&
            (obj.nextValidatorsHash = base64FromBytes(message.nextValidatorsHash !== undefined ? message.nextValidatorsHash : new Uint8Array()));
        message.proposerAddress !== undefined &&
            (obj.proposerAddress = base64FromBytes(message.proposerAddress !== undefined ? message.proposerAddress : new Uint8Array()));
        return obj;
    },
    create(base) {
        return PrepareProposalRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePrepareProposalRequest();
        message.maxTxBytes = object.maxTxBytes ?? "0";
        message.txs = object.txs?.map((e) => e) || [];
        message.localLastCommit = (object.localLastCommit !== undefined && object.localLastCommit !== null)
            ? ExtendedCommitInfo.fromPartial(object.localLastCommit)
            : undefined;
        message.misbehavior = object.misbehavior?.map((e) => Misbehavior.fromPartial(e)) || [];
        message.height = object.height ?? "0";
        message.time = object.time ?? undefined;
        message.nextValidatorsHash = object.nextValidatorsHash ?? new Uint8Array();
        message.proposerAddress = object.proposerAddress ?? new Uint8Array();
        return message;
    },
};
function createBaseProcessProposalRequest() {
    return {
        txs: [],
        proposedLastCommit: undefined,
        misbehavior: [],
        hash: new Uint8Array(),
        height: "0",
        time: undefined,
        nextValidatorsHash: new Uint8Array(),
        proposerAddress: new Uint8Array(),
    };
}
export const ProcessProposalRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.txs) {
            writer.uint32(10).bytes(v);
        }
        if (message.proposedLastCommit !== undefined) {
            CommitInfo.encode(message.proposedLastCommit, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.misbehavior) {
            Misbehavior.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.hash.length !== 0) {
            writer.uint32(34).bytes(message.hash);
        }
        if (message.height !== "0") {
            writer.uint32(40).int64(message.height);
        }
        if (message.time !== undefined) {
            Timestamp.encode(toTimestamp(message.time), writer.uint32(50).fork()).ldelim();
        }
        if (message.nextValidatorsHash.length !== 0) {
            writer.uint32(58).bytes(message.nextValidatorsHash);
        }
        if (message.proposerAddress.length !== 0) {
            writer.uint32(66).bytes(message.proposerAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProcessProposalRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.txs.push(reader.bytes());
                    break;
                case 2:
                    message.proposedLastCommit = CommitInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.misbehavior.push(Misbehavior.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.hash = reader.bytes();
                    break;
                case 5:
                    message.height = longToString(reader.int64());
                    break;
                case 6:
                    message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.nextValidatorsHash = reader.bytes();
                    break;
                case 8:
                    message.proposerAddress = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => bytesFromBase64(e)) : [],
            proposedLastCommit: isSet(object.proposedLastCommit) ? CommitInfo.fromJSON(object.proposedLastCommit) : undefined,
            misbehavior: Array.isArray(object?.misbehavior)
                ? object.misbehavior.map((e) => Misbehavior.fromJSON(e))
                : [],
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            height: isSet(object.height) ? String(object.height) : "0",
            time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
            nextValidatorsHash: isSet(object.nextValidatorsHash)
                ? bytesFromBase64(object.nextValidatorsHash)
                : new Uint8Array(),
            proposerAddress: isSet(object.proposerAddress) ? bytesFromBase64(object.proposerAddress) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.txs) {
            obj.txs = message.txs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.txs = [];
        }
        message.proposedLastCommit !== undefined &&
            (obj.proposedLastCommit = message.proposedLastCommit ? CommitInfo.toJSON(message.proposedLastCommit) : undefined);
        if (message.misbehavior) {
            obj.misbehavior = message.misbehavior.map((e) => e ? Misbehavior.toJSON(e) : undefined);
        }
        else {
            obj.misbehavior = [];
        }
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.height !== undefined && (obj.height = message.height);
        message.time !== undefined && (obj.time = message.time.toISOString());
        message.nextValidatorsHash !== undefined &&
            (obj.nextValidatorsHash = base64FromBytes(message.nextValidatorsHash !== undefined ? message.nextValidatorsHash : new Uint8Array()));
        message.proposerAddress !== undefined &&
            (obj.proposerAddress = base64FromBytes(message.proposerAddress !== undefined ? message.proposerAddress : new Uint8Array()));
        return obj;
    },
    create(base) {
        return ProcessProposalRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseProcessProposalRequest();
        message.txs = object.txs?.map((e) => e) || [];
        message.proposedLastCommit = (object.proposedLastCommit !== undefined && object.proposedLastCommit !== null)
            ? CommitInfo.fromPartial(object.proposedLastCommit)
            : undefined;
        message.misbehavior = object.misbehavior?.map((e) => Misbehavior.fromPartial(e)) || [];
        message.hash = object.hash ?? new Uint8Array();
        message.height = object.height ?? "0";
        message.time = object.time ?? undefined;
        message.nextValidatorsHash = object.nextValidatorsHash ?? new Uint8Array();
        message.proposerAddress = object.proposerAddress ?? new Uint8Array();
        return message;
    },
};
function createBaseExtendVoteRequest() {
    return {
        hash: new Uint8Array(),
        height: "0",
        time: undefined,
        txs: [],
        proposedLastCommit: undefined,
        misbehavior: [],
        nextValidatorsHash: new Uint8Array(),
        proposerAddress: new Uint8Array(),
    };
}
export const ExtendVoteRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.hash.length !== 0) {
            writer.uint32(10).bytes(message.hash);
        }
        if (message.height !== "0") {
            writer.uint32(16).int64(message.height);
        }
        if (message.time !== undefined) {
            Timestamp.encode(toTimestamp(message.time), writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.txs) {
            writer.uint32(34).bytes(v);
        }
        if (message.proposedLastCommit !== undefined) {
            CommitInfo.encode(message.proposedLastCommit, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.misbehavior) {
            Misbehavior.encode(v, writer.uint32(50).fork()).ldelim();
        }
        if (message.nextValidatorsHash.length !== 0) {
            writer.uint32(58).bytes(message.nextValidatorsHash);
        }
        if (message.proposerAddress.length !== 0) {
            writer.uint32(66).bytes(message.proposerAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtendVoteRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hash = reader.bytes();
                    break;
                case 2:
                    message.height = longToString(reader.int64());
                    break;
                case 3:
                    message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.txs.push(reader.bytes());
                    break;
                case 5:
                    message.proposedLastCommit = CommitInfo.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.misbehavior.push(Misbehavior.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.nextValidatorsHash = reader.bytes();
                    break;
                case 8:
                    message.proposerAddress = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            height: isSet(object.height) ? String(object.height) : "0",
            time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => bytesFromBase64(e)) : [],
            proposedLastCommit: isSet(object.proposedLastCommit) ? CommitInfo.fromJSON(object.proposedLastCommit) : undefined,
            misbehavior: Array.isArray(object?.misbehavior)
                ? object.misbehavior.map((e) => Misbehavior.fromJSON(e))
                : [],
            nextValidatorsHash: isSet(object.nextValidatorsHash)
                ? bytesFromBase64(object.nextValidatorsHash)
                : new Uint8Array(),
            proposerAddress: isSet(object.proposerAddress) ? bytesFromBase64(object.proposerAddress) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.height !== undefined && (obj.height = message.height);
        message.time !== undefined && (obj.time = message.time.toISOString());
        if (message.txs) {
            obj.txs = message.txs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.txs = [];
        }
        message.proposedLastCommit !== undefined &&
            (obj.proposedLastCommit = message.proposedLastCommit ? CommitInfo.toJSON(message.proposedLastCommit) : undefined);
        if (message.misbehavior) {
            obj.misbehavior = message.misbehavior.map((e) => e ? Misbehavior.toJSON(e) : undefined);
        }
        else {
            obj.misbehavior = [];
        }
        message.nextValidatorsHash !== undefined &&
            (obj.nextValidatorsHash = base64FromBytes(message.nextValidatorsHash !== undefined ? message.nextValidatorsHash : new Uint8Array()));
        message.proposerAddress !== undefined &&
            (obj.proposerAddress = base64FromBytes(message.proposerAddress !== undefined ? message.proposerAddress : new Uint8Array()));
        return obj;
    },
    create(base) {
        return ExtendVoteRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExtendVoteRequest();
        message.hash = object.hash ?? new Uint8Array();
        message.height = object.height ?? "0";
        message.time = object.time ?? undefined;
        message.txs = object.txs?.map((e) => e) || [];
        message.proposedLastCommit = (object.proposedLastCommit !== undefined && object.proposedLastCommit !== null)
            ? CommitInfo.fromPartial(object.proposedLastCommit)
            : undefined;
        message.misbehavior = object.misbehavior?.map((e) => Misbehavior.fromPartial(e)) || [];
        message.nextValidatorsHash = object.nextValidatorsHash ?? new Uint8Array();
        message.proposerAddress = object.proposerAddress ?? new Uint8Array();
        return message;
    },
};
function createBaseVerifyVoteExtensionRequest() {
    return { hash: new Uint8Array(), validatorAddress: new Uint8Array(), height: "0", voteExtension: new Uint8Array() };
}
export const VerifyVoteExtensionRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.hash.length !== 0) {
            writer.uint32(10).bytes(message.hash);
        }
        if (message.validatorAddress.length !== 0) {
            writer.uint32(18).bytes(message.validatorAddress);
        }
        if (message.height !== "0") {
            writer.uint32(24).int64(message.height);
        }
        if (message.voteExtension.length !== 0) {
            writer.uint32(34).bytes(message.voteExtension);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyVoteExtensionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hash = reader.bytes();
                    break;
                case 2:
                    message.validatorAddress = reader.bytes();
                    break;
                case 3:
                    message.height = longToString(reader.int64());
                    break;
                case 4:
                    message.voteExtension = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            validatorAddress: isSet(object.validatorAddress) ? bytesFromBase64(object.validatorAddress) : new Uint8Array(),
            height: isSet(object.height) ? String(object.height) : "0",
            voteExtension: isSet(object.voteExtension) ? bytesFromBase64(object.voteExtension) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.validatorAddress !== undefined &&
            (obj.validatorAddress = base64FromBytes(message.validatorAddress !== undefined ? message.validatorAddress : new Uint8Array()));
        message.height !== undefined && (obj.height = message.height);
        message.voteExtension !== undefined &&
            (obj.voteExtension = base64FromBytes(message.voteExtension !== undefined ? message.voteExtension : new Uint8Array()));
        return obj;
    },
    create(base) {
        return VerifyVoteExtensionRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVerifyVoteExtensionRequest();
        message.hash = object.hash ?? new Uint8Array();
        message.validatorAddress = object.validatorAddress ?? new Uint8Array();
        message.height = object.height ?? "0";
        message.voteExtension = object.voteExtension ?? new Uint8Array();
        return message;
    },
};
function createBaseFinalizeBlockRequest() {
    return {
        txs: [],
        decidedLastCommit: undefined,
        misbehavior: [],
        hash: new Uint8Array(),
        height: "0",
        time: undefined,
        nextValidatorsHash: new Uint8Array(),
        proposerAddress: new Uint8Array(),
        syncingToHeight: "0",
    };
}
export const FinalizeBlockRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.txs) {
            writer.uint32(10).bytes(v);
        }
        if (message.decidedLastCommit !== undefined) {
            CommitInfo.encode(message.decidedLastCommit, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.misbehavior) {
            Misbehavior.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.hash.length !== 0) {
            writer.uint32(34).bytes(message.hash);
        }
        if (message.height !== "0") {
            writer.uint32(40).int64(message.height);
        }
        if (message.time !== undefined) {
            Timestamp.encode(toTimestamp(message.time), writer.uint32(50).fork()).ldelim();
        }
        if (message.nextValidatorsHash.length !== 0) {
            writer.uint32(58).bytes(message.nextValidatorsHash);
        }
        if (message.proposerAddress.length !== 0) {
            writer.uint32(66).bytes(message.proposerAddress);
        }
        if (message.syncingToHeight !== "0") {
            writer.uint32(72).int64(message.syncingToHeight);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFinalizeBlockRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.txs.push(reader.bytes());
                    break;
                case 2:
                    message.decidedLastCommit = CommitInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.misbehavior.push(Misbehavior.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.hash = reader.bytes();
                    break;
                case 5:
                    message.height = longToString(reader.int64());
                    break;
                case 6:
                    message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.nextValidatorsHash = reader.bytes();
                    break;
                case 8:
                    message.proposerAddress = reader.bytes();
                    break;
                case 9:
                    message.syncingToHeight = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            txs: Array.isArray(object?.txs) ? object.txs.map((e) => bytesFromBase64(e)) : [],
            decidedLastCommit: isSet(object.decidedLastCommit) ? CommitInfo.fromJSON(object.decidedLastCommit) : undefined,
            misbehavior: Array.isArray(object?.misbehavior)
                ? object.misbehavior.map((e) => Misbehavior.fromJSON(e))
                : [],
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            height: isSet(object.height) ? String(object.height) : "0",
            time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
            nextValidatorsHash: isSet(object.nextValidatorsHash)
                ? bytesFromBase64(object.nextValidatorsHash)
                : new Uint8Array(),
            proposerAddress: isSet(object.proposerAddress) ? bytesFromBase64(object.proposerAddress) : new Uint8Array(),
            syncingToHeight: isSet(object.syncingToHeight) ? String(object.syncingToHeight) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.txs) {
            obj.txs = message.txs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.txs = [];
        }
        message.decidedLastCommit !== undefined &&
            (obj.decidedLastCommit = message.decidedLastCommit ? CommitInfo.toJSON(message.decidedLastCommit) : undefined);
        if (message.misbehavior) {
            obj.misbehavior = message.misbehavior.map((e) => e ? Misbehavior.toJSON(e) : undefined);
        }
        else {
            obj.misbehavior = [];
        }
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.height !== undefined && (obj.height = message.height);
        message.time !== undefined && (obj.time = message.time.toISOString());
        message.nextValidatorsHash !== undefined &&
            (obj.nextValidatorsHash = base64FromBytes(message.nextValidatorsHash !== undefined ? message.nextValidatorsHash : new Uint8Array()));
        message.proposerAddress !== undefined &&
            (obj.proposerAddress = base64FromBytes(message.proposerAddress !== undefined ? message.proposerAddress : new Uint8Array()));
        message.syncingToHeight !== undefined && (obj.syncingToHeight = message.syncingToHeight);
        return obj;
    },
    create(base) {
        return FinalizeBlockRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFinalizeBlockRequest();
        message.txs = object.txs?.map((e) => e) || [];
        message.decidedLastCommit = (object.decidedLastCommit !== undefined && object.decidedLastCommit !== null)
            ? CommitInfo.fromPartial(object.decidedLastCommit)
            : undefined;
        message.misbehavior = object.misbehavior?.map((e) => Misbehavior.fromPartial(e)) || [];
        message.hash = object.hash ?? new Uint8Array();
        message.height = object.height ?? "0";
        message.time = object.time ?? undefined;
        message.nextValidatorsHash = object.nextValidatorsHash ?? new Uint8Array();
        message.proposerAddress = object.proposerAddress ?? new Uint8Array();
        message.syncingToHeight = object.syncingToHeight ?? "0";
        return message;
    },
};
function createBaseResponse() {
    return {
        exception: undefined,
        echo: undefined,
        flush: undefined,
        info: undefined,
        initChain: undefined,
        query: undefined,
        checkTx: undefined,
        commit: undefined,
        listSnapshots: undefined,
        offerSnapshot: undefined,
        loadSnapshotChunk: undefined,
        applySnapshotChunk: undefined,
        prepareProposal: undefined,
        processProposal: undefined,
        extendVote: undefined,
        verifyVoteExtension: undefined,
        finalizeBlock: undefined,
    };
}
export const Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.exception !== undefined) {
            ExceptionResponse.encode(message.exception, writer.uint32(10).fork()).ldelim();
        }
        if (message.echo !== undefined) {
            EchoResponse.encode(message.echo, writer.uint32(18).fork()).ldelim();
        }
        if (message.flush !== undefined) {
            FlushResponse.encode(message.flush, writer.uint32(26).fork()).ldelim();
        }
        if (message.info !== undefined) {
            InfoResponse.encode(message.info, writer.uint32(34).fork()).ldelim();
        }
        if (message.initChain !== undefined) {
            InitChainResponse.encode(message.initChain, writer.uint32(50).fork()).ldelim();
        }
        if (message.query !== undefined) {
            QueryResponse.encode(message.query, writer.uint32(58).fork()).ldelim();
        }
        if (message.checkTx !== undefined) {
            CheckTxResponse.encode(message.checkTx, writer.uint32(74).fork()).ldelim();
        }
        if (message.commit !== undefined) {
            CommitResponse.encode(message.commit, writer.uint32(98).fork()).ldelim();
        }
        if (message.listSnapshots !== undefined) {
            ListSnapshotsResponse.encode(message.listSnapshots, writer.uint32(106).fork()).ldelim();
        }
        if (message.offerSnapshot !== undefined) {
            OfferSnapshotResponse.encode(message.offerSnapshot, writer.uint32(114).fork()).ldelim();
        }
        if (message.loadSnapshotChunk !== undefined) {
            LoadSnapshotChunkResponse.encode(message.loadSnapshotChunk, writer.uint32(122).fork()).ldelim();
        }
        if (message.applySnapshotChunk !== undefined) {
            ApplySnapshotChunkResponse.encode(message.applySnapshotChunk, writer.uint32(130).fork()).ldelim();
        }
        if (message.prepareProposal !== undefined) {
            PrepareProposalResponse.encode(message.prepareProposal, writer.uint32(138).fork()).ldelim();
        }
        if (message.processProposal !== undefined) {
            ProcessProposalResponse.encode(message.processProposal, writer.uint32(146).fork()).ldelim();
        }
        if (message.extendVote !== undefined) {
            ExtendVoteResponse.encode(message.extendVote, writer.uint32(154).fork()).ldelim();
        }
        if (message.verifyVoteExtension !== undefined) {
            VerifyVoteExtensionResponse.encode(message.verifyVoteExtension, writer.uint32(162).fork()).ldelim();
        }
        if (message.finalizeBlock !== undefined) {
            FinalizeBlockResponse.encode(message.finalizeBlock, writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.exception = ExceptionResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.echo = EchoResponse.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.flush = FlushResponse.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.info = InfoResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.initChain = InitChainResponse.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.query = QueryResponse.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.checkTx = CheckTxResponse.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.commit = CommitResponse.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.listSnapshots = ListSnapshotsResponse.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.offerSnapshot = OfferSnapshotResponse.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.loadSnapshotChunk = LoadSnapshotChunkResponse.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.applySnapshotChunk = ApplySnapshotChunkResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.prepareProposal = PrepareProposalResponse.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.processProposal = ProcessProposalResponse.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.extendVote = ExtendVoteResponse.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.verifyVoteExtension = VerifyVoteExtensionResponse.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.finalizeBlock = FinalizeBlockResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            exception: isSet(object.exception) ? ExceptionResponse.fromJSON(object.exception) : undefined,
            echo: isSet(object.echo) ? EchoResponse.fromJSON(object.echo) : undefined,
            flush: isSet(object.flush) ? FlushResponse.fromJSON(object.flush) : undefined,
            info: isSet(object.info) ? InfoResponse.fromJSON(object.info) : undefined,
            initChain: isSet(object.initChain) ? InitChainResponse.fromJSON(object.initChain) : undefined,
            query: isSet(object.query) ? QueryResponse.fromJSON(object.query) : undefined,
            checkTx: isSet(object.checkTx) ? CheckTxResponse.fromJSON(object.checkTx) : undefined,
            commit: isSet(object.commit) ? CommitResponse.fromJSON(object.commit) : undefined,
            listSnapshots: isSet(object.listSnapshots) ? ListSnapshotsResponse.fromJSON(object.listSnapshots) : undefined,
            offerSnapshot: isSet(object.offerSnapshot) ? OfferSnapshotResponse.fromJSON(object.offerSnapshot) : undefined,
            loadSnapshotChunk: isSet(object.loadSnapshotChunk)
                ? LoadSnapshotChunkResponse.fromJSON(object.loadSnapshotChunk)
                : undefined,
            applySnapshotChunk: isSet(object.applySnapshotChunk)
                ? ApplySnapshotChunkResponse.fromJSON(object.applySnapshotChunk)
                : undefined,
            prepareProposal: isSet(object.prepareProposal)
                ? PrepareProposalResponse.fromJSON(object.prepareProposal)
                : undefined,
            processProposal: isSet(object.processProposal)
                ? ProcessProposalResponse.fromJSON(object.processProposal)
                : undefined,
            extendVote: isSet(object.extendVote) ? ExtendVoteResponse.fromJSON(object.extendVote) : undefined,
            verifyVoteExtension: isSet(object.verifyVoteExtension)
                ? VerifyVoteExtensionResponse.fromJSON(object.verifyVoteExtension)
                : undefined,
            finalizeBlock: isSet(object.finalizeBlock) ? FinalizeBlockResponse.fromJSON(object.finalizeBlock) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.exception !== undefined &&
            (obj.exception = message.exception ? ExceptionResponse.toJSON(message.exception) : undefined);
        message.echo !== undefined && (obj.echo = message.echo ? EchoResponse.toJSON(message.echo) : undefined);
        message.flush !== undefined && (obj.flush = message.flush ? FlushResponse.toJSON(message.flush) : undefined);
        message.info !== undefined && (obj.info = message.info ? InfoResponse.toJSON(message.info) : undefined);
        message.initChain !== undefined &&
            (obj.initChain = message.initChain ? InitChainResponse.toJSON(message.initChain) : undefined);
        message.query !== undefined && (obj.query = message.query ? QueryResponse.toJSON(message.query) : undefined);
        message.checkTx !== undefined &&
            (obj.checkTx = message.checkTx ? CheckTxResponse.toJSON(message.checkTx) : undefined);
        message.commit !== undefined && (obj.commit = message.commit ? CommitResponse.toJSON(message.commit) : undefined);
        message.listSnapshots !== undefined &&
            (obj.listSnapshots = message.listSnapshots ? ListSnapshotsResponse.toJSON(message.listSnapshots) : undefined);
        message.offerSnapshot !== undefined &&
            (obj.offerSnapshot = message.offerSnapshot ? OfferSnapshotResponse.toJSON(message.offerSnapshot) : undefined);
        message.loadSnapshotChunk !== undefined && (obj.loadSnapshotChunk = message.loadSnapshotChunk
            ? LoadSnapshotChunkResponse.toJSON(message.loadSnapshotChunk)
            : undefined);
        message.applySnapshotChunk !== undefined && (obj.applySnapshotChunk = message.applySnapshotChunk
            ? ApplySnapshotChunkResponse.toJSON(message.applySnapshotChunk)
            : undefined);
        message.prepareProposal !== undefined && (obj.prepareProposal = message.prepareProposal
            ? PrepareProposalResponse.toJSON(message.prepareProposal)
            : undefined);
        message.processProposal !== undefined && (obj.processProposal = message.processProposal
            ? ProcessProposalResponse.toJSON(message.processProposal)
            : undefined);
        message.extendVote !== undefined &&
            (obj.extendVote = message.extendVote ? ExtendVoteResponse.toJSON(message.extendVote) : undefined);
        message.verifyVoteExtension !== undefined && (obj.verifyVoteExtension = message.verifyVoteExtension
            ? VerifyVoteExtensionResponse.toJSON(message.verifyVoteExtension)
            : undefined);
        message.finalizeBlock !== undefined &&
            (obj.finalizeBlock = message.finalizeBlock ? FinalizeBlockResponse.toJSON(message.finalizeBlock) : undefined);
        return obj;
    },
    create(base) {
        return Response.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseResponse();
        message.exception = (object.exception !== undefined && object.exception !== null)
            ? ExceptionResponse.fromPartial(object.exception)
            : undefined;
        message.echo = (object.echo !== undefined && object.echo !== null)
            ? EchoResponse.fromPartial(object.echo)
            : undefined;
        message.flush = (object.flush !== undefined && object.flush !== null)
            ? FlushResponse.fromPartial(object.flush)
            : undefined;
        message.info = (object.info !== undefined && object.info !== null)
            ? InfoResponse.fromPartial(object.info)
            : undefined;
        message.initChain = (object.initChain !== undefined && object.initChain !== null)
            ? InitChainResponse.fromPartial(object.initChain)
            : undefined;
        message.query = (object.query !== undefined && object.query !== null)
            ? QueryResponse.fromPartial(object.query)
            : undefined;
        message.checkTx = (object.checkTx !== undefined && object.checkTx !== null)
            ? CheckTxResponse.fromPartial(object.checkTx)
            : undefined;
        message.commit = (object.commit !== undefined && object.commit !== null)
            ? CommitResponse.fromPartial(object.commit)
            : undefined;
        message.listSnapshots = (object.listSnapshots !== undefined && object.listSnapshots !== null)
            ? ListSnapshotsResponse.fromPartial(object.listSnapshots)
            : undefined;
        message.offerSnapshot = (object.offerSnapshot !== undefined && object.offerSnapshot !== null)
            ? OfferSnapshotResponse.fromPartial(object.offerSnapshot)
            : undefined;
        message.loadSnapshotChunk = (object.loadSnapshotChunk !== undefined && object.loadSnapshotChunk !== null)
            ? LoadSnapshotChunkResponse.fromPartial(object.loadSnapshotChunk)
            : undefined;
        message.applySnapshotChunk = (object.applySnapshotChunk !== undefined && object.applySnapshotChunk !== null)
            ? ApplySnapshotChunkResponse.fromPartial(object.applySnapshotChunk)
            : undefined;
        message.prepareProposal = (object.prepareProposal !== undefined && object.prepareProposal !== null)
            ? PrepareProposalResponse.fromPartial(object.prepareProposal)
            : undefined;
        message.processProposal = (object.processProposal !== undefined && object.processProposal !== null)
            ? ProcessProposalResponse.fromPartial(object.processProposal)
            : undefined;
        message.extendVote = (object.extendVote !== undefined && object.extendVote !== null)
            ? ExtendVoteResponse.fromPartial(object.extendVote)
            : undefined;
        message.verifyVoteExtension = (object.verifyVoteExtension !== undefined && object.verifyVoteExtension !== null)
            ? VerifyVoteExtensionResponse.fromPartial(object.verifyVoteExtension)
            : undefined;
        message.finalizeBlock = (object.finalizeBlock !== undefined && object.finalizeBlock !== null)
            ? FinalizeBlockResponse.fromPartial(object.finalizeBlock)
            : undefined;
        return message;
    },
};
function createBaseExceptionResponse() {
    return { error: "" };
}
export const ExceptionResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.error !== "") {
            writer.uint32(10).string(message.error);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExceptionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.error = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { error: isSet(object.error) ? String(object.error) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.error !== undefined && (obj.error = message.error);
        return obj;
    },
    create(base) {
        return ExceptionResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExceptionResponse();
        message.error = object.error ?? "";
        return message;
    },
};
function createBaseEchoResponse() {
    return { message: "" };
}
export const EchoResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.message !== "") {
            writer.uint32(10).string(message.message);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEchoResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { message: isSet(object.message) ? String(object.message) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.message !== undefined && (obj.message = message.message);
        return obj;
    },
    create(base) {
        return EchoResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEchoResponse();
        message.message = object.message ?? "";
        return message;
    },
};
function createBaseFlushResponse() {
    return {};
}
export const FlushResponse = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFlushResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return FlushResponse.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseFlushResponse();
        return message;
    },
};
function createBaseInfoResponse() {
    return {
        data: "",
        version: "",
        appVersion: "0",
        lastBlockHeight: "0",
        lastBlockAppHash: new Uint8Array(),
        lanePriorities: {},
        defaultLane: "",
    };
}
export const InfoResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.data !== "") {
            writer.uint32(10).string(message.data);
        }
        if (message.version !== "") {
            writer.uint32(18).string(message.version);
        }
        if (message.appVersion !== "0") {
            writer.uint32(24).uint64(message.appVersion);
        }
        if (message.lastBlockHeight !== "0") {
            writer.uint32(32).int64(message.lastBlockHeight);
        }
        if (message.lastBlockAppHash.length !== 0) {
            writer.uint32(42).bytes(message.lastBlockAppHash);
        }
        Object.entries(message.lanePriorities).forEach(([key, value]) => {
            InfoResponse_LanePrioritiesEntry.encode({ key: key, value }, writer.uint32(50).fork()).ldelim();
        });
        if (message.defaultLane !== "") {
            writer.uint32(58).string(message.defaultLane);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInfoResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data = reader.string();
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                case 3:
                    message.appVersion = longToString(reader.uint64());
                    break;
                case 4:
                    message.lastBlockHeight = longToString(reader.int64());
                    break;
                case 5:
                    message.lastBlockAppHash = reader.bytes();
                    break;
                case 6:
                    const entry6 = InfoResponse_LanePrioritiesEntry.decode(reader, reader.uint32());
                    if (entry6.value !== undefined) {
                        message.lanePriorities[entry6.key] = entry6.value;
                    }
                    break;
                case 7:
                    message.defaultLane = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            data: isSet(object.data) ? String(object.data) : "",
            version: isSet(object.version) ? String(object.version) : "",
            appVersion: isSet(object.appVersion) ? String(object.appVersion) : "0",
            lastBlockHeight: isSet(object.lastBlockHeight) ? String(object.lastBlockHeight) : "0",
            lastBlockAppHash: isSet(object.lastBlockAppHash) ? bytesFromBase64(object.lastBlockAppHash) : new Uint8Array(),
            lanePriorities: isObject(object.lanePriorities)
                ? Object.entries(object.lanePriorities).reduce((acc, [key, value]) => {
                    acc[key] = Number(value);
                    return acc;
                }, {})
                : {},
            defaultLane: isSet(object.defaultLane) ? String(object.defaultLane) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.data !== undefined && (obj.data = message.data);
        message.version !== undefined && (obj.version = message.version);
        message.appVersion !== undefined && (obj.appVersion = message.appVersion);
        message.lastBlockHeight !== undefined && (obj.lastBlockHeight = message.lastBlockHeight);
        message.lastBlockAppHash !== undefined &&
            (obj.lastBlockAppHash = base64FromBytes(message.lastBlockAppHash !== undefined ? message.lastBlockAppHash : new Uint8Array()));
        obj.lanePriorities = {};
        if (message.lanePriorities) {
            Object.entries(message.lanePriorities).forEach(([k, v]) => {
                obj.lanePriorities[k] = Math.round(v);
            });
        }
        message.defaultLane !== undefined && (obj.defaultLane = message.defaultLane);
        return obj;
    },
    create(base) {
        return InfoResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInfoResponse();
        message.data = object.data ?? "";
        message.version = object.version ?? "";
        message.appVersion = object.appVersion ?? "0";
        message.lastBlockHeight = object.lastBlockHeight ?? "0";
        message.lastBlockAppHash = object.lastBlockAppHash ?? new Uint8Array();
        message.lanePriorities = Object.entries(object.lanePriorities ?? {}).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = Number(value);
            }
            return acc;
        }, {});
        message.defaultLane = object.defaultLane ?? "";
        return message;
    },
};
function createBaseInfoResponse_LanePrioritiesEntry() {
    return { key: "", value: 0 };
}
export const InfoResponse_LanePrioritiesEntry = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== 0) {
            writer.uint32(16).uint32(message.value);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInfoResponse_LanePrioritiesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = Math.round(message.value));
        return obj;
    },
    create(base) {
        return InfoResponse_LanePrioritiesEntry.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInfoResponse_LanePrioritiesEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseInitChainResponse() {
    return { consensusParams: undefined, validators: [], appHash: new Uint8Array() };
}
export const InitChainResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.consensusParams !== undefined) {
            ConsensusParams.encode(message.consensusParams, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.validators) {
            ValidatorUpdate.encode(v, writer.uint32(18).fork()).ldelim();
        }
        if (message.appHash.length !== 0) {
            writer.uint32(26).bytes(message.appHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInitChainResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.consensusParams = ConsensusParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.validators.push(ValidatorUpdate.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.appHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            consensusParams: isSet(object.consensusParams) ? ConsensusParams.fromJSON(object.consensusParams) : undefined,
            validators: Array.isArray(object?.validators)
                ? object.validators.map((e) => ValidatorUpdate.fromJSON(e))
                : [],
            appHash: isSet(object.appHash) ? bytesFromBase64(object.appHash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.consensusParams !== undefined &&
            (obj.consensusParams = message.consensusParams ? ConsensusParams.toJSON(message.consensusParams) : undefined);
        if (message.validators) {
            obj.validators = message.validators.map((e) => e ? ValidatorUpdate.toJSON(e) : undefined);
        }
        else {
            obj.validators = [];
        }
        message.appHash !== undefined &&
            (obj.appHash = base64FromBytes(message.appHash !== undefined ? message.appHash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return InitChainResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseInitChainResponse();
        message.consensusParams = (object.consensusParams !== undefined && object.consensusParams !== null)
            ? ConsensusParams.fromPartial(object.consensusParams)
            : undefined;
        message.validators = object.validators?.map((e) => ValidatorUpdate.fromPartial(e)) || [];
        message.appHash = object.appHash ?? new Uint8Array();
        return message;
    },
};
function createBaseQueryResponse() {
    return {
        code: 0,
        log: "",
        info: "",
        index: "0",
        key: new Uint8Array(),
        value: new Uint8Array(),
        proofOps: undefined,
        height: "0",
        codespace: "",
    };
}
export const QueryResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).uint32(message.code);
        }
        if (message.log !== "") {
            writer.uint32(26).string(message.log);
        }
        if (message.info !== "") {
            writer.uint32(34).string(message.info);
        }
        if (message.index !== "0") {
            writer.uint32(40).int64(message.index);
        }
        if (message.key.length !== 0) {
            writer.uint32(50).bytes(message.key);
        }
        if (message.value.length !== 0) {
            writer.uint32(58).bytes(message.value);
        }
        if (message.proofOps !== undefined) {
            ProofOps.encode(message.proofOps, writer.uint32(66).fork()).ldelim();
        }
        if (message.height !== "0") {
            writer.uint32(72).int64(message.height);
        }
        if (message.codespace !== "") {
            writer.uint32(82).string(message.codespace);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQueryResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.uint32();
                    break;
                case 3:
                    message.log = reader.string();
                    break;
                case 4:
                    message.info = reader.string();
                    break;
                case 5:
                    message.index = longToString(reader.int64());
                    break;
                case 6:
                    message.key = reader.bytes();
                    break;
                case 7:
                    message.value = reader.bytes();
                    break;
                case 8:
                    message.proofOps = ProofOps.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.height = longToString(reader.int64());
                    break;
                case 10:
                    message.codespace = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? Number(object.code) : 0,
            log: isSet(object.log) ? String(object.log) : "",
            info: isSet(object.info) ? String(object.info) : "",
            index: isSet(object.index) ? String(object.index) : "0",
            key: isSet(object.key) ? bytesFromBase64(object.key) : new Uint8Array(),
            value: isSet(object.value) ? bytesFromBase64(object.value) : new Uint8Array(),
            proofOps: isSet(object.proofOps) ? ProofOps.fromJSON(object.proofOps) : undefined,
            height: isSet(object.height) ? String(object.height) : "0",
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.log !== undefined && (obj.log = message.log);
        message.info !== undefined && (obj.info = message.info);
        message.index !== undefined && (obj.index = message.index);
        message.key !== undefined &&
            (obj.key = base64FromBytes(message.key !== undefined ? message.key : new Uint8Array()));
        message.value !== undefined &&
            (obj.value = base64FromBytes(message.value !== undefined ? message.value : new Uint8Array()));
        message.proofOps !== undefined && (obj.proofOps = message.proofOps ? ProofOps.toJSON(message.proofOps) : undefined);
        message.height !== undefined && (obj.height = message.height);
        message.codespace !== undefined && (obj.codespace = message.codespace);
        return obj;
    },
    create(base) {
        return QueryResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseQueryResponse();
        message.code = object.code ?? 0;
        message.log = object.log ?? "";
        message.info = object.info ?? "";
        message.index = object.index ?? "0";
        message.key = object.key ?? new Uint8Array();
        message.value = object.value ?? new Uint8Array();
        message.proofOps = (object.proofOps !== undefined && object.proofOps !== null)
            ? ProofOps.fromPartial(object.proofOps)
            : undefined;
        message.height = object.height ?? "0";
        message.codespace = object.codespace ?? "";
        return message;
    },
};
function createBaseCheckTxResponse() {
    return {
        code: 0,
        data: new Uint8Array(),
        log: "",
        info: "",
        gasWanted: "0",
        gasUsed: "0",
        events: [],
        codespace: "",
        laneId: "",
    };
}
export const CheckTxResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).uint32(message.code);
        }
        if (message.data.length !== 0) {
            writer.uint32(18).bytes(message.data);
        }
        if (message.log !== "") {
            writer.uint32(26).string(message.log);
        }
        if (message.info !== "") {
            writer.uint32(34).string(message.info);
        }
        if (message.gasWanted !== "0") {
            writer.uint32(40).int64(message.gasWanted);
        }
        if (message.gasUsed !== "0") {
            writer.uint32(48).int64(message.gasUsed);
        }
        for (const v of message.events) {
            Event.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.codespace !== "") {
            writer.uint32(66).string(message.codespace);
        }
        if (message.laneId !== "") {
            writer.uint32(98).string(message.laneId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCheckTxResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.uint32();
                    break;
                case 2:
                    message.data = reader.bytes();
                    break;
                case 3:
                    message.log = reader.string();
                    break;
                case 4:
                    message.info = reader.string();
                    break;
                case 5:
                    message.gasWanted = longToString(reader.int64());
                    break;
                case 6:
                    message.gasUsed = longToString(reader.int64());
                    break;
                case 7:
                    message.events.push(Event.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.codespace = reader.string();
                    break;
                case 12:
                    message.laneId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? Number(object.code) : 0,
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            log: isSet(object.log) ? String(object.log) : "",
            info: isSet(object.info) ? String(object.info) : "",
            gasWanted: isSet(object.gas_wanted) ? String(object.gas_wanted) : "0",
            gasUsed: isSet(object.gas_used) ? String(object.gas_used) : "0",
            events: Array.isArray(object?.events) ? object.events.map((e) => Event.fromJSON(e)) : [],
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            laneId: isSet(object.laneId) ? String(object.laneId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.log !== undefined && (obj.log = message.log);
        message.info !== undefined && (obj.info = message.info);
        message.gasWanted !== undefined && (obj.gas_wanted = message.gasWanted);
        message.gasUsed !== undefined && (obj.gas_used = message.gasUsed);
        if (message.events) {
            obj.events = message.events.map((e) => e ? Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.laneId !== undefined && (obj.laneId = message.laneId);
        return obj;
    },
    create(base) {
        return CheckTxResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCheckTxResponse();
        message.code = object.code ?? 0;
        message.data = object.data ?? new Uint8Array();
        message.log = object.log ?? "";
        message.info = object.info ?? "";
        message.gasWanted = object.gasWanted ?? "0";
        message.gasUsed = object.gasUsed ?? "0";
        message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
        message.codespace = object.codespace ?? "";
        message.laneId = object.laneId ?? "";
        return message;
    },
};
function createBaseCommitResponse() {
    return { retainHeight: "0" };
}
export const CommitResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.retainHeight !== "0") {
            writer.uint32(24).int64(message.retainHeight);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCommitResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 3:
                    message.retainHeight = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { retainHeight: isSet(object.retainHeight) ? String(object.retainHeight) : "0" };
    },
    toJSON(message) {
        const obj = {};
        message.retainHeight !== undefined && (obj.retainHeight = message.retainHeight);
        return obj;
    },
    create(base) {
        return CommitResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCommitResponse();
        message.retainHeight = object.retainHeight ?? "0";
        return message;
    },
};
function createBaseListSnapshotsResponse() {
    return { snapshots: [] };
}
export const ListSnapshotsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.snapshots) {
            Snapshot.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListSnapshotsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snapshots.push(Snapshot.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            snapshots: Array.isArray(object?.snapshots) ? object.snapshots.map((e) => Snapshot.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.snapshots) {
            obj.snapshots = message.snapshots.map((e) => e ? Snapshot.toJSON(e) : undefined);
        }
        else {
            obj.snapshots = [];
        }
        return obj;
    },
    create(base) {
        return ListSnapshotsResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseListSnapshotsResponse();
        message.snapshots = object.snapshots?.map((e) => Snapshot.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOfferSnapshotResponse() {
    return { result: 0 };
}
export const OfferSnapshotResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.result !== 0) {
            writer.uint32(8).int32(message.result);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOfferSnapshotResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { result: isSet(object.result) ? offerSnapshotResultFromJSON(object.result) : 0 };
    },
    toJSON(message) {
        const obj = {};
        message.result !== undefined && (obj.result = offerSnapshotResultToJSON(message.result));
        return obj;
    },
    create(base) {
        return OfferSnapshotResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOfferSnapshotResponse();
        message.result = object.result ?? 0;
        return message;
    },
};
function createBaseLoadSnapshotChunkResponse() {
    return { chunk: new Uint8Array() };
}
export const LoadSnapshotChunkResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.chunk.length !== 0) {
            writer.uint32(10).bytes(message.chunk);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLoadSnapshotChunkResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chunk = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { chunk: isSet(object.chunk) ? bytesFromBase64(object.chunk) : new Uint8Array() };
    },
    toJSON(message) {
        const obj = {};
        message.chunk !== undefined &&
            (obj.chunk = base64FromBytes(message.chunk !== undefined ? message.chunk : new Uint8Array()));
        return obj;
    },
    create(base) {
        return LoadSnapshotChunkResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLoadSnapshotChunkResponse();
        message.chunk = object.chunk ?? new Uint8Array();
        return message;
    },
};
function createBaseApplySnapshotChunkResponse() {
    return { result: 0, refetchChunks: [], rejectSenders: [] };
}
export const ApplySnapshotChunkResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.result !== 0) {
            writer.uint32(8).int32(message.result);
        }
        writer.uint32(18).fork();
        for (const v of message.refetchChunks) {
            writer.uint32(v);
        }
        writer.ldelim();
        for (const v of message.rejectSenders) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseApplySnapshotChunkResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.refetchChunks.push(reader.uint32());
                        }
                    }
                    else {
                        message.refetchChunks.push(reader.uint32());
                    }
                    break;
                case 3:
                    message.rejectSenders.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            result: isSet(object.result) ? applySnapshotChunkResultFromJSON(object.result) : 0,
            refetchChunks: Array.isArray(object?.refetchChunks) ? object.refetchChunks.map((e) => Number(e)) : [],
            rejectSenders: Array.isArray(object?.rejectSenders) ? object.rejectSenders.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.result !== undefined && (obj.result = applySnapshotChunkResultToJSON(message.result));
        if (message.refetchChunks) {
            obj.refetchChunks = message.refetchChunks.map((e) => Math.round(e));
        }
        else {
            obj.refetchChunks = [];
        }
        if (message.rejectSenders) {
            obj.rejectSenders = message.rejectSenders.map((e) => e);
        }
        else {
            obj.rejectSenders = [];
        }
        return obj;
    },
    create(base) {
        return ApplySnapshotChunkResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseApplySnapshotChunkResponse();
        message.result = object.result ?? 0;
        message.refetchChunks = object.refetchChunks?.map((e) => e) || [];
        message.rejectSenders = object.rejectSenders?.map((e) => e) || [];
        return message;
    },
};
function createBasePrepareProposalResponse() {
    return { txs: [] };
}
export const PrepareProposalResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.txs) {
            writer.uint32(10).bytes(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrepareProposalResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.txs.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { txs: Array.isArray(object?.txs) ? object.txs.map((e) => bytesFromBase64(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.txs) {
            obj.txs = message.txs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.txs = [];
        }
        return obj;
    },
    create(base) {
        return PrepareProposalResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePrepareProposalResponse();
        message.txs = object.txs?.map((e) => e) || [];
        return message;
    },
};
function createBaseProcessProposalResponse() {
    return { status: 0 };
}
export const ProcessProposalResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProcessProposalResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { status: isSet(object.status) ? processProposalStatusFromJSON(object.status) : 0 };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined && (obj.status = processProposalStatusToJSON(message.status));
        return obj;
    },
    create(base) {
        return ProcessProposalResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseProcessProposalResponse();
        message.status = object.status ?? 0;
        return message;
    },
};
function createBaseExtendVoteResponse() {
    return { voteExtension: new Uint8Array() };
}
export const ExtendVoteResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.voteExtension.length !== 0) {
            writer.uint32(10).bytes(message.voteExtension);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtendVoteResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.voteExtension = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { voteExtension: isSet(object.voteExtension) ? bytesFromBase64(object.voteExtension) : new Uint8Array() };
    },
    toJSON(message) {
        const obj = {};
        message.voteExtension !== undefined &&
            (obj.voteExtension = base64FromBytes(message.voteExtension !== undefined ? message.voteExtension : new Uint8Array()));
        return obj;
    },
    create(base) {
        return ExtendVoteResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExtendVoteResponse();
        message.voteExtension = object.voteExtension ?? new Uint8Array();
        return message;
    },
};
function createBaseVerifyVoteExtensionResponse() {
    return { status: 0 };
}
export const VerifyVoteExtensionResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyVoteExtensionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { status: isSet(object.status) ? verifyVoteExtensionStatusFromJSON(object.status) : 0 };
    },
    toJSON(message) {
        const obj = {};
        message.status !== undefined && (obj.status = verifyVoteExtensionStatusToJSON(message.status));
        return obj;
    },
    create(base) {
        return VerifyVoteExtensionResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVerifyVoteExtensionResponse();
        message.status = object.status ?? 0;
        return message;
    },
};
function createBaseFinalizeBlockResponse() {
    return {
        events: [],
        txResults: [],
        validatorUpdates: [],
        consensusParamUpdates: undefined,
        appHash: new Uint8Array(),
        nextBlockDelay: undefined,
    };
}
export const FinalizeBlockResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.events) {
            Event.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.txResults) {
            ExecTxResult.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.validatorUpdates) {
            ValidatorUpdate.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.consensusParamUpdates !== undefined) {
            ConsensusParams.encode(message.consensusParamUpdates, writer.uint32(34).fork()).ldelim();
        }
        if (message.appHash.length !== 0) {
            writer.uint32(42).bytes(message.appHash);
        }
        if (message.nextBlockDelay !== undefined) {
            Duration.encode(message.nextBlockDelay, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFinalizeBlockResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.events.push(Event.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.txResults.push(ExecTxResult.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.validatorUpdates.push(ValidatorUpdate.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.consensusParamUpdates = ConsensusParams.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appHash = reader.bytes();
                    break;
                case 6:
                    message.nextBlockDelay = Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            events: Array.isArray(object?.events) ? object.events.map((e) => Event.fromJSON(e)) : [],
            txResults: Array.isArray(object?.txResults) ? object.txResults.map((e) => ExecTxResult.fromJSON(e)) : [],
            validatorUpdates: Array.isArray(object?.validatorUpdates)
                ? object.validatorUpdates.map((e) => ValidatorUpdate.fromJSON(e))
                : [],
            consensusParamUpdates: isSet(object.consensusParamUpdates)
                ? ConsensusParams.fromJSON(object.consensusParamUpdates)
                : undefined,
            appHash: isSet(object.appHash) ? bytesFromBase64(object.appHash) : new Uint8Array(),
            nextBlockDelay: isSet(object.nextBlockDelay) ? Duration.fromJSON(object.nextBlockDelay) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.events) {
            obj.events = message.events.map((e) => e ? Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        if (message.txResults) {
            obj.txResults = message.txResults.map((e) => e ? ExecTxResult.toJSON(e) : undefined);
        }
        else {
            obj.txResults = [];
        }
        if (message.validatorUpdates) {
            obj.validatorUpdates = message.validatorUpdates.map((e) => e ? ValidatorUpdate.toJSON(e) : undefined);
        }
        else {
            obj.validatorUpdates = [];
        }
        message.consensusParamUpdates !== undefined && (obj.consensusParamUpdates = message.consensusParamUpdates
            ? ConsensusParams.toJSON(message.consensusParamUpdates)
            : undefined);
        message.appHash !== undefined &&
            (obj.appHash = base64FromBytes(message.appHash !== undefined ? message.appHash : new Uint8Array()));
        message.nextBlockDelay !== undefined &&
            (obj.nextBlockDelay = message.nextBlockDelay ? Duration.toJSON(message.nextBlockDelay) : undefined);
        return obj;
    },
    create(base) {
        return FinalizeBlockResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseFinalizeBlockResponse();
        message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
        message.txResults = object.txResults?.map((e) => ExecTxResult.fromPartial(e)) || [];
        message.validatorUpdates = object.validatorUpdates?.map((e) => ValidatorUpdate.fromPartial(e)) || [];
        message.consensusParamUpdates =
            (object.consensusParamUpdates !== undefined && object.consensusParamUpdates !== null)
                ? ConsensusParams.fromPartial(object.consensusParamUpdates)
                : undefined;
        message.appHash = object.appHash ?? new Uint8Array();
        message.nextBlockDelay = (object.nextBlockDelay !== undefined && object.nextBlockDelay !== null)
            ? Duration.fromPartial(object.nextBlockDelay)
            : undefined;
        return message;
    },
};
function createBaseCommitInfo() {
    return { round: 0, votes: [] };
}
export const CommitInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.round !== 0) {
            writer.uint32(8).int32(message.round);
        }
        for (const v of message.votes) {
            VoteInfo.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCommitInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.round = reader.int32();
                    break;
                case 2:
                    message.votes.push(VoteInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            round: isSet(object.round) ? Number(object.round) : 0,
            votes: Array.isArray(object?.votes) ? object.votes.map((e) => VoteInfo.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.round !== undefined && (obj.round = Math.round(message.round));
        if (message.votes) {
            obj.votes = message.votes.map((e) => e ? VoteInfo.toJSON(e) : undefined);
        }
        else {
            obj.votes = [];
        }
        return obj;
    },
    create(base) {
        return CommitInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCommitInfo();
        message.round = object.round ?? 0;
        message.votes = object.votes?.map((e) => VoteInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseExtendedCommitInfo() {
    return { round: 0, votes: [] };
}
export const ExtendedCommitInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.round !== 0) {
            writer.uint32(8).int32(message.round);
        }
        for (const v of message.votes) {
            ExtendedVoteInfo.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtendedCommitInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.round = reader.int32();
                    break;
                case 2:
                    message.votes.push(ExtendedVoteInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            round: isSet(object.round) ? Number(object.round) : 0,
            votes: Array.isArray(object?.votes) ? object.votes.map((e) => ExtendedVoteInfo.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.round !== undefined && (obj.round = Math.round(message.round));
        if (message.votes) {
            obj.votes = message.votes.map((e) => e ? ExtendedVoteInfo.toJSON(e) : undefined);
        }
        else {
            obj.votes = [];
        }
        return obj;
    },
    create(base) {
        return ExtendedCommitInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExtendedCommitInfo();
        message.round = object.round ?? 0;
        message.votes = object.votes?.map((e) => ExtendedVoteInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEvent() {
    return { type: "", attributes: [] };
}
export const Event = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        for (const v of message.attributes) {
            EventAttribute.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.attributes.push(EventAttribute.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? String(object.type) : "",
            attributes: Array.isArray(object?.attributes)
                ? object.attributes.map((e) => EventAttribute.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        if (message.attributes) {
            obj.attributes = message.attributes.map((e) => e ? EventAttribute.toJSON(e) : undefined);
        }
        else {
            obj.attributes = [];
        }
        return obj;
    },
    create(base) {
        return Event.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEvent();
        message.type = object.type ?? "";
        message.attributes = object.attributes?.map((e) => EventAttribute.fromPartial(e)) || [];
        return message;
    },
};
function createBaseEventAttribute() {
    return { key: "", value: "", index: false };
}
export const EventAttribute = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        if (message.index === true) {
            writer.uint32(24).bool(message.index);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventAttribute();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.index = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
            index: isSet(object.index) ? Boolean(object.index) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        message.index !== undefined && (obj.index = message.index);
        return obj;
    },
    create(base) {
        return EventAttribute.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseEventAttribute();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        message.index = object.index ?? false;
        return message;
    },
};
function createBaseExecTxResult() {
    return {
        code: 0,
        data: new Uint8Array(),
        log: "",
        info: "",
        gasWanted: "0",
        gasUsed: "0",
        events: [],
        codespace: "",
    };
}
export const ExecTxResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== 0) {
            writer.uint32(8).uint32(message.code);
        }
        if (message.data.length !== 0) {
            writer.uint32(18).bytes(message.data);
        }
        if (message.log !== "") {
            writer.uint32(26).string(message.log);
        }
        if (message.info !== "") {
            writer.uint32(34).string(message.info);
        }
        if (message.gasWanted !== "0") {
            writer.uint32(40).int64(message.gasWanted);
        }
        if (message.gasUsed !== "0") {
            writer.uint32(48).int64(message.gasUsed);
        }
        for (const v of message.events) {
            Event.encode(v, writer.uint32(58).fork()).ldelim();
        }
        if (message.codespace !== "") {
            writer.uint32(66).string(message.codespace);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExecTxResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.uint32();
                    break;
                case 2:
                    message.data = reader.bytes();
                    break;
                case 3:
                    message.log = reader.string();
                    break;
                case 4:
                    message.info = reader.string();
                    break;
                case 5:
                    message.gasWanted = longToString(reader.int64());
                    break;
                case 6:
                    message.gasUsed = longToString(reader.int64());
                    break;
                case 7:
                    message.events.push(Event.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.codespace = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? Number(object.code) : 0,
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            log: isSet(object.log) ? String(object.log) : "",
            info: isSet(object.info) ? String(object.info) : "",
            gasWanted: isSet(object.gas_wanted) ? String(object.gas_wanted) : "0",
            gasUsed: isSet(object.gas_used) ? String(object.gas_used) : "0",
            events: Array.isArray(object?.events) ? object.events.map((e) => Event.fromJSON(e)) : [],
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.log !== undefined && (obj.log = message.log);
        message.info !== undefined && (obj.info = message.info);
        message.gasWanted !== undefined && (obj.gas_wanted = message.gasWanted);
        message.gasUsed !== undefined && (obj.gas_used = message.gasUsed);
        if (message.events) {
            obj.events = message.events.map((e) => e ? Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        message.codespace !== undefined && (obj.codespace = message.codespace);
        return obj;
    },
    create(base) {
        return ExecTxResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExecTxResult();
        message.code = object.code ?? 0;
        message.data = object.data ?? new Uint8Array();
        message.log = object.log ?? "";
        message.info = object.info ?? "";
        message.gasWanted = object.gasWanted ?? "0";
        message.gasUsed = object.gasUsed ?? "0";
        message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
        message.codespace = object.codespace ?? "";
        return message;
    },
};
function createBaseTxResult() {
    return { height: "0", index: 0, tx: new Uint8Array(), result: undefined };
}
export const TxResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).int64(message.height);
        }
        if (message.index !== 0) {
            writer.uint32(16).uint32(message.index);
        }
        if (message.tx.length !== 0) {
            writer.uint32(26).bytes(message.tx);
        }
        if (message.result !== undefined) {
            ExecTxResult.encode(message.result, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTxResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.int64());
                    break;
                case 2:
                    message.index = reader.uint32();
                    break;
                case 3:
                    message.tx = reader.bytes();
                    break;
                case 4:
                    message.result = ExecTxResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            index: isSet(object.index) ? Number(object.index) : 0,
            tx: isSet(object.tx) ? bytesFromBase64(object.tx) : new Uint8Array(),
            result: isSet(object.result) ? ExecTxResult.fromJSON(object.result) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.index !== undefined && (obj.index = Math.round(message.index));
        message.tx !== undefined && (obj.tx = base64FromBytes(message.tx !== undefined ? message.tx : new Uint8Array()));
        message.result !== undefined && (obj.result = message.result ? ExecTxResult.toJSON(message.result) : undefined);
        return obj;
    },
    create(base) {
        return TxResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTxResult();
        message.height = object.height ?? "0";
        message.index = object.index ?? 0;
        message.tx = object.tx ?? new Uint8Array();
        message.result = (object.result !== undefined && object.result !== null)
            ? ExecTxResult.fromPartial(object.result)
            : undefined;
        return message;
    },
};
function createBaseValidator() {
    return { address: new Uint8Array(), power: "0" };
}
export const Validator = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address.length !== 0) {
            writer.uint32(10).bytes(message.address);
        }
        if (message.power !== "0") {
            writer.uint32(24).int64(message.power);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.bytes();
                    break;
                case 3:
                    message.power = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: isSet(object.address) ? bytesFromBase64(object.address) : new Uint8Array(),
            power: isSet(object.power) ? String(object.power) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined &&
            (obj.address = base64FromBytes(message.address !== undefined ? message.address : new Uint8Array()));
        message.power !== undefined && (obj.power = message.power);
        return obj;
    },
    create(base) {
        return Validator.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidator();
        message.address = object.address ?? new Uint8Array();
        message.power = object.power ?? "0";
        return message;
    },
};
function createBaseValidatorUpdate() {
    return { power: "0", pubKeyBytes: new Uint8Array(), pubKeyType: "" };
}
export const ValidatorUpdate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.power !== "0") {
            writer.uint32(16).int64(message.power);
        }
        if (message.pubKeyBytes.length !== 0) {
            writer.uint32(26).bytes(message.pubKeyBytes);
        }
        if (message.pubKeyType !== "") {
            writer.uint32(34).string(message.pubKeyType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidatorUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.power = longToString(reader.int64());
                    break;
                case 3:
                    message.pubKeyBytes = reader.bytes();
                    break;
                case 4:
                    message.pubKeyType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            power: isSet(object.power) ? String(object.power) : "0",
            pubKeyBytes: isSet(object.pubKeyBytes) ? bytesFromBase64(object.pubKeyBytes) : new Uint8Array(),
            pubKeyType: isSet(object.pubKeyType) ? String(object.pubKeyType) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.power !== undefined && (obj.power = message.power);
        message.pubKeyBytes !== undefined &&
            (obj.pubKeyBytes = base64FromBytes(message.pubKeyBytes !== undefined ? message.pubKeyBytes : new Uint8Array()));
        message.pubKeyType !== undefined && (obj.pubKeyType = message.pubKeyType);
        return obj;
    },
    create(base) {
        return ValidatorUpdate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidatorUpdate();
        message.power = object.power ?? "0";
        message.pubKeyBytes = object.pubKeyBytes ?? new Uint8Array();
        message.pubKeyType = object.pubKeyType ?? "";
        return message;
    },
};
function createBaseVoteInfo() {
    return { validator: undefined, blockIdFlag: 0 };
}
export const VoteInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.validator !== undefined) {
            Validator.encode(message.validator, writer.uint32(10).fork()).ldelim();
        }
        if (message.blockIdFlag !== 0) {
            writer.uint32(24).int32(message.blockIdFlag);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVoteInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.validator = Validator.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.blockIdFlag = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            validator: isSet(object.validator) ? Validator.fromJSON(object.validator) : undefined,
            blockIdFlag: isSet(object.blockIdFlag) ? blockIDFlagFromJSON(object.blockIdFlag) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.validator !== undefined &&
            (obj.validator = message.validator ? Validator.toJSON(message.validator) : undefined);
        message.blockIdFlag !== undefined && (obj.blockIdFlag = blockIDFlagToJSON(message.blockIdFlag));
        return obj;
    },
    create(base) {
        return VoteInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVoteInfo();
        message.validator = (object.validator !== undefined && object.validator !== null)
            ? Validator.fromPartial(object.validator)
            : undefined;
        message.blockIdFlag = object.blockIdFlag ?? 0;
        return message;
    },
};
function createBaseExtendedVoteInfo() {
    return {
        validator: undefined,
        voteExtension: new Uint8Array(),
        extensionSignature: new Uint8Array(),
        blockIdFlag: 0,
    };
}
export const ExtendedVoteInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.validator !== undefined) {
            Validator.encode(message.validator, writer.uint32(10).fork()).ldelim();
        }
        if (message.voteExtension.length !== 0) {
            writer.uint32(26).bytes(message.voteExtension);
        }
        if (message.extensionSignature.length !== 0) {
            writer.uint32(34).bytes(message.extensionSignature);
        }
        if (message.blockIdFlag !== 0) {
            writer.uint32(40).int32(message.blockIdFlag);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtendedVoteInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.validator = Validator.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.voteExtension = reader.bytes();
                    break;
                case 4:
                    message.extensionSignature = reader.bytes();
                    break;
                case 5:
                    message.blockIdFlag = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            validator: isSet(object.validator) ? Validator.fromJSON(object.validator) : undefined,
            voteExtension: isSet(object.voteExtension) ? bytesFromBase64(object.voteExtension) : new Uint8Array(),
            extensionSignature: isSet(object.extensionSignature)
                ? bytesFromBase64(object.extensionSignature)
                : new Uint8Array(),
            blockIdFlag: isSet(object.blockIdFlag) ? blockIDFlagFromJSON(object.blockIdFlag) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.validator !== undefined &&
            (obj.validator = message.validator ? Validator.toJSON(message.validator) : undefined);
        message.voteExtension !== undefined &&
            (obj.voteExtension = base64FromBytes(message.voteExtension !== undefined ? message.voteExtension : new Uint8Array()));
        message.extensionSignature !== undefined &&
            (obj.extensionSignature = base64FromBytes(message.extensionSignature !== undefined ? message.extensionSignature : new Uint8Array()));
        message.blockIdFlag !== undefined && (obj.blockIdFlag = blockIDFlagToJSON(message.blockIdFlag));
        return obj;
    },
    create(base) {
        return ExtendedVoteInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseExtendedVoteInfo();
        message.validator = (object.validator !== undefined && object.validator !== null)
            ? Validator.fromPartial(object.validator)
            : undefined;
        message.voteExtension = object.voteExtension ?? new Uint8Array();
        message.extensionSignature = object.extensionSignature ?? new Uint8Array();
        message.blockIdFlag = object.blockIdFlag ?? 0;
        return message;
    },
};
function createBaseMisbehavior() {
    return { type: 0, validator: undefined, height: "0", time: undefined, totalVotingPower: "0" };
}
export const Misbehavior = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.validator !== undefined) {
            Validator.encode(message.validator, writer.uint32(18).fork()).ldelim();
        }
        if (message.height !== "0") {
            writer.uint32(24).int64(message.height);
        }
        if (message.time !== undefined) {
            Timestamp.encode(toTimestamp(message.time), writer.uint32(34).fork()).ldelim();
        }
        if (message.totalVotingPower !== "0") {
            writer.uint32(40).int64(message.totalVotingPower);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMisbehavior();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.validator = Validator.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.height = longToString(reader.int64());
                    break;
                case 4:
                    message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.totalVotingPower = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? misbehaviorTypeFromJSON(object.type) : 0,
            validator: isSet(object.validator) ? Validator.fromJSON(object.validator) : undefined,
            height: isSet(object.height) ? String(object.height) : "0",
            time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
            totalVotingPower: isSet(object.totalVotingPower) ? String(object.totalVotingPower) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = misbehaviorTypeToJSON(message.type));
        message.validator !== undefined &&
            (obj.validator = message.validator ? Validator.toJSON(message.validator) : undefined);
        message.height !== undefined && (obj.height = message.height);
        message.time !== undefined && (obj.time = message.time.toISOString());
        message.totalVotingPower !== undefined && (obj.totalVotingPower = message.totalVotingPower);
        return obj;
    },
    create(base) {
        return Misbehavior.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseMisbehavior();
        message.type = object.type ?? 0;
        message.validator = (object.validator !== undefined && object.validator !== null)
            ? Validator.fromPartial(object.validator)
            : undefined;
        message.height = object.height ?? "0";
        message.time = object.time ?? undefined;
        message.totalVotingPower = object.totalVotingPower ?? "0";
        return message;
    },
};
function createBaseSnapshot() {
    return { height: "0", format: 0, chunks: 0, hash: new Uint8Array(), metadata: new Uint8Array() };
}
export const Snapshot = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.height !== "0") {
            writer.uint32(8).uint64(message.height);
        }
        if (message.format !== 0) {
            writer.uint32(16).uint32(message.format);
        }
        if (message.chunks !== 0) {
            writer.uint32(24).uint32(message.chunks);
        }
        if (message.hash.length !== 0) {
            writer.uint32(34).bytes(message.hash);
        }
        if (message.metadata.length !== 0) {
            writer.uint32(42).bytes(message.metadata);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSnapshot();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.height = longToString(reader.uint64());
                    break;
                case 2:
                    message.format = reader.uint32();
                    break;
                case 3:
                    message.chunks = reader.uint32();
                    break;
                case 4:
                    message.hash = reader.bytes();
                    break;
                case 5:
                    message.metadata = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            height: isSet(object.height) ? String(object.height) : "0",
            format: isSet(object.format) ? Number(object.format) : 0,
            chunks: isSet(object.chunks) ? Number(object.chunks) : 0,
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            metadata: isSet(object.metadata) ? bytesFromBase64(object.metadata) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.height !== undefined && (obj.height = message.height);
        message.format !== undefined && (obj.format = Math.round(message.format));
        message.chunks !== undefined && (obj.chunks = Math.round(message.chunks));
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.metadata !== undefined &&
            (obj.metadata = base64FromBytes(message.metadata !== undefined ? message.metadata : new Uint8Array()));
        return obj;
    },
    create(base) {
        return Snapshot.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseSnapshot();
        message.height = object.height ?? "0";
        message.format = object.format ?? 0;
        message.chunks = object.chunks ?? 0;
        message.hash = object.hash ?? new Uint8Array();
        message.metadata = object.metadata ?? new Uint8Array();
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1_000).toString();
    const nanos = (date.getTime() % 1_000) * 1_000_000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = Number(t.seconds) * 1_000;
    millis += t.nanos / 1_000_000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
