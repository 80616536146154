/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { orderFromJSON, orderToJSON, Timeout } from "./channel.js";
export const protobufPackage = "ibc.core.channel.v1";
function createBaseUpgrade() {
    return { fields: undefined, timeout: undefined, nextSequenceSend: "0" };
}
export const Upgrade = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.fields !== undefined) {
            UpgradeFields.encode(message.fields, writer.uint32(10).fork()).ldelim();
        }
        if (message.timeout !== undefined) {
            Timeout.encode(message.timeout, writer.uint32(18).fork()).ldelim();
        }
        if (message.nextSequenceSend !== "0") {
            writer.uint32(24).uint64(message.nextSequenceSend);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpgrade();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fields = UpgradeFields.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.timeout = Timeout.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.nextSequenceSend = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            fields: isSet(object.fields) ? UpgradeFields.fromJSON(object.fields) : undefined,
            timeout: isSet(object.timeout) ? Timeout.fromJSON(object.timeout) : undefined,
            nextSequenceSend: isSet(object.nextSequenceSend) ? String(object.nextSequenceSend) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.fields !== undefined && (obj.fields = message.fields ? UpgradeFields.toJSON(message.fields) : undefined);
        message.timeout !== undefined && (obj.timeout = message.timeout ? Timeout.toJSON(message.timeout) : undefined);
        message.nextSequenceSend !== undefined && (obj.nextSequenceSend = message.nextSequenceSend);
        return obj;
    },
    create(base) {
        return Upgrade.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseUpgrade();
        message.fields = (object.fields !== undefined && object.fields !== null)
            ? UpgradeFields.fromPartial(object.fields)
            : undefined;
        message.timeout = (object.timeout !== undefined && object.timeout !== null)
            ? Timeout.fromPartial(object.timeout)
            : undefined;
        message.nextSequenceSend = object.nextSequenceSend ?? "0";
        return message;
    },
};
function createBaseUpgradeFields() {
    return { ordering: 0, connectionHops: [], version: "" };
}
export const UpgradeFields = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ordering !== 0) {
            writer.uint32(8).int32(message.ordering);
        }
        for (const v of message.connectionHops) {
            writer.uint32(18).string(v);
        }
        if (message.version !== "") {
            writer.uint32(26).string(message.version);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpgradeFields();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ordering = reader.int32();
                    break;
                case 2:
                    message.connectionHops.push(reader.string());
                    break;
                case 3:
                    message.version = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            ordering: isSet(object.ordering) ? orderFromJSON(object.ordering) : 0,
            connectionHops: Array.isArray(object?.connectionHops) ? object.connectionHops.map((e) => String(e)) : [],
            version: isSet(object.version) ? String(object.version) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.ordering !== undefined && (obj.ordering = orderToJSON(message.ordering));
        if (message.connectionHops) {
            obj.connectionHops = message.connectionHops.map((e) => e);
        }
        else {
            obj.connectionHops = [];
        }
        message.version !== undefined && (obj.version = message.version);
        return obj;
    },
    create(base) {
        return UpgradeFields.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseUpgradeFields();
        message.ordering = object.ordering ?? 0;
        message.connectionHops = object.connectionHops?.map((e) => e) || [];
        message.version = object.version ?? "";
        return message;
    },
};
function createBaseErrorReceipt() {
    return { sequence: "0", message: "" };
}
export const ErrorReceipt = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sequence !== "0") {
            writer.uint32(8).uint64(message.sequence);
        }
        if (message.message !== "") {
            writer.uint32(18).string(message.message);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseErrorReceipt();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            message: isSet(object.message) ? String(object.message) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.message !== undefined && (obj.message = message.message);
        return obj;
    },
    create(base) {
        return ErrorReceipt.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseErrorReceipt();
        message.sequence = object.sequence ?? "0";
        message.message = object.message ?? "";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
