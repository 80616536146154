/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { InsuranceFund, Params, RedemptionSchedule } from "./insurance.js";
export const protobufPackage = "injective.insurance.v1beta1";
function createBaseGenesisState() {
    return {
        params: undefined,
        insuranceFunds: [],
        redemptionSchedule: [],
        nextShareDenomId: "0",
        nextRedemptionScheduleId: "0",
    };
}
export const GenesisState = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.insuranceFunds) {
            InsuranceFund.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.redemptionSchedule) {
            RedemptionSchedule.encode(v, writer.uint32(26).fork()).ldelim();
        }
        if (message.nextShareDenomId !== "0") {
            writer.uint32(32).uint64(message.nextShareDenomId);
        }
        if (message.nextRedemptionScheduleId !== "0") {
            writer.uint32(40).uint64(message.nextRedemptionScheduleId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenesisState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.insuranceFunds.push(InsuranceFund.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.redemptionSchedule.push(RedemptionSchedule.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.nextShareDenomId = longToString(reader.uint64());
                    break;
                case 5:
                    message.nextRedemptionScheduleId = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
            insuranceFunds: Array.isArray(object?.insuranceFunds)
                ? object.insuranceFunds.map((e) => InsuranceFund.fromJSON(e))
                : [],
            redemptionSchedule: Array.isArray(object?.redemptionSchedule)
                ? object.redemptionSchedule.map((e) => RedemptionSchedule.fromJSON(e))
                : [],
            nextShareDenomId: isSet(object.nextShareDenomId) ? String(object.nextShareDenomId) : "0",
            nextRedemptionScheduleId: isSet(object.nextRedemptionScheduleId) ? String(object.nextRedemptionScheduleId) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        if (message.insuranceFunds) {
            obj.insuranceFunds = message.insuranceFunds.map((e) => e ? InsuranceFund.toJSON(e) : undefined);
        }
        else {
            obj.insuranceFunds = [];
        }
        if (message.redemptionSchedule) {
            obj.redemptionSchedule = message.redemptionSchedule.map((e) => e ? RedemptionSchedule.toJSON(e) : undefined);
        }
        else {
            obj.redemptionSchedule = [];
        }
        message.nextShareDenomId !== undefined && (obj.nextShareDenomId = message.nextShareDenomId);
        message.nextRedemptionScheduleId !== undefined && (obj.nextRedemptionScheduleId = message.nextRedemptionScheduleId);
        return obj;
    },
    create(base) {
        return GenesisState.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGenesisState();
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        message.insuranceFunds = object.insuranceFunds?.map((e) => InsuranceFund.fromPartial(e)) || [];
        message.redemptionSchedule = object.redemptionSchedule?.map((e) => RedemptionSchedule.fromPartial(e)) || [];
        message.nextShareDenomId = object.nextShareDenomId ?? "0";
        message.nextRedemptionScheduleId = object.nextRedemptionScheduleId ?? "0";
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
