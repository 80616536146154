/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
export const protobufPackage = "cometbft.libs.bits.v1";
function createBaseBitArray() {
    return { bits: "0", elems: [] };
}
export const BitArray = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bits !== "0") {
            writer.uint32(8).int64(message.bits);
        }
        writer.uint32(18).fork();
        for (const v of message.elems) {
            writer.uint64(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBitArray();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bits = longToString(reader.int64());
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.elems.push(longToString(reader.uint64()));
                        }
                    }
                    else {
                        message.elems.push(longToString(reader.uint64()));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            bits: isSet(object.bits) ? String(object.bits) : "0",
            elems: Array.isArray(object?.elems) ? object.elems.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.bits !== undefined && (obj.bits = message.bits);
        if (message.elems) {
            obj.elems = message.elems.map((e) => e);
        }
        else {
            obj.elems = [];
        }
        return obj;
    },
    create(base) {
        return BitArray.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseBitArray();
        message.bits = object.bits ?? "0";
        message.elems = object.elems?.map((e) => e) || [];
        return message;
    },
};
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
