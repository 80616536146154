/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Timestamp } from "../../../google/protobuf/timestamp.js";
import { signedMsgTypeFromJSON, signedMsgTypeToJSON } from "./types.js";
export const protobufPackage = "cometbft.types.v1";
function createBaseCanonicalBlockID() {
    return { hash: new Uint8Array(), partSetHeader: undefined };
}
export const CanonicalBlockID = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.hash.length !== 0) {
            writer.uint32(10).bytes(message.hash);
        }
        if (message.partSetHeader !== undefined) {
            CanonicalPartSetHeader.encode(message.partSetHeader, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCanonicalBlockID();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hash = reader.bytes();
                    break;
                case 2:
                    message.partSetHeader = CanonicalPartSetHeader.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
            partSetHeader: isSet(object.partSetHeader) ? CanonicalPartSetHeader.fromJSON(object.partSetHeader) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.partSetHeader !== undefined &&
            (obj.partSetHeader = message.partSetHeader ? CanonicalPartSetHeader.toJSON(message.partSetHeader) : undefined);
        return obj;
    },
    create(base) {
        return CanonicalBlockID.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCanonicalBlockID();
        message.hash = object.hash ?? new Uint8Array();
        message.partSetHeader = (object.partSetHeader !== undefined && object.partSetHeader !== null)
            ? CanonicalPartSetHeader.fromPartial(object.partSetHeader)
            : undefined;
        return message;
    },
};
function createBaseCanonicalPartSetHeader() {
    return { total: 0, hash: new Uint8Array() };
}
export const CanonicalPartSetHeader = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.total !== 0) {
            writer.uint32(8).uint32(message.total);
        }
        if (message.hash.length !== 0) {
            writer.uint32(18).bytes(message.hash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCanonicalPartSetHeader();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.total = reader.uint32();
                    break;
                case 2:
                    message.hash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            total: isSet(object.total) ? Number(object.total) : 0,
            hash: isSet(object.hash) ? bytesFromBase64(object.hash) : new Uint8Array(),
        };
    },
    toJSON(message) {
        const obj = {};
        message.total !== undefined && (obj.total = Math.round(message.total));
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        return obj;
    },
    create(base) {
        return CanonicalPartSetHeader.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCanonicalPartSetHeader();
        message.total = object.total ?? 0;
        message.hash = object.hash ?? new Uint8Array();
        return message;
    },
};
function createBaseCanonicalProposal() {
    return { type: 0, height: "0", round: "0", polRound: "0", blockId: undefined, timestamp: undefined, chainId: "" };
}
export const CanonicalProposal = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.height !== "0") {
            writer.uint32(17).sfixed64(message.height);
        }
        if (message.round !== "0") {
            writer.uint32(25).sfixed64(message.round);
        }
        if (message.polRound !== "0") {
            writer.uint32(32).int64(message.polRound);
        }
        if (message.blockId !== undefined) {
            CanonicalBlockID.encode(message.blockId, writer.uint32(42).fork()).ldelim();
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(50).fork()).ldelim();
        }
        if (message.chainId !== "") {
            writer.uint32(58).string(message.chainId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCanonicalProposal();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.height = longToString(reader.sfixed64());
                    break;
                case 3:
                    message.round = longToString(reader.sfixed64());
                    break;
                case 4:
                    message.polRound = longToString(reader.int64());
                    break;
                case 5:
                    message.blockId = CanonicalBlockID.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.chainId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? signedMsgTypeFromJSON(object.type) : 0,
            height: isSet(object.height) ? String(object.height) : "0",
            round: isSet(object.round) ? String(object.round) : "0",
            polRound: isSet(object.polRound) ? String(object.polRound) : "0",
            blockId: isSet(object.blockId) ? CanonicalBlockID.fromJSON(object.blockId) : undefined,
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            chainId: isSet(object.chainId) ? String(object.chainId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = signedMsgTypeToJSON(message.type));
        message.height !== undefined && (obj.height = message.height);
        message.round !== undefined && (obj.round = message.round);
        message.polRound !== undefined && (obj.polRound = message.polRound);
        message.blockId !== undefined &&
            (obj.blockId = message.blockId ? CanonicalBlockID.toJSON(message.blockId) : undefined);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
        message.chainId !== undefined && (obj.chainId = message.chainId);
        return obj;
    },
    create(base) {
        return CanonicalProposal.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCanonicalProposal();
        message.type = object.type ?? 0;
        message.height = object.height ?? "0";
        message.round = object.round ?? "0";
        message.polRound = object.polRound ?? "0";
        message.blockId = (object.blockId !== undefined && object.blockId !== null)
            ? CanonicalBlockID.fromPartial(object.blockId)
            : undefined;
        message.timestamp = object.timestamp ?? undefined;
        message.chainId = object.chainId ?? "";
        return message;
    },
};
function createBaseCanonicalVote() {
    return { type: 0, height: "0", round: "0", blockId: undefined, timestamp: undefined, chainId: "" };
}
export const CanonicalVote = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.height !== "0") {
            writer.uint32(17).sfixed64(message.height);
        }
        if (message.round !== "0") {
            writer.uint32(25).sfixed64(message.round);
        }
        if (message.blockId !== undefined) {
            CanonicalBlockID.encode(message.blockId, writer.uint32(34).fork()).ldelim();
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(42).fork()).ldelim();
        }
        if (message.chainId !== "") {
            writer.uint32(50).string(message.chainId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCanonicalVote();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.height = longToString(reader.sfixed64());
                    break;
                case 3:
                    message.round = longToString(reader.sfixed64());
                    break;
                case 4:
                    message.blockId = CanonicalBlockID.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.chainId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? signedMsgTypeFromJSON(object.type) : 0,
            height: isSet(object.height) ? String(object.height) : "0",
            round: isSet(object.round) ? String(object.round) : "0",
            blockId: isSet(object.blockId) ? CanonicalBlockID.fromJSON(object.blockId) : undefined,
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            chainId: isSet(object.chainId) ? String(object.chainId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = signedMsgTypeToJSON(message.type));
        message.height !== undefined && (obj.height = message.height);
        message.round !== undefined && (obj.round = message.round);
        message.blockId !== undefined &&
            (obj.blockId = message.blockId ? CanonicalBlockID.toJSON(message.blockId) : undefined);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
        message.chainId !== undefined && (obj.chainId = message.chainId);
        return obj;
    },
    create(base) {
        return CanonicalVote.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCanonicalVote();
        message.type = object.type ?? 0;
        message.height = object.height ?? "0";
        message.round = object.round ?? "0";
        message.blockId = (object.blockId !== undefined && object.blockId !== null)
            ? CanonicalBlockID.fromPartial(object.blockId)
            : undefined;
        message.timestamp = object.timestamp ?? undefined;
        message.chainId = object.chainId ?? "";
        return message;
    },
};
function createBaseCanonicalVoteExtension() {
    return { extension: new Uint8Array(), height: "0", round: "0", chainId: "" };
}
export const CanonicalVoteExtension = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.extension.length !== 0) {
            writer.uint32(10).bytes(message.extension);
        }
        if (message.height !== "0") {
            writer.uint32(17).sfixed64(message.height);
        }
        if (message.round !== "0") {
            writer.uint32(25).sfixed64(message.round);
        }
        if (message.chainId !== "") {
            writer.uint32(34).string(message.chainId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCanonicalVoteExtension();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.extension = reader.bytes();
                    break;
                case 2:
                    message.height = longToString(reader.sfixed64());
                    break;
                case 3:
                    message.round = longToString(reader.sfixed64());
                    break;
                case 4:
                    message.chainId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            extension: isSet(object.extension) ? bytesFromBase64(object.extension) : new Uint8Array(),
            height: isSet(object.height) ? String(object.height) : "0",
            round: isSet(object.round) ? String(object.round) : "0",
            chainId: isSet(object.chainId) ? String(object.chainId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.extension !== undefined &&
            (obj.extension = base64FromBytes(message.extension !== undefined ? message.extension : new Uint8Array()));
        message.height !== undefined && (obj.height = message.height);
        message.round !== undefined && (obj.round = message.round);
        message.chainId !== undefined && (obj.chainId = message.chainId);
        return obj;
    },
    create(base) {
        return CanonicalVoteExtension.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCanonicalVoteExtension();
        message.extension = object.extension ?? new Uint8Array();
        message.height = object.height ?? "0";
        message.round = object.round ?? "0";
        message.chainId = object.chainId ?? "";
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1_000).toString();
    const nanos = (date.getTime() % 1_000) * 1_000_000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = Number(t.seconds) * 1_000;
    millis += t.nanos / 1_000_000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
