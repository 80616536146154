/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";
import { Duration } from "../../../google/protobuf/duration.js";
import { Timestamp } from "../../../google/protobuf/timestamp.js";
import { Event, ExecTxResult, FinalizeBlockResponse, ValidatorUpdate } from "../../abci/v1/types.js";
import { ConsensusParams } from "../../types/v1/params.js";
import { BlockID } from "../../types/v1/types.js";
import { ValidatorSet } from "../../types/v1/validator.js";
import { Consensus } from "../../version/v1/types.js";
export const protobufPackage = "cometbft.state.v1";
function createBaseLegacyABCIResponses() {
    return { deliverTxs: [], endBlock: undefined, beginBlock: undefined };
}
export const LegacyABCIResponses = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.deliverTxs) {
            ExecTxResult.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.endBlock !== undefined) {
            ResponseEndBlock.encode(message.endBlock, writer.uint32(18).fork()).ldelim();
        }
        if (message.beginBlock !== undefined) {
            ResponseBeginBlock.encode(message.beginBlock, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLegacyABCIResponses();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.deliverTxs.push(ExecTxResult.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.endBlock = ResponseEndBlock.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.beginBlock = ResponseBeginBlock.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            deliverTxs: Array.isArray(object?.deliverTxs) ? object.deliverTxs.map((e) => ExecTxResult.fromJSON(e)) : [],
            endBlock: isSet(object.endBlock) ? ResponseEndBlock.fromJSON(object.endBlock) : undefined,
            beginBlock: isSet(object.beginBlock) ? ResponseBeginBlock.fromJSON(object.beginBlock) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.deliverTxs) {
            obj.deliverTxs = message.deliverTxs.map((e) => e ? ExecTxResult.toJSON(e) : undefined);
        }
        else {
            obj.deliverTxs = [];
        }
        message.endBlock !== undefined &&
            (obj.endBlock = message.endBlock ? ResponseEndBlock.toJSON(message.endBlock) : undefined);
        message.beginBlock !== undefined &&
            (obj.beginBlock = message.beginBlock ? ResponseBeginBlock.toJSON(message.beginBlock) : undefined);
        return obj;
    },
    create(base) {
        return LegacyABCIResponses.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseLegacyABCIResponses();
        message.deliverTxs = object.deliverTxs?.map((e) => ExecTxResult.fromPartial(e)) || [];
        message.endBlock = (object.endBlock !== undefined && object.endBlock !== null)
            ? ResponseEndBlock.fromPartial(object.endBlock)
            : undefined;
        message.beginBlock = (object.beginBlock !== undefined && object.beginBlock !== null)
            ? ResponseBeginBlock.fromPartial(object.beginBlock)
            : undefined;
        return message;
    },
};
function createBaseResponseBeginBlock() {
    return { events: [] };
}
export const ResponseBeginBlock = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.events) {
            Event.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponseBeginBlock();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.events.push(Event.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { events: Array.isArray(object?.events) ? object.events.map((e) => Event.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.events) {
            obj.events = message.events.map((e) => e ? Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        return obj;
    },
    create(base) {
        return ResponseBeginBlock.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseResponseBeginBlock();
        message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
        return message;
    },
};
function createBaseResponseEndBlock() {
    return { validatorUpdates: [], consensusParamUpdates: undefined, events: [] };
}
export const ResponseEndBlock = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.validatorUpdates) {
            ValidatorUpdate.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.consensusParamUpdates !== undefined) {
            ConsensusParams.encode(message.consensusParamUpdates, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.events) {
            Event.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponseEndBlock();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.validatorUpdates.push(ValidatorUpdate.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.consensusParamUpdates = ConsensusParams.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.events.push(Event.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            validatorUpdates: Array.isArray(object?.validatorUpdates)
                ? object.validatorUpdates.map((e) => ValidatorUpdate.fromJSON(e))
                : [],
            consensusParamUpdates: isSet(object.consensusParamUpdates)
                ? ConsensusParams.fromJSON(object.consensusParamUpdates)
                : undefined,
            events: Array.isArray(object?.events) ? object.events.map((e) => Event.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.validatorUpdates) {
            obj.validatorUpdates = message.validatorUpdates.map((e) => e ? ValidatorUpdate.toJSON(e) : undefined);
        }
        else {
            obj.validatorUpdates = [];
        }
        message.consensusParamUpdates !== undefined && (obj.consensusParamUpdates = message.consensusParamUpdates
            ? ConsensusParams.toJSON(message.consensusParamUpdates)
            : undefined);
        if (message.events) {
            obj.events = message.events.map((e) => e ? Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        return obj;
    },
    create(base) {
        return ResponseEndBlock.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseResponseEndBlock();
        message.validatorUpdates = object.validatorUpdates?.map((e) => ValidatorUpdate.fromPartial(e)) || [];
        message.consensusParamUpdates =
            (object.consensusParamUpdates !== undefined && object.consensusParamUpdates !== null)
                ? ConsensusParams.fromPartial(object.consensusParamUpdates)
                : undefined;
        message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
        return message;
    },
};
function createBaseValidatorsInfo() {
    return { validatorSet: undefined, lastHeightChanged: "0" };
}
export const ValidatorsInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.validatorSet !== undefined) {
            ValidatorSet.encode(message.validatorSet, writer.uint32(10).fork()).ldelim();
        }
        if (message.lastHeightChanged !== "0") {
            writer.uint32(16).int64(message.lastHeightChanged);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValidatorsInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.validatorSet = ValidatorSet.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lastHeightChanged = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            validatorSet: isSet(object.validatorSet) ? ValidatorSet.fromJSON(object.validatorSet) : undefined,
            lastHeightChanged: isSet(object.lastHeightChanged) ? String(object.lastHeightChanged) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.validatorSet !== undefined &&
            (obj.validatorSet = message.validatorSet ? ValidatorSet.toJSON(message.validatorSet) : undefined);
        message.lastHeightChanged !== undefined && (obj.lastHeightChanged = message.lastHeightChanged);
        return obj;
    },
    create(base) {
        return ValidatorsInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseValidatorsInfo();
        message.validatorSet = (object.validatorSet !== undefined && object.validatorSet !== null)
            ? ValidatorSet.fromPartial(object.validatorSet)
            : undefined;
        message.lastHeightChanged = object.lastHeightChanged ?? "0";
        return message;
    },
};
function createBaseConsensusParamsInfo() {
    return { consensusParams: undefined, lastHeightChanged: "0" };
}
export const ConsensusParamsInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.consensusParams !== undefined) {
            ConsensusParams.encode(message.consensusParams, writer.uint32(10).fork()).ldelim();
        }
        if (message.lastHeightChanged !== "0") {
            writer.uint32(16).int64(message.lastHeightChanged);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConsensusParamsInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.consensusParams = ConsensusParams.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lastHeightChanged = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            consensusParams: isSet(object.consensusParams) ? ConsensusParams.fromJSON(object.consensusParams) : undefined,
            lastHeightChanged: isSet(object.lastHeightChanged) ? String(object.lastHeightChanged) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.consensusParams !== undefined &&
            (obj.consensusParams = message.consensusParams ? ConsensusParams.toJSON(message.consensusParams) : undefined);
        message.lastHeightChanged !== undefined && (obj.lastHeightChanged = message.lastHeightChanged);
        return obj;
    },
    create(base) {
        return ConsensusParamsInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseConsensusParamsInfo();
        message.consensusParams = (object.consensusParams !== undefined && object.consensusParams !== null)
            ? ConsensusParams.fromPartial(object.consensusParams)
            : undefined;
        message.lastHeightChanged = object.lastHeightChanged ?? "0";
        return message;
    },
};
function createBaseABCIResponsesInfo() {
    return { legacyAbciResponses: undefined, height: "0", finalizeBlock: undefined };
}
export const ABCIResponsesInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.legacyAbciResponses !== undefined) {
            LegacyABCIResponses.encode(message.legacyAbciResponses, writer.uint32(10).fork()).ldelim();
        }
        if (message.height !== "0") {
            writer.uint32(16).int64(message.height);
        }
        if (message.finalizeBlock !== undefined) {
            FinalizeBlockResponse.encode(message.finalizeBlock, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseABCIResponsesInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.legacyAbciResponses = LegacyABCIResponses.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.height = longToString(reader.int64());
                    break;
                case 3:
                    message.finalizeBlock = FinalizeBlockResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            legacyAbciResponses: isSet(object.legacyAbciResponses)
                ? LegacyABCIResponses.fromJSON(object.legacyAbciResponses)
                : undefined,
            height: isSet(object.height) ? String(object.height) : "0",
            finalizeBlock: isSet(object.finalizeBlock) ? FinalizeBlockResponse.fromJSON(object.finalizeBlock) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.legacyAbciResponses !== undefined && (obj.legacyAbciResponses = message.legacyAbciResponses
            ? LegacyABCIResponses.toJSON(message.legacyAbciResponses)
            : undefined);
        message.height !== undefined && (obj.height = message.height);
        message.finalizeBlock !== undefined &&
            (obj.finalizeBlock = message.finalizeBlock ? FinalizeBlockResponse.toJSON(message.finalizeBlock) : undefined);
        return obj;
    },
    create(base) {
        return ABCIResponsesInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseABCIResponsesInfo();
        message.legacyAbciResponses = (object.legacyAbciResponses !== undefined && object.legacyAbciResponses !== null)
            ? LegacyABCIResponses.fromPartial(object.legacyAbciResponses)
            : undefined;
        message.height = object.height ?? "0";
        message.finalizeBlock = (object.finalizeBlock !== undefined && object.finalizeBlock !== null)
            ? FinalizeBlockResponse.fromPartial(object.finalizeBlock)
            : undefined;
        return message;
    },
};
function createBaseVersion() {
    return { consensus: undefined, software: "" };
}
export const Version = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.consensus !== undefined) {
            Consensus.encode(message.consensus, writer.uint32(10).fork()).ldelim();
        }
        if (message.software !== "") {
            writer.uint32(18).string(message.software);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVersion();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.consensus = Consensus.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.software = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            consensus: isSet(object.consensus) ? Consensus.fromJSON(object.consensus) : undefined,
            software: isSet(object.software) ? String(object.software) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.consensus !== undefined &&
            (obj.consensus = message.consensus ? Consensus.toJSON(message.consensus) : undefined);
        message.software !== undefined && (obj.software = message.software);
        return obj;
    },
    create(base) {
        return Version.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseVersion();
        message.consensus = (object.consensus !== undefined && object.consensus !== null)
            ? Consensus.fromPartial(object.consensus)
            : undefined;
        message.software = object.software ?? "";
        return message;
    },
};
function createBaseState() {
    return {
        version: undefined,
        chainId: "",
        initialHeight: "0",
        lastBlockHeight: "0",
        lastBlockId: undefined,
        lastBlockTime: undefined,
        nextValidators: undefined,
        validators: undefined,
        lastValidators: undefined,
        lastHeightValidatorsChanged: "0",
        consensusParams: undefined,
        lastHeightConsensusParamsChanged: "0",
        lastResultsHash: new Uint8Array(),
        appHash: new Uint8Array(),
        nextBlockDelay: undefined,
    };
}
export const State = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.version !== undefined) {
            Version.encode(message.version, writer.uint32(10).fork()).ldelim();
        }
        if (message.chainId !== "") {
            writer.uint32(18).string(message.chainId);
        }
        if (message.initialHeight !== "0") {
            writer.uint32(112).int64(message.initialHeight);
        }
        if (message.lastBlockHeight !== "0") {
            writer.uint32(24).int64(message.lastBlockHeight);
        }
        if (message.lastBlockId !== undefined) {
            BlockID.encode(message.lastBlockId, writer.uint32(34).fork()).ldelim();
        }
        if (message.lastBlockTime !== undefined) {
            Timestamp.encode(toTimestamp(message.lastBlockTime), writer.uint32(42).fork()).ldelim();
        }
        if (message.nextValidators !== undefined) {
            ValidatorSet.encode(message.nextValidators, writer.uint32(50).fork()).ldelim();
        }
        if (message.validators !== undefined) {
            ValidatorSet.encode(message.validators, writer.uint32(58).fork()).ldelim();
        }
        if (message.lastValidators !== undefined) {
            ValidatorSet.encode(message.lastValidators, writer.uint32(66).fork()).ldelim();
        }
        if (message.lastHeightValidatorsChanged !== "0") {
            writer.uint32(72).int64(message.lastHeightValidatorsChanged);
        }
        if (message.consensusParams !== undefined) {
            ConsensusParams.encode(message.consensusParams, writer.uint32(82).fork()).ldelim();
        }
        if (message.lastHeightConsensusParamsChanged !== "0") {
            writer.uint32(88).int64(message.lastHeightConsensusParamsChanged);
        }
        if (message.lastResultsHash.length !== 0) {
            writer.uint32(98).bytes(message.lastResultsHash);
        }
        if (message.appHash.length !== 0) {
            writer.uint32(106).bytes(message.appHash);
        }
        if (message.nextBlockDelay !== undefined) {
            Duration.encode(message.nextBlockDelay, writer.uint32(122).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.version = Version.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.chainId = reader.string();
                    break;
                case 14:
                    message.initialHeight = longToString(reader.int64());
                    break;
                case 3:
                    message.lastBlockHeight = longToString(reader.int64());
                    break;
                case 4:
                    message.lastBlockId = BlockID.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.lastBlockTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.nextValidators = ValidatorSet.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.validators = ValidatorSet.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.lastValidators = ValidatorSet.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.lastHeightValidatorsChanged = longToString(reader.int64());
                    break;
                case 10:
                    message.consensusParams = ConsensusParams.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.lastHeightConsensusParamsChanged = longToString(reader.int64());
                    break;
                case 12:
                    message.lastResultsHash = reader.bytes();
                    break;
                case 13:
                    message.appHash = reader.bytes();
                    break;
                case 15:
                    message.nextBlockDelay = Duration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            version: isSet(object.version) ? Version.fromJSON(object.version) : undefined,
            chainId: isSet(object.chainId) ? String(object.chainId) : "",
            initialHeight: isSet(object.initialHeight) ? String(object.initialHeight) : "0",
            lastBlockHeight: isSet(object.lastBlockHeight) ? String(object.lastBlockHeight) : "0",
            lastBlockId: isSet(object.lastBlockId) ? BlockID.fromJSON(object.lastBlockId) : undefined,
            lastBlockTime: isSet(object.lastBlockTime) ? fromJsonTimestamp(object.lastBlockTime) : undefined,
            nextValidators: isSet(object.nextValidators) ? ValidatorSet.fromJSON(object.nextValidators) : undefined,
            validators: isSet(object.validators) ? ValidatorSet.fromJSON(object.validators) : undefined,
            lastValidators: isSet(object.lastValidators) ? ValidatorSet.fromJSON(object.lastValidators) : undefined,
            lastHeightValidatorsChanged: isSet(object.lastHeightValidatorsChanged)
                ? String(object.lastHeightValidatorsChanged)
                : "0",
            consensusParams: isSet(object.consensusParams) ? ConsensusParams.fromJSON(object.consensusParams) : undefined,
            lastHeightConsensusParamsChanged: isSet(object.lastHeightConsensusParamsChanged)
                ? String(object.lastHeightConsensusParamsChanged)
                : "0",
            lastResultsHash: isSet(object.lastResultsHash) ? bytesFromBase64(object.lastResultsHash) : new Uint8Array(),
            appHash: isSet(object.appHash) ? bytesFromBase64(object.appHash) : new Uint8Array(),
            nextBlockDelay: isSet(object.nextBlockDelay) ? Duration.fromJSON(object.nextBlockDelay) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.version !== undefined && (obj.version = message.version ? Version.toJSON(message.version) : undefined);
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.initialHeight !== undefined && (obj.initialHeight = message.initialHeight);
        message.lastBlockHeight !== undefined && (obj.lastBlockHeight = message.lastBlockHeight);
        message.lastBlockId !== undefined &&
            (obj.lastBlockId = message.lastBlockId ? BlockID.toJSON(message.lastBlockId) : undefined);
        message.lastBlockTime !== undefined && (obj.lastBlockTime = message.lastBlockTime.toISOString());
        message.nextValidators !== undefined &&
            (obj.nextValidators = message.nextValidators ? ValidatorSet.toJSON(message.nextValidators) : undefined);
        message.validators !== undefined &&
            (obj.validators = message.validators ? ValidatorSet.toJSON(message.validators) : undefined);
        message.lastValidators !== undefined &&
            (obj.lastValidators = message.lastValidators ? ValidatorSet.toJSON(message.lastValidators) : undefined);
        message.lastHeightValidatorsChanged !== undefined &&
            (obj.lastHeightValidatorsChanged = message.lastHeightValidatorsChanged);
        message.consensusParams !== undefined &&
            (obj.consensusParams = message.consensusParams ? ConsensusParams.toJSON(message.consensusParams) : undefined);
        message.lastHeightConsensusParamsChanged !== undefined &&
            (obj.lastHeightConsensusParamsChanged = message.lastHeightConsensusParamsChanged);
        message.lastResultsHash !== undefined &&
            (obj.lastResultsHash = base64FromBytes(message.lastResultsHash !== undefined ? message.lastResultsHash : new Uint8Array()));
        message.appHash !== undefined &&
            (obj.appHash = base64FromBytes(message.appHash !== undefined ? message.appHash : new Uint8Array()));
        message.nextBlockDelay !== undefined &&
            (obj.nextBlockDelay = message.nextBlockDelay ? Duration.toJSON(message.nextBlockDelay) : undefined);
        return obj;
    },
    create(base) {
        return State.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseState();
        message.version = (object.version !== undefined && object.version !== null)
            ? Version.fromPartial(object.version)
            : undefined;
        message.chainId = object.chainId ?? "";
        message.initialHeight = object.initialHeight ?? "0";
        message.lastBlockHeight = object.lastBlockHeight ?? "0";
        message.lastBlockId = (object.lastBlockId !== undefined && object.lastBlockId !== null)
            ? BlockID.fromPartial(object.lastBlockId)
            : undefined;
        message.lastBlockTime = object.lastBlockTime ?? undefined;
        message.nextValidators = (object.nextValidators !== undefined && object.nextValidators !== null)
            ? ValidatorSet.fromPartial(object.nextValidators)
            : undefined;
        message.validators = (object.validators !== undefined && object.validators !== null)
            ? ValidatorSet.fromPartial(object.validators)
            : undefined;
        message.lastValidators = (object.lastValidators !== undefined && object.lastValidators !== null)
            ? ValidatorSet.fromPartial(object.lastValidators)
            : undefined;
        message.lastHeightValidatorsChanged = object.lastHeightValidatorsChanged ?? "0";
        message.consensusParams = (object.consensusParams !== undefined && object.consensusParams !== null)
            ? ConsensusParams.fromPartial(object.consensusParams)
            : undefined;
        message.lastHeightConsensusParamsChanged = object.lastHeightConsensusParamsChanged ?? "0";
        message.lastResultsHash = object.lastResultsHash ?? new Uint8Array();
        message.appHash = object.appHash ?? new Uint8Array();
        message.nextBlockDelay = (object.nextBlockDelay !== undefined && object.nextBlockDelay !== null)
            ? Duration.fromPartial(object.nextBlockDelay)
            : undefined;
        return message;
    },
};
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1_000).toString();
    const nanos = (date.getTime() % 1_000) * 1_000_000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = Number(t.seconds) * 1_000;
    millis += t.nanos / 1_000_000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
